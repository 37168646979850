<div id="container">

    <div class="alert-message alert-message-warning">
        <div class="row">
            <div class="col-lg-9 header-banner-title">
                <br >
                <h1 class="headerTitle text-left">
                    {{ bannerTitle }}
                </h1>
                <br />
                <h4 *ngIf="bannerSubTitle != ''" class="text-left">{{ bannerSubTitle }}</h4>
            </div>

            <div class="header-banner col-lg-3">
                <a (click)="utility.redirectToSolar()" >
                    <img src="assets/images/solar-banner2.png" id="banner">
                </a>
                <br>
                <div class="header-banner-link">
                    <a 
                        (click)="utility.redirectToSolar()" 
                        alt="Try the Solar Calculator" 
                        title="Try the Solar Calculator" 
                        aria-label="Try the Solar Calculator" 
                        class="link non-focus">
                        Try the Solar Calculator
                    </a>
                </div>
            </div>
        </div>
    </div>
    <br />

    <div class="solarsavings-details col-lg-11">
        <div class="checkbox-inline col-lg-12">
            <input type="checkbox" id="step1" value="step1" name="steps" class="accordion">
            <label for="step1" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">sunny</i> 
                Step 1 - Research if solar is right for you
            </label>
            <div class="content">
                <h3>Why solar?</h3>
                <p>
                    By installing a solar photovoltaic system (PV), you can create your own electricity. This electricity can 
                    then be used in your property. Any electricity that you don’t use will be fed back into the grid. Providers 
                    may even pay you for this excess electricity that is fed back into the grid, this is known as a 'Feed-in 
                    tariff' (FiT). 
                </p>
                <br />
                <h3>Is my property suitable?</h3>
                <p>
                    You will need to understand how many panels you can fit on your property. For example, if installing on your 
                    home, this is determined by the size of your roof. In most cases, north is the best way to face solar panels, 
                    but in some circumstances you can also benefit from having your solar panels facing to the east or the west.
                </p>
                <br />
                <h3>
                    Electricity storage
                </h3>
                <p>
                    Some consumers choose to install batteries or a home energy management system. These will allow you to 
                    efficiently use the electricity you generate. For example, a battery stores excess energy for later use.
                </p>
            </div>

            <input type="checkbox" id="step2" value="step2" name="steps" class="accordion">
            <label for="step2" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">request_quote</i> 
                Step 2 - Arrange quotes from qualified installers
            </label>
            <div class="content">
                <p>
                    To understand which solar system suits you, you will need to arrange multiple quotes from qualified installers. The Clean 
                    Energy Council (CEC) provides accreditation for solar panel installers. If you are looking to receive government discounts 
                    for solar, your installer must be CEC accredited. Visit <a class="link" href="http://cleanenergycouncil.org.au" target="_blank">cleanenergycouncil.org.au</a> to find out more information.
                </p>
            </div>

            <input type="checkbox" id="step3" value="step3" name="steps" class="accordion">
            <label for="step3" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">compare</i> 
                Step 3 - Compare quotes
            </label>
            <div class="content">
                <p>
                    Now that you have quotes from different installers you will need to compare these to make sure you are getting the best deal 
                    for your situation.
                </p>
                <br />
                <h3>
                    Return on Investment – will solar save you money?
                </h3>
                <p>
                    Use our Solar Calculator to compare up to 3 of the solar quotes you have been given, to see if you can save money. Make sure 
                    you know and understand all the upfront costs and any ongoing costs included in each quote.
                </p>
                <br />
                <p>
                    If you do not have any electricity tariffs to use in the calculator, visit <a class="link" href="{{ appURL }}">Victorian Energy Compare</a> to find out what offers are 
                    currently available to you.
                </p>
                <br />
                <h3>
                    Incentives
                </h3>
                <p>
                    There may be rebates or incentives available to reduce the cost of your solar system. Visit <a class="link" href="https://www.solar.vic.gov.au/" target="_blank">Solar Victoria</a> to find out what might 
                    be available to you.
                </p>
                <br />
                <h3>
                    Warranties
                </h3>
                <p>
                    In each quote you will receive information regarding the warranties that will cover your solar system. There are four that you 
                    need to be aware of:
                </p>
                <br />
                <ol>
                    <li>
                        Panel performance warranty – this covers the performance of the panels
                    </li>
                    <li>
                        Panel product warranty – this covers the panels and any defects or workmanship issues
                    </li>
                    <li>
                        Inverter warranty – this covers the inverter and any defects or workmanship issues
                    </li>
                    <li>
                        Installation warranty – this covers the installation of the solar system  
                    </li>
                </ol>
            </div>

            <input type="checkbox" id="step4" value="step4" name="steps" class="accordion">
            <label for="step4" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">how_to_reg</i> 
                Step 4 - Choose an installer
            </label>
            <div class="content">
                <p>
                    Now that you have compared the quotes you received from the installers, you must choose the installer 
                    that is right for you and sign a contract agreeing to the costs and installation.
                </p>
                <br />
                <p>
                    If you need any information or further clarification before choosing an installer, you can visit <a class="link" href="https://www.solar.vic.gov.au/" target="_blank">Solar 
                    Victoria</a> and the <a class="link" href="http://cleanenergycouncil.org.au" target="_blank">Clean Energy Council</a> for guidance.
                </p>
            </div>

            <input type="checkbox" id="step5" value="step5" name="steps" class="accordion">
            <label for="step5" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">electrical_services</i> 
                Step 5 - System installation and grid connection
            </label>
            <div class="content">
                <p>
                    The solar installers will come and install your selected system at your property. The new system will then be 
                    connected to the grid.
                </p>
            </div>

            <input type="checkbox" id="step6" value="step6" name="steps" class="accordion">
            <label for="step6" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">flash_on</i> 
                Step 6 - Find the best electricity offer
            </label>
            <div class="content">
                <p>
                    Now that you have your solar system up and running, make sure you are getting the best electricity deal and 
                    Feed-in Tariff. Visit <a class="link" href="{{ appURL }}">Victorian Energy Compare</a> to find the best deal for you.
                </p>
            </div>

            <input type="checkbox" id="step7" value="step7" name="steps" class="accordion">
            <label for="step7" class="custom-btn-large custom-btn-large-text col-lg-12">
                <i class="material-icons solar-icons" aria-hidden="true">build</i> 
                Step 7 - Maintain your solar system
            </label>
            <div class="content">
                <p>
                    It is important to maintain your solar system to make sure that it is running safely, correctly and efficiently. 
                    This will ensure that you get safe and optimal use of it.
                </p>
                <br />
                <p>
                    The manufacturers of your solar system will advise how often maintenance must be carried out.
                </p>
                <br />
                <p>
                    The Clean Energy Regulator recommends that maintenance should be done by an ECC accredited solar system installer 
                    or a licenced electrician.
                </p>
            </div>
        </div>
    </div>
    <div class="solarsavings col-lg-12">
        <div class="row solar-images">
            <div class="col-md-6 col-sm-6">
                <img src="assets/images/contentpages/solarsavings_left.jpg" alt="Reduce your electricity bills with a solar system">
            </div>
            <div class="col-md-6 col-sm-6">
                <img src="assets/images/contentpages/solarsavings_right.jpg" alt="Reduce your electricity bills with a solar system">
            </div>
        </div>

        <div class="row">
            <div class="col-md-2 offset-md-1" style="clear: both;">
                <br />
                <span class="circle-badge">1</span>
                <br />
                <br />
                <strong>Solar Panels</strong>
                <br />
                Solar panels generate Direct Current (DC) electricity that flows to the inverter
            </div>
            <div class="col-md-2">
                <br />
                <span class="circle-badge">2</span>
                <br />
                <br />
                <strong>Inverter</strong>
                <br />
                An inverter changes the solar DC power into alternating current (AC), suitable for your household appliances 
                and feeding into the grid
            </div>
            <div class="col-md-2">
                <br />
                <span class="circle-badge">3</span>
                <br />
                <br />
                <strong>Switchboard (electricity to house)</strong>
                <br />
                Your home uses electricity from solar PV modules first, and additional demand is supplied from the grid
            </div>
            <div class="col-md-2">
                <br />
                <span class="circle-badge">4</span>
                <br />
                <br />
                <strong>Meter</strong>
                <br />
                A meter measures your electricity consumption and solar export
            </div>
            <div class="col-md-2">
                <br />
                <span class="circle-badge">5</span>
                <br />
                <br />
                <strong>Electricity network (grid)</strong>
                <br />
                Any excess electricity is exported back into the electricity network
            </div>
        </div>
    </div>
</div>
<br /><br />
