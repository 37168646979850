<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
</ngx-spinner><br />
<div class="col-lg-12" class="cal-background">
    <form method="post" id="solarForm" [formGroup]="solarForm">
        <br />
        <div class="form-details">
            <h3>
                Enter your current electricity rates
            </h3>
            <hr>

            <div class="row" *ngIf="saveFormError != ''">
                <div class="col-lg-12" id="saveFormError">
                    <div class="alert alert-danger text-center" role="alert">
                        <p>
                            An error has occured while trying to submit the information you have provided. Please try again.
                        </p>
                    </div>
                </div>
            </div>

            <div class="alert alert-danger" *ngIf="utility.getErrorMessage() != ''">
                <a class="close" data-dismiss="alert" aria-label="close" title="close" (click)="utility.setErrorMessage('')">�</a>
                <i class="fa fa-warning"></i> {{ utility.getErrorMessage() }}
            </div>

            <div class="form-group">
                <label class="control-label"><h3 class="form_label title-black">Peak rate for electricity usage </h3></label>
                <span class="fa fa-info-circle" placement="bottom" [triggers]="utility.toolTipForMobile()" ngbTooltip="If your current plan only has one tariff, this will probably be called 'peak'. You might have peak and off-peak rates on your bill if you are on a flexible pricing plan. This means your electricity costs different prices at different times of the day. Peak is when electricity costs the most money. This might be during the evening."></span>
                <div class="input-group-prepend">
                    <input type="number" step="0.1" class="form-control short-input-text"
                           placeholder="e.g. 25" formControlName="peakRateValue" tabindex="1" [(ngModel)]="solarData.peakRateValue">
                    <span class="kwh-label">Cents per kWh</span>
                </div>
                <bfv-messages></bfv-messages>
            </div>
            <p>&nbsp;</p>

            <div class="form-group">
                <label class="control-label"><h3 class="form_label title-black">Do you have off-peak rates? </h3></label>
                <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="You will only have an off-peak rate if you are on a flexible pricing plan. This means your electricity costs different prices at different times of the day. Off-peak is when electricity is cheapest. This might be during the night time."></span>
                <br />
                <div class="radio-inline">
                    <input type="radio" id="offPeakRateYes" value="1" formControlName="offPeakRate" [(ngModel)]="solarData.offPeakRate" [checked]="solarData.offPeakRate == 1">
                    <label for="offPeakRateYes" class="custom-btn-default custom-btn-text" tabindex="2" (keydown)="selectButton($event, 'offPeakRateYes')">Yes</label>
                    <input type="radio" id="offPeakRateNo" value="0" formControlName="offPeakRate" [(ngModel)]="solarData.offPeakRate" [checked]="solarData.offPeakRate == 0">
                    <label for="offPeakRateNo" class="custom-btn-default custom-btn-text" tabindex="3" (keydown)="selectButton($event, 'offPeakRateNo')">No</label>
                </div>
                <bfv-messages></bfv-messages>

                <div id="offpeak" [hidden]="solarData.offPeakRate != 1">
                    <div class="form-group">
                        <p>&nbsp;</p>
                        <label class="control-label"><h3 class="form_label title-black">Off-peak rate </h3></label>
                        <div class="input-group-prepend">
                            <input type="number" step="0.1" class="form-control short-input-text" formControlName="offPeakRateValue" placeholder="e.g. 17" tabindex="4" [(ngModel)]="solarData.offPeakRateValue">
                            <span class="kwh-label">Cents per kWh</span>
                        </div>
                        <bfv-messages></bfv-messages>
                    </div>
                    <p>&nbsp;</p>

                    <div class="form-group">
                        <label class="control-label"><h3 class="form_label title-black">Do you have shoulder rates? </h3></label>
                        <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="Shoulder rates reflects the price for electricity used between peak and off-peak times."></span>
                        <br />
                        <div class="radio-inline">
                            <input type="radio" id="shoulderRateYes" value="1" formControlName="shoulderRate" [(ngModel)]="solarData.shoulderRate" [checked]="solarData.shoulderRate == 1">
                            <label for="shoulderRateYes" class="custom-btn-default custom-btn-text" tabindex="5" (keydown)="selectButton($event, 'shoulderRateYes')">Yes</label>
                            <input type="radio" id="shoulderRateNo" value="0" formControlName="shoulderRate" [(ngModel)]="solarData.shoulderRate" [checked]="solarData.shoulderRate == 0">
                            <label for="shoulderRateNo" class="custom-btn-default custom-btn-text" tabindex="6" (keydown)="selectButton($event, 'shoulderRateNo')">No</label>
                        </div>
                        <bfv-messages></bfv-messages>

                        <div id="shoulder" [hidden]="solarData.shoulderRate != 1">
                            <div class="form-group">
                                <p>&nbsp;</p>
                                <label class="control-label title-black"><h3>Shoulder rate </h3></label>
                                <div class="input-group-prepend">
                                    <input type="number" step="0.1" class="form-control short-input-text" placeholder="e.g. 20" formControlName="shoulderRateValue" [(ngModel)]="solarData.shoulderRateValue" tabindex="7">
                                    <span class="kwh-label">Cents per kWh</span>
                                </div>
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>

            <div class="form-group">
                <label class="control-label"><h3 class="form_label title-black">Your Daily supply charge </h3></label>
                <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="All electricity bills include daily charge. This charge covers the cost of maintenance and improvement to the network that delivers electricity to your home."></span>
                <div class="input-group-prepend">
                    <input type="number" step="0.1" class="form-control short-input-text" placeholder="e.g. 100" [(ngModel)]="solarData.dailySupplyCharge" formControlName="dailySupplyCharge" tabindex="8">
                    <span class="kwh-label">
                        Cents per day
                    </span>
                </div>
                <bfv-messages></bfv-messages>
            </div>
            <p>&nbsp;</p>

            <div class="form-group">
                <label class="control-label"><h3 class="form_label title-black">Do you have any discounts? </h3></label>
                <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="Some retailers offer a cheaper rate if you do certain things. For example, if you pay by direct debit, some retailers will reduce your bill. Other retailers might offer a discount if you pay on time. If your retailer offers a discount on your bill, add it here."></span>
                <br />
                <div class="radio-inline">
                    <input type="radio" id="discountYes" value="1" formControlName="discount" [(ngModel)]="solarData.discount" [checked]="solarData.discount == 1">
                    <label for="discountYes" class="custom-btn-default custom-btn-text" tabindex="9" (keydown)="selectButton($event, 'discountYes')">Yes</label>
                    <input type="radio" id="discountNo" value="0" formControlName="discount" [(ngModel)]="solarData.discount" [checked]="solarData.discount == 0">
                    <label for="discountNo" class="custom-btn-default custom-btn-text" tabindex="10" (keydown)="selectButton($event, 'discountNo')">No</label>
                </div>
                <bfv-messages></bfv-messages>

                <div id="discounts" [hidden]="solarData.discount != 1">
                    <div class="form-group">
                        <p>&nbsp;</p>
                        <label class="control-label"><h3 class="form_label title-black">Discount rate</h3></label>
                        <div class="input-group-prepend">
                            <input type="number" step="0.1" class="form-control short-input-text" placeholder="%" formControlName="discountRateValue" [(ngModel)]="solarData.discountRateValue" tabindex="11">
                            <span class="kwh-label">Rate in %</span>
                        </div>
                        <bfv-messages></bfv-messages>
                        <p>&nbsp;</p>
                    </div>

                    <div class="form-group">
                        <label class="control-label"><h3 class="form_label title-black">Discount applied to</h3></label>
                        <span class="fa fa-info-circle" width="350px" [triggers]="utility.toolTipForMobile()" ngbTooltip="If your retailer offers a discount, please select whether it applies only to your usage or to your total bill."></span>
                        <div class="input-group-prepend">
                            <div class="row custom-padding radio-inline" id="row-discount-usage">
                                <input type="radio" id="usage" value="1" formControlName="usage" [(ngModel)]="solarData.usage" tabindex="12" [checked]="solarData.usage == 1">
                                <label for="usage" class="custom-btn-default custom-btn-text" tabindex="12" (keydown)="selectButton($event, 'usage')">Usage</label>
                                <input type="radio" id="bill" value="0" formControlName="usage" [(ngModel)]="solarData.usage" tabindex="13" [checked]="solarData.usage == 0">
                                <label for="bill" class="custom-btn-default custom-btn-text" tabindex="13" (keydown)="selectButton($event, 'bill')">Total bill</label>
                            </div>
                        </div>
                        <bfv-messages></bfv-messages>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>

            <span class="disclaimer-agreement">Disclaimer agreement</span>
            <p>&nbsp;</p>

            <div class="form-group">
                <div class="checkbox checkbox-primary">
                    <input type="checkbox" id="agree" tabindex="14" formControlName="agree" [(ngModel)]="solarData.agree">
                    <label for="agree" class="disclaimer-label">
                        I have read and agree to the
                        <a (click)="termsModal()" class="link" data-toggle="modal" data-target="#modal-terms" tabindex="15" (keydown)="selectButton($event, 'terms')" onclick="return false">
                        terms and conditions
                        </a>
                    </label>
                </div>
                <bfv-messages></bfv-messages>
            </div>
            <p>&nbsp;</p>
            <button
                name="submit"
                (click)="submitSolarForm()"
                class="custom-btn-default custom-button no-margin no-padding"
                type="submit"
                value="CALCULATE"
                tabindex="16">CALCULATE</button>
        </div>
        <p>&nbsp;</p>
    </form>
</div>
