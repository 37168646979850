import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment'; //config file
import { EmailBrochureModalComponent } from '../email-brochure-modal/email-brochure-modal.component';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-download-data-modal',
  templateUrl: './download-data-modal.component.html',
  styleUrls: ['./download-data-modal.component.css']
})
export class DownloadDataModalComponent implements OnInit {
  public secBranding = environment.secBranding;
  public emailSecurityKey = environment.emailSecurityKey;
  public appURL = environment.appURL;
  public distributor;
  public postcode;
  public distributorInfo = {
    step1 : "",
    step2 : "",
    step3 : "",
    step4 : "",
    step5 : ""
  };
  constructor(
    public activeModal: NgbActiveModal,
    public modalService : NgbModal,
    public dataService : DataService) { }

  ngOnInit() {
    this.distributor = this.dataService.getDistributor(); //pending getting undefined
  }

  public print() {
    window.print();
    GA4LogEvent('/print', 'PRINT');
  }

  public emailDistributorBrochure() {
    let modalRef = this.modalService.open(EmailBrochureModalComponent, {size : 'xs'});
    modalRef.componentInstance.distributor = this.distributor.name;
    modalRef.componentInstance.html = document.getElementById("distributorBrochure").innerHTML;
  }

}
