<div class="card-group" id="solar-compare-table-modal">
    <div class="card card-default">
        <div class="card-header bg-success text-white">
            <div class="card-title title-pad">
                Comparison: <b>Your Results <span class="option"></span></b>
            </div>
            How are these calculated?
        </div>
        <div class="hidden-label">{{ emailSecurityKey }}</div>
        <div class="card-body" *ngIf="solarEstimatesData1.annualSavedTotal != undefined && solarEstimatesData2.annualSavedTotal != undefined && solarEstimatesData3.annualSavedTotal != undefined">
            <table class="container solar-compare-table">
                <tr>
                    <th class="solar-width">
                        <div class="row">
                            <div class="options-modal options-header2-modal">
                                <h4>heading</h4>
                            </div>
                            <div class="options-modal options-sub-header2-modal">
                                <span class="values-pad-modal values-border-modal">
                                    System Size
                                </span>
                                <span class="values-pad-modal">
                                    System Cost
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                    <th>
                        <div class="row">
                            <div class="options-modal options-header-modal">
                                <h4>Option 1</h4>
                            </div>
                            <div class="options-modal options-sub-header-modal">
                                <span class="values-pad-modal">
                                    {{ solarData1.systemSize }}kW
                                </span>
                                <br />
                                <span class="values-pad-modal">
                                    {{ solarData1.systemCost | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                    <th>
                        <div class="row">
                            <div class="options-modal options-header-modal">
                                <h4>Option 2</h4>
                            </div>
                            <div class="options-modal options-sub-header-modal">
                                <span class="values-pad-modal">
                                    {{ solarData2.systemSize }}kW
                                </span>
                                <br />
                                <span class="values-pad-modal">
                                    {{ solarData2.systemCost | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                    <th>
                        <div class="row">
                            <div class="options-modal options-header-modal">
                                <h4>Option 3</h4>
                            </div>
                            <div class="options-modal options-sub-header-modal">
                                <span class="values-pad-modal">
                                    {{ solarData3.systemSize }}kW
                                </span>
                                <br />
                                <span class="values-pad-modal">
                                    {{ solarData3.systemCost | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                </tr>
                <tr>
                    <td class="solar-row-modal">
                        <br />
                        <img src='{{ appURL + "/assets/images/icon-pig.png" }}' class="img-responsive" height="45" alt="" />
                        <br />
                        Time taken to pay for itself
                        <br />
                    </td>
                    <td class="values-modal solar-row-modal">
                        {{ solarCalculator.getPayback(solarData1.systemCost, solarEstimatesData1.annualSavedTotal) | number : '1.0-1' }}
                        <br />
                        years
                    </td>
                    <td class="values-modal solar-row-modal">
                        {{ solarCalculator.getPayback(solarData2.systemCost, solarEstimatesData2.annualSavedTotal) | number : '1.0-1' }}
                        <br />
                        years
                    </td>
                    <td class="values-modal solar-row-modal">
                        {{ solarCalculator.getPayback(solarData3.systemCost, solarEstimatesData3.annualSavedTotal) | number : '1.0-1' }}
                        <br />
                        years
                    </td>
                </tr>
                <tr>
                    <td class="solar-row-modal">
                        <br />
                        <img src='{{ appURL + "/assets/images/dollar.png" }}' class="img-responsive" height="45" alt="" />
                        <br />
                        Your estimated annual electricity bill
                        <br />
                    </td>
                    <td class="values-modal solar-row-modal">
                        {{ solarEstimatesData1.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values-modal solar-row-modal">
                        {{ solarEstimatesData2.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values-modal solar-row-modal">
                        {{ solarEstimatesData3.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        <br />
                        <img src='{{ appURL + "/assets/images/dollar-save.png" }}' class="img-responsive" height="45" alt="" />
                        <br />
                        Your estimated annual <br />
                        savings
                        <br />
                    </td>
                    <td class="values-modal">
                        {{ solarEstimatesData1.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values-modal">
                        {{ solarEstimatesData2.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values-modal">
                        {{ solarEstimatesData3.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div class="modal-header" class="button">
    <div class="row col-md-12">
        <div class="col-md-12 modal-icons">
            <button class="hidden-btn"></button>
            <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close('none')">
                <i class="fa fa-times" aria-hidden="true"></i> close
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <h2>Please select an option</h2>
    <br />
    <div class="row">
        <div class="col-lg-3">
            <div class="radio-inline">
                <input name="option" type="radio" id="option1" value="1">
                <label for="option1" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="1" (keydown)="changeOption(1)" (click)="changeOption(1)">
                    Option 1 {{ (selectedOption == 1) ? '(current)' : '' }}
                </label>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="radio-inline">
                <input name="option" type="radio" id="option2" value="2">
                <label for="option2" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="2" (keydown)="changeOption(2)" (click)="changeOption(2)">
                    Option 2 {{ (selectedOption == 2) ? '(current)' : '' }}
                </label>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="radio-inline">
                <input name="option" type="radio" id="option3" value="3">
                <label for="option3" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="3" (keydown)="changeOption(3)" (click)="changeOption(3)">
                    Option 3 {{ (selectedOption == 3) ? '(current)' : '' }}
                </label>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="radio-inline">
                <input name="option" type="radio" id="option4" value="4">
                <label for="option4" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="4" (keydown)="changeOption(0)" (click)="changeOption(0)">
                    Select All Options
                </label>
            </div>
        </div>
    </div>
</div>
