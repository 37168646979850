import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment'; //config file
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-email-brochure-modal',
  templateUrl: './email-brochure-modal.component.html',
  styleUrls: ['./email-brochure-modal.component.css']
})
export class EmailBrochureModalComponent implements OnInit {
  public html = '';
  public distributor = '';
  public emailSubmitted = false;
  public emailStatus;
  public errorMessage;
  public emailForm : FormGroup;
  public email = '';
  public headerTitle = '';
  public appURL = environment.appURL;
  constructor(
    public router : Router,
    public spinner : NgxSpinnerService,
    public formBuilder : FormBuilder,
    public activeModal: NgbActiveModal,
    public dataService : DataService) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
    });
    this.headerTitle = 'Email ' + this.distributor + ' Brochure';
  }

  public formIsValid() {
    return (this.emailForm.status != 'VALID');
  }

  public selectButton(event) {
    if (event.keyCode == 13) { //keypress enter
      this.sendMail();
    }
  }

  public sendMail() {
    if (this.emailForm.status == 'VALID') {
      let emailHtml;
      //send email here

      emailHtml = '<link href="' + this.appURL + '/assets/css/bootstrap.min.css" rel="stylesheet" media="screen">';
      emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/email.css" />';
      emailHtml += '<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>';
      emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/distributor-email.css" />';
      emailHtml += this.html;

      this.spinner.show();
      this.dataService.sendBrochure(emailHtml, this.email).subscribe(
        result => {
          this.emailSubmitted = true;
          this.emailStatus = result["status"];
          if (this.emailStatus == "error") {
            this.errorMessage = result["message"];
          }
          this.spinner.hide();
        },
        error => {
          this.emailStatus = false;
          this.errorMessage = 'An error has occurred while trying to send the email, please try again later';
          this.spinner.hide();
        }
      );
    }
  }
}
