import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ebr-faqs',
  templateUrl: './ebr-faqs.component.html',
  styleUrls: ['./ebr-faqs.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class EbrFaqsComponent implements OnInit {

  public pageContents;

  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public bannerTitle = "Energy Bill Relief for Embedded Network households";
  public bannerSubTitle = "";
  public bannerDetails = "";

  constructor(
    public dataService : DataService,
    private _sanitizer: DomSanitizer,
    public router : Router,) {

      this.dataService.getEbrFaqs().subscribe(
        result => {
          this.pageContents = this._sanitizer.bypassSecurityTrustHtml(result.toString());
        }, error => {

        }
      );

     }

  ngOnInit(): void {


  }

}
