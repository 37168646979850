import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.css']
})
export class SurveyModalComponent implements OnInit {
  public loadCount = 0;
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public iFrameOnLoad() {
    this.loadCount++;
    if (this.loadCount > 1) {
      localStorage.setItem("survey", "true");
      this.activeModal.close();
    }
  }

}
