import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from '../utility';
import { SolarCalculator } from '../solarCalculator';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { CompareSolarModalComponent } from '../compare-solar-modal/compare-solar-modal.component';

import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-solar-left-panel',
  templateUrl: './solar-left-panel.component.html',
  styleUrls: ['./solar-left-panel.component.css']
})
export class SolarLeftPanelComponent implements OnInit {
  private showModal = true;
  public solarForm : FormGroup;
  //get data from main component (results.component)
  @Input() selectedOption;
  @Input() solarData1 = {};
  @Input() solarData2 = {};
  @Input() solarData3 = {};
  @Input() solarData = {
    nmi : '',
    postcode : '',
    retailer : 0,
    offPeakRate : -1,
    offPeakRateValue : '',
    shoulderRate : -1,
    shoulderRateValue : '',
    discount : -1,
    discountRateValue : '',
    agree : 0,
    peakRateValue : '',
    dailySupplyCharge : '',
    usage : -1,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 12,

    // The scope of discounts. Options include 'usage' and 'bill'
    discountType : '',

    // System size in KW
    systemSize : 0,

    // System cost in dollars
    systemCost : 0,

    fitRates : {},
    tariffs : {}
  };
  @Input() SolarEstimatesData : Object;
  @Input() download;
  @Output() updateEstimates = new EventEmitter();
  @Output() changeOption = new EventEmitter();

  public updateSizePosition() {
    var max = 18;
    var min = 1.5;
    var addSpace = (max - this.solarData.systemSize) * 2;
    addSpace -= 13;
    var percent = (this.solarData.systemSize - min) / max;
    var width = document.getElementById("systemSize").clientWidth;
    var position = (percent * width) + addSpace;
    position = (position < 30) ? 14 : position;
    position = ((width - position) < 37) ? (width - 37) : position;
    return position;
  }

  public getSizePosition() {
    return this.updateSizePosition() + "px";
  }

  public updateCostPosition() {
    var max = 20000;
    var addSpace = (10.5 - (this.solarData.systemCost / 1000)) * 2;

    var percent = this.solarData.systemCost / max;
    var width = document.getElementById("systemCost").clientWidth;
    var position = (percent * width) + addSpace;
    position = (position < 14) ? 14 : position;
    position = ((width - position) < 50) ? (width - 50) : position;
    position = (position == addSpace) ? 14 : position;
    return position;
  }

  public getCostPosition() {
    return this.updateCostPosition() + "px";
  }

  public updateSelectedOption(selectedOption) {
    this.selectedOption = selectedOption;
    this.changeOption.emit(selectedOption);
  }

  public updateEstimate() {
    this.updateEstimates.emit(this.SolarEstimatesData);
  }

  public openInfoModal() {
    if (this.showModal == true) {
      this.solarForm.markAllAsTouched();
      this.modalService.open(InformationModalComponent, { size: 'lg', windowClass:'slideInUp'});
      this.showModal = false;
    }
  }

  constructor(public tooltipConfig : NgbTooltipConfig,
              public dataService : DataService,
              private formBuilder : FormBuilder,
              private spinner : NgxSpinnerService,
              private modalService : NgbModal,
              public utility : Utility,
              private solarCalculator : SolarCalculator) {
                this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
              }

  ngAfterViewInit() {
    this.updateSizePosition();
    this.updateCostPosition();
  }

  ngOnInit() {
    this.createForm();
  }

  public createForm() {
    this.solarForm = this.formBuilder.group({
      peakRateValue : new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      shoulderRateValue : new FormControl('', [
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      offPeakRateValue : new FormControl('', [
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      dailySupplyCharge : new FormControl('', [
        Validators.min(1),
        Validators.max(999),
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      discountRateValue : new FormControl('', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      fitPeak : new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      fitShoulder : new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      fitOffPeak : new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
      ]),
      systemSize : new FormControl('', [
        Validators.required,
      ]),
      systemCost : new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$')
      ])
    });
  }

  public updateSolarEstimates(slider = '') {
    if (this.solarForm.status == 'VALID') {
      if (slider == '') {
        this.solarForm.disable();
      } else if (slider == 'size')  {
        GA4LogEvent('/solar-results', 'SOLAR_SIZE_SLIDER', this.solarData.systemSize);
      } else if (slider == 'cost')  {
        GA4LogEvent('/solar-results', 'SOLAR_COST_SLIDER', this.solarData.systemCost);
      }

      this.utility.setErrorMessage('');
      this.spinner.show();
      this.solarData.fitRates = this.solarCalculator.getFitRates(
        this.solarData.fitPeak,
        this.solarData.fitOffPeak,
        this.solarData.fitShoulder
      );
      this.solarData.tariffs = this.solarCalculator.getTariffs(
                      this.solarData.peakRateValue,
                      this.solarData.offPeakRateValue,
                      this.solarData.shoulderRateValue
                    );
      this.dataService.getSolarEstimates(this.solarData)
      .subscribe(
        data => {
          if (data['status'] == 'error') {
            this.showError();
          } else {
            try {
              //this.SolarEstimatesData = (this.dataService.getSessionId() != '') ? JSON.parse(data['data']) : data['data'];
              this.SolarEstimatesData = data['data'];
              if (this.SolarEstimatesData['consumptionGeneration'] == undefined) {
                this.showError();
              } else {
                this.spinner.hide();
                this.updateEstimate();
                this.solarForm.enable();
                if (this.solarData.systemCost != 6200) {
                  this.openInfoModal();
                }
              }
            } catch (e) {
              this.showError();
            }
          }
        },
        error => {
          this.showError();
        },
      );
    }
  }

  private showError() {
    this.utility.setErrorMessage(" An error has occurred while trying to process the data, please try again later");
    this.utility.scrollToTop("none");
    this.spinner.hide();
    this.solarForm.enable();
  }

  public submitSolarForm() {
    //scroll page to top
    this.utility.scrollToTop("none");
  }

  public compareSolar() {
    let modalRef = this.modalService.open(CompareSolarModalComponent, { size: 'xl', windowClass:'slideInUp'});
    modalRef.componentInstance.solarData1 = this.solarData1;
    modalRef.componentInstance.solarData2 = this.solarData2;
    modalRef.componentInstance.solarData3 = this.solarData3;

    modalRef.result.then((result) => {
      this.updateSolarEstimates();
    }, (reason) => {
      this.updateSolarEstimates();
    });

  }
}
