<div class="container">
    <div class="back-navigation-link">
        <span title="back" aria-label="back" class="anchor-style" (click)="back()"><i class="fa fa-chevron-left"></i> return to previous page</span>
    </div>
    <br />

    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-print-header" *ngIf="secBranding == false">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_940_left.png">
        <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_1140_vec_logo.png">
        <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_1140_vic_logo.png">
    </div>
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-print-header" *ngIf="secBranding == true">
        <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png">
    </div>

    <div class="contact-retailer" id="contact-retailer-details">
        <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-medium-print-header" class="vec-sec" [ngClass]="secBranding == true ? 'vec-sec' : ''">
            <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png" *ngIf="secBranding == true">
            <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_720_left.png" *ngIf="secBranding == false">
            <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_720_vic_logo.png" *ngIf="secBranding == false">
            <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_600_vec_logo.png" *ngIf="secBranding == false">
        </div>

        <div class="hidden-label">{{ emailSecurityKey }}</div>
        <div class="row offer-title-container">
            <div class="col-lg-9 col-xs-12">
                <p class="retailer_name">{{ offer.retailerName }}</p>
                <p class="retailer_offer_name">{{ offer.offerName }} - {{ offer.offerId }}</p>
                <p class="retailer_offer_code"></p>
            </div>
            <div class="col-lg-3 hidden-sm hidden-xs retailer-logo">
                <div class="compare-retailer-logo align-middle-img">
                    <img [attr.title]="offer.retailerName" [attr.alt]="offer.retailerName" [attr.aria-label]="offer.retailerName" src='{{ appURL }}/assets/{{ offer.retailerImageUrl }}' class="align-retailer-logo">
                </div>
            </div>
        </div>

        <div class="row offer-details-container">
            <div class="col-lg-9 col-xs-12 retailer-details-container">
                <div class="row">
                    <div class="col-md-5">
                        <h4 class="heading-n1">Contact the retailer</h4>
                    </div>
                    <div class="col-md-7">
                        <p>
                            <a
                                [attr.title]="offer.retailerName"
                                [attr.aria-label]="offer.retailerName"
                                tabindex="1"
                                href="{{ (offer.retailerUrl.indexOf('http') == -1) ? 'http://' + offer.retailerUrl : offer.retailerUrl }}"
                                target="_blank"
                                class="with-focus link">
                                {{ (offer.retailerUrl.indexOf('http') == -1) ? 'http://' + offer.retailerUrl : offer.retailerUrl }}
                                <i class="fa fa-external-link"></i>
                            </a>
                            <br/>
                            <a
                                title="Retailer contact number"
                                aria-label="Retailer contact number"
                                tabindex="2"
                                href="tel:{{ offer.retailerPhone }}"
                                class="with-focus link">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                                {{ offer.retailerPhone }}
                            </a>
                        </p>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-5">
                        <h4 class="heading-n2">Quote the following information</h4>
                    </div>
                    <div class="col-md-7">
                        <p>
                            <span class="strong">Offer name</span><br/>
                            {{ offer.offerName }}
                        </p><br/>
                        <p>
                            <span class="strong">Offer Id</span><br/>
                            {{ offer.offerId }}<br/>
                        </p><br/>
                        <p>
                            <span class="strong">Postcode</span><br/>
                            {{ postCode }}
                            {{ offer.distributor }}
                            <br/>
                        </p><br/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <h4 class="heading-n3">Questions to ask the retailer</h4>
                    </div>
                    <div class="col-md-7">
                        <ul>
                            <li>Are you getting the same offer recommended by Victorian Energy Compare?</li>
                            <li>How will you be notified of price changes?</li>
                            <li>What are the payment methods and discount conditions?</li>
                            <li *ngIf="vFiT">What are the available options for solar feed-in tariffs?</li>
                        </ul>
                    </div>
                </div>

                <div class="retailer-additional-container">

                    <div class="row">
                        <div class="print-icons col-lg-12">
                            <button
                                title="print retailer offer details"
                                aria-label="print retailer offer details"
                                tabindex="4"
                                type="button"
                                class="btn btn-link"
                                (click)="print()">
                                <i class="fa fa-print"></i>
                                - print retailer offer details
                            </button>
                            <button
                                title="email retailer offer details"
                                aria-label="email retailer offer details"
                                tabindex="3"
                                type="button"
                                class="btn btn-link"
                                id="email-results"
                                (click)="email()">
                                <i class="fa fa-envelope"></i>
                                - email retailer offer details
                            </button>
                        </div>
                    </div>

                    <div class="col-md-12 col-xs-12">
                        <h4 class="offers-title">Additional offer details</h4>
                    </div>
                </div>

                <div class="col-lg-10">
                    <input type="checkbox" class="accordion" id="title1" />
                    <label for="title1">VIEW FULL TERMS AND CONDITIONS</label>
                    <div class="content">
                        <p>
                            {{ (offer.contract.terms_and_conditions == '') ? 'N/A' : offer.contract.terms_and_conditions }}
                        </p>
                    </div>

                    <input type="checkbox" class="accordion" id="title2" />
                    <label for="title2">ELIGIBILITY CRITERIA</label>
                    <div class="content">
                        <p>
                            {{ (offer.contract.offer_eligibility_clause == '') ? 'N/A' : offer.contract.offer_eligibility_clause }}
                        </p>
                    </div>

                    <input type="checkbox" class="accordion" id="title3" />
                    <label for="title3">PRICE CHANGES</label>
                    <div class="content">
                        <p>
                            {{ (offer.contract.offer_var_of_prices == '') ? 'N/A' : offer.contract.offer_var_of_prices }}
                        </p>
                    </div>

                    <input type="checkbox" class="accordion" id="title4" />
                    <label for="title4">EXPIRY DETAILS</label>
                    <div class="content">
                        <p>
                            {{ (offer.contract.offer_arr_on_expiry == '') ? 'N/A' : offer.contract.offer_arr_on_expiry }}
                        </p>
                    </div>

                    <input type="checkbox" class="accordion" id="title5" />
                    <label for="title5">WANT TO KNOW MORE ABOUT THE SWITCHING PROCESS?</label>
                    <div class="content">
                        <h4>How do I switch?</h4>
                        <p>Contact the retailer. They will do the rest.</p>
                        <br />
                        <h4>Does the power get turned off when I switch?</h4>
                        <p>
                            No. Your power does not get turned off when you change
                            contracts or move to a different retailer.
                        </p>
                        <br />
                        <h4>Will it cost me anything to switch?</h4>
                        <p>
                            Check with your retailer. If there are exit fees, they are
                            usually quite low and often less than the savings you might make by
                            switching.
                        </p>
                        <br />
                        <h4>Can I change my mind about my new plan?</h4>
                        <p>
                            Yes. If you change your mind within the cooling off period, you will not pay an exit fee.
                            You will only pay for the power you used. After the cooling off period, you might also
                            have to pay an exit fee.
                        </p>
                        <br />
                        <h4>How long is the cooling off period?</h4>
                        <p>If you contacted the retailer, the cooling off period is at least 5 business days.</p>
                        <br />
                        <p>
                            If the retailer contacted you, the cooling off period is 10 business days after you receive the contract from them.
                        </p>
                        <br />
                        <p>
                            To make yourself fully aware of your consumer rights when choosing a retail offer, please visit the
                            <a class="link" title="Essential Services Commission's website" aria-label="Essential Services Commission's website" href="http://www.esc.vic.gov.au/for-customers/energy-customers/customer-information/about-the-energy-industry/choosing-a-retailer/" target="_blank">Essential Services Commission's website</a>.
                        </p>
                        <br />
                    </div>

                </div>
                <br /><br />
            </div>
            <div class="col-lg-3 col-xs-12 offer-rates-container">
                <div class="offer-rate-header col-md-12">
                    <span class="sub-title">
                        Offer rate and details
                    </span>
                    <br/>
                    <span *ngIf="energyType != 'Gas'">
                        {{ offer.timeDefinition }}
                    </span>
                    <span *ngIf="energyType == 'Gas'">
                        AEST only
                    </span>
                </div>

                <!-- season start-->
                <div *ngFor="let index=index; let season of offer.tariffDetails">
                    <div class="offer-rate-header">
                        <p>
                            {{ season.season_name }}
                        </p>
                        <p class="read-only" *ngIf="season.tariff_applicable_from != '' && season.tariff_applicable_to != ''">
                            {{ utility.convertDate(season.tariff_applicable_from) + " - " + utility.convertDate(season.tariff_applicable_to) }}
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                            <p class="read-only tariff-heading">
                                {{ (offer.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                            </p>
                        </div>
                    </div>

                    <div class="row supply-charge">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            Supply charges
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 value">
                            <p>
                                <span *ngIf="offer.includeGSTRates == true">
                                    {{ ((season.tariff_daily_charge * 1) + (season.tariff_daily_charge * (offer.GSTPercent / 100))) | number : '1.2-2' }}
                                </span>
                                <span *ngIf="offer.includeGSTRates == false">
                                    {{ season.tariff_daily_charge | number : '1.2-2' }}
                                </span>
                                &cent;/day
                            </p>
                        </div>
                    </div>

                    <div *ngFor="let tariff of season.TARIFFS">
                        <div class="row rate-charge" *ngIf="season.TARIFFS.length == 1">
                            <div class="col-md-12">
                                Flat
                            </div>
                        </div>
                        <div class="row rate-charge" *ngIf="season.TARIFFS.length > 1">
                            <div class="col-md-12">
                                {{ (tariff.tariff_type == '' || tariff.tariff_type == null) ? tariff.tariff_name : tariff.tariff_type }}
                            </div>
                        </div>

                        <!--time bands-->
                        <div class="row rate-separator no-border" *ngIf="season.TARIFFS.length > 1">
                            <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                            </div>
                        </div>

                        <!--rates start-->
                        <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                            <div class="row rate-charge rate-separator">
                                <div class="col-md-6 mid-padding season-rate">
                                    <div *ngIf="tariff.RATES.length > 1">
                                        <span *ngIf="rate.desc != 'Balance'">
                                            {{ rate.desc }}
                                            {{ rate.desc_rate_block_consumption | number : '1.0-2' }}
                                            {{ energyType == 'Gas' ? 'MJ' : 'kWh' }} per {{ season.tariff_cons_prd }}
                                        </span>
                                        <span *ngIf="rate.desc == 'Balance'">
                                            {{ rate.desc }}
                                        </span>
                                    </div>
                                    <div *ngIf="tariff.RATES.length == 1">
                                        All consumption
                                    </div>
                                </div>
                                <div class="col-md-6 mid-padding value strong">
                                    <span>
                                        <span *ngIf="offer.includeGSTRates == true">
                                            {{ ((rate.rate_block * 1) + (rate.rate_block * (offer.GSTPercent / 100))) | number : '1.2-2' }}
                                        </span>
                                        <span *ngIf="offer.includeGSTRates == false">
                                            {{ rate.rate_block | number : '1.2-2' }}
                                        </span>
                                        {{ energyType == 'Gas' ? '¢/MJ' : '¢/kWh' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <!--rates end-->

                        <!--demand start-->
                        <div *ngIf="season.DEMAND !== undefined" class="demand">
                            <br />
                            <div class="row">
                                <div class="col-md-12">
                                    {{ (fileUpload == true || getMyData == true) ? 'Demand Charge' : 'Demand Charge (not included in estimate)'}}
                                </div>
                            </div>
                            <!--demand days-->
                            <div class="row" *ngIf="season.DEMAND.tariff_weekdays == true">
                                <div class="col-md-12 season-rate">
                                    Weekdays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <div class="row" *ngIf="season.DEMAND.tariff_sat == true && season.DEMAND.tariff_sun == true">
                                <div class="col-md-12 season-rate">
                                    Weekends {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <div class="row" *ngIf="season.DEMAND.tariff_sat == true && season.DEMAND.tariff_sun == false">
                                <div class="col-md-12 season-rate">
                                    Saturdays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <div class="row" *ngIf="season.DEMAND.tariff_sat == false && season.DEMAND.tariff_sun == true">
                                <div class="col-md-12 season-rate">
                                    Sundays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <!--demand days-->

                            <div class="row bottom-margin">
                                <div class="col-md-6 season-rate">
                                    Charge
                                </div>
                                <div class="col-md-6 value strong">
                                    {{ season.DEMAND.demand_charge + (season.DEMAND.demand_charge * (offer.GSTPercent / 100)) | number : '1.2-2' }}
                                    {{ energyType == 'Gas' ? '¢/MJ' : '¢/kWh' }} per
                                    {{ season.DEMAND.demand_charge_period | lowercase }}
                                </div>
                            </div>
                            <div class="row rate-line-border rate-line-border-top demand-padding">
                                <div class="col-md-12 no-padding">
                                    {{ (fileUpload == true || getMyData == true) ? 'You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.' : 'This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.' }}
                                </div>
                            </div>
                            <br />
                        </div>
                        <!--demand end-->
                    </div>
                </div>
                <!-- season end -->
                <!--controll load start-->
                <div *ngIf="offer.controlledLoadRate > 0">
                    <div class="row">
                        <div class="col-md-12 offer-rate-header">
                            Controlled Load
                            <br />
                            1 January - 31 December
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                            <p class="read-only tariff-heading">
                                {{ (offer.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                            </p>
                        </div>
                    </div>
                    <div class="row bottom-margin rate-separator rate-charge">
                        <div class="col-md-6 season-rate no-padding">
                            All consumption
                        </div>
                        <div class="col-md-6 value strong no-padding">
                            <span *ngIf="offer.includeGSTRates == true">
                                {{ ((offer.controlledLoadRate * 1) + (offer.controlledLoadRate * (offer.GSTPercent / 100))) | number : '1.2-2' }}
                            </span>
                            <span *ngIf="offer.includeGSTRates == false">
                                {{ offer.controlledLoadRate | number : '1.2-2' }}
                            </span>
                            ¢/kWh
                        </div>
                    </div>
                    <br /><br />
                </div>
                <!--controll load end-->

                <!-- greenpower start -->
                <div *ngIf="offer.greenPowerDisplay != false">
                    <div class="row col-md-12 offer-rate-header">
                        Green power
                    </div>
                    <div class="read-only no-padding col-md-12" *ngIf="offer.intrinsicGreenPowerDisplay != 0">
                        Annual total includes {{ offer.intrinsicGreenPowerDisplay }}% green power included in the offer rates
                        <br />
                    </div>
                    <div class="read-only no-padding col-md-12" *ngIf="offer.greenPower !== 0">
                        Annual total includes {{ offer.greenPower }}%
                        GreenPower option
                        <br />
                    </div>

                    <div class="row solar bottom-margin rate-separator rate-charge" *ngIf="offer.greenPower !== 0">
                        <div class="col-md-6 col-sm-6 col-xs-6 season-rate no-padding">
                            Charge
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 solar-value strong no-padding">
                            {{ offer.greenpowerAmount * 100 | number : '1.2-2' }}
                            <span *ngIf="offer.greenpowerChargeType == 'Amount per unit of consumption'" class="no-padding">
                                &cent;/kWh
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Weekly charge'" class="no-padding">
                                &cent;/Week
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Daily charge'" class="no-padding">
                                &cent;/Day
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Percentage of consumption'" class="no-padding">
                                &cent;/% of your total bill amount
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Percentage of bill amount'" class="no-padding">
                                &cent;/% of your consumption cost amount
                            </span>
                        </div>
                    </div>

                    <br /><br />
                </div>
                <!-- greenpower end -->

                <!-- solar -->
                <!-- solar -->
                <div *ngIf="offer.solar.length > 0">
                  <div *ngFor="let solar of offer.solar">

                    <!-- Current and Premium FiT -->
                    <div *ngIf="solar.rate">
                      <div class="col-md-12 offer-rate-header">
                        {{ solar.feed_in_tariff }}
                        <br />
                        1 January - 31 December
                      </div>
                      <div class="row bottom-margin rate-separator rate-charge">
                        <div class="col-md-6 season-rate no-padding">
                          All export
                        </div>
                        <div class="col-md-6 value strong no-padding">
                          {{ solar.rate | number : '1.2-2' }} ¢/kWh
                        </div>
                      </div>
                      <br /><br />
                    </div>

                    <!-- Solar variable FiT: Option 1 & 2 -->
                    <div *ngIf="solar.name === 'Variable FiT - Option 1' || solar.name === 'Variable FiT - Option 2'">
                      <div class="col-md-12 offer-rate-header">
                        {{ solar.name }}
                        <br />
                      </div>
                      <div *ngFor="let tariff of solar.TARIFFS">
                        <br />
                        {{tariff.tariff_type}}

                        <!--time bands-->
                        <div class="row rate-separator no-border" *ngIf="tariff.TIME_BANDS.length >= 1">
                          <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                          </div>
                        </div>
                        <div *ngIf="tariff.RATES.length > 1">
                          <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                            <div class="row rate-charge rate-separator">
                              <div class="col-md-7 mid-padding season-rate">
                                <div *ngIf="tariff.RATES.length > 1">
                                  <span *ngIf="rate.desc != 'Balance'">
                                    {{ rate.desc }} {{ rate.desc_rate_block_export
                                    | number : '1.0-2' }} kWh / {{solar.period}}
                                  </span>
                                  <span *ngIf="rate.desc == 'Balance'">
                                    {{ rate.desc }} / {{solar.period}}
                                  </span>
                                </div>
                                <div *ngIf="tariff.RATES.length == 1">All export</div>
                              </div>
                              <div class="col-md-5 mid-padding value strong">
                                <span>
                                  {{ rate.rate_block | number : '1.2-2' }} ¢/kWh
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="tariff.RATES.length == 1" class="row bottom-margin rate-separator rate-charge">
                          <div class="col-md-8 season-rate no-padding">
                            All export
                          </div>
                          <div class="col-md-4 value strong no-padding">
                            {{ tariff.RATES[0]["rate_block"] | number : '1.2-2' }} ¢/kWh
                          </div>
                        </div>

                      </div>
                      <br /><br />
                    </div>

                    <!-- Solar variable FiT: Custom with tiers -->
                    <div *ngIf="!solar.rate && (solar.feed_in_tariff === 'Current FIT policy' || solar.feed_in_tariff === 'Premium FIT [closed]') ">
                      <div class="col-md-12 offer-rate-header">
                        {{ solar.feed_in_tariff }}
                        <br />
                      </div>
                      <div *ngFor="let tariff of solar.TARIFFS">
                        <br />
                        {{tariff.tariff_type}}

                        <!--time bands-->
                        <div class="row rate-separator no-border" *ngIf="tariff.TIME_BANDS?.length >= 1">
                          <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                          </div>
                        </div>

                        <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                          <div class="row rate-charge rate-separator">
                            <div class="col-md-7 mid-padding season-rate">
                              <div *ngIf="tariff.RATES.length > 1">
                                <span *ngIf="rate.desc != 'Balance'">
                                  {{ rate.desc }} {{ rate.desc_rate_block_export
                                  | number : '1.0-2' }} kWh / {{solar.period}}
                                </span>
                                <span *ngIf="rate.desc == 'Balance'">
                                  {{ rate.desc }} / {{solar.period}}
                                </span>
                              </div>
                              <div *ngIf="tariff.RATES.length == 1">All export</div>
                            </div>
                            <div class="col-md-5 mid-padding value strong">
                              <span>
                                {{ rate.rate_block | number : '1.2-2' }} ¢/kWh
                              </span>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>

                  </div>
                </div>
                <!-- solar end -->

            </div>
        </div>
    </div>
</div>
