<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <img
                class="hottopic-image"
                src="assets/images/{{ secImageURL }}contentpages/hottopics_header_5.jpg"
                alt="Saving as a senior: how to reduce my energy bill?">
            <p>
                Older people have been around long enough to remember when energy was really cheap. So the recent price rises can hurt,
                especially if you're on a fixed income that's not going up at the same rate as energy bills.
            </p>
            <p>
                It might be even worse if you've stuck with the same energy company for a few years now without comparing offers; it's very
                likely there are better deals available out there if you know where to look. As a senior there's lots you can do to save on
                energy bills: here's three ways to take charge.
            </p>
            <ol>
                <li>
                    <span class="strong">
                        Compare electricity and gas providers to find the cheapest offer for you.
                    </span>
                    <p>
                        We know 7 out of 10 Victorian households are paying too much, and if you've had kids moving out in recent years, your
                        drop in energy consumption might mean you're no longer on the best deal for your circumstances. Rather than low usage
                        rates, looking for a lower daily service charge might be what's best for you now. The Victorian Government's independent
                        website, Victorian Energy Compare can work all this out for you, so you can find you the best deal for your household.
                        Grab your latest energy bill and head to <a href="/">compare.energy.vic.gov.au</a>.
                    </p>
                </li>
                <li>
                    <span class="strong">
                        Save money on energy use with a concession card.
                    </span>
                    <p>
                        The Victorian State Government offer a variety of concessions and benefits to eligible cardholders to assist with energy bills.
                        If you have recently downsized, or your circumstances have changed for any reason and concessions aren't transferred, this
                        could mean you are paying far more than you should be. If you have a concession card but have experienced recent changes,
                        ensure your card is still linked to your account. If you do not yet have a concession card visit the Department of Health and
                        Human Services website to find out if you are eligible for one to assist with energy expenses.
                    </p>
                </li>
                <li>
                    <span class="strong">
                        Reach out for help if you have concerns.
                    </span>
                    <p>
                        If there is something not right about your energy bill, and your company hasn't helped, you can reach out to the free and independent
                        energy ombudsman for advice. The Energy and Water Ombudsmen of Victoria (EWOV) provides impartial dispute resolution services and
                        handles most complaints about energy and water issues.
                    </p>
                </li>
            </ol>
            <p>
                It's easy to take charge of your energy use if you know what to do! Head to our independent&nbsp;comparison website today and make
                sure you're saving as a senior.
            </p>
            <br />
            <p>
                <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
            </p>
        </div>
    </div>
</div>
<br /><br />
