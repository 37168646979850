import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { environment } from '../../environments/environment'; //config file
import { DataService } from '../data.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ebr-modal',
  templateUrl: './ebr-modal.component.html',
  styleUrls: ['./ebr-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EbrModalComponent implements OnInit {
  public appURL = environment.appURL;
  public pageContents;

  constructor(
    public activeModal: NgbActiveModal,
    public dataService : DataService,
    private _sanitizer: DomSanitizer,
    public router : Router,) {

      this.dataService.getEbrFaqs().subscribe(
        result => {
          this.pageContents = this._sanitizer.bypassSecurityTrustHtml(result.toString());
        }, error => {

        }
      );

     }

  ngOnInit() {



  }

}
