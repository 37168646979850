import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';
import { environment } from '../../environments/environment'; //config file
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Utility } from '../utility';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-rate-details-modal',
  templateUrl: './rate-details-modal.component.html',
  styleUrls: ['./rate-details-modal.component.css']
})
export class RateDetailsModalComponent implements OnInit {
  public retailersSelection = false;
  public retailers;
  public retailersLoading = false;
  private oneDay = 86400000;
  public appURL = environment.appURL;
  //Peak / flat rate
  public billRateMin = 0;
  public billRateOffPeakMin = 0;
  public billRateMax = 0;
  //Feed in tariff rates // Solar
  public fitRateMin = 0;
  public fitRateMax = 0;
  // Control load charge
  public controllLoadMin = 0;
  public controllLoadMax = 0;
  // supply charge electricity
  public dailyChargeUnitMin = 0;
  public dailyChargeUnitMax = 0;
  // supply charge gas
  public dailyGasChargeUnitMin = 0;
  public dailyGasChargeUnitMax = 0;

  public peakRateMin = 0;
  public peakRateMax = 0;

  public solarFitRateMin = 0;
  public solarFitRateMax = 0;

  public controlledLoadRateMin = 0;
  public controlledLoadRateMax = 0;

  public offPeakRateMin = 0;
  public offPeakRateMax = 0;

  public shoulderRateMin = 0;
  public shoulderRateMax = 0;

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public isMobile = false;
  public energyConfigForm = {};
  public supplyDays = 0;
  public PDFUploadError = '';
  public manualEntryError = '';
  public billRates = {
    "offerType" : '',
    "retailerImageUrl" : '',
    "retailerName" : '',
    "gasRetailerImageUrl" : '',
    "gasRetailerName" : '',
    "totalConditional" : 0,
    "totalUnconditional" : 0,
    "components" : {
      "solar" : 0,
      "controlledLoad" : 0
    }
  };

  public retailerDetails : any = {
    "retailerSettings" : {
      'retailer_bill_daily_charge_cost_unit_min_res' : 0,
      'retailer_bill_daily_charge_cost_unit_max_res' : 0,
      'retailer_bill_daily_charge_cost_unit_min_smb' : 0,
      'retailer_bill_daily_charge_cost_unit_max_smb' : 0,

      'retailer_bill_flat_rate_cost_unit' : '',
      'retailer_bill_peak_rate_cost_unit' : '',
      'retailer_bill_offpeak_rate_cost_unit' : '',
      'retailer_bill_shoulder_rate_cost_unit' : '',
      'retailer_bill_controlled_load_rate_cost_unit' : '',

    }
  };

  public energyType;
  public userType;
  public loopBack = false;
  public controlledLoad = false;
  public billRatesForm = {
    loopBack : false,
    energyType : "",
    hasSolar : false,
    feedInTariff: "",
    billStartDate: "",
    billEndDate: "",
    retailer: "",
    retailerName: "",
    totalCharge: "",
    offPeak: "",
    offPeakRate: "",
    controlledLoadRate: "",
    peakRate: "",
    solarFitRate: "",
    shoulder: "",
    shoulderRate: "",
    supplyCharge: "",
    discount: "",
    discountAmount: "",
    discountType: "",
    rateUnit : '$',
    supplyRateUnit : '$',
    serverCacheId: ""
  };

  private subscription : Subscription;
  public userData = {
    loopBack : false,
    energyType : "",
    hasSolar : false,
    feedInTariff: "",
    billStartDate: "",
    billEndDate: "",
    retailer: "",
    retailerName: "",
    totalCharge: "",
    offPeak: "",
    offPeakRate: "",
    controlledLoadRate: "",
    peakRate: "",
    solarFitRate: "",
    shoulder: "",
    shoulderRate: "",
    supplyCharge: "",
    discount: "",
    discountAmount: "",
    discountType: "",
    rateUnit : '$',
    supplyRateUnit : '$',
    serverCacheId: ""
  }
  public fileName = '';
  public rateForm : FormGroup;
  public currentPage = 'main';
  public showTermsAndCondition = false;
  public minDate = {};
  public maxDate = {};
  public currentDate;

  constructor(private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    public router : Router,
    private calendar: NgbCalendar,
    public utility : Utility,
    public formatter: NgbDateParserFormatter,
    public dataService : DataService,
    public spinner : NgxSpinnerService,
    public formBuilder : FormBuilder,
    public activeModal : NgbActiveModal,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
    }

  ngOnInit() {
    this.currentDate = new Date();
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 2,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };

    this.createForm();
    this.isMobile = this.deviceService.isMobile();
    var serverCacheId = localStorage.getItem("serverCacheId");
    this.userData.serverCacheId = serverCacheId;
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.spinner.show();
      //this.userData.serverCacheId = serverCacheId;
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.energyConfigData.hasOwnProperty('energyType') == false) {
            this.spinner.hide();
            window.location.reload();
          }

          let resultsData;
          this.controlledLoad = results.hasControlledLoad;
          this.userData.hasSolar = results.hasSolar;
          if (this.router.url.indexOf(results.energyConfigData.energyType.toLowerCase()) != -1) {
            this.energyConfigForm = results.energyConfigData;
            resultsData = results.energyConfigData;
          } else if (results.loopBackData.energyConfigData.energyType != undefined && this.router.url.indexOf(results.loopBackData.energyConfigData.energyType.toLowerCase()) != -1) {
            this.energyConfigForm = results.loopBackData.energyConfigData;
            resultsData = results.loopBackData.energyConfigData;
          } else {
            this.energyConfigForm = results.energyConfigData;
            resultsData = results.energyConfigData;
          }
          this.userType = resultsData.userType;

          if (this.loopBack == true) {
            this.billRatesForm = (results.loopBackData.billRatesForm == undefined) ? [] : results.loopBackData.billRatesForm;
          } else {
            this.billRatesForm = (results.billRatesForm == undefined) ? [] : results.billRatesForm;
          }
          if (this.billRatesForm.billStartDate != undefined) {
            this.userData = this.billRatesForm;
            this.energyConfigForm['retailerName'] = (this.billRatesForm.retailerName != '') ? this.billRatesForm.retailerName : '';
          } else {
            this.userData.energyType = this.energyType;
            this.userData.retailer = resultsData.retailer;
            this.userData.retailerName = resultsData.retailerName;
            this.userData.feedInTariff = resultsData.feedInTariff;
            if (this.energyType == 'Gas') {
              if (results.billDetailsData.gasBillStartDate != '' && results.billDetailsData.gasBillStartDate != '') {
                if (this.loopBack == true) {
                  this.userData.billStartDate = (results.loopBackData.billDetailsData.gasBillStartDate == undefined) ? '' : results.loopBackData.billDetailsData.gasBillStartDate;
                  this.userData.billEndDate = (results.loopBackData.billDetailsData.gasBillEndDate == undefined) ? '' : results.loopBackData.billDetailsData.gasBillEndDate;
                } else {
                  this.userData.billStartDate = (results.billDetailsData.gasBillStartDate == undefined) ? '' : results.billDetailsData.gasBillStartDate;
                  this.userData.billEndDate = (results.billDetailsData.gasBillEndDate == undefined) ? '' : results.billDetailsData.gasBillEndDate;
                }
              }
            } else {
              if (results.billDetailsData.electricityBillStartDate != '' && results.billDetailsData.electricityBillEndDate != '') {
                if (this.loopBack == true) {
                  this.userData.billStartDate = (results.loopBackData.billDetailsData.electricityBillStartDate == undefined) ? '' : results.loopBackData.billDetailsData.electricityBillStartDate;
                  this.userData.billEndDate = (results.loopBackData.billDetailsData.electricityBillEndDate == undefined) ? '' : results.loopBackData.billDetailsData.electricityBillEndDate;
                } else {
                  this.userData.billStartDate = (results.billDetailsData.electricityBillStartDate == undefined) ? '' : results.billDetailsData.electricityBillStartDate;
                  this.userData.billEndDate = (results.billDetailsData.electricityBillEndDate == undefined) ? '' : results.billDetailsData.electricityBillEndDate;
                }
              }
            }
            this.userData.loopBack = this.loopBack;
          }
          this.getSupplyDays();
          if (this.userData.retailer == null) {
            this.getRetailers(this.userData.energyType);
            this.retailersSelection = true;
          }

          this.getRetailerSettings(this.userData.retailer);
        },
        error => {
          this.spinner.hide();
        }
      );
    } else {
      window.location.reload();
    }
  }


  private updateValidation() {
      //reset form
      if (this.energyType == 'Gas') {
        var totalChargeValidation = <FormControl>this.rateForm.get('totalCharge');
        totalChargeValidation.setValidators(
          [
            Validators.required,
            this.checkIfNumber.bind(this)
          ]
        );
        totalChargeValidation.updateValueAndValidity();

        var offPeakValidation = <FormControl>this.rateForm.get('offPeak');
        offPeakValidation.setValidators([]);
        offPeakValidation.updateValueAndValidity();

      } else {
        this.peakRateMin = (this.retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit == 'cents') ? 1 : 0.01;
        this.peakRateMax = (this.retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit == 'cents') ? 100 : 1;

        this.solarFitRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 1 : 0.01;
        this.solarFitRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 100 : 1;

        this.controlledLoadRateMin = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == 'cents') ? 1 : 0.01;
        this.controlledLoadRateMax = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == 'cents') ? 100 : 1;

        var offPeakValidation = <FormControl>this.rateForm.get('offPeak');
        offPeakValidation.setValidators(
          [
            Validators.required,
            Validators.pattern('^[01]+$')
          ]
        );
        offPeakValidation.updateValueAndValidity();

        var peakRateValidation = <FormControl>this.rateForm.get('peakRate');
        peakRateValidation.setValidators(
          [
            Validators.required,
            Validators.min(this.peakRateMin),
            Validators.max(this.peakRateMax),
            this.checkIfNumber.bind(this)
          ]
        );
        peakRateValidation.updateValueAndValidity();
        if (this.userData.hasSolar == true) {
          var solarFitRateValidation = <FormControl>this.rateForm.get('solarFitRate');
          solarFitRateValidation.setValidators(
            [
              Validators.required,
              Validators.min(this.solarFitRateMin),
              Validators.max(this.solarFitRateMax),
              this.checkIfNumber.bind(this)
            ]
          );
          solarFitRateValidation.updateValueAndValidity();
        }

        if (this.controlledLoad == true) {
          var controlledLoadRateValidation = <FormControl>this.rateForm.get('controlledLoadRate');
          controlledLoadRateValidation.setValidators(
            [
              Validators.required,
              Validators.min(this.controlledLoadRateMin),
              Validators.max(this.controlledLoadRateMax),
              this.checkIfNumber.bind(this)
            ]
          );
          controlledLoadRateValidation.updateValueAndValidity();
        }

      }
  }

  public selectButton(event, name) {
    if (event.keyCode == 32) { //spacebar
      if (name == "offPeakYes") {
        this.userData.offPeak = '1';
      } else if (name == "offPeakNo") {
        this.userData.offPeak = '0';
      } else if (name == "shoulderRateYes") {
        this.userData.shoulder = '1';
      } else if (name == "shoulderRateNo") {
        this.userData.shoulder = '0';
      } else if (name == "elecDiscountYes") {
        this.userData.discount = '1';
      } else if (name == "elecDiscountNo") {
        this.userData.discount = '0';
      } else if (name == "usage") {
        this.userData.discountType = 'usage';
      } else if (name == "bill") {
        this.userData.discountType = 'bill';
      }
    }

    if (event.keyCode == 13) { //enter
      if (name == "textInput") {
        this.submitRates();
      }
    }
  }

  public validateDate(obj, userData) {
    var dateFrom = new Date(userData.billStartDate).getTime() / 1000;
    var dateTo =  new Date(userData.billEndDate).getTime() / 1000;
    if (dateTo <= dateFrom) {
      return { 'pattern' : true };
    } else {
      return null;
    }
  }

  public format(date): string {
    return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
  }

  public isDisabled(date: NgbDate) {
    let unixDateValue = new Date(date.year, date.month, date.day).getTime() / 1000;
    let dateStartLimit = new Date(this.currentDate.getFullYear() - 2, this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
    let dateEndLimit = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
    if (unixDateValue > dateEndLimit || unixDateValue < dateStartLimit) {
      return true;
    } else {
      return false;
    }
  }

  public onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.userData.billStartDate = this.format(date);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.userData.billEndDate = this.format(date);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.userData.billStartDate = this.format(date);
      this.userData.billEndDate = '';
    }

    if (this.fromDate != null && this.toDate != null) {
      let date1 : any = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      let date2 : any = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      this.supplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.supplyDays = (this.supplyDays < 0) ? 0 : this.supplyDays + 1;
      datepicker.close();
    }
  }

  public getSupplyDays() {
    if (this.userData.billStartDate != '' && this.userData.billEndDate != '') {
      let billStartDate = [];
      let billEndDate = [];
      billStartDate = this.userData.billStartDate.split("/");
      billEndDate = this.userData.billEndDate.split("/");
      let date1 : any = new Date(billStartDate[2], billStartDate[1] - 1, billStartDate[0]);
      let date2 : any = new Date(billEndDate[2], billEndDate[1] - 1, billEndDate[0]);
      this.supplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.supplyDays = (this.supplyDays < 0) ? 0 : this.supplyDays + 1;
    } else {
      this.supplyDays = 0;
    }
  }

  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public validateInput(currentValue : NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public changePage(page) {
    this.PDFUploadError = '';
    this.manualEntryError = '';
    this.fileName = '';
    this.currentPage = page;
    this.showTermsAndCondition = false;
    if (page == 'manual') {
      this.createForm();
      this.updateValidation();
    }
  }

  public toggleTermsAndCondition() {
    this.showTermsAndCondition = !this.showTermsAndCondition;
  }

  public createForm() {
    this.rateForm = this.formBuilder.group({
      billStartDate: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$'),
      ]),
      billEndDate: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$'),
      ]),
      retailer: new FormControl('', [Validators.required]),
      totalCharge: new FormControl(''),
      solarFitRate: new FormControl(''),
      offPeak: new FormControl(''),
      offPeakRate: new FormControl(''),
      peakRate: new FormControl(''),
      controlledLoadRate: new FormControl(''),
      supplyCharge: new FormControl('', [Validators.required]),
      shoulder: new FormControl(''),
      shoulderRate: new FormControl(''),
      discount: new FormControl('', [Validators.required, Validators.pattern('^[01]+$')]), //allow 1 or 0 only
      discountAmount: new FormControl(''),
      discountType: new FormControl(''),
    });

    /*
    * custom validation for fields
    * validation rules based on other fields
    * if a field gets selected then some other field will have validation
    */
    var offPeakValidation = <FormControl>this.rateForm.get('offPeak');
    var offPeakRateValidation = <FormControl>this.rateForm.get('offPeakRate');
    this.subscription = offPeakValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        this.offPeakRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 1 : 0;
        this.offPeakRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 100 : 1;

        shoulderValidation.setValidators([Validators.required]);
        offPeakRateValidation.setValidators(
          [
            Validators.required,
            Validators.min(this.offPeakRateMin),
            Validators.max(this.offPeakRateMax),
            this.checkIfNumber.bind(this)
          ]
        );
      } else {
        shoulderValidation.setValidators(null);
        offPeakRateValidation.setValidators(null);
        //this.userData.offPeakRate = '';
        offPeakRateValidation.updateValueAndValidity();
        shoulderValidation.updateValueAndValidity();
      }
    });
    var discountValidation = <FormControl>this.rateForm.get('discount');
    var discountAmountValidation = <FormControl>this.rateForm.get('discountAmount');
    var discountTypeValidation = <FormControl>this.rateForm.get('discountType');
    this.subscription = discountValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        discountTypeValidation.setValidators([Validators.required]);
        discountAmountValidation.setValidators(
          [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            this.checkIfNumber.bind(this)
          ]
        );
        discountAmountValidation.updateValueAndValidity();
        discountTypeValidation.updateValueAndValidity();
      } else {
        discountAmountValidation.setValidators(null);
        discountTypeValidation.setValidators(null);
        this.userData.discountAmount = '';
        discountAmountValidation.updateValueAndValidity();
        discountTypeValidation.updateValueAndValidity();
      }
    });
    var shoulderValidation = <FormControl>this.rateForm.get('shoulder');
    var shoulderRateValidation = <FormControl>this.rateForm.get('shoulderRate');
    this.subscription = shoulderValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        this.shoulderRateMin = (this.retailerDetails.retailerSettings.retailer_bill_shoulder_rate_cost_unit == 'cents') ? 1 : 0.01;
        this.shoulderRateMax = (this.retailerDetails.retailerSettings.retailer_bill_shoulder_rate_cost_unit == 'cents') ? 100 : 1;

        shoulderRateValidation.setValidators(
          [
            Validators.required,
            Validators.min(this.shoulderRateMin),
            Validators.max(this.shoulderRateMax),
            this.checkIfNumber.bind(this)
          ]
        );
      } else {
        shoulderRateValidation.setValidators(null);
        //this.userData.shoulderRate = '';
        shoulderRateValidation.updateValueAndValidity();
      }
    });
  }

  public submitRates() {
    if (this.rateForm.status == 'VALID' && this.totalChargeFinalValidation() == true) {
      this.spinner.show();
      this.dataService.getBillRates(this.userData, this.supplyDays).subscribe(
        data => {
          let status = data["status"];
          this.spinner.hide();
          if (status == "success") {
            this.billRates = data["offers"][0]["results"]["result"];
            this.activeModal.close(this.billRates);
          } else {
            this.manualEntryError = data["message"];
            setTimeout(()=> {
              document.getElementById("startDate").focus();
            }, 0);
            if (status == 'redirect') {
              window.location.reload();
            }
          }
        },
        error => {
          this.spinner.hide();
        }
      );
    }
  }

  public uploadFileConsumption(files: FileList) {
    this.fileName = files.item(0).name;
    this.spinner.show();
    this.dataService.uploadPDFConsumptionFile(files.item(0), this.energyType, this.loopBack).subscribe(
      data => {
        let status = data["status"];
        this.spinner.hide();
        if (status == "success") {
          this.billRates = data["offers"][0]["results"]["result"];
          this.activeModal.close(this.billRates);
        } else {
          this.PDFUploadError = data["message"];
          if (status == 'redirect') {
            window.location.reload();
          }
        }
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public clearOffPeakRate() {
    this.userData.offPeakRate = '';
  }

  public clearShoulderRate() {
    this.userData.shoulderRate = '';
  }

  private totalChargeFinalValidation() {
    if (this.energyType != 'Gas') {
      return true;
    }

    if (Number(this.userData.totalCharge) <= Number(this.userData.supplyCharge)) {
      return false;
    }
    return true;
  }

  public convertToNumber(data) {
    return Number(data);
  }

  public checkIfNumber(control : FormControl) {
    var numberValue = control.value;
    if (isNaN(numberValue)) {
      return { 'pattern' : true };
    }
    return null; //valid
  }

  public getRetailers(selectedEnergyType) {
    selectedEnergyType = (selectedEnergyType == 'Solar') ? 'Electricity' : selectedEnergyType;
    this.retailersLoading = true;
    this.dataService.getAPIRetailers(selectedEnergyType).subscribe(
      retailers => {
        retailers = retailers["data"];
        this.retailers = retailers; //get data object from rest api
        this.retailersLoading = false;
      },
      error => {
        this.retailersLoading = false;
      }
    );
  }

  public setRetailerName() {
    this.userData.retailerName = document.getElementsByClassName("ng-value-label")[0].innerHTML;
    this.billRatesForm.retailerName = document.getElementsByClassName("ng-value-label")[0].innerHTML;
    this.getRetailerSettings(this.userData.retailer);
  }

  public getRetailerSettings(retailer) {
    //get retailer Settings
    this.dataService.getRetailerSettings(retailer).subscribe(
      data => {
        this.retailerDetails = data;
        this.spinner.hide();
        if (this.userType == 'Residential') {
          this.dailyChargeUnitMin = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_res : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_res * 100;
          this.dailyChargeUnitMax = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_res : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_res * 100;

          this.dailyGasChargeUnitMin = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_res : this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_res * 100;
          this.dailyGasChargeUnitMax = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_res : this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_res * 100;
        } else {
          this.dailyChargeUnitMin = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_smb : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_smb * 100;
          this.dailyChargeUnitMax = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_smb : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_smb * 100;

          this.dailyGasChargeUnitMin = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_smb : this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_smb * 100;
          this.dailyGasChargeUnitMax = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_smb : this.retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_smb * 100;
        }

        this.controllLoadMin = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_min : this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_min * 100;
        this.controllLoadMax = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_max : this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_max * 100;

        this.billRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_min : this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_min * 100;
        this.billRateOffPeakMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_rate_off_peak_cost_unit_min : this.retailerDetails.retailerSettings.retailer_bill_rate_off_peak_cost_unit_min * 100;
        this.billRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_max : this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_max * 100;

        this.fitRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_min : this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_min * 100;
        this.fitRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_max : this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_max * 100;

        this.userData.rateUnit = this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit;
        this.userData.supplyRateUnit = this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit;
        this.updateValidation();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public startsWithSearchFn(item, term) {
    return term.title.toLowerCase().startsWith(item.toLowerCase());
  }

}
