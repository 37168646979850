import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { Utility } from '../utility';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment'; //config file
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public secBranding = environment.secBranding;
  public emailSecurityKey = environment.emailSecurityKey;
  public offer;
  public energyType;
  public retailers;
  public panelOpenState = false;
  public distributor = { id: "", name: "" };
  public postCode = '';
  public gst = environment.gst;
  public appURL = environment.appURL;
  public bannerTitle = "Ready to contact the retailer?";
  public bannerSubTitle = "I've decided to switch. What do I do next?";
  public bannerDetails = "You'll find all the important information you need to switch to a new retailer on this page.";
  public fileUpload = false;
  public getMyData = false;
  public vFiT = false;

  constructor(
    public modalService : NgbModal,
    public utility : Utility,
    public router : Router,
    public dataService : DataService,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
    }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Results - Victorian Energy Compare';
    this.modalService.dismissAll();
    var serverCacheId = localStorage.getItem("serverCacheId");
    this.postCode = this.dataService.getPostCode();
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.energyType = this.dataService.getSelectedEnergyType();
      this.offer = this.dataService.getSelectedOffer();
      if (Object.keys(this.offer).length < 1) {
        this.router.navigate(['/offers']);
      } else {
        this.distributor = this.dataService.getDistributor();
        this.getRetailers();
        this.hasSolarVFiT();
      }

      this.dataService.getSavedData(serverCacheId).subscribe(
        data => {
          if (data.existing == false) {
            this.utility.sessionRestart();
            this.utility.redirectToHome();
            return false;
          }

          //log journey progress
          this.utility.logJourneyProgress('contact_retailer', this.offer.retailerId + ',' + this.offer.retailerName);

          //check if the user has uploaded a consumption file
          if (data.energyType == 'Electricity') {
            if (data.usageProfile == 1) {
              this.getMyData = true;
            } else if (data.usageProfile == 2) {
              this.fileUpload = true;
            }
          }
          if (data.loopBackData.energyConfigData.hasOwnProperty('energyType') == true && data.loopBackData.energyConfigData.energyType == 'Electricity') {
            if (data.loopBackData.energyConfigData.usageProfile == 1) {
              this.getMyData = true;
            } else if (data.loopBackData.energyConfigData.usageProfile == 2) {
              this.fileUpload = true;
            }
          }
        },
      );
    } else {
      //redirect to home page
      this.utility.redirectToHome();
    }
    this.formatRates();
  }

  public back() {
    let returnPage = this.dataService.getReturnPage();
    this.router.navigate(['/' + returnPage]);
  }

  public email() {
    let modalRef = this.modalService.open(EmailModalComponent, {size : 'xs'});
    modalRef.componentInstance.headerTitle = 'Email single offer (' + this.offer.offerId + ')';
    GA4LogEvent('/contact/' + this.energyType, 'EMAIL');
  }

  public print() {
    window.print();
    GA4LogEvent('/contact/' + this.energyType, 'PRINT');
  }

  private getRetailers() {
    if (Object.keys(this.dataService.getRetailers()).length < 1) {
      this.dataService.getAPIRetailers(this.energyType).subscribe(
        retailers => {
          retailers = retailers["data"];
          this.retailers = retailers; //get data object from rest api
          this.dataService.setRetailers(retailers);
        },
        error => {
        }
      );
    } else {
      this.retailers = this.dataService.getRetailers();
    }
  }

  private formatRates() {
    this.offer.tariffDetails = this.utility.formatTariffs(this.offer.tariffDetails);
  }

  hasSolarVFiT() {
    this.offer.solar.forEach(solar => {
      // VFiT option 1 & 2
      if(solar.name == 'Variable FiT - Option 1' || solar.name == 'Variable FiT - Option 2') {
        this.vFiT = true;
      } // Custom VFiT
      else if (!solar.rate && (solar.feed_in_tariff === 'Current FIT policy' || solar.feed_in_tariff === 'Premium FIT [closed]')) {
        this.vFiT = true;
      }
    });
  }

}
