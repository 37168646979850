import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';
import { Router } from "@angular/router";

@Component({
  selector: 'app-hot-topics-switch',
  templateUrl: './hot-topics-switch.component.html',
  styleUrls: ['./hot-topics-switch.component.css']
})
export class HotTopicsSwitchComponent implements OnInit {
  public appURL = environment.appURL;
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public bannerTitle = "How to switch and get the best offer for you";
  public bannerSubTitle = "";
  public bannerDetails = "";

  constructor(
    public router : Router,
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Hot Topics - How to switch and get the best offer for you  - Victorian Energy Compare';
  }

  public mainHotTopics() {
    this.router.navigate(['/hot-topics']);
    this.utility.scrollToTop('none');
  }
}
