import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-distributor-information-modal',
  templateUrl: './distributor-information-modal.component.html',
  styleUrls: ['./distributor-information-modal.component.css']
})
export class DistributorInformationModalComponent implements OnInit {
  public distributorInfo;
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
