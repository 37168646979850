import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';
import { Utility } from '../utility';

@Component({
  selector: 'app-psb-faq',
  templateUrl: './psb-faq.component.html',
  styleUrls: ['./psb-faq.component.css']
})
export class PsbFaqComponent implements OnInit {
  public appURL = environment.appURL;
  public psbURL = environment.psbURL;
  public ts = new Date().getTime();
  public hmac = '';
  public sessionId = '';
  public bannerTitle = "$250 Power Saving Bonus Program - FAQs";
  public bannerSubTitle = "";
  public bannerDetails = "";

  constructor(
    public spinner : NgxSpinnerService,
    public utility : Utility,
    public dataService : DataService) { }

  ngOnInit() {
    let serverCacheId = localStorage.getItem("serverCacheId");
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'PSB FAQs - Victorian Energy Compare';
    this.spinner.show();
    this.dataService.getPSBDetails(serverCacheId).subscribe(
      results => {
        this.ts = results.ts;
        this.hmac = results.hmac;
        this.sessionId = results.serverCacheId;
        this.spinner.hide();
        if (serverCacheId == null) {
          localStorage.setItem("serverCacheId", results.serverCacheId);
        }
      },
      error => {
        this.spinner.hide();
      },
    );

  }

  public psb2Application() {
    document.forms["psb2ClickThrough"].submit();
  }
}
