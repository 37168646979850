import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment'; //config file


@Injectable({
  providedIn: 'root'
})
export class DataService {
  public liveChatOn = false;
  public liveAEMO = false;
  public PSBReference = '';
  public EAPReference = '';
  public psbMode = '';
  public ebrMode = '';
  public selectedSolarEnergyType = '';
  public pageEnergyType;
  public isIE = false;
  public download = false;
  private returnPage;
  private apiEndpoint = environment.apiEndpoint;
  private fileIsUploaded = false;
  private singleOffer = 0;
  private selectedOffer = {};
  private retailerDetails = {};
  private showTopOffer = false;
  private sortByConditionalPrice = true;
  private sorting = 'totalConditionalPrice';
  private sortingIsSaved = false;
  private retailers = [];
  private offers = { "offersList": [] };
  private sortedOffers = { "offersList": [] };
  private dualOffers = { "offersList": [] };
  private gasOffers = { "offersList": [] };
  private selectedOffers = [];
  public isDualOffer = false;
  private distributor;
  private print = false;
  private billsPage = new BehaviorSubject('false');
  public billDetails = this.billsPage.asObservable();
  private energyProfilePage = new BehaviorSubject('false');
  private energyQuestionnairePage = new BehaviorSubject('false');
  public energyDetails = this.energyProfilePage.asObservable();
  public energyQuestionnaireDetails = this.energyQuestionnairePage.asObservable();
  private loopBack = new BehaviorSubject('false');
  public loopBackDetails = this.loopBack.asObservable();
  public selectedEnergyType = '';

  private energyConfigData = {
    energyType : "",
    userType : "",
    bill : "",
    twelveMonths : "",
    retailer : "",
    retailerName : "",
    postcode : "",
    usageProfile : 0,
    averageDailyConsumption : 0,
    nmi : "",
    skipNMI : false,
    smartMeter : "",
    fileProvider : "",
    file : "",
    agree : "",
    disclaimer : "",
    hasSolar : "",
    solarCapacity : '',
    hasConcession : "",
    feedInTariff : "",
    serverCacheId : "",
    distributor : { id : "", name : "" },
    pageDataType : "energyConfigData"
  };

  private offersSelection = {
    'energyType' : '',
    'showRetailersFilter' : false,
    'showOfferTypesFilter' : false,
    'showResultsFilter' : false,
    'showFeaturesFilter' : false,
    'showContractsFilter' : false,
    'showResourcesFilter' : false,
    'selectAllRetailers' : false,
    'selectAllContracts' : false,
    'showTouOffer' : false,
    'showSingleRate' : false,
    'showFlexiblePricing' : false,
    'showPayment' : false,
    'showIncentive' : false,
    'showVDO' : false,
    'showCustomEligibility' : false,
    'showSolarFiT' : false,
    'showDemand' : false,
    'showInnovative' : false,
    'showGreenPower' : false,
    'showGoGreenFilter' : false,
    'selectedRetailers' : [],
    'selectedOffers' : [],
    'offersShown' : 0
  };

  private energyProfileData = {
    pvCapacity : 0,
    pvCapacityCap : 0,
    hhSize : "0",
    totalRooms : "0",
    fridgeCount : "",
    gasConnection : "",
    poolHeating : "",
    poolHeatingSolar : false,
    poolHeatingGas : false,
    poolHeatingElectric : false,
    poolHeatingNone : false,
    spaceHeatingElectricDucted : false,
    spaceHeatingSplitSystem : false,
    spaceHeatingElectricUnderfloor : false,
    spaceHeatingElectricIndividual : false,
    spaceHeatingGasDucted : false,
    spaceHeatingGasUnderfloor : false,
    spaceHeatingGasIndividual : false,
    spaceHeatingOther : false,
    spaceHeatingNone : false,
    spaceCoolingRoomAC : false,
    spaceCoolingSplitSystem : false,
    spaceCoolingDuctedReverse : false,
    spaceCoolingDuctedEvaporative : false,
    spaceCoolingPortableRef : false,
    spaceCoolingPortableEvap : false,
    spaceCoolingOther : false,
    spaceCoolingNone : false,
    seaDistance : "",
    clothesDryer : "",
    clothesDryerWeekday : "",
    clothesDryerWeekend : "",
    dishwasherWeekday : "",
    dishwasherWeekend : "",
    waterHeatingElectric : false,
    waterHeatingElectricSolar : false,
    waterHeatingGasStorage : false,
    waterHeatingGasInstant : false,
    waterHeatingGasSolar : false,
    waterHeatingOther : false,
    controlledLoad : "",
    tvTotal : "",
    turnOffAtPowerShort : "",
    ovensElectric : "",
    ovensGas : "",
    washingMachineUsage : "",
    televisionUsageWeekday : "",
    televisionUsageWeekend : "",
    heatingUsageMethod : "",
    gasUsageWinter : "0",
    hhSize51 : "",
    energyType : "",
    serverCacheId : "",
    pageDataType : "energyProfileData"
  }

  public energyQuestionnaireData = {
    serverCacheId : "",
    // userType : "",
    smbPeakOffpeakConsumptionSummaryId : "",
    smbWeekdayWeekendConsumptionSummaryId : "",
    // energyType : "",
    distributor : "",
    postcode : "",
    averageDailyConsumption : "",
    industryId : 0,
    industryOther : "",
    hoursMornings : false,
    hoursAfternoons : false,
    hoursEvenings : false,
    hoursOvernight : false,
    days1 : false,
    days2 : false,
    days3 : false,
    days4 : false,
    days5 : false,
    days6 : false,
    days7 : false,
    premisesTypeId : 0,
    premisesTypeOther : "",
    employeesOnSite : "",
    employeesOnSiteOther : "",
    gasSupply : "0",
    appliance1 : "",
    appliance2 : "",
    appliance3 : "",
    electricitySupply : "",
    hasSolar : "",
    solarCapacity : "",
    created : "",

    pageDataType : "energyQuestionnaireData",
    loopBack: false
  }

  private billDetailsData = {
    billStartDate : "",
    billEndDate : "",
    elecUsage : "",
    gasUsage : "",
    serverCacheId : "",
    pageDataType : "billDetailsData"
  }

  private solarData = {
    offPeakRate : -1,
    offPeakRateValue : "",
    shoulderRate : -1,
    shoulderRateValue : "",
    discount : -1,
    discountRateValue : "",
    peakRateValue : "",
    dailySupplyCharge : "",
    usage : -1,
    agree : 0
  };

  constructor(private httpClient : HttpClient) { }

  public changeEnergyType(energyType) {
    this.pageEnergyType = energyType;
  }

  public changeBillPage(message: string) {
    this.billsPage.next(message);
  }

  public changeEnergyProfilePage(message: string) {
    this.energyProfilePage.next(message);
  }

  public changeEnergyQuestionnairePage(message: string) {
    this.energyQuestionnairePage.next(message);
  }

  public changeLoopBack(message: string) {
    this.loopBack.next(message);
  }

  public uploadFile(fileToUpload : File, serverCacheId : string, energyType : string, recaptchaResponse : string, loopBack : string, fileName : string) : Observable<Object> {
    let formData : FormData = new FormData();
    formData.append('energyType', energyType);
    formData.append('consumptionFile', fileToUpload, fileToUpload.name);
    formData.append('serverCacheId', serverCacheId);
    formData.append('recaptchaResponse', recaptchaResponse);
    formData.append('loopBack', loopBack);
    formData.append('fileName', fileName);
    return this.httpClient.post(this.apiEndpoint + 'upload-consumption-file', formData);
  }

  public uploadConsumptionFile(fileToUpload: File) : Observable<Object> {
    let formData : FormData = new FormData();
    formData.append('consumptionFile', fileToUpload, fileToUpload.name);
    return this.httpClient.post(this.apiEndpoint + 'upload-consumption-file', formData);
  }

  public uploadPDFConsumptionFile(fileToUpload: File, energyType : string, loopBack): Observable<Object> {
    let serverCacheId = localStorage.getItem("serverCacheId");
    let formData : FormData = new FormData();
    formData.append('serverCacheId', serverCacheId);
    formData.append('energyType', energyType);
    formData.append('loopBack', loopBack);
    formData.append('pdfBill', fileToUpload, fileToUpload.name);
    return this.httpClient.post(this.apiEndpoint + 'upload-pdf-bill', formData);
  }

  public sendEmail(html, email, isSolar) : Observable<Object> {
    let urlRoute = (isSolar == true) ? 'email-solar' : 'email-offers';
    return this.httpClient.post(
      environment.apiEndpoint + urlRoute,
      {
        "html" : html,
        "email" : email,
      },
    );
  }

  public psb3Registration(name, email, mobile, serverCacheId) : Observable<Object> {
    return this.httpClient.post(
      environment.apiEndpoint + 'psb3-registration',
      {
        "name" : name,
        "email" : email,
        "mobile" : mobile,
        "serverCacheId" : serverCacheId,
      },
    );
  }

  public sendBrochure(html, email) : Observable<Object> {
    return this.httpClient.post(
      environment.apiEndpoint + 'email-brochure',
      {
        "html" : html,
        "email" : email,
      },
    );
  }

  public sendContactMessage(name, email, subject, refNumber, comment, offerId, recaptchaResponse) : Observable<Object> {
    return this.httpClient.post(
      environment.apiEndpoint + 'contact-us',
      {
        "name" : name,
        "email" : email,
        "subject" : subject,
        "comment" : comment,
        "offerId" : offerId,
        "refNumber" : refNumber,
        "recaptchaResponse" : recaptchaResponse
      },
    );
  }

  public sendFeedbackMessage(firstName, lastName, email, phoneNumber, postcode, natureOfEnquiry, enquiryRelatingTo, psbNumber, offerIdNumber, contactedBefore, message, recaptchaResponse) : Observable<Object> {
    return this.httpClient.post(
      environment.apiEndpoint + 'feedback',
      {
        "firstName" : firstName,
        "lastName" : lastName,
        "email" : email,
        "phoneNumber" : phoneNumber,
        "postcode" : postcode,
        "natureOfEnquiry" : natureOfEnquiry,
        "enquiryRelatingTo" : enquiryRelatingTo,
        "psbNumber" : psbNumber,
        "offerIdNumber" : offerIdNumber,
        "contactedBefore" : contactedBefore,
        "message" : message,
        "recaptchaResponse" : recaptchaResponse
      },
    );
  }

  public getOutreach(postcode) : Observable<Object> {
    let params = new HttpParams().set('postcode', postcode);
    return this.httpClient.get(this.apiEndpoint + 'outreach', { params: params });
  }

  public getRetailerSettings(retailerId) : Observable<Object> {
    let params = new HttpParams().set('retailerId', retailerId);
    return this.httpClient.get(this.apiEndpoint + 'get-retailer-settings', { params: params });
  }

  public getLanguage(language) : Observable<Object> {
    return this.httpClient.get("./assets/languages/" + language + "/" + language + ".json");
  }

  public getLanguageFile(language) : Observable<Object> {
    return this.httpClient.get("./assets/languages/" + language + "/" + language + ".html", { responseType: 'text' });
  }

  public getLanguagePageContents(language, page_name) : Observable<Object> {
    return this.httpClient.get("./assets/languages/" + language + "/" + page_name + ".html", { responseType: 'text' });
  }

  public getLanguagePageConfig(language, page_name) : Observable<Object> {
    return this.httpClient.get("./assets/languages/" + language + "/" + page_name + ".json");
  }

  public getEbrFaqs() : Observable<Object> {
    return this.httpClient.get("./assets/faqs/ebr/index.html", { responseType: 'text' });
  }

  public getSettings() : Observable<Object> {
    return this.httpClient.get(this.apiEndpoint + 'get-settings');
  }

  public getMaxConsumption() : Observable<Object> {
    return this.httpClient.get(this.apiEndpoint + 'get-max-consumption');
  }

  public getAPIRetailers(energyType) : Observable<Object> {
    let params = new HttpParams().set('energyType', energyType);
    return this.httpClient.get(this.apiEndpoint + 'get-retailers', { params: params });
  }

  public getAPIDistributorsList(energyType) : Observable<Object> {
    let params = new HttpParams().set('energyType', energyType);
    return this.httpClient.get(this.apiEndpoint + 'get-distributors-list', { params: params });
  }

  public showRetailers() : Observable<Object> {
    return this.httpClient.get(this.apiEndpoint + 'show-retailers');
  }

  public getDistributorInfo(distributor) : Observable<Object> {
    let params = new HttpParams().set('distributor', distributor);
    return this.httpClient.get(
      this.apiEndpoint + 'get-distributor-info', { params: params });
  }

  public getDistributorDownloadInfo(distributor) : Observable<Object> {
    let params = new HttpParams().set('distributor', distributor);
    return this.httpClient.get(
      this.apiEndpoint + 'get-distributor-download-info', { params: params });
  }

  public getFAQ(lang) : Observable<Object> {
    let params = new HttpParams().set('lang', lang);
    return this.httpClient.get(
      this.apiEndpoint + 'get-faq', { params: params });
  }

  public getSavedData(serverCacheId) : Observable<any> {
    let params = new HttpParams().set('serverCacheId', serverCacheId);

    return this.httpClient.get(
      this.apiEndpoint + 'get-form-data', { params: params }
    );
  }

  public getPSBSavedData(serverCacheId) : Observable<any> {
    let params = new HttpParams().set('serverCacheId', serverCacheId);
    return this.httpClient.get(
      this.apiEndpoint + 'get-psb-form-data', { params: params }
    );
  }

  public getSmbIndustries() : Observable<Object> {
    return this.httpClient.get(this.apiEndpoint + 'get-smb_industry');
  }

  public getSmbPremisesTypes() : Observable<Object> {
    return this.httpClient.get(this.apiEndpoint + 'get-smb-premises-type');
  }

  public getSavedOffers() {
    return this.offers;
  }

  public getSavedSortedOffers() {
    return this.sortedOffers;
  }

  public getDualOffers() {
    return this.dualOffers;
  }

  public getGasOffers() {
    return this.gasOffers;
  }

  public getPSBDetails(serverCacheId) : Observable<any> {
    let params = new HttpParams().set('serverCacheId', serverCacheId);
    return this.httpClient.get(
      this.apiEndpoint + 'get-psb-details', { params: params }
    );
  }

  public getEBRDetails(serverCacheId) : Observable<any> {
    let params = new HttpParams().set('serverCacheId', serverCacheId);
    return this.httpClient.get(
      this.apiEndpoint + 'get-ebr-details', { params: params }
    );
  }

  public selectProfile(usage) : Observable<any> {
    let serverCacheId = localStorage.getItem("serverCacheId");
    let params = new HttpParams().set('serverCacheId', serverCacheId).set('usage', usage);
    return this.httpClient.get(
      this.apiEndpoint + 'select-profile', { params: params }
    );
  }

  public getOffers(loopBack, energyType) : Observable<any> {
    let serverCacheId = localStorage.getItem("serverCacheId");
    let params = new HttpParams().set('serverCacheId', serverCacheId).set('loopBack', loopBack).set('selectedEnergy', energyType);
    return this.httpClient.get(
      this.apiEndpoint + 'get-offers', { params: params }
    );
  }

  public getPSBOffers(loopBack, energyType) : Observable<any> {
    let serverCacheId = "test";
    let params = new HttpParams().set('serverCacheId', serverCacheId).set('loopBack', loopBack).set('selectedEnergy', energyType);
    return this.httpClient.get(
      this.apiEndpoint + 'get-psb-offers', { params: params }
    );
  }

  public getDistributors(postcode, energyType) : Observable<Object> {
    energyType = (energyType == 'Solar') ? 'Electricity' : energyType;
    let params = new HttpParams().set('postcode', postcode).set('energyType', energyType);
    return this.httpClient.get(
      this.apiEndpoint + 'get-distributors', { params: params }
    );
  }

  public reporting(energyType, showTopOffer, serverCacheId, topOffers, mostExpensiveOffers) : Observable<any> {
    return this.httpClient.post(this.apiEndpoint + 'top-offers', { energyType : energyType, isTopOfferForRetailer: showTopOffer, serverCacheId : serverCacheId, topOffers : topOffers, mostExpensiveOffers : mostExpensiveOffers });
  }

  public getConsumption(energyConfigData) : Observable<any> {
    return this.httpClient.post(this.apiEndpoint + 'consumption', energyConfigData);
  }

  public saveData(data) : Observable<any> {
    if (data.pageDataType == 'energyProfileData') {
      this.energyProfileData = data;
    } else if (data.pageDataType == 'energyQuestionnaireData') {
      this.energyQuestionnaireData = data;
    } else if (data.pageDataType == 'energyConfigData') {
      this.energyConfigData = data;
    } else {
      this.billDetailsData = data;
    }
    return this.httpClient.post(this.apiEndpoint + 'save-form-data', data);
  }

  public saveFormData(energyConfigData) {
    this.energyConfigData = energyConfigData;
  }

  public setEnergyConfigData(data) {
    this.energyConfigData = data;
  }

  public setEnergyProfileData(data) {
    this.energyProfileData = data;
  }

  public setEnergyQuestionnaireData(data) {
    this.energyQuestionnaireData = data;
  }

  public setBillDetailsData(data) {
    this.billDetailsData = data;
  }

  /*
  * Getters functions
  */
  public getReturnPage() {
    return this.returnPage;
  }

  public getRetailerDetails() : any {
    return this.retailerDetails;
  }

  public getShowTopOffer() : boolean {
    return this.showTopOffer;
  }

  public getSortingSaved() : boolean {
    return this.sortingIsSaved;
  }

  public getSelectedOffer() : any {
    return this.selectedOffer;
  }

  public getPrint() : any {
    return this.print;
  }

  public getFileIsUploaded() : boolean {
    return this.fileIsUploaded;
  }

  public getDistributor() {
    return this.distributor;
  }

  public getEnergyConfigData() {
    return this.energyConfigData;
  }

  public getSelectedEnergyType() {
    return this.selectedEnergyType;
  }

  public getEnergyProfileData() {
    return this.energyProfileData;
  }

  public getEnergyQuestionnaireData() {
    return this.energyQuestionnaireData;
  }

  public getBillDetailsData() {
    return this.billDetailsData;
  }


  public getRetailers() {
    return this.retailers;
  }

  public getNMI() : string {
    return this.energyConfigData.nmi;
  }

  public getPostCode() : string {
    return this.energyConfigData.postcode;
  }

  public getRetailer() {
    return this.energyConfigData.retailer;
  }

  public getEnergyType() : string {
    return this.energyConfigData.energyType;
  }

  public getUserType() : string {
    return this.energyConfigData.userType;
  }

  public getBill() : string {
    return this.energyConfigData.bill;
  }

  public getUsageProfile() : number {
    return this.energyConfigData.usageProfile;
  }

  public getSmartMeter() : string {
    return this.energyConfigData.smartMeter;
  }

  public getFileProvider() {
    return this.energyConfigData.fileProvider;
  }

  public getFile() : string {
    return this.energyConfigData.file;
  }

  public getSelectedOffers() : any {
    return this.selectedOffers;
  }

  public getSorting() : boolean {
    return this.sortByConditionalPrice;
  }

  /*
  * Setters functions
  */
  public setPrint(print) {
    this.print = print;
  }

  public setSorting(sortByConditionalPrice) {
    this.sortByConditionalPrice = sortByConditionalPrice;
  }

  public setSelectedEnergyType(selectedEnergyType : string) : void {
    this.selectedEnergyType = selectedEnergyType;
  }

  public setEnergyType(energyType : string) : void {
    this.energyConfigData.energyType = energyType;
  }

  public setUserType(userType : string) : void {
    this.energyConfigData.userType = userType;
  }

  public setBill(bill : string) : void {
    this.energyConfigData.bill = bill;
  }

  public setTwelveMonths(twelveMonths : string) : void {
    this.energyConfigData.twelveMonths = twelveMonths;
  }

  public setUsageProfile(usageProfile : number) : void {
    this.energyConfigData.usageProfile = usageProfile;
  }

  public setSmartMeter(smartMeter : string) : void {
    this.energyConfigData.smartMeter = smartMeter;
  }

  public setFileProvider(fileProvider) : void {
    this.energyConfigData.fileProvider = fileProvider;
  }

  public setFile(file : string) : void {
    this.energyConfigData.file = file;
  }

  public setNMI(nmi : string) : void {
    this.energyConfigData.nmi = nmi;
  }

  public setPostCode(postCode : string) : void {
    this.energyConfigData.postcode = postCode;
  }

  public setRetailer(retailer) : void {
    this.energyConfigData.retailer = retailer;
  }

  public setRetailers(retailers) : void {
    this.retailers = retailers;
  }

  public setOffers(offers : any) : void {
    this.offers = offers;
  }

  public setSortedOffers(offers : any) : void {
    this.sortedOffers = offers;
  }

  public setDualOffers(offers : any) : void {
    this.dualOffers = offers;
  }

  public setGasOffers(offers : any) : void {
    this.gasOffers = offers;
  }

  public setSelectedOffers(selectedOffers : any) : void {
    this.selectedOffers = selectedOffers;
  }

  public setSelectedOffer(offer) {
    this.selectedOffer = offer;
  }

  public setReturnPage(returnPage) {
    this.returnPage = returnPage;
  }

  public setRetailerDetails(retailer) {
    this.retailerDetails = retailer;
  }

  public setDistributor(selectedDistributor) {
    this.distributor = selectedDistributor;
  }

  public setFileIsUploaded(fileIsUploaded) {
    this.fileIsUploaded = fileIsUploaded;
  }

  public setSortingSaved(sortingIsSaved) {
    this.sortingIsSaved = sortingIsSaved;
  }

  public setShowTopOffer(showTopOffer) : void {
    this.showTopOffer = showTopOffer;
  }

  public resetForms(serverCacheId, loopBack) {
    this.energyProfileData = {
      pvCapacity : 0,
      pvCapacityCap : 0,
      hhSize : "0",
      totalRooms : "0",
      fridgeCount : "",
      gasConnection : "",
      poolHeating : "",
      poolHeatingSolar : false,
      poolHeatingGas : false,
      poolHeatingElectric : false,
      poolHeatingNone : false,
      spaceHeatingElectricDucted : false,
      spaceHeatingSplitSystem : false,
      spaceHeatingElectricUnderfloor : false,
      spaceHeatingElectricIndividual : false,
      spaceHeatingGasDucted : false,
      spaceHeatingGasUnderfloor : false,
      spaceHeatingGasIndividual : false,
      spaceHeatingOther : false,
      spaceHeatingNone : false,
      spaceCoolingRoomAC : false,
      spaceCoolingSplitSystem : false,
      spaceCoolingDuctedReverse : false,
      spaceCoolingDuctedEvaporative : false,
      spaceCoolingPortableRef : false,
      spaceCoolingPortableEvap : false,
      spaceCoolingOther : false,
      spaceCoolingNone : false,
      seaDistance : "",
      clothesDryer : "",
      clothesDryerWeekday : "",
      clothesDryerWeekend : "",
      dishwasherWeekday : "",
      dishwasherWeekend : "",
      waterHeatingElectric : false,
      waterHeatingElectricSolar : false,
      waterHeatingGasStorage : false,
      waterHeatingGasInstant : false,
      waterHeatingGasSolar : false,
      waterHeatingOther : false,
      controlledLoad : "",
      tvTotal : "",
      turnOffAtPowerShort : "",
      ovensElectric : "",
      ovensGas : "",
      washingMachineUsage : "",
      televisionUsageWeekday : "",
      televisionUsageWeekend : "",
      heatingUsageMethod : "",
      gasUsageWinter : "0",
      hhSize51 : "",
      energyType : "",
      serverCacheId : "",
      pageDataType : "energyProfileData"
    }

    this.billDetailsData = {
      billStartDate : "",
      billEndDate : "",
      elecUsage : "",
      gasUsage : "",
      serverCacheId : "",
      pageDataType : "billDetailsData"
    }
    return this.httpClient.post(this.apiEndpoint + 'reset-forms', { serverCacheId : serverCacheId, loopBack : loopBack });
  }

  public getSolarEstimates(data) : Observable<Object> {
    data.discountRateValue = (data.discountRateValue == '') ? 0 : data.discountRateValue;
    var params = {
                    "nmi" : data.nmi,
                    "postcode" : data.postcode,
                    "retailer" : data.retailer,
                    "systemSize" : data.systemSize,
                    "systemCost" : data.systemCost,
                    "fitPeak" : data.fitPeak,
                    "fitOffPeak" : data.fitOffPeak,
                    "fitShoulder" : data.fitShoulder,
                    "peak" : data.peakRateValue,
                    "offPeak": data.offPeakRateValue,
                    "shoulder": data.shoulderRateValue,
                    "discount" : (data.discountRateValue / 100),
                    "dailySupplyCharge" : data.dailySupplyCharge,
                    "discountType" : (data.usage == 1) ? 'usage' : 'bill',
                    "fitRates" : data.fitRates,
                    "tariffs" : data.tariffs,
                    "serverCacheId" : data.serverCacheId,
                    "pageLoad" : true
                };
    var url = environment.apiEndpoint + 'get-solar-estimates';
    return this.httpClient.post(url, params);
  }

  public getBillRates(data, supplyDays) : Observable<Object> {
    var params = {
      "loopBack" : data.loopBack,
      "energyType" : data.energyType,
      "hasSolar" : data.hasSolar,
      "feedInTariff" : data.feedInTariff,
      "billStartDate" : data.billStartDate,
      "billEndDate" : data.billEndDate,
      "retailer" : data.retailer,
      "retailerName" : data.retailerName,
      "totalCharge" : data.totalCharge,
      "offPeak" : data.offPeak,
      "offPeakRate" : data.offPeakRate,
      "controlledLoadRate" : data.controlledLoadRate,
      "peakRate" : data.peakRate,
      "solarFitRate" : data.solarFitRate,
      "shoulder" : data.shoulder,
      "shoulderRate" : data.shoulderRate,
      "supplyCharge" : data.supplyCharge,
      "supplyDays" : supplyDays,
      "discount" : data.discount,
      "discountAmount" : data.discountAmount,
      "discountType" : data.discountType,
      "rateUnit" : data.rateUnit,
      "supplyRateUnit" : data.supplyRateUnit,
      "kogbillResponse" : data.kogbillResponse,
      "serverCacheId" : data.serverCacheId
    };

    var url = environment.apiEndpoint + 'get-bill-rates';
    return this.httpClient.post(url, params);
  }

  public fetchWAIPForKogbill(data, supplyDays) : Observable<Object> {
    var params = {
      "serverCacheId" : data.serverCacheId,
      "energyType" : "Electricity"
    };

    var url = environment.apiEndpoint + 'fetch-waip';
    return this.httpClient.post(url, params);
  }

  public fetchGasWAIPForKogbill(data, supplyDays) : Observable<Object> {
    var params = {
      "serverCacheId" : data.serverCacheId,
      "energyType" : "Gas"
    };

    var url = environment.apiEndpoint + 'fetch-waip';
    return this.httpClient.post(url, params);
  }

  public getDualBillRates(data, supplyDays, gasSupplyDays) : Observable<Object> {
    var params = {
      "hasSolar" : data.hasSolar,
      "feedInTariff" : data.feedInTariff,
      "billStartDate" : data.billStartDate,
      "billEndDate" : data.billEndDate,
      "retailer" : data.retailer,
      "retailerName" : data.retailerName,
      "totalCharge" : data.totalCharge,
      "offPeak" : data.offPeak,
      "offPeakRate" : data.offPeakRate,
      "controlledLoadRate" : data.controlledLoadRate,
      "peakRate" : data.peakRate,
      "solarFitRate" : data.solarFitRate,
      "shoulder" : data.shoulder,
      "shoulderRate" : data.shoulderRate,
      "supplyCharge" : data.supplyCharge,
      "supplyDays" : supplyDays,
      "discount" : data.discount,
      "discountAmount" : data.discountAmount,
      "discountType" : data.discountType,
      "rateUnit" : data.rateUnit,
      "supplyRateUnit" : data.supplyRateUnit,
      "serverCacheId" : data.serverCacheId,

      //gas
      "gasBillStartDate": data.gasBillStartDate,
      "gasBillEndDate": data.gasBillEndDate,
      "gasRetailer": data.gasRetailer,
      "gasRetailerName": data.gasRetailerName,
      "gasSupplyCharge": data.gasSupplyCharge,
      "gasDiscount": data.gasDiscount,
      "gasDiscountAmount": data.gasDiscountAmount,
      "gasDiscountType": data.gasDiscountType,
      "gasSupplyDays" : gasSupplyDays,
      "gasRateUnit" : data.gasRateUnit,
      "supplyGasRateUnit" : data.supplyGasRateUnit
    };

    var url = environment.apiEndpoint + 'get-dual-bill-rates';
    return this.httpClient.post(url, params);
  }

  public validateDetails(serverCacheId, userData) : Observable<Object> {
    return this.httpClient.post(
      environment.apiEndpoint + 'validate-solar',
      {
        "serverCacheId" : serverCacheId,
        "nmi" : userData.nmi,
        "postcode" : userData.postcode,
        "retailer" : userData.retailer,
        "recaptchaResponse" : userData.recaptchaResponse,
        "yearRequirement" : 1
      }
    );
  }

  public logJourneyProgress(serverCacheId, stage, notes = '') : Observable<Object> {
    var params = {
      "serverCacheId" : serverCacheId,
      "stage" : stage,
      "notes" : notes
    };

    var url = environment.apiEndpoint + 'journey-progress';
    return this.httpClient.post(url, params);
  }

  public setOffersSelection(offersSelection) {
    this.offersSelection = offersSelection;
  }

  public getOffersSelection() {
    return this.offersSelection;
  }

}
