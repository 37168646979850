<div id="container">
    <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <div>
                <img class="hottopic-image" src="assets/images/{{ secImageURL }}contentpages/hottopics_header_9.jpg"
                    alt="How to switch and get the best offer for you">
                <p>
                    With the cost of living going up every dollar of savings can count.
                </p>
                <p>
                    But last financial year Victorian households missed out on $280 million in savings on energy bills
                    by not being on their retailer’s best offer.
                </p>

                <br /><br />
                <h3>What’s the best offer and how do you check you’re not missing out? </h3>
                <p>
                  One of the easiest ways to save money on your energy bills is by getting on the best offer with your energy retailer.
                </p>
                <p>
                  A retailer’s best offer is typically the cheapest offer available with your current retailer based on your household consumption.
                  In Victoria retailers are required to tell you on your bill if you could save money by moving to their best offer every 3 months for electricity and every 4 months for gas.
                </p>
                <p>
                  The best offer is with your current retailer, so you don’t need to change retailers or pay a fee. It only takes one phone call to start saving.
                </p>

                <br /><br />
                <h3>You could save hundreds of dollars on your energy bills by taking these 2 simple steps to get on your energy retailer’s best offer.</h3>

                <ol>
                    <li>
                        Grab copies of your most recent energy bills and check for the best offer notice
                        <br><br>
                        Your energy bill will tell you if you can save money on a different energy plan with your current energy retailer. Check the first page of your energy bill for the words ‘could you save money on another plan?’. This means there is a cheaper offer for your household with your retailer.

                        <br><br>
                        If it’s not on your most recent bill check earlier bills you have received – retailers are required to include their best offer on your bill every 3 months for electricity and every 4 months for gas.
                    </li>

                    <li>
                        Phone your energy retailer and ask to be moved to their best offer.
                        <br><br>
                        If you can save money and would like to make the change, all you need to do is phone your retailer and ask ‘can you put me on the best offer?’. Your energy retailer will be able to switch you to their best offer while you are on the phone – meaning you will start saving money on your energy bills right away.

                        <br><br>
                        Moving to your retailer’s best offer won’t stop your electricity/gas supply, change your retailer or your account number, you’ll just be paying less for your energy. Also, in Victoria if you’re on a market retail contract your retailer can only increase your prices once a year so you’ll keep enjoying the same price.
                    </li>
                </ol>
                <p>For more information on Best Offer Notice <a
                        href="https://www.esc.vic.gov.au/electricity-and-gas/information-consumers/getting-best-energy-offer"
                        target="_blank">visit the Essential Services Commission’s website</a>. </p>
                <p>
                  Psst! You can unlock even more savings on your energy bills by comparing all offers available in the market. The Victorian Energy Compare website allows you to check whether there’s a better offer for your household with another energy retailer. <a href="/">Grab a copy of a recent energy bill
                        and visit the Victorian Energy Compare home page to find even more energy bill savings</a>.
                </p>

                <br /><br />
                <h3>Need more help getting your retailers best offer? </h3>
                <p>If you’re a concession card holder or having trouble with your energy bills you can get free help to get the best offer and with contacting your retailer. Just call <a href="tel:1800161215">1800 161 215</a> to get help through the
                    <a href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills/energy-assistance-program" target="_blank">Victorian Government’s Energy Assistance Program</a>. </p>
                <br>
                <p>
                    <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
                </p>
            </div>
        </div>
    </div>
</div>
<br /><br />
