<div id="results-right">
    <div class="card-group">
        <div class="card card-default">
            <div class="card-header bg-success text-white">
                <div class="card-title">Solar Calculator <b>Detailed Results <span class="option">&mdash; Option {{ selectedOption }}</span></b></div>
                <a class="panel-short-text white" (click)="showCalculation = !showCalculation" *ngIf="download == false">
                    How is this calculated?
                </a>
            </div>
            <div class="card-body">
                <div class="calculationDiv" [class.opened]="showCalculation" *ngIf="download == false">
                    <div class="card-header background-header">
                        How is this calculated?
                    </div>
                    <div class="row how-calculated">
                        <div class="col-lg-3 nopadding systems-savings-cost">
                            <div class="row">
                                <div class="col-lg-11 nopadding">
                                    <div class="col-lg-12 nopadding">
                                        <p>&nbsp;</p>
                                        <img src='{{ appURL + "/assets/images/icon-solar-panel.png" }}' class="img-responsive" height="68" alt="" />
                                    </div>
                                    <div class="col-lg-12">
                                        <p>&nbsp;</p>
                                        Your solar <br>system size<br>
                                        <span class="strong">{{ solarData.systemSize }}kW</span>
                                    </div>
                                </div>
                                <div class="col-lg-1 nopadding vcenter text-center systems-savings-cost">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    &
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 nopadding systems-savings-cost">
                            <div class="row">
                                <div class="col-lg-11 nopadding">
                                    <div class="col-lg-12 nopadding">
                                        <p>&nbsp;</p><img src='{{ appURL + "/assets/images/dollar.png" }}' class="img-responsive" height="68" alt="" />
                                    </div>
                                    <div class="col-lg-12">
                                        <p>&nbsp;</p>Solar system<br>cost<br>
                                        <span class="strong">{{ solarData.systemCost | currency:'USD':'symbol':'1.0-0' }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-1 nopadding vcenter text-center systems-savings-cost">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    &
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 nopadding systems-savings-cost">
                            <div class="row">
                                <div class="col-lg-11 nopadding">
                                    <div class="col-lg-12 nopadding">
                                        <p>&nbsp;</p><img src='{{ appURL + "/assets/images/energy-vector-electric.png" }}' class="img-responsive" height="68" alt="" />
                                    </div>
                                    <div class="col-lg-12">
                                        <p>&nbsp;</p>Your energy <br />usage<p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class="col-lg-1 nopadding vcenter text-center systems-savings-cost">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    &
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 nopadding systems-savings-cost">
                            <div class="row">
                                <div class="col-lg-12 nopadding">
                                    <div class="col-lg-12">
                                        <p>&nbsp;</p><img src='{{ appURL + "/assets/images/solar-profile.png" }}' class="img-responsive" height="68" alt="" />
                                    </div>
                                    <div class="col-lg-12">
                                        <p>&nbsp;</p>Solar Profile <br />for your area
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-header background-header">Estimated Return on Investment</div>
                <div class="string" *ngIf="download == true">{{ emailSecurityKey }}</div>
                <div class="row">
                    <div class="col-lg-4 systems-savings-cost">
                        <p>&nbsp;</p>
                        <img src='{{ appURL + "/assets/images/icon-pig.png" }}' class="img-responsive" height="68" alt="" />
                        <p>&nbsp;</p>
                        Your solar system could<br>pay for itself in
                        <div class="systems-savings-cost nopadding">
                            <div class="systems-savings-cost-pinkblock">
                                <div>{{ solarCalculator.getPayback(solarData.systemCost, SolarEstimatesData.annualSavedTotal) | number : '1.0-1' }}&nbsp;years</div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 systems-savings-cost">
                        <p>&nbsp;</p>
                        <img src='{{ appURL + "/assets/images/dollar-save.png" }}' class="img-responsive" height="68" alt="" />
                        <p>&nbsp;</p>
                        Your estimated annual<br>savings are
                        <div class="systems-savings-cost nopadding">
                            <div class="systems-savings-cost-pinkblock">
                                <div>{{ SolarEstimatesData.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}<sup>*</sup></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 systems-savings-cost">
                        <p>&nbsp;</p>
                        <img src='{{ appURL + "/assets/images/dollar.png" }}' class="img-responsive" height="67" width="38" alt="" />
                        <p>&nbsp;</p>
                        Your new estimated <br>annual electricity bill
                        <div class="systems-savings-cost nopadding">
                            <div class="systems-savings-cost-pinkblock">
                                <div id="MYebill">{{ SolarEstimatesData.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}</div>
                                <span id="MonthlyBillTotal" style="display: none;">{{ solarCalculator.getMonthlyBill(SolarEstimatesData.annualBillTotal) | currency:'USD':'symbol':'1.0-0' }}</span>
                                <span id="QuarterlyBillTotal" style="display: none;">{{ solarCalculator.getQuarterlyBill(SolarEstimatesData.annualBillTotal) | currency:'USD':'symbol':'1.0-0' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row orangeblank">
                    <div class="col-lg-4 orangeblank">&nbsp;
                    </div>
                    <div class="col-lg-4 orangeblank">&nbsp;
                    </div>
                    <div class="col-lg-4 orangeblank">&nbsp;
                    </div>
                </div>

                <div id="charts">
                    <p>&nbsp;</p>
                    <div class="card-header background-header">Estimated System Output</div>
                    <div class="google-charts">
                        <google-chart [data]="chart1" *ngIf="download == false"></google-chart>
                        <google-chart [data]="chart1Print" *ngIf="download == true"></google-chart>
                    </div>
                    <div id="chart1_legend" class="col-lg-12 chart-legends">
                        <div class="table-responsive">
                            <table class="table col-lg-12">
                                <tbody>
                                <tr>
                                    <td width="50%">
                                        <svg height="10" width="80" style="width:80px;">
                                            <line x1="0%" y1="10" x2="80" y2="10" class="legend-generation" />
                                        </svg>
                                        <br />
                                        Your average half hourly solar energy <span class="generation">generation</span> (based on solar data for your area)
                                    </td>
                                    <td width="50%">
                                        <svg height="10" width="80" style="width:80px;">
                                            <line x1="0%" y1="10" x2="80" y2="10" class="legend-usage" />
                                        </svg>
                                        <br />
                                        Your average half hourly energy <span class="usage">usage</span>
                                        (based on the information you have provided)
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p class="spacer">&nbsp;</p>

                    <div class="card-header background-header">Estimated Monthly Savings</div>
                    <div class="google-charts">
                        <google-chart [data]="chart2" *ngIf="download == false"></google-chart>
                        <google-chart [data]="chart2Print" *ngIf="download == true"></google-chart>
                    </div>
                    <div id="chart2_legend" class="col-lg-12 chart-legends" style="display:flex;">
                        <div class="table-responsive">
                            <table class="table col-lg-12">
                                <tbody>
                                <tr>
                                    <td width="50%">
                                        <svg height="10" width="80">
                                            <line x1="0%" y1="10" x2="80" y2="10" class="legend-generation" />
                                        </svg>
                                        <br />
                                        Saving from exporting excess solar energy to grid (i.e. feed-in tariff)
                                    </td>
                                    <td width="50%">
                                        <svg height="10" width="80">
                                            <line x1="0%" y1="10" x2="80" y2="10" class="legend-usage" />
                                        </svg>
                                        <br />
                                        Saving from reduced grid energy use
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <p class="spacer">&nbsp;</p>

                <div class="card-header background-header">Estimated Usage</div>
                <div class="card-body row">
                    <div class="table-responsive col-lg-9">
                        <table class="table" id="estimated-usage-table">
                            <thead>
                                <tr>
                                    <th scope="col" class="usage-header">Energy Consumed</th>
                                    <th scope="col" colspan="2" class="usage-header">Before Solar</th>
                                    <th scope="col" colspan="2" class="usage-header">After Solar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Grid</td>
                                    <td>{{ SolarEstimatesData.annualImportNoSolar | number : '1.0-0' }} kWh</td>
                                    <td>100%</td>
                                    <td>{{ SolarEstimatesData.annualImportWithSolar | number : '1.0-0' }} kWh</td>
                                    <td>{{ solarCalculator.getEnergySavingsSolarPercentage(SolarEstimatesData.annualImportNoSolar, SolarEstimatesData.annualImportWithSolar) | number : '1.0-0' }}%</td>
                                </tr>
                                <tr>
                                    <td>Solar</td>
                                    <td>0 kWh</td>
                                    <td>0%</td>
                                    <td>{{ solarCalculator.getAnnualImportAfterSolar(SolarEstimatesData.annualImportNoSolar, SolarEstimatesData.annualImportWithSolar)  | number : '1.0-0' }} kW</td>
                                    <td>{{ solarCalculator.getEnergySavingsPercentage(SolarEstimatesData.annualImportNoSolar, SolarEstimatesData.annualImportWithSolar) | number : '1.0-0' }}%</td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                      <div class="col-lg-3">
                        <p align="center">
                            Your estimated reduction of grid usage is <br>
                            <span class="strong">
                                {{ solarCalculator.getEnergySavingsPercentage(SolarEstimatesData.annualImportNoSolar, SolarEstimatesData.annualImportWithSolar) | number : '1.0-0' }}%
                            </span>
                        </p>
                      </div>
                </div>
                <div *ngIf="dataService.download == false" [innerHTML]="solarCompareTable" id="solar-compare-table">
                </div>
            </div>
        </div>
    </div>
    <p>&nbsp;</p>
</div>

