import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  public bannerTitle = "Privacy";
  public bannerSubTitle = "Read the privacy information below to understand how your data will be treated and protected.";
  public bannerDetails = "";

  constructor(public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Privacy - Victorian Energy Compare';
  }

}
