<div class="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="col-lg-10" [ngClass]="{ 'solar-background' : (energyProfileForm.energyProfile.energyType == 'Solar') }">
        <h2 class="subTitle">Your household energy usage</h2>

        <div class="row" *ngIf="energyProfileForm.saveFormError != ''">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        An error has occured while trying to submit the information you have provided. Please try again.
                    </h4>
                </div>
            </div>
        </div>

        <form
            method="post"
            id="profileForm"
            [formGroup]="energyProfileForm.profileForm"
            onsubmit="return false"
            (keydown.enter)="$event.preventDefault()">

            <div class="row page-divider-up form-group">
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>
                        How many people live in your house?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select
                            aria-hidden="true"
                            formControlName='hhSize'
                            class="form-control select-questionnaire"
                            [(ngModel)]="energyProfileForm.energyProfile.hhSize"
                            tabindex="1">
                            <option value="0">Please select one</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5 or more</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        How many rooms (total)?
                    </label>
                    &nbsp;
                    <i
                        onclick="return false"
                        class="material-icons black"
                        data-toggle="tooltip"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="When answering this question, please count all the rooms in your house, including small rooms like bathrooms and laundry."
                        aria-label="When answering this question, please count all the rooms in your house, including small rooms like bathrooms and laundry.">info</i>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select
                            aria-hidden="true"
                            formControlName='totalRooms'
                            class="form-control select-questionnaire"
                            [(ngModel)]="energyProfileForm.energyProfile.totalRooms"
                            tabindex="2">
                            <option value="0">Please select one</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12 or more</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group" *ngIf="energyProfileForm.energyProfile.energyType != 'Gas'">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        How many refrigerators do you own (exclude freezer only units)?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='fridgeCount' class="form-control select-questionnaire" [(ngModel)]="energyProfileForm.energyProfile.fridgeCount" tabindex="3">
                            <option value="">Please select one</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3 or more</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group page-divider-up" *ngIf="energyProfileForm.energyProfile.energyType != 'Gas'">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        Do you have gas?
                    </label>
                </div>
                <div class="col-md-6">
                    <div class="select">
                        <select aria-hidden="true" formControlName='gasConnection' class="form-control select-questionnaire" [(ngModel)]="energyProfileForm.energyProfile.gasConnection" tabindex="4">
                            <option value="" selected>Please select one</option>
                            <option value="1">Yes, I have mains connected gas</option>
                            <option value="3">Yes I have bulk bottled gas that are piped into the house (not just for BBQ)</option>
                            <option value="4">No</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group" *ngIf="energyProfileForm.energyProfile.energyType != 'Gas'">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        Do you have a pool?
                    </label>
                </div>
                <div class="col-md-6">
                    <div class="radio-inline col-lg-7 vertical-align no-padding">
                        <input formControlName="poolHeating" type="radio" id="poolHeatingYes" [(ngModel)]="energyProfileForm.energyProfile.poolHeating" value="1">
                        <label for="poolHeatingYes" class="custom-btn-default custom-btn-text" tabindex="5" (keydown)="energyProfileForm.selectButton($event, 'poolHeatingYes')">Yes</label>
                        <input formControlName="poolHeating" type="radio" id="poolHeatingNo" [(ngModel)]="energyProfileForm.energyProfile.poolHeating" value="0">
                        <label for="poolHeatingNo" class="custom-btn-default custom-btn-text" tabindex="6" (keydown)="energyProfileForm.selectButton($event, 'poolHeatingNo')">No</label>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group" *ngIf="energyProfileForm.energyProfile.energyType != 'Gas' && energyProfileForm.energyProfile.poolHeating == '1'">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <span class="strong">What types of heating do you have for it?</span>
                    <div class="custom-checkbox-align">
                        <br />
                        <div class="checkbox checkbox-primary">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="poolHeatingSolar"
                                formControlName="poolHeatingSolar"
                                [(ngModel)]="energyProfileForm.energyProfile.poolHeatingSolar"
                                [attr.disabled]="(energyProfileForm.energyProfile.poolHeatingNone == true) ? true : null"
                                (change)="energyProfileForm.resetCheckboxValidation()"
                                tabindex="7">
                            <label for="poolHeatingSolar">
                                Solar Heating
                            </label>
                        </div>
                        <div class="checkbox checkbox-primary">
                            <input
                                *ngIf="energyProfileForm.energyProfile.gasConnection != '4'"
                                type="checkbox"
                                class="form-check-input"
                                id="poolHeatingGas"
                                formControlName="poolHeatingGas"
                                [(ngModel)]="energyProfileForm.energyProfile.poolHeatingGas"
                                [attr.disabled]="(energyProfileForm.energyProfile.poolHeatingNone == true) ? true : null"
                                (change)="energyProfileForm.resetCheckboxValidation()"
                                tabindex="9">
                            <label for="poolHeatingGas" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                                Gas Heating
                            </label>
                        </div>
                        <div class="checkbox checkbox-primary">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="poolHeatingElectric"
                                formControlName="poolHeatingElectric"
                                [(ngModel)]="energyProfileForm.energyProfile.poolHeatingElectric"
                                [attr.disabled]="(energyProfileForm.energyProfile.poolHeatingNone == true) ? true : null"
                                (change)="energyProfileForm.resetCheckboxValidation()"
                                tabindex="8">
                            <label for="poolHeatingElectric">
                                Electric Heating
                            </label>
                        </div>
                        <div class="checkbox checkbox-primary">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="poolHeatingNone"
                                formControlName="poolHeatingNone"
                                [(ngModel)]="energyProfileForm.energyProfile.poolHeatingNone"
                                (change)="energyProfileForm.resetCheckboxValidation()"
                                (click)="energyProfileForm.resetCheckbox('poolHeating');"
                                tabindex="10">
                            <label for="poolHeatingNone">
                                None
                            </label>
                        </div>

                        <span class="text-danger-questionnaire" *ngIf="energyProfileForm.poolValidation != ''">
                            {{ energyProfileForm.poolValidation }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        How do you heat your home?
                        <br />
                        - tick all that apply
                    </label>
                </div>
                <div class="col-md-6 custom-checkbox-align">
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingElectricDucted"
                            formControlName="spaceHeatingElectricDucted"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingElectricDucted"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="11">
                        <label for="spaceHeatingElectricDucted">
                            Electric ducted central heating
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingSplitSystem"
                            formControlName="spaceHeatingSplitSystem"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingSplitSystem"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="12">
                        <label for="spaceHeatingSplitSystem">
                            Split system air conditioning unit that you use as a heater
                            <i
                                onclick="return false"
                                class="material-icons medium-tooltip black"
                                data-toggle="tooltip"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="A split system air conditioning unit has two parts. The cooling system is outside your house. The fan is mounted on a wall inside your house." data-original-title="A split system air conditioning unit has two parts. The cooling system is outside your house. The fan is mounted on a wall inside your house."
                                aria-label="A split system air conditioning unit has two parts. The cooling system is outside your house. The fan is mounted on a wall inside your house." data-original-title="A split system air conditioning unit has two parts. The cooling system is outside your house. The fan is mounted on a wall inside your house.">info</i>
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingElectricUnderfloor"
                            formControlName="spaceHeatingElectricUnderfloor"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingElectricUnderfloor"
                            (click)="energyProfileForm.clearControllLoad()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="13">
                        <label for="spaceHeatingElectricUnderfloor">
                            Electric under floor heating
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingElectricIndividual"
                            formControlName="spaceHeatingElectricIndividual"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingElectricIndividual"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="14">
                        <label for="spaceHeatingElectricIndividual">
                            Individual electric room heater(s) in regular use
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            *ngIf="energyProfileForm.energyProfile.gasConnection != '4'"
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingGasDucted"
                            formControlName="spaceHeatingGasDucted"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingGasDucted"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="15">
                        <label for="spaceHeatingGasDucted" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                            Gas ducted central heating
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            *ngIf="energyProfileForm.energyProfile.gasConnection != '4'"
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingGasUnderfloor"
                            formControlName="spaceHeatingGasUnderfloor"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingGasUnderfloor"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="16">
                        <label for="spaceHeatingGasUnderfloor" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                            Gas slab heating
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            *ngIf="energyProfileForm.energyProfile.gasConnection != '4'"
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingGasIndividual"
                            formControlName="spaceHeatingGasIndividual"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingGasIndividual"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="17">
                        <label for="spaceHeatingGasIndividual" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                            Individual gas room heater(s) in regular use
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingOther"
                            formControlName="spaceHeatingOther"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingOther"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceHeatingNone == true) ? true : null"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            tabindex="18">
                        <label for="spaceHeatingOther">
                            Other heating
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceHeatingNone"
                            formControlName="spaceHeatingNone"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceHeatingNone"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            (click)="energyProfileForm.resetCheckbox('space')"
                            tabindex="19">
                        <label for="spaceHeatingNone">
                            None
                        </label>
                    </div>

                    <span class="text-danger-questionnaire" *ngIf="energyProfileForm.spaceValidation != ''">
                        {{ energyProfileForm.spaceValidation }}
                    </span>
                </div>
            </div>

            <div class="row form-group" *ngIf="energyProfileForm.energyProfile.energyType == 'Gas' && (energyProfileForm.energyProfile.spaceHeatingGasDucted == true || energyProfileForm.energyProfile.spaceHeatingGasUnderfloor == true || energyProfileForm.energyProfile.spaceHeatingGasIndividual == true)">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        How much of the time do you run your gas heating in winter?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='gasUsageWinter' class="form-control select-questionnaire" [(ngModel)]="energyProfileForm.energyProfile.gasUsageWinter" tabindex="20">
                            <option value="0" selected>None</option>
                            <option value="1">Up to a quarter of the time</option>
                            <option value="2">Between a quarter and half the time</option>
                            <option value="3">About half the time</option>
                            <option value="4">More than half the time</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>
            <div class="row form-group" *ngIf="energyProfileForm.energyProfile.energyType != 'Gas'">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        How do you cool your home?
                        <br />
                        - tick all that apply
                    </label>
                </div>
                <div class="col-md-6 custom-checkbox-align">
                    <div class="checkbox checkbox-primary">
                        <input
                        type="checkbox"
                        class="form-check-input"
                        id="spaceCoolingRoomAC"
                        formControlName="spaceCoolingRoomAC"
                        [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingRoomAC"
                        (change)="energyProfileForm.resetCheckboxValidation()"
                        [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                        tabindex="21">
                        <label for="spaceCoolingRoomAC">
                            Room air conditioner
                            <i
                                onclick="return false"
                                class="material-icons medium-tooltip black"
                                data-toggle="tooltip"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="The cooling system is mounted on a wall inside your house."
                                aria-label="The cooling system is mounted on a wall inside your house.">info</i>
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingSplitSystem"
                            formControlName="spaceCoolingSplitSystem"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingSplitSystem"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                            tabindex="22">
                        <label for="spaceCoolingSplitSystem">
                            Split system
                            <i
                                onclick="return false"
                                class="material-icons medium-tooltip black"
                                data-toggle="tooltip"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="A split system air conditioning unit has two parts. The cooling system is outside your house. The fan is mounted on a wall inside your house."
                                aria-label="A split system air conditioning unit has two parts. The cooling system is outside your house. The fan is mounted on a wall inside your house.">info</i>
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingDuctedReverse"
                            formControlName="spaceCoolingDuctedReverse"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingDuctedReverse"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                            tabindex="23">
                        <label for="spaceCoolingDuctedReverse">
                            Ducted reverse cycle (refrigerated) air conditioner
                            <i
                                onclick="return false"
                                class="material-icons medium-tooltip black"
                                data-toggle="tooltip"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="A ducted air conditioning system has an indoor part, usually in the roof of your house. The cool air travels through ducts in the roof and comes into the rooms through air vents in the ceiling. Reverse cycle means the system can cool your house and heat your house."
                                aria-label="A ducted air conditioning system has an indoor part, usually in the roof of your house. The cool air travels through ducts in the roof and comes into the rooms through air vents in the ceiling. Reverse cycle means the system can cool your house and heat your house.">info</i>
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingDuctedEvaporative"
                            formControlName="spaceCoolingDuctedEvaporative"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingDuctedEvaporative"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                            tabindex="24">
                        <label for="spaceCoolingDuctedEvaporative">
                            Ducted evaporative cooler
                            <i
                                onclick="return false"
                                class="material-icons medium-tooltip black"
                                data-toggle="tooltip"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="A ducted air conditioning system has an indoor part, usually in the roof of your house. The cool air travels through ducts in the roof and comes into the rooms through air vents in the ceiling. Evaporative means the system can only cool your house. Evaporative air conditioning can not heat your house."
                                aria-label="A ducted air conditioning system has an indoor part, usually in the roof of your house. The cool air travels through ducts in the roof and comes into the rooms through air vents in the ceiling. Evaporative means the system can only cool your house. Evaporative air conditioning can not heat your house.">info</i>
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingPortableRef"
                            formControlName="spaceCoolingPortableRef"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingPortableRef"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                            tabindex="25">
                        <label for="spaceCoolingPortableRef">
                            Portable refrigerated
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingPortableEvap"
                            formControlName="spaceCoolingPortableEvap"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingPortableEvap"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                            tabindex="26">
                        <label for="spaceCoolingPortableEvap">
                            Portable evaporative
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingOther"
                            formControlName="spaceCoolingOther"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingOther"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [attr.disabled]="(energyProfileForm.energyProfile.spaceCoolingNone == true) ? true : null"
                            tabindex="28">
                        <label for="spaceCoolingOther">
                            Other
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="spaceCoolingNone"
                            formControlName="spaceCoolingNone"
                            [(ngModel)]="energyProfileForm.energyProfile.spaceCoolingNone"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            (click)="energyProfileForm.resetCheckbox('home')"
                            tabindex="29">
                        <label for="spaceCoolingNone">
                            None
                        </label>
                    </div>

                    <span class="text-danger-questionnaire" *ngIf="energyProfileForm.homeValidation != ''">
                        {{ energyProfileForm.homeValidation }}
                    </span>
                </div>
            </div>

            <div class="row form-group page-divider-up">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        Do you have a clothes dryer?
                    </label>
                </div>
                <div class="col-md-6">
                    <div class="radio-inline col-lg-7 vertical-align no-padding">
                        <input formControlName="clothesDryer" type="radio" id="clothesDryerYes" [(ngModel)]="energyProfileForm.energyProfile.clothesDryer" value="1">
                        <label for="clothesDryerYes" class="custom-btn-default custom-btn-text" tabindex="30" (keydown)="energyProfileForm.selectButton($event, 'clothesDryerYes')">Yes</label>
                        <input formControlName="clothesDryer" type="radio" id="clothesDryerNo" [(ngModel)]="energyProfileForm.energyProfile.clothesDryer" value="0">
                        <label for="clothesDryerNo" class="custom-btn-default custom-btn-text" tabindex="31" (keydown)="energyProfileForm.selectButton($event, 'clothesDryerNo')">No</label>
                    </div>
                    <bfv-messages></bfv-messages>

                    <div *ngIf="energyProfileForm.energyProfile.clothesDryer == '1'">
                        <br >
                        <div class="row form-group">
                            <div class="col-md-12">
                                How many times do you use your clothes dryer on a typical:
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-md-6">
                                <span class="strong">
                                    Week: (Monday-Friday)
                                </span>
                            </div>
                            <div class="col-md-6">
                                <span class="strong">
                                    Weekend: (Saturday-Sunday)
                                </span>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-md-6 form-group">
                                <label class="hidden-label">
                                    Weekday
                                </label>
                                <div class="select">
                                    <select
                                        aria-hidden="true"
                                        formControlName='clothesDryerWeekday'
                                        class="form-control select-questionnaire"
                                        [(ngModel)]="energyProfileForm.energyProfile.clothesDryerWeekday"
                                        tabindex="32">
                                        <option value="1">None - I have a clothes dryer but don't use it on these days</option>
                                        <option value="2">Once</option>
                                        <option value="3">Twice or more</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="hidden-label">
                                    Weekend
                                </label>
                                <div class="select">
                                    <select
                                        aria-hidden="true"
                                        formControlName='clothesDryerWeekend'
                                        class="form-control select-questionnaire"
                                        [(ngModel)]="energyProfileForm.energyProfile.clothesDryerWeekend"
                                        tabindex="33">
                                        <option value="1">None - I have a clothes dryer but don't use it on these days</option>
                                        <option value="2">Once</option>
                                        <option value="3">Twice or more</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        Which hot water systems do you use?
                    </label>
                </div>
                <div class="col-md-6 custom-checkbox-align">
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="waterHeatingElectric"
                            formControlName="waterHeatingElectric"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            (click)="energyProfileForm.clearControllLoad()"
                            [(ngModel)]="energyProfileForm.energyProfile.waterHeatingElectric"
                            tabindex="34">
                        <label for="waterHeatingElectric">
                            Electric
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="waterHeatingElectricSolar"
                            formControlName="waterHeatingElectricSolar"
                            (click)="energyProfileForm.clearControllLoad()"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [(ngModel)]="energyProfileForm.energyProfile.waterHeatingElectricSolar"
                            tabindex="35">
                        <label for="waterHeatingElectricSolar">
                            Electric and Solar
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="waterHeatingGasStorage"
                            formControlName="waterHeatingGasStorage"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [(ngModel)]="energyProfileForm.energyProfile.waterHeatingGasStorage"
                            tabindex="36">
                        <label for="waterHeatingGasStorage">
                            Gas storage
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="waterHeatingGasInstant"
                            formControlName="waterHeatingGasInstant"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [(ngModel)]="energyProfileForm.energyProfile.waterHeatingGasInstant"
                            tabindex="37">
                        <label for="waterHeatingGasInstant">
                            Gas instantaneous
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary" *ngIf="energyProfileForm.energyProfile.gasConnection != '4'">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="waterHeatingGasSolar"
                            formControlName="waterHeatingGasSolar"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [(ngModel)]="energyProfileForm.energyProfile.waterHeatingGasSolar"
                            tabindex="38">
                        <label for="waterHeatingGasSolar">
                            Gas and Solar
                        </label>
                    </div>
                    <div class="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="waterHeatingOther"
                            formControlName="waterHeatingOther"
                            (change)="energyProfileForm.resetCheckboxValidation()"
                            [(ngModel)]="energyProfileForm.energyProfile.waterHeatingOther"
                            tabindex="39">
                        <label for="waterHeatingOther">
                            Other
                        </label>
                    </div>

                    <span class="text-danger-questionnaire" *ngIf="energyProfileForm.waterHeatingValidation != ''">
                        {{ energyProfileForm.waterHeatingValidation }}
                    </span>
                </div>
            </div>

            <div class="row form-group" *ngIf="energyProfileForm.energyProfile.waterHeatingElectric == true || energyProfileForm.energyProfile.waterHeatingElectricSolar == true || energyProfileForm.energyProfile.spaceHeatingElectricUnderfloor == true">
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        Do you have a controlled load connected to your house?
                        <i
                            onclick="return false"
                            class="material-icons black"
                            data-toggle="tooltip"
                            [triggers]="utility.toolTipForMobile()"
                            ngbTooltip="Controlled load is a separate stream of electricity. Controlled load is also called dedicated load. You may have controlled load if you use electric hot water or slab heating. Your electricity retailer charges you a different rate for your general electricity usage and your controlled load electricity usage. If you have a controlled load, it will say 'controlled load' or 'dedicated load' on your bill."
                            aria-label="Controlled load is a separate stream of electricity. Controlled load is also called dedicated load. You may have controlled load if you use electric hot water or slab heating. Your electricity retailer charges you a different rate for your general electricity usage and your controlled load electricity usage. If you have a controlled load, it will say 'controlled load' or 'dedicated load' on your bill.">info</i>
                    </label>
                </div>
                <div class="col-md-6 form-group">
                    <label class='hidden-label'>
                        Do you have a controlled load connected to your house?
                    </label>
                    <div class="radio-inline vertical-align">
                        <input formControlName="controlledLoad" type="radio" id="controlledLoadYes" [(ngModel)]="energyProfileForm.energyProfile.controlledLoad" value="1">
                        <label for="controlledLoadYes" class="custom-btn-default custom-btn-text" tabindex="40" (keydown)="energyProfileForm.selectButton($event, 'controlledLoadYes')">Yes</label>
                        <input formControlName="controlledLoad" type="radio" id="controlledLoadNo" [(ngModel)]="energyProfileForm.energyProfile.controlledLoad" value="0">
                        <label for="controlledLoadNo" class="custom-btn-default custom-btn-text" tabindex="41" (keydown)="energyProfileForm.selectButton($event, 'controlledLoadNo')">No</label>
                    </div>
                </div>
            </div>

            <div *ngIf="energyProfileForm.energyProfile.energyType != 'Gas' && energyProfileForm.energyProfile.hasSolar == '1'">
                <div class="row form-group page-divider-up">
                    <div class="col-md-6">
                        <label class='h3 questionnaire-form-label'>
                            How far is your home from the sea?
                        </label>
                    </div>
                    <div class="col-md-6 custom-select-align">
                        <div class="select">
                            <select
                                aria-hidden="true"
                                formControlName='seaDistance'
                                class="form-control select-questionnaire"
                                [(ngModel)]="energyProfileForm.energyProfile.seaDistance"
                                tabindex="42">
                                <option value="">Please select one</option>
                                <option value="0">Don't know</option>
                                <option value="1">Less than 5km</option>
                                <option value="2">5km to 20 km</option>
                                <option value="3">20km to 50 km</option>
                                <option value="4">50km to 100 km</option>
                                <option value="5">Greater than 100 km</option>
                            </select>
                        </div>
                        <bfv-messages></bfv-messages>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-6">
                        <label class='h3 questionnaire-form-label'>
                            Do you have a washing machine?
                        </label>
                    </div>
                    <div class="col-md-6">
                        <div class="radio-inline col-lg-7 vertical-align no-padding">
                            <input formControlName="washingMachineUsage" type="radio" id="washingMachineUsageYes" [(ngModel)]="energyProfileForm.energyProfile.washingMachineUsage" value="1">
                            <label for="washingMachineUsageYes" class="custom-btn-default custom-btn-text" tabindex="43" (keydown)="energyProfileForm.selectButton($event, 'washingMachineUsageYes')">Yes</label>
                            <input formControlName="washingMachineUsage" type="radio" id="washingMachineUsageNo" [(ngModel)]="energyProfileForm.energyProfile.washingMachineUsage" value="0">
                            <label for="washingMachineUsageNo" class="custom-btn-default custom-btn-text" tabindex="44" (keydown)="energyProfileForm.selectButton($event, 'washingMachineUsageNo')">No</label>
                        </div>
                        <bfv-messages></bfv-messages>
                        <div *ngIf="energyProfileForm.energyProfile.washingMachineUsage == '1'">
                            <br >
                            <div class="row form-group">
                                <div class="col-md-12">
                                    How many times do you use your washing machine on a typical:
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-6">
                                    <span class="strong">
                                        Week: (Monday-Friday)
                                    </span>
                                </div>
                                <div class="col-md-6">
                                    <span class="strong">
                                        Weekend: (Saturday-Sunday)
                                    </span>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-6 form-group">
                                    <label class="hidden-label">
                                        Weekday
                                    </label>
                                    <div class="select">
                                        <select
                                            aria-hidden="true"
                                            formControlName='washingMachineWeekday'
                                            class="form-control select-questionnaire"
                                            [(ngModel)]="energyProfileForm.energyProfile.washingMachineWeekday"
                                            tabindex="45">
                                            <option value="1">None - I have a machine but don't use it on these days</option>
                                            <option value="2">Once</option>
                                            <option value="3">2 or 3</option>
                                            <option value="4">4 or 5</option>
                                            <option value="5">6 or more</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="hidden-label">
                                        Weekend
                                    </label>
                                    <div class="select">
                                        <select
                                            aria-hidden="true"
                                            formControlName='washingMachineWeekend'
                                            class="form-control select-questionnaire"
                                            [(ngModel)]="energyProfileForm.energyProfile.washingMachineWeekend"
                                            tabindex="46">
                                            <option value="1">None - I have a machine but don't use it on these days</option>
                                            <option value="2">Once</option>
                                            <option value="3">2 or 3</option>
                                            <option value="4">4 or 5</option>
                                            <option value="5">6 or more</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-group page-divider-up">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <button
                        type="submit"
                        class="energy-questionnaire-button custom-button profile-btn custom-btn-large custom-btn-large-text col-lg-12"
                        tabindex="47"
                        (keydown)="energyProfileForm.selectButton($event, 'saveData')"
                        (click)="energyProfileForm.saveData()">
                        Next</button>
                </div>
            </div>
        </form>
    </div>

</div>
<br /><br />
