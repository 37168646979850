<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <img
                class="hottopic-image"
                src="assets/images/{{ secImageURL }}contentpages/hottopics_header_6.jpg"
                alt="The Victorian Default Offer makes electricity bills fair">
            <p>
                We're making energy prices fairer for Victorian households and businesses.
            </p>
            <p>
                From 1 July 2019, if you are on a simple standing offer for your electricity, you will be rolled over to a
                better, fairer plan. It's called the Victorian Default Offer, or the VDO.
            </p>
            <p>
                It could save you hundreds of dollars.
            </p>
            <br>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-save')">How much can I save?</a>
            </p>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-why')"> Why are we introducing the Victorian Default Offer?</a>
            </p>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-how')"> How do I know if I'm on a simple standing offer?</a>
            </p>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-not')">I'm not on a simple standing offer. How can I save?</a>
            </p>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-qualify')">Do I qualify for the VDO?</a>
            </p>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-better')">Is the Victorian Default Offer better than my current deal?</a>
            </p>
            <p>
                <a class="hot-topics" (click)="utility.scrollToId('hot-topics-more')"> More information</a>
            </p>

            <br /><br />
            <h3 id="hot-topics-save">
                How much can I save?
            </h3>
            <p>
                A typical household could save up to $450 per year, when compared with the median standing offer in your area.
                A typical small business could save up to $2,050 each year.
            </p>

            <br />
            <h3 id="hot-topics-why">
                Why are we introducing the Victorian Default Offer?
            </h3>
            <p>
                The Victorian Default Offer is being introduced after an independent review into energy prices found that
                Victorians were paying more than they should for electricity. The VDO is part of the government's Energy
                Fairness Plan to make the electricity market simpler and more affordable for all consumers.
            </p>

            <br /><br />
            <h3 id="hot-topics-how">
                How do I know if I'm on a simple standing offer?
            </h3>
            <p>
                From 1 July, your energy provider must automatically move customers on existing simple standing offers to the
                VDO. If you think you haven't been moved, you can call your energy provider or check your next electricity
                bill.
            </p>
            <p>
                If you don't know which deal you're on, your provider may have automatically put you on a costly flat tariff
                standing offer for electricity. These standing offers are set by the energy provider and are usually much higher
                than the energy provider's initial cheaper deal.
            </p>

            <br /><br />
            <h3 id="hot-topics-not">
                I'm not on a simple standing offer. How can I save?
            </h3>
            <p>
                If you're not on a simple standing offer, you can still opt in by calling your energy provider after 1 July 2019
                and asking to be put on the Victorian Default Offer.
            </p>
            <p>
                The VDO is a fair offer but not necessarily the cheapest offer available to you. If you opt in to the Victorian
                Default Offer, you will be able to leave at any time and access other offers in the market.
            </p>
            <p>
                From 1 July, on every bill you receive, your energy provider must show you the best energy offer your household
                or business could receive, which means you can compare which offer is best and fairest for you.
            </p>
            <p>
                You can also call your energy provider to ask for their best deal.
            </p>
            <p>
                If you're not sure, visit <a class="hot-topics" href="{{ appURL }}">Victorian Energy Compare</a>.
                It provides free, independent advice by comparing what is the best available offer for you – no tricks, no gimmicks.
            </p>

            <br /><br />
            <h3 id="hot-topics-qualify">
                Do I qualify for the VDO?
            </h3>
            <p>
                The VDO is available to all Victorian households and small business customers from 1 July 2019. However, if you
                live in an apartment block, retirement home, caravan park, or you're a small business tenant in a shopping
                centre, you may be receiving your electricity through an embedded network. Embedded networks can't access the
                Victorian Default Offer at this stage. The Essential Services Commission will review the price cap for customers
                in embedded networks as soon as possible.
            </p>
            <p>
                You can check whether electricity at your address is supplied by an embedded network at the
                <a class="hot-topics" href="http://www.esc.vic.gov.au/energy-licence-exemptions">Essential services commission register</a>
            </p>
            <p>
                The government has committed to ban embedded networks in new-build residential apartment blocks, which too often
                lock in high costs for consumers. Exemptions will be allowed for buildings that use renewable energy micro grids
                to deliver low-cost renewable energy to apartment blocks.
            </p>

            <br /><br />
            <h3 id="hot-topics-better">
                Is the Victorian Default Offer better than my current deal?
            </h3>
            <p>
                The Victorian Default Offer is a fair price for energy – but not necessary the best price. If you are a Victorian
                household or small business customer, you can compare the tariffs on your electricity bill to those in the
                tables <a class="hot-topics" href="https://www.esc.vic.gov.au/making-most-victorian-default-offer#toc-what-are-the-prices-">here</a>
                to help you decide whether the Victorian Default Offer may be better than your current deal.
            </p>

            <br /><br />
            <h3 id="hot-topics-more">
                Need more information?
            </h3>
            <p>
                More tips to help you understand the VDO:
            </p>

            <br /><br />
            <h4>Households</h4>
            <ul>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/Tips-for-Victorian-households_JulyUpdate_V1.pdf" target="_blank">
                        Tips for Victorian households (PDF, 614.5 KB)
                    </a>
                </li>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/Victorian-Default-Offer-Tips-for-Victorian-households-fact-sheet.docx" target="_blank">
                        Tips for Victorian households (DOCX, 164.7 KB)
                    </a>
                </li>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/VDO-Extended-FAQs_Households.docx" target="_blank">
                        Extended FAQ's for Victorian households (DOCX, 165.8 KB)
                    </a>
                </li>
            </ul>

            <h4>Small businesses</h4>
            <ul>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/Tips-for-small-business-electricity-customers_JulyUpdate_V1.pdf" target="_blank">
                        Tips for Small businesses (PDF, 523.2 KB)
                    </a>
                </li>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/Victorian-Default-Offer-Small-business-consumer-facing-fact-sheet.docx" target="_blank">
                        Tips for Small businesses (DOCX, 165.0 KB)
                    </a>
                </li>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/VDO-Extended-FAQs_Small-Businesses.docx" target="_blank">
                        Extended FAQ's for Small businesses (DOCX, 165.7 KB)
                    </a>
                </li>
            </ul>

            <h4>Embedded networks</h4>
            <ul>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/Tips-for-Embedded-networks.pdf" target="_blank">
                        Tips for Customers in embedded networks (PDF, 596.6 KB)
                    </a>
                </li>
                <li>
                    <!-- pending implement event tracker-->
                    <a class="hot-topics" href="assets/files/Victorian-Default-Offer-Embedded-Network-consumer-fact-sheet_Revised.docx" target="_blank">
                        Tips for Customers in embedded networks (DOCX, 163.6 KB)
                    </a>
                </li>
            </ul>

            <p>
                If you would like further information on the Victorian Default Offer contact the Department of Energy, Environment and Climate Action on <a class="hot-topics" href="tel:1800000832">1800 000 VEC (832)</a>.
            </p>
            <br />

            <p>
                <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
            </p>
        </div>
    </div>
</div>
<br /><br />
