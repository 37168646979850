import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from '../utility';
import { SolarCalculator } from '../solarCalculator';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment'; //config file
import { HasSolarModalComponent } from '../has-solar-modal/has-solar-modal.component';
import { SolarOptionsModalComponent } from '../solar-options-modal/solar-options-modal.component';

@Component({
  selector: 'app-solar-results',
  templateUrl: './solar-results.component.html',
  styleUrls: ['./solar-results.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class SolarResultsComponent implements OnInit {
  @ViewChild('div-results') public mydiv: ElementRef;

  constructor(public dataService : DataService,
              private router : Router,
              private spinner : NgxSpinnerService,
              public utility : Utility,
              private solarCalculator : SolarCalculator,
              public modalService: NgbModal,
              private cd: ChangeDetectorRef) {}
  public solarCompareTable = '';
  public appURL = environment.appURL;
  private showSolarModal = true;
  public compareURL = environment.compareURL;
  public apiEndpoint = environment.apiEndpoint;
  public pageIsloaded = false;
  public SolarEstimatesData = {};
  private hasElectricityData = false;
  private hasGasData = false;
  public solarData = {
    offPeakRate : -1,
    offPeakRateValue : '',
    shoulderRate : -1,
    shoulderRateValue : '',
    discount : -1,
    discountRateValue : '',
    systemSize : 6.6,
    systemCost : 9240,
    peakRateValue : '',
    dailySupplyCharge : '',
    fitRates : {},
    tariffs : {},
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 5.2,
    serverCacheId : ""
  };
  public solarData1 = {};
  public solarData2 = {};
  public solarData3 = {};
  public selectedOption = 1;
  public selectedSolarData = {};

  public changeOption(selectedOption) {
    this.selectedOption = selectedOption;
    this.getSolarEstimates();
  }

  private getSelectedData() {
    if (this.selectedOption == 1) {
      this.selectedSolarData = this.solarData1;
    } else if (this.selectedOption == 2) {
      this.selectedSolarData = this.solarData2;
    } else {
      this.selectedSolarData = this.solarData3;
    }
  }

  public downloadOptionsModal() {
    let modalRef = this.modalService.open(SolarOptionsModalComponent, { size: 'xl', windowClass:'slideInUp' });
    modalRef.componentInstance.selectedOption = this.selectedOption;
    modalRef.componentInstance.solarData1 = this.solarData1;
    modalRef.componentInstance.solarData2 = this.solarData2;
    modalRef.componentInstance.solarData3 = this.solarData3;
    modalRef.result.then((result) => {
      if (result != 'none') {
        this.downloadPDF(result);
      }
    }, (reason) => {
    });
  }

  public emailOptionsModal() {
    let modalRef = this.modalService.open(SolarOptionsModalComponent, { size: 'xl', windowClass:'slideInUp' });
    modalRef.componentInstance.selectedOption = this.selectedOption;
    modalRef.componentInstance.solarData1 = this.solarData1;
    modalRef.componentInstance.solarData2 = this.solarData2;
    modalRef.componentInstance.solarData3 = this.solarData3;
    modalRef.result.then((result) => {
      if (result != 'none') {
        this.emailModal(result);
      }
    }, (reason) => {
    });
  }

  public openHasSolarModal() {
    if (this.showSolarModal == true) {
      this.modalService.open(HasSolarModalComponent, { size: 'lg', windowClass:'slideInUp'});
      this.showSolarModal = false;
    }
  }

  public updateEstimates(SolarEstimatesData) {
    this.solarCompareTable = '';
    this.SolarEstimatesData = SolarEstimatesData;
  }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.dataService.changeBillPage('false');
    this.dataService.changeEnergyProfilePage('false');
    this.dataService.changeEnergyType('Solar');
    this.modalService.dismissAll(); //close modal dialog if there are any open
    document.title = 'Your Energy Profile - Victorian Energy Compare';
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheID is defined get saved data from the server
    if (serverCacheId != null) {
      this.solarData.serverCacheId = serverCacheId;
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.existing == false) {
            this.utility.sessionRestart();
            this.utility.removeServerCacheId(serverCacheId);
            this.utility.redirectToSolarHome();
          } else {
            //check if theres no saved form data
            if (results.loopBackData.hasOwnProperty('energyConfigData') == false && results.energyConfigData.hasOwnProperty('energyType') == false) {
              this.utility.redirectToSolarHome();
            }

            if (results.energyConfigData.energyType == 'Electricity') {
              this.hasElectricityData = true;
            }
            if (results.energyConfigData.energyType == 'Gas') {
              this.hasGasData = true;
            }
            if (results.loopBackData.energyConfigData.hasOwnProperty('energyType') == true && results.loopBackData.energyConfigData.energyType == 'Electricity') {
              this.hasElectricityData = true;
            }
            if (results.loopBackData.energyConfigData.hasOwnProperty('energyType') == true && results.loopBackData.energyConfigData.energyType == 'Gas') {
              this.hasGasData = true;
            }

            let data = results.solarData;
            if (results.billDetailsData.billStartDate != undefined) {
              this.dataService.changeBillPage('true');
            }
            if (results.energyProfileData.energyType != undefined) {
              this.dataService.changeEnergyProfilePage('true');
            }

            this.solarData.offPeakRate = data.offPeakRate;
            this.solarData.offPeakRateValue = data.offPeakRateValue;
            this.solarData.shoulderRate = data.shoulderRate;
            this.solarData.shoulderRateValue = (data.shoulderRateValue != undefined) ? data.shoulderRateValue : '';
            this.solarData.discount = data.discount;
            this.solarData.discountRateValue = (data.discountRateValue != undefined) ? data.discountRateValue : '';
            this.solarData.peakRateValue = (data.peakRateValue != undefined) ? data.peakRateValue : '';
            this.solarData.dailySupplyCharge = data.dailySupplyCharge;

            this.solarData1 = JSON.parse(JSON.stringify(this.solarData));
            this.solarData2 = JSON.parse(JSON.stringify(this.solarData));
            this.solarData3 = JSON.parse(JSON.stringify(this.solarData));
            this.solarData2['systemSize'] = 3.6;
            this.solarData2['systemCost'] = 5400;
            this.solarData3['systemSize'] = 10;
            this.solarData3['systemCost'] = 14000;
            this.getSolarEstimates();
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.utility.redirectToSolarHome();
        }
      );
    } else {
      this.utility.redirectToSolarHome();
    }
  }

  public getSolarEstimates() {
    this.utility.setErrorMessage('');
    this.spinner.show();

    this.getSelectedData();
    this.selectedSolarData['fitRates'] = this.solarCalculator.getFitRates(
      this.selectedSolarData['fitPeak'],
      this.selectedSolarData['fitOffPeak'],
      this.selectedSolarData['fitShoulder']
    );
    this.selectedSolarData['tariffs'] = this.solarCalculator.getTariffs(
      this.selectedSolarData['peakRateValue'],
      this.selectedSolarData['offPeakRateValue'],
      this.selectedSolarData['shoulderRateValue']
    );
    this.dataService.getSolarEstimates(this.selectedSolarData)
    .subscribe(
      data => {
        if (data['status'] == 'error') {
          this.showError();
        } else {
          try {
            this.pageIsloaded = true;
            this.SolarEstimatesData = data['data'];
            if (this.SolarEstimatesData['consumptionGeneration'] == undefined) {
              this.showError();
            } else {
              if (data['hasSolar'] == true) {
                this.openHasSolarModal();
              }
              var root = this;
              setTimeout(function() {
                root.spinner.hide();
              }, 1000);
            }
          } catch(e) {
            this.showError();
          }
        }
      },
      error => {
        this.showError();
      },
    );
  }

  private showError() {
    this.utility.setErrorMessage(" An error has occurred while trying to process the data, please try again later");
    this.utility.scrollToTop("none");
    this.spinner.hide();
  }

  public downloadPDF(result) {
    this.spinner.show();
    this.dataService.download = true;
    var root = this;
    var html;
    let allOptions = (result["selectedOption"] == 0) ? true : false;

    if (allOptions == true) { //process all options
      var html1, html2, html3;
      setTimeout(() => {
        root.SolarEstimatesData = result["solarEstimatesData1"];
        root.selectedSolarData = root.solarData1;
      }, 10);

      setTimeout(() => {
        html1 = this.generateHTMLPDF();
      }, 110);

      setTimeout(() => {
        root.SolarEstimatesData = result["solarEstimatesData2"];
        root.selectedSolarData = root.solarData2;
      }, 200);

      setTimeout(() => {
        html2 = this.generateHTMLPDF();
      }, 300);

      setTimeout(() => {
        root.SolarEstimatesData = result["solarEstimatesData3"];
        root.selectedSolarData = root.solarData3;
      }, 400);

      setTimeout(() => {
        html3 = this.generateHTMLPDF();
      }, 500);

      this.solarCompareTable = result["solarCompareTable"];
      setTimeout(() => {
        root.selectedOption = 3;
        html = html1 + html2 + html3;
        this.initiatePDFDownload(html);
      }, 600);

    } else {
      this.solarCompareTable = '';
      root.selectedOption = result["selectedOption"];
      if (result["selectedOption"] == 1) {
        root.SolarEstimatesData = result["solarEstimatesData1"];
        root.selectedSolarData = root.solarData1;
      } else if (result["selectedOption"] == 2) {
        root.SolarEstimatesData = result["solarEstimatesData2"];
        root.selectedSolarData = root.solarData2;
      } else {
        root.SolarEstimatesData = result["solarEstimatesData3"];
        root.selectedSolarData = root.solarData3;
      }
      setTimeout(() => {
        html = this.generateHTMLPDF();
        html += result["solarCompareTable"];
      }, 100);

      setTimeout(() => {
        this.initiatePDFDownload(html);
      }, 200);

    }
  }

  public initiatePDFDownload(html) {
    var root = this;
    setTimeout(() => {
      root.dataService.download = false;
      root.cd.detectChanges();
      //call API to generate PDF
      //create form and make a post call to the api
      var form = document.createElement('form');
      form.setAttribute('id', 'pdf-form');
      document.body.appendChild(form);
      //if device is mobile or using ie open a new window
      var ua = window.navigator.userAgent;
      var isIE = /MSIE|Trident/.test(ua);
      if (screen.width <= 420 || isIE == true) { //if mobile or IE
        form.setAttribute('target', '_blank');
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'mobile';
        input.value = '1';
        form.appendChild(input);
      }

      form.method = 'POST';
      form.action = root.apiEndpoint + 'solar-savings.pdf';
      var input2 = document.createElement('input');
      input2.type = 'hidden';
      input2.name = 'html';
      input2.value = html;
      form.appendChild(input2);
      form.submit();
      setTimeout(function() {
        root.spinner.hide(); //hide loader
      }, 2000);
    }, 100);
  }

  public emailModal(result) {
    this.spinner.show();
    this.utility.setEmailErrorMessage('');
    this.dataService.download = true;
    var root = this;
    var html = '';
    let allOptions = (result["selectedOption"] == 0) ? true : false;

    if (allOptions == true) { //process all options
      var html1, html2, html3;
      setTimeout(() => {
        root.SolarEstimatesData = result["solarEstimatesData1"];
        root.selectedSolarData = root.solarData1;
      }, 10);

      setTimeout(() => {
        html1 = this.generateHTMLPDF(true);
      }, 110);

      setTimeout(() => {
        root.SolarEstimatesData = result["solarEstimatesData2"];
        root.selectedSolarData = root.solarData2;
      }, 200);

      setTimeout(() => {
        html2 = this.generateHTMLPDF(true);
      }, 300);

      setTimeout(() => {
        root.SolarEstimatesData = result["solarEstimatesData3"];
        root.selectedSolarData = root.solarData3;
      }, 400);

      setTimeout(() => {
        html3 = this.generateHTMLPDF(true);
      }, 500);
      this.solarCompareTable = result["solarCompareTable"];
      setTimeout(() => {
        root.selectedOption = 3;
        html = html1 + html2 + html3;
        this.initiateEmailModal(html);
      }, 600);

    } else {
      this.solarCompareTable = "";
      root.selectedOption = result["selectedOption"];
      if (result["selectedOption"] == 1) {
        root.SolarEstimatesData = result["solarEstimatesData1"];
        root.selectedSolarData = root.solarData1;
      } else if (result["selectedOption"] == 2) {
        root.SolarEstimatesData = result["solarEstimatesData2"];
        root.selectedSolarData = root.solarData2;
      } else {
        root.SolarEstimatesData = result["solarEstimatesData3"];
        root.selectedSolarData = root.solarData3;
      }
      setTimeout(() => {
        html = root.generateHTMLPDF(true);
        html += result["solarCompareTable"];
      }, 100);

      setTimeout(() => {
        this.initiateEmailModal(html);
      }, 200);

    }
  }

  private generateHTMLPDF(email = false) {
    let html = document.getElementById("header").innerHTML + document.getElementById("container").innerHTML;
    if (email == true) {
      html += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/solar-email.css" />';
    } else {
      html += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/solar-print.css" />';
    }
    html += '<div id="footer">Victorian Energy Compare - ' + this.compareURL + '</div>';
    html += '<div style = "display:block; clear:both; page-break-after:always;"></div>';
    return html;
  }

  private initiateEmailModal(html) {
    var root = this;
    setTimeout(() => {
      root.dataService.download = false;
      root.cd.detectChanges();
      root.spinner.hide(); //hide loader
      root.utility.scrollToTop("none");
      const modalRef = root.modalService.open(EmailModalComponent);
      modalRef.componentInstance.html = html;
      modalRef.componentInstance.isSolar = true;
    }, 100);
  }

  public electricityOffers() {
    if (this.hasElectricityData == true) {
      this.router.navigate(['/offers']);
    } else {
      this.utility.resetPage();
    }
  }

  public gasOffers() {
    if (this.hasGasData == true) {
      this.router.navigate(['/offers']);
    } else {
      this.utility.resetPage();
    }
  }

}
