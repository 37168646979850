<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Collection statement for retrieving your smart meter data from AEMO</h2>
    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="strong">Collection Statement</p><br />
    <p>
        The Department of Energy, Environment and Climate Action (DEECA) is committed to protecting personal information provided by you in accordance with the principles of the Victorian privacy laws.
    </p><br />
    <p>
        The information you provide will be used to determine the energy offers available to you.
    </p><br />
    <p>
        The information you provide may be made available to Australian Energy Market Operator (AEMO) in order to retrieve your smart meter data on your behalf.
    </p><br />
    <p>
        This information is being collected in accordance with the Privacy and Data Protection Act 2014.
    </p><br />
    <p>
        If all requested information is not received, DEECA will be unable to provide you with accurate information about energy offers available to you.
    </p><br />
    <p>
        You may access the information you have provided to DEECA by contacting Victorian Energy Compare at info.vec@deeca.vic.gov.au
    </p>
</div>
<div class="modal-footer">
    <button title="Close" aria-label="Close" type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>
