<div class="modal-header">
    <div class="modal-icons col-lg-12">
        <h3 style="float: left;">Export options</h3>
        <button class="hidden-btn"></button>
        <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
            <i class="fa fa-times" aria-hidden="true"></i> close
        </button>
    </div>
</div>
<div class="modal-body">
    <div><p>Your requested information will download as comma-separated CSV file.</p></div>
    <br />
    <div class="row">
        <div class="col-md-1">
        </div>
        <div class="col-md-5">
            <label class="export-csv-button export-csv-button-txt btn-block x-custom-btn-large-text" (click)="exportOffers();">
                Export displayed offers
            </label>
        </div>
        <div class="col-md-5">
            <label class="export-csv-button export-csv-button-txt btn-block x-custom-btn-large-text" (click)="exportAllOffers();">
                Export all offers
            </label>
        </div>
    </div>
    <form method="post" #htmlForm id="exportForm" [formGroup]="exportForm" action="{{ apiEndpoint + 'export-offers' }}">
        <input type="hidden" formControlName="offerList" [(ngModel)]="offerData" name="offerList">
    </form>
</div>
