<div class="modal-header">
    <h3>Smart meter data gives you a more accurate, faster comparison</h3>
    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        Did you know that using your own smart meter data in Victorian Energy Compare will give you 
        the best electricity comparisons?
    </p>
    <br />
    <p>
        Selecting no to this question and answering the Victorian Energy Compare questionnaire will 
        still give you an accurate comparison, but using your smart meter data will provide the most accurate estimates and will be based 
        solely on your electricity usage habits.
    </p>
    <br />
    <p>
        <button 
            title="Download my data" 
            aria-label="Download my data" 
            class="link non-focus"
            (click)="downloadDataModal()">Show me how to download my data
        </button>
    </p>
</div>
<div class="modal-footer">
    <button 
        title="Close" 
        aria-label="Close" 
        type="button" 
        class="btn btn-outline-secondary" 
        (click)="activeModal.close('Close click')">Close</button>
</div>