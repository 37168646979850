<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="row privacy">
        <div class="col-lg-12 col-xs-12">
            <div>
                <label class="h3">Application of this privacy statement</label>
                <p>
                    This privacy statement applies only to the Victorian Energy Compare website. It is consistent
                    with the Department of Energy, Environment and Climate Action (DEECA - 'The Department') privacy
                    policy and applies to all Victorian Energy Compare activities.
                </p>
                <p>
                    In using the Victorian Energy Compare website, you will be asked to supply certain information for
                    the purposes of determining your estimated energy usage and profile, and communicating some of the
                    energy offers available to you. Any such information that you supply will only be used for these
                    purposes and any such information that is personal information will be held and used in accordance
                    with this privacy policy.
                </p>
                <p>
                    This website contains links to other websites. This privacy statement does not apply to those websites.
                </p>
                <p>
                    The Department's privacy policy is available here
                    <a href="https://www.deeca.vic.gov.au/doing-business-with-us/information-privacy" target="_blank" class="hot-topics">https://www.deeca.vic.gov.au/doing-business-with-us/information-privacy</a>
                </p>

                <label class="h3"><em>Privacy and Data Protection Act 2014</em> (Vic): Personal information</label>
                <p>
                    In this privacy statement the term 'personal information' has the meaning provided in section 3 of the
                    <i>Privacy and Data Protection Act 2014</i> (Vic).
                </p>
                <p>
                    Personal information is information or an opinion that is recorded in any form, whether true or not,
                    about an individual whose identity is apparent or can reasonably be ascertained.
                </p>
                <p>
                    This Act is the legislation that regulates our collection and handling of personal information.
                </p>
                <p>
                    Detailed information about the Act is available at the 
                    <a class="hot-topics" href="https://ovic.vic.gov.au/privacy/" target="_blank">Office of the Commissioner for Privacy and Data Protection</a>.
                </p>

                <label class="h3">Collection of personal information</label>
                <p>
                    It is intended that this website can be used anonymously. However, for some functions, the collection of personal
                    information is necessary or unavoidable. You will know if the Department is collecting personal information from
                    you because you will be requested to provide it.
                </p>
                There are two exceptions to this:
                <ol>
                    <li>
                        When someone else provides the Department with your personal information. The Department cannot prevent this.
                        However, you are entitled to access and to correct any personal information about you collected by the
                        Department
                    </li>
                    <li>
                        When the Department collects website visitation statistics using a web analytics service. For further
                        information regarding this, see the Website Usage Measurement section below.
                    </li>
                </ol>
                <p>
                    The statistics in No. 2 may qualify as personal information under the <i>Privacy and Data Protection Act 2014</i> 
                    (Vic). However, these statistics are not used to identify individual web users.
                </p>

                <h4 class="privacy-header-sub">Use and disclosure of personal information</h4>
                <p>
                    If you provide personal information, the Department's privacy policy will regulate its use and disclosure.
                    However, should the Department's intended use and disclosure of personal information deviate from the Department's
                    general policy, a privacy statement particular to the circumstances will be made available at the time of collection.
                </p>
                <p>
                    Personal information that is collected by the Department will be used by, and disclosed to, Departmental employees
                    or contractors whose duties require them to use it. Such employees and contractors are required to protect and
                    handle your personal information in accordance with the Privacy and Data Protection Act 2014 (Vic) and any other
                    applicable legislation regulating the collection, use, disclosure, storage and destruction of personal information.
                </p>

                <label class="h3">Access to and correction of personal information</label>
                <p>
                    You may request access to any personal information that the Department may have collected about you. Also, you may
                    request correction of your personal information if you can establish that it is not accurate, complete or up-to-date.
                    The Victorian Information Commissioner has recommended that the appropriate means to access and correct information held
                    by government departments is pursuant to the <i>Freedom of Information Act 1982</i> (Vic).
                </p>
                <p>
                    The Freedom of Information Officer may be contacted on the details below about access to, and correction of, personal
                    information collected by the Department or for any questions or concerns you may have arising from this privacy
                    statement:
                </p>
                <p>
                    <span class="strong">
                    Freedom of Information Manager<br>
                    Department of Energy, Environment and Climate Action
                    </span>
                    <br>
                    PO Box 500, <br />
                    East Melbourne VIC 8002
                </p>

                <label class="h3">Internet user risks</label>
                <p>
                    The Department does not warrant that the functions contained in the site will be uninterrupted or without error.
                    In addition, the Department shall not be responsible for the propagation of computer worms, viruses or other harmful
                    components transmitted from this site and other third-party sites.
                </p>
                <p>
                    The Department recommends that you ensure that your browser is equipped with updated virus protection software.
                </p>

                <label class="h3">Website usage measurement</label>
                <p>
                    This website uses Google Analytics, a web analytics service provided by Google, Inc. (Google). Google Analytics uses
                    cookies, which are text files placed on your computer, to help the website analyse how users use the site. The
                    information generated by the cookie about your use of the website (including your IP address) will be transmitted
                    to and stored by Google on servers in the United States. Google will use this information for the purpose of
                    evaluating your use of the website, compiling reports on website activity for website operators and providing
                    other services relating to website activity and internet usage. Google may also transfer this information to third
                    parties where required to do so by law, or where such third parties process the information on Google's behalf.
                    Google will not associate your IP address with any other data held by Google.
                </p>
                <p>
                    You may refuse the use of cookies by selecting the appropriate settings on your browser; however please note that
                    if you do this you may not be able to use the full functionality of this website. By using this website, you consent
                    to the processing of data about you by Google in the manner and for the purposes set out above.
                </p>
                <p>
                    For further information, please visit the <a class="hot-topics" href="https://policies.google.com/privacy" target="_blank">Google
                    Privacy Center</a>
                </p>

                <label class="h3">Email addresses</label>
                <p>
                    Email addresses will only be used to respond to specific user queries and will not be kept or used for any purpose
                    other than stated. Email addresses will not be added to a mailing list unless consent is given, nor disclosed to
                    any other party without your knowledge or consent.
                </p>

                <label class="h3">Cookies</label>
                <p>
                    This site uses cookies to make your experience of the site easier and more efficient. A cookie is a small data
                    file placed on your hard drive by the web page server. A cookie cannot retrieve any other data from your hard
                    drive, pass on a computer virus, or capture your email address. Note that the cookie will remain on your computer
                    as a record for future visits to this website.
                </p>
                <p>
                    In using cookies the Department does not record any personal information or collect names or details of commercial
                    transactions. In addition, the Department does not use cookies as the basis for direct marketing.
                </p>
                <p>
                    You can configure your browser to notify you when you receive a cookie, providing you with the opportunity to
                    either accept or reject it. You can also refuse all cookies by turning them off in your browser or deleting
                    all cookies from your computer if required.
                </p>
                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</div>
<br /><br />
