<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails"
        [hideTips]="bannerHideTips"
        [textDirection]="bannerTextDirection">
    </app-banner>
    <br />

    <div class="language-content {{language}}-content">
        <div class="col-md-12 col-xs-12" [innerHTML]="pageContents"></div>
    </div>
</div>
<br /><br />
