import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnergyQuestionnaireForm } from '../energyQuestionnaireForm';
import { DataService } from '../data.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-small-business-energy-questionnaire',
  templateUrl: './small-business-energy-questionnaire.component.html',
  styleUrls: ['./small-business-energy-questionnaire.component.css'],
  animations: [
    trigger('slowAnimate', [
      transition(':enter', [style({opacity: '0'}), animate(1000)]),
      transition(':leave', [style({opacity: '1'}), animate(1000, style({opacity: '0'}))]),
    ])
  ]
})
export class SmallBusinessEnergyQuestionnaireComponent implements OnInit {



  public bannerTitle = "Energy questionnaire";
  public bannerSubTitle = "";
  public bannerDetails = "The questionnaire will take one or two minutes to complete.";

  constructor(
    public utility : Utility,
    public modalService : NgbModal,
    public energyQuestionnaireForm : EnergyQuestionnaireForm,
    public dataService : DataService,

  ) {

  }

  ngOnInit(): void {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.modalService.dismissAll(); //close modal dialog if there are any open
    document.title = 'Your Energy Profile - Victorian Energy Compare';
    this.energyQuestionnaireForm.initializeForm();
    this.dataService.changeEnergyQuestionnairePage('true');

  }

}
