<div class="modal-header">
    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        <span class="strong">
            Powercor has an online customer portal which allows users to import their smart meter data directly from the 
            portal to the Victorian Energy Compare website - Allowing for the most accurate energy comparison in Victoria.
        </span>
    </p>
    <br />
    <p>To register for the 'myEnergy' portal and import your data to Victorian Energy Compare, follow these steps:</p>
    <br />
    <ol>
        <li>
            Click on the 'myEnergy' link below to go to the portal registration page
        </li>
        <li>
            Register for the portal so you can gain access to your information
        </li>
        <li>
            Add you NMI (National Meter Identifier – This information can be found on your electricity 
            bill and will be an 11 digit number starting with 6)
        </li>
        <li>
            Select your NMI and then click on the 'Download Data' button
        </li>
        <li>
            Select the 'Compare different retail offers and save' button – This will take you to Victorian 
            Energy Compare and will send your smart meter data across with you (note: If you do not have 12 
            months of data, the information cannot be passed across).
        </li>
    </ol>

</div>
