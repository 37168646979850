import { Component } from '@angular/core';
import { ReCaptchaService } from './reCaptcha.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vec-refresh-ui';
  token: any = '';
  private reCaptchaAutoRefreshInterval = environment.reCaptchaAutoRefreshInterval ? environment.reCaptchaAutoRefreshInterval : 60000; // default 60 seconds

  constructor(
    public reCaptchaService: ReCaptchaService,
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.reCaptchaService.initializeRecaptcha();
    }, 10);

    setInterval(() => {
      this.reCaptchaService.initializeRecaptcha();
    }, this.reCaptchaAutoRefreshInterval);
  }

}
