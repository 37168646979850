import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { environment } from '../../environments/environment'; //config file

@Component({
  selector: 'app-psb-modal',
  templateUrl: './psb-modal.component.html',
  styleUrls: ['./psb-modal.component.css']
})
export class PsbModalComponent implements OnInit {
  public appURL = environment.appURL;
  constructor(
    public activeModal: NgbActiveModal,
    public router : Router,) { }

  ngOnInit() {
  }

  public psbFAQPage() {
    this.router.navigate(['/solar-details']);
  }
}
