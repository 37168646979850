import { Component, OnInit } from '@angular/core';
import { SolarCalculator } from '../solarCalculator';
import { Utility } from '../utility';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';
import { environment } from '../../environments/environment'; //config file

@Component({
  selector: 'app-solar-options-modal',
  templateUrl: './solar-options-modal.component.html',
  styleUrls: ['./solar-options-modal.component.css']
})
export class SolarOptionsModalComponent implements OnInit {
  public selectedOption = 1;
  public emailSecurityKey = environment.emailSecurityKey;
  public solar1Loaded = false;
  public solar2Loaded = false;
  public solar3Loaded = false;
  public solarData1 = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public solarData2 = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public solarData3 = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public selectedSolarData = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public appURL = environment.appURL;
  public solarEstimatesData1 = {
    annualSavedTotal : 0,
    annualBillTotal : 0
  };
  public solarEstimatesData2 = {
    annualSavedTotal : 0,
    annualBillTotal : 0
  };
  public solarEstimatesData3 = {
    annualSavedTotal : 0,
    annualBillTotal : 0
  };
  public showOptions = false;

  constructor(public solarCalculator : SolarCalculator,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private spinner : NgxSpinnerService,
    public dataService : DataService,
    public utility : Utility) { }

  ngOnInit() {
    this.getSolarEstimates(this.solarData1, 1);
    this.getSolarEstimates(this.solarData2, 2);
    this.getSolarEstimates(this.solarData3, 3);
    this.checkValues();
  }

  ngOnDestroy() {}

  private checkValues() {
    let root = this;
    let timerId  = setInterval(function() {
      root.spinner.show();
      if (root.solar1Loaded == true && root.solar2Loaded == true && root.solar3Loaded == true) {
        clearInterval(timerId);
        root.spinner.hide();
      }
    }, 1000);
  }

  public changeOption(selectedOption) {
    this.selectedOption = selectedOption;
    var solarCompareTable = document.getElementById("solar-compare-table-modal").innerHTML;
    let data = {
      "selectedOption" : selectedOption,
      "solarCompareTable" : solarCompareTable,
      "solarEstimatesData1" : this.solarEstimatesData1,
      "solarEstimatesData2" : this.solarEstimatesData2,
      "solarEstimatesData3" : this.solarEstimatesData3
    }
    this.activeModal.close(data);
  }

  public updateSolarEstimates() {
    this.getSolarEstimates(this.selectedSolarData, 0);
  }

  public getSolarEstimates(solarData, number) {
    setTimeout(() => {
      this.spinner.show();
    }, 0);
    let solarEstimatesData = {
      annualSavedTotal : 0,
      annualBillTotal : 0
    };
    solarData['fitRates'] = this.solarCalculator.getFitRates(
      solarData['fitPeak'],
      solarData['fitOffPeak'],
      solarData['fitShoulder']
    );
    solarData['tariffs'] = this.solarCalculator.getTariffs(
      solarData['peakRateValue'],
      solarData['offPeakRateValue'],
      solarData['shoulderRateValue']
    );
    this.dataService.getSolarEstimates(solarData)
    .subscribe(
      data => {
        //this.spinner.hide();
        if (data['status'] == 'error') {
          //this.showError();
        } else {
          solarEstimatesData = data['data'];
          if (number == 1) {
            this.solarEstimatesData1 = solarEstimatesData;
            this.solar1Loaded = true;
          } else if (number == 2) {
            this.solarEstimatesData2 = solarEstimatesData;
            this.solar2Loaded = true;
          } else {
            this.solarEstimatesData3 = solarEstimatesData;
            this.solar3Loaded = true;
          }
        }
      },
      error => {
        this.spinner.hide();
      },
    );
  }

}
