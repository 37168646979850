import { FaqConsumptionModalComponent } from './../faq-consumption-modal/faq-consumption-modal.component';
import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FaqModalComponent } from '../faq-modal/faq-modal.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'; //config file
import { FaqQuestionnaireModalComponent } from '../faq-questionnaire-modal/faq-questionnaire-modal.component';
import { FaqContactModalComponent } from '../faq-contact-modal/faq-contact-modal.component';
import { Utility } from '../utility';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() title;
  @Input() subTitle;
  @Input() details;
  @Input() hideTips;
  @Input() textDirection;

  public appURL = environment.appURL;

  public faqModal() {
    if (this.router.url == '/energy-questionnaire' || this.router.url == '/loopback/energy-questionnaire') {
      this.modalService.open(FaqQuestionnaireModalComponent, { size : 'xl' });
    } else if (this.router.url == '/bill-details' || this.router.url == '/loopback/bill-details') {
      this.modalService.open(FaqConsumptionModalComponent, { size : 'xl' });
    } else if (this.router.url == '/contact') {
      this.modalService.open(FaqContactModalComponent, { size : 'xl' });
    } else {
      this.modalService.open(FaqModalComponent, { size : 'xl' });
    }
  }

  constructor(public modalService : NgbModal,
    public utility : Utility,
    public router : Router) {
  }

  ngOnInit() {
  }

}
