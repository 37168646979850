import { Component, OnInit } from '@angular/core';
import { SolarCalculator } from '../solarCalculator';
import { Utility } from '../utility';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';
import { environment } from '../../environments/environment'; //config file
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-compare-solar-modal',
  templateUrl: './compare-solar-modal.component.html',
  styleUrls: ['./compare-solar-modal.component.css']
})
export class CompareSolarModalComponent implements OnInit {
  public solarForm : FormGroup;
  public selectedOption = 1;
  public print = false;
  public compareURL = environment.compareURL;
  public apiEndpoint = environment.apiEndpoint;
  public emailSecurityKey = environment.emailSecurityKey;
  public solarData1 = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public solarData2 = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public solarData3 = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public selectedSolarData = {
    systemSize : 0,
    systemCost : 0,
    peakRateValue : 0,
    shoulderRateValue : 0,
    offPeakRateValue : 0,
    discountRateValue : 0,
    dailySupplyCharge : 0,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 0,
    shoulderRate : -1,
    offPeakRate : -1,
    discount : -1,
  };
  public appURL = environment.appURL;
  public solarEstimatesData1 = {
    annualSavedTotal : 0,
    annualBillTotal : 0
  };
  public solarEstimatesData2 = {
    annualSavedTotal : 0,
    annualBillTotal : 0
  };
  public solarEstimatesData3 = {
    annualSavedTotal : 0,
    annualBillTotal : 0
  };
  public showOptions = false;

  constructor(public solarCalculator : SolarCalculator,
    private deviceService: DeviceDetectorService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private spinner : NgxSpinnerService,
    public dataService : DataService,
    private formBuilder : FormBuilder,
    public utility : Utility) { }

  ngOnInit() {
    this.getSolarEstimates(this.solarData1, 1);
    this.getSolarEstimates(this.solarData2, 2);
    this.getSolarEstimates(this.solarData3, 3);
    this.createForm();
    this.changeOption(1);
  }

  ngOnDestroy() {}

  public toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }

  public changeOption(selectedOption) {
    this.selectedOption = selectedOption;
    if (this.selectedOption == 1) {
      this.selectedSolarData = this.solarData1;
    } else if (this.selectedOption == 2) {
      this.selectedSolarData = this.solarData2;
    } else {
      this.selectedSolarData = this.solarData3;
    }
  }

  public updateSizePosition() {
    var max = 18;
    var min = 1.5;
    var addSpace = (max - this.selectedSolarData['systemSize']) * 2;
    addSpace -= (this.deviceService.isTablet() == true) ? 28 : 18;
    var percent = (this.selectedSolarData['systemSize'] - min) / max;
    var width = document.getElementById("systemSize").clientWidth;
    var position = (percent * width) + addSpace;
    position = (position < 30) ? 14 : position;
    position = ((width - position) < 37) ? (width - 37) : position;
    return position;
  }

  public getSizePosition() {
    return this.updateSizePosition() + "px";
  }

  public updateCostPosition() {
    var max = 20000;
    var addSpace = (10.5 - (this.selectedSolarData['systemCost'] / 1000)) * 2;
    addSpace = (this.deviceService.isTablet() == true) ? (addSpace - 7) : addSpace;
    var percent = this.selectedSolarData['systemCost'] / max;
    var width = document.getElementById("systemCost").clientWidth;
    var position = (percent * width) + addSpace;
    position = (position < 14) ? 14 : position;
    position = ((width - position) < 50) ? (width - 50) : position;
    position = (position == addSpace) ? 14 : position;
    return position;
  }

  public getCostPosition() {
    return this.updateCostPosition() + "px";
  }

  public createForm() {
    this.solarForm = this.formBuilder.group({
      peakRateValue : new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      shoulderRateValue : new FormControl('', [
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      offPeakRateValue : new FormControl('', [
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      dailySupplyCharge : new FormControl('', [
        Validators.min(1),
        Validators.max(999),
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      discountRateValue : new FormControl('', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      fitPeak : new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      fitShoulder : new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      fitOffPeak : new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
      ]),
      systemSize : new FormControl('', [
        Validators.required,
      ]),
      systemCost : new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$')
      ])
    });
  }

  public updateSolarEstimates() {
    this.getSolarEstimates(this.selectedSolarData, 0);
  }

  public getSolarEstimates(solarData, number) {
    setTimeout(() => {
      this.spinner.show();
    }, 0);
    let solarEstimatesData = {
      annualSavedTotal : 0,
      annualBillTotal : 0
    };
    solarData['fitRates'] = this.solarCalculator.getFitRates(
      solarData['fitPeak'],
      solarData['fitOffPeak'],
      solarData['fitShoulder']
    );
    solarData['tariffs'] = this.solarCalculator.getTariffs(
      solarData['peakRateValue'],
      solarData['offPeakRateValue'],
      solarData['shoulderRateValue']
    );
    this.dataService.getSolarEstimates(solarData)
    .subscribe(
      data => {
        this.spinner.hide();
        if (data['status'] == 'error') {
          //this.showError();
        } else {
          try {
            solarEstimatesData = data['data'];
            if (number == 0) {
              if (this.selectedOption == 1) {
                this.solarEstimatesData1 = solarEstimatesData;
              } else if (this.selectedOption == 2) {
                this.solarEstimatesData2 = solarEstimatesData;
              } else {
                this.solarEstimatesData3 = solarEstimatesData;
              }
            } else {
              if (number == 1) {
                this.solarEstimatesData1 = solarEstimatesData;
              } else if (number == 2) {
                this.solarEstimatesData2 = solarEstimatesData;
              } else {
                this.solarEstimatesData3 = solarEstimatesData;
              }
            }
          } catch(e) {
            this.spinner.hide();
          }
        }
      },
      error => {
        this.spinner.hide();
      },
    );
  }

  public downloadPDF() {
    this.spinner.show();
    this.print = true;
    var root = this;
    setTimeout(function() {
      var html = document.getElementById("compare-modal").innerHTML;
      html += '<link rel="stylesheet" media="screen" href="' + root.appURL + '/assets/css/solar-options.css" />';
      root.print = false;
      //call API to generate PDF
      //create form and make a post call to the api
      var form = document.createElement('form');
      form.setAttribute('id', 'pdf-form');
      document.body.appendChild(form);
      //if device is mobile or using ie open a new window
      var ua = window.navigator.userAgent;
      var isIE = /MSIE|Trident/.test(ua);
      if (screen.width <= 420 || isIE == true) { //if mobile or IE
        form.setAttribute('target', '_blank');
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'mobile';
        input.value = '1';
        form.appendChild(input);
      }

      form.method = 'POST';
      form.action = root.apiEndpoint + 'solar-savings2.pdf';
      var input2 = document.createElement('input');
      input2.type = 'hidden';
      input2.name = 'html';
      input2.value = html;
      form.appendChild(input2);
      form.submit();
      setTimeout(function() {
        root.print = false;
        root.spinner.hide(); //hide loader
      }, 2000);
    }, 1000);
  }

  public emailModal() {
    this.spinner.show();
    this.utility.setEmailErrorMessage('');
    this.print = true;
    var root = this;
    setTimeout(function() {
      var html = document.getElementById("compare-modal").innerHTML;
      html += '<link rel="stylesheet" media="screen" href="' + root.appURL + '/assets/css/solar-options.css" />';
      //html += '<div id="footer">Victorian Energy Compare - ' + root.compareURL + '</div>';
      root.print = false;
      root.spinner.hide(); //hide loader
      root.utility.scrollToTop("none");
      const modalRef = root.modalService.open(EmailModalComponent);
      modalRef.componentInstance.html = html;
      modalRef.componentInstance.isSolar = true;
    }, 1000);
  }


}
