<div class="container">
    <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails">
    </app-banner>

    <div class="col-lg-11">
        <h2 class="subTitle">Your business details</h2>
        <div class="row" *ngIf="energyQuestionnaireForm.saveFormError != ''">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        An error has occured while trying to submit the information you have provided. Please try again.
                    </h4>
                </div>
            </div>
        </div>

        <form method="post" id="questionnaireForm" [formGroup]="energyQuestionnaireForm.questionnaireForm"
            onsubmit="return false" (keydown.enter)="$event.preventDefault()">

            <div class="row page-divider-up form-group">
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>
                        What industry best describes your business?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='industryId'
                            class="form-control select-questionnaire"
                            (change)="energyQuestionnaireForm.showBusinessHour()"
                            (ngModelChange)="energyQuestionnaireForm.showBusinessHour()"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.industryId"
                            class="form-control select-questionnaire">
                            <option value="">Please select one</option>
                            <option *ngFor="let industry of energyQuestionnaireForm.industries; "
                                value="{{ industry . id_pk }}">{{ industry . title }}
                            </option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.questionnaireForm.controls['industryId'].value == 'other'">
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>

                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="">
                        <input type="text" maxlength="255" title="industry type other"
                            aria-label="premises type other" formControlName="industryOther"
                            placeholder="Enter industry" id="industryOther" name="industryOther"
                            (focusout)="energyQuestionnaireForm.validateIndustryOther()"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.industryOther"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'industryContinue')"
                            class="form-control" />
                        <bfv-messages></bfv-messages>
                    </div>
                </div>
            </div>

            <div class="row form-group page-divider-up"
                *ngIf="energyQuestionnaireForm.questionnaireForm.controls['industryId'].value == 'other' && energyQuestionnaireForm.showButtonContinueIndustry && !energyQuestionnaireForm.showQuestionWorkPremises">
                <div class="col-md-9"></div>
                <div class="col-md-3 text-right">
                    <input type="button" class="custom-button form-control" value="Continue"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'industryContinue')"
                        (click)="energyQuestionnaireForm.validateIndustryOther()" />
                </div>
            </div>



            <!-- Premises Type -->

            <div class="row page-divider-up form-group" *ngIf="energyQuestionnaireForm.showQuestionWorkPremises" @slowAnimate>
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>
                        What best describes your work premises?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='premisesTypeId'
                            (change)="this.energyQuestionnaireForm.validateWorkPremises()"
                            class="form-control select-questionnaire"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.premisesTypeId"
                            class="form-control select-questionnaire">
                            <option value="">Please select one</option>
                            <option *ngFor="let premises of energyQuestionnaireForm.premisesTypes"
                                value="{{ premises . id_pk }}">{{ premises . title }}</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.questionnaireForm.controls['premisesTypeId'].value == 'other'">
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'></label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="">
                        <input type="text" maxlength="255" title="premises type other"
                            aria-label="premises type other" formControlName="premisesTypeOther"
                            placeholder="Enter premises type" id="premisesTypeOther"
                            (focusout)="energyQuestionnaireForm.validateWorkPremisesOther()"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'premisesTypeOther')"
                            [required]="energyQuestionnaireForm.questionnaireForm.controls['premisesTypeId'].value == 6"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.premisesTypeOther"
                            class="form-control" />
                        <bfv-messages></bfv-messages>
                    </div>
                </div>
            </div>

            <div class="row form-group page-divider-up" @slowAnimate
                *ngIf="energyQuestionnaireForm.questionnaireForm.controls['premisesTypeId'].value == 'other' && !energyQuestionnaireForm.showQuestionEmployees">
                <div class="col-md-9"></div>
                <div class="col-md-3 text-right">
                    <input type="button" class="custom-button form-control" value="Continue"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'workPremisesContinue')"
                        (click)="energyQuestionnaireForm.validateWorkPremisesOther()" />
                </div>
            </div>

            <!-- employeesOnSite -->

            <div class="row page-divider-up form-group" *ngIf="energyQuestionnaireForm.showQuestionEmployees" @slowAnimate>
                <div class="col-md-6 dual-line">
                    <ng-template #tipEmp>This does not include employees working from home or at a site separate to the
                        business premises.</ng-template>
                    <label class='h3 questionnaire-form-label'>
                        Typically, how many employees are on site (at the business premises) during operating hours?
                        <i class="material-icons" data-toggle="tooltip" [triggers]="utility.toolTipForMobile()"
                            [ngbTooltip]="tipEmp" aria-label="">info</i>
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='employeesOnSite'
                            (change)="this.energyQuestionnaireForm.validateEmployeesOnSite()"
                            class="form-control select-questionnaire"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'employeesOnSite')"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.employeesOnSite"
                            class="form-control select-questionnaire">
                            <option value="">Please select one</option>
                            <option *ngFor="let employees of energyQuestionnaireForm.employeesOnSite"
                                value="{{ employees.value }}">
                                {{ employees.name }}</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <!-- employeesOnSite other -->
            <div class="row form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.questionnaireForm.controls['employeesOnSite'].value == 'other' && energyQuestionnaireForm.showQuestionEmployees">
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>

                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="">
                        <input type="number" min="1" max="500" title="number of employees on site"
                            aria-label="number of employees on site" formControlName="employeesOnSiteOther"
                            placeholder="Enter number of employees on site" id="employeesOnSiteOther"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'employeesOnSiteOther')"
                            (keypress)="energyQuestionnaireForm.numericOnly($event)"
                            [required]="energyQuestionnaireForm.questionnaireForm.controls['employeesOnSite'].value == 'Other'"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.employeesOnSiteOther"
                            class="form-control" />
                        <bfv-messages></bfv-messages>
                    </div>
                </div>
            </div>

            <!-- employeesOnSite other continue -->

            <div class="row form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.questionnaireForm.controls['employeesOnSite'].value == 'other' && !energyQuestionnaireForm.showBusinessHours">
                <div class="col-md-9"></div>
                <div class="col-md-3 text-right">
                    <input type="button" class="custom-button form-control" value="Continue"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'employeesOnSiteOther')"
                        (click)="energyQuestionnaireForm.validateEmployeesOnSiteOther()" />
                </div>
            </div>

            <!-- Business hours -->
            <div class="row page-divider-up form-group" *ngIf="energyQuestionnaireForm.showBusinessHours" @slowAnimate>
                <div class="col-md-6">
                    <ng-template #tipBusinessHours>For example, if your business operates between 9:00am – 5:00pm select
                        mornings and afternoons.
                        <br>If your hours vary day to day, select options that reflect a “typical day.</ng-template>
                    <label class='h3 questionnaire-form-label'>
                        What are your usual business hours?<br />
                        - select all that apply
                        <i class="material-icons" data-toggle="tooltip" [triggers]="utility.toolTipForMobile()"
                            [ngbTooltip]="tipBusinessHours" aria-label="">info</i>
                    </label>
                </div>
                <div class="col-sm-6 vertical-align check-box-group" formGroupName="businessHoursCheckboxGroup">
                    <div class="checkbox-inline"
                        *ngFor="let hours of energyQuestionnaireForm.businessHours; let i = index">
                        <input type="checkbox" class="form-check-input" id="{{ hours . value }}"
                            formControlName="{{ hours . value }}" id="{{ hours . value }}"
                            (change)="energyQuestionnaireForm.validateBusinessHours()"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'businessHoursContinue')"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire[hours.value]">
                        <label for="{{ hours . value }}" class="custom-btn-default custom-btn-text">
                            &nbsp;{{ hours . name }}
                        </label>
                    </div>
                    <div class="text-danger-questionnaire"
                        *ngIf="energyQuestionnaireForm.questionnaireForm.controls['businessHoursCheckboxGroup'].errors && energyQuestionnaireForm.questionnaireForm.controls['businessHoursCheckboxGroup'].touched">
                        Select at least one option
                    </div>
                </div>
            </div>

            <!-- Days of operation -->

            <div class="row form-group" *ngIf="energyQuestionnaireForm.showBusinessHours" @slowAnimate>
                <div class="col-md-6">
                    <label class='h3 questionnaire-form-label'>
                        What are your days of operation?<br />
                      - select all that apply
                    </label>
                </div>
                <div class="col-sm-6 vertical-align check-box-group" formGroupName="businessDaysCheckboxGroup">
                    <div class="checkbox-inline" *ngFor="let day of energyQuestionnaireForm.days; let i = index">
                        <input type="checkbox" class="form-check-input" id="{{ day . value }}"
                            formControlName="{{ day . value }}"
                            (change)="energyQuestionnaireForm.validateBusinessDays()"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'businessHoursContinue')"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire[day.value]">
                        <label for="{{ day . value }}" class="custom-btn-default custom-btn-text">
                            {{ day . name }}
                        </label>
                    </div>
                    <div class="text-danger-questionnaire"
                        *ngIf="energyQuestionnaireForm.questionnaireForm.controls['businessDaysCheckboxGroup'].errors
                      && energyQuestionnaireForm.questionnaireForm.controls['businessDaysCheckboxGroup'].touched">
                        Select at least one option
                    </div>
                </div>
            </div>

            <!-- Continue business hours and days -->

            <div class="row form-group page-divider-up"
                *ngIf="energyQuestionnaireForm.showBusinessHours  && !energyQuestionnaireForm.showQuestionGas" @slowAnimate>
                <div class="col-md-9"></div>
                <div class="col-md-3 text-right">
                    <input type="button" class="custom-button form-control" value="Continue"
                        id="businessHoursContinue"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'businessHoursContinue')"
                        (click)="energyQuestionnaireForm.validateBusinessHoursDays()" />
                </div>
            </div>

            <!-- Your business energy details -->

            <div class="row form-group" *ngIf="energyQuestionnaireForm.showQuestionGas">
                <h2 class="subTitle" style="padding-top: 10px;">Your business energy details</h2>
            </div>
            <div class="row page-divider-up form-group" *ngIf="energyQuestionnaireForm.showQuestionGas" @slowAnimate>
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>
                        Do you have gas?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='gasSupply'
                            (change)="this.energyQuestionnaireForm.validateGasSupply()"
                            class="form-control select-questionnaire"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.gasSupply"
                            class="form-control select-questionnaire">
                            <option value="">Please select one</option>
                            <option *ngFor="let gas of energyQuestionnaireForm.haveGas" value="{{ gas . value }}">
                                {{ gas . name }}</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row page-divider-up form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.showQuestionElectricityType && energyQuestionnaireForm.showQuestionGas">
                <div class="col-md-6 dual-line">
                    <label class='h3 questionnaire-form-label'>
                        What type of electricity supply do you have?
                    </label>
                </div>
                <div class="col-md-6 custom-select-align">
                    <div class="select">
                        <select aria-hidden="true" formControlName='electricitySupply'
                            (change)="this.energyQuestionnaireForm.validateElectricitytype()"
                            class="form-control select-questionnaire"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.electricitySupply"
                            class="form-control select-questionnaire">
                            <option value="">Please select one</option>
                            <option *ngFor="let supply of energyQuestionnaireForm.electricitySupply"
                                value="{{ supply }}">
                                {{ supply }}</option>
                        </select>
                    </div>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div class="row page-divider-up form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.showQuestionAppliances && energyQuestionnaireForm.showQuestionElectricityType">
                <div class="col-md-6 dual-line">
                  <ng-template #tipAppliances>Energy consuming appliances and equipment will vary for each business.
                    <br/>Some examples include, refrigeration units, kitchen appliances, lighting systems, computers & servers,
                    manufacturing machinery, HVAC systems and water heaters.
                    <br/>Please list what is specific to your business.</ng-template>
                    <label class='h3 questionnaire-form-label'>
                      Which appliances or equipment do you think use the most energy in your workplace? Please list up to three.
                      <i class="material-icons" data-toggle="tooltip" [triggers]="utility.toolTipForMobile()"
                         [ngbTooltip]="tipAppliances" aria-label="">info</i>
                    </label>
                </div>
                <div class="col-md-6">
                    <div class="col-md-12" class="appliances">

                        <input type="text" maxlength="80" title="First appliance" aria-label="appliance 1"
                            formControlName="appliance1" placeholder="First appliance" id="appliance1"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'appliances')"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.appliance1"
                            class="form-control" />

                    </div>
                    <div class="col-md-12" class="appliances">
                        <span class="text-danger-questionnaire"
                            *ngIf="(energyQuestionnaireForm.questionnaireForm.get('appliance1').touched || energyQuestionnaireForm.questionnaireForm.get('appliance1').dirty) && energyQuestionnaireForm.questionnaireForm.get('appliance1').errors?.applianceInvalid">
                            This field is required.
                        </span>
                    </div>
                    <div class="col-md-12" class="appliances">
                        <input type="text" maxlength="80" title="Second appliance" aria-label="appliance 2"
                            formControlName="appliance2" placeholder="Second appliance (optional)" id="appliance2"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'appliances')"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.appliance2"
                            class="form-control"
                            [ngClass]="{'form-control-no-validate': energyQuestionnaireForm.energyQuestionnaire.appliance2 === ''}"
                            />
                    </div>
                    <div class="col-md-12" class="appliances">
                        <span class="text-danger-questionnaire"
                            *ngIf="(energyQuestionnaireForm.questionnaireForm.get('appliance2').touched || energyQuestionnaireForm.questionnaireForm.get('appliance2').dirty) && energyQuestionnaireForm.questionnaireForm.get('appliance2').errors?.applianceInvalid">
                            This field is required.
                        </span>
                    </div>
                    <div class="col-md-12" class="appliances">
                        <input type="text" maxlength="80" title="Third appliance" aria-label="appliance 3"
                            formControlName="appliance3" placeholder="Third appliance (optional)" id="appliance3"
                            (keydown)="energyQuestionnaireForm.selectButton($event, 'appliances')"
                            [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.appliance3"
                            class="form-control"
                            [ngClass]="{'form-control-no-validate': energyQuestionnaireForm.energyQuestionnaire.appliance3 === ''}"
                            />

                    </div>
                    <div class="col-md-12" class="appliances">
                        <span class="text-danger-questionnaire"
                            *ngIf="(energyQuestionnaireForm.questionnaireForm.get('appliance3').touched || energyQuestionnaireForm.questionnaireForm.get('appliance3').dirty) && energyQuestionnaireForm.questionnaireForm.get('appliance3').errors?.applianceInvalid">
                            This field is required.
                        </span>
                    </div>

                </div>

            </div>

            <div class="row form-group page-divider-up" @slowAnimate
                *ngIf="energyQuestionnaireForm.showQuestionAppliances && !energyQuestionnaireForm.showQuestionSolar">
                <div class="col-md-9"></div>
                <div class="col-md-3 text-right">
                    <input type="button" class="custom-button form-control" value="Continue"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'appliances')"
                        (click)="energyQuestionnaireForm.validatAppliances()" />
                </div>
            </div>



            <div class="row form-group page-divider-up" *ngIf="energyQuestionnaireForm.showQuestionSolar" @slowAnimate>
                <div class="col-lg-6 vertical-align">
                    <ng-template #tipSolar>If your business has solar panels which generate electricity then you should
                        answer yes to this question. If solar panels are installed, your business electricity costs are
                        likely to decrease during the daylight hours.</ng-template>
                    <label class="h3">
                        Do you have solar panels that generate electricity?
                        <i class="material-icons" data-toggle="tooltip" [triggers]="utility.toolTipForMobile()"
                            [ngbTooltip]="tipSolar" aria-label="">info</i>
                    </label>
                </div>
                <div class="radio-inline col-sm-6 vertical-align">
                    <input formControlName="hasSolar" type="radio" id="solarYes" value="1"
                        (click)="energyQuestionnaireForm.selectButton($event, 'SolarYes')"
                        [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.hasSolar">
                    <label for="solarYes" class="custom-btn-default custom-btn-text">Yes</label>
                    <input formControlName="hasSolar" type="radio" id="solarNo" value="0"
                        (click)="energyQuestionnaireForm.selectButton($event, 'SolarNo')"
                        [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.hasSolar">
                    <label for="solarNo" class="custom-btn-default custom-btn-text">No</label>
                </div>
            </div>

            <div class="row form-group" @slowAnimate
                *ngIf="energyQuestionnaireForm.energyQuestionnaire.hasSolar == '1' && this.energyQuestionnaireForm.showQuestionSolar">
                <div class="col-lg-6 vertical-align">
                    <label class="h3">
                        What is the capacity of the solar panels?
                    </label>
                </div>
                <div class="col-sm-3 vertical-align">
                    <input type="text" maxlength="10" title="capacity" aria-label="capacity"
                        formControlName="solarCapacity" value="" id="solarCapacity"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'solarCapacity')"
                        [ngClass]="{'form-control-no-validate': energyQuestionnaireForm.questionnaireForm.get('solarCapacity').valid && energyQuestionnaireForm.questionnaireForm.get('solarCapacity').value == ''}"
                        [(ngModel)]="energyQuestionnaireForm.energyQuestionnaire.solarCapacity" class="form-control" />
                    <bfv-messages></bfv-messages>
                </div>

                <div class="col-sm-3 solar-label no-padding" >
                  <span class="solar-capacity-unit">kW</span>
                  <span
                      (click)="energyQuestionnaireForm.skipSolarCapacity()"
                      class="download">I don't know
                  </span>
              </div>
            </div>

            <p><ngb-progressbar [showValue]="true" type="success"
                    [value]="energyQuestionnaireForm.progress"></ngb-progressbar></p>

            <!-- <pre>{{ (energyQuestionnaireForm . questionnaireForm . value) | json }}</pre>
            <pre>{{ energyQuestionnaireForm . questionnaireForm . get('solarCapacity') . valid }}</pre> -->

            <div class="row form-group page-divider-up" *ngIf="this.energyQuestionnaireForm.energyQuestionnaire.hasSolar != '' ||  (energyQuestionnaireForm.questionnaireForm.get('solarCapacity').valid  &&  this.energyQuestionnaireForm.showQuestionSolar)">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <button type="submit" id="submit"
                        [disabled]="!this.energyQuestionnaireForm.questionnaireForm.valid"
                        class="energy-questionnaire-button custom-button profile-btn custom-btn-large custom-btn-large-text col-lg-12"
                        (keydown)="energyQuestionnaireForm.selectButton($event, 'saveData')"
                        (click)="energyQuestionnaireForm.saveData()">
                        Next <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                </div>
            </div>

        </form>
    </div>

</div>
<br /><br />
