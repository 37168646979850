<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Terms and conditions for use of Victorian Energy Compare</h2>
    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="strong">The main things you should know:</p>
    <ul>
        <li>
            <span class="strong">
                You are responsible for ensuring that information you provide is accurate and complete, as
                this will affect your results.
            </span>
        </li>
        <li>
            <span class="strong">
                Information provided by Victorian Energy Compare about your future energy usage (and, where
                you do not provide past usage data, your past and current usage) is a guide only.
            </span>
        </li>
        <li>
            <span class="strong">
                Not all energy offers available to you may be displayed on Victorian Energy Compare.
            </span>
        </li>
        <li>
            <span class="strong">
                You should make your own inquiries about energy plans that are suited to your needs and not
                rely on the information in Victorian Energy Compare.
            </span>
        </li>
    </ul>
    <br />
    <p>
        <span class="strong">
            Application and amendment of these terms and conditions
        </span><br>
        Your use of Victorian Energy Compare website is governed by these terms and conditions. By
        accessing and using Victorian Energy Compare, you agree to be bound by and abide by these
        terms and conditions. The Department of Energy, Environment and Climate Action
        (<span class="strong">DEECA</span>) reserves the right to amend these terms and conditions at any time.
    </p>
    <p>
        <span class="strong">Your use of Victorian Energy Compare</span><br>
        You are entitled to, using a generally recognised web browser, download and view the content
        of this website and use Victorian Energy Compare for the purpose of obtaining information
        about your own household's or small business's energy consumption and generally available
        energy offers that may be available to cater to your needs. This does not include obtaining
        information about the energy consumption and needs of others in order to advise them.
    </p>
    <p>
        <span class="strong">You must not use Victorian Energy Compare:</span><br>
    </p>
    <ul>
        <li>
            for any purpose that is unlawful or infringes any rights of the State of Victoria, its
            agents, instrumentalities or employees (together, the <span class="strong">State</span>) or a third
            party; or
        </li>
        <li>
            to breach or circumvent or attempt to breach or circumvent the security of this website
            (including hacking) or engage in any other malicious, illegal or damaging behaviour in
            relation to this website.
        </li>
    </ul>
    <p>
        <span class="strong">Reliance on Victorian Energy Compare</span><br>
        If you upload or otherwise use usage data provided by your energy retailer or distributor for
        the purposes of using Victorian Energy Compare, the energy company (and you, if you make any
        changes to the data), not the State, is responsible for the accuracy of that data. DEECA does
        not check that the data is correct or otherwise take any responsibility for the content of
        material provided to you by your energy company.
    </p>
    <p>
        If you have lived at your residence for more than twelve months, the usage data used by the
        Victorian Energy Compare website is provided by Australian Energy Market Operator (AEMO). As
        such, AEMO, not the State, is responsible for the accuracy of that data. DEECA does not check
        that the data is correct or otherwise take any responsibility for the content of material
        provided to you by AEMO.
    </p>
    <p>
        If you have lived at your residence for less than twelve months, any information about your
        past, current or projected future energy usage that you obtain through use of Victorian Energy
        Compare is determined based on the information that you provide in response to questions. You
        are responsible for ensuring that the information that you provide is accurate and complete.
        The results provided by Victorian Energy Compare are estimates provided as a guide only and,
        although DEECA takes due care to ensure that the results are as accurate as possible, the
        results may not reflect your actual past, current or future energy usage. The information is
        not financial advice and may not accurately reflect the amount that you will pay for energy.
    </p>
    <p>
        The information about available energy offers is provided by energy retailers and made available
        to DEECA. The energy retailer is required to ensure that information is accurate and current.
        The State is not responsible for verifying its accuracy, currency or completeness and does not
        take any steps to do so. Accordingly, DEECA does not guarantee the accuracy, currency or
        completeness of that material. If you wish to take up any offer listed in Victorian Energy
        Compare, you should verify the details of the offer with the energy retailer, including checking
        that the offer is available to you and all of the terms and conditions applicable to the offer.
        Victorian Energy Compare cannot provide information about whether certain discounts or incentives
        are available to you. You should not merely rely on the information provided through Victorian
        Energy Compare as being correct, current and applicable to you or your circumstances.
    </p>
    <p>
        The energy offers displayed within Victorian Energy Compare do not necessarily reflect all of the
        offers that may be available to you.  There may be additional offers that are available to you
        but that:
    </p>
    <ul>
        <li>
            are not widely available to most domestic or small business customers or are exclusive to a
            particular customer segment, and are accordingly not required to be provided to the DEECA;
        </li>
        <li>
            cannot be handled by Victorian Energy Compare because they fall outside the parameters used by
            Victorian Energy Compare to compare different offers; or
        </li>
        <li>
            are otherwise not provided by the energy retailer to the DEECA.
        </li>
    </ul>
    <p>
        You should check with the relevant energy retailer what offers may be available to you, including
        offers not displayed as part of the results given by Victorian Energy Compare.
    </p>
    <p>
        <span class="strong">Third party websites</span><br>
        This website may contain hyperlinks or references to other websites.  The State does not claim any
        association with, and does not endorse, websites that are not clearly identified as sites maintained
        by the State.  If you follow a hyperlink or are referred to another website, you are responsible for
        observing that website's terms of use.  The State takes no responsibility for the accuracy or use of
        material on third party sites.  The State is also not responsible for any non-availability of, or
        computer viruses, other harmful code, defects or errors in, third party sites.
    </p>
    <p>
        <span class="strong">Your rights under law</span><br>
        To the extent that your use of Victorian Energy Compare or this website results in you acquiring
        services as a consumer within the meaning of the Australian Consumer Law (as set out in Schedule 2
        to the Competition and Consumer Act 2010 (Cth) and given effect under the Australian Consumer Law
        and Fair Trading Act 2012 (Vic)), you may have certain rights and remedies (including, without
        limitation, consumer guarantee rights) that cannot be excluded, restricted or modified by agreement.
        Nothing in these terms and conditions operates to exclude, restrict or modify the application of any
        implied condition or warranty, provision, the exercise of any right or remedy, or the imposition of
        any liability, implied or conferred under the Australian Consumer Law or any other statute, the
        exclusion, restriction or modification of which would contravene that statute or cause any of these
        terms and conditions to be void (<span class="strong">Non-excludable Obligation</span>).
    </p>
    <p>
        <span class="strong">Operation of this website</span><br>
        Except in relation to Non-excludable Obligations, all conditions, warranties, guarantees, rights,
        remedies, liabilities or other terms implied or conferred by statute, custom, or the general law
        that impose any liability or obligation on the State are expressly excluded under these terms and
        conditions. Without limiting the above, the State does not warrant that:
    </p>
    <ul>
        <li>
            Victorian Energy Compare or this website will be free of infections by viruses, worms, spyware,
            adware, keyloggers, trojans or any other computer code, program or routine that is harmful,
            destructive, disabling or that assists in or enables theft, alteration, denial of service,
            unauthorised disclosure or destruction or corruption of data; or
        </li>
        <li>
            the functioning of Victorian Energy Compare or your access to Victorian Energy Compare or this
            website will be uninterrupted or error-free.
        </li>
    </ul>
    <p>
        You should use up-to-date virus protection software and other systems to protect your own computer
        and systems from viruses or other harmful code.
    </p>
    <p>
        <span class="strong">Exclusions and limitations of liability</span><br>
        Except in relation to Non-excludable Obligations, the State will have no liability to you for any
        economic loss, loss of contract, loss of profit or revenue, loss of opportunity, loss of production,
        production stoppage, loss of data or other loss, harm, damage, cost or expense (including legal fees)
        in the nature of special, indirect or consequential loss or damage that arises directly or indirectly
        in connection with your use of Victorian Energy Compare or this website or the performance or
        non-performance of Victorian Energy Compare or this website, and whether arising under any indemnity,
        statute, in tort (for negligence or otherwise), or on any other basis in law or equity. The liability
        of the State to you:
    </p>
    <ul>
        <li>
            for a failure to comply with any Non-excludable Obligation, except for services of a kind ordinarily
            acquired for personal, domestic or household use or consumption (in respect of which liability is
            not so limited under these terms and conditions); or
        </li>
        <li>
            in connection with your use of Victorian Energy Compare or this website or the performance or
            non-performance of Victorian Energy Compare or this website, and whether arising under any indemnity,
            statute, in tort (for negligence or otherwise), or on any other basis in law or equity, other than
            for a Non-excludable Obligation,
        </li>
    </ul>
    <p>
        is limited to supplying the services again or payment of the cost of having the services supplied again.
    </p>
    <p>
        <span class="strong">Suspension of access</span><br>
        Without limiting the above, DEECA may remove or suspend Victorian Energy Compare or this website, or
        access to them, for any reason including where there is a fault, harmful code, unauthorised access or
        other malicious attack on the website or DEECA's systems or for the purposes of upgrading or maintenance
        of the website.
    </p>
    <p>
        <span class="strong">Termination of access</span><br>
        If, in DEECA's opinion, you breach any of these terms and conditions, DEECA may suspend,
        terminate or limit your access to Victorian Energy Compare or this website. DEECA does not waive any
        right under these terms and conditions unless the waiver is in writing signed by DEECA.
    </p>
    <p>
        <span class="strong">Intellectual property</span><br>
        Without limiting any other terms and conditions that apply to this website, you must not reproduce,
        adapt, modify, communicate or otherwise use any part of Victorian Energy Compare or this website
        without written permission from DEECA or as expressly permitted in these terms and conditions. This
        does not limit your rights under law (including to engage in fair dealing with the copyright material
        in Victorian Energy Compare or this website for the purposes permitted under the Copyright Act 1968
        (Cth)).
    </p>
    <p>
        <span class="strong">Privacy</span><br>
        Your use of Victorian Energy Compare is governed by the privacy statement for this website, which is
        located at  <a
                        title="Privacy statement"
                        aria-label="Privacy statement"
                        href="/privacy"
                        target="_blank">https://compare.energy.vic.gov.au/privacy</a>
        and the DEECA privacy policy referred to in that statement. In addition to the collection of
        anonymous information through web servers set out in that privacy statement, in using Victorian
        Energy Compare you will be asked to supply certain information for the purposes of determining your
        estimated energy usage and profile, and some of the energy offers available to you.
    </p>
    <p>
        If you do not provide this information, you will not be able to obtain information from Victorian
        Energy Compare.
    </p>
    <p>
        To assist you, DEECA will need to access your electricity metering data (via Victorian Energy
        Compare) from the Australian Energy Market Operator (<span class="strong">AEMO</span>) under the Advance
        Metering Infrastructure (Information) Order and section 46D of the Electricity Industry Act 2000.
        Victorian Energy Compare will use your last 16 months of available data to determine your estimated
        energy usage and profile. You will need to provide your National Metering Identifier
        (<span class="strong">NMI</span>), retailer name, post code and confirmation that you have been occupying the
        relevant premises for at least 12 months.
    </p>
    <p>
        Any such information that you supply through use of Victorian Energy Compare will only be used by
        DEECA for these purposes, and will not be supplied to any third parties. The information will not
        be retained by DEECA beyond the end of your session, except that information will be retained in a
        form aggregated with the information provided by other users of the website, and as part of the
        caching that occurs during the ordinary operation of this website. Any such information that is
        personal information will be held and used in accordance with DEECA's privacy policy.
    </p>
    <p>
        <span class="strong">Collection Statement</span><br>
        The Department of Energy, Environment and Climate Action (DEECA) is committed to protecting personal
        information provided by you in accordance with the principles of the Victorian privacy laws.
    </p>
    <p>
        The information you provide will be used to determine the energy offers available to you.
    </p>
    <p>
        The information you provide may be made available to Australian Energy Market Operator (AEMO) in
        order to retrieve your smart meter data on your behalf.
    </p>
    <p>
        This information is being collected in accordance with the Privacy and Data Protection Act 2014.
    </p>
    <p>
        If all requested information is not received, DEECA will be unable to provide you with accurate
        information about energy offers available to you.
    </p>
    <p>
        You may access the information you have provided to DEECA by contacting Victorian Energy Compare
        at info.vec@deeca.vic.gov.au .
    </p>
</div>
<div class="modal-footer">
    <button
        title="Close"
        aria-label="Close"
        type="button"
        class="btn btn-outline-secondary"
        (click)="activeModal.close('Close click')">Close</button>
</div>
