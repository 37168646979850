<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Terms & Conditions</h2>
    <button class="hidden-btn"></button>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p><strong>The main things you should know:</strong></p>
    <ul>
        <li>
            <strong>
                You are responsible for ensuring that information you provide is accurate and complete,
                as this will affect your results.
            </strong>
        </li>
        <li>
            <strong>
                Information provided by the Victorian Energy Compare Solar Calculator (The Solar
                Calculator) about your return on investment and future energy usage is a guide
                only.
            </strong>
        </li>
        <li>
            <strong>
                Not all energy offers available to you may be displayed on The Solar
                Calculator.
            </strong>
        </li>
        <li>
            <strong>
                You should make your own inquiries about energy plans that are suited to your needs. You
                should not rely solely on the information provided by The Solar Calculator.
            </strong>
        </li>
    </ul>
    <br />
    <p>
        <strong>Application and amendment of these terms and conditions</strong><br /> Your use of The Solar
        Calculator website is governed by these terms and conditions. By accessing and using The Solar
        Calculator, you agree to be bound and abide by these terms and conditions. Department of Energy, Environment and Climate Action (<strong>DEECA</strong>) reserves the right to amend these
        terms and conditions at any time.
    </p>
    <p>
        <strong>Your use of The Solar Calculator</strong><br /> You are entitled to, using a generally
        recognised web browser, download and view the content of this website and use The Solar Calculator
        for the purpose of obtaining information about your own household's return on investment and energy
        usage. This does not include obtaining information about the energy consumption and needs of others
        in order to advise them.
    </p>
    <p>
        <strong>You must not use The Solar Calculator:</strong>
    </p>
    <ul>
        <li>for any purpose that is unlawful or infringes any rights of the State of Victoria, its agents,
            instrumentalities or employees (together, the&nbsp;<strong>State</strong>) or the rights of a
            third party; or
        </li>
        <li>to breach or circumvent or attempt to breach or circumvent the security of this website
            (including hacking) or engage in any other malicious, illegal or damaging behaviour in relation
            to this website.
        </li>
    </ul>
    <br />
    <p>
        <strong>Reliance on The Solar Calculator</strong><br /> If you upload or otherwise use usage data
        provided by your energy retailer or distributor for the purposes of using The Solar Calculator, the
        energy company (and you, if you make any changes to the data), not the State, is responsible for the
        accuracy of that data. DEECA does not check that the data is correct and does not take any
        responsibility for the content of material provided to you by your energy company.
    </p>
    <p>
        Any information about your past, current or projected future energy usage that you obtain through use
        of The Solar Calculator is determined based on the information that you provide in response to
        questions. You are responsible for ensuring that the information that you provide is accurate and
        complete. The results provided by The Solar Calculator are estimates and are provided as a guide
        only. Although DEECA takes due care to ensure that the results are as accurate as possible, the
        results may not reflect your actual past, current or future energy usage. The information is not
        financial advice and may not accurately reflect the amount that you will pay for energy.
    </p>
    <p>
        The information about available energy usage is provided by energy retailers and made available to
        DEECA. The energy retailer is required to ensure that information is accurate and current. DEECA is
        not responsible for verifying its accuracy, currency or completeness and does not take any steps to
        do so. Accordingly, DEECA does not guarantee the accuracy, currency or completeness of that
        material. If you wish to take up any offer listed The Solar Calculator, you should verify the
        details of the offer with the energy retailer, including checking that the offer is available to you
        and all of the terms and conditions applicable to the offer. The Solar Calculator cannot provide
        information about whether certain discounts or incentives are available to you. You should not rely
        only on the information provided through The Solar Calculator as being correct, current and
        applicable to you or your circumstances.
    </p>
    <p>
        <strong>Disclaimer</strong>
    </p>
    <p>
        * Information provided by The Solar Calculator is general in nature and does not take into
        consideration your personal financial situation, or property-specific factors that may impact the
        effectiveness of PV solar panels for your property such as shading, roof area, roof pitch, and aspect.
        Calculation of solar benefit estimates are based on user-supplied post code and energy costs, historical
        consumption, and a blend of typical roof pitches and aspects.
    </p>
    <p>
        Solar benefit estimates do not constitute formal financial advice or a formal assessment of the suitability
        of PV solar panels for your property. Before acting, you should consider seeking independent personal
        financial advice that is tailored to your needs, as well as a quote from a qualified solar installer.
    </p>
    <p>
        Please speak to your solar installer and your electricity distributor to confirm whether you are
        able to export electricity to the grid before you decide to install solar panels. Some properties
        are unable to export electricity, or have limits on how much electricity can be exported. These
        limits and restrictions (known as 'export limiting') are based on the electricity infrastructure
        in your area and the rules can vary across different distribution zones in Victoria.
    </p>
    <p>
        <strong>Solar Calculator Assumptions:</strong>
    </p>
    <ul>
        <li>
            The solar generation curve is based on a 1 kW PV solar system Melbourne latitude.
        </li>
        <li>
            The Solar Calculator assumes a north orientation and an installation appropriate for the
            latitude (i.e. correct tilt).
        </li>
        <li>
            The monthly generation profiles are based on hourly weather data (temperature and sunshine) for
            a typical weather year.
        </li>
        <li>
            The Solar Calculator does not take into account any degradation in PV efficiency through age or
            maintenance.
        </li>
        <li>
            The net saving assumes you continue your current consumption patterns.
        </li>
        <li>
            Energy savings are calculated using the electricity rates (which include discounts and GST) and
            feed in tariffs you input into the calculator.&nbsp; These rates are assumed for all months and
            all years.
        </li>
        <li>
            Installation costs supplied by this tool are estimates only (based on simple installations) and
            should be updated by you following your discussion with installers.&nbsp;
        </li>
        <li>
            Break-even and payback calculations use simple payback calculations and do not include
            escalations or other financial calculations.
        </li>
    </ul>
    <p>&nbsp;</p>
    <p>
        <strong>Third party websites</strong><br /> This website may contain hyperlinks or references to
        other websites. The State does not claim any association with, and does not endorse, websites that
        are not clearly identified as sites maintained by the State. If you follow a hyperlink or are
        referred to another website, you are responsible for observing that website's terms of use. The
        State takes no responsibility for the accuracy or use of material on third party sites. The State is
        also not responsible for any non-availability of, or computer viruses, other harmful code, defects
        or errors in, third party sites.
    </p>
    <p>
        <strong>Your rights under law</strong><br /> To the extent that your use of The Solar Calculator or
        this website results in you acquiring services as a consumer within the meaning of the Australian
        Consumer Law (as set out in Schedule 2 to the <em>Competition and Consumer Act 2010</em> (Cth) and
        given effect under the Australian Consumer Law and Fair Trading Act 2012 (Vic)), you may have
        certain rights and remedies (including, without limitation, consumer guarantee rights) that cannot
        be excluded, restricted or modified by agreement. Nothing in these terms and conditions operates to
        exclude, restrict or modify the application of any implied condition or warranty, provision, the
        exercise of any right or remedy, or the imposition of any liability, implied or conferred under the
        Australian Consumer Law or any other statute, the exclusion, restriction or modification of which
        would contravene that statute or cause any of these terms and conditions to be void
        (<strong>Non-excludable Obligation</strong>).
    </p>
    <p>
        <strong>Operation of this website</strong><br /> Except in relation to Non-excludable Obligations,
        all conditions, warranties, guarantees, rights, remedies, liabilities or other terms implied or
        conferred by statute, custom, or the general law that impose any liability or obligation on the
        State are expressly excluded under these terms and conditions. Without limiting the above, the State
        does not warrant that:
    </p>
    <ul>
        <li>
            The Solar Calculator or this website will be free of infections by viruses, worms, spyware,
            adware, keyloggers, trojans or any other computer code, program or routine that is harmful,
            destructive, disabling or that assists in or enables theft, alteration, denial of service,
            unauthorised disclosure or destruction or corruption of data; or
        </li>
        <li>
            the functioning of The Solar Calculator or your access to The Solar Calculator or this website
            will be uninterrupted or error-free.
        </li>
    </ul>
    <p>
        You should use up-to-date virus protection software and other systems to protect your own computer
        and systems from viruses or other harmful code.
    </p>
    <p>
        <strong>Exclusions and limitations of liability</strong><br /> Except in relation to Non-excludable
        Obligations, the State will have no liability to you for any economic loss, loss of contract, loss
        of profit or revenue, loss of opportunity, loss of production, production stoppage, loss of data or
        other loss, harm, damage, cost or expense (including legal fees) in the nature of special, indirect
        or consequential loss or damage that arises directly or indirectly in connection with your use of
        The Solar Calculator or this website or the performance or non-performance of The Solar Calculator
        or this website, and whether arising under any indemnity, statute, in tort (for negligence or
        otherwise), or on any other basis in law or equity.
    </p>
    <p>
        The liability of the State to you:
    </p>
    <ul>
        <li>
            for a failure to comply with any Non-excludable Obligation, except for services of a kind
            ordinarily acquired for personal, domestic or household use or consumption (in respect of which
            liability is not so limited under these terms and conditions); or
        </li>
        <li>
            in connection with your use of The Solar Calculator or this website or the performance or
            non-performance of The Solar Calculator or this website, and whether arising under any
            indemnity, statute, in tort (for negligence or otherwise), or on any other basis in law or
            equity, other than for a Non-excludable Obligation,
        </li>
    </ul>
    <p>
        is limited to supplying the services again or payment of the cost of having the services supplied
        again.
    </p>
    <p>
        <strong>Suspension of access</strong><br /> Without limiting the above, DEECA may remove or suspend
        The Solar Calculator or this website, or access to them, for any reason including where there is a
        fault, harmful code, unauthorised access or other malicious attack on the website or DEECA's systems
        or for the purposes of upgrading or maintenance of the website.
    </p>
    <p>
        <strong>Termination of access</strong><br /> If, in DEECA's opinion, you breach any of these terms
        and conditions, DEECA may suspend, terminate or limit your access to The Solar Calculator or this
        website. DEECA does not waive any right under these terms and conditions unless the waiver is in
        writing signed by DEECA.
    </p>
    <p>
        <strong>Intellectual property</strong><br /> Without limiting any other terms and conditions that
        apply to this website, you must not reproduce, adapt, modify, communicate or otherwise use any part
        of The Solar Calculator or this website without written permission from DEECA or as expressly
        permitted in these terms and conditions. This does not limit your rights under law (including to
        engage in fair dealing with the copyright material in The Solar Calculator or this website for the
        purposes permitted under the <em>Copyright Act 1968</em> (Cth)).
    </p>
    <p>
        <strong>Privacy</strong><br /> Your use of The Solar Calculator is governed by the privacy statement
        for this website, which is located at&nbsp;<a href="https://www.deeca.vic.gov.au/privacy" target="_blank">https://www.deeca.vic.gov.au/privacy</a>
        &nbsp;and the DEECA privacy policy referred to in that statement. In addition to the collection of anonymous
        information through web servers set out in that privacy statement, in using The Solar Calculator you
        will be asked to supply certain information for the purposes of determining your estimated energy
        usage and profile, and potential solar generation. If you do not provide this information, you will
        not be able to obtain information from The Solar Calculator.
    </p>
    <p>
        Any such information that you supply through use of The Solar Calculator will only be used by DEECA
        for these purposes and will not be supplied to any third parties. The information will not be
        retained by DEECA beyond the end of your session, except that information will be retained in a form
        aggregated with the information provided by other users of the website, and as part of the caching
        that occurs during the ordinary operation of this website. Any such information that is personal information
        will be held and used in accordance with DEECA's privacy policy.
    </p>
</div>
