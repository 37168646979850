<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="col-md-12 col-xs-12">
        <div class="toptips-container">
            <h4>
                Saving money on your energy bills can be as easy as making a few simple changes. Victorian Energy
                Compare can help you find the most competitive energy offers available to you. Follow our tips to save.
            </h4>

            <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        1. Use Victorian Energy Compare
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <p>
                        Seven out of 10 Victorians can save money on their energy bills by using Victorian Energy Compare. Typically,
                        Victorian households can save $330 on energy bills annually, simply by using Victorian Energy Compare, and switching
                        their retailer or plan.
                    </p>

                    <p>
                        Victorian Energy Compare is the government's independent energy price comparison website. It searches every generally
                        available energy offer currently on the market so you can easily compare them against what you're currently paying.
                        All you need is a device with access to the internet, some information about your household, your past billing information,
                        and Smart Meter data.
                    </p>
                    <p>
                        If you're moving to a new house and don't have a bill you can still use Victorian Energy Compare by answering some
                        questions about your household and energy usage habits.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        2. Shop around at least once a year
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Energy customers should reconsider their energy offer at least once a year, as energy offers change regularly. Victorian
                        Energy Compare is a great place to start. You should also call your current retailer annually to ask if they have any
                        cheaper offers available that are better suited to your energy use and household needs.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        3. Switch retailers if you find a cheaper deal
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Switching energy retailers is easy. If you find a cheaper offer on Victorian Energy Compare,
                        and want to switch, you must:
                    </p>
                    <ul>
                        <li>
                            Call the new retailer using the contact details provided in the Victorian Energy Compare offer summary.
                        </li>
                        <li>
                            Mention the offer name, ID and rate as shown on Victorian Energy Compare.
                        </li>
                    </ul>
                    <p>
                        Your new energy retailer will organise the rest, including contacting your current retailer to let them know
                        about the change, so you don't have to.
                    </p>
                    <p>
                        It doesn't cost anything to switch. However, depending on your current contract, there may be an exit fee.
                        Check this with your current retailer. Exit fees are generally low, or much less than the saving made by
                        switching. You should also check with your current retailer to see if they can give you a better offer, or
                        match the new offer.
                    </p>
                    <p>
                        Your electricity or gas supply will not be interrupted when you change contracts or switch to a different
                        energy company. Your new retailer will use the existing distribution network and your Smart Meter.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        4. Read and understand your bills
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Every energy retailer's billing format is different, however, there are key details in all bills that
                        are generally the same. Familiarise yourself with your bills so that you can easily find these details,
                        and understand how much energy you are using, and what you are paying for it. Take note of:
                    </p>
                    <ul>
                        <li>
                            <span class="strong">The billing period</span>: This is the period covered by your bill.
                        </li>
                        <li>
                            <span class="strong">Average daily usage</span>: This is how much energy you used on average, per day, during
                            the billing period. It's measured in kilowatt hours (kWh) for electricity, and megajoules (MJ) for gas.
                        </li>
                        <li>
                            <span class="strong">Daily supply charge</span>: This is a fixed charge for the supply of energy to your property.
                            It is charged to you regardless of how much energy you use.
                        </li>
                        <li>
                            <span class="strong">Usage charge/s</span>: This is based on the actual amount of energy you use. On your bill, you
                            will be charged by as cents per kilowatt hour (c/kWh) for electricity, and/or cents per megajoule (c/MJ) for gas.
                        </li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        5. Understand your pricing structure
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        The price you are paying on your electricity plan for usage is also called a tariff or rate. Pricing or tariff structures
                        differ from offer to offer. The most common tariff structures offered by retailers are:
                    </p>
                    <ul>
                        <li>
                            <span class="strong">Flat rate</span>: The same rate is charged for electricity consumed at any time of the day or night.
                        </li>
                        <li>
                            <span class="strong">Time-of-use</span>: A different price is charged according to when the electricity is used.
                            Time-of-use tariffs usually have peak and off-peak pricing. Users are charged less for electricity during 'off-peak' or low
                            demand periods, and a higher rate for electricity used during high demand or 'peak' periods. Not everyone is eligible for
                            time-of-use offers – check with your retailer.
                        </li>
                        <li>
                            <span class="strong">Flexible pricing</span>: This is an extended 'time-of-use' tariff, with peak, shoulder and off-peak rates.
                        </li>
                        <li>
                            <span class="strong">Block pricing</span>: Some retailers calculate rates by splitting usage into blocks. Each block is charged at a different price depending on how much energy is used.
                        </li>
                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        6. Know the difference between your retailer and distributor
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Distributors own the energy infrastructure, including poles and wires, that deliver electricity or gas to your home or business.
                        They also own your Smart Meter. Each distributor oversees the servicing of a geographical area, and you cannot choose your
                        distributor.
                    </p>
                    <p>
                        Retailers buy the electricity from distributors and sell it to you. Retailers typically offer many different deals. Changing
                        retailer has no effect on the reliability or quality of your supply. Retailers also operate customer service centres and provide
                        direct services to households.
                    </p>
                    <p>
                        Both distributors and retailers must comply with government legislation in delivering their services to consumers.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        7. Consider what makes a "good" offer
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        What makes a "good" offer differs from household to household. This is because every household is unique, and consumes energy
                        differently. Factors including the number of people living in your home, the number of rooms you have, the types of appliances
                        you use, the way you heat and cool your home, and if you have solar will all effect what makes a good offer for you. There is no
                        one standard of "good" when it comes to energy offers, and using Victorian Energy Compare is a great way to find out what offers
                        best suit your specific needs.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        8. Check the conditions of any discounts
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        Many offers come with discounts. When choosing the right offer for you, be aware of these discounts and consider what conditions
                        they come with. For instance, some discounts only apply to your usage charge, and not your supply charge. Most discounts require
                        you to pay on time, so if you find you often pay late, you might end up paying more than you would on an offer with no discounts.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        9. Find out if you are eligible for any government concessions
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        You may also be eligible for government concessions, which are available for both electricity and gas. For most
                        concessions, you will need to have one of these cards:
                    </p>
                    <ul>
                        <li>
                            Centrelink Health Care Card (cards issued in the name of a child are not eligible)
                        </li>
                        <li>
                            Centrelink Pensioner Concession Card
                        </li>
                        <li>
                            Veterans' Affairs Pensioner Concession Card
                        </li>
                        <li>
                            Veterans' Affairs Gold Card for all conditions
                        </li>
                    </ul>
                    <p>
                        To receive a concession, you must contact your energy retailer and provide your concession details.
                        You should also regularly check that your concessions are still being applied to your bill.
                    </p>
                    <p>
                        For more details on the different types of energy concessions and how to apply, contact your energy
                        retailer, check the Department of Health and Human Service's
                        "<a class="hot-topics" target="_blank" href="https://services.dhhs.vic.gov.au/concessions-and-benefits">Concessions &amp; benefits</a>"
                        page or call the Victorian Government's Concessions Information Line on <a class="hot-topics" href="tel:1800 658 521">1800 658 521</a>.
                    </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        10. Be energy efficient
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        You can also lower your energy bills by using energy more efficiently. This could mean replacing old
                        or faulty appliances, adjusting your heating and cooling habits, or simply remembering to turn off
                        the lights when you leave the room. For more information about energy efficiency, visit
                        <a class="hot-topics" target="_blank" href="https://www.energy.vic.gov.au/for-households/save-energy-and-money/top-10-energy-saving-tips">
                            https://www.energy.vic.gov.au/for-households/save-energy-and-money/top-10-energy-saving-tips
                        </a>.
                    </p>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
</div>
<br /><br />
