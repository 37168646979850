import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from '../utility';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptchaService } from '../reCaptcha.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {

  public recaptchaEnabled = environment.recaptchaEnabled;
  public FeedbackForm : FormGroup;
  public emailStatus;
  public errorMessage = '';
  public feedbackId = '';
  public recaptchaResponse;
  public messageIsSent = false;
  public reviewForm = false;
  public googleSiteKey = environment.googleSiteKey;
  public bannerTitle = "Provide feedback";
  public bannerSubTitle = "To provide us feedback, give us compliment or to make a complaint you can contact the Victorian Energy Compare Support team";
  public bannerDetails = "";
  enquiryRelatingTo : any = ['General', 'Technical issue', 'Energy offer comparison', 'Power Saving Bonus', 'Energy Bill Relief'];
  natureOfEnquiry: any = ['Complaint', 'Feedback', 'Compliment',];
  public contactData = {
    firstName : "",
    lastName : "",
    email : "",
    emailConfirm : "",
    phoneNumber : "",
    offerIdNumber : "",
    postcode : "",
    message : "",
    natureOfEnquiry : "",
    enquiryRelatingTo : "",
    contactedBefore : "",
    refNumber : "",
    recaptchaResponse : ""
  }

  constructor(
    private reCaptchaService: ReCaptchaService,
    public reCaptchaV3Service : ReCaptchaV3Service,
    public formBuilder : FormBuilder,
    public spinner : NgxSpinnerService,
    public utility : Utility,
    public dataService : DataService) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Feedback Form - Victorian Energy Compare';

    //subscribe for reCaptcha token
    this.reCaptchaService.token$.subscribe(token => {
      this.contactData.recaptchaResponse = token;
    });

    this.createForm();
  }

  public createForm() {
    this.FeedbackForm = this.formBuilder.group({
      firstName : new FormControl('', [
        Validators.required
      ]),
      lastName : new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$")]),
      emailConfirm: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$")]),
      message : new FormControl('', [
        Validators.required
      ]),
      enquiryRelatingTo : new FormControl('', [
        Validators.required
      ]),
      natureOfEnquiry : new FormControl('', [
        Validators.required
      ]),
      refNumber : new FormControl('', [
      ]),
      offerIdNumber : new FormControl('', [
      ]),
      phoneNumber : new FormControl('', [
      ]),
      contactedBefore : new FormControl('', [
      ]),
      postcode : new FormControl('', [
        Validators.required
      ]),
      recaptchaResponse : new FormControl('')
    });

    this.FeedbackForm.get('natureOfEnquiry').valueChanges.subscribe(val => {
      if (val == "Power Saving Bonus") {
        this.FeedbackForm.controls['refNumber'].setValidators([Validators.required]);
      } else {
        this.FeedbackForm.controls['refNumber'].clearValidators();
      }
      this.FeedbackForm.controls['refNumber'].updateValueAndValidity();
    });
  }

  public resetForm() {
    this.contactData = {
      firstName : "",
      lastName : "",
      email : "",
      emailConfirm : "",
      phoneNumber : "",
      offerIdNumber : "",
      postcode : "",
      natureOfEnquiry : "",
      enquiryRelatingTo : "",
      contactedBefore : "",
      refNumber : "",
      message : "",
      recaptchaResponse : ""
    };
  }


  public review() {
    if (this.FeedbackForm.status == 'VALID') {
      this.reviewForm = true;
    }
  }

  public back() {
    this.reviewForm = false;
  }

  public sendMessage() {
    if (this.recaptchaEnabled == true) {
      this.reCaptchaV3Service.execute(this.googleSiteKey, 'feedback', (token) => {
          this.contactData.recaptchaResponse = token;
          this.sendEmailMessage();
        },
        {
          useGlobalDomain: false
        }
      );
    } else {
      this.sendEmailMessage();
    }
  }

  public sendEmailMessage() {
    if (this.FeedbackForm.status == 'VALID') {
      let message = this.contactData.message.replace(/<[^>]*>/g, '\n'); //remove html tags
      //send message to backend
      this.spinner.show();
      this.dataService.sendFeedbackMessage(this.contactData.firstName, this.contactData.lastName, this.contactData.email, this.contactData.phoneNumber, this.contactData.postcode ,this.contactData.natureOfEnquiry ,this.contactData.enquiryRelatingTo, this.contactData.refNumber, this.contactData.offerIdNumber, this.contactData.contactedBefore, message, this.contactData.recaptchaResponse).subscribe(
        result => {
          this.emailStatus = result["status"];
          if (this.emailStatus == "error") {
            this.errorMessage = result["message"];
            setTimeout(() => {
              let error = document.querySelector('.alert-danger');
              error.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
          } else {
            this.feedbackId = result["feedbackId"];
            this.messageIsSent = true;
          }
          this.spinner.hide();
        },
        error => {
          this.errorMessage = 'An error has occurred while trying to send the email, please try again later';
          this.spinner.hide();
        },
        () => {
          this.reCaptchaService.initializeRecaptcha();
        }
      );
    } else {
      this.utility.scrollToError();
    }
  }

  get fbForm() {
    return this.FeedbackForm.controls;
  }

}

