// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appURL: 'https://compare-uat.energy.vic.gov.au/',
  apiEndpoint: 'https://compare-uat.energy.vic.gov.au/api/',
  compareURL: 'https://compare-uat.energy.vic.gov.au/',
  solarURL: 'https://compare-uat.energy.vic.gov.au/solar-calculator/',
  liveChatOn: false,
  liveChatID: '9022070',
  pageLimit: 5,
  emailSecurityKey: 'esr4352ue0ouiaaq4562a100',
  psbURL: 'https://powersavingbonus2-uat.energy.vic.gov.au/',
  psb3URL: 'https://powersavingbonus3-uat.energy.vic.gov.au/',
  ebrURL: 'https://en-billrelief-uat.energy.vic.gov.au/',
  recaptchaEnabled: true,
  googleSiteKey: '6Lew0uMUAAAAALCMcHA3J1IwPNCJbAUEsaaoBRck',
  surveyEnabled: true,
  surveyURL: "https://www.surveymonkey.com/s/7T67VD5",
  gst: true,
  standAlone: true,
  secBranding: true,
  reCaptchaAutoRefreshInterval: 60000,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
