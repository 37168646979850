import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Utility } from '../utility';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.css']
})
export class OfflineComponent implements OnInit {
  public bannerTitle = "Victorian Energy Compare is under maintenance";
  public bannerSubTitle = "";
  public bannerDetails = "Victorian Energy Compare has been taken offline to allow maintenance work to occur. It is expected to be back online shortly.<br /><br />We apologise for the inconvenience.";

  constructor(public router : Router,
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Victorian Energy Compare is under maintenance';

  }

}
