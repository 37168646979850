import { ErrorMessage } from "ng-bootstrap-form-validation";
 
export const CUSTOM_ERRORS : ErrorMessage[] = [
  {
    error: "NMIPrefixAusnet",
    format: NMIPrefixAusnet
  },
  {
    error: "NMIPrefixJemena",
    format: NMIPrefixJemena
  },
  {
    error: "NMIPrefixPowercor",
    format: NMIPrefixPowercor
  },
  {
    error: "NMIPrefixUnited",
    format: NMIPrefixUnited
  },
  {
    error: "NMIPrefixCitipower",
    format: NMIPrefixCitipower
  },
  {
    error: "NMIPrefixStartAusnet",
    format: NMIPrefixStartAusnet
  }, 
  {
    error: "NMIPrefixStartJemena",
    format: NMIPrefixStartJemena
  }, 
  {
    error: "NMIPrefixStartPowercor",
    format: NMIPrefixStartPowercor
  }, 
  {
    error: "NMIPrefixStartUnited",
    format: NMIPrefixStartUnited
  }, 
  {
    error: "NMIPrefixStartCitipower",
    format: NMIPrefixStartCitipower
  }, 
  {
    error: "required",
    format: requiredFormat
  },
  {
    error: "pattern",
    format: patternFormat
  },
  {
    error: "dateForward",
    format: dateForwardFormat
  },
  {
    error: "dateBackward",
    format: dateBackwardFormat
  },
  {
    error: "minlength",
    format: lengthFormat
  },
  {
    error: "NMILength",
    format: NMILengthFormat
  },
  {
    error: "AUNumber",
    format: AUNumberFormat
  },
  {
    error: "emailFormat",
    format: emailFormat
  },
  {
    error: "NMIStartPrefix",
    format: NMIStartPrefixFormat
  },
  {
    error: "NMIPrefix",
    format: NMIPrefixFormat
  },
  {
    error: "maxlength",
    format: maxLengthFormat
  },
  {
    error: "min",
    format: minValueFormat
  },
  {
    error: "max",
    format: maxValueFormat
  }
];

export function emailFormat(label: string, error: any) : string {
  return 'Invalid Email';
}

export function AUNumberFormat(label: string, error: any) : string {
  return 'Please enter a valid Australian phone number';
}

export function dateForwardFormat(label: string, error: any) : string {
  let currentDate = new Date();
  return 'Please enter a date on or before ' + currentDate.getDate() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear();
}

export function dateBackwardFormat(label: string, error: any) : string {
  let currentDate = new Date();
  return 'Invalid ' + label;
}

export function NMILengthFormat(label: string, error: any) : string {
  return 'Please enter at least 10 digits. The NMI is a 10 or 11 digit number beginning with 6.';
}

export function NMIPrefixAusnet(label: string, error: any) : string {
  return 'The value you have entered is not a valid NMI. The NMI is a 10 or 11 digit number beginning with 6305 or 6306.';
}

export function NMIPrefixJemena(label: string, error: any) : string {
  return 'The value you have entered is not a valid NMI. The NMI is a 10 or 11 digit number beginning with 6001.';
}

export function NMIPrefixPowercor(label: string, error: any) : string {
  return 'The value you have entered is not a valid NMI. The NMI is a 10 or 11 digit number beginning with 6203 or 6204.';
}

export function NMIPrefixUnited(label: string, error: any) : string {
  return 'The value you have entered is not a valid NMI. The NMI is a 10 or 11 digit number beginning with 6407 or 6408.';
}

export function NMIPrefixCitipower(label: string, error: any) : string {
  return 'The value you have entered is not a valid NMI. The NMI is a 10 or 11 digit number beginning with 6102 or 6103.';
}

export function NMIPrefixStartAusnet(label: string, error: any) : string {
  return 'The value you have entered looks like a meter number. The NMI is a 10 or 11 digit number beginning with 6305 or 6306.';
}

export function NMIPrefixStartJemena(label: string, error: any) : string {
  return 'The value you have entered looks like a meter number. The NMI is a 10 or 11 digit number beginning with 6001.';
}

export function NMIPrefixStartPowercor(label: string, error: any) : string {
  return 'The value you have entered looks like a meter number. The NMI is a 10 or 11 digit number beginning with 6203 or 6204.';
}

export function NMIPrefixStartUnited(label: string, error: any) : string {
  return 'The value you have entered looks like a meter number. The NMI is a 10 or 11 digit number beginning with 6407 or 6408.';
}

export function NMIPrefixStartCitipower(label: string, error: any) : string {
  return 'The value you have entered looks like a meter number. The NMI is a 10 or 11 digit number beginning with 6102 or 6103.';
}

export function NMIPrefixFormat(label: string, error: any) : string {
  return 'The value you have entered is not a valid NMI. The NMI is a 10 or 11 digit number beginning  with 6001, 6102, 6103, 6203, 6204, 6305, 6306, 6407 or 6408.';
}

export function NMIStartPrefixFormat(label: string, error: any) : string {
  return 'The value you have entered looks like a meter number. The NMI is a 10 or 11 digit number beginning with 6001, 6102, 6103, 6203, 6204, 6305, 6306, 6407 or 6408.';
}

export function minValueFormat(label: string, error: any) : string {
  if (label == 'How would you like to build your energy usage profile?') {
    return 'Please select an option';
  } else if (label == 'How many people live in your house?' || 
             label == 'How many rooms (total)?' || 
             label == 'How far is your home from the sea?' || 
             label == 'How many refrigerators do you own (exclude freezer only units)?') {
      return 'Please select an option';
  } else {
    return `${label}` + ' must be at least ' + error.min;
  }
}

export function maxValueFormat(label: string, error: any) : string {
  if (label == 'Your average daily usage in kWh') {
    return 'Your average daily usage must be no greater than ' + error.max + ' kWh';
  } else if (label == 'Your average daily usage in MJ') {
    return 'Your average daily usage must be no greater than ' + error.max + ' MJ';
  } else {
    return `${label}` + ' must be no greater than ' + error.max;
  }
}

export function lengthFormat(label: string, error: any) : string {
  if (label == 'What is your NMI?') {
    return 'NMI ' + 'must be at least ' + error.requiredLength + ' characters';
  } else if (label == 'What is your postcode?') {
    return 'Postcode must be at least ' + error.requiredLength + ' characters';
  } else if (label == 'How would you like to build your energy usage profile? ') {
    return 'Please select one';
  } else {
    return `${label}` + ' must be at least ' + error.requiredLength + ' characters';
  }
}

export function maxLengthFormat(label: string, error: any) : string {
  if (label == 'What is your NMI?') {
    return 'NMI ' + 'must be at least ' + error.requiredLength + ' characters';
  } else if (label == 'What is your postcode?') {
    return 'Postcode must be ' + error.requiredLength + ' characters only';
  } else {
    return `${label}` + ' must be at least ' + error.requiredLength + ' characters';
  }
}

export function requiredFormat(label : string, error: any) : string {
    if (label == 'Off-Peak' || label == 'Discount' || label == 'Discount applied to' || 
        label == 'How many refrigerators do you own (exclude freezer only units)?' || 
        label == 'How far is your home from the sea?' || 
        label == 'Do you have a washing machine?' || 
        label == 'Do you have gas?') {
      return `Please select an option`;
    } else if (label == 'I have read and agree to the  terms and conditions') {
      return `Please agree to the disclaimer agreement`;
    } else if (label == 'Do you have off-peak rates?' || label == 'Do you have any discounts?') {
      return `${label} please make a selection`;
    } else if (label == 'What is the capacity of the solar panels?') {
      return 'Solar Capacity is required';
    } else if (label == 'Where did you get the file?') {
      return 'File provider is required';
    } else if (label == 'What is your postcode?') {
      return 'Postcode is required';
    } else if (label == 'I have read and agree to the') {
      return 'Please agree to the terms & conditions';
    } else if (label == 'What is your NMI?') {
      return 'NMI is required';
    } else if (label == 'Who is your current retailer?') {
      return 'Please select a retailer';
    } else if (label == 'Do you have a pool?' || label == 'Do you have a clothes dryer?'
               || label == 'Do you have a controlled load connected to your house?') {
      return 'Please select an option';
    } else {
      return `${label} is required`;
    }

}

export function patternFormat(label: string, error: any) : string {
  if (label == 'Do you have off-peak rates?' || label == 'Do you have any discounts?' || 
      label == 'Discount applied to' || label == 'Do you have shoulder rates?') {
    return `Please make a selection`;
  } else if (label == 'I have read and agree to the') {
    return 'Please agree to the terms & conditions';
  } else if (label == 'What is your postcode?') {
    return  'Invalid postcode';
  } else if (label == 'What is your NMI?') {
    return  'Invalid NMI';
  } else if (label == 'Who is your current retailer?') {
    return 'Please select a retailer';
  } else if (label == 'Your Daily supply charge') {
    return 'Invalid Format';
  } else {
    if (label == 'Email or Mobile Number') {
      label = 'Email';
    }
    return  'Invalid ' + label;
  }
}