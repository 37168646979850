import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { NgxSpinnerService } from "ngx-spinner";
declare const googleAnalytics4Languages: any;

@Component({
  selector: 'app-faq-offers-modal',
  templateUrl: './faq-offers-modal.component.html',
  styleUrls: ['./faq-offers-modal.component.css']
})
export class FaqOffersModalComponent implements OnInit {

  public sortOrders: any = [
    { langCode: "en", language: "English"},
    { langCode: "ar", language: "Arabic - العربية"},
    { langCode: "cn", language: "Chinese (Simplified) - 简体中文"},
    { langCode: "zh", language: "Chinese (Traditional) - 繁體中文"},
    { langCode: "ph", language: "Filipino - Filipino"},
    { langCode: "gr", language: "Greek - ελληνικά"},
    { langCode: "it", language: "Italian - Italiano"},
    { langCode: "kh", language: "Khmer - ភាសាខ្មែរ"},
    { langCode: "ko", language: "Korean - 한국어"},
    { langCode: "mk", language: "Macedonian - македонски"},
    { langCode: "om", language: "Oromo - Afaan Oromoo"},
    { langCode: "pa", language: "Punjabi - ਪੰਜਾਬੀ"},
    { langCode: "es", language: "Spanish - Español"},
    { langCode: "tr", language: "Turkish - Türkçe"},
    { langCode: "so", language: "Somali - Soomaali"},
    { langCode: "vn", language: "Vietnamese - Tiếng Việt"},
   ];


  public selectedSortOrder: string = "English";

  public ChangeSortOrder(newSortOrder: object) {
    this.selectedSortOrder = newSortOrder["language"];
    this.loadLanguage(newSortOrder["langCode"]);
    localStorage.setItem("langCode", newSortOrder["langCode"]);
    localStorage.setItem("language", newSortOrder["language"]);
    googleAnalytics4Languages(newSortOrder["language"].slice(0, newSortOrder["language"].indexOf('-')));
  }

  public language = 'en';
  public FAQContent = {
    'key' : '',
    'file' : {
      'offers' : ''
    },
    'tracks' : {
      'offers' : {}
    },
    'lbl_lang_video' : '',
    'lbl_results_header' : '',
    'lbl_results_video_script' : '',
    'lbl_results_about_q1' : '',
    'lbl_results_about_a1' : '',
    'lbl_results_subheader' : '',
    'lbl_results_faq_q1' : '',
    'lbl_results_faq_a1' : '',
    'lbl_results_faq_q2' : '',
    'lbl_results_faq_a2' : '',
    'lbl_results_faq_q3' : '',
    'lbl_results_faq_a3' : '',
    'lbl_results_faq_q4' : '',
    'lbl_results_faq_a4' : '',
    'lbl_results_faq_q5' : '',
    'lbl_results_faq_a5' : '',
    'lbl_results_faq_q6' : '',
    'lbl_results_faq_a6' : ''
  };
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public spinner: NgxSpinnerService,
    public dataService : DataService) {}

  ngOnInit(): void {
    setTimeout(() => {
      if(localStorage.getItem("langCode")  !== null) {
        this.loadLanguage(localStorage.getItem("langCode"));
        this.selectedSortOrder = localStorage.getItem("language");
      } else {
        this.loadLanguage(this.language);
      }
    });
  }

  public loadLanguage(language) {
    this.language = language;
    this.spinner.show();
    this.dataService.getFAQ(language).subscribe(
      faq => {
        this.FAQContent = faq["data"];
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      }
    );
  }
}
