import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SolarTermsModalComponent } from '../solar-terms-modal/solar-terms-modal.component';
import { Utility } from '../utility';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-solar-details',
  templateUrl: './solar-details.component.html',
  styleUrls: ['./solar-details.component.css'],
  providers : [ NgbModalConfig, NgbModal ]
})
export class SolarDetailsComponent implements OnInit {
  public saveFormError = '';
  public solarForm : FormGroup;
  private subscription : Subscription;

  public solarData = {
    offPeakRate : -1,
    offPeakRateValue : '',
    shoulderRate : -1,
    shoulderRateValue : '',
    discount : -1,
    discountRateValue : '',
    peakRateValue : '',
    dailySupplyCharge : '',
    usage : -1,
    agree : 0,
    energyType : "",
    pageDataType : "solarData",
    serverCacheId : ""
  };

  constructor(public tooltipConfig : NgbTooltipConfig,
              public utility : Utility,
              public config : NgbModalConfig,
              private modalService : NgbModal,
              public dataService : DataService,
              public router : Router,
              private formBuilder : FormBuilder,
              public spinner : NgxSpinnerService) {
                this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
                this.createForm();
              }

  ngAfterViewInit() {
    GA4LogEvent('/solar-details', 'SOLAR_CALCULATOR_DETAILS');
  }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.dataService.changeBillPage('false');
    this.dataService.changeEnergyProfilePage('false');
    this.dataService.changeEnergyType('Solar');
    this.modalService.dismissAll(); //close modal dialog if there are any open
    document.title = 'Solar savings calculator - VEC Solar Journey';
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.solarData.serverCacheId = serverCacheId;
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.existing == false) {
            this.utility.sessionRestart();
            this.utility.removeServerCacheId(serverCacheId);
            this.utility.redirectToSolarHome();
          } else {
            //check solar consumption
            if (results.hasSolarConsumption == false) {
              this.utility.redirectToSolarHome();
              return false;
            }
            let data = results.solarData;
            if (results.billDetailsData.billStartDate != undefined) {
              this.dataService.changeBillPage('true');
            }
            if (results.energyProfileData.energyType != undefined) {
              this.dataService.changeEnergyProfilePage('true');
            }
            this.solarData.energyType = results.energyConfigData.energyType;
            this.solarData.offPeakRate = data.offPeakRate;
            this.solarData.offPeakRateValue = data.offPeakRateValue;
            this.solarData.shoulderRate = data.shoulderRate;
            this.solarData.shoulderRateValue = data.shoulderRateValue;
            this.solarData.discount = data.discount;
            this.solarData.discountRateValue = data.discountRateValue;
            this.solarData.peakRateValue = data.peakRateValue;
            this.solarData.dailySupplyCharge = data.dailySupplyCharge;
            this.solarData.usage = data.usage;
            this.solarData.agree = data.agree;
          }
          this.spinner.hide();
        },
        error => {
          //get form data from dataservice
          this.spinner.hide();
          this.utility.redirectToSolarHome();
        }
      );
    } else {
      this.spinner.hide();
      this.utility.redirectToSolarHome();
    }
  }

  public termsModal() {
    this.modalService.open(SolarTermsModalComponent, {size : 'lg'});
  }

  public createForm() {
    this.solarForm = this.formBuilder.group({
      offPeakRate : new FormControl('', [Validators.required, Validators.pattern('^[01]+$')]), //allow 1 or 0 only
      offPeakRateValue : new FormControl(''),
      shoulderRate : new FormControl(''),
      shoulderRateValue : new FormControl(''),
      discountRateValue : new FormControl(''),
      agree : new FormControl('', [Validators.requiredTrue]),
      usage : new FormControl(''),
      peakRateValue : new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$') //field type must be float/decimal
      ]),
      dailySupplyCharge : new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(999),
        Validators.pattern('^[0-9]+(.[0-9]{0,3})?$') //field type must be float/decimal
      ]),
      discount : new FormControl('', [Validators.required, Validators.pattern('^[01]+$')]), //allow 1 or 0 only
    });


    /*
    * custom validation for fields
    * validation rules based on other fields
    * if a field gets selected then some other field will have validation
    */
    var offPeakRateValidation = <FormControl>this.solarForm.get('offPeakRate');
    var offPeakRateValueValidation = <FormControl>this.solarForm.get('offPeakRateValue');
    var shoulderRateValidation = <FormControl>this.solarForm.get('shoulderRate');
    this.subscription = offPeakRateValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        shoulderRateValidation.setValidators([Validators.pattern('^[01]+$')]);
        offPeakRateValueValidation.setValidators(
          [
            Validators.required,
            Validators.min(1),
            Validators.max(999),
            Validators.pattern('^[0-9]+(.[0-9]{0,3})?$') //field type must be float/decimal
          ]
        );
      } else {
        offPeakRateValueValidation.setValidators(null);
        shoulderRateValidation.setValidators(null);
        this.solarData.offPeakRateValue = '';
        this.solarData.shoulderRate = -1;
        offPeakRateValueValidation.updateValueAndValidity();
      }
    });

    var shoulderRateValueValidation = <FormControl>this.solarForm.get('shoulderRateValue');
    this.subscription = shoulderRateValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        shoulderRateValueValidation.setValidators(
          [
            Validators.required,
            Validators.min(1),
            Validators.max(999),
            Validators.pattern('^[0-9]+(.[0-9]{0,3})?$') //field type must be float/decimal
          ]
        );
      } else {
        shoulderRateValueValidation.setValidators(null);
        this.solarData.shoulderRateValue = '';
        shoulderRateValueValidation.updateValueAndValidity();
      }
    });


    var discountValidation = <FormControl>this.solarForm.get('discount');
    var discountRateValueValidation = <FormControl>this.solarForm.get('discountRateValue');
    var usageValidation = <FormControl>this.solarForm.get('usage');
    this.subscription = discountValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        discountRateValueValidation.setValidators(
          [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            Validators.pattern('^[0-9]+(.[0-9]{0,3})?$') //field type must be float/decimal
          ]
        );
        usageValidation.setValidators(
          [
            Validators.pattern('^[01]+$')
          ]
        );
      } else {
        discountRateValueValidation.setValidators(null);
        usageValidation.setValidators(null);
        usageValidation.updateValueAndValidity();
        discountRateValueValidation.updateValueAndValidity();
        this.solarData.discountRateValue = '';
        this.solarData.usage = -1;
      }
    });
  }

  public submitSolarForm() {
    //scroll page to top
    this.utility.scrollToTop('none');

    if (this.solarForm.status == 'VALID') {
      this.spinner.show();
      this.dataService.saveData(this.solarData).subscribe(
        data => {
          if (data.status == 'success') {
            this.utility.setErrorMessage(''); //clear error message if any
            this.router.navigate(['/solar-results']); //redirect to result page
          } else {
            this.spinner.hide();
            this.saveFormError = data.message;
            this.utility.scrollToTop('none');
          }
        },
        error => {
          this.spinner.hide();
          this.saveFormError = 'error';
          this.utility.scrollToTop('none');
        }
      );
    } else {
      this.solarForm.markAllAsTouched();
      this.utility.scrollToError();
    }
  }

  public selectButton(event, name) {
    if (event.keyCode == 32 || event.keyCode == 13) { //spacebar & enter
      if (name == "offPeakRateYes") {
        this.solarData.offPeakRate = 1;
      } else if (name == "offPeakRateNo") {
        this.solarData.offPeakRate = 0;
      } else if (name == "shoulderRateYes") {
        this.solarData.shoulderRate = 1;
      } else if (name == "shoulderRateNo") {
        this.solarData.shoulderRate = 0;
      } else if (name == "discountYes") {
        this.solarData.discount = 1;
      } else if (name == "discountNo") {
        this.solarData.discount = 0;
      } else if (name == "usage") {
        this.solarData.usage = 1;
      } else if (name == "bill") {
        this.solarData.usage = 0;
      } else if (name == "terms") {
        this.termsModal();
      }
    }
  }
}
