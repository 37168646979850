<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="smcx-sdk col-md-8">
        <div id="smcx-sdk"></div>
    </div>
</div>
<br /><br />

