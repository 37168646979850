<div class="container">
    <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails">
    </app-banner>

    <div class="col-lg-12" *ngIf="billDetailsForm.billDetails.energyType != ''"
        [ngClass]="{ 'solar-background' : (billDetailsForm.billDetails.energyType == 'Solar') }">
        <h2 class="subTitle" id="billDetails">Your
            {{ (billDetailsForm . billDetails . energyType == 'Solar' ? 'Electricity' : billDetailsForm . billDetails . energyType) | lowercase }}
            bill details:</h2>

        <div class="row" *ngIf="billDetailsForm.saveFormError != ''">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        An error has occured while trying to submit the information you have provided. Please try again.
                    </h4>
                </div>
            </div>
        </div>

        <div *ngIf="billDetailsForm.billDetails.energyType != 'Gas' && billDetailsForm.showElectricityForms == true">
            <form method="post" id="rateForm" [formGroup]="billDetailsForm.billDetailsForm" class="col-md-12"
                onsubmit="return false" (keydown.enter)="$event.preventDefault()">
                <div class="row zero-height" role="group">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="input-group">
                                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                    [autoClose]="'outside'"
                                    (dateSelect)="billDetailsForm.onDateSelection($event, datepicker)"
                                    [displayMonths]="(billDetailsForm.isMobile == true) ? 1 : 2" [dayTemplate]="t"
                                    outsideDays="hidden" [startDate]="billDetailsForm.fromDate!"
                                    [maxDate]="billDetailsForm.maxDate" [minDate]="billDetailsForm.minDate"
                                    style="opacity: 0;">
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                        [ngClass]="{'custom-day-disabled' : billDetailsForm.isDisabled(date)}"
                                        [class.focused]="focused" [class.range]="billDetailsForm.isRange(date)"
                                        [class.faded]="billDetailsForm.isHovered(date) || billDetailsForm.isInside(date)"
                                        (mouseenter)="billDetailsForm.hoveredDate = date"
                                        (mouseleave)="billDetailsForm.hoveredDate = null">
                                        {{ date . day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding page-divider-up">
                    <div class="col-md-4 custom-margin">
                        <label class="h3 bill-date-group control-label">
                            Your electricity bill period:
                            <i aria-label="The bill period is the time from one bill to the next bill. You pay for the electricity you used during the bill period. Your bill might say something like ’28 Jul 2015 to 21 Sept 2015 (56 days)’. The bill period is not related to the invoice date or processing data shown on the bill."
                                class="material-icons non-focus" tabindex="-1" [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="The bill period is the time from one bill to the next bill. You pay for the electricity you used during the bill period. Your bill might say something like ’28 Jul 2015 to 21 Sept 2015 (56 days)’. The bill period is not related to the invoice date or processing data shown on the bill."></i>
                        </label>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <label class="control-label" for="elec-start-date">START DATE:</label>
                            <div class="input-group form-group">
                                <label class="hidden-label">Start date </label>
                                <input #dpFromDate id="startDate" tabindex="1" class="form-control"
                                    placeholder="dd//mm/yyyy" formControlName="billStartDate"
                                    [(ngModel)]="billDetailsForm.billDetails.electricityBillStartDate"
                                    [value]="billDetailsForm.billDetails.electricityBillStartDate"
                                    (input)="billDetailsForm.fromDate = billDetailsForm.validateInput(billDetailsForm.fromDate, dpFromDate.value)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
                                        type="button" tabindex="2">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <bfv-messages class="date-error"></bfv-messages>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <label class="control-label" for="elec-end-date">END DATE:</label>
                            <div class="input-group form-group">
                                <label class="hidden-label">End date</label>
                                <input #dpToDate tabindex="3" class="form-control" placeholder="dd//mm/yyyy"
                                    formControlName="billEndDate"
                                    [(ngModel)]="billDetailsForm.billDetails.electricityBillEndDate"
                                    [value]="billDetailsForm.billDetails.electricityBillEndDate"
                                    (input)="billDetailsForm.toDate = billDetailsForm.validateInput(billDetailsForm.toDate, dpToDate.value)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
                                        type="button" tabindex="4">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <bfv-messages class="date-error"></bfv-messages>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding" role="group" *ngIf="billDetailsForm.billDetails.energyType != 'Gas'">
                    <div class="col-md-4 custom-margin">
                        <label id="lbl_elec_step1_header" class="h3 bill-date-group control-label">
                            Your average daily usage in kWh:
                            <i aria-label="The average daily usage can be found near the graph on your bill."
                                class="material-icons non-focus" tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="The average daily usage can be found near the graph on your bill."></i>
                        </label>
                    </div>
                    <div class="col-md-3 form-group daily-usage">
                        <label class="hidden-label">
                            Your average daily usage in kWh
                        </label>
                        <input type="text" maxlength="5" title="Electricity usage"
                            aria-label="Electricity usage" tabindex="3" formControlName="elecUsage" value=""
                            (keydown)="billDetailsForm.selectButton($event, 'saveData')" id="elecUsage"
                            [(ngModel)]="billDetailsForm.billDetails.elecUsage" class="form-control" />
                    </div>
                    <div class="col-md-3 mertic-label daily-usage">
                        kWh
                    </div>
                </div>
                <div class="row" *ngIf="billDetailsForm.validateElectricity() == true">
                    <p>
                        <span class="strong">Please note:</span>
                        Offers in Victorian Energy Compare are for consumers with less than 40 MWh of energy consumption
                        per year. Based on the information provided you may be consuming more than this and retailer(s)
                        may not be required to honour offers shown to you. If you require further information please
                        contact info.vec@deeca.vic.gov.au.
                    </p>
                </div>

                <div class="row zero-height" role="group">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="input-group">
                                <input name="solarDatepicker" class="form-control" ngbDatepicker
                                    #solarDatePicker="ngbDatepicker" [autoClose]="'outside'"
                                    (dateSelect)="billDetailsForm.onSolarDateSelection($event, solarDatePicker)"
                                    [displayMonths]="(billDetailsForm.isMobile == true) ? 1 : 2"
                                    [dayTemplate]="solarTemplate" outsideDays="hidden"
                                    [startDate]="billDetailsForm.solarFromDate!" [maxDate]="billDetailsForm.maxDate"
                                    [minDate]="billDetailsForm.minDate" style="opacity: 0;">
                                <ng-template #solarTemplate let-date let-focused="focused">
                                    <span class="custom-day"
                                        [ngClass]="{'custom-day-disabled' : billDetailsForm.isDisabled(date)}"
                                        [class.focused]="focused" [class.range]="billDetailsForm.solarIsRange(date)"
                                        [class.faded]="billDetailsForm.solarIsHovered(date) || billDetailsForm.solarIsInside(date)"
                                        (mouseenter)="billDetailsForm.solarHoveredDate = date"
                                        (mouseleave)="billDetailsForm.solarHoveredDate = null">
                                        {{ date . day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding page-divider-up"
                    *ngIf="billDetailsForm.billDetails.hasSolar == '1' && billDetailsForm.showSolarForms == true">
                    <div class="col-md-4 custom-margin">
                        <label class="h3 bill-date-group control-label">
                            Solar Generation statement period:
                            <i aria-label="The solar generation period is the period of time that your solar panels generate power."
                                class="material-icons non-focus" tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="The solar generation period is the period of time that your solar panels generate power."></i>
                        </label>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <label class="control-label" for="elec-start-date">START DATE:</label>
                            <div class="input-group form-group">
                                <label class="hidden-label">Start date </label>
                                <input #dpSolarFromDate id="solarStartDate" tabindex="6" class="form-control"
                                    placeholder="dd//mm/yyyy" formControlName="solarStartDate"
                                    [(ngModel)]="billDetailsForm.billDetails.solarStartDate"
                                    [value]="billDetailsForm.billDetails.solarStartDate"
                                    (input)="billDetailsForm.solarFromDate = billDetailsForm.validateSolarInput(billDetailsForm.solarFromDate, dpSolarFromDate.value)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar"
                                        (click)="solarDatePicker.toggle()" type="button" tabindex="7">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <bfv-messages class="date-error"></bfv-messages>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <label class="control-label" for="elec-end-date">END DATE:</label>
                            <div class="input-group form-group">
                                <label class="hidden-label">End date</label>
                                <input #dpSolarToDate tabindex="8" class="form-control" placeholder="dd//mm/yyyy"
                                    formControlName="solarEndDate"
                                    [(ngModel)]="billDetailsForm.billDetails.solarEndDate"
                                    [value]="billDetailsForm.billDetails.solarEndDate"
                                    (input)="billDetailsForm.solarToDate = billDetailsForm.validateSolarInput(billDetailsForm.solarToDate, dpSolarToDate.value)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar"
                                        (click)="solarDatePicker.toggle()" type="button" tabindex="9">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <bfv-messages class="date-error"></bfv-messages>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding" role="group"
                    *ngIf="billDetailsForm.billDetails.hasSolar == '1' && billDetailsForm.showSolarForms == true">
                    <div class="col-md-4 custom-margin">
                        <label id="lbl_elec_step1_header" class="h3 bill-date-group control-label">
                            Total solar export for the period in kWh:
                            <i aria-label="The 'total solar export for the period' is the total amount of electricity exported from your panels during the solar generation statement period. It is the number of kWh your solar panels fed back into the electricity grid."
                                class="material-icons non-focus" tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="The 'total solar export for the period' is the total amount of electricity exported from your panels during the solar generation statement period. It is the number of kWh your solar panels fed back into the electricity grid."></i>
                        </label>
                    </div>
                    <div class="col-md-3 form-group daily-usage">
                        <label class="hidden-label">
                            Solar generation
                        </label>
                        <input type="text" maxlength="5" title="Solar generation" aria-label="Solar generation"
                            tabindex="10" formControlName="solarGeneration"
                            (keydown)="billDetailsForm.selectButton($event, 'saveData')" value=""
                            id="solarGeneration" [(ngModel)]="billDetailsForm.billDetails.solarGeneration"
                            class="form-control" />
                    </div>
                    <div class="col-md-3 mertic-label daily-usage">
                        kWh
                    </div>
                </div>

                <div class="row custom-padding page-divider-up" role="group"
                    *ngIf="billDetailsForm.billDetails.energyType != 'Gas'">
                    <div class="col-lg-4 vertical-align">
                        <label class="h3">
                            Your current electricity tariff:
                        </label>
                    </div>
                    <div class="row radio-inline tariff col-sm-8 vertical-align">
                        <div class="col-lg-4 tariff-btn-padding">
                            <ng-template #tipContent1>Single Rate/Flat plans charge a single rate for usage, regardless
                                of the time of day.</ng-template>
                            <input formControlName="tariffType" type="radio" id="single_rate" value="single_rate"
                                (click)="billDetailsForm.selectButton($event, 'single_rate')"
                                [(ngModel)]="billDetailsForm.billDetails.tariffType">
                            <label for="single_rate"
                                class="ie-padding custom-btn-large custom-btn-large-text col-md-12">Single Rate/Flat
                                <i class="material-icons" [triggers]="utility.toolTipForMobile()"
                                    [ngbTooltip]="tipContent1">info</i>
                            </label>
                        </div>
                        <div class="col-lg-4 tariff-btn-padding">
                            <ng-template #tipContent2>Time of Use plans charge different prices for usage at different
                                times of the day. The prices will typically be categorised as peak and
                                off-peak.</ng-template>
                            <input formControlName="tariffType" type="radio" id="tou" value="tou"
                                (click)="billDetailsForm.selectButton($event, 'tou')"
                                [(ngModel)]="billDetailsForm.billDetails.tariffType">
                            <label for="tou"
                                class="ie-padding custom-btn-large custom-btn-large-text col-md-12">Time of Use
                                <i class="material-icons" [triggers]="utility.toolTipForMobile()"
                                    [ngbTooltip]="tipContent2">info</i>
                            </label>
                        </div>
                        <div class="col-lg-4 tariff-btn-padding">
                            <input formControlName="tariffType" type="radio" id="unsure" value="I don't know"
                                (click)="billDetailsForm.selectButton($event, 'unsure')"
                                [(ngModel)]="billDetailsForm.billDetails.tariffType">
                            <label for="unsure" class="custom-btn-large custom-btn-large-text col-md-12">I don't
                                know</label>
                        </div>
                        <div class="tariff-validation">
                            <div *ngIf="billDetailsForm.billDetailsForm.controls['tariffType'].errors && billDetailsForm.billDetailsForm.controls['tariffType'].touched"
                                class="text-danger-questionnaire">
                                Select at least one option
                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </div>


        <div *ngIf="billDetailsForm.billDetails.energyType == 'Gas' && billDetailsForm.showGasForms == true">
            <div class="alert alert-danger" *ngIf="billDetailsForm.gasErrorMessage != ''"
                [innerHTML]="billDetailsForm.gasErrorMessage">
            </div>
            <form method="post" id="rateForm" [formGroup]="billDetailsForm.billDetailsForm" class="col-md-12"
                onsubmit="return false" (keydown.enter)="$event.preventDefault()">
                <div *ngFor="let form of billDetailsForm.billForms; let i = index;">
                    <div class="row zero-height" role="group" id="gasForm{{ i + 1 }}">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <input name="datepicker{{ i + 1 }}" class="form-control" ngbDatepicker
                                        #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                        (dateSelect)="billDetailsForm.onGasDateSelection($event, i, datepicker)"
                                        [displayMonths]="(billDetailsForm.isMobile == true) ? 1 : 2" [dayTemplate]="t"
                                        outsideDays="hidden" [startDate]="billDetailsForm.gasFromDate[i]!"
                                        [maxDate]="billDetailsForm.maxDate" [minDate]="billDetailsForm.minDate"
                                        style="opacity: 0;">
                                    <ng-template #t let-date let-focused="focused">
                                        <span class="custom-day"
                                            [ngClass]="{'custom-day-disabled' : billDetailsForm.isDisabled(date)}"
                                            [class.focused]="focused"
                                            [class.range]="billDetailsForm.gasIsRange(date, i)"
                                            [class.faded]="billDetailsForm.gasIsHovered(date, i) || billDetailsForm.gasIsInside(date, i)"
                                            (mouseenter)="billDetailsForm.hoveredDate = date"
                                            (mouseleave)="billDetailsForm.hoveredDate = null">
                                            {{ date . day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row custom-padding page-divider-up">
                        <div class="row col-md-12" *ngIf="i > 0">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                                <button type="button" class="btn btn-danger del-btn"
                                    (click)="billDetailsForm.removeGasForm(i)">Remove</button>
                            </div>
                            <br /><br /><br />
                        </div>
                        <div class="col-md-5 custom-margin">
                            <label class="h3 bill-date-group control-label">
                                Your gas bill period:
                                <i aria-label="The bill period is the time from one bill to the next bill. You pay for the gas you used during the bill period. Your bill might say something like ’28 Jul 2015 to 21 Sept 2015 (56 days)’. The bill period is not related to the invoice date or processing data shown on the bill."
                                    class="material-icons non-focus" tabindex="-1"
                                    [triggers]="utility.toolTipForMobile()"
                                    ngbTooltip="The bill period is the time from one bill to the next bill. You pay for the gas you used during the bill period. Your bill might say something like ’28 Jul 2015 to 21 Sept 2015 (56 days)’. The bill period is not related to the invoice date or processing data shown on the bill."></i>
                            </label>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label class="control-label" for="elec-start-date">START DATE:</label>
                                <div class="input-group form-group">
                                    <label class="hidden-label">Start date</label>
                                    <input #dpFromDate id="startDate{{ i + 1 }}" tabindex="1"
                                        class="form-control" placeholder="dd//mm/yyyy"
                                        formControlName="gasBillStartDate{{ i }}"
                                        [(ngModel)]="billDetailsForm.billForms[i].gasBillStartDate"
                                        [value]="billDetailsForm.billForms[i].gasBillStartDate"
                                        (input)="billDetailsForm.gasFromDate[i] = billDetailsForm.validateGasInput(billDetailsForm.billForms[i].gasBillStartDate, dpFromDate.value)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar"
                                            (click)="datepicker.toggle()" type="button" tabindex="2">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <bfv-messages class="date-error"></bfv-messages>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label class="control-label" for="elec-end-date">END DATE:</label>
                                <div class="input-group form-group">
                                    <label class="hidden-label">End date</label>
                                    <input #dpToDate id="endDate{{ i + 1 }}" tabindex="3"
                                        class="form-control" placeholder="dd//mm/yyyy"
                                        formControlName="gasBillEndDate{{ i }}"
                                        [(ngModel)]="billDetailsForm.billForms[i].gasBillEndDate"
                                        [value]="billDetailsForm.billForms[i].gasBillEndDate"
                                        (input)="billDetailsForm.gasToDate[i] = billDetailsForm.validateGasInput(billDetailsForm.billForms[i].gasBillEndDate, dpToDate.value)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar"
                                            (click)="datepicker.toggle()" type="button" tabindex="4">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <bfv-messages class="date-error"></bfv-messages>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row custom-padding" role="group">
                        <div class="col-md-5 custom-margin">
                            <label id="lbl_elec_step1_header" class="h3 bill-date-group control-label">
                                Your average daily usage in MJ:
                                <i aria-label="The average daily usage can be found near the graph on your bill."
                                    class="material-icons non-focus" tabindex="-1"
                                    [triggers]="utility.toolTipForMobile()"
                                    ngbTooltip="The average daily usage can be found near the graph on your bill."></i>
                            </label>
                        </div>
                        <div class="col-md-3 form-group daily-usage">
                            <label class="hidden-label">
                                Your average daily usage in MJ
                            </label>
                            <input type="text" maxlength="6" title="Gas usage" aria-label="Gas usage"
                                tabindex="4" value=""
                                (keydown)="billDetailsForm.selectButton($event, 'saveData')"
                                id="gasUsage{{ i + 1 }}" formControlName="gasUsage{{ i }}"
                                [(ngModel)]="billDetailsForm.billForms[i].gasUsage"
                                [ngClass]="(billDetailsForm.gasUsageErrors[i] == true) ? 'is-invalid' : ''"
                                class="form-control" />
                        </div>
                        <div class="col-md-3 mertic-label daily-usage">
                            MJ
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="billDetailsForm.gasUsageErrors[i] == true && billDetailsForm.gasUsageErrors[i] != undefined">
                        <div class="alert alert-danger maximum-usage" role="alert">
                            <i class="material-icons button-icons small-tooltip">warning</i>
                            Based on the bill details that you have provided, your average daily usage exceeds the
                            maximum amount.
                            Please enter a value of less than {{ billDetailsForm . gasMaxConsumption }}MJ
                        </div>
                        <p>
                            <span class="strong">Please note:</span>
                            Offers in Victorian Energy Compare are for consumers with less than
                            {{ billDetailsForm . dailyMaxConsumption }} GJ of energy consumption
                            per year. Based on the information provided you may be consuming more than this and
                            retailer(s)
                            may not be required to honour offers shown to you. If you require further information please
                            contact info.vec@deeca.vic.gov.au.
                        </p>
                    </div>
                </div>
            </form>
            <div class="row page-divider-up">
                <div class="col-md-5">
                </div>
                <div class="col-md-7">
                    <div class="row add-bill">
                        <div class="col-md-3">
                            <button type="button" class="custom-btn-text-sm add-bill-btn"
                                (click)="billDetailsForm.addNewForm()">Add Bill</button>
                        </div>
                        <div class="col-md-9 no-padding">
                          If you have another bill, you can add details to improve the accuracy of the results.
                          Bills need to be consecutive.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row form-group page-divider-up">
            <div class="col-md-5"></div>
            <div class="col-md-7">
                <button type="submit"
                    class="custom-button profile-btn custom-btn-large custom-btn-large-text col-lg-12" tabindex="5"
                    (keydown)="billDetailsForm.selectButton($event, 'saveData')" (click)="billDetailsForm.saveData()">
                    Next</button>
            </div>
        </div>

    </div>
</div>
<br /><br />
