<div class="modal-header">
    <div class="modal-icons col-lg-12">
        <h3>{{ headerTitle }}</h3>
        <button class="hidden-btn"></button>
        <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
            <i class="fa fa-times" aria-hidden="true"></i> close
        </button>
    </div>
</div>
<div class="modal-body">
    <form name="emailForm" [formGroup]="emailForm" *ngIf="emailSubmitted != true">
        <h5 id="lbl_email">Enter your email address:</h5>
        <div class="form-group">
            <label class="hidden-label">
                email address
            </label>
            <input type="email" class="form-control custom-input-text custom-large" 
                   formControlName="email" [(ngModel)]="email" required auto-focus autofocus 
                   (keydown)="selectButton($event)" /> 
            <bfv-messages></bfv-messages>
        </div>
    </form>
    <div *ngIf="emailSubmitted == true">
        <p class="alert alert-success" *ngIf="emailStatus == 'success'">
            Your requested information will be sent to you as an attached PDF.<br /><br />
            If this email does not appear in your inbox within 5 minutes, please check your junk mail.
        </p>
        <p class="alert alert-danger" *ngIf="emailStatus == 'error'" [innerHTML]="errorMessage">
        </p>
    </div>
</div>

<div class="modal-footer" *ngIf="emailSubmitted != true">
    <br />
    <button class="btn btn-primary custom-button" [disabled]="formIsvalid()" (click)="sendMail()">Send Email</button>
</div>