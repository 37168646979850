<div class="modal-header">
    <h3>
        I have been at my address for less than 12 months
    </h3>
    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        Victorian Energy Compare needs at least 12 months of consumption data to provide a price comparison for small business users.
    </p>
    <br />
</div>
<div class="modal-footer">
    <button title="Close" aria-label="Close" type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Close</button>
</div>