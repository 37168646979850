import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-standalone',
  templateUrl: './standalone.component.html',
  styleUrls: ['./standalone.component.css']
})
export class StandaloneComponent implements OnInit {

  constructor(public router : Router) { }

  ngOnInit() {
    sessionStorage.setItem("selectedEnergyType", 'Solar');
    this.router.navigate(['/']);
  }

}
