<div class="container" [ngClass]="(infoModalShown == true) ? 'offers-hidden' : ''">
    <div class="back-navigation-link">
        <span title="back" aria-label="back" class="anchor-style" (click)="back()"><i class="fa fa-chevron-left"></i> return to previous page</span>
    </div>
    <br />

    <app-offer-banner-panel
        [hasConcession]="hasConcession"
        [averageDailyConsumption]="averageDailyConsumption"
        [loopBackAverageDailyConsumption]="loopBackAverageDailyConsumption"
        [energyType]="energyType"
        [loopBackEnergyType]="loopBackEnergyType"
        [loopBackIsEmpty]="loopBackIsEmpty"
        [bill]="bill">
    </app-offer-banner-panel>

    <br />
    <span id="top"></span>
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-print-header" *ngIf="secBranding == false">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_940_left.png">
        <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_1140_vec_logo.png">
        <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_1140_vic_logo.png">
    </div>
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-print-header" *ngIf="secBranding == true">
        <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png">
    </div>

    <div class="row page-divider" id="compare-details">
        <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-medium-print-header" [ngClass]="secBranding == true ? 'vec-sec' : ''">
            <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png" *ngIf="secBranding == true">
            <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_720_left.png" *ngIf="secBranding == false">
            <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_720_vic_logo.png" *ngIf="secBranding == false">
            <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_600_vec_logo.png" *ngIf="secBranding == false">
        </div>

        <div *ngFor="let offerInfo of checkedOffers; let ctr = index;" class="content-separator col-lg-4 offer-details">
            <!-- Bundle Pricing : START -->
            <div class="compare-bundle-price">
                <p class="col-md-12">
                    <span class="HelveticaNeueLTStd-Regular">Bundle total price</span>
                </p>
                <p class="col-md-12">

                    <span *ngIf="dataService.getSorting() == true">
                        <span class="price-sign">$</span>
                        <span class="currency">
                            {{ Math.abs((offerInfo.totalConditionalPrice * 1) + (offerInfo.gasOffer.totalConditionalPrice * 1)) | number }}
                        </span>
                        <sup *ngIf="((offerInfo.totalConditionalPrice * 1) + (offerInfo.gasOffer.totalConditionalPrice * 1)) < 0" class="strong">
                            CR
                        </sup>
                    </span>
                    <span *ngIf="dataService.getSorting() == false">
                        <span class="price-sign">$</span>
                        <span class="currency">
                            {{ Math.abs((offerInfo.totalBasePrice * 1) + (offerInfo.gasOffer.totalBasePrice * 1)) | number }}
                        </span>
                        <sup *ngIf="((offerInfo.totalBasePrice) + (offerInfo.gasOffer.totalBasePrice * 1)) < 0" class="strong">
                            CR
                        </sup>
                    </span>
                    &nbsp;
                    <sub>
                        / Est. yearly price
                    </sub>
                </p>
                <p *ngIf="dataService.getSorting() == true">
                    Without conditional discounts
                    <span class="strong total-base-price">
                        {{ Math.abs((offerInfo.totalBasePrice * 1) + (offerInfo.gasOffer.totalBasePrice * 1)) | currency:'USD':'symbol':'1.0-0' }}
                    </span>
                    <span *ngIf="((offerInfo.totalBasePrice * 1) + (offerInfo.gasOffer.totalBasePrice * 1)) < 0" class="strong">
                        CR
                    </span>
                </p>
                <p *ngIf="dataService.getSorting() == false">
                    With conditional discounts
                    <span class="total-base-price strong">
                        {{ Math.abs((offerInfo.totalConditionalPrice * 1) + (offerInfo.gasOffer.totalConditionalPrice * 1)) | currency:'USD':'symbol':'1.0-0' }}
                    </span>
                    <span *ngIf="((offerInfo.totalConditionalPrice * 1) + (offerInfo.gasOffer.totalConditionalPrice * 1)) < 0" class="strong">
                        CR
                    </span>
                </p>
            </div>
            <br />
            <ul class="nav nav-tabs">
                <li [ngClass]="{ 'active' : (electricityIsVisible == true) }">
                    <a (click)="toggleElectricityOffer(true)">
                        <i aria-hidden="true" class="material-icons button-icons electricity-icon">flash_on</i>
                        <span class="NimbusSan">ELEC</span>
                    </a>
                </li>
                <li [ngClass]="{ 'active' : (electricityIsVisible == false) }">
                    <a (click)="toggleElectricityOffer(false)">
                        <i aria-hidden="true" class="material-icons button-icons gas-icon">whatshot</i>
                        <span class="NimbusSan">GAS</span>
                    </a>
                </li>
            </ul>
            <div class="offer-info-container">
                <!--electricity-->
                <div *ngIf="electricityIsVisible == true">

                    <div class="section-separator-top offer-name-div col-lg-12">
                      <p class="col-md-12">
                          <span class="strong">
                              {{ offerInfo.offerName }}
                          </span>
                      </p>
                    </div>
                    <div class="row cmp-solar-credit-display section-separator-bottom" *ngIf="offerInfo.solarCredit > 0">
                        <p class="col-md-12">
                            <span class="strong">
                                Estimate includes solar credit of {{ offerInfo.solarCredit | currency : 'USD' : 'symbol' : '1.0-2' }}
                            </span>
                        </p>
                    </div>

                    <div class="hidden-label">{{ emailSecurityKey }}</div>
                    <div class="row">
                        <p class="col-md-12">
                            <span class="price-sign">$</span>
                            <span *ngIf="dataService.getSorting() == true">
                                <span class="currency">{{ Math.abs(offerInfo.totalConditionalPrice) | number : '1.0-0' }}</span>
                                <sup *ngIf="offerInfo.totalConditionalPrice < 0">CR</sup>
                            </span>

                            <span *ngIf="dataService.getSorting() == false">
                                <span class="currency">{{ Math.abs(offerInfo.totalBasePrice) | number : '1.0-0' }}</span>
                                <sup *ngIf="offerInfo.totalBasePrice < 0">CR</sup>
                            </span>

                            <sub class="dual-per-year">/ Est. yearly price</sub>
                        </p>
                        <div
                            *ngIf="offerInfo.includesDemandTariff != 0 && fileUpload == false && getMyData == false"
                            class="col-md-12 includes-demand-tariff includes-tariff-notice full-width center-text"
                            tooltipClass="includes-demand-tariff-tip"
                            placement="top"
                            [triggers]="utility.toolTipForMobile()"
                            ngbTooltip="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you."
                            aria-label="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.">
                            <br />
                            <span class="plus">+</span>
                            demand charge
                        </div>

                    </div>

                    <div class="row" class="line-separator">

                        <p class="col-md-12" *ngIf="dataService.getSorting() == true">
                            Without conditional discounts
                            <span class="strong">
                                ${{ Math.abs(offerInfo.totalBasePrice) | number : '1.0-0' }}
                                <span *ngIf="offerInfo.totalBasePrice < 0">CR</span>
                            </span>
                        </p>

                        <p class="col-md-12" *ngIf="dataService.getSorting() == false">
                            With conditional discounts
                            <span class="strong">
                                ${{ Math.abs(offerInfo.totalConditionalPrice) | number : '1.0-0' }}
                                <span *ngIf="offerInfo.totalConditionalPrice < 0">CR</span>
                            </span>
                        </p>
                    </div>

                    <div class="section-separator-bottom line-separator" *ngIf="offerInfo.isInnovativeOffer">
                        <br />
                        <i class="material-icons contingentgasoffer" tabindex="0"
                        popover="This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information."
                        aria-label="This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information.">
                        &#xE002;</i>
                        This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information.
                    </div>

                    <p class="section-separator-top">
                        <span class="strong">
                            <br />
                            Offer Specifics
                        </span>
                    </p>

                    <div class="line-separator section-separator-bottom offer-specific-details">
                        <p class="col-md-12">
                            Cooling off period:
                            {{ offerInfo.coolOffPeriod }}
                        </p>

                        <p class="col-md-12">
                            GreenPower {{ offerInfo.greenPower }}%
                        </p>

                        <p class="col-md-12">
                            Rate type: {{ offerInfo.tariffType }}
                        </p>

                        <p class="col-md-12">
                            Offer type: {{ offerInfo.offerType }}
                        </p>

                        <p class="col-md-12">
                            Contract term:
                            {{ (offerInfo.contractLengthCount > 1) ? "Multiple" : offerInfo.contractLength }}
                        </p>
                        <br>
                        <!--
                        <p class="col-md-12">
                            Exit Fee:
                            <span class="strong">
                                {{ (offerInfo.exitFeeCount > 1) ? "Multiple" : "$" }}{{ offerInfo.exitFee | number : '1.2-2' }}
                            </span>
                        </p>
                        -->
                    </div>

                    <div class="compare-retailer-logo">
                      <img
                          [attr.title]="offerInfo.retailerName"
                          [attr.aria-label]="offerInfo.retailerName"
                          src='{{ appURL }}/assets/{{ offerInfo.retailerImageUrl }}'
                          (click)="viewRetailer(offerInfo.retailerId)">
                    </div>

                    <div class="row retailer-section">
                        <p class="col-md-12">
                            <span class="sub-title">
                                {{ offerInfo.retailerName }}
                            </span>
                        </p>
                        <p class="col-md-12">
                            ID: {{ offerInfo.offerId }}
                        </p>
                    </div>

                    <div class="row">
                        <p class="col-md-12">
                            <a
                                tabindex="1"
                                title="Retailer URL"
                                aria-label="Retailer URL"
                                href="{{ (offerInfo.retailerUrl.indexOf('http') == -1) ? 'http://' + offerInfo.retailerUrl : offerInfo.retailerUrl }}"
                                target="_blank"
                                class="retailer-link with-focus">
                                {{ (offerInfo.retailerUrl.indexOf('http') == -1) ? 'http://' + offerInfo.retailerUrl : offerInfo.retailerUrl }}
                                <i class="fa fa-external-link"></i>
                            </a>
                        </p>
                    </div>

                    <div class="line-separator col-md-12">
                        <p>
                            <i class="fa fa-phone" aria-hidden="true"></i>
                            {{ offerInfo.retailerPhone }}
                        </p>
                    </div>

                    <div class="tariff-details compare-buttons">
                        <button
                            tabindex="2"
                            class="view-offer-btn col-lg-12"
                            (click)="viewOffer(offerInfo.offerIndex)">
                            View this offer
                        </button>
                        <button
                            tabindex="3"
                            class="what-next-button what-next-button-text col-lg-12"
                            (click)="contactRetailer(offerInfo.offerIndex)">
                            Contact
                        </button>
                        <button tabindex="4" class="btn view-details-btn col-lg-12" (click)="toggleShowRates()">
                            {{ (showRates == true) ? 'Hide ' : 'View ' }}
                            rates and details
                            <i class="fa fa-chevron-up" *ngIf="showRates == true"></i>
                            <i class="fa fa-chevron-down" *ngIf="showRates == false"></i>
                        </button>
                    </div>
                    <div *ngIf="showRates == true" class="focus-padding">
                        <div class="col-md-12 offer-rate-header">
                            <br />
                            <span class="sub-title">
                                Offer rate and details
                            </span>
                            <br/><span>{{ offerInfo.timeDefinition }}</span>
                        </div>
                        <!-- season start-->
                        <div *ngFor="let index=index; let season of offerInfo.tariffDetails">
                            <div class="offer-rate-header">
                                <br />
                                <p>
                                    {{ season.season_name }}
                                </p>
                                <p class="read-only" *ngIf="season.tariff_applicable_from != '' && season.tariff_applicable_to != ''">
                                    {{ utility.convertDate(season.tariff_applicable_from) + " - " + utility.convertDate(season.tariff_applicable_to) }}
                                </p>
                                <!--
                                <p class="read-only" *ngIf="season.tariff_applicable_from == '' && season.tariff_applicable_to == ''">
                                    1 January - 31 December
                                </p>
                                -->
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                    <p class="read-only tariff-heading">
                                        {{ (offerInfo.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                                    </p>
                                </div>
                            </div>

                            <div class="row supply-charge">
                                <div class="col-md-6 col-sm-6 col-xs-6 text-left">
                                    Supply charges
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 value">
                                    <p>
                                        <span *ngIf="offerInfo.includeGSTRates == true">
                                            {{ ((season.tariff_daily_charge * 1) + (season.tariff_daily_charge * (offerInfo.GSTPercent / 100))) | number : '1.2-2' }}
                                        </span>
                                        <span *ngIf="offerInfo.includeGSTRates == false">
                                            {{ season.tariff_daily_charge | number : '1.2-2' }}
                                        </span>
                                        &cent;/day
                                    </p>
                                </div>
                            </div>

                            <div *ngFor="let tariff of season.TARIFFS">
                                <div class="row rate-charge" *ngIf="season.TARIFFS.length == 1">
                                    <div class="col-md-12 text-left">
                                        Flat
                                    </div>
                                </div>
                                <div class="row rate-charge" *ngIf="season.TARIFFS.length > 1">
                                    <div class="col-md-12 text-left">
                                        {{ (tariff.tariff_type == '' || tariff.tariff_type == null) ? tariff.tariff_name : tariff.tariff_type }}
                                    </div>
                                </div>

                                <!--time bands-->
                                <div class="row rate-separator no-border" *ngIf="season.TARIFFS.length > 1">
                                    <div class="col-md-12 mid-padding text-left" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                                    </div>
                                </div>

                                <!--rates start-->
                                <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                                    <div class="row rate-charge rate-separator">
                                        <div class="col-md-6 mid-padding season-rate">
                                            <div *ngIf="tariff.RATES.length > 1">
                                                <span *ngIf="rate.desc != 'Balance'">
                                                    {{ rate.desc }}
                                                    {{ rate.desc_rate_block_consumption | number : '1.0-2' }}
                                                    {{ energyType == 'Gas' ? 'MJ' : 'kWh' }} per {{ season.tariff_cons_prd }}
                                                </span>
                                                <span *ngIf="rate.desc == 'Balance'">
                                                    {{ rate.desc }}
                                                </span>
                                            </div>
                                            <div *ngIf="tariff.RATES.length == 1">
                                                All consumption
                                            </div>
                                        </div>
                                        <div class="col-md-6 mid-padding value strong">
                                            <span>
                                                <span *ngIf="offerInfo.includeGSTRates == true">
                                                    {{ ((rate.rate_block * 1) + (rate.rate_block * (offerInfo.GSTPercent / 100))) | number : '1.2-2' }}
                                                </span>
                                                <span *ngIf="offerInfo.includeGSTRates == false">
                                                    {{ rate.rate_block | number : '1.2-2' }}
                                                </span>
                                                {{ energyType == 'Gas' ? '¢/MJ' : '¢/kWh' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <!--rates end-->

                                <!--demand start-->
                                <div *ngIf="season.DEMAND !== undefined" class="demand">
                                    <br />
                                    <div class="row">
                                        <div class="col-md-12">
                                            {{ (fileUpload == true || getMyData == true) ? 'Demand Charge' : 'Demand Charge (not included in estimate)'}}
                                        </div>
                                    </div>
                                    <!--demand days-->
                                    <div class="row" *ngIf="season.DEMAND.tariff_weekdays == true">
                                        <div class="col-md-12 season-rate">
                                            Weekdays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="season.DEMAND.tariff_sat == true && season.DEMAND.tariff_sun == true">
                                        <div class="col-md-12 season-rate">
                                            Weekends {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="season.DEMAND.tariff_sat == true && season.DEMAND.tariff_sun == false">
                                        <div class="col-md-12 season-rate">
                                            Saturdays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="season.DEMAND.tariff_sat == false && season.DEMAND.tariff_sun == true">
                                        <div class="col-md-12 season-rate">
                                            Sundays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                        </div>
                                    </div>
                                    <!--demand days-->

                                    <div class="row bottom-margin">
                                        <div class="col-md-6 season-rate">
                                            Charge
                                        </div>
                                        <div class="col-md-6 value strong demmand-value">
                                            {{ season.DEMAND.demand_charge + (season.DEMAND.demand_charge * (offerInfo.GSTPercent / 100)) | number : '1.2-2' }}
                                            {{ energyType == 'Gas' ? '¢/MJ' : '¢/kWh' }} per
                                            {{ season.DEMAND.demand_charge_period | lowercase }}
                                        </div>
                                    </div>
                                    <div class="row rate-line-border rate-line-border-top demand-padding">
                                        <div class="col-md-12 no-padding">
                                            {{ (fileUpload == true || getMyData == true) ? 'You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.' : 'This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.' }}
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <!--demand end-->
                            </div>
                        </div>
                        <!-- season end -->
                        <!--controll load start-->
                        <div *ngIf="offerInfo.controlledLoadRate > 0">
                            <div class="row">
                                <div class="col-md-12 offer-rate-header">
                                    Controlled Load
                                    <br />
                                    1 January - 31 December
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                    <p class="read-only tariff-heading">
                                        {{ (offerInfo.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                                    </p>
                                </div>
                            </div>
                            <div class="row bottom-margin rate-separator rate-charge">
                                <div class="col-md-6 season-rate no-padding">
                                    All consumption
                                </div>
                                <div class="col-md-6 value strong no-padding">
                                    <span *ngIf="offerInfo.includeGSTRates == true">
                                        {{ ((offerInfo.controlledLoadRate * 1) + (offerInfo.controlledLoadRate * (offerInfo.GSTPercent / 100))) | number : '1.2-2' }}
                                    </span>
                                    <span *ngIf="offerInfo.includeGSTRates == false">
                                        {{ offerInfo.controlledLoadRate | number : '1.2-2' }}
                                    </span>
                                    ¢/kWh
                                </div>
                            </div>
                            <br /><br />
                        </div>
                        <!--controll load end-->
                        <!-- Green Power -->
                        <div *ngIf="showRates == true">
                            <br />
                            <div class="viewRateDetails">
                                <div *ngIf="offerInfo.greenPowerDisplay != false">
                                    <div class="row col-md-12 offer-rate-header">
                                        Green power
                                    </div>
                                    <div class="read-only no-padding col-md-12" *ngIf="offerInfo.intrinsicGreenPowerDisplay != 0">
                                        Annual total includes {{ offerInfo.intrinsicGreenPowerDisplay }}% green power included in the offer rates
                                        <br />
                                    </div>
                                    <div class="read-only no-padding col-md-12" *ngIf="offerInfo.greenPower !== 0">
                                        Annual total includes {{ offerInfo.greenPower }}%
                                        GreenPower option
                                        <br />
                                    </div>

                                    <div class="row green-power-row bottom-margin rate-separator rate-charge no-padding" *ngIf="offerInfo.greenPower !== 0">
                                        <div class="col-md-6 col-sm-6 col-xs-6 season-rate no-padding">
                                            Charge
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6 solar-value strong no-padding">
                                            {{ offerInfo.greenpowerAmount * 100 | number : '1.2-2' }}
                                            <span *ngIf="offerInfo.greenpowerChargeType == 'Amount per unit of consumption'" class="no-padding">
                                                &cent;/kWh
                                            </span>
                                            <span *ngIf="offerInfo.greenpowerChargeType == 'Weekly charge'" class="no-padding">
                                                &cent;/Week
                                            </span>
                                            <span *ngIf="offerInfo.greenpowerChargeType == 'Daily charge'" class="no-padding">
                                                &cent;/Day
                                            </span>
                                            <span *ngIf="offerInfo.greenpowerChargeType == 'Percentage of consumption'" class="no-padding">
                                                &cent;/% of your total bill amount
                                            </span>
                                            <span *ngIf="offerInfo.greenpowerChargeType == 'Percentage of bill amount'" class="no-padding">
                                                &cent;/% of your consumption cost amount
                                            </span>
                                        </div>
                                    </div>

                                    <br /><br />
                                </div>
                            </div>
                        </div>
                        <!--solar -->
                      <!-- solar -->
                      <div *ngIf="offerInfo.solar.length > 0">
                        <div *ngFor="let solar of offerInfo.solar">

                          <!-- Current and Premium FiT -->
                          <div *ngIf="solar.rate">
                            <div class="col-md-12 offer-rate-header">
                              {{ solar.feed_in_tariff }}
                              <br />
                              1 January - 31 December
                            </div>
                            <div class="row bottom-margin rate-separator rate-charge">
                              <div class="col-md-6 season-rate no-padding">
                                All export
                              </div>
                              <div class="col-md-6 value strong no-padding">
                                {{ solar.rate | number : '1.2-2' }} ¢/kWh
                              </div>
                            </div>
                            <br /><br />
                          </div>

                          <!-- Solar variable FiT: Option 1 & 2 -->
                          <div *ngIf="solar.name === 'Variable FiT - Option 1' || solar.name === 'Variable FiT - Option 2'">
                            <div class="col-md-12 offer-rate-header">
                              {{ solar.name }}
                              <br />
                            </div>
                            <div *ngFor="let tariff of solar.TARIFFS">
                              <br />
                              {{tariff.tariff_type}}

                              <!--time bands-->
                              <div class="row rate-separator no-border" *ngIf="tariff.TIME_BANDS.length >= 1">
                                <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                                </div>
                              </div>
                              <div *ngIf="tariff.RATES.length > 1">
                                <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                                  <div class="row rate-charge rate-separator">
                                    <div class="col-md-7 mid-padding season-rate">
                                      <div *ngIf="tariff.RATES.length > 1">
                                        <span *ngIf="rate.desc != 'Balance'">
                                          {{ rate.desc }} {{ rate.desc_rate_block_export
                                          | number : '1.0-2' }} kWh / {{solar.period}}
                                        </span>
                                        <span *ngIf="rate.desc == 'Balance'">
                                          {{ rate.desc }} / {{solar.period}}
                                        </span>
                                      </div>
                                      <div *ngIf="tariff.RATES.length == 1">All export</div>
                                    </div>
                                    <div class="col-md-5 mid-padding value strong">
                                      <span>
                                        {{ rate.rate_block | number : '1.2-2' }} ¢/kWh
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="tariff.RATES.length == 1" class="row bottom-margin rate-separator rate-charge">
                                <div class="col-md-8 season-rate no-padding">
                                  All export
                                </div>
                                <div class="col-md-4 value strong no-padding">
                                  {{ tariff.RATES[0]["rate_block"] | number : '1.2-2' }} ¢/kWh
                                </div>
                              </div>

                            </div>
                            <br /><br />
                          </div>

                          <!-- Solar variable FiT: Custom with tiers -->
                          <div *ngIf="!solar.rate && (solar.feed_in_tariff === 'Current FIT policy' || solar.feed_in_tariff === 'Premium FIT [closed]') ">
                            <div class="col-md-12 offer-rate-header">
                              {{ solar.feed_in_tariff }}
                              <br />
                            </div>
                            <div *ngFor="let tariff of solar.TARIFFS">
                              <br />
                              {{tariff.tariff_type}}

                              <!--time bands-->
                              <div class="row rate-separator no-border" *ngIf="tariff.TIME_BANDS?.length >= 1">
                                <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                                </div>
                              </div>

                              <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                                <div class="row rate-charge rate-separator">
                                  <div class="col-md-7 mid-padding season-rate">
                                    <div *ngIf="tariff.RATES.length > 1">
                                      <span *ngIf="rate.desc != 'Balance'">
                                        {{ rate.desc }} {{ rate.desc_rate_block_export
                                        | number : '1.0-2' }} kWh / {{solar.period}}
                                      </span>
                                      <span *ngIf="rate.desc == 'Balance'">
                                        {{ rate.desc }} / {{solar.period}}
                                      </span>
                                    </div>
                                    <div *ngIf="tariff.RATES.length == 1">All export</div>
                                  </div>
                                  <div class="col-md-5 mid-padding value strong">
                                    <span>
                                      {{ rate.rate_block | number : '1.2-2' }} ¢/kWh
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                </div>

                <!--gas-->
                <div *ngIf="electricityIsVisible == false">

                    <div class="section-separator-top offer-name-div col-lg-12">
                        <p class="col-md-12">
                            <span class="strong">
                                {{ offerInfo.gasOffer.offerName }}
                            </span>
                        </p>
                    </div>

                    <div class="row cmp-solar-credit-display section-separator-bottom" *ngIf="offerInfo.gasOffer.solarCredit > 0">
                        <p class="col-md-12">
                            <span class="strong">
                                Estimate includes solar credit of {{ offerInfo.gasOffer.solarCredit | currency : 'USD' : 'symbol' : '1.0-0' }}
                            </span>
                        </p>
                    </div>

                    <div class="hidden-label">{{ emailSecurityKey }}</div>
                    <div class="row">
                        <p class="col-md-12">
                            <span class="price-sign">$</span>
                                <span *ngIf="dataService.getSorting() == true">
                                <span class="currency">{{ Math.abs(offerInfo.gasOffer.totalConditionalPrice) | number : '1.0-0' }}</span>
                                <sup *ngIf="offerInfo.gasOffer.totalConditionalPrice < 0">CR</sup>
                            </span>

                            <span *ngIf="dataService.getSorting() == false">
                                <span class="currency">{{ Math.abs(offerInfo.gasOffer.totalBasePrice) | number : '1.0-0' }}</span>
                                <sup *ngIf="offerInfo.gasOffer.totalBasePrice < 0">CR</sup>
                            </span>

                            <sub class="dual-per-year">/ Est. yearly price</sub>
                        </p>
                    </div>

                    <div class="row" class="line-separator">

                        <p class="col-md-12" *ngIf="dataService.getSorting() == true">
                            Without conditional discounts
                            <span class="strong">
                                ${{ Math.abs(offerInfo.gasOffer.totalBasePrice) | number : '1.0-0' }}
                                <span *ngIf="offerInfo.gasOffer.totalBasePrice < 0">CR</span>
                            </span>
                        </p>

                        <p class="col-md-12" *ngIf="dataService.getSorting() == false">
                            With conditional discounts
                            <span class="strong">
                                ${{ Math.abs(offerInfo.gasOffer.totalConditionalPrice) | number : '1.0-0' }}
                                <span *ngIf="offerInfo.gasOffer.totalConditionalPrice < 0">CR</span>
                            </span>
                        </p>
                    </div>

                    <div class="section-separator-bottom line-separator" *ngIf="offerInfo.gasOffer.isInnovativeOffer == true">
                        <br />
                        <i class="material-icons contingentgasoffer" tabindex="0"
                        popover="This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information."
                        aria-label="This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information.">
                        &#xE002;</i>
                        This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information.
                    </div>

                    <p class="section-separator-top">
                        <span class="strong">
                            <br />
                            Offer Specifics
                        </span>
                    </p>

                    <div class="line-separator section-separator-bottom offer-specific-details">
                        <p class="col-md-12">
                            Cooling off period:
                            {{ offerInfo.gasOffer.coolOffPeriod }}
                        </p>

                        <p class="col-md-12">
                            GreenPower {{ offerInfo.gasOffer.greenPower }}%
                        </p>

                        <p class="col-md-12">
                            Rate type: {{ offerInfo.gasOffer.tariffType }}
                        </p>

                        <p class="col-md-12">
                            Offer type: {{ offerInfo.gasOffer.offerType }}
                        </p>

                        <p class="col-md-12">
                            Contract term:
                            {{ (offerInfo.gasOffer.contractLengthCount > 1) ? "Multiple" : offerInfo.gasOffer.contractLength }}
                        </p>
                        <!--
                        <p class="col-md-12">
                            Exit Fee:
                            <span class="strong">
                                {{ (offerInfo.gasOffer.exitFeeCount > 1) ? "Multiple" : "$" }}{{ offerInfo.gasOffer.exitFee | number : '1.2-2' }}
                            </span>
                        </p>
                        -->
                    </div>
                    <div class="compare-retailer-logo">
                      <img
                          [attr.title]="offerInfo.gasOffer.retailerName"
                          [attr.aria-label]="offerInfo.gasOffer.retailerName"
                          src='{{ appURL }}/assets/{{ offerInfo.gasOffer.retailerImageUrl }}'
                          (click)="viewRetailer(offerInfo.gasOffer.retailerId)">
                    </div>

                    <div class="row retailer-section">
                        <p class="col-md-12">
                            <span class="sub-title">
                                {{ offerInfo.gasOffer.retailerName }}
                            </span>
                        </p>
                        <p class="col-md-12">
                            ID: {{ offerInfo.gasOffer.offerId }}
                        </p>
                    </div>

                    <div class="row">
                        <p class="col-md-12">
                            <a
                                tabindex="1"
                                title="Retailer URL"
                                aria-label="Retailer URL"
                                href="{{ (offerInfo.gasOffer.retailerUrl.indexOf('http') == -1) ? 'http://' + offerInfo.gasOffer.retailerUrl : offerInfo.gasOffer.retailerUrl }}"
                                target="_blank"
                                class="retailer-link with-focus">
                                {{ (offerInfo.gasOffer.retailerUrl.indexOf('http') == -1) ? 'http://' + offerInfo.gasOffer.retailerUrl : offerInfo.gasOffer.retailerUrl }}
                                <i class="fa fa-external-link"></i>
                            </a>
                        </p>
                    </div>

                    <div class="line-separator section-separator-bottom col-md-12">
                      <p>
                          <i class="fa fa-phone" aria-hidden="true"></i>
                          {{ offerInfo.gasOffer.retailerPhone }}
                      </p>
                    </div>

                    <div class="tariff-details compare-buttons">
                        <button
                            tabindex="2"
                            class="view-offer-btn col-lg-12"
                            (click)="viewGasOffer(offerInfo.offerIndex)">
                            View this offer
                        </button>
                        <button
                            tabindex="3"
                            class="what-next-button what-next-button-text col-lg-12"
                            (click)="contactGasRetailer(offerInfo.offerIndex)">
                            Contact
                        </button>
                        <button tabindex="4" class="btn view-details-btn col-lg-12" (click)="toggleShowRates()">
                            {{ (showRates == true) ? 'Hide ' : 'View ' }}
                            rates and details
                            <i class="fa fa-chevron-up" *ngIf="showRates == true"></i>
                            <i class="fa fa-chevron-down" *ngIf="showRates == false"></i>
                        </button>
                    </div>
                    <div *ngIf="showRates == true" class="focus-padding">
                        <div class="col-md-12 offer-rate-header">
                            <br />
                            <span class="sub-title">
                                Offer rate and details
                            </span>
                            <br/>
                            <span>AEST only</span>
                        </div>
                        <!-- season start-->
                        <div *ngFor="let index=index; let season of offerInfo.gasOffer.tariffDetails">
                            <div class="offer-rate-header">
                                <br />
                                <p>
                                    {{ season.season_name }}
                                </p>
                                <p class="read-only" *ngIf="season.tariff_applicable_from != '' && season.tariff_applicable_to != ''">
                                    {{ utility.convertDate(season.tariff_applicable_from) + " - " + utility.convertDate(season.tariff_applicable_to) }}
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                    <p class="read-only tariff-heading">
                                        {{ (offerInfo.gasOffer.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                                    </p>
                                </div>
                            </div>

                            <div class="row supply-charge">
                                <div class="col-md-6 col-sm-6 col-xs-6 text-left">
                                    Supply charges
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 value">
                                    <p>
                                        <span *ngIf="offerInfo.gasOffer.includeGSTRates == true">
                                            {{ ((season.tariff_daily_charge * 1) + (season.tariff_daily_charge * (offerInfo.gasOffer.GSTPercent / 100))) | number : '1.2-2' }}
                                        </span>
                                        <span *ngIf="offerInfo.gasOffer.includeGSTRates == false">
                                            {{ season.tariff_daily_charge | number : '1.2-2' }}
                                        </span>
                                        &cent;/day
                                    </p>
                                </div>
                            </div>

                            <div *ngFor="let tariff of season.TARIFFS">
                                <div class="row rate-charge" *ngIf="season.TARIFFS.length == 1">
                                    <div class="col-md-12 text-left">
                                        Flat
                                    </div>
                                </div>
                                <div class="row rate-charge" *ngIf="season.TARIFFS.length > 1">
                                    <div class="col-md-12 text-left">
                                        {{ (tariff.tariff_type == '' || tariff.tariff_type == null) ? tariff.tariff_name : tariff.tariff_type }}
                                    </div>
                                </div>

                                <!--time bands-->
                                <div class="row rate-separator no-border" *ngIf="season.TARIFFS.length > 1">
                                    <div class="col-md-12 mid-padding text-left" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                                    </div>
                                </div>

                                <!--rates start-->
                                <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                                    <div class="row rate-charge rate-separator">
                                        <div class="col-md-6 mid-padding season-rate">
                                            <div *ngIf="tariff.RATES.length > 1">
                                                <span *ngIf="rate.desc != 'Balance'">
                                                    {{ rate.desc }}
                                                    {{ rate.desc_rate_block_consumption | number : '1.0-2' }}
                                                    MJ per {{ season.tariff_cons_prd }}
                                                </span>
                                                <span *ngIf="rate.desc == 'Balance'">
                                                    {{ rate.desc }}
                                                </span>
                                            </div>
                                            <div *ngIf="tariff.RATES.length == 1">
                                                All consumption
                                            </div>
                                        </div>
                                        <div class="col-md-6 mid-padding value strong">
                                            <span>
                                                <span *ngIf="offerInfo.gasOffer.includeGSTRates == true">
                                                    {{ ((rate.rate_block * 1) + (rate.rate_block * (offerInfo.gasOffer.GSTPercent / 100))) | number : '1.2-2' }}
                                                </span>
                                                <span *ngIf="offerInfo.gasOffer.includeGSTRates == false">
                                                    {{ rate.rate_block | number : '1.2-2' }}
                                                </span>
                                                {{ offerInfo?.gasOffer?.isGasOffer ? '¢/MJ' : '¢/kWh' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <!--rates end-->
                            </div>
                        </div>
                        <!-- season end -->

                        <!-- Green Power -->
                        <div *ngIf="showRates == true">
                            <br />
                            <div class="viewRateDetails">
                                <div *ngIf="offerInfo.gasOffer.greenPowerDisplay != false">
                                    <div class="row col-md-12 offer-rate-header">
                                        Green power
                                    </div>
                                    <div class="read-only no-padding col-md-12" *ngIf="offerInfo.gasOffer.intrinsicGreenPowerDisplay != 0">
                                        Annual total includes {{ offerInfo.gasOffer.intrinsicGreenPowerDisplay }}% green power included in the offer rates
                                        <br />
                                    </div>
                                    <div class="read-only no-padding col-md-12" *ngIf="offerInfo.gasOffer.greenPower !== 0">
                                        Annual total includes {{ offerInfo.gasOffer.greenPower }}%
                                        GreenPower option
                                        <br />
                                    </div>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="print-icons col-lg-12">
    <button
        id="print-compare"
        title="print retailer offer details"
        aria-label="print retailer offer details"
        tabindex="6"
        type="button" class="btn btn-link" (click)="print()">
        <i class="fa fa-print"></i>
        - print these results
    </button>
    <button
        id="email-compare"
        title="email retailer offer details"
        aria-label="email retailer offer details"
        tabindex="5"
        type="button" class="btn btn-link"
        (click)="email()">
        <i class="fa fa-envelope"></i>
        - email these results
    </button>
</div>
<br /><br /><br /><br />
