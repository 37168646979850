<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="row retailer-list col-lg-12">
        <div class="col-md-4 retailer" *ngFor="let retailer of retailers">
            <div>
                <img
                [attr.alt]="retailer.retailer_licence_name"
                [attr.aria-label]="retailer.retailer_licence_name"
                src='assets/{{ retailer.retailerImageUrl }}'>
            </div>
            <h4>
                {{ retailer.title }}
                <span class="retailer-icons">
                    <i class="material-icons button-icons elec" title="Retailer has electricity offers" aria-hidden="true" *ngIf="retailer.hasElec == true">flash_on</i>
                    <i class="material-icons button-icons gas" title="Retailer has gas offers" aria-hidden="true" *ngIf="retailer.hasGas == true">whatshot</i>
                    <i class="material-icons button-icons resi" title="Retailer has residential offers" aria-hidden="true" *ngIf="retailer.hasResi == true">home</i>
                    <i class="material-icons button-icons sme" title="Retailer has business offers" aria-hidden="true" *ngIf="retailer.hasSme == true">business</i>
                </span>
            </h4>
            <p>
                <i class="fa fa-phone"></i>
                {{ retailer.retailer_phone }}
                <br>
                <a href="{{ (retailer.retailer_url.indexOf('http') == -1) ? 'http://' + retailer.retailer_url : retailer.retailer_url }}" target="_blank" class="retailer-link non-focus">
                    <i class="fa fa-external-link"></i>
                    {{ retailer.retailer_url }}
                </a>
                <br>ABN: {{ retailer.abn }}
                <br>ESC licence information:
                {{ (retailer.retailer_fuel_type == 'Both') ? 'Electricity and gas' : retailer.retailer_fuel_type }}
                {{ (retailer.retailer_licence_name == null || retailer.retailer_licence_name == '') ? '' : ' retail licence under ' + retailer.retailer_licence_name }}
            </p>
        </div>
    </div>
</div>
<br /><br />
