import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment'; //config file
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.css']
})
export class EmailModalComponent implements OnInit {
  public modal = false;
  public html = '';
  public isSolar = false;
  public emailSubmitted = false;
  public emailStatus;
  public errorMessage;
  public emailForm : FormGroup;
  public email = '';
  public headerTitle = 'Email Results';
  public appURL = environment.appURL;
  constructor(
    public router : Router,
    public spinner : NgxSpinnerService,
    public formBuilder : FormBuilder,
    public activeModal: NgbActiveModal,
    public dataService : DataService) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.pattern("^.+@.+\\..+$")]),
    });
  }

  public sendMail() {
    if (this.emailForm.status == 'VALID') {
      let emailHtml;
      if (this.isSolar == true) {
        emailHtml = this.html;
      } else {
        //send email here
        emailHtml = '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/email.css" />';
        emailHtml += '<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>';
        emailHtml += '<link href="' + this.appURL + '/assets/css/bootstrap.min.css" rel="stylesheet" media="screen">';
        if (this.modal == true) {
          emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/offer-info.css" />';
          emailHtml += '<div class="container">' + document.getElementsByClassName("modal-body")[0].innerHTML + '</div>';
        } else if (this.router.url.indexOf('/offers') != -1 || this.router.url.indexOf('/psb-offers') != -1) {
          emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/offers-email.css" />';
          if (this.router.url.indexOf('/offers/list/dual') != -1) {
            emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/dual-offers-email.css" />';
          }
          emailHtml += document.getElementsByTagName("app-offers-right-panel")[0].innerHTML;
        } else if (this.router.url == '/contact') {
          emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/contact-email.css" />';
          emailHtml += document.getElementById("contact-retailer-details").innerHTML;
        } else { //compare offers
          emailHtml += '<link rel="stylesheet" media="screen" href="' + this.appURL + '/assets/css/compare-email.css" />';
          emailHtml += document.getElementById("compare-details").innerHTML;
        }
      }

      this.spinner.show();
      this.dataService.sendEmail(emailHtml, this.email, this.isSolar).subscribe(
        result => {
          this.emailSubmitted = true;
          this.emailStatus = result["status"];
          if (this.emailStatus == "error") {
            this.errorMessage = result["message"];
          }
          this.spinner.hide();
        },
        error => {
          this.emailStatus = false;
          this.errorMessage = 'An error has occurred while trying to send the email, please try again later';
          this.spinner.hide();
        }
      );
    }
  }

  public formIsvalid() {
    return (this.emailForm.status != 'VALID');
  }

  public selectButton(event) {
    if (event.keyCode == 13) { //keypress enter
      this.sendMail();
    }
  }
}
