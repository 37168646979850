import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'; //config file
import { Router } from "@angular/router";
import { DataService } from './data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DeviceDetectorService } from 'ngx-device-detector';



@Injectable({
  providedIn: 'root'
})

export class Utility {
  public appURL = environment.appURL;
  private errorMessage = '';
  private errorEmailMessage = '';
  public errorValidation = '';

  constructor(public router : Router,
    public dataService : DataService,
    private deviceService: DeviceDetectorService,
    public spinner : NgxSpinnerService) {}
  public convertDate(date) {
    let dates = date.split("-");
    var monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November",
                      "December"];
    let dateString = dates[2] + " " + monthNames[parseInt(dates[1]) - 1];
    return dateString;
  }

  public getErrorMessage() : string {
    return this.errorMessage;
  }

  public getEmailErrorMessage() : string {
    return this.errorEmailMessage;
  }

  public setErrorMessage(errorMessage) : void {
    this.errorMessage = errorMessage;
  }

  public setEmailErrorMessage(errorMessage) : void {
    this.errorEmailMessage = errorMessage;
  }

  private scrollTo(el: Element) : void {
    if (el) {
      //radio buttons is hidden, need to get the next element
      if (el.getAttribute('type') == 'radio') {
        el = el.nextElementSibling;
      }
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public scrollToError() : void {
    let priorityErrors = document.querySelector('.ng-invalid[formControlName]');
    let secondaryErrors = document.querySelector('.text-danger');
    if (priorityErrors != null) {
      this.scrollTo(priorityErrors);
    }
    if (priorityErrors == null && secondaryErrors != null) {
      this.scrollTo(secondaryErrors);
    }
  }

  public gasErrors() : void {
    let errors = document.querySelector('#billDetails');
    if (errors != null) {
      this.scrollTo(errors);
    }
  }

  public scrollToAlertMessage() : void {
    const messageElement = document.querySelector('head');
    this.scrollTo(messageElement);
  }

  public scrollToId(id) : void {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  public scrollToTop(id) : void {
    let topPlacement = (id == 'none') ? 0 : document.getElementById(id).offsetTop - 30;
    if (navigator.userAgent.indexOf("Firefox") != -1) {
      window.scroll(0, 0); //scrolling update works on all browsers
    } else {
      window.scrollTo({ top: topPlacement, behavior: 'smooth' });
    }
  }

  public scrollToNextForm(addTop : number = 90) {
    let doc = document.documentElement;
    let topPlacement = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    topPlacement += addTop;
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);
    if (isIE == true) { //if mobile or IE
      window.scrollTo(0, topPlacement);
    } else {
      window.scroll(0, topPlacement);
    }
  }

  public resetPage() {
    sessionStorage.removeItem("selectedEnergyType");
    let isMobile = this.checkIfMobile();
    //Cancel click when the browser is mobile/tablet & the filters are shown
    if (isMobile == true && this.router.url.indexOf('offers') != -1) {
      if (document.getElementById('open-filters-btn').style.display == 'none') {
        return false;
      }
    }

    //remove local storage key if it exist
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheID is defined get saved data from the server
    if (serverCacheId != null) {
      localStorage.removeItem("serverCacheId");
    }
    window.location.href = this.appURL;
  }

  public redirectToHome(selectedSolarEnergyType = '', reload = false) {
    this.spinner.hide();
    if (this.router.url != '/') {
      if (selectedSolarEnergyType != '') {
        this.dataService.selectedSolarEnergyType = selectedSolarEnergyType;
      }
      //redirect to home page
      this.router.navigate(['/']).then(() => {
        if (reload == true) {
          window.location.reload();
        }
      });
      return false;
    }
  }

  public redirectToSolarHome() {
    this.router.navigate(['/standalone']);
  }

  public redirectToSolar() {
    sessionStorage.setItem("VecClickThrough", this.uniqid());
    sessionStorage.setItem("energySelection", this.uniqid());
    this.router.navigate(['/standalone']);
  }

  public nextForm(urlRoute, loopBack) {
    if (loopBack == true) {
      this.router.navigate(['/loopback/' + urlRoute]);
    } else {
      this.router.navigate(['/' + urlRoute]);
    }
    return false;
  }

  public showOffers(loopBack, energyType) {
    if (loopBack == true) {
      this.router.navigate(['/offers/list/dual']);
    } else {
      this.router.navigate(['/offers']);
    }
  }

  public loopBack() {
    this.scrollToTop("none");
    this.router.navigate(['/loopback/start']);
  }

  public convertTime(time) {
    time = time.split(':');
    let now = new Date();
    let convertedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), time[0], time[1], 0);
    return convertedTime.toLocaleString('en-US', { hour: 'numeric', hour12: true }).toLowerCase();
  }

  public maintenanceMode() {
    this.dataService.getSettings().subscribe(
      settings => {
        this.dataService.liveChatOn = (settings["live_chat"] == 'on') ? true : false;
        this.dataService.liveAEMO = (settings["get_my_data"] == 'on') ? true : false;
        this.dataService.psbMode = settings["psb_mode"];
        this.dataService.ebrMode = settings["ebr_mode"];
        //check session storage for live chat
        if (sessionStorage.getItem("liveChatOn") != null) {
          this.dataService.liveChatOn = (sessionStorage.getItem("liveChatOn") == 'on') ? true : false;
          // if live chat is turned off on session storage and livechat is set as on, on database
          // turn on live chat
          if (settings["live_chat"] == 'on' && sessionStorage.getItem("liveChatOn") == 'off') {
            this.dataService.liveChatOn = true;
            sessionStorage.setItem("liveChatOn", "on");
          }
        } else {
          sessionStorage.setItem("liveChatOn", settings["live_chat"]);
        }

        if (settings["maintenance_mode"] == 'on') {
          this.router.navigate(['/offline']).then(() => {
            this.scrollToTop("none");
            //window.location.reload();
          });
          return false;
        } else {
          if (this.router.url == '/offline') {
            this.router.navigate(['/']).then(() => {
              this.scrollToTop("none");
              //window.location.reload();
            });
          }
        }

        if (sessionStorage.getItem("psbMode") != null) {
          this.dataService.psbMode = sessionStorage.getItem("psbMode");
          sessionStorage.removeItem("psbMode");
        }


      }
    );
  }

  public uniqid(prefix = "", random = false) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
  }

  public formatStepRates(rates) {
    let tmp = 0;
    let ratesCount = rates.length;
    if (ratesCount > 1) {
      let allConsumption = 0;
      let allExport = 0;
      for (let rate of rates) {
        if (rates[tmp].rate_block_export) {
          allExport = (allExport > 0) ? (rates[tmp].rate_block_export - rates[tmp - 1].rate_block_export) : rates[tmp].rate_block_export;
          if (tmp == 0) {
            rates[tmp].desc = 'First';
            rates[tmp].desc_rate_block_export = rates[tmp].rate_block_export;
          } else if (tmp + 1 == ratesCount) { //last
            rates[tmp].desc = 'Balance';
            rates[tmp].desc_rate_block_export = 0;
          } else {
            rates[tmp].desc = 'Next';
            rates[tmp].desc_rate_block_export = rates[tmp].rate_block_export;
          }
        } else {
          allConsumption = (allConsumption > 0) ? (rates[tmp].rate_block_consumption - rates[tmp - 1].rate_block_consumption) : rates[tmp].rate_block_consumption;
          if (tmp == 0) {
            rates[tmp].desc = 'First';
            rates[tmp].desc_rate_block_consumption = allConsumption;
          } else if (tmp + 1 == ratesCount) { //last
            rates[tmp].desc = 'Balance';
            rates[tmp].desc_rate_block_consumption = 0;
          } else {
            rates[tmp].desc = 'Next';
            rates[tmp].desc_rate_block_consumption = allConsumption;
          }
        }
        tmp++;
      }
    }
    return rates;
  }

  public formatTariffs(tariffDetails) {
    let ctr = 0;
    for (let tariff of tariffDetails) {
      let tmp = 0;
      if (tariff.TARIFFS) {
        for (let newTariff of tariff.TARIFFS) {
          let rates = newTariff.RATES;
          if (rates.length > 1) {
            tariffDetails[ctr].TARIFFS[tmp].RATES = this.formatStepRates(rates);
          }
          tmp++;
        }
      }
      ctr++;
   }
   return tariffDetails;
  }

  public formatTimeBand(timebands) {
    let formattedTimeBands = '';
    let weekdays = [];
    let saturdays = [];
    let sundays = [];
    let tmp = 0;
    let timebandCount = timebands.length;
    if (timebandCount > 0) {
      for (let timeband of timebands) {
        if (timeband.tariff_sat == true) {
          saturdays.push(this.convertTime(timeband.time_band_start) + " - " + this.convertTime(timeband.time_band_end));
        }

        if (timeband.tariff_sun == true) {
          sundays.push(this.convertTime(timeband.time_band_start) + " - " + this.convertTime(timeband.time_band_end));
        }

        if (timeband.tariff_weekdays == true) {
          weekdays.push(this.convertTime(timeband.time_band_start) + " - " + this.convertTime(timeband.time_band_end));
        }
        tmp++;
      }

      if (saturdays.length > 0) {
        formattedTimeBands += "Saturday " + saturdays.join(', ') + "<br>";
      }
      if (sundays.length > 0) {
        formattedTimeBands += "Sunday " + sundays.join(', ') + "<br>";
      }
      if (weekdays.length > 0) {
        formattedTimeBands += "Weekday " + weekdays.join(', ') + "<br>";
      }

    }
    return formattedTimeBands;
  }

  public titleCase(value : string) : string {
    if (value != undefined && value != '') {
      let first = value.substr(0,1).toUpperCase();
      return first + value.substr(1);
    } else {
      return '';
    }
  }

  public logJourneyProgress(stage, notes = '') {
    //log journey progress
    let serverCacheId = localStorage.getItem("serverCacheId");
    if (serverCacheId != null) {
      this.dataService.logJourneyProgress(serverCacheId, stage, notes).subscribe(
        data => {
        },
        error => {
        }
      );
    }
  }

  public inApp() {
    var ua = navigator.userAgent;
    let isInApp = false;
    if ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf("GSA") > -1)) {
      isInApp = true;
    }
    return isInApp;
  }

  public sessionRestart() {
    localStorage.setItem("sessionRestart", 'true');
  }

  public toolTipForMobile() {
    let isMobile = this.checkIfMobile();
    if (isMobile == true) {
      return 'click:blur';
    } else {
      return 'hover:blur';
    }
  }

  public checkNMIPrefix(distributorName, NMIPrefix) {
    /* NMI prefix values
    Ausnet: 6305, 6306
    Citipower: 6102, 6103
    Jemena: 6001
    Powercor: 6203, 6204
    United: 6407, 6408
    */
    let validation = true;
    if (distributorName == 'AusNet Services (electricity)' && ["6305", "6306"].indexOf(NMIPrefix) == -1) {
      validation = false;
      this.errorValidation = 'NMIPrefixAusnet';
    } else if (distributorName == 'Citipower' && ["6102", "6103"].indexOf(NMIPrefix) == -1) {
      validation = false;
      this.errorValidation = 'NMIPrefixCitipower';
    } else if (distributorName == 'Jemena' && NMIPrefix != "6001") {
      validation = false;
      this.errorValidation = 'NMIPrefixJemena';
    } else if (distributorName == 'Powercor' && ["6203", "6204"].indexOf(NMIPrefix) == -1) {
      validation = false;
      this.errorValidation = 'NMIPrefixPowercor';
    } else if (distributorName == 'United Energy' && ["6407", "6408"].indexOf(NMIPrefix) == -1) {
        validation = false;
        this.errorValidation = 'NMIPrefixUnited';
    }
    return validation;

  }

  // Converts AAA12345AA to 12345, where 12345 can be of mixed length
  public offerRefCodeToInteger(offerRefCode)
  {
    if (offerRefCode == undefined || offerRefCode.length < 6) {
      return 0;
    } else {
      return offerRefCode.substring(0, offerRefCode.length-2).substring(3);
    }
  }

  public openFilters() {
    document.getElementById("filters").style.width = "290px";
    document.getElementById("filters").style.paddingTop = "60px";
    document.getElementById("filters").style.paddingLeft = "1em";
    document.getElementById("filters").style.paddingRight = "1em";
    document.getElementById("open-filters-btn").style.display = "none";
    document.getElementById("close-filters-btn").style.display = "block";
  }

  public closeFilters() {
    document.getElementById("filters").style.width = "0";
    document.getElementById("filters").style.padding = "0";
    document.getElementById("open-filters-btn").style.display = "block";
    document.getElementById("close-filters-btn").style.display = "none";
  }

  public checkFilters() {
    let isMobile = this.checkIfMobile();
    if (isMobile == true && this.router.url.indexOf('offers') != -1) {
      if (document.getElementById('open-filters-btn').style.display == 'none') {
        this.closeFilters();
        return false;
      }
    }
  }

  private checkIfMobile() {
    let isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? true : false;

    return isMobile;
  }

  public getSelectedEnergyType() {
    return sessionStorage.getItem("selectedEnergyType");
  }

  public removeServerCacheId(currentServerCacheId) {
    if (currentServerCacheId == localStorage.getItem("serverCacheId")) {
      localStorage.removeItem("serverCacheId");
    }
  }

  public sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

}
