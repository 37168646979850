<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <img class="hottopic-image"
                 src="assets/images/{{ secImageURL }}contentpages/hottopics_header_3.jpg"
                 alt="Building or renovating? It's time to start saving">
            <p>
                There's no better time to start your energy saving than when you're planning to build or renovate. Having a clean slate
                to design a home that saves on energy is a unique opportunity that can make a big difference in the long-term, both
                financially and for the environment.
            </p>
            <p>
                As of 2011, all new homes, renovations, additions and alterations have to meet the 6 Star Standard as part of the National
                Construction Code. So, if you're thinking of building, it's good to know what your options are.
            </p>

            <br /><br />
            <h3>How to reduce energy bills.</h3>
            <ol>
                <li>
                    <span class="strong">
                        Compare electricity and gas providers to find the cheapest offer for you.
                    </span>
                    <p>
                        7 out of 10 Victorians spend more money than they need to on energy, and almost a quarter spend over $500 more. To
                        find out which energy offer is cheapest and best for your new home, you can use our independent
                        <a class="hot-topics" href="{{ appURL }}" target="_blank">comparison website</a>. Victorians who use the website
                        make savings of on average, $330 a year by switching energy provider.
                    </p>
                </li>
                <li>
                    <span class="strong">Go solar!</span>
                    <p>
                        While solar can seem like a large expenditure at the offset, it's the long-term benefits that make it an attractive
                        investment. By using energy that you produce yourself, you're drawing less energy from the grid and therefore paying
                        less. Plus, most households use only 50% of the solar energy they produce, leaving 50% that gets injected back into
                        the grid, which your energy provider will pay you for.
                    </p>
                </li>
                <li>
                    <span class="strong">Make sure your windows are solid.</span>
                    <p>
                        A single pane of glass can gain or lose up to ten times more heat than the same area of wall, making insulation
                        difficult. Energy efficient windows, such as ones that are double or triple glazed reduce heat loss or gain by 30%
                        and are designed to keep the heat inside in winter, and out in summer, minimising the need for use of heating and
                        cooling systems.
                    </p>
                </li>
                <li>
                    <span class="strong">Insulation. Insulation. Insulation.</span>
                    <p>
                        Having a well-insulated home can save you up to 45% on your energy usage per year. Having correct ceiling, wall
                        and floor insulation makes your house like an esky in summer and like a thermos in winter, leading to less
                        reliance on heating and cooling systems.
                    </p>
                </li>
                <li>
                    <span class="strong">Lighting.</span>
                    <p>
                        For any home build or renovation, considering lighting is important for minimising energy usage. Make sure you're
                        installing LED light bulbs, which use up to 75% less energy than halogen bulbs, and look to using lamps to light
                        your rooms, a cheaper, and energy-saving strategy.
                    </p>
                </li>
            </ol>
            <p>
                Building an energy-efficient and energy saving home takes planning and preparation, but the future benefits make the
                effort worthwhile.
            </p>
            <p>
                For more information on building and renovating sustainably, check out
                <a class="hot-topics" href="https://www.victorianenergysaver.vic.gov.au" target="_blank">Victorian Energy Saver</a> and the
                <a class="hot-topics" href="http://www.yourhome.gov.au/" target="_blank">Australian Government's Your Home</a> magazine. To
                find the best energy offer for your new house, head to our easy to use
                <a class="hot-topics" href="{{ appURL }}" target="_blank">comparison website</a>.
            </p>
            <br />
            <p>
                <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
            </p>
        </div>
    </div>
</div>
<br /><br />
