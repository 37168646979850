<!-- Footer -->
<footer (click)="utility.checkFilters()">
    <div class="footer-primary {{ secCss }} hidden-sm hidden-xs">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 footer-desc">
                    <p class="gray-text">Once you've taken charge of your bills, you can take charge of your energy use.</p>
                    <br />
                    <p>
                        <a title="Tips on how to save more" aria-label="Tips on how to save more" tabindex=-1 class="energy-link non-focus"
                            href="https://energy.vic.gov.au" target="_blank">
                            For more ways to save, visit energy.vic.gov.au.
                        </a>
                    </p>
                    <br />
                </div>
                <div class="col-lg-3 col-md-offset-1 col-sm-offset-1 footer-desc">
                    <p>
                      <a href="tel:1800000832" class="interpreter-link non-focus" tabindex=-1>1800 000 VEC (832)</a>
                    </p>
                    <p>
                      <a routerLink="/contact-us" class="energy-link non-focus" tabindex="-1">Contact Us</a>
                    </p>
                </div>
                <div class="col-lg-3 footer-image footer-desc">
                    <img aria-label="Take Charge. Save on Bills." alt="Take Charge. Save on Bills." src='assets/images/{{ secImageURL }}footer_1140.png'>
                </div>
            </div>
        </div>

    <div class="footer-secondary">
        <div class="container">
            <div class="row footer-links" *ngIf="utility.getSelectedEnergyType() != 'Solar'">
                <div class="col-md-7 col-xs-12 col-sm-12 pull-left">
                    <ul class="row">
                        <li class="first col-sm-4 col-md-2">
                            <a title="Current retailers" aria-label="Current retailers" tabindex=-1 target="_blank" href="/retailers" class="non-focus">Current retailers</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="VEC Energy Updates" aria-label="VEC Energy Updates" tabindex=1 target="_blank" href="/subscribe" class="non-focus">VEC Energy Updates</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                          <a title="Embedded Networks" aria-label="Embedded Networks" tabindex=-1 target="_blank" href="/embedded-networks" class="non-focus">Embedded Networks</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Top tips for saving money" aria-label="Top tips for saving money" tabindex=-1 target="_blank" href="/top-tips-for-saving-money-on-energy-bills" class="non-focus">Top tips for saving money</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Hot topics" aria-label="Hot topics" tabindex=-1 target="_blank" href="/hot-topics" class="non-focus">Hot topics</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Solar Savings" aria-label="Solar Savings" tabindex=-1 target="_blank" href="solar-savings" class="non-focus">Solar Savings</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Instructional Videos" aria-label="Instructional Videos" tabindex=-1 target="_blank" href="/instructional-videos" class="non-focus">Instructional Videos</a>
                        </li>

                        <li class="col-sm-4 col-md-2">
                            <a title="Current retailers" aria-label="Current retailers" tabindex=-1 target="_blank" href="https://www.ewov.com.au/" class="non-focus">
                                Energy and Water Ombudsman of Victoria (EWOV)
                            </a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Current retailers" aria-label="Current retailers" tabindex=-1 target="_blank" href="http://www.esc.vic.gov.au/" class="non-focus">
                                Essential Services Commission (ESC)
                            </a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Top tips for saving money" aria-label="Top tips for saving money" tabindex=-1 target="_blank" href="https://www.choice.com.au/home-improvement/energy-saving" class="non-focus">
                                CHOICE
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-5 col-xs-12 col-sm-12 pull-right">
                    <ul class="row pull-right">
                        <li class="first col-sm-4 col-md-2">
                            <a title="Privacy policy" aria-label="Privacy policy" tabindex=-1 target="_blank" href="/privacy" class="non-focus">Privacy</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Disclaimer" aria-label="Disclaimer" tabindex=-1 target="_blank" href="https://www.deeca.vic.gov.au/disclaimer" class="non-focus">Disclaimer</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Copyright" aria-label="Copyright" tabindex=-1 target="_blank" href="https://www.deeca.vic.gov.au/copyright" class="non-focus">Copyright</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Accessibility" aria-label="Accessibility" tabindex=-1 target="_blank" href="https://www.deeca.vic.gov.au/accessibility" class="non-focus">Accessibility</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Sitemap" aria-label="Sitemap" tabindex=-1 target="_blank" href="https://www.deeca.vic.gov.au/sitemap" class="non-focus">Sitemap</a>
                        </li>
                        <li class="col-sm-4 col-md-2">
                            <a title="Provide feedback" aria-label="Provide feedback" tabindex=-1 target="_blank" href="/feedback" class="non-focus">Provide Feedback</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row no-padding">
                <div class="col-md-4 col-xs-12 col-sm-12">
                    <div class="last-updated">Page last updated {{ currentDate | date:'dd MMMM yyyy' }}</div>
                    <div class="copyright">
                        <em>
                            Copyright {{ currentDate | date:'yyyy' }}
                        </em>
                        <br />
                        <br />
                        Department of Energy, Environment and Climate Action
                    </div>
                </div>

                <div class="col-md-4 col-xs-12 col-sm-12">
                    <div class="social">
                        <span>
                            <a title="twitter" aria-label="twitter" tabindex=-1 class="icon-ftwitter external non-focus" href="https://twitter.com/DEECA_Vic" target="_blank" title="DEECA Victoria">
                                <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                        </span>
                        <span>
                            <a title="facebook" aria-label="facebook" tabindex=-1 class="icon-ffacebook external non-focus" href="https://www.facebook.com/DEECAVictoria" target="_blank" title="Department of Energy, Environment and Climate Action">
                                <i class="fa fa-facebook-square" aria-hidden="true"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</footer>
<livechat-widget license="{{ liveChatID }}" *ngIf="getChatSetting() == true"></livechat-widget>
