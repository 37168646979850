<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

  <div class="row" *ngIf="messageIsSent == false">
        <div class="col-lg-7 col-md-7 col-xs-12">
            <h3>Send us a message</h3>
            <div class="alert alert-danger" *ngIf="errorMessage != ''">
                {{ errorMessage }}
            </div>

            <form [formGroup]="contactForm" onsubmit="return false" (keydown.enter)="$event.preventDefault()">
                <input type="hidden" name="referrer" value="">
                <input type="hidden" name="offerID" value="">
                <input type="hidden" formControlName="recaptchaResponse" [(ngModel)]="recaptchaResponse">
                <div class="row">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <label for="name" class="strong">Name</label> *
                            <input type="text" class="form-control" formControlName="name" [(ngModel)]="contactData.name">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <label for="email" class="strong">Email</label> *
                            <input type="text" class="form-control" formControlName="email" [(ngModel)]="contactData.email">
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="offerId == ''">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <label for="enquiryRelatingTo" class="strong">Enquiry Relating To</label> *
                            <select class="custom-select" formControlName='subject' [(ngModel)]="contactData.subject">
                                <option value="" disabled>Choose one</option>
                                <option *ngFor="let enquiry of enquiryRelatingTo" [ngValue]="enquiry">{{enquiry}}</option>
                            </select>
                            <bfv-messages></bfv-messages>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="contactData.subject == 'Power Saving Bonus'">
                  <div class="col-lg-11 col-md-11 col-xs-12">
                      <div class="form-group">
                          <label for="name" class="strong">PSB application number (if known)</label>
                          <input type="text" aria-label="PSB application number" placeholder="Optional" class="form-control" formControlName="refNumber" [(ngModel)]="contactData.refNumber">
                      </div>
                  </div>
              </div>
              <div class="row" *ngIf="contactData.subject == 'Energy Bill Relief'">
                <div class="col-lg-11 col-md-11 col-xs-12">
                    <div class="form-group">
                        <label for="name" class="strong">EBR application number (if known)</label>
                        <input type="text" aria-label="EBR application number" placeholder="Optional" class="form-control" formControlName="refNumber" [(ngModel)]="contactData.refNumber">
                    </div>
                </div>
            </div>

                <div class="row">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <label for="comment" class="strong">Comment</label> *
                            <br />
                            <textarea name="comment" rows="5" class="form-control"
                            formControlName="comment" [(ngModel)]="contactData.comment"></textarea>
                        </div>
                    </div>
                </div>
                <span class="msg-error error"></span>

                <p>&nbsp;</p>

                <button type="button" name="reset" class="btn btn-danger" (click)="resetForm()">
                    <i class="fa fa-undo"></i> Reset
                </button>
                <button type="submit" name="submit" id="submit" class="btn btn-primary" (click)="sendMessage()">
                    <i class="fa fa-paper-plane"></i> Submit</button>
            </form>
            <br />
        </div>
        <div class="col-lg-5 col-md-5 col-xs-12">
        </div>
    </div>
    <div class="row" *ngIf="messageIsSent == true && emailStatus == 'success'">
        <div class="col-lg-10 col-md-10 col-xs-12 messageSent">
            <div class="">
                <h4>Thank you for contacting Victorian Energy Compare.</h4>
                <p>Please find information below that may help answer your query.</p>
                <p><strong>2024-25 Energy Bill Relief Fund</strong></p>
                <ul>
                  <li>All Australian households will receive a $300 rebate and eligible small businesses $325 from the Australian Government,
                    to help with the cost of their energy bills in financial year 2024-25. The Victorian Government is currently working with
                    the Commonwealth Government to determine how the payment will be delivered. To find out more visit <a href="https://www.energy.gov.au/energy-bill-relief-fund">energy.gov.au/energy-bill-relief-fund</a>.</li>
                </ul>
                <p><strong> Energy Bill Relief Program (for Embedded Network households)</strong></p>
                <ul>
                  <li>The Energy Bill Relief Program for embedded network households closed on 30 June 2024 and no new applications are being accepted.</li>
                  <li>Existing application enquiries will be responded to as soon as possible.</li>
                </ul>
                <p><strong>$250 Power Saving Bonus Program</strong></p>
                <ul>
                  <li>The last round of the Power Saving Bonus program closed on 31 August 2023.</li>
                  <li>There are no further rounds of the $250 Power Saving Bonus Program.
                  </li>
                </ul>
                <p><strong>Victorian Energy Compare website</strong></p>
                <ul>
                  <li>Frequently Asked Questions for Victorian Energy Compare and the comparison
                    tool can be found <a href="https://compare.energy.vic.gov.au/languages/english/vec-faqs">here</a>.</li>
                  <li>If you have any questions about a specific offer or would like to sign up for an energy offer you
                    have found on Victorian Energy Compare, please get in touch with the retailer directly. The retailer
                    can provide further information and help you make the switch. Retailer details can be found
                    <a href="https://compare.energy.vic.gov.au/retailers">here</a>.
                  </li>
                </ul>
                <br />
            </div>
            <div class="alert alert-danger" *ngIf="emailStatus == 'error'">
                An error has occured while trying to send the email, please try again later.
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-10 col-md-10 col-xs-10 messageSent page-divider-up" data-nosnippet>
            <br />
            <h3>Call us</h3>
            <p>
                Call <span class="strong">1800 000 VEC (832)</span> from anywhere in Australia, Monday to Friday, 9am to 5pm.
                Costs may vary - mobiles and  public telephones may incur additional costs.
            </p>
            <p>
                If you are deaf, or have a hearing impairment or speech impairment, contact us throught the National
                Relay Service on 133 677 or visit
                <a href="http://www.relayservice.gov.au" target="_blank">www.relayservice.gov.au</a>
            </p>
            <h3>Contact us by post</h3>
            <p>
                <span class="strong">Victorian Energy Compare team</span><br>
                <span class="strong">PO Box 500</span><br>
                <span class="strong">East Melbourne VIC 8002</span>
            </p>
            <h3>Feedback or Complaint</h3>
            <p>
               If you have a complaint or wish to provide feedback, please use this form <a href="https://compare.energy.vic.gov.au/feedback">https://compare.energy.vic.gov.au/feedback</a>.
            </p>
        </div>
    </div>

</div>
<br /><br />
