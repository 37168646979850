import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from './data.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { Utility } from './utility';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EnergyProfileForm {
  public otherEnergyRetailer = '';
  public currentEnergyRetailer = '';
  public saveFormError = '';
  public profileForm : FormGroup;
  public bannerTitle = "Energy questionnaire";
  public bannerSubTitle = "";
  public bannerDetails = "A set of questions designed to discover personalised energy usage.";
  public bill;
  public controllLoadValidation;
  public clothesWeekDayValidation;
  public clothesWeekEndValidation;
  public homeValidation = '';
  public spaceValidation = '';
  public poolValidation = '';
  public waterHeatingValidation = '';

  public energyProfile = {
    pvCapacity : 0,
    pvCapacityCap : 0,
    hhSize : "0",
    totalRooms : "0",
    fridgeCount : "",
    gasConnection : "",
    poolHeating : "",
    poolHeatingSolar : false,
    poolHeatingGas : false,
    poolHeatingElectric : false,
    poolHeatingNone : false,
    spaceHeatingElectricDucted : false,
    spaceHeatingSplitSystem : false,
    spaceHeatingElectricUnderfloor : false,
    spaceHeatingElectricIndividual : false,
    spaceHeatingGasDucted : false,
    spaceHeatingGasUnderfloor : false,
    spaceHeatingGasIndividual : false,
    spaceHeatingOther : false,
    spaceHeatingNone : false,
    spaceCoolingRoomAC : false,
    spaceCoolingSplitSystem : false,
    spaceCoolingDuctedReverse : false,
    spaceCoolingDuctedEvaporative : false,
    spaceCoolingPortableRef : false,
    spaceCoolingPortableEvap : false,
    spaceCoolingOther : false,
    spaceCoolingNone : false,
    seaDistance : "",
    clothesDryer : "",
    clothesDryerWeekday : "",
    clothesDryerWeekend : "",
    dishwasherWeekday : "",
    dishwasherWeekend : "",
    waterHeatingElectric : false,
    waterHeatingElectricSolar : false,
    waterHeatingGasStorage : false,
    waterHeatingGasInstant : false,
    waterHeatingGasSolar : false,
    waterHeatingOther : false,
    controlledLoad : "",
    tvTotal : "",
    turnOffAtPowerShort : "",
    ovensElectric : "",
    ovensGas : "",
    washingMachineUsage : "",
    washingMachineWeekday : "",
    washingMachineWeekend : "",
    televisionUsageWeekday : "",
    televisionUsageWeekend : "",
    heatingUsageMethod : "",
    gasUsageWinter : "0",
    hhSize51 : "",
    energyType : "",
    hasSolar : "0",
    serverCacheId : "",
    pageDataType : "energyProfileData",
    loopBack: false
  }

  public loopBackValues = {
    hasConcession : "",
    postcode : "",
    hasSolar : ""
  }

  constructor(
    public dataService : DataService,
    public router : Router,
    public utility : Utility,
    public formBuilder : FormBuilder,
    public spinner : NgxSpinnerService,
    public config : NgbModalConfig,
    public modalService : NgbModal,
    private tooltipConfig : NgbTooltipConfig) {
  }

  public resetForm() {
    this.profileForm.reset();
    this.energyProfile = {
      pvCapacity : 0,
      pvCapacityCap : 0,
      hhSize : "0",
      totalRooms : "0",
      fridgeCount : "",
      gasConnection : "",
      poolHeating : "",
      poolHeatingSolar : false,
      poolHeatingGas : false,
      poolHeatingElectric : false,
      poolHeatingNone : false,
      spaceHeatingElectricDucted : false,
      spaceHeatingSplitSystem : false,
      spaceHeatingElectricUnderfloor : false,
      spaceHeatingElectricIndividual : false,
      spaceHeatingGasDucted : false,
      spaceHeatingGasUnderfloor : false,
      spaceHeatingGasIndividual : false,
      spaceHeatingOther : false,
      spaceHeatingNone : false,
      spaceCoolingRoomAC : false,
      spaceCoolingSplitSystem : false,
      spaceCoolingDuctedReverse : false,
      spaceCoolingDuctedEvaporative : false,
      spaceCoolingPortableRef : false,
      spaceCoolingPortableEvap : false,
      spaceCoolingOther : false,
      spaceCoolingNone : false,
      seaDistance : "",
      clothesDryer : "",
      clothesDryerWeekday : "",
      clothesDryerWeekend : "",
      dishwasherWeekday : "",
      dishwasherWeekend : "",
      waterHeatingElectric : false,
      waterHeatingElectricSolar : false,
      waterHeatingGasStorage : false,
      waterHeatingGasInstant : false,
      waterHeatingGasSolar : false,
      waterHeatingOther : false,
      controlledLoad : "",
      tvTotal : "",
      turnOffAtPowerShort : "",
      ovensElectric : "",
      ovensGas : "",
      washingMachineUsage : "",
      washingMachineWeekday : "",
      washingMachineWeekend : "",
      televisionUsageWeekday : "",
      televisionUsageWeekend : "",
      heatingUsageMethod : "",
      gasUsageWinter : "0",
      hhSize51 : "",
      energyType : "",
      hasSolar : "0",
      serverCacheId : "",
      pageDataType : "energyProfileData",
      loopBack: false
    }
  }

  public selectButton(event, name) {
    if (event.keyCode == 32 || event.keyCode == 13) { //spacebar & enter
      if (name == "poolHeatingYes") {
        this.energyProfile.poolHeating = '1';
      } else if (name == "poolHeatingNo") {
        this.energyProfile.poolHeating = '0';
      } else if (name == "clothesDryerYes") {
        this.energyProfile.clothesDryer = '1';
      } else if (name == "clothesDryerNo") {
        this.energyProfile.clothesDryer = '0';
      } else if (name == "controlledLoadYes") {
        this.energyProfile.controlledLoad = '1';
      } else if (name == "controlledLoadNo") {
        this.energyProfile.controlledLoad = '0';
      } else if (name == "saveData") {
        this.saveData();
      }
    }
  }

  public resetCheckbox(group : string) {
    if (group == 'poolHeating') {
      if (!this.energyProfile.poolHeatingNone == true) {
        this.energyProfile.poolHeatingSolar = false;
        this.energyProfile.poolHeatingGas = false;
        this.energyProfile.poolHeatingElectric = false;
      }
    } else if (group == 'space') {
      if (!this.energyProfile.spaceHeatingNone == true) {
        this.energyProfile.spaceHeatingElectricDucted = false;
        this.energyProfile.spaceHeatingSplitSystem = false;
        this.energyProfile.spaceHeatingElectricUnderfloor = false;
        this.energyProfile.spaceHeatingElectricIndividual = false;
        this.energyProfile.spaceHeatingGasDucted = false;
        this.energyProfile.spaceHeatingGasUnderfloor = false;
        this.energyProfile.spaceHeatingGasIndividual = false;
        this.energyProfile.spaceHeatingOther = false;
      }
    } else {
      if (!this.energyProfile.spaceCoolingNone == true) {
        this.energyProfile.spaceCoolingRoomAC = false;
        this.energyProfile.spaceCoolingSplitSystem = false;
        this.energyProfile.spaceCoolingDuctedReverse = false;
        this.energyProfile.spaceCoolingDuctedEvaporative = false;
        this.energyProfile.spaceCoolingPortableRef = false;
        this.energyProfile.spaceCoolingPortableEvap = false;
        this.energyProfile.spaceCoolingOther = false;
      }
    }
  }

  public resetCheckboxValidation() {
    if (this.energyProfile.spaceCoolingNone == true ||
      this.energyProfile.spaceCoolingRoomAC == true ||
      this.energyProfile.spaceCoolingSplitSystem == true ||
      this.energyProfile.spaceCoolingDuctedReverse == true ||
      this.energyProfile.spaceCoolingDuctedEvaporative == true ||
      this.energyProfile.spaceCoolingPortableRef == true ||
      this.energyProfile.spaceCoolingPortableEvap == true ||
      this.energyProfile.spaceCoolingOther == true) {
      this.homeValidation = '';
    }

    if (this.energyProfile.spaceHeatingNone == true ||
        this.energyProfile.spaceHeatingElectricDucted == true ||
        this.energyProfile.spaceHeatingSplitSystem == true ||
        this.energyProfile.spaceHeatingElectricUnderfloor == true ||
        this.energyProfile.spaceHeatingElectricIndividual == true ||
        this.energyProfile.spaceHeatingGasDucted == true ||
        this.energyProfile.spaceHeatingGasUnderfloor == true ||
        this.energyProfile.spaceHeatingGasIndividual == true ||
        this.energyProfile.spaceHeatingOther == true) {
      this.spaceValidation = '';
    }

    if (this.energyProfile.poolHeating == "1") {
      if (this.energyProfile.poolHeatingNone == true ||
          this.energyProfile.poolHeatingSolar == true ||
          this.energyProfile.poolHeatingGas == true ||
          this.energyProfile.poolHeatingElectric == true) {
        this.poolValidation = '';
      }
    }

    if (this.energyProfile.waterHeatingElectric == true ||
        this.energyProfile.waterHeatingElectricSolar == true ||
        this.energyProfile.waterHeatingGasStorage == true ||
        this.energyProfile.waterHeatingGasInstant == true ||
        this.energyProfile.waterHeatingGasSolar == true ||
        this.energyProfile.waterHeatingOther == true) {
      this.waterHeatingValidation = '';
    }
  }

  public checkboxValidation() {
    let validation = true;
    let errorMessage = 'Please select one or more options';
    if (this.energyProfile.spaceCoolingNone == false &&
        this.energyProfile.spaceCoolingRoomAC == false &&
        this.energyProfile.spaceCoolingSplitSystem == false &&
        this.energyProfile.spaceCoolingDuctedReverse == false &&
        this.energyProfile.spaceCoolingDuctedEvaporative == false &&
        this.energyProfile.spaceCoolingPortableRef == false &&
        this.energyProfile.spaceCoolingPortableEvap == false &&
        this.energyProfile.spaceCoolingOther == false && this.energyProfile.energyType == 'Electricity') {
      this.homeValidation = errorMessage;
      validation = false;
    }

    if (this.energyProfile.spaceHeatingNone == false &&
        this.energyProfile.spaceHeatingElectricDucted == false &&
        this.energyProfile.spaceHeatingSplitSystem == false &&
        this.energyProfile.spaceHeatingElectricUnderfloor == false &&
        this.energyProfile.spaceHeatingElectricIndividual == false &&
        this.energyProfile.spaceHeatingGasDucted == false &&
        this.energyProfile.spaceHeatingGasUnderfloor == false &&
        this.energyProfile.spaceHeatingGasIndividual == false &&
        this.energyProfile.spaceHeatingOther == false) {
      this.spaceValidation = errorMessage;
      validation = false;
    }

    if (this.energyProfile.poolHeating == "1") {
      if (this.energyProfile.poolHeatingNone == false &&
          this.energyProfile.poolHeatingSolar == false &&
          this.energyProfile.poolHeatingGas == false &&
          this.energyProfile.poolHeatingElectric == false && this.energyProfile.energyType == 'Electricity') {
        this.poolValidation = errorMessage;
        validation = false;
      }
    }

    if (this.energyProfile.waterHeatingElectric == false &&
        this.energyProfile.waterHeatingElectricSolar == false &&
        this.energyProfile.waterHeatingGasStorage == false &&
        this.energyProfile.waterHeatingGasInstant == false &&
        this.energyProfile.waterHeatingGasSolar == false &&
        this.energyProfile.waterHeatingOther == false) {
      this.waterHeatingValidation = errorMessage;
      validation = false;
    }

    return validation;
  }

  public createForm() {
    this.profileForm = this.formBuilder.group({
      hhSize : new FormControl('', [Validators.required, Validators.min(1)]),
      totalRooms : new FormControl('', [Validators.required, Validators.min(1)]),
      fridgeCount : new FormControl(''),
      gasConnection : new FormControl(''),
      poolHeating : new FormControl(''),
      poolHeatingSolar : new FormControl(''),
      poolHeatingGas : new FormControl(''),
      poolHeatingElectric : new FormControl(''),
      poolHeatingNone : new FormControl(''),
      spaceHeatingElectricDucted : new FormControl(''),
      spaceHeatingSplitSystem : new FormControl(''),
      spaceHeatingElectricUnderfloor : new FormControl(''),
      spaceHeatingElectricIndividual : new FormControl(''),
      spaceHeatingGasDucted : new FormControl(''),
      spaceHeatingGasUnderfloor : new FormControl(''),
      spaceHeatingGasIndividual : new FormControl(''),
      spaceHeatingOther : new FormControl(''),
      spaceHeatingNone : new FormControl(''),
      spaceCoolingRoomAC : new FormControl(''),
      spaceCoolingSplitSystem : new FormControl(''),
      spaceCoolingDuctedReverse : new FormControl(''),
      spaceCoolingDuctedEvaporative : new FormControl(''),
      spaceCoolingPortableRef : new FormControl(''),
      spaceCoolingPortableEvap : new FormControl(''),
      spaceCoolingOther : new FormControl(''),
      spaceCoolingNone : new FormControl(''),
      clothesDryer : new FormControl('', [Validators.required]),
      gasUsageWinter : new FormControl(''),
      clothesDryerWeekday : new FormControl(''),
      clothesDryerWeekend : new FormControl(''),
      waterHeatingElectric : new FormControl(''),
      waterHeatingElectricSolar : new FormControl(''),
      waterHeatingGasStorage : new FormControl(''),
      waterHeatingGasInstant : new FormControl(''),
      waterHeatingGasSolar : new FormControl(''),
      waterHeatingOther : new FormControl(''),
      controlledLoad : new FormControl(''),
      seaDistance : new FormControl(''),
      washingMachineUsage : new FormControl(''),
      washingMachineWeekday : new FormControl(''),
      washingMachineWeekend : new FormControl(''),
    });

    this.profileForm.controls['waterHeatingElectric'].valueChanges.subscribe(value => {
      this.controllLoadValidation = <FormControl>this.profileForm.get('controlledLoad');
      if (value == '0') {
        this.controllLoadValidation.setValidators(null);
      } else if (value == '1') {
        this.controllLoadValidation.setValidators([Validators.required]);
      }
      this.controllLoadValidation.updateValueAndValidity();
    });

    this.profileForm.controls['poolHeating'].valueChanges.subscribe(value => {
      if (value == 0) {
        this.energyProfile.poolHeatingSolar = false;
        this.energyProfile.poolHeatingGas = false;
        this.energyProfile.poolHeatingElectric = false;
        this.energyProfile.poolHeatingNone = false;
      }
    });

    this.profileForm.controls['clothesDryer'].valueChanges.subscribe(value => {
      if (value == 0) {
        this.energyProfile.clothesDryerWeekday = "";
        this.energyProfile.clothesDryerWeekend = "";
      }
    });

    this.profileForm.controls['waterHeatingElectricSolar'].valueChanges.subscribe(value => {
      this.controllLoadValidation = <FormControl>this.profileForm.get('controlledLoad');
      if (value == '0') {
        if (this.energyProfile.waterHeatingElectric == false) {
          this.controllLoadValidation.setValidators(null);
        }
      } else if (value == '1') {
        this.controllLoadValidation.setValidators([Validators.required]);
      }
      this.controllLoadValidation.updateValueAndValidity();
    });

    this.profileForm.controls['clothesDryer'].valueChanges.subscribe(value => {
      this.clothesWeekDayValidation = <FormControl>this.profileForm.get('clothesDryerWeekday');
      this.clothesWeekEndValidation = <FormControl>this.profileForm.get('clothesDryerWeekend');
      if (value == '0') {
        this.clothesWeekDayValidation.clearValidators();
        this.clothesWeekEndValidation.clearValidators();
      }
      this.clothesWeekDayValidation.updateValueAndValidity();
      this.clothesWeekEndValidation.updateValueAndValidity();
    });

    this.profileForm.controls['washingMachineUsage'].valueChanges.subscribe(value => {
      let washingMachineWeekDayValidation = <FormControl>this.profileForm.get('washingMachineWeekday');
      let washingMachineWeekEndValidation = <FormControl>this.profileForm.get('washingMachineWeekend');
      if (value == '0') {
        washingMachineWeekDayValidation.setValidators(null);
        washingMachineWeekEndValidation.setValidators(null);
      } else if (value == '1') {
        washingMachineWeekDayValidation.setValidators([Validators.required]);
        washingMachineWeekEndValidation.setValidators([Validators.required]);
      }
      washingMachineWeekDayValidation.updateValueAndValidity();
      washingMachineWeekEndValidation.updateValueAndValidity();
    });

  }

  public clearControllLoad() {
    this.energyProfile.controlledLoad = "";
  }

  public validateClothesDryer() {
    if (this.profileForm.controls['clothesDryer'].value == '1') {
      this.clothesWeekDayValidation.setValidators([Validators.required]);
      this.clothesWeekEndValidation.setValidators([Validators.required]);
      this.clothesWeekDayValidation.updateValueAndValidity();
      this.clothesWeekEndValidation.updateValueAndValidity();
    }
  }

  public saveData() {
    //remove offers saved
    let offers = { "offersList" : [] };
    this.dataService.setOffers(offers);
    if (this.energyProfile.gasConnection == '4') {
      this.energyProfile.spaceHeatingGasDucted = false;
      this.energyProfile.spaceHeatingGasUnderfloor = false;
      this.energyProfile.spaceHeatingGasIndividual = false;
      this.energyProfile.waterHeatingGasInstant = false;
      this.energyProfile.waterHeatingGasSolar = false;
      this.energyProfile.waterHeatingGasStorage = false;
      this.energyProfile.poolHeatingGas = false;
    }

    this.energyProfile.serverCacheId = localStorage.getItem("serverCacheId");
    this.validateClothesDryer();
    let validation = this.checkboxValidation();
    if (this.profileForm.status == 'VALID' && validation == true) {
        this.spinner.show();
        this.dataService.saveData(this.energyProfile).subscribe(
          data => {
            if (data.status == "success") {
              this.energyProfile.serverCacheId = data["serverCacheId"];
              localStorage.setItem("serverCacheId", data['serverCacheId']);
              this.utility.scrollToTop('none');
              if (this.bill == 'no bill') {
                if (this.energyProfile.energyType == 'Solar') {
                  this.router.navigate(['/solar-details']);
                } else {
                  this.router.navigate(['/offers']);
                  this.utility.showOffers(this.energyProfile.loopBack, this.energyProfile.energyType);
                }
              } else {
                this.utility.nextForm('bill-details', this.energyProfile.loopBack);
              }
            } else {
              this.spinner.hide();
              this.saveFormError = data.message;
              this.utility.scrollToTop('none');
            }
          },
          error => {
            this.spinner.hide();
            this.saveFormError = 'error';
            this.utility.scrollToTop('none');
          }
        );
    } else {
      this.profileForm.markAllAsTouched();
      this.utility.scrollToError();
    }
  }

  private initializeLoopBackValues() {
    if (this.router.url != '/') {
      for (var property in this.loopBackValues) {
        if (this.loopBackValues[property] != '') {
          this.energyProfile[property] = this.loopBackValues[property];
        }
      }
    }
  }

  public reAssignVariables(results) {
    let data;
    if (this.router.url == '/energy-questionnaire') {
      this.energyProfile.loopBack = false;
      data = results.energyProfileData;
      this.bill = results.energyConfigData.bill;
      this.dataService.changeEnergyType(results.energyConfigData.energyType);
      this.energyProfile.energyType = results.energyConfigData.energyType;
      this.energyProfile.hasSolar = results.energyConfigData.hasSolar;
      this.energyProfile.pvCapacity = results.energyConfigData.hasSolar;
      this.energyProfile.pvCapacityCap = results.energyConfigData.solarCapacity;
    } else {
      this.otherEnergyRetailer = results.energyConfigData.hasOwnProperty('retailer') ? results.energyConfigData.retailer : '';
      this.currentEnergyRetailer = results.loopBackData.energyConfigData.hasOwnProperty('retailer') ? results.loopBackData.energyConfigData.retailer : '';

      if (results.loopBackData.energyConfigData == undefined) {
        this.utility.redirectToHome('', true);
      }
      data = (results.loopBackData.energyProfileData.hasOwnProperty('hhSize') == false) ? results.energyProfileData : results.loopBackData.energyProfileData;
      this.energyProfile.loopBack = true;
      this.bill = results.loopBackData.energyConfigData.bill;
      this.dataService.changeEnergyType(results.loopBackData.energyConfigData.energyType);
      this.energyProfile.energyType = results.loopBackData.energyConfigData.energyType;
      this.energyProfile.hasSolar = results.loopBackData.energyConfigData.hasSolar;
      this.energyProfile.pvCapacity = results.loopBackData.energyConfigData.hasSolar;
      this.energyProfile.pvCapacityCap = results.loopBackData.energyConfigData.solarCapacity;
      this.energyProfile.controlledLoad = (results.hasControlledLoad == true) ? '1' : '0';
    }
    if (this.energyProfile.energyType != 'Gas' && this.energyProfile.hasSolar == '1') {
      let seaDistanceValidation = <FormControl>this.profileForm.get('seaDistance');
      let washingMachineUsageValidation = <FormControl>this.profileForm.get('washingMachineUsage');
      seaDistanceValidation.setValidators([Validators.required]);
      washingMachineUsageValidation.setValidators([Validators.required]);
      seaDistanceValidation.updateValueAndValidity();
      washingMachineUsageValidation.updateValueAndValidity();
    }

    if (this.energyProfile.energyType != 'Gas') {
      let fridgeCountValidation = <FormControl>this.profileForm.get('fridgeCount');
      let gasConnectionValidation = <FormControl>this.profileForm.get('gasConnection');
      let poolHeatingValidation = <FormControl>this.profileForm.get('poolHeating');
      fridgeCountValidation.setValidators([Validators.required]);
      gasConnectionValidation.setValidators([Validators.required]);
      poolHeatingValidation.setValidators([Validators.required]);
      fridgeCountValidation.updateValueAndValidity();
      gasConnectionValidation.updateValueAndValidity();
      poolHeatingValidation.updateValueAndValidity();
    }

    if (data.hasOwnProperty('hhSize') == true) {
      this.energyProfile.hhSize = data.hhSize;
      this.energyProfile.totalRooms = data.totalRooms;
      this.energyProfile.fridgeCount = data.fridgeCount;
      this.energyProfile.gasConnection = data.gasConnection;
      this.energyProfile.poolHeating = data.poolHeating;
      this.energyProfile.poolHeatingSolar = data.poolHeatingSolar;
      this.energyProfile.poolHeatingGas = data.poolHeatingGas;
      this.energyProfile.poolHeatingElectric = data.poolHeatingElectric;
      this.energyProfile.poolHeatingNone = data.poolHeatingNone;
      this.energyProfile.spaceHeatingElectricDucted = data.spaceHeatingElectricDucted;
      this.energyProfile.spaceHeatingSplitSystem = data.spaceHeatingSplitSystem;
      this.energyProfile.spaceHeatingElectricUnderfloor = data.spaceHeatingElectricUnderfloor;
      this.energyProfile.spaceHeatingElectricIndividual = data.spaceHeatingElectricIndividual;
      this.energyProfile.spaceHeatingGasDucted = data.spaceHeatingGasDucted;
      this.energyProfile.spaceHeatingGasUnderfloor = data.spaceHeatingGasUnderfloor;
      this.energyProfile.spaceHeatingGasIndividual = data.spaceHeatingGasIndividual;
      this.energyProfile.spaceHeatingOther = data.spaceHeatingOther;
      this.energyProfile.spaceHeatingNone = data.spaceHeatingNone;
      this.energyProfile.spaceCoolingRoomAC = data.spaceCoolingRoomAC;
      this.energyProfile.spaceCoolingSplitSystem = data.spaceCoolingSplitSystem;
      this.energyProfile.spaceCoolingDuctedReverse = data.spaceCoolingDuctedReverse;
      this.energyProfile.spaceCoolingDuctedEvaporative = data.spaceCoolingDuctedEvaporative;
      this.energyProfile.spaceCoolingPortableRef = data.spaceCoolingPortableRef;
      this.energyProfile.spaceCoolingPortableEvap = data.spaceCoolingPortableEvap;
      this.energyProfile.spaceCoolingOther = data.spaceCoolingOther;
      this.energyProfile.spaceCoolingNone = data.spaceCoolingNone;
      this.energyProfile.clothesDryer = data.clothesDryer;
      this.energyProfile.gasUsageWinter = data.gasUsageWinter;
      this.energyProfile.clothesDryerWeekday = data.clothesDryerWeekday;
      this.energyProfile.clothesDryerWeekend = data.clothesDryerWeekend;
      this.energyProfile.dishwasherWeekday = data.dishwasherWeekday;
      this.energyProfile.dishwasherWeekend = data.dishwasherWeekend;
      this.energyProfile.waterHeatingElectric = data.waterHeatingElectric;
      this.energyProfile.waterHeatingElectricSolar = data.waterHeatingElectricSolar;
      this.energyProfile.waterHeatingGasStorage = data.waterHeatingGasStorage;
      this.energyProfile.waterHeatingGasInstant = data.waterHeatingGasInstant;
      this.energyProfile.waterHeatingGasSolar = data.waterHeatingGasSolar;
      this.energyProfile.waterHeatingOther = data.waterHeatingOther;
      this.energyProfile.seaDistance = data.seaDistance;
      this.energyProfile.washingMachineUsage = data.washingMachineUsage;
      this.energyProfile.washingMachineWeekday = data.washingMachineWeekday;
      this.energyProfile.washingMachineWeekend = data.washingMachineWeekend;
      if (this.router.url == '/energy-questionnaire') {
        this.energyProfile.controlledLoad = data.controlledLoad;
      }
    }

    if (this.otherEnergyRetailer != this.currentEnergyRetailer) {
      this.energyProfile.gasConnection = '1';
      let gasConnectionValidation = <FormControl>this.profileForm.get('gasConnection');
      gasConnectionValidation.setValidators(null);
      gasConnectionValidation.updateValueAndValidity();
    }

  }

  public initializeForm() {
    this.createForm();
    this.modalService.dismissAll(); //close modal dialog if there are any open
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheID is defined get saved data from the server
    if (serverCacheId != null) {
      this.energyProfile.serverCacheId = serverCacheId;
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.existing == false) {
            this.utility.sessionRestart();
            this.utility.removeServerCacheId(serverCacheId);
            this.utility.redirectToHome('', true);
            return false;
            //this.energyProfile = this.dataService.getEnergyConfigData();
          } else {
            if (results.energyConfigData.energyType == '' || results.energyConfigData.energyType == undefined) {
              //redirect to home page
              this.utility.redirectToHome('', true);
              return false;
            }
            this.reAssignVariables(results);
          }
          this.spinner.hide();
        },
        error => {
          //get form data from dataservice
          this.spinner.hide();
          this.utility.redirectToHome();
        }
      );
    } else {
      this.utility.redirectToHome();
    }
  }

}
