<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails"
        [hideTips]="bannerHideTips"
        [textDirection]="bannerTextDirection">
    </app-banner>
    <br />
    <div class="row {{pageLanguage}}-content">

        <div class="col-md-12 col-xs-12" [innerHTML]="pageContents">

        </div>

        <div *ngIf="pageName == 'vec-faqs'">
            <h4 [innerHTML]="FAQContent.lbl_welcome_about_q1"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_about_a1" class="pad"></p>
            <p>&nbsp;</p>

            <!-- VIDEO -->
            <div class="video-container">
                <iframe [src]="videoFile" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <!-- //VIDEO -->

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q1"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a1" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q2"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a2" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q3"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a3" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q4"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a4" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q5"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a5" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q6"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a6" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q7"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a7" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q8"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a8" class="pad"></p>

            <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q9"></h4>
            <p [innerHTML]="FAQContent.lbl_welcome_faq_a9" class="pad"></p>

            <div *ngIf="language == 'en'">

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q10"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a10" class="pad"></p>

                <!-- <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q11"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a11" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q12"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a12" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q13"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a13" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q14"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a14" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q15"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a15" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q16"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a16" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q17"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a17" class="pad"></p> -->

                <h4 [innerHTML]="FAQContent.lbl_welcome_faq_q18"></h4>
                <p [innerHTML]="FAQContent.lbl_welcome_faq_a18" class="pad"></p>

                <br />
                <h3 [innerHTML]="FAQContent.lbl_profile_about_q1"></h3>
                <p [innerHTML]="FAQContent.lbl_profile_about_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_profile_faq_q1"></h4>
                <p [innerHTML]="FAQContent.lbl_profile_faq_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_profile_faq_q2"></h4>
                <p [innerHTML]="FAQContent.lbl_profile_faq_a2" class="pad" ></p>

                <h4 [innerHTML]="FAQContent.lbl_profile_faq_q3"></h4>
                <p [innerHTML]="FAQContent.lbl_profile_faq_a3" class="pad"></p>


                <h3 [innerHTML]="FAQContent.lbl_consumption_about_q1"></h3>
                <p [innerHTML]="FAQContent.lbl_consumption_about_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_consumption_faq_q1"></h4>
                <p [innerHTML]="FAQContent.lbl_consumption_faq_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_consumption_faq_q2"></h4>
                <p [innerHTML]="FAQContent.lbl_consumption_faq_a2" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_consumption_faq_q3"></h4>
                <p [innerHTML]="FAQContent.lbl_consumption_faq_a3" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_consumption_faq_q4"></h4>
                <p [innerHTML]="FAQContent.lbl_consumption_faq_a4" class="pad"></p>


                <h3 [innerHTML]="FAQContent.lbl_results_about_q1"></h3>
                <p [innerHTML]="FAQContent.lbl_results_about_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_results_faq_q1"></h4>
                <p [innerHTML]="FAQContent.lbl_results_faq_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_results_faq_q2"></h4>
                <p [innerHTML]="FAQContent.lbl_results_faq_a2" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_results_faq_q3"></h4>
                <p [innerHTML]="FAQContent.lbl_results_faq_a3" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_results_faq_q4"></h4>
                <p [innerHTML]="FAQContent.lbl_results_faq_a4" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_results_faq_q5"></h4>
                <p [innerHTML]="FAQContent.lbl_results_faq_a5" class="pad"></p>


                <h3 [innerHTML]="FAQContent.lbl_compare_about_q1"></h3>
                <p [innerHTML]="FAQContent.lbl_compare_about_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_compare_faq_q1"></h4>
                <p [innerHTML]="FAQContent.lbl_compare_faq_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_compare_faq_q2"></h4>
                <p [innerHTML]="FAQContent.lbl_compare_faq_a2" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_compare_faq_q3"></h4>
                <p [innerHTML]="FAQContent.lbl_compare_faq_a3" class="pad"></p>


                <h3 [innerHTML]="FAQContent.lbl_contact_about_q1"></h3>
                <p [innerHTML]="FAQContent.lbl_contact_about_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_contact_faq_q1"></h4>
                <p [innerHTML]="FAQContent.lbl_contact_faq_a1" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_contact_faq_q2"></h4>
                <p [innerHTML]="FAQContent.lbl_contact_faq_a2" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_contact_faq_q3"></h4>
                <p [innerHTML]="FAQContent.lbl_contact_faq_a3" class="pad"></p>

                <h3 [innerHTML]="FAQContent.lbl_solar_about_q1"></h3>
                <p [innerHTML]="FAQContent.lbl_solar_about_a1" class="pad"></p>

                <h3 [innerHTML]="FAQContent.lbl_enquiries_header"></h3>

                <h4 [innerHTML]="FAQContent.lbl_contact_faq_q4"></h4>
                <p [innerHTML]="FAQContent.lbl_contact_faq_a4" class="pad"></p>

                <h4 [innerHTML]="FAQContent.lbl_contact_faq_q5"></h4>
                <p [innerHTML]="FAQContent.lbl_contact_faq_a5" class="pad"></p>
            </div>

        </div>
    </div>
</div>
<br /><br />
