import { Component, OnInit } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillDetailsForm } from '../billDetailsForm';
import { Utility } from '../utility';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class BillDetailsComponent implements OnInit {
  public bannerTitle = "Your Energy Usage";
  public bannerSubTitle = "";
  public bannerDetails = "Enter a few details from a recent bill to find the best offers for you.";

  constructor(public billDetailsForm : BillDetailsForm,
    public config : NgbModalConfig,
    public modalService : NgbModal,
    public utility : Utility,
    private tooltipConfig : NgbTooltipConfig) {

    }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.modalService.dismissAll(); //close modal dialog if there are any open
    document.title = 'Consumption - Victorian Energy Compare';
    this.billDetailsForm.initializeForm();
  }

}
