<div class="modal-header" class="button">
    <div class="row col-md-12">
        <div class="col-md-12 modal-icons">
            <button class="hidden-btn"></button>
            <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close()">
                <i class="fa fa-times" aria-hidden="true"></i> close
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="container col-md-12">
        <p class="psb3-title">
            The Victorian Government has announced a new <span class="strong">$250 Power Saving Bonus for Victorian 
            Households</span>. To receive an invitation to apply when the program opens, please 
            provide your information below.
        </p>
        <br /><br />
        <form method="post" id="rateForm" [formGroup]="registrationForm" class="col-md-12" onsubmit="return false" (keydown.enter)="$event.preventDefault()">
            <div class="alert alert-danger" *ngIf="status == 'error'">
                An error has occured while trying to ave the data, please try again later.
                <br />
            </div>
            <div class="alert alert-success" *ngIf="status == 'success'">
                Thank you for submitting your information. We will be in contact when the Bonus opens for applications.
                <br />
            </div>

            <div *ngIf="status == ''">
                <div class="row">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <label for="name" class="strong">Name</label> *
                            <input type="text" formControlName="name" [(ngModel)]="userData.name" class="form-control custom-input-text" (keydown)="keypress($event)">
                            <bfv-messages></bfv-messages>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <span class="strong">Email Address</span>
                            <label for="email" class="hidden">Email or Mobile Number</label>
                            <input type="text" formControlName="email" (keydown.space)="$event.preventDefault();" [(ngModel)]="userData.email" class="form-control custom-input-text" (keydown)="keypress($event)">
                            <bfv-messages></bfv-messages>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-11 col-md-11 col-xs-12">
                        <div class="form-group">
                            <label for="mobile" class="strong">Mobile Number</label>
                            <input type="text" formControlName="mobile" [(ngModel)]="userData.mobile" class="form-control custom-input-text" (keydown)="keypress($event)">
                            <bfv-messages></bfv-messages>
                        </div>
                    </div>
                </div>

                <div class="row custom-padding">
                    <div class="col-md-12">
                        <button type="button" class="btn custom-button btn-lg" (click)="register()">Submit</button>
                        <button type="button" class="btn cancel-button btn-danger btn-lg" (click)="activeModal.close()">Cancel</button>
                    </div>
                </div>
                <br />
                <p>
                    If you do not wish to provide your information, please revisit Victorian Energy Compare on 1 July 2022 to apply.
                </p>
                <br />
            </div>

        </form>
    </div>
</div>
