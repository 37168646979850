import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';

@Component({
  selector: 'app-psb-registration-modal',
  templateUrl: './psb-registration-modal.component.html',
  styleUrls: ['./psb-registration-modal.component.css']
})
export class PsbRegistrationModalComponent implements OnInit {
  public status = '';
  public userData = {
    name : '',
    email : '',
    mobile : '',
  }
  public registrationForm : FormGroup;

  constructor(public spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    public dataService : DataService,
    public formBuilder : FormBuilder) { }

  ngOnInit() {
    this.createForm();
  }

  public createForm() {
    this.registrationForm = this.formBuilder.group({
      name : new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 \'\-]+$'),
      ]),
      email : new FormControl(''),
      mobile : new FormControl('')
    });

    let mobileValidation = <FormControl>this.registrationForm.get('mobile');
    mobileValidation.setValidators([
      this.validatePhoneNumber.bind(this) //custom javascript validation function
    ]);
    let emailValidation = <FormControl>this.registrationForm.get('email');
    emailValidation.setValidators([
      this.emailWithWhitespaceValidator.bind(this) //custom javascript validation function
    ]);

  }

  public emailWithWhitespaceValidator = (control : FormControl) : ValidationErrors => {
    let email = control.value;
    if (email != '') {
      let isValid = control.value.trim().match('^.+@.+\..+$');
      if (isValid) {
        return null;
      } else {
        return { 'emailFormat' : true } //return error message
      }
    } else {
      return null;
    }
  }


  /**
  * Validate the phone number format
  * @param group Form group used for validation
  */
  public validatePhoneNumber = (control : FormControl) : ValidationErrors => {
    let ausPhoneRegex: RegExp = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?([0-9\s-]{8,})$/;
    let phone = control.value;
    if (phone && phone.length > 0 && (!ausPhoneRegex.test(phone) || phone.replace(/^\+61/g, '0').replace(/[^0-9]/g, '').length !== 10)) {
      return { 'AUNumber' : true } //return error message
    }
    return null; //mobile number is valid
  }

  public register() {
    let emailValidation = <FormControl>this.registrationForm.get('email');
    if (this.userData.email == '' && this.userData.mobile == '') {
      emailValidation.setValidators([
        Validators.required,
        this.emailWithWhitespaceValidator.bind(this) //custom javascript validation function
      ]);
    } else if (this.userData.email != '' || this.userData.mobile != '') {
      emailValidation.setValidators([this.emailWithWhitespaceValidator.bind(this)]);
    }
    emailValidation.updateValueAndValidity();
    this.registrationForm.markAllAsTouched();
    Object.keys(this.registrationForm.controls).forEach(key => {
      this.registrationForm.get(key).markAsDirty();
    });


    if (this.registrationForm.status == 'VALID') {
      this.spinner.show();
      //this.userData.serverCacheId = serverCacheId;
      this.dataService.psb3Registration(this.userData.name, this.userData.email, this.userData.mobile, localStorage.getItem("serverCacheId")).subscribe(
        results => {
          this.spinner.hide();
          this.status = results['status'];
        },
        error => {
          this.spinner.hide();
        }
      );
    }

  }

  public keypress(event) {
    if (event.keyCode == 13) { //enter
      this.register();
    }
  }

}
