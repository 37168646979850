import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from './data.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { Utility } from './utility';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class BillDetailsForm {
  public saveFormError = '';
  public multiMaxConsumption = 0;
  public gasMaxAnnualConsumptionMJ = 1000; // if business 2000, else 1000
  public dailyMaxConsumption = 0;
  private errorTimeout = 4000;
  public gasUsageErrors = [];
  public showElectricityForms = false;
  public showSolarForms = false;
  public showGasForms = false;

  public gasErrorMessage = '';
  public gasBillCtr = 1;
  public gasBillLimit = 6;
  public allConsumption = 0;
  public usageMaxConsumption = 0;
  private errorIcon = '<i class="material-icons button-icons small-tooltip">warning</i>';
  public gasFromDate = [];
  public gasToDate = [];

  public currentDate = undefined;
  public minDate = undefined;
  public maxDate = undefined;
  public hoveredDate: NgbDate | null = null;
  public solarHoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public solarFromDate: NgbDate | null;
  public solarToDate: NgbDate | null;
  public billDetailsForm: FormGroup;
  public gasMaxConsumption;
  public electricityMaxConsumption;
  public isMobile = false;
  public solarCapacity = 0;
  //$solarExportMax = $solar instanceof BilledSolarInfo ? $solar->capacity() * 90 * 10 : 90000;

  public billForm = {
    gasBillStartDate: '',
    gasBillEndDate: '',
    gasUsage: ''
  };
  public billForms = [
    {
      gasBillStartDate: '',
      gasBillEndDate: '',
      gasUsage: ''
    }
  ];

  public billDetails = {
    electricityBillStartDate: "",
    electricityBillEndDate: "",
    gasBillStartDate: '',
    gasBillEndDate: '',
    elecUsage: 0,
    gasUsage: 0,
    solarGeneration: "",
    solarStartDate: "",
    solarEndDate: "",
    serverCacheId: "",
    pageDataType: "billDetailsData",
    userType: "",
    tariffType: "single_rate",
    energyType: "",
    hasSolar: "0",
    billForms: this.billForms,
    loopBack: false
  }

  constructor(public dataService: DataService,
    private deviceService: DeviceDetectorService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public router: Router,
    public utility: Utility,
    public config: NgbModalConfig,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    private tooltipConfig: NgbTooltipConfig) {
    this.currentDate = new Date();
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 2,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
  }

  public isValidDate = (control: FormControl): ValidationErrors => {
    var dateValue = control.value;
    if (dateValue == '') { // 1
      return { 'required': true } //return required error
    } else if (/^((0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/.test(dateValue) == false) {
      return { 'pattern': true } //return pattern error
    } else {
      //check dates if its within the allowed dates
      dateValue = dateValue.split("/");
      let unixDateValue = new Date(dateValue[2], dateValue[1], dateValue[0]).getTime() / 1000;
      let dateStartLimit = new Date(this.currentDate.getFullYear() - 2, this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
      let dateEndLimit = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
      if (unixDateValue > dateEndLimit) {
        return { 'dateForward': true } //return pattern error
      }
      if (unixDateValue < dateStartLimit) {
        return { 'dateBackward': true } //return pattern error
      }
    }
    return null;
  }

  public removeGasForm(index) {
    this.billForms.splice(index, 1);
    this.gasBillCtr--;
    this.gasFromDate[index] = null;
    this.gasToDate[index] = null;
    this.billDetailsForm.removeControl('gasBillStartDate' + index);
    this.billDetailsForm.removeControl('gasBillEndDate' + index);
    this.billDetailsForm.removeControl('gasUsage' + index);
  }

  public addNewForm() {
    let maxGasBlls = 6;
    if (this.gasBillCtr < maxGasBlls) {
      if (this.validateBills() && this.billDetailsForm.status == 'VALID') {
        if (this.billForms.length < this.gasBillLimit) {
          var validation = true;
          //check if there are empty fields
          let ctr = 0;
          while (ctr < this.billForms.length) {
            if (this.billForms[ctr].gasUsage == '' || this.billForms[ctr].gasBillStartDate == '' || this.billForms[ctr].gasBillEndDate == '') {
              this.gasErrorMessage = this.errorIcon + ' Please enter the values of all fields before adding a new bill.';
              validation = false;
              this.utility.gasErrors();
              this.resetGasError();
              return validation;
            }
            ctr++;
          }

          if (this.allConsumption > this.usageMaxConsumption) {
            this.gasErrorMessage = this.errorIcon + ' Please update the values of your average daily usage fields before adding a new bill.';
            validation = false;
            this.utility.gasErrors();
            this.resetGasError();
            return validation;
          }
        }
        this.gasBillCtr++;
        this.billDetailsForm.addControl('gasBillStartDate' + this.gasBillCtr, new FormControl(''));
        this.billDetailsForm.addControl('gasBillEndDate' + this.gasBillCtr, new FormControl(''));
        this.billDetailsForm.addControl('gasUsage' + this.gasBillCtr, new FormControl('', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]));
        let gasBillStartDateValidation = <FormControl>this.billDetailsForm.get('gasBillStartDate' + this.gasBillCtr);
        let gasBillEndDateValidation = <FormControl>this.billDetailsForm.get('gasBillEndDate' + this.gasBillCtr);
        gasBillStartDateValidation.setValidators([this.isValidDate.bind(this)]);
        gasBillEndDateValidation.setValidators([this.isValidDate.bind(this)]);

        let newBill = JSON.stringify(this.billForm);
        this.billDetails.billForms.push(JSON.parse(newBill));
      } else {
        this.showFormErrors();
      }
    } else {
      this.gasErrorMessage = this.errorIcon + ' Maximum of ' + maxGasBlls + ' bills reached.';
      this.utility.gasErrors();
      this.resetGasError();
      return false;
    }
  }

  private resetGasError() {
    this.errorTimeout += 4000;
    setTimeout(() => {
      this.gasErrorMessage = '';
      this.errorTimeout = 4000;
    }, this.errorTimeout);
  }

  public validateGas(i) {
    if (parseInt(this.billForms[i].gasUsage) * 365 > 1000 * this.dailyMaxConsumption) {
      return true;
    } else {
      return false;
    }
  }

  private validateGasUsageConsumption() {
    let validation = true;
    let oneYearComparison = 365;
    let numberOfDays = 0;
    this.usageMaxConsumption = 1000 * this.dailyMaxConsumption;
    let ctr = 0;
    let allDays = 0;
    let allUsage = 0;
    let collectionOfDays = [];
    let usages = [];
    let averageUsage = 0;
    while (ctr < this.billForms.length) {
      if ((this.billForms[ctr].gasBillStartDate != '' && this.billForms[ctr].gasBillEndDate != '') &&
        (this.billForms[ctr].gasUsage != '' && !isNaN(parseInt(this.billForms[ctr].gasUsage)))) {
        let startDate: any = this.billForms[ctr].gasBillStartDate.split("/");
        let endDate: any = this.billForms[ctr].gasBillEndDate.split("/");
        let billStartDate: any = new Date(startDate[2], startDate[1] - 1, startDate[0]);
        let billEndDate: any = new Date(endDate[2], endDate[1] - 1, endDate[0]);
        if (billStartDate.toString() != 'Invalid Date' && billEndDate.toString() != 'Invalid Date') {
          let dateDifference: any = new Date(billEndDate - billStartDate);
          let days = Math.round(dateDifference / 1000 / 60 / 60 / 24);
          allDays += days;
          let currentUsage = parseInt(this.billForms[ctr].gasUsage) * 1;
          allUsage += currentUsage;
          usages.push(currentUsage);
          collectionOfDays.push(days);
        }
      }
      ctr++;
    }
    var tmp = 0;
    while (tmp < usages.length) {
      averageUsage += Math.round(usages[tmp] * (collectionOfDays[tmp] / allDays));
      tmp++;
    }
    numberOfDays = allDays;
    this.allConsumption = averageUsage * numberOfDays;
    //add option for 2 years
    let projectedDays = (numberOfDays > 365) ? oneYearComparison * 2 : oneYearComparison;
    //compute the projected consumption
    let projectedConsumption = averageUsage * projectedDays;

    //if the duration is more than a year double the usageMaxConsumption
    this.usageMaxConsumption = (numberOfDays > oneYearComparison) ? (this.usageMaxConsumption * 2) : this.usageMaxConsumption;
    if (this.allConsumption > this.usageMaxConsumption || projectedConsumption > this.usageMaxConsumption) {
      ctr = 0;
      while (ctr < this.billForms.length) {
        if (parseInt(this.billForms[ctr].gasUsage) > this.gasMaxConsumption) {
          validation = false;
          this.gasUsageErrors[ctr] = true;
        } else {
          this.gasUsageErrors[ctr] = false;
        }
        ctr++;
      }
    } else {
      this.gasUsageErrors = [];
    }
    return validation;
  }

  private validateBills() {
    let validation = true;
    let errors = [];
    let invalidErrors = [];
    let consecutiveErrors = [];

    const validateDateRange = (startDate, endDate, index) => {
      if (this.dateToLinuxTime(startDate) > this.dateToLinuxTime(endDate)) {
        invalidErrors.push(index);
        validation = false;
      }
    };

    const validateConflicts = (startDate, endDate, index, startDates, endDates) => {
      for (let i = 0; i < startDates.length; i++) {
        if (
          (startDate >= startDates[i] && startDate < endDates[i]) ||
          (endDate > startDates[i] && endDate <= endDates[i]) ||
          (startDate < startDates[i] && endDate > endDates[i])
        ) {
          errors.push(index);
          validation = false;
        }
      }
    };

    const validateConsecutiveness = (startDate, prevEndDate, index) => {
      let expectedStartDate = new Date(prevEndDate);
      expectedStartDate.setDate(expectedStartDate.getDate() + 1);
      if (startDate.getTime() !== expectedStartDate.getTime()) {
        consecutiveErrors.push(index);
        validation = false;
      }
    };

    const displayErrors = (errorList, message) => {
      for (let i = 0; i < errorList.length; i++) {
        this.gasErrorMessage = this.errorIcon + message;
        this.clearGasError();
      }
      this.utility.gasErrors();
    };

    // Sort bills by start date
    const sortedBillForms = this.billForms.slice().sort((a, b) => {
      return this.parseDate(a.gasBillStartDate).getTime() - this.parseDate(b.gasBillStartDate).getTime();
    });

    if (sortedBillForms.length === 1) {
      let startDate = this.parseDate(sortedBillForms[0].gasBillStartDate);
      let endDate = this.parseDate(sortedBillForms[0].gasBillEndDate);
      validateDateRange(startDate, endDate, 0);

      if (!validation) {
        displayErrors(invalidErrors, ' Invalid date range. Please enter an end date after the start date.');
      }
    } else if (sortedBillForms.length > 1) {
      let startDates = [this.parseDate(sortedBillForms[0].gasBillStartDate)];
      let endDates = [this.parseDate(sortedBillForms[0].gasBillEndDate)];

      for (let ctr = 1; ctr < sortedBillForms.length; ctr++) {
        if (!sortedBillForms[ctr].gasBillEndDate || !sortedBillForms[ctr].gasBillStartDate) {
          this.gasErrorMessage = this.errorIcon + ' Please enter bill start and end date.';
          this.clearGasError();
          this.utility.gasErrors();
          return false;
        }

        let startDate = this.parseDate(sortedBillForms[ctr].gasBillStartDate);
        let endDate = this.parseDate(sortedBillForms[ctr].gasBillEndDate);

        validateDateRange(startDate, endDate, ctr);
        validateConflicts(startDate, endDate, ctr, startDates, endDates);
        validateConsecutiveness(startDate, endDates[ctr - 1], ctr);

        startDates.push(startDate);
        endDates.push(endDate);
      }

      if (consecutiveErrors.length > 0 || errors.length > 0) {
        displayErrors(consecutiveErrors, ' Gas bills must be consecutive. Ensure the end date of one is the day before the start date of the next.');
      }

      if (invalidErrors.length > 0) {
        displayErrors(invalidErrors, ' Invalid date range. Please enter an end date after the start date.');
      }
    }

    return validation;
  }



  // Helper function to parse a date string into a Date object
  private parseDate(dateString) {
    let parts = dateString.split("/");
    // Assume the format is dd/mm/yyyy
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript Date
    let year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }


  private dateToLinuxTime(date) {
    date = new Date(date);
    return Math.floor(date.getTime() / 1000);
  }

  private clearGasError() {
    setTimeout(() => {
      this.gasErrorMessage = '';
    }, 7000);
  }

  public createForm() {
    if (this.billDetails.energyType != 'Gas') {
      this.billDetailsForm = this.formBuilder.group({
        billStartDate: new FormControl(''),
        billEndDate: new FormControl(''),
        elecUsage: new FormControl(''),
        tariffType: new FormControl(''),
      });
      let billStartDateValidation = <FormControl>this.billDetailsForm.get('billStartDate');
      let billEndDateValidation = <FormControl>this.billDetailsForm.get('billEndDate');
      billStartDateValidation.setValidators([this.isValidDate.bind(this)]);
      billEndDateValidation.setValidators([this.isValidDate.bind(this)]);

      if (this.billDetails.hasSolar == '1') {
        this.billDetailsForm.addControl('solarStartDate', new FormControl(''));
        this.billDetailsForm.addControl('solarEndDate', new FormControl(''));
        let solarGenerationLimit = this.solarCapacity * 90 * 10;
        this.billDetailsForm.addControl('solarGeneration', new FormControl('', [
          Validators.min(1),
          Validators.max(solarGenerationLimit),
          Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
        ]
        ));
        let solarStartDateDateValidation = <FormControl>this.billDetailsForm.get('solarStartDate');
        let solarEndDateValidation = <FormControl>this.billDetailsForm.get('solarEndDate');
        solarStartDateDateValidation.setValidators([this.isValidDate.bind(this)]);
        solarEndDateValidation.setValidators([this.isValidDate.bind(this)]);
      }

    } else {
      this.billDetailsForm = this.formBuilder.group({
        gasBillStartDate0: new FormControl(''),
        gasBillEndDate0: new FormControl(''),
        gasUsage0: new FormControl('', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]),
      });
      let gasBillStartDateValidation = <FormControl>this.billDetailsForm.get('gasBillStartDate0');
      let gasBillEndDateValidation = <FormControl>this.billDetailsForm.get('gasBillEndDate0');
      gasBillStartDateValidation.setValidators([this.isValidDate.bind(this)]);
      gasBillEndDateValidation.setValidators([this.isValidDate.bind(this)]);
    }
  }

  public format(date): string {
    return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
  }

  public gasFormat(date): string {
    return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
  }

  public solarFormat(date): string {
    return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
  }

  public onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.billDetails.electricityBillStartDate = this.format(date);
      if (this.billDetails.solarStartDate == '' && this.billDetails.hasSolar == '1') {
        this.billDetails.solarStartDate = this.format(date);
      }
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.billDetails.electricityBillEndDate = this.format(date);
      if (this.billDetails.solarEndDate == '' && this.billDetails.hasSolar == '1') {
        this.billDetails.solarEndDate = this.format(date);
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.billDetails.electricityBillStartDate = this.format(date);
      this.billDetails.electricityBillEndDate = '';
      if (this.billDetails.solarStartDate == '' && this.billDetails.hasSolar == '1') {
        this.billDetails.solarStartDate = this.format(date);
      }
      if (this.billDetails.solarEndDate == '' && this.billDetails.hasSolar == '1') {
        this.billDetails.solarEndDate = '';
      }
    }

    if (this.fromDate != null && this.toDate != null) {
      datepicker.close();
    }
  }

  public onSolarDateSelection(date: NgbDate, datepicker: any) {
    if (!this.solarFromDate && !this.solarToDate) {
      this.solarFromDate = date;
      this.billDetails.solarStartDate = this.format(date);
    } else if (this.solarFromDate && !this.solarToDate && date && date.after(this.solarFromDate)) {
      this.solarToDate = date;
      this.billDetails.solarEndDate = this.format(date);
    } else {
      this.solarToDate = null;
      this.solarFromDate = date;
      this.billDetails.solarStartDate = this.format(date);
      this.billDetails.solarEndDate = '';
    }

    if (this.solarFromDate != null && this.solarToDate != null) {
      datepicker.close();
    }
  }

  public onGasDateSelection(date: NgbDate, index, datepicker: any) {
    if (!this.gasFromDate[index] && !this.gasToDate[index]) {
      this.gasFromDate[index] = date;
      this.billForms[index].gasBillStartDate = this.format(date);
    } else if (this.gasFromDate[index] && !this.gasToDate[index] && date && date.after(this.gasFromDate[index])) {
      this.gasToDate[index] = date;
      this.billForms[index].gasBillEndDate = this.format(date);
    } else {
      this.gasToDate[index] = null;
      this.gasFromDate[index] = date;
      this.billForms[index].gasBillStartDate = this.format(date);
      this.billForms[index].gasBillEndDate = '';
    }

    if (this.gasToDate[index] != null && this.gasFromDate[index] != null) {
      datepicker.close();
    }

  }

  public isDisabled(date: NgbDate) {
    let unixDateValue = new Date(date.year, date.month, date.day).getTime() / 1000;
    let dateStartLimit = new Date(this.currentDate.getFullYear() - 2, this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
    let dateEndLimit = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
    if (unixDateValue > dateEndLimit || unixDateValue < dateStartLimit) {
      return true;
    } else {
      return false;
    }
  }

  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public solarIsHovered(date: NgbDate) {
    return this.solarFromDate && !this.solarToDate && this.solarHoveredDate && date.after(this.solarFromDate) && date.before(this.solarHoveredDate);
  }

  public solarIsInside(date: NgbDate) {
    return this.solarToDate && date.after(this.solarFromDate) && date.before(this.solarToDate);
  }

  public solarIsRange(date: NgbDate) {
    return date.equals(this.solarFromDate) || (this.solarToDate && date.equals(this.solarToDate)) || this.solarIsInside(date) || this.solarIsHovered(date);
  }

  public validateSolarInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public gasIsHovered(date: NgbDate, index) {
    return this.gasFromDate[index] && !this.gasToDate[index] && this.hoveredDate && date.after(this.gasFromDate[index]) && date.before(this.hoveredDate);
  }

  public gasIsInside(date: NgbDate, index) {
    return this.gasToDate[index] && date.after(this.gasFromDate[index]) && date.before(this.gasToDate[index]);
  }

  public gasIsRange(date: NgbDate, index) {
    return date.equals(this.gasFromDate[index]) || (this.gasToDate[index] && date.equals(this.gasToDate[index])) || this.gasIsInside(date, index) || this.gasIsHovered(date, index);
  }

  public validateGasInput(currentValue, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public saveData() {
    //remove offers saved
    let offers = { "offersList": [] };
    this.dataService.setOffers(offers);
    if (this.billDetails.energyType == 'Gas') {
      //validate multiple gas bills
      if (!this.validateBills()) {
        return false;
      }

      if (this.validateGasUsageConsumption() != true) {
        return false;
      }
    }

    this.billDetails.serverCacheId = localStorage.getItem("serverCacheId");
    if (this.billDetailsForm.status == 'VALID') {
      this.spinner.show();
      this.dataService.saveData(this.billDetails).subscribe(
        data => {
          if (data.status == "success") {
            this.billDetails.serverCacheId = data["serverCacheId"];
            localStorage.setItem("serverCacheId", data['serverCacheId']);

            this.utility.scrollToTop('none');
            if (this.billDetails.energyType == 'Solar') {
              this.router.navigate(['/solar-details']);
            } else {
              this.utility.showOffers(this.billDetails.loopBack, this.billDetails.energyType);
            }
          } else {
            this.spinner.hide();
            this.saveFormError = data.message;
            this.utility.scrollToTop('none');
          }

        },
        error => {
          this.spinner.hide();
          this.saveFormError = 'error';
          this.utility.scrollToTop('none');
        });
    } else {
      this.showFormErrors();
      this.utility.scrollToError();
    }
  }

  public selectButton(event, name) {
    if (event.keyCode == 32 || event.keyCode == 13) { //spacebar & enter
      if (name == "saveData") {
        this.saveData();
      }
    } else if (name == "single_rate") {
      this.billDetails.tariffType = 'single_rate';
    } else if (name == "tou") {
      this.billDetails.tariffType = 'tou';
    } else if (name == "unsure") {
      this.billDetails.tariffType = 'I don\'t know';
    }
  }

  public validateElectricity() {
    if ((this.billDetails.elecUsage * 365) > (40 * 1000)) {
      return true;
    } else {
      return false;
    }
  }

  public reAssignVariables(results) {
    let data;
    if (results.existing == false) {
      this.utility.sessionRestart();
      this.utility.removeServerCacheId(this.billDetails.serverCacheId);
      this.utility.redirectToHome('', true);
    } else {
      if (results.energyConfigData.energyType == '' || results.energyConfigData.energyType == undefined) {
        this.utility.redirectToHome('', true);
      } else {
        if (this.router.url == '/loopback/bill-details') {
          this.billDetails.loopBack = true;
          this.dataService.changeEnergyType(results.loopBackData.energyConfigData.energyType);
          this.solarCapacity = results.loopBackData.energyConfigData.solarCapacity;
          if (results.loopBackData.energyProfileData.hhSize == undefined && results.loopBackData.energyConfigData.energyType != 'Gas' &&
            results.loopBackData.energyConfigData.userType != 'Small business') {
            this.utility.redirectToHome('', true);
          } else {
            this.dataService.changeEnergyProfilePage('false');
          }
          if (results.loopBackData.energyProfileData.energyType != undefined) {
            this.dataService.changeEnergyProfilePage('true');
          }
          data = results.loopBackData.billDetailsData;
          this.billDetails.energyType = results.loopBackData.energyConfigData.energyType;
          this.billDetails.hasSolar = results.loopBackData.energyConfigData.hasSolar;
          this.billDetails.userType = results.loopBackData.energyConfigData.userType;
          this.billDetails.tariffType = results.tariffType;
        } else {
          this.dataService.changeEnergyType(results.energyConfigData.energyType);
          this.solarCapacity = results.energyConfigData.solarCapacity;
          if (results.energyProfileData.hhSize == undefined && results.energyConfigData.energyType != 'Gas' &&
            results.energyConfigData.userType != 'Small business') {
            this.utility.redirectToHome('', true);
          } else {
            this.dataService.changeEnergyProfilePage('false');
          }

          if (results.energyProfileData.energyType != undefined) {
            this.dataService.changeEnergyProfilePage('true');
          }
          data = results.billDetailsData;
          this.billDetails.energyType = results.energyConfigData.energyType;
          this.billDetails.hasSolar = results.energyConfigData.hasSolar;
          this.billDetails.userType = results.energyConfigData.userType;
        }
        this.dailyMaxConsumption = (this.billDetails.userType == 'Small business') ? this.gasMaxAnnualConsumptionMJ * 2 : this.gasMaxAnnualConsumptionMJ;
      }
      this.dataService.changeBillPage('true');
      this.showSolarForms = true;

      if (this.billDetails.energyType == 'Electricity') {
        this.billDetails.electricityBillStartDate = (data.electricityBillStartDate != undefined) ? data.electricityBillStartDate : '';
        this.billDetails.electricityBillEndDate = (data.electricityBillEndDate != undefined) ? data.electricityBillEndDate : '';
        this.billDetails.elecUsage = (data.elecUsage != undefined) ? data.elecUsage : '';
        this.billDetails.tariffType = results.tariffType;
        //solar
        this.billDetails.solarStartDate = (data.solarStartDate != undefined) ? data.solarStartDate : '';
        this.billDetails.solarEndDate = (data.solarEndDate != undefined) ? data.solarEndDate : '';
        this.billDetails.solarGeneration = (data.solarGeneration != undefined) ? data.solarGeneration : '';
      } else {
        this.showGasForms = true;
        if (data.billForms != undefined && this.billDetails.energyType == 'Gas') {
          this.billForms = data.billForms;
          this.billDetails.billForms = this.billForms;
        }
      }
    }
    this.showElectricityForms = true;
    this.dataService.getMaxConsumption().subscribe(
      results => {
        let maxConsumptions = results["data"];
        this.gasMaxConsumption = (this.billDetails.userType == 'Small business') ? maxConsumptions.gasMaxConsumption * 2 : maxConsumptions.gasMaxConsumption;
        this.electricityMaxConsumption = maxConsumptions.electricityMaxConsumption;
        let consumptionValidation;
        let tariffTypeValidation;
        if (this.billDetails.energyType != 'Gas') {
          consumptionValidation = <FormControl>this.billDetailsForm.get('elecUsage');
          consumptionValidation.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(this.electricityMaxConsumption),
            Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')
          ]);
          consumptionValidation.updateValueAndValidity();

          tariffTypeValidation = <FormControl>this.billDetailsForm.get('tariffType');
          tariffTypeValidation.setValidators([
            Validators.required
          ]);
          tariffTypeValidation.updateValueAndValidity();

        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public initializeForm() {
    this.saveFormError = "";
    this.showSolarForms = false;
    this.showGasForms = false;
    this.showElectricityForms = false;
    this.gasBillCtr = 0;
    this.isMobile = (this.deviceService.isMobile()) ? true : false;

    this.modalService.dismissAll(); //close modal dialog if there are any open
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          this.reAssignVariables(results);
          this.createForm();
          //create additional forms/set validation for gas multibill
          if (this.billForms.length > 1 && this.billDetails.energyType == 'Gas') {
            let tmp = 1;
            while (tmp < this.billForms.length) {
              this.billDetailsForm.addControl('gasBillStartDate' + tmp, new FormControl(''));
              this.billDetailsForm.addControl('gasBillEndDate' + tmp, new FormControl(''));
              this.billDetailsForm.addControl('gasUsage' + tmp, new FormControl('', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]));
              let gasBillStartDateValidation = <FormControl>this.billDetailsForm.get('gasBillStartDate' + tmp);
              let gasBillEndDateValidation = <FormControl>this.billDetailsForm.get('gasBillEndDate' + tmp);
              gasBillStartDateValidation.setValidators([this.isValidDate.bind(this)]);
              gasBillEndDateValidation.setValidators([this.isValidDate.bind(this)]);
              tmp++;
              this.gasBillCtr++;
            }
          }
        },
        error => {
          this.spinner.hide();
        }
      );
    } else {
      this.utility.redirectToHome('', true);
    }
  }

  private showFormErrors() {
    Object.keys(this.billDetailsForm.controls).forEach(key => {
      this.billDetailsForm.controls[key].markAsDirty();
      this.billDetailsForm.controls[key].markAsTouched();
    });
  }

}
