import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from '../utility';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})

export class LanguagesComponent implements OnInit {
  public bannerTitle = "In your language";
  public bannerSubTitle = "Translated content about Victorian Energy Compare programs and services are available in the following 16 languages";
  public bannerDetails = "";
  public bannerHideTips = false;
  public language = "";

  constructor(public utility : Utility, public router : Router) {

    var userLanguage;

    if (localStorage.getItem("langCode") === null) {
      userLanguage = window.navigator.language;
    } else {
      userLanguage = localStorage.getItem("langCode");
    }

    switch (userLanguage) {
      case "en-AU":
      case "en":
      case "en-GB":
      case "en-US":
          this.language = 'english';
          break;
      case 'it':
          this.language = 'italian';
          break;
      case 'ar':
          this.language = 'arabic';
          break;
      case 'zh-CN':
      case 'cn':
          this.language = 'chinese';
          break;
      case 'zh-TW':
      case 'zh':
          this.language = 'chinese-traditional';
          break;
      case 'el':
      case 'gr':
          this.language = 'greek';
          break;
      case 'km':
      case 'kh':
          this.language = 'khmer';
          break;
      case 'ko':
          this.language = 'korean';
          break;
      case 'mk':
          this.language = 'macedonian';
          break;
      case 'pa':
          this.language = 'punjabi';
          break;
      case 'es':
          this.language = 'spanish';
          break;
      case 'tr':
          this.language = 'turkish';
          break;
      case 'vi':
      case 'vn':
          this.language = 'vietnamese';
          break;
      default:
          break;
    }

    if (this.router.url != '/languages/all') {
        //this.router.navigate(['/languages/' + this.language]);
    }
  }

  ngOnInit(): void {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'In your language - Victorian Energy Compare';
  }

}
