<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <div>
                <img
                    class="hottopic-image" src="assets/images/{{ secImageURL }}contentpages/hottopics_header_8.jpg"
                    alt="How to switch and get the best offer for you">
                <p>
                    Switching retailers is easy once you've compared offers on the Victorian Energy
                    Compare website and found your best offer. Follow the 3 steps listed below to
                    switch to your best offer and start saving money.
                </p>
                <ol>
                    <li>
                        <span class="strong">Be prepared with your offer information</span>
                        <br />
                        <p>
                            To switch retailers and take up your best offer you will need to contact the retailer directly.
                            Before you contact them make sure you have the following documents handy:
                        </p>
                    </li>
                    <ul>
                        <li>
                            Details of the offer you're interested in, including offer name, offer ID, the total estimated annual cost,
                            contract terms and retailer contact details.
                        </li>
                        <li>
                            Concession card details (if eligible).
                        </li>
                    </ul>
                    <p>
                        You can find the above information by clicking on the offer.
                    </p>
                    <li>
                        <span class="strong">Contact your new retailer to discuss the offer</span>
                        <br />
                    </li>
                    <p>
                        When you're ready, contact your new retailer to discuss the offer and switching process.
                    </p>
                    <p>
                        Some helpful questions you may wish to ask your new retailer are:
                    </p>
                    <ul>
                        <li>What are the payment methods and discount conditions?</li>
                        <li>How long will the switching process take?</li>
                        <li>For electricity, what is the cost per kilowatt hour? For gas, what is the cost per megajoule?</li>
                        <li>Are there any exit fees, connection and disconnection fees?</li>
                        <li>When you are satisfied that the offer is the best one for you, ask for an offer summary to be sent to you.</li>
                    </ul>
                    <p>
                        If you're happy with the offer and wish to go ahead the representative will take you through the switching process.
                    </p>
                    <li>
                        <span class="strong">Sit back and let the retailer do the work for you!</span>
                        <br />
                    </li>
                    <p>
                        After choosing to go ahead and switch, your new retailer will take care of everything for you including notifying
                        your current retailer. If you change your mind you can cancel the switch within your cooling off period. The
                        cooling off period is usually 10 business days but check this with your retailer.
                    </p>
                </ol>
                <h3>Frequently Asked Questions</h3>
                <p>
                    <span class="strong">Q: How much will it cost me to switch?</span>
                </p>
                <p>
                    A: There is no cost to switching, however, depending on your current contract there may be an exit fee as well as
                    fees to connect and disconnect. If you are unsure check this with your current retailer. Exit fees are generally
                    low, or much less than the savings made by switching.
                </p>
                <p>
                    <span class="strong">Q: Does the electricity or gas get turned off when I switch?</span>
                </p>
                <p>
                    A: No. Your electricity or gas supply will not be interrupted when you change offers or move to a different retailer.
                    You'll always have power in your home, even when you switch. Your new retailer will simply use the existing
                    distribution network and your smart meter.
                </p>
                <p>
                    <span class="strong">Q. Can I switch retailers if I have outstanding debt with my current retailer?</span>
                </p>
                <p>
                    A: If you have debt you still may be able to switch electricity or gas providers, however energy retailers are
                    entitled to carry out credit checks where required. Keep in mind that changing energy retailers won't cancel out
                    outstanding debt, you will still need to make arrangements with your existing retailer for any outstanding
                    payments. If you are having trouble paying your energy bills,
                    <a class="hot-topics" href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills" target="_blank">help is available</a>.
                </p>
                <p>
                    <span class="strong">
                        Q. Who can I contact if I want to learn more about consumer protections and my rights or want to make a
                        complaint?
                    </span>
                </p>
                <p>
                    A: The <a class="hot-topics" href="https://www.ewov.com.au" target="_blank">Energy and Water Ombudsman Victoria</a> can provide
                    information and advice about changing energy companies and other energy issues. Energy and Water Ombudsman
                    Victoria is also responsible for responding to Victorian energy and water complaints and provide Victorian
                    customers with free, accessible, informal and fast dispute resolution.
                </p>
                <br>
                <p>
                    <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
                </p>
            </div>
        </div>
    </div>
</div>
<br /><br />
