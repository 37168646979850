import { CommunityOutreachSurveyComponent } from './community-outreach-survey/community-outreach-survey.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OffersComponent } from './offers/offers.component';
import { CompareComponent } from './compare/compare.component';
import { ContactComponent } from './contact/contact.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { QuizComponent } from './quiz/quiz.component';
import { RetailersComponent } from './retailers/retailers.component';
import { InstructionalVideosComponent } from './instructional-videos/instructional-videos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TopTipsComponent } from './top-tips/top-tips.component';
import { HotTopicsComponent } from './hot-topics/hot-topics.component';
import { HotTopicsElectricityBillComponent } from './hot-topics-electricity-bill/hot-topics-electricity-bill.component';
import { HotTopicsSwitchComponent } from './hot-topics-switch/hot-topics-switch.component';
import { HotTopicsVDOComponent } from './hot-topics-vdo/hot-topics-vdo.component';
import { HotTopicsEnergySimplifiedComponent } from './hot-topics-energy-simplified/hot-topics-energy-simplified.component';
import { HotTopicsReduceComponent } from './hot-topics-reduce/hot-topics-reduce.component';
import { HotTopicsRenovatingComponent } from './hot-topics-renovating/hot-topics-renovating.component';
import { HotTopicsMovingOutComponent } from './hot-topics-moving-out/hot-topics-moving-out.component';
import { HotTopicsMovingHouseComponent } from './hot-topics-moving-house/hot-topics-moving-house.component';
import { SolarSavingsComponent } from './solar-savings/solar-savings.component';
import { EnergyProfileComponent } from './energy-profile/energy-profile.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SolarDetailsComponent } from './solar-details/solar-details.component';
import { SolarResultsComponent } from './solar-results/solar-results.component';
import { LoopbackStartComponent } from './loopback-start/loopback-start.component';
import { LoopbackEnergyProfileComponent } from './loopback-energy-profile/loopback-energy-profile.component';
import { LoopBackBillDetailsComponent } from './loopback-bill-details/loopback-bill-details.component';
import { LoopBackCompareComponent } from './loopback-compare/loopback-compare.component';
import { PsbFaqComponent } from './psb-faq/psb-faq.component';
import { PsbOutreachComponent } from './psb-outreach/psb-outreach.component';
import { OfflineComponent } from './offline/offline.component';
import { SolarCalculatorComponent } from './solar-calculator/solar-calculator.component';
import { LanguagesComponent } from './languages/languages.component';
import { LanguageComponent } from './languages/language/language.component';
import { LanguagePageComponent } from './languages/language-page/language-page.component';
import { PsbOffersComponent } from './psb-offers/psb-offers.component';
import { StandaloneComponent } from './standalone/standalone.component';
import { RestartComponent } from './restart/restart.component';
import { PSBResultsComponent } from './psbresults/psbresults.component';
import { PsbSurveyComponent } from './psb-survey/psb-survey.component';
import { PsbSurvey3Component } from './psb-survey3/psb-survey3.component';
import { PsbSurvey4Component } from './psb-survey4/psb-survey4.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { PSB3JumpPointComponent } from './psb3-jump-point/psb3-jump-point.component';
import { EmbeddedNetworksComponent } from './embedded-networks/embedded-networks.component';
import { EbrFaqsComponent } from './ebr-faqs/ebr-faqs.component';
import { SmallBusinessEnergyQuestionnaireComponent } from './small-business-energy-questionnaire/small-business-energy-questionnaire.component';
import { HotTopicsBestOfferComponent } from './hot-topics-best-offer/hot-topics-best-offer.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  //{
    //path: 'psb-enquiry',
    //component: HomeComponent
  //},
  {
    path: 'psb3-jumppoint',
    component: PSB3JumpPointComponent
  },
  {
    path: 'psb',
    component: PSBResultsComponent
  },
  {
    path: 'eap',
    component: PSBResultsComponent
  },
  {
    path: 'offers',
    component: OffersComponent
  },
  {
    path: 'psb-offers',
    component: PsbOffersComponent
  },
  {
    path: 'offers/list/gas',
    component: OffersComponent
  },
  {
    path: 'offers/list/electricity',
    component: OffersComponent
  },
  {
    path: 'offers/list/dual',
    component: OffersComponent
  },
  {
    path: 'compare',
    component: CompareComponent
  },
  {
    path: 'compare/gas',
    component: CompareComponent
  },
  {
    path: 'compare/electricity',
    component: CompareComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'feedback',
    component: FeedbackFormComponent
  },
  {
    path: 'offline',
    component: OfflineComponent
  },
  {
    path: 'powersavingbonus2survey',
    component: PsbSurveyComponent
  },
  {
    path: 'powersavingbonus3survey',
    component: PsbSurvey3Component
  },
  {
    path: 'powersavingbonus4survey',
    component: PsbSurvey4Component
  },
  {
    path: 'communityoutreachsurvey',
    component: CommunityOutreachSurveyComponent
  },
  {
    path: 'retailers',
    component: RetailersComponent
  },
  {
    path: 'subscribe',
    component: SubscribeComponent
  },
  {
    path: 'instructional-videos',
    component: InstructionalVideosComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'top-tips-for-saving-money-on-energy-bills',
    component: TopTipsComponent
  },
  {
    path: 'energy-questionnaire',
    component: EnergyProfileComponent
  },
  {
    path: 'small-business-energy-questionnaire',
    component: SmallBusinessEnergyQuestionnaireComponent
  },
  {
    path: 'solar-calculator',
    component: StandaloneComponent
  },
  {
    path: 'bill-details',
    component: BillDetailsComponent
  },
  {
    path: 'hot-topics',
    component: HotTopicsComponent
  },
  {
    path: 'hot-topics/why-is-my-electricity-bill-so-high',
    component: HotTopicsElectricityBillComponent
  },
  {
    path: 'hot-topics/unlock-energy-bill-savings-with-your-retailer’s-best-offer',
    component: HotTopicsBestOfferComponent
  },
  {
    path: 'hot-topics/how-to-switch-and-get-the-best-offer-for-you',
    component: HotTopicsSwitchComponent
  },
  {
    path: 'hot-topics/the-victorian-default-offer-makes-electricity-bills-fair',
    component: HotTopicsVDOComponent
  },
  {
    path: 'hot-topics/how-to-reduce-my-energy-bill',
    component: HotTopicsReduceComponent
  },
  {
    path: 'hot-topics/moving-or-renovating-its-time-to-start-saving',
    component: HotTopicsRenovatingComponent
  },
  {
    path: 'hot-topics/moving-out-six-ways-to-save-on-energy-bills-as-a-renter',
    component: HotTopicsMovingOutComponent
  },
  {
    path: 'hot-topics/moving-house-move-to-cheaper-energy-bills',
    component: HotTopicsMovingHouseComponent
  },
  {
    path: 'solar-savings',
    component: SolarSavingsComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'feedback',
    component: FeedbackFormComponent
  },
  {
    path: 'solar-details',
    component: SolarDetailsComponent
  },
  {
    path: 'solar-results',
    component: SolarResultsComponent
  },
  {
    path: 'loopback/start',
    component: LoopbackStartComponent
  },
  {
    path: 'loopback/energy-questionnaire',
    component: LoopbackEnergyProfileComponent
  },
  {
    path: 'loopback/bill-details',
    component: LoopBackBillDetailsComponent
  },
  {
    path: 'loopback/compare',
    component: LoopBackCompareComponent
  },
  {
    path: 'psb-faq',
    component: PsbFaqComponent
  },
  {
    path: 'psb-outreach',
    component: PsbOutreachComponent
  },
  {
    path: 'languages',
    component: LanguagesComponent,
  },
  {
    path: 'languages/all',
    component: LanguagesComponent,
  },
  {
    path: 'languages/:language',
    component: LanguageComponent,
  },
  {
    path: 'languages/:language/:page-name',
    component: LanguagePageComponent,
  },
  {
    path: 'ebr-faq',
    component: EbrFaqsComponent,
  },
  {
    path: 'ebr-faqs',
    component: EbrFaqsComponent,
  },
  {
    path: 'restart',
    component: RestartComponent
  },
  {
    path: 'standalone',
    component: StandaloneComponent
  },
  {
    path: 'embedded-networks',
    component: EmbeddedNetworksComponent
  },
  {
    //if path does not exists redirect to home page
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
