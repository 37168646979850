import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataService } from '../../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from 'src/app/utility';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class LanguagePageComponent implements OnInit {
  public pageName = "";
  public pageLanguage = "";
  public appURL = environment.appURL;
  public bannerTitle = "";
  public bannerSubTitle = "";
  public bannerDetails = "";
  public bannerHideTips = true;
  public bannerTextDirection = "";
  public pageContents;

  public language = 'en';
  public videoFile;
  public languageISOCode;
  public FAQContent = {
    'key' : '',
    'file' : {
      'welcome' : ''
    },
    'tracks' : {
      'welcome' : {}
    },
    'lbl_lang_video' : '',
    'lbl_about_header' : '',
    'lbl_welcome_about_q1' : '',
    'lbl_welcome_about_a1' : '',
    'lbl_welcome_subheader' : '',
    'lbl_welcome_video_script' : '',
    'lbl_welcome_faq_q1' : '',
    'lbl_welcome_faq_a1' : '',
    'lbl_welcome_faq_q2' : '',
    'lbl_welcome_faq_a2' : '',
    'lbl_welcome_faq_q3' : '',
    'lbl_welcome_faq_a3' : '',
    'lbl_welcome_faq_q4' : '',
    'lbl_welcome_faq_a4' : '',
    'lbl_welcome_faq_q5' : '',
    'lbl_welcome_faq_a5' : '',
    'lbl_welcome_faq_q6' : '',
    'lbl_welcome_faq_a6' : '',
    'lbl_welcome_faq_q7' : '',
    'lbl_welcome_faq_a7' : '',
    'lbl_welcome_faq_q8' : '',
    'lbl_welcome_faq_a8' : '',
    'lbl_welcome_faq_q9' : '',
    'lbl_welcome_faq_a9' : '',
    'lbl_welcome_faq_q10' : '',
    'lbl_welcome_faq_a10' : '',
    'lbl_welcome_faq_q11' : '',
    'lbl_welcome_faq_a11' : '',
    'lbl_welcome_faq_q12' : '',
    'lbl_welcome_faq_a12' : '',
    'lbl_welcome_faq_q13' : '',
    'lbl_welcome_faq_a13' : '',
    'lbl_welcome_faq_q14' : '',
    'lbl_welcome_faq_a14' : '',
    'lbl_welcome_faq_q15' : '',
    'lbl_welcome_faq_a15' : '',
    'lbl_welcome_faq_q16' : '',
    'lbl_welcome_faq_a16' : '',
    'lbl_welcome_faq_q17' : '',
    'lbl_welcome_faq_a17' : '',
    'lbl_welcome_faq_q18' : '',
    'lbl_welcome_faq_a18' : '',
    'lbl_welcome_faq_q19' : '',
    'lbl_welcome_faq_a19' : '',
    'lbl_psb3_household_header' : '',
    'lbl_psb3_header' : '',
    'lbl_concession_header' : '',
    'lbl_concession_faq_q1' : '',
    'lbl_concession_faq_a1' : '',
    'lbl_concession_faq_q2' : '',
    'lbl_concession_faq_a2' : '',
    'lbl_concession_faq_q3' : '',
    'lbl_concession_faq_a3' : '',
    'lbl_concession_faq_q4' : '',
    'lbl_concession_faq_a4' : '',
    'lbl_profile_header' : '',
    'lbl_profile_video_header' : '',
    'lbl_profile_video_script' : '',
    'lbl_profile_about' : '',
    'lbl_profile_subheader' : '',
    'lbl_profile_about_q1' : '',
    'lbl_profile_about_a1' : '',
    'lbl_profile_faq_q1' : '',
    'lbl_profile_faq_a1' : '',
    'lbl_profile_faq_q2' : '',
    'lbl_profile_faq_a2' : '',
    'lbl_profile_faq_q3' : '',
    'lbl_profile_faq_a3' : '',
    'lbl_consumption_about_q1' : '',
    'lbl_consumption_about_a1' : '',
    'lbl_consumption_subheader' : '',
    'lbl_faq_header' : '',
    'lbl_consumption_faq_q1' : '',
    'lbl_consumption_faq_a1' : '',
    'lbl_consumption_faq_q2' : '',
    'lbl_consumption_faq_a2' : '',
    'lbl_consumption_faq_q3' : '',
    'lbl_consumption_faq_a3' : '',
    'lbl_consumption_faq_q4' : '',
    'lbl_consumption_faq_a4' : '',
    'lbl_solar_about_q1' : '',
    'lbl_solar_about_a1' : '',
    'lbl_results_about_q1' : '',
    'lbl_results_about_a1' : '',
    'lbl_results_subheader' : '',
    'lbl_results_faq_q1' : '',
    'lbl_results_faq_a1' : '',
    'lbl_results_faq_q2' : '',
    'lbl_results_faq_a2' : '',
    'lbl_results_faq_q3' : '',
    'lbl_results_faq_a3' : '',
    'lbl_results_faq_q4' : '',
    'lbl_results_faq_a4' : '',
    'lbl_results_faq_q5' : '',
    'lbl_results_faq_a5' : '',
    'lbl_results_faq_q6' : '',
    'lbl_results_faq_a6' : '',
    'lbl_compare_about_q1' : '',
    'lbl_compare_about_a1' : '',
    'lbl_compare_faq_q1' : '',
    'lbl_compare_faq_a1' : '',
    'lbl_compare_faq_q2' : '',
    'lbl_compare_faq_a2' : '',
    'lbl_compare_faq_q3' : '',
    'lbl_compare_faq_a3' : '',
    'lbl_contact_about_q1' : '',
    'lbl_contact_about_a1' : '',
    'lbl_contact_faq_q1' : '',
    'lbl_contact_faq_a1' : '',
    'lbl_contact_faq_q2' : '',
    'lbl_contact_faq_a2' : '',
    'lbl_contact_faq_q3' : '',
    'lbl_contact_faq_a3' : '',
    'lbl_contact_faq_q4' : '',
    'lbl_contact_faq_a4' : '',
    'lbl_contact_faq_q5' : '',
    'lbl_contact_faq_a5' : '',
    'lbl_enquiries_header' : '',
  };

  constructor(
    private route: ActivatedRoute,
    public dataService : DataService,
    public spinner: NgxSpinnerService,
    public router : Router,
    public utility : Utility,
    private _sanitizer: DomSanitizer
    )
    {
      Window["LanguagePageComponent"] = this;
    }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.pageName = this.route.snapshot.paramMap.get("page-name");
    this.bannerTitle = this.pageName;
    this.pageLanguage = this.route.snapshot.paramMap.get("language");

    switch (this.pageLanguage) {
        case "english":
          this.language = 'en';
          break;
        case 'italian':
          this.language = 'it';
          break;
        case 'arabic':
          this.language = 'ar';
          break;
        case 'chinese':
          this.language = 'cn';
          break;
        case 'chinese-traditional':
          this.language = 'zh';
          break;
        case 'greek':
          this.language = 'gr';
          break;
        case 'khmer':
          this.language = 'kh';
          break;
        case 'korean':
          this.language = 'ko';
          break;
        case 'macedonian':
          this.language = 'mk';
          break;
        case 'punjabi':
          this.language = 'pa';
          break;
        case 'spanish':
          this.language = 'es';
          break;
        case 'turkish':
          this.language = 'tr';
          break;
        case 'filipino':
          this.language = 'ph';
          break;
        case 'somali':
          this.language = 'so';
          break;
        case 'oromo':
          this.language = 'om';
          break;
        case 'vietnamese':
          this.language = 'vn';
          break;
        default:
          console.log("No such language exists!");
          break;
    }

    this.dataService.getLanguagePageConfig(this.pageLanguage, this.pageName).subscribe(
      result => {
        this.bannerTitle = result["title"];
        this.bannerSubTitle = result["subtitle"];
        document.title = result["title"] + " - Victorian Energy Compare";
        this.bannerTextDirection = result["textDirection"];
        this.spinner.hide();
      }, error => {
        this.bannerTitle = "404 - Page not found";
        this.pageContents = "The page you are looking for doesn't exist.";
        this.spinner.hide();
      }
    );

    if(this.pageName == 'vec-faqs') {
      this.loadLanguage(this.language);
    }
    else {

      this.dataService.getLanguagePageContents(this.pageLanguage, this.pageName).subscribe(
        result => {
          this.pageContents = this._sanitizer.bypassSecurityTrustHtml(result.toString());
          this.spinner.hide();
        }, error => {
          this.bannerTitle = "404 - Page not found";
          this.pageContents = "The page you are looking for doesn't exist.";
          this.spinner.hide();
        }
      );
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  public loadLanguage(language) {
    this.spinner.show();

    switch (language) {
      case 'gr':
          this.languageISOCode = 'el';
          break;
      case "vn":
          this.languageISOCode = 'vi';
          break;
      case "cn":
          this.languageISOCode = 'zh-Hans';
          break;
      case "zh":
        this.languageISOCode = 'zh-Hant';
        break;
      case "kh":
        this.languageISOCode = 'km';
          break;
      case "ph":
        this.languageISOCode = 'fil';
          break;
      default:
          this.languageISOCode = language;
          break;
  }

    this.dataService.getFAQ(language).subscribe(
      faq => {
        this.FAQContent = faq["data"];
        this.spinner.hide();
        this.videoFile = this._sanitizer.bypassSecurityTrustResourceUrl(this.FAQContent.file.welcome + '?rel=0&cc_load_policy=1&cc_lang_pref=' + this.languageISOCode);

      }, error => {
        this.spinner.hide();
      }
    );
  }

}

