<div id="container">
    <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails" [hideTips]="bannerHideTips">
    </app-banner>

    <div>
        <div class="col-md-12 languages">
            <p>&nbsp;</p>
            <ul>
                <li><a hreflangs="ar" href="/languages/english">English</a></li>
                <li><a hreflangs="ar" href="/languages/arabic">Arabic - العربية</a></li>
                <li><a hreflangs="zh-hans" href="/languages/chinese">Chinese (Simplified) - 简体中文</a></li>
                <li><a hreflangs="zh" href="/languages/chinese-traditional">Chinese (Traditional) - 繁體中文</a></li>
                <li><a hreflangs="ph" href="/languages/filipino">Filipino - Filipino</a></li>
                <li><a hreflangs="el" href="/languages/greek">Greek - ελληνικά</a></li>
                <li><a hreflangs="it" href="/languages/italian">Italian - Italiano</a></li>
                <li><a hreflangs="km" href="/languages/khmer">Khmer - ភាសាខ្មែរ</a></li>
                <li><a hreflangs="ko" href="/languages/korean">Korean - 한국어</a></li>
                <li><a hreflangs="mk" href="/languages/macedonian">Macedonian - македонски</a></li>
                <li><a hreflangs="es" href="/languages/oromo">Oromo - Afaan Oromoo</a></li>
                <li><a hreflangs="pa" href="/languages/punjabi">Punjabi - ਪੰਜਾਬੀ</a></li>
                <li><a hreflangs="es" href="/languages/spanish">Spanish - Español</a></li>
                <li><a hreflangs="es" href="/languages/somali">Somali - Soomaali</a></li>
                <li><a hreflangs="tr" href="/languages/turkish">Turkish - Türkçe</a></li>
                <li><a hreflangs="vi" href="/languages/vietnamese">Vietnamese - Tiếng Việt</a></li>
            </ul>

            <p>&nbsp;</p>
            <p>
                <img alt="Interpreter" style="width: 70px; height: 70px; margin-bottom: 10px;" src="assets/images/interpreter.png"></p>
            <p>
                For further assistance, contact <strong>Victorian Energy Compare</strong> on <a href="tel:1800000832"><strong>1800 000 832</strong></a>. If you require a translator contact <strong>Translating and Interpreting Service (TIS)</strong> on
                <a href="tel:131450"><strong>131 450</strong></a> or visit <br>
                <a href="https://www.tisnational.gov.au/" target="_blank">www.tisnational.gov.au.</a>
            </p>
        </div>
    </div>
</div>
<br /><br />
