<div class="modal-header">
    <div class="modal-icons col-lg-12">
        <button class="hidden-btn"></button>
        <button 
            title="Close" 
            aria-label="Close" 
            type="" 
            class="btn btn-link non-focus" 
            (click)="activeModal.close('Close click')">
            <i class="fa fa-times" aria-hidden="true"></i> close
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="row view-retailer-section1">
        <div class="col-md-8">
            <h1>{{ retailer.name }}</h1>
        </div>
        <div class="col-md-4">
            <div class="compare-retailer-logo modal-retailer-logo">
                <img 
                    [attr.alt]="retailer.name" 
                    [attr.aria-label]="retailer.name" 
                    class="align-retailer-logo" 
                    src='assets/{{ retailer.retailerImageUrl }}'>
            </div>
            <div class="retailer-webid">
                <a 
                    class="link" 
                    [attr.title]="retailer.name" 
                    [attr.aria-label]="retailer.name" 
                    href="{{ (retailer.retailerUrl.indexOf('http') == -1) ? 'http://' + retailer.retailerUrl : retailer.retailerUrl }}" target="_blank">
                    {{ (retailer.retailerUrl.indexOf('http') == -1) ? 'http://' + retailer.retailerUrl : retailer.retailerUrl }}
                    <i class="fa fa-external-link"></i>
                </a>
            </div>
            <div class="retailer-contact">
                <i class="fa fa-phone"></i> 
                {{ retailer.retailerPhone }}
            </div>
        </div>
    </div>
    <div class="view-retailer-section2">
        <h3>About this retailer</h3>
        <p class="retailer-blurb" [innerHTML]="retailer.retailerDescription">
        </p>
        <p>
            <br />
            <span class="strong">
                ESC licence information: 
            </span>
            {{ (retailer.retailerFueltype == 'Both') ? 'Electricity and gas' : retailer.retailerFueltype }} 
            {{ (retailer.retailerLicenseName == null || retailer.retailerLicenseName == '') ? '' : ' retail licence under ' + retailer.retailerLicenseName }}
        </p>
    </div>
</div>