import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { DataService } from '../data.service';
import { Utility } from '../utility';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public appURL = environment.appURL;
  public liveChatOn = this.dataService.liveChatOn;
  public liveChatID = environment.liveChatID;
  public secCss = (environment.secBranding == true) ? 'footer-primary-sec' : '';
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public currentDate = new Date();
  constructor(public dataService : DataService,
    public utility : Utility) {}

  ngOnInit() {
  }

  public getChatSetting() {
    return this.dataService.liveChatOn;
  }

}
