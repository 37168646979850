import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';

@Component({
  selector: 'app-instructional-videos',
  templateUrl: './instructional-videos.component.html',
  styleUrls: ['./instructional-videos.component.css'],
})
export class InstructionalVideosComponent implements OnInit {
  public appURL = environment.appURL;
  public compareURL = environment.compareURL;
  public bannerTitle = "Instructional Videos";
  public bannerSubTitle = "The Victorian Energy Compare videos explain the following:";
  public bannerDetails = "<ol><li>How to read your bills</li><li>How to use Victorian Energy Compare</li><li>Other ways to save energy</li></ol>";

  constructor(public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Instructional Videos - Victorian Energy Compare';
  }

}
