import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-psb3-jump-point',
  templateUrl: './psb3-jump-point.component.html',
  styleUrls: ['./psb3-jump-point.component.css']
})
export class PSB3JumpPointComponent implements OnInit {

  constructor(public spinner : NgxSpinnerService, public utility : Utility) { }

  ngOnInit() {
    this.spinner.show();
    sessionStorage.setItem("psbMode", 'three-YCxQbYcMuweGPQCdFGeFzyC3');
    this.utility.redirectToHome();
  }

}
