<div class="card-group" id="compare-modal">
    <div class="card card-default">
        <div class="card-header bg-success text-white">
            <div class="card-title title-pad">
                Comparison: <b>Your Results <span class="option"></span></b>
                <button class="hidden-btn" *ngIf="print == false"></button>
                <button type="button" class="close non-focus link" (click)="activeModal.close('Close click')" *ngIf="print == false">×</button>
            </div>
            How are these calculated?
        </div>
        <div class="hidden-label">{{ emailSecurityKey }}</div>
        <div class="card-body" *ngIf="solarEstimatesData1.annualSavedTotal != undefined && solarEstimatesData2.annualSavedTotal != undefined && solarEstimatesData3.annualSavedTotal != undefined">
            <table class="container">
                <tr>
                    <th>
                        <div class="row">
                            <div class="options options-header2">
                                <h4>heading</h4>
                            </div>
                            <div class="options options-sub-header2">
                                <span class="values-pad values-border">
                                    System Size
                                </span>
                                <span class="values-pad">
                                    System Cost
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                    <th>
                        <div class="row">
                            <div class="options options-header">
                                <h4>Option 1</h4>
                            </div>
                            <div class="options options-sub-header">
                                <span class="values-pad">
                                    {{ solarData1.systemSize }}kW
                                </span>
                                <br />
                                <span class="values-pad">
                                    {{ solarData1.systemCost | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                    <th>
                        <div class="row">
                            <div class="options options-header">
                                <h4>Option 2</h4>
                            </div>
                            <div class="options options-sub-header">
                                <span class="values-pad">
                                    {{ solarData2.systemSize }}kW
                                </span>
                                <br />
                                <span class="values-pad">
                                    {{ solarData2.systemCost | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                    <th>
                        <div class="row">
                            <div class="options options-header">
                                <h4>Option 3</h4>
                            </div>
                            <div class="options options-sub-header">
                                <span class="values-pad">
                                    {{ solarData3.systemSize }}kW
                                </span>
                                <br />
                                <span class="values-pad">
                                    {{ solarData3.systemCost | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                            </div>
                        </div>
                        <br />
                    </th>
                </tr>
                <tr>
                    <td class="solar-row">
                        <br />
                        <img src='{{ appURL + "/assets/images/icon-pig.png" }}' class="img-responsive" height="45" alt="" />
                        <br />
                        Time taken to pay for itself
                        <br />
                    </td>
                    <td class="values solar-row">
                        {{ solarCalculator.getPayback(solarData1.systemCost, solarEstimatesData1.annualSavedTotal) | number : '1.0-1' }}
                        <br />
                        years
                    </td>
                    <td class="values solar-row">
                        {{ solarCalculator.getPayback(solarData2.systemCost, solarEstimatesData2.annualSavedTotal) | number : '1.0-1' }}
                        <br />
                        years
                    </td>
                    <td class="values solar-row">
                        {{ solarCalculator.getPayback(solarData3.systemCost, solarEstimatesData3.annualSavedTotal) | number : '1.0-1' }}
                        <br />
                        years
                    </td>
                </tr>
                <tr>
                    <td class="solar-row">
                        <br />
                        <img src='{{ appURL + "/assets/images/dollar.png" }}' class="img-responsive" height="45" alt="" />
                        <br />
                        Your estimated annual electricity bill
                        <br />
                    </td>
                    <td class="values solar-row">
                        {{ solarEstimatesData1.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values solar-row">
                        {{ solarEstimatesData2.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values solar-row">
                        {{ solarEstimatesData3.annualBillTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <br />
                        <img src='{{ appURL + "/assets/images/dollar-save.png" }}' class="img-responsive" height="45" alt="" />
                        <br />
                        Your estimated annual <br />
                        savings
                        <br />
                    </td>
                    <td class="values">
                        {{ solarEstimatesData1.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values">
                        {{ solarEstimatesData2.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                    <td class="values">
                        {{ solarEstimatesData3.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}
                    </td>
                </tr>
            </table>
            <br /><br />
            <div class="card card-default" *ngIf="print == false">
                <div class="card-header background-header pad-bottom cursor-pointer" (click)="toggleShowOptions()">
                    <div class="card-title adjust-options-title">
                        Adjust your options here
                        <div class="float-right">
                            <i class="fa fa-chevron-up" *ngIf="showOptions == true"></i>
                            <i class="fa fa-chevron-down" *ngIf="showOptions == false"></i>
                        </div>
                    </div>
                </div>

                <div class="card-body" *ngIf="solarEstimatesData1.annualSavedTotal != undefined && solarEstimatesData2.annualSavedTotal != undefined && solarEstimatesData3.annualSavedTotal != undefined && showOptions == true">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="radio-inline">
                                <input name="option" type="radio" id="option1" value="1" checked>
                                <label for="option1" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="6" (keydown)="changeOption(1)" (click)="changeOption(1)">
                                    Option 1
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="radio-inline">
                                <input name="option" type="radio" id="option2" value="2">
                                <label for="option2" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="6" (keydown)="changeOption(2)" (click)="changeOption(2)">
                                    Option 2
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="radio-inline">
                                <input name="option" type="radio" id="option3" value="3">
                                <label for="option3" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="6" (keydown)="changeOption(3)" (click)="changeOption(3)">
                                    Option 3
                                </label>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <span class="result-left-sub-title">Solar System Details</span>

                    <form method="post" id="solarForm" [formGroup]="solarForm">
                        <div class="row container container-options">
                            <div class="col-lg-5 sliders">
                                <br />
                                <div class="form-label title-black">
                                    <label class="control-label"><h3 class="form_label">Size of Solar System</h3></label>
                                    <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="The solar system size is the total output of the solar system. This is measured in kilowatts."></span>
                                </div>
                                <div class="form-group">
                                    <span class="bound_label float-left">1.5 kW</span>
                                    <span class="bound_label float-right">20 kW</span>
                                    <input class="form-control-range" step="0.1" type="range" id="systemSize" formControlName="systemSize" [(ngModel)]="selectedSolarData.systemSize" min="1.5" max="20" (change)="updateSizePosition(); updateSolarEstimates();" data-html2canvas-ignore="true">
                                    <span [style.left]="getSizePosition()" class="slider-value slider-size"> {{ selectedSolarData.systemSize }} kW</span>
                                </div>
                                <br /><br />

                                <div class="form-label title-black">
                                    <label class="control-label"><h3 class="form_label">Cost of Solar System</h3></label>
                                    <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="The cost of the system is after any rebates and should include GST"></span>
                                </div>
                                <div class="form-group">
                                    <span class="bound_label float-left">$0</span>
                                    <span class="bound_label float-right">$20,000</span>
                                    <input class="form-control-range" step="100" type="range" id="systemCost" formControlName="systemCost" [(ngModel)]="selectedSolarData.systemCost" min="0" max="20000" (change)="updateCostPosition(); updateSolarEstimates();" data-html2canvas-ignore="true">
                                    <span  [style.left]="getCostPosition()" class="slider-value slider-cost"> {{ selectedSolarData.systemCost | currency:'USD':'symbol':'1.0-0' }}</span>
                                </div>
                            </div>
                            <div class="col-lg-3 border-left border-right forms">
                                <label class="control-label"><h3 class="form_label electricity_label">Electricity Usage</h3></label>
                                <p></p>

                                <div class="form-group">
                                    <label class="control-label">Peak</label>
                                    <div class="input-group-prepend">
                                        <input type="number" class="form-control result-left-input" placeholder="Cents per kWh" [(ngModel)]="selectedSolarData.peakRateValue" id="peakRateValue" formControlName="peakRateValue" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per kWh</span>
                                    <bfv-messages></bfv-messages>
                                </div>
                                <div class="col-sm-12">&nbsp;</div>

                                <div class="form-group" *ngIf="selectedSolarData.shoulderRate > 0">
                                    <label class="control-label">Shoulder</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.01" min="0" max="25" class="form-control result-left-input" placeholder="Cents per kWh" [(ngModel)]="selectedSolarData.shoulderRateValue" id="shoulderRateValue" formControlName="shoulderRateValue" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per kWh</span>
                                    <bfv-messages></bfv-messages>
                                    <div class="col-sm-12">&nbsp;</div>
                                </div>


                                <div class="form-group" *ngIf="selectedSolarData.offPeakRate > 0">
                                    <label class="control-label">Off-Peak</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.01" min="0" max="25" class="form-control result-left-input" placeholder="Cents per kWh" [(ngModel)]="selectedSolarData.offPeakRateValue" id="offPeakRateValue" formControlName="offPeakRateValue" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per kWh</span>
                                    <bfv-messages></bfv-messages>
                                    <div class="col-sm-12">&nbsp;</div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Daily Supply Charge</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.1" min="0" max="10000" class="form-control result-left-input DSC" placeholder="Cents per day" [(ngModel)]="selectedSolarData.dailySupplyCharge" id="dailySupplyCharge" formControlName="dailySupplyCharge" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per day</span>
                                    <bfv-messages></bfv-messages>
                                </div>
                                <div class="col-sm-12">&nbsp;</div>

                                <div class="form-group" *ngIf="selectedSolarData.discount > 0">
                                    <label class="control-label">Discount rate</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.1" min="0" max="100" class="form-control result-left-input" placeholder="Rate in %" [(ngModel)]="selectedSolarData.discountRateValue" id="discountRateValue" formControlName="discountRateValue" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">Rate in %</span>
                                    <bfv-messages></bfv-messages>
                                    <div class="col-sm-12">&nbsp;</div>
                                </div>
                            </div>
                            <div class="col-lg-3 forms">
                                <label class="control-label">
                                    <h3 class="form_label">
                                    Solar Feed-in Tariff
                                    <span style="font-size: 15px;" class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="A feed-in tariff (FiT) is a rate paid for electricity fed back into the electricity grid from a designated renewable electricity generation source. Please speak to both your solar installer and electricity distributor about your properties ability to export electricity to the grid before installation."></span></h3>
                                </label>

                                <p class="col-xs-12 col-sm-12 nopadding"></p>
                                <div class="form-group">
                                    <label class="control-label">Peak / Single Rate</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.1" min="0" class="form-control result-fit-input" placeholder="Cents per kWh" formControlName="fitPeak" id="fitPeak" [(ngModel)]="selectedSolarData.fitPeak" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per kWh</span>
                                    <bfv-messages></bfv-messages>
                                </div>
                                <div class="col-sm-12">&nbsp;</div>

                                <div class="form-group">
                                    <label class="control-label">Shoulder</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.01" min="0" max="25" class="form-control result-fit-input" formControlName="fitShoulder" id="fitShoulder" placeholder="e.g. 7" [(ngModel)]="selectedSolarData.fitShoulder" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per kWh</span>
                                    <bfv-messages></bfv-messages>
                                </div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="form-group">
                                    <label class="control-label">Off-Peak</label>
                                    <div class="input-group-prepend">
                                        <input type="number" step="0.01" min="0" max="25" class="form-control result-fit-input" formControlName="fitOffPeak" id="fitOffPeak" placeholder="e.g. 5" [(ngModel)]="selectedSolarData.fitOffPeak" (blur)="updateSolarEstimates()">
                                    </div>
                                    <span class="small-label">cents per kWh</span>
                                    <bfv-messages></bfv-messages>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <br />
            <div class="row" *ngIf="print == false">
                <div class="col-lg-4">
                    <input type="button" value="Back to Results" class="compare-btn col-lg-12" (click)="activeModal.close('Close click')">
                </div>
                <!--
                <div class="col-lg-4">
                    <input type="button" value="Download Results" class="compare-btn col-lg-12" (click)="downloadPDF()">
                </div>
                -->
                <div class="col-lg-4">
                    <input type="button" value="Email Results" class="compare-btn col-lg-12" (click)="emailModal()">
                </div>
            </div>

        </div>
    </div>
</div>
