<div class="modal-header" class="button">
    <div class="row col-md-12 no-gutters">
        <div class="col-md-12 modal-icons">
            <button class="hidden-btn"></button>
            <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
                <i class="fa fa-times" aria-hidden="true"></i> close
            </button>
        </div>
    </div>
    <div class="languages-dropdown">
      <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic">
                <span class="material-icons">language</span>
                {{selectedSortOrder}} <span class="caret"></span>
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
            role="menu" aria-labelledby="button-basic">
            <li role="menuitem" class="dropdown-item"
            *ngFor="let sortOrder of sortOrders"
            (click)="ChangeSortOrder(sortOrder)" >{{sortOrder.language}}</li>
        </ul>
      </div>
  </div>
</div>

<div class="modal-body container {{ (language == 'ar') ? 'align-right' : '' }}">
    <div class="row">
        <h3 class="col-xs-12" [innerHTML]="FAQContent.lbl_contact_header"></h3>
    </div>
    <div class="row">
        <h4 [innerHTML]="FAQContent.lbl_contact_subheader"></h4>
    </div>
    <p>&nbsp;</p>
    <div class="row">
        <blockquote>
            <h5 [innerHTML]="FAQContent.lbl_contact_about_q1"></h5>
            <p [innerHTML]="FAQContent.lbl_contact_about_a1" class="pad"></p>

            <h5 [innerHTML]="FAQContent.lbl_contact_faq_q1"></h5>
            <p [innerHTML]="FAQContent.lbl_contact_faq_a1" class="pad"></p>

            <h5 [innerHTML]="FAQContent.lbl_contact_faq_q2"></h5>
            <p [innerHTML]="FAQContent.lbl_contact_faq_a2" class="pad"></p>

            <h5 [innerHTML]="FAQContent.lbl_contact_faq_q3"></h5>
            <p [innerHTML]="FAQContent.lbl_contact_faq_a3" class="pad"></p>

            <h5 [innerHTML]="FAQContent.lbl_contact_faq_q4"></h5>
            <p [innerHTML]="FAQContent.lbl_contact_faq_a4" class="pad"></p>

        </blockquote>
    </div>
</div>
