import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { SolarCalculator } from '../solarCalculator';
import { GoogleChartInterface } from 'ng2-google-charts';
import { ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';

@Component({
  selector: 'app-solar-right-panel',
  templateUrl: './solar-right-panel.component.html',
  styleUrls: ['./solar-right-panel.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(window:resize)' : 'onResize($event)'
  }
})
export class SolarRightPanelComponent implements OnInit {
  //get data from main component (results.component)
  @Input() solarData = {
    nmi : '',
    postcode : '',
    retailer : 0,
    offPeakRate : -1,
    offPeakRateValue : '',
    shoulderRate : -1,
    shoulderRateValue : '',
    discount : -1,
    discountRateValue : '',
    agree : 0,
    peakRateValue : '',
    dailySupplyCharge : '',
    usage : -1,
    fitOffPeak : 0,
    fitShoulder : 0,
    fitPeak : 12,

    // The scope of discounts. Options include 'usage' and 'bill'
    discountType : '',

    // System size in KW
    systemSize : 0,

    // System cost in dollars
    systemCost : 0,

    fitRates : {},
    tariffs : {}
  };
  @Input() selectedOption;
  @Input() solarCompareTable;
  @Input() SolarEstimatesData: {
    consumptionGeneration : {},
    annualGenerated : 0,
    annualImportWithSolar : 0,
    annualImportNoSolar : 0,
    annualSavedTotal : 0,
    annualSelfConsumedTotal : 0,
    annualBillTotal : 0,
    annualSupplyCharge : 0,
    annualFeedInTotal : 0,
    annualImportTotal : 0,
    monthlyTotals : {}
  };

  @Input() download;

  private pageIsloaded = false;
  public appURL = environment.appURL;
  public emailSecurityKey = environment.emailSecurityKey;
  public compareURL = environment.compareURL;
  public apiEndpoint = environment.apiEndpoint;
  public showCalculation = false;

  /** Google Charts starts here **/
  public chart1: GoogleChartInterface = {
    chartType: 'LineChart',
  };

  public chart1Print: GoogleChartInterface = {
    chartType: 'LineChart',
  };

  public chart2: GoogleChartInterface = {
    chartType: 'ColumnChart',
  };

  public chart2Print: GoogleChartInterface = {
    chartType: 'ColumnChart',
  };

  constructor(public dataService : DataService,
              public solarCalculator : SolarCalculator,
              public utility: Utility,
              private router : Router,
              private spinner : NgxSpinnerService,
              private cd: ChangeDetectorRef) {
                this.spinner.show();
              }

  //any changes to @input variables, redraw chart
  ngOnChanges(changes: any) {
    if (this.pageIsloaded == true) {
      this.redrawCharts();
    }
  }

  ngOnInit() {
    this.pageIsloaded = true;
    this.spinner.hide();
    this.drawCharts();
  }

  public redrawCharts() {
    this.drawCharts();
    let chart1Component = this.chart1.component;
    let chart2Component = this.chart2.component;

    //force a redraw
    chart1Component.draw();
    chart2Component.draw();
  }

  public drawCharts() {
    try {
      var consumptionGenerationData = this.solarCalculator.getLabelledConsumptionGeneration(this.SolarEstimatesData);
      this.chart1.dataTable = consumptionGenerationData;
      this.chart1.options = this.solarCalculator.chart1Options;

      var monthlyTotals = this.solarCalculator.getLabelledMonthlyTotals(this.SolarEstimatesData);
      this.chart2.dataTable = monthlyTotals;
      this.chart2.options = this.solarCalculator.chart2Options;

      //charts for printing/pdf
      this.chart1Print.dataTable = consumptionGenerationData;
      this.chart1Print.options = this.solarCalculator.chart1PrintOptions;
      this.chart2Print.dataTable = monthlyTotals;
      this.chart2Print.options = this.solarCalculator.chart2PrintOptions;
    } catch (e) {
      //this.showError();
    }

  }

  public previousPage() {
    this.router.navigate(['/details']); //redirect to details route
  }

  onResize(event) {
    this.redrawCharts();
  }
}
