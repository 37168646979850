
<div class="modal-body">
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.dismiss('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <br ><br >
    <p class="para-solar-rebates">
        We have detected from your energy data that your property already has solar panels installed. 
        You can still use the Solar Savings function, but please be aware that the results will provide 
        information based on installing additional solar panels at your property, and how that 
        installation will reduce your existing electricity costs.
    </p>
</div>
