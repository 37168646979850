<header (click)="utility.checkFilters()">
    <div class="top-bar {{ secCss }}" id="header">
        <div class="container" id="big-header">
            <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs">
                <div class="take_charge_logo" [ngClass]="secBranding == true ? 'vec-sec' : ''">
                    <img
                        class="take_charge_logo"
                        aria-label="Take Charge. Save on Bills."
                        alt="Take Charge. Save on Bills."
                        src='{{ appURL }}/assets/images/{{ secImageURL }}header_940_left.png'>
                </div>

                <div class="vic_logo" *ngIf="secBranding == false">
                    <a
                        title="State Government of Victoria, Australia – link to Government home"
                        aria-label="State Government of Victoria, Australia – link to Government home"
                        tabindex=-1
                        target="_blank"
                        class="non-focus"
                        href="http://vic.gov.au" id="vicgov-link">
                        <img
                            class="vic_logo"
                            aria-label="State Government of Victoria, Australia – link to Government home"
                            alt="State Government of Victoria, Australia – link to Government home"
                            src='{{ appURL }}/assets/images/header_1140_vic_logo.png'>
                    </a>
                </div>
                <div class="vec_logo" [ngClass]="secBranding == true ? 'vec-sec-logo' : ''">
                    <a
                        title="Victorian Energy Compare"
                        aria-label="Victorian Energy Compare"
                        tabindex=-1
                        class="non-focus"
                        (click)="utility.resetPage()"
                        id="vec-link">
                        <img
                            [ngClass]="secBranding == true ? 'vec-sec-logos' : ''"
                            aria-label="Victorian Energy Compare"
                            alt="Victorian Energy Compare"
                            src='{{ appURL }}/assets/images/{{ secImageURL }}header_600_vec_logo.png'>
                    </a>
                    <a href="https://vic.gov.au" target="_blank" *ngIf="secBranding == true" title="State Government of Victoria, Australia – link to Government home">
                      <img
                          class="vec-sec-logos sec-logos"
                          aria-label="State Government of Victoria, Australia – link to Government home"
                          alt="State Government of Victoria, Australia – link to Government home"
                          src='{{ appURL }}/assets/images/{{ secImageURL }}header_940_left_vic.png'>
                  </a>
                </div>
            </div>
        </div>
        <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-medium-print-header" class="vec-sec" [ngClass]="secBranding == true ? 'vec-sec' : ''">
            <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png" *ngIf="secBranding == true">
            <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_720_left.png" *ngIf="secBranding == false">
            <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_720_vic_logo.png" *ngIf="secBranding == false">
            <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_600_vec_logo.png" *ngIf="secBranding == false">
        </div>

        <div class="large container" id="medium-header">
            <div class="take_charge_logo" [ngClass]="secBranding == true ? 'vec-sec' : ''">
                <img
                    [ngClass]="secBranding == true ? 'vec-sec-logos' : ''"
                    aria-label="Take Charge. Save on Bills."
                    alt="Take Charge. Save on Bills."
                    src='{{ appURL }}/assets/images/{{ secImageURL }}header_720_left.png'>

            </div>
            <div class="vic_logo" *ngIf="secBranding == false">
                <a
                    title="State Government of Victoria, Australia – link to Government home"
                    aria-label="State Government of Victoria, Australia – link to Government home"
                    tabindex=-1
                    target="_blank"
                    href="http://vic.gov.au" id="vicgov-link">
                    <img
                        class="vic_logo"
                        aria-label="State Government of Victoria, Australia – link to Government home"
                        alt="State Government of Victoria, Australia – link to Government home"
                        src='{{ appURL }}/assets/images/header_720_vic_logo.png'>
                </a>
            </div>
            <div class="vec_logo">
                <a
                    title="Victorian Energy Compare"
                    aria-label="Victorian Energy Compare"
                    tabindex=-1
                    (click)="utility.resetPage()"
                    id="vec-link">
                    <img
                        [ngClass]="secBranding == true ? 'vec-sec-logos' : ''"
                        aria-label="Victorian Energy Compare"
                        class="non-focus {{ vecLogo }}"
                        alt="Victorian Energy Compare"
                        src='{{ appURL }}/assets/images/{{ secImageURL }}header_600_vec_logo.png'>
                </a>
                <a href="https://vic.gov.au" target="_blank" *ngIf="secBranding == true" title="State Government of Victoria, Australia – link to Government home">
                  <img
                      class="vec-sec-logos sec-logos"
                      aria-label="State Government of Victoria, Australia – link to Government home"
                      alt="State Government of Victoria, Australia – link to Government home"
                      src='{{ appURL }}/assets/images/{{ secImageURL }}header_940_left_vic.png'>
              </a>

            </div>
        </div>
        <div class="large container" id="small-header" [ngClass]="secBranding == true ? 'vec-sec' : ''">
            <img
                [ngClass]="secBranding == true ? 'vec-sec-logos' : ''"
                class="take_charge_logo"
                aria-label="Take Charge. Save on Bills."
                alt="Take Charge. Save on Bills."
                src='{{ appURL }}/assets/images/{{ secImageURL }}header_600_left.png'>
            <a
                title="Victorian Energy Compare"
                aria-label="Victorian Energy Compare"
                tabindex=-1
                (click)="utility.resetPage()">
                <img
                    [ngClass]="secBranding == true ? 'vec-sec-logos' : ''"
                    aria-label="Victorian Energy Compare"
                    alt="Victorian Energy Compare"
                    class="vec_logo {{ vecLogo }}"
                    src='{{ appURL }}/assets/images/{{ secImageURL }}header_600_vec_logo.png'>
            </a>
        </div>
    </div>

    <div class="nav-pages">
        <div class="container">
            <div class="row bs-wizard wizard-height" *ngIf="router.url == '/offline'">
            </div>
            <div class="row bs-wizard" *ngIf="router.url != '/offline' && router.url != '/solar-calculator' && router.url != '/solar-details' && router.url != '/solar-results'">
                <div class="col-md-2 col-sm-2 col-xs-2 bs-wizard-step {{ showProgress('step1') }} {{ checkPSBMenu() }}">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step1')" class="bs-wizard-dot non-focus" title="Start" aria-label="Start">✓</a>
                    <div class="text-center bs-wizard-stepnum">Start</div>
                </div>
                <div
                    class="col-md-2 col-sm-2 col-xs-2 bs-wizard-step {{ showProgress('step2') }} {{ checkPSBMenu() }}"
                    [ngClass]="(URLRoutes.indexOf(router.url) != -1 && energyProfilePage == 'false' && energyQuestionnairePage == 'false') ? 'disabled complete' : ''">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step2')" class="bs-wizard-dot non-focus" title="Your energy profile" aria-label="Your energy profile">
                        {{ (URLRoutes.indexOf(router.url) != -1) ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Your energy profile</div>
                </div>

                <div
                    class="col-md-2 col-sm-2 col-xs-2 bs-wizard-step {{ showProgress('step3') }} {{ checkPSBMenu() }}"
                    [ngClass]="(URLRoutes2.indexOf(router.url) != -1 && billDetailsPage == 'false') ? 'disabled complete' : ''">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step3')" class="bs-wizard-dot non-focus" title="Consumption" aria-label="Consumption">
                        {{ (URLRoutes2.indexOf(router.url) != -1) ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Consumption</div>
                </div>

                <div class="col-md-2 col-sm-2 col-xs-2 bs-wizard-step {{ showProgress('step4') }} {{ checkPSBMenu() }}" *ngIf="getPageEnergyType() != 'Solar'">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step4')" class="bs-wizard-dot non-focus" title="Available Offers" aria-label="Available Offers">
                        {{ (router.url.indexOf('/psb-offers') != -1 || router.url.indexOf('/offers') != -1 || router.url == '/contact' || router.url.indexOf('/compare') != -1) ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Available Offers</div>
                </div>

                <div class="col-md-2 col-sm-2 col-xs-2 bs-wizard-step {{ showProgress('step5') }} {{ checkPSBMenu() }}">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step5')" class="bs-wizard-dot non-focus" title="Compare Offers" aria-label="Compare Offers">
                        {{ (router.url == '/contact' || router.url.indexOf('/compare') != -1) ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Compare Offers</div>
                </div>

                <div class="col-md-2 col-sm-2 col-xs-2 bs-wizard-step last-step {{ showProgress('step6') }} {{ checkPSBMenu() }}">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 class="bs-wizard-dot non-focus" title="Contact Retailer" aria-label="Contact Retailer">
                        {{ (router.url == '/contact')  ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Contact Retailer</div>
                </div>
            </div>

            <div class="row bs-wizard" *ngIf="router.url != '/offline' && (router.url == '/solar-calculator' || router.url == '/solar-details' || router.url == '/solar-results')">
                <div class="col-md-4 col-sm-4 col-xs-4 bs-wizard-step {{ showProgress('step1') }}">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step1')" class="bs-wizard-dot non-focus" title="Start" aria-label="Start">✓</a>
                    <div class="text-center bs-wizard-stepnum">Start</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4 bs-wizard-step {{ showProgress('step2') }}">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 (click)="navigateRoutes('step2')" class="bs-wizard-dot non-focus" title="Your energy profile" aria-label="Electricity Details">
                        {{ (router.url == '/solar-details' || router.url == '/solar-results') ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Your energy profile</div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-4 bs-wizard-step last-step {{ showProgress('step3') }}">
                    <div class="progress">
                        <div class="progress-bar progress-bar-success progress-bar-striped"></div>
                    </div>
                    <a tabindex=-1 class="bs-wizard-dot non-focus" title="Contact Retailer" aria-label="Contact Retailer">
                        {{ (router.url == '/solar-results')  ? '✓' : '' }}
                    </a>
                    <div class="text-center bs-wizard-stepnum">Results</div>
                </div>
            </div>
        </div>
    </div>
    <div class="yellow_banner_psb" *ngIf="router.url.startsWith('/embedded-networks') && dataService.ebrMode != 'off-c7b1e41' && dataService.ebrMode != ''">

      <div class="container long-desc">
          <div *ngIf="dataService.ebrMode == 'on-zae9eaCh'">
            <h4>2024-25 Energy Bill Relief for Embedded Network Customers</h4>
            <p>The Australian Government is providing $3.5 billion to extend and expand the Energy Bill Relief Fund into the 2024-25 financial year. The 2024-25 program provides electricity bill rebates to all Australian households and eligible small businesses to ease cost of living pressures.</p>
            <p>Victorian households will receive $300 and small businesses will receive $325 to help with the cost of their electricity bills.</p>
            <p></p>
          </div>

          <div>
            <h4>
              Embedded Network Customers
            </h4>
            <p>
              Embedded network households and eligible small businesses can apply for the one-off payment via an application on this website in early October 2024.
            </p>
            <p>
              Embedded network customers of Origin, WinConnect and Momentum will have their bill relief automatically applied via their retailer.
            </p>
            <p>Learn more about the <a href="https://www.energy.vic.gov.au/households/help-paying-your-energy-bills/energy-bill-relief-fund" target="_blank">Energy Bill Relief Fund</a>.</p>
          </div>

          <div *ngIf="dataService.ebrMode == 'on-3b95151'">
              <p></p>
              <h4>2023-24 Energy Bill Relief Program for Embedded Network Households</h4>
              <p>
                    The Commonwealth Government is partnering with the Victorian Government to provide electricity bill relief to eligible households
                    through the Energy Bill Relief program. Embedded network households that meet the eligibility criteria for the program will be able to
                    apply for a once-off $250 payment.
              </p>
              <p>
                <strong>
                  The 2023-24 Energy Bill Relief Program for Embedded Network Households will close for new applications on 30 June 2024. No further applications will be accepted after this date.
                </strong>
              </p>
              <br>
              <h4>
                  Eligibility
              </h4>
              <p>Embedded network households are eligible if the primary electricity account holder, or another household member named on the account, receives or holds one of the following: </p>
              <ul>
                  <li>
                    Pensioner Concession Card (PCC) issued by Services Australia or Department of Veterans’ Affairs (DVA)
                  </li>
                  <li>
                    Department of Veterans’ Affairs Gold Card (GCH) For All Conditions
                  </li>
                  <li>
                    Health Care Card (HCC) issued by Services Australia
                  </li>
                  <li>
                    Carer Allowance
                  </li>
                  <li>
                    Commonwealth Seniors Health Card (SHC) issued by Services Australia or Department of Veterans’ Affairs
                  </li>
                  <li>
                    Family Tax Benefit A & B
                  </li>
              </ul>
              <p>
                You will need a copy of your most recent electricity bill and the details of your eligible Centrelink or DVA card to apply.
              </p>
              <p>
                If you meet the eligibility requirements but do not live in an embedded network, you should receive this bill relief automatically
                through your energy retailer. For further information on eligibility and how payments are made please visit the <a href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills">Help paying your bills</a> page.
              </p>
          </div>

          <p *ngIf="dataService.ebrMode == 'maintenance'" class="ebr_maintenance">
            The Energy Bill Relief website is under maintenance and will be back shortly.
          </p>

          <br>
          <div class="row">
              <div class="col-md-8 text-center">
                  <form method="post" id="EBRForm" name="EBRForm" action="{{ ebrURL }}" target="{{ (dataService.ebrMode == 'on-3b95151') ? '' : getTarget() }}" ngNoForm>
                      <input type="hidden" name="ts" value="{{ ebrts }}" />
                      <input type="hidden" name="hmac" value="{{ ebrHmac }}" />
                      <input type="hidden" name="session_id" value="{{ sessionId }}" />
                      <input type="submit" value="Submit an Energy Bill Relief application" class="custom-btn-large custom-btn-large-text" (click)="logEBRJourney()" *ngIf="dataService.ebrMode == 'on-3b95151'">
                      <input type="submit" value="Submit an Energy Bill Relief application" disabled="disabled" class="ebr_maintenance custom-btn-large custom-btn-large-text"  *ngIf="dataService.ebrMode == 'maintenance'">
                  </form>
              </div>

              <div class="col-md-4 text-right faqLink" *ngIf="dataService.ebrMode == 'on-3b95151'">
                  <a id="EBRpopuplink" data-toggle="modal" (click)="ebrFAQ()" class="link">Frequently Asked Questions</a>
              </div>
          </div>
      </div>

  </div>
</header>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
</ngx-spinner>
