import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';
import { Router } from '@angular/router';

@Component({
  selector: 'app-embedded-networks',
  templateUrl: './embedded-networks.component.html',
  styleUrls: ['./embedded-networks.component.css']
})
export class EmbeddedNetworksComponent implements OnInit {

  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public bannerTitle = "Embedded Networks";
  public bannerSubTitle = "A guide to electricity embedded networks in Victoria.";
  public bannerDetails = "";

  constructor(
    public router : Router,
    public utility : Utility,
  ) { }

  ngOnInit() {
    this.router.navigate([], { queryParams: {} });
    this.utility.maintenanceMode(); //check if its in maintenance mode
  }

}
