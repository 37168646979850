<div class="container">
    <br />
    <app-offers-header-panel id="top-filters"
        [hasConcession]="hasConcession"
        [userType]="userType"
        [bill]="bill"
        [averageDailyConsumption]="averageDailyConsumption"
        [loopBackAverageDailyConsumption]="loopBackAverageDailyConsumption"
        [topOfferRate]="topOfferRate"
        [offersHasSolar]="offersHasSolar"
        [mostExpensiveOfferRate]="mostExpensiveOfferRate"
        [energyType]="energyType"
        [loopBackEnergyType]="loopBackEnergyType"
        [showFilters]="showFilters"
        [offerTypes]="offerTypes"
        [showTouOffer]="showTouOffer"
        [loopBackIsEmpty]="loopBackIsEmpty"
        [showOfferTypesFilter]="showOfferTypesFilter"
        [loopBackHasOffers]="loopBackHasOffers"
        [showFlexiblePricing]="showFlexiblePricing"
        [showSingleRate]="showSingleRate"
        [sortByConditionalPrice]="sortByConditionalPrice"
        [showTopOffer]="showTopOffer"
        (resetResult)='resetResult()'
        (groupOffers)='groupOffers()'
        (toggleFilters)='toggleFilters($event)'
        (updateFilters)='updateFilters($event)'
        (click)="utility.checkFilters()">
    </app-offers-header-panel>
    <br />
    <div class="row" *ngIf="showFilters == true" id="offers-div">
        <app-offers-left-panel
            class="col-lg-2"
            (compareSelected)='compareSelected()'
            (resetResult)='resetResult()'
            (updateFilters)='updateFilters($event)'
            [showTouOffer]="showTouOffer"
            [showFlexiblePricing]="showFlexiblePricing"
            [showSingleRate]="showSingleRate"
            [showOfferTypesFilter]="showOfferTypesFilter"
            [showResultsFilter]="showResultsFilter"
            [showRetailersFilter]="showRetailersFilter"
            [showFeaturesFilter]="showFeaturesFilter"
            [showContractsFilter]="showContractsFilter"
            [showResourcesFilter]="showResourcesFilter"
            [selectAllRetailers]="selectAllRetailers"
            [selectAllContracts]="selectAllContracts"
            [sortByConditionalPrice]="sortByConditionalPrice"
            [showTopOffer]="showTopOffer"
            [energyType]="energyType"
            [showIncentive]="showIncentive"
            [showGreenPower]="showGreenPower"
            [showGoGreenFilter]="showGoGreenFilter"
            [showPayment]="showPayment"
            [showVDO]="showVDO"
            [showCustomEligibility]="showCustomEligibility"
            [showDemand]="showDemand"
            [showInnovative]="showInnovative"
            [selectedContracts]="selectedContracts"
            [selectedRetailers]="selectedRetailers"
            [retailers]="retailers"
            [features]="features"
            [selectedOffers]="selectedOffers"
            [compareIsDisabled]="compareIsDisabled"
            [contractLength]="contractLength">
        </app-offers-left-panel>
        <app-offers-right-panel
            (click)="utility.checkFilters()"
            class="col-lg-10"
            (compareSelected)='compareSelected()'
            (showMoreOffers)='showMoreOffers()'
            (updateSelectedOffers)='updateSelectedOffers($event)'
            [pageLoad]="pageLoad"
            [retailers]="retailers"
            [offers]="offers"
            [electricityOffers]="electricityOffers"
            [dualOffers]="dualOffers"
            [gasOfferIndex]="gasOfferIndex"
            [gasOffers]="gasOffers"
            [energyType]="energyType"
            [electricityGasConnection]="electricityGasConnection"
            [loopBackIsEmpty]="loopBackIsEmpty"
            [loopBackHasOffers]="loopBackHasOffers"
            [loopBackDistributors]="loopBackDistributors"
            [offersCount]="offersCount"
            [offersShown]="offersShown"
            [selectedOffers]="selectedOffers"
            [fileUpload]="fileUpload"
            [getMyData]="getMyData"
            [showFilters]="showFilters">
        </app-offers-right-panel>
    </div>
    <div class="row" *ngIf="showFilters == false" id="offers-div">
        <app-offers-right-panel
            (click)="utility.checkFilters()"
            class="col-lg-12"
            (compareSelected)='compareSelected()'
            (showMoreOffers)='showMoreOffers()'
            (updateSelectedOffers)='updateSelectedOffers($event)'
            [pageLoad]="pageLoad"
            [retailers]="retailers"
            [offers]="offers"
            [electricityOffers]="electricityOffers"
            [dualOffers]="dualOffers"
            [gasOfferIndex]="gasOfferIndex"
            [gasOffers]="gasOffers"
            [energyType]="energyType"
            [electricityGasConnection]="electricityGasConnection"
            [loopBackIsEmpty]="loopBackIsEmpty"
            [loopBackHasOffers]="loopBackHasOffers"
            [offersCount]="offersCount"
            [offersShown]="offersShown"
            [selectedOffers]="selectedOffers"
            [fileUpload]="fileUpload"
            [getMyData]="getMyData"
            [showFilters]="showFilters">
        </app-offers-right-panel>
    </div>

    <br /><br />
    <a class="open-filters-btn" id="open-filters-btn" (click)="utility.openFilters()">
        <i class="fa fa-sliders slider-icon"></i>
    </a>
    <a class="close-filters-btn" id="close-filters-btn" (click)="utility.closeFilters()">
        <i class="fa fa-times slider-icon"></i>
    </a>
</div>
