import { Component, OnInit } from '@angular/core';
import { DownloadDataModalComponent } from '../download-data-modal/download-data-modal.component';
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';

@Component({
  selector: 'app-smart-meter-modal',
  templateUrl: './smart-meter-modal.component.html',
  styleUrls: ['./smart-meter-modal.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class SmartMeterModalComponent implements OnInit {
  public distributor;
  public postcode;

  constructor(
    public activeModal: NgbActiveModal,
    public dataService : DataService,
    public spinner : NgxSpinnerService,
    public modalService : NgbModal) { }

  ngOnInit() {
  }

  public downloadDataModal() {
    this.modalService.dismissAll();
    this.spinner.show();
    this.dataService.getDistributorDownloadInfo(this.distributor).subscribe(
      data => {
        this.spinner.hide();
        let modalRef = this.modalService.open(DownloadDataModalComponent, {size : 'lg'});
        modalRef.componentInstance.postcode = this.postcode;
        modalRef.componentInstance.distributorInfo = data['content'];
      },
      error => {
        this.spinner.hide();
      }
    );
  }

}
