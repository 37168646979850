import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from '../utility';
import { DataService } from '../data.service';

@Component({
  selector: 'app-psb-outreach',
  templateUrl: './psb-outreach.component.html',
  styleUrls: ['./psb-outreach.component.css']
})
export class PsbOutreachComponent implements OnInit {
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public bannerTitle = "$250 Power Saving Bonus – Community Outreach Program";
  public bannerSubTitle = "";
  public bannerDetails = "";
  public postcode = "";
  public postcodeNewValue = "";
  public outreachData : any;
  public errorMessage = '';
  public councilNames = [];

  constructor(
    public spinner : NgxSpinnerService,
    public dataService : DataService,
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = '$250 Power Saving Bonus – Community Outreach Program - Victorian Energy Compare';
  }

  public getOutreach(event) {
    this.errorMessage = '';
    let validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'v', 'V'];
    if (validKeys.includes(event.key)) {
      if (this.postcode.length == 4 && Number(this.postcode) >= 3000 && Number(this.postcode) <= 4000) {
        this.spinner.show();
        this.dataService.getOutreach(this.postcode).subscribe(
          results => {
            this.outreachData = results["data"];
            this.councilNames = (results["data"].length > 0) ? results["councilNames"] : [];
            if (results["data"].length == 0) {
              this.errorMessage = 'No participating sites were found in your area';
            }
            this.spinner.hide();
          },
          error => {
            this.spinner.hide();
          }
        );
      } else {
        this.outreachData = [];
        this.councilNames = [];
        this.checkInvalidPostcode();
      }
    } else {
      if ( (!(Number(this.postcode) >= 3000 && Number(this.postcode) <= 4000)) ) {
        this.outreachData = [];
        this.councilNames = [];
      }
      this.checkInvalidPostcode();
    }

  }

  private checkInvalidPostcode() {
    if (this.postcode != '' && this.postcode.length == 4 && !(Number(this.postcode) >= 3000 && Number(this.postcode) <= 4000)) {
      this.errorMessage = 'You have entered an invalid postcode';
    }
  }

}
