import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';
import { Router } from "@angular/router";

@Component({
  selector: 'app-solar-savings',
  templateUrl: './solar-savings.component.html',
  styleUrls: ['./solar-savings.component.css']
})
export class SolarSavingsComponent implements OnInit {
  public appURL = environment.appURL;
  public bannerTitle = "The Solar Journey";
  public bannerSubTitle = "A step by step guide, from beginning your solar journey to installation and maintenance.";
  public bannerDetails = "";

  constructor(
    public router : Router, 
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Solar Savings - Victorian Energy Compare';
  }

}
