import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';
import { Router } from "@angular/router";

@Component({
  selector: 'app-hot-topics-electricity-bill',
  templateUrl: './hot-topics-electricity-bill.component.html',
  styleUrls: ['./hot-topics-electricity-bill.component.css']
})
export class HotTopicsElectricityBillComponent implements OnInit {
  public appURL = environment.appURL;
  public bannerTitle = "Why is my electricity bill so high?";
  public bannerSubTitle = "";
  public bannerDetails = "";

  constructor(
    public router : Router,
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Hot Topics - Why is my electricity bill so high? - Victorian Energy Compare';
  }

  public mainHotTopics() {
    this.router.navigate(['/hot-topics']);
    this.utility.scrollToTop('none');
  }

}
