<div class="modal-header">
    <h3>Am I eligible?</h3>
    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h4>Concessions</h4>
    <p>
        If you have a relevant concession card, you may be able to get a concession.
    </p>
    <br />
    <p>Having trouble paying your energy bills? You may be eligible for a concession that will save you 17.5% or more off your gas and electricity bills</p>
    <br />
    <p>Victorian Energy Compare calculates two electricity concessions and one gas concession and applies these to your estimated bills:</p>
    <br />
    <ol>
        <li>
            <span class="strong">Electricity Concession</span> – gives you 17.5% discount off your electricity bills
        </li>
        <li>
            <span class="strong">Winter Gas Concession</span> – gives you 17.5% discount off your winter gas bills
        </li>
        <li>
            <span class="strong">Service to Property Concession</span> – where your electricity service charge may be reduced to your usage charge
        </li>
    </ol>
    <br />
    <p>
        <a title="Concessions and rebates" aria-label="Concessions and rebates" class="download" target="_blank" href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills">
            Find out about other concessions and rebates here
        </a>
    </p>
    <br />
    <p>
        Victorian Energy Compare does not calculate any other energy concessions.
    </p>

</div>
<div class="modal-footer">
    <button title="Close" aria-label="Close" type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>
