import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-powercor-info-modal',
  templateUrl: './powercor-info-modal.component.html',
  styleUrls: ['./powercor-info-modal.component.css']
})
export class PowercorInfoModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
