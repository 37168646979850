import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { environment } from '../../../environments/environment'; //config file
import { DataService } from '../../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageComponent implements OnInit {

  public language = "";
  public appURL = environment.appURL;
  public bannerTitle = "";
  public bannerSubTitle = "";
  public bannerDetails = "";
  public bannerHideTips = true;
  public bannerTextDirection = "";
  public pageContents;
  
  constructor(
    private route: ActivatedRoute, 
    public dataService : DataService,
    public spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer) {
      Window["LanguageComponent"] = this;
    }

  ngOnInit() {
    this.language = this.route.snapshot.paramMap.get("language");
    this.bannerTitle = this.language;
    this.spinner.show();
    this.dataService.getLanguage(this.language).subscribe(
      result => {
        this.bannerTitle = result["title"];
        this.bannerSubTitle = result["subtitle"];
        this.bannerTextDirection = (result["textDirection"] ? result["textDirection"] : 'ltr');
        this.spinner.hide();
      }, error => {
        this.bannerTitle = "404 - Page not found";
        this.pageContents = "The page you are looking for doesn't exist.";
        this.spinner.hide();
      }
    );

    this.dataService.getLanguageFile(this.language).subscribe(
      result => {
        this.pageContents = this.sanitizer.bypassSecurityTrustHtml(result.toString());
        this.spinner.hide();
      }, error => {
        this.bannerTitle = "404 - Page not found";
        this.pageContents = "The page you are looking for doesn't exist.";
        this.spinner.hide();
      }
    );

  }
  
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}
