import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-collection-statement-modal',
  templateUrl: './collection-statement-modal.component.html',
  styleUrls: ['./collection-statement-modal.component.css']
})
export class CollectionStatementModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
