<div class="modal-header" class="button">
    <div class="row col-md-12">
        <div class="col-md-12 modal-icons">
            <button class="hidden-btn"></button>
            <button type="" class="btn close btn-link non-focus" aria-label="Close"
                (click)="activeModal.close('Close click')">
                Close ×
            </button>
        </div>
    </div>
</div>

<div class="modal-content">
    <span class="h3 PSBpopupTitle">
        $250 Power Saving Bonus Program – FAQs
    </span>
    <div class="modal-body video-modal-body" id="modal-body">
        <label class="h4">
            What was the $250 Power Saving Bonus program?
        </label>
        <p>
            The Power Saving Bonus was a Victorian Government program that offered eligible Victorian households a
            $250 payment to help ease cost-of-living pressures and encourage them to compare energy offers and save
            money.
        </p>
        <label class="h4">
            How many rounds of the Power Saving Bonus were offered?
        </label>
        <p>
            The fourth round of the program ran from 24 March 2023 till 31 August 2023. Three previous rounds of the
            program were delivered between July 2018 and March 2023.
        </p>

        <label class="h4">
            When did applications for the $250 Power Saving Bonus close?
        </label>
        <p>
            The fourth round of the $250 Power Saving Bonus closed on 31 August 2023. New applications will not be
            accepted after this date.
        </p>
        <label class="h4">
            Will I still receive my $250 Bonus if my application wasn’t approved and paid by 31 August 2023?
        </label>
        <p>
            Yes, if you submitted your application by 11.59pm on 31 August 2023 and you meet the eligibility
            requirements (including having not previously applied) you will still receive your $250 Bonus.
        </p>
        <label class="h4">
            How long will it take to receive the $250 Power Saving Bonus if my application is still being processed
            after 31 August 2023?
        </label>
        <p>
            Complete applications are normally processed within 2 weeks of submission though there may be delays due to
            high demand.
        </p>
        <label class="h4">
            I applied through an Outreach Partner or the Victorian Energy Compare helpline – how do I contact them?
        </label>
        <p>
            If you applied with support from a community outreach partner and need to contact them about your
            application, please contact the organisation directly on the numbers listed below or call the Victorian
            Energy Compare helpline on <a href="tel:1800000832">1800 000 832</a>.
        </p>
        <p><u>Participating community organisations</u>:</p>

        <ul>
            <li><strong>Anglicare Victoria:</strong> <a href="tel:1800531741">1800 531 741</a></li>
            <li><strong>Bendigo Community Health Services</strong>: <a href="tel:(03)54061200">(03) 5406 1200</a>.</li>
            <li><strong>The Brotherhood of St. Laurence (and partners GV Community Energy and Uniting Vic.Tas)</strong>:
                <a href="tel:(03)99568850">(03) 9956 8850</a> or <a href="tel:1800 830 029">1800 830 029</a>.
            </li>
            <li><strong>Community Information & Support Victoria</strong>: <a href="tel:(03)96722003">(03) 9672 2003</a>
            </li>
            <li><strong>Ethnic Communities' Council Victoria</strong>: <a href="tel:0466038261">0466 038 261</a></li>
            <li><strong>Good Shepherd and Neighbourhood House</strong>: <a href="tel:1300026500">1300 026 500</a></li>
            <li><strong>The National Debt Helpline - Consumer Action Law Centre</strong>: <a href="tel:1800149689">1800
                    149 689</a></li>
            <li><strong>State Trustees</strong>: <a href="tel:1300138672">1300 138 672</a></li>
        </ul>
        <label class="h4">
            Will there be another round of the Power Saving Bonus?
        </label>
        <p>
            The fourth round of the Power Saving Bonus closed on 31 August 2023. New applications will not be accepted
            after this date.
        </p>
        <p>
            Help is available for households having difficulty paying their energy bills. The Victorian Government’s
            Energy Assistance Program can assist households struggling with power bills to apply for eligible grants and
            concessions, find and switch to better offers. For more information call <a href="tel:1800 161 215">1800 161
                215</a> or visit <a href="https://www.energy.vic.gov.au/for-households/energy-assistance-program"
                target="_blank">https://www.energy.vic.gov.au/for-households/energy-assistance-program</a>
        </p>
        <label class="h4">
            What is the Energy Bill Relief Program?
        </label>
        <p>
            The Commonwealth Government is partnering with states and territories to deliver $1.5 billion of targeted
            electricity bill relief to eligible Australian households and small businesses under the Energy Bill Relief
            plan.
        </p>
        <p>
            Eligible Victorian households can receive $250 in addition to the Victorian Government’s $250 Power Saving
            Bonus. The $250 rebate will be provided in periodic instalments on bills during 2023-24. To learn more about
            the eligibility criteria and how payments will be made visit the <a
                href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills" target="_blank"> Help paying
                your bills</a> page.
        </p>
        <label class="h4">
            Who was eligible for the $250 Power Saving Bonus?
        </label>
        <p>
            To be eligible for the $250 Power Saving Bonus, Victorian households were required to:
        </p>
        <ul>
            <li>
                Have a recent residential electricity bill.
            </li>
            <li>Be the account holder for that bill</li>
            <li>
                Visit the <a href="https://compare.energy.vic.gov.au/" target="_blank">Victorian Energy Compare</a>
                website or engage with an energy affordability service through one of our participating community
                outreach partners.
            </li>
        </ul>
        <label class="h4">
            I have lost my Power Saving Bonus application number.
        </label>
        <p>
            If you have lost your application number, you can request it to be emailed to you by contacting <a
                href="mailto:info.vec@deeca.vic.gov.au">info.vec@deeca.vic.gov.au</a>.
        </p>

        <label class="h4">
            I have not received an email from you. How can I contact you to follow-up?
        </label>
        <p>
            If you have not received an email about your application, we recommend waiting 10 business days from
            submission before following-up on its status. We suggest that you also check your Spam/ Junk email folder
            for any missed emails.
        </p>
        <p>If you have still not received a response after 10 business days, our support team can be
            contacted at <a href="mailto:info.vec@deeca.vic.gov.au">info.vec@deeca.vic.gov.au</a>.</p>

        <label class="h4">
            How long does it take to receive the $250 Power Saving Bonus?
        </label>
        <p>
            If your application is successful, you should receive your $250 payment via Electronic Fund Transfer (EFT)
            within 3-15 business days of submission if you meet the eligibility and documentation requirements. Cheque
            payments will take a little longer to process but should be received within 5-20 business days if you meet
            the eligibility and documentation requirements. Incomplete applications may take longer to process.
        </p>

        <label class="h4">
            How will the $250 Power Saving Bonus be paid?
        </label>
        <p>
            The $250 Power Saving Bonus will generally be paid via Electronic Funds Transfer (EFT) to your nominated
            bank account, or by bank cheque upon request. In certain circumstances, it may be necessary for your payment
            to be made via bank cheque.
        </p>

        <label class="h4">
            I haven't received my $250 Bonus yet.
        </label>
        <p>
            EFT payments should be received within 3-15 business days of receiving email confirmation. Cheque payments
            take a little longer to process but should be received within 5-20 business OFFICIAL days of receiving email
            confirmation. If you haven't received your $250 Bonus within thesetimeframes, you can contact Victorian
            Energy Compare by emailing <a href="mailto:info.vec@deeca.vic.gov.au">info.vec@deeca.vic.gov.au</a>.
        </p>

        <label class="h4">
            How will I know my details won't get used for something else?
        </label>
        <p>
            When you applied for the $250 Bonus you were asked to provide certain information to confirm
            your eligibility. Any personal information you provided will only be used for these purposes and
            will be held and used in accordance with the Victorian Energy Compare website's privacy
            statement, which is located at <a href="https://compare.energy.vic.gov.au/privacy"
                target="_blank">https://compare.energy.vic.gov.au/privacy</a>.
        </p>
        <label class="h4">
            I want to submit an enquiry.
        </label>
        <p>
            If you have already submitted an application we recommend waiting 10 business days from submission before
            following up. We also
            suggest that you check your Spam/ Junk email folder for any missed emails.
        </p>
        <p>
            All enquiries can be submitted to our support team at <a href='mailto:info.vec@deeca.vic.gov.au'
                class="link">info.vec@deeca.vic.gov.au</a>.
        </p>
        <label class="h4">
            I want to provide feedback.
        </label>
        <p>
            Victorian Energy Compare values feedback. If you wish to provide feedback please use the form located at <a
                href="https://compare.energy.vic.gov.au/feedback"
                target="_blank">https://compare.energy.vic.gov.au/feedback</a>.
        </p>

    </div>
    <div class="close-btn">
        <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">Close</button>
    </div>
</div>
