<div class="modal-body" class="sorting-border">
    <br />
    <h4>How would you like Victorian Energy Compare to display your list of results?</h4>

    <div class="container_outer">
        <div class="container_inner">
            <div class="col-lg-1 col-md-1">&nbsp;</div>
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <ol class="list-group">
                    <li class="list-group-item">
                        <div class="sorting_label">
                            1. Sort offers based on:
                        </div>
                        <button 
                            class="btn btn-margin" 
                            [ngClass]="(options.sortByConditionalPrice == true) ? 'btn-primary' : 'btn-light'"
                            (click)="changeSort(true)">
                            Discounted price
                        </button>
                        <button class="btn"
                            [ngClass]="(options.sortByConditionalPrice == false) ? 'btn-primary' : 'btn-light'"
                            (click)="changeSort(false)">
                            Price
                        </button>
                    </li>
                    <li class="list-group-item">
                        <div class="sorting_label">
                            2. Show:
                        </div>
                        <button 
                            class="btn btn-margin btn-margin-bottom" 
                            [ngClass]="(options.showTopOffer == true) ? 'btn-primary' : 'btn-light'"
                            (click)="changeOffers(true)">
                            Only each retailer's best offer
                        </button>
                        <button 
                            class="btn"
                            [ngClass]="(options.showTopOffer == false) ? 'btn-primary' : 'btn-light'"
                            (click)="changeOffers(false)">
                            All offers
                        </button>
                    </li>
                    <li class="list-group-item">
                        <button class="btn btn-display-results" (click)="activeModal.close(options)">Display your list of results</button>
                    </li>
                </ol>
            </div>
            <div class="col-lg-1 col-md-1">&nbsp;</div>
            <div class="clear"></div>
        </div>
    </div>
    <p class="description">
        'Price' includes all guaranteed discounts - These are discounts that you are entitled to and don't 
        need to do anything to receive. 'Discounted Price' includes both guaranteed and conditional 
        discounts (E.g. Pay on time discounts, direct debit discounts).
    </p>
    <br />
    <p class="description">
        'Results' includes all generally available offers in your distribution zone, based on your energy 
        profile - Offers are listed in order of best price, and to see some offers you will need to use 
        filter settings on the left hand side, as those filters include some offers not shown in the 
        default list.
    </p>

</div>