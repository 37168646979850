import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';

@Component({
  selector: 'app-retailer-info-modal',
  templateUrl: './retailer-info-modal.component.html',
  styleUrls: ['./retailer-info-modal.component.css']
})
export class RetailerInfoModalComponent implements OnInit {
  public retailer;
  constructor(
    public activeModal: NgbActiveModal,
    public dataService : DataService) { }

  ngOnInit() {
  }

}
