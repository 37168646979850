<div id="div-results">
    <div class="container error" *ngIf="utility.getErrorMessage() != ''">
        <br />
        <div class="alert alert-danger">
            <i class="fa fa-warning"></i>&nbsp;<span [innerHTML]="utility.getErrorMessage()"></span>
        </div>
    </div>

    <div class="container" *ngIf="pageIsloaded == true">
        <br />
        <app-solar-header-panel [solarData]="selectedSolarData" [SolarEstimatesData]="SolarEstimatesData"></app-solar-header-panel>
        <div class="row" id="row-results">
            <app-solar-left-panel class="col-lg-4" (changeOption)="changeOption($event)" (updateEstimates)='updateEstimates($event)' [download]="dataService.download" [solarData1]="solarData1" [solarData2]="solarData2" [solarData3]="solarData3" [solarData]="selectedSolarData" [SolarEstimatesData]="SolarEstimatesData" [selectedOption]="selectedOption" ></app-solar-left-panel>
            <app-solar-right-panel class="col-lg-8" [download]="dataService.download" [solarData]="selectedSolarData" [SolarEstimatesData]="SolarEstimatesData" [selectedOption]="selectedOption" [solarCompareTable]="solarCompareTable"></app-solar-right-panel>
        </div>
        <div data-html2canvas-ignore="true" *ngIf="dataService.download == false">
            <div class="row">
                <h3 class="btn-heading col-lg-12">Compare</h3>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <a (click)="electricityOffers()" tabindex="14" class="solar-btn">
                        <label for="electricity" class="compare-btn col-lg-12">
                            <i class="material-icons button-icons" aria-hidden="true">flash_on</i>
                            Electricity Offers
                        </label>
                    </a>
                </div>
                <div class="col-lg-3">
                    <a (click)="gasOffers()" tabindex="15" class="solar-btn">
                        <label for="gas" class="compare-btn col-lg-12">
                            <i class="material-icons button-icons" aria-hidden="true">whatshot</i>
                            Gas Offers
                        </label>
                    </a>
                </div>

                <div class="col-lg-3">
                    <a id="downloadBtn" (click)="downloadOptionsModal()" tabindex="16" class="solar-btn">
                        <label for="download" class="compare-btn-green col-lg-12 download-results">
                            Download Results
                        </label>
                    </a>
                </div>

                <div class="col-lg-3">
                    <a id="emailPdfLink" tabindex="17" (click)="emailOptionsModal()" class="solar-btn">
                        <label for="email" class="compare-btn-green email-pdf col-lg-12">
                            Email Results
                        </label>
                    </a>
                </div>

            </div>
            <div class="row">
                <br />
                <p class="disclaimer">
                    * Information provided by The Solar Calculator is general in nature and does not take into
                    consideration your personal financial situation, or property-specific factors that may impact the
                    effectiveness of PV solar panels for your property such as shading, roof area, roof pitch, and aspect.
                    Calculation of solar benefit estimates are based on user-supplied post code and energy costs, historical
                    consumption, and a blend of typical roof pitches and aspects.
                </p>
                <p class="disclaimer">
                    Solar benefit estimates do not constitute formal financial advice or a formal assessment of the suitability
                    of PV solar panels for your property. Before acting, you should consider seeking independent personal
                    financial advice that is tailored to your needs, as well as a quote from a qualified solar installer.
                </p>
                <p class="disclaimer">
                    Please speak to your solar installer and your electricity distributor to confirm whether you are
                    able to export electricity to the grid before you decide to install solar panels. Some properties
                    are unable to export electricity, or have limits on how much electricity can be exported. These
                    limits and restrictions (known as 'export limiting') are based on the electricity infrastructure
                    in your area and the rules can vary across different distribution zones in Victoria.
                </p>
            </div>
        </div>
        <br />
        <br />
    </div>
</div>
<br />

