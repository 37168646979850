import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'; //config file
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PsbModalComponent } from '../psb-modal/psb-modal.component';
import { PsbRegistrationModalComponent } from '../psb-registration-modal/psb-registration-modal.component';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
import { Utility } from '../utility';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { EbrModalComponent } from '../ebr-modal/ebr-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers : [ NgbModalConfig, NgbModal ]
})
export class HeaderComponent implements OnInit {
  public isIE = false;
  public ts = new Date().getTime();
  public ebrts = new Date().getTime();
  public hmac = '';
  public ebrHmac = '';
  public sessionId = '';
  public PSBForm : FormGroup;
  public EBRForm : FormGroup;
  public appURL = environment.appURL;
  public psbURL = environment.psbURL;
  public psb3URL = environment.psb3URL;
  public ebrURL = environment.ebrURL;
  public secBranding = environment.secBranding;
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public secCss = (environment.secBranding == true) ? 'top-bar-sec' : '';
  public vecLogo = (environment.secBranding == true) ? 'vec-logo-sec' : '';
  public moreDetails = false;
  public billDetailsPage;
  public loopBack;
  public energyProfilePage;
  public energyQuestionnairePage;
  public energyType = '';
  public billSubscription: Subscription;
  public profileSubscription: Subscription;
  public questionnaireSubscription: Subscription;
  public loopBackSubscription: Subscription;
  public URLRoutes = [
                      '/psb-offers',
                      '/offers',
                      '/offers/list/electricity',
                      '/offers/list/gas',
                      '/offers/list/dual',
                      '/contact',
                      '/loopback/compare',
                      '/compare',
                      '/compare/electricity',
                      '/compare/gas',
                      '/energy-questionnaire',
                      '/small-business-energy-questionnaire',
                      '/bill-details',
                      '/solar-details',
                      '/solar-results',
                      '/loopback/energy-questionnaire',
                      '/loopback/bill-details'
                    ];
  public URLRoutes2;

  constructor(
    public utility : Utility,
    public config : NgbModalConfig,
    public modalService : NgbModal,
    public router : Router,
    public dataService : DataService) {}

  ngOnInit() {
    let ua = window.navigator.userAgent;
    this.isIE = /MSIE|Trident/.test(ua);

    this.billSubscription = this.dataService.billDetails.subscribe(status => this.billDetailsPage = status);
    this.profileSubscription = this.dataService.energyDetails.subscribe(status => this.energyProfilePage = status);
    this.questionnaireSubscription =  this.dataService.energyQuestionnaireDetails.subscribe(status => this.energyQuestionnairePage = status);
    this.loopBackSubscription = this.dataService.loopBackDetails.subscribe(status => this.loopBack = status);
    this.URLRoutes2 = this.URLRoutes.filter(e => e !== '/energy-questionnaire');
    this.URLRoutes2 = this.URLRoutes2.filter(e => e !== '/loopback/energy-questionnaire');
    this.URLRoutes2 = this.URLRoutes2.filter(e => e !== '/small-business-energy-questionnaire');

    var serverCacheId = localStorage.getItem("serverCacheId");

    this.dataService.getEBRDetails(serverCacheId).subscribe(
      results => {
        this.ebrts = results.ts;
        this.ebrHmac = results.hmac;
        this.sessionId = results.serverCacheId;
        if (results.serverCacheId != undefined) {
          localStorage.setItem("serverCacheId", results.serverCacheId);
        }
      },
      error => {
      },
    );

    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.existing != false) {
            this.dataService.PSBReference = (results.PSBReference != undefined) ? results.PSBReference : '';
            let data = results.energyConfigData;
            this.energyType = data.energyType;
          }
        }
      );
    }

    //log journey progress
    this.utility.logJourneyProgress('homepage_landed');
  }

  public getPageEnergyType() : string {
    return this.dataService.pageEnergyType;
  }

  public navigateRoutes(step) {
    if (step == "step1") {
      if (this.router.url.indexOf('solar') != -1 && this.router.url != '/solar-savings') {
        this.router.navigate(['/solar-calculator']);
      } else {
        if (this.router.url.indexOf("loopback") != -1 || this.loopBack == 'true') {
          this.router.navigate(['/loopback/start']);
        } else {
          this.utility.redirectToHome();
        }
      }
    } else if (step == "step2") {
      if (this.router.url.indexOf('solar') != -1) {
        this.router.navigate(['/solar-details']);
      } else {
        if (this.router.url.indexOf("loopback") != -1 || this.loopBack == 'true') {
          this.router.navigate(['/loopback/energy-questionnaire']);
        } else {
          this.router.navigate([this.router.url]);
        }
      }
    } else if (step == "step3") {
      if (this.loopBack == 'true') {
        this.router.navigate(['/loopback/bill-details']);
      } else {
        this.router.navigate(['/bill-details']);
      }
    } else if (step == "step4") {
      if (this.getPageEnergyType() == 'dual') {
        this.router.navigate(['/offers/list/dual']);
      } else {
        let returnPage = this.dataService.getReturnPage();
        returnPage = (returnPage.indexOf("gas") != -1) ? 'offers/list/gas' : 'offers';
        this.router.navigate(['/' + returnPage]);
      }
    } else if (step == "step5") {
      if (this.getPageEnergyType() == 'dual') {
        this.router.navigate(['/loopback/compare']);
      } else {
        this.router.navigate(['/compare']);
      }
    } else {
      this.router.navigate(['/contact']);
    }
  }

  public showProgress(step) {
    var currentClass = 'disabled';


    if (step == "step1") {
      if (this.router.url.indexOf('solar') != -1) {
        currentClass = 'active';
        if (this.router.url == '/solar-details' || this.router.url == '/solar-results') {
          currentClass = 'complete';
        }
      } else {
        if (this.URLRoutes.indexOf(this.router.url) != -1) {
          currentClass = 'complete';
        } else {
          currentClass = 'active';
        }
      }
    } else if (step == "step2") {
      if (this.router.url.indexOf('/solar') != -1) {
        if (this.router.url == '/solar-details') {
          currentClass = 'active';
        } else if (this.router.url == '/solar-results') {
          currentClass = 'complete';
        }
      } else {
        if (this.router.url.indexOf('/energy-questionnaire') != -1 || this.router.url.indexOf('/small-business-energy-questionnaire') != -1) {
          currentClass = 'active';
        } else if (this.URLRoutes.indexOf(this.router.url) != -1) {
          currentClass = 'complete'
        }
      }
    } else if (step == "step3") {
      if (this.router.url.indexOf('/solar') != -1) {
        if (this.router.url == '/solar-results') {
          currentClass = 'complete';
        }
      } else {
        if (this.router.url.indexOf('/bill-details') != -1) {
          currentClass = 'active';
        } else if (this.URLRoutes2.indexOf(this.router.url) != -1) {
          currentClass = 'complete';
        }
      }
    } else if (step == "step4") {
      if (this.router.url.indexOf('/offers') != -1 || this.router.url.indexOf('/psb-offers') != -1) {
        currentClass = 'active';
      } else {
        currentClass = (this.router.url.indexOf('/compare') != -1 || this.router.url == '/contact') ? 'complete' : 'disabled';
      }
    } else if (step == "step5") {
      if (this.router.url.indexOf('/compare') != -1) {
        currentClass = 'active';
      } else {
        currentClass = (this.router.url == '/contact') ? 'complete' : 'disabled';
      }
    } else {
      currentClass = (this.router.url == '/contact') ? 'complete' : 'disabled';
    }
    return currentClass;
  }

  public psb3Registration() {
    let modalRef = this.modalService.open(PsbRegistrationModalComponent, { size : 'xl' });
    modalRef.result.then((result) => {
      this.redirectToHome();
    }, (reason) => {
      this.redirectToHome();
    });
  }

  public redirectToHome() {
    if (this.router.url.indexOf('psb-enquiry') != -1) {
      this.utility.redirectToHome();
    }
  }

  public psbFAQ() {
    this.modalService.open(PsbModalComponent, { size : 'xl' });
  }

  public ebrFAQ() {
    this.modalService.open(EbrModalComponent, { size : 'xl' });
  }

  public showDetails() {
    this.moreDetails = true;
  }

  public disableSolarMenu() {
    let disableMenu = false;
    if (this.router.url == '/solar-details' || this.router.url == '/solar-results') {
      if (this.energyType != 'Solar') {
        disableMenu = true;
      }
    }
    return disableMenu;
  }

  public psb2Application() {
    document.forms["psb2ClickThrough"].submit();
  }

  public getTarget() {
    let target = '_blank';
    var ua = navigator.userAgent;
    if ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf("GSA") > -1)) {
      target = '';
    }
    return target;
  }

  public logPSBJourney() {
    //log journey progress
    let serverCacheId = localStorage.getItem("serverCacheId");
    this.dataService.logJourneyProgress(serverCacheId, 'psb_visited').subscribe(
      data => {
        //this.submitPSBForm();
      },
      error => {
        //there is an error writing the journey log, error information is added on the log file
        //this.submitPSBForm();
      }
    );
  }

  public logEBRJourney() {
    //log journey progress
    let serverCacheId = localStorage.getItem("serverCacheId");
    this.dataService.logJourneyProgress(serverCacheId, 'ebr_visited').subscribe(
      data => {
      },
      error => {
      }
    );
  }

  private submitPSBForm() {
    document.forms["PSBForm"].submit();
  }

  private submitEBRForm() {
    document.forms["EBRForm"].submit();
  }

  public checkPSBMenu() {
    let CSSClass = '';
    if ((this.router.url.indexOf('offers') != -1 || this.router.url.indexOf('compare') != -1 || this.router.url.indexOf('contact') != -1) && this.dataService.PSBReference != ''    ) {
      CSSClass = 'd-none';
    }
    return CSSClass;
  }

  public getSelectedEnergyType() {
    return sessionStorage.getItem("selectedEnergyType");
  }

}
