import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SolarCalculator {
  constructor() {

  }

  public chart1Headings = ['Time', 'Your average half hourly solar energy generation', 'Your average half hourly energy usage'];
  public chart1Options = {
      'height' : 280,
      'width' : "100%",
      title: 'Average energy generation and usage',
      hAxis: {
          title: 'Time of Day',
          titleTextStyle: {
              color: '#333'
          },
          slantedText:true,
          slantedTextAngle: 45
      },
      vAxis: {
          title: 'Energy (kWh)',
          viewWindowMode: 'explicit',
          viewWindow: {
              min: 0
          }
      },
      curveType: 'function',
      legend: 'none',
      lineWidth: 3.5,
      colors: ['#f46b42', '#4262f4'],
  };

  public chart2Headings = ['Month', 'Saving from reduced grid energy use' , 'Saving from exporting excess solar energy to grid (i.e. feed-in tariff)'];
  public chart2Options = {
      'height' : 280,
      'width': '100%',
      isStacked: true,

      hAxis: {
          title: '',
          titleTextStyle: {
              color: '#333'
          }
      },
      vAxis: {
          title: 'Total Savings ($)',
          viewWindowMode: 'explicit',
          viewWindow: {
              min: 0
          }
      },
      legend: 'none',
      lineWidth: 5.5,
      colors: ['#4262f4', '#f46b42'],
      animation: {
          duration: 1,
          easing: 'inAndOut',
      },
  };

  public chart1PrintOptions = {
    'height' : 220,
    'width' : 650,
    title: 'Average energy generation and usage',
    hAxis: {
        title: '',
        titleTextStyle: {
            color: '#333'
        },
        slantedText:true,
        slantedTextAngle: 45
    },
    vAxis: {
        title: 'kWh',
        viewWindowMode: 'explicit',
        viewWindow: {
            min: 0
        }
    },
    curveType: 'function',
    legend: 'none',
    lineWidth: 3.5,
    colors: ['#f46b42', '#4262f4'],
  };

  public chart2PrintOptions = {
    'height' : 220,
    'width': 650,
    isStacked: true,

    hAxis: {
        title: '',
        titleTextStyle: {
            color: '#333'
        }
    },
    vAxis: {
        title: 'Total Savings ($)',
        viewWindowMode: 'explicit',
        viewWindow: {
            min: 0
        }
    },
    legend: 'none',
    lineWidth: 5.5,
    colors: ['#4262f4', '#f46b42'],
    animation: {
        duration: 1,
        easing: 'inAndOut',
    },
  };

  public getLabelledConsumptionGeneration(solarEstimatesData) {
    let labelled = [];

    var data = solarEstimatesData.consumptionGeneration;
    labelled.push(this.chart1Headings);
    let timeArray = ['0:00','2:00','4:00','6:00','8:00','10:00','12:00','14:00','16:00','18:00','20:00','22:00','0:00'];
    let keys = [0,3,7,11,15,19,23,27,31,35,39,43,47];
    let ctr = 0;
    for (let key of keys) {
      labelled.push([timeArray[ctr], data[key]['averageGeneration'], data[key]['averageConsumption']]);
      ctr++;
    }
    return labelled;
  }

  public getLabelledMonthlyTotals(solarEstimatesData) {
    let labelled = [];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var monthlyTotals = solarEstimatesData.monthlyTotals;

    labelled.push(this.chart2Headings);

    for (let i = 0; i < 12; i++) {
      labelled.push([months[i], monthlyTotals[i]['selfConsumed'], monthlyTotals[i]['selfConsumed'] + monthlyTotals[i]['fedIn']])
    }

    return labelled;
  }

  // Return the monthly bill amount in dollars after considering consumption, self-consumption and feed-in
  public getMonthlyBill(annualBillTotal) {
    return annualBillTotal / 12;
  }

  // Return the quarterly bill amount in dollars after considering consumption, self-consumption and feed-in
  public getQuarterlyBill(annualBillTotal) {
    return annualBillTotal / 4;
  }

  // Returns number of years to pay back system
  public getPayback(systemCost, annualSavedTotal) {
    return systemCost / annualSavedTotal;
  }

  // Returns the percentage of import kWh that has been reduced by self-consumption of solar
  public getEnergySavingsPercentage(annualImportNoSolar, annualImportWithSolar) {
    return ((annualImportNoSolar - annualImportWithSolar) / annualImportNoSolar) * 100.0;
  }

  public getEnergySavingsSolarPercentage(annualImportNoSolar, annualImportWithSolar) {
    var withoutSolar = ((annualImportNoSolar - annualImportWithSolar) / annualImportNoSolar) * 100.0;
    var withSolar = 100 - withoutSolar;
    return withSolar;
  }

  public getAnnualImportAfterSolar(annualImportNoSolar, annualImportWithSolar) {
    return annualImportNoSolar - annualImportWithSolar;
  }

  public getFitRates(peak, offPeak, shoulder) {
    peak = (peak == null) ? '' : peak;
    offPeak = (offPeak == null) ? '' : offPeak;
    shoulder = (shoulder == null) ? '' : shoulder;
    if ((offPeak === undefined || offPeak == '') && (shoulder === undefined || shoulder == '') ) {
      offPeak = peak;
      shoulder = peak;
    } else if ((offPeak !== undefined || offPeak !== '') && (shoulder === undefined || shoulder == '')) {
      shoulder = offPeak;
    }
    var fitRates = {'p': peak, 'o': offPeak, 's': shoulder};
    return fitRates;
  }

  public getTariffs(peak, offPeak, shoulder) {
    peak = (peak == null) ? '' : peak;
    offPeak = (offPeak == null) ? '' : offPeak;
    shoulder = (shoulder == null) ? '' : shoulder;
    if ((offPeak === undefined || offPeak == '') && (shoulder === undefined  || shoulder == '')) {
      offPeak = peak;
      shoulder = peak;
    } else if ((offPeak !== false || offPeak !== '') && (shoulder === undefined || shoulder == '')) {
      shoulder = offPeak;
    }
    var tariffs = {'p': peak, 'o': offPeak, 's': shoulder};
    return tariffs;
  }
}
