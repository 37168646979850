import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';
import { Router } from "@angular/router";
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Utility } from '../utility';

@Component({
  selector: 'app-psbresults',
  templateUrl: './psbresults.component.html',
  styleUrls: ['./psbresults.component.css']
})
export class PSBResultsComponent implements OnInit {
  public bannerTitle = "Welcome to Victorian Energy Compare";
  public bannerSubTitle = "An independent Victorian Government energy price comparison site";
  public bannerDetails = "To get the most out of this tool, you need a recent energy bill or smart meter data file.";

  public PSBForm : FormGroup;
  public PSBData = {
    usage : '',
    energyType : "",
    userType : "",
    bill : "",
    twelveMonths : "",
    retailer : "",
    retailerName : "",
    postcode : "",
    usageProfile : 0,
    averageDailyConsumption : 0,
    nmi : "",
    skipNMI : false,
    smartMeter : "1",
    fileProvider : "",
    file : "",
    agree : "",
    disclaimer : "",
    hasSolar : "",
    solarCapacity : "",
    hasConcession : "",
    feedInTariff : "",
    serverCacheId : "",
    distributor : { id : "", name : "", distribution_zone_name : "" },
    distributorDerived : 0,
    recaptchaResponse : "",
    fileName : "",
    distributorSubmit : false,
    pageDataType : "energyConfigData",
    loopBack : false
  }

  constructor(public formBuilder : FormBuilder,
    private tooltipConfig : NgbTooltipConfig,
    public utility : Utility,
    public spinner : NgxSpinnerService,
    public dataService : DataService,
    public router : Router) {
    this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
    this.PSBForm = this.formBuilder.group({
      usage : new FormControl(''),
    });

    this.PSBForm.controls['usage'].valueChanges.subscribe(value => {
      if (this.PSBData.usage != '') {
        this.selectProfile();
      }
    });
  }

  private selectProfile() {
    this.spinner.show();
    this.dataService.selectProfile(this.PSBData.usage).subscribe(
      data => {
        this.router.navigate(['/offers']);
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public selectButton(event, name) {
    if (event.keyCode == 32 || event.keyCode == 13) { //spacebar & enter
      this.PSBData.usage = name;
      this.selectProfile();
    }
  }

  ngOnInit() {
    var serverCacheId = localStorage.getItem("serverCacheId");
    if (serverCacheId != null) {
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          this.spinner.hide();
          if (results.energyConfigData.hasOwnProperty('psbResults') == false && results.energyConfigData.hasOwnProperty('eapResults') == false ) {
            this.utility.redirectToHome();
          }
        },
        error => {
          this.spinner.hide();
          this.utility.redirectToHome();
        }
      );
    } else {
      this.utility.redirectToHome();
    }
  }

}
