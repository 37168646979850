import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { DataService } from '../data.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { SortingModalComponent } from '../sorting-modal/sorting-modal.component';
import { OfferInfoModalComponent } from '../offer-info-modal/offer-info-modal.component';
import { SurveyModalComponent } from '../survey-modal/survey-modal.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Utility } from '../utility';
import * as _ from 'lodash';

@Component({
  selector: 'app-psb-offers',
  templateUrl: './psb-offers.component.html',
  styleUrls: ['./psb-offers.component.css'],
  providers : [NgbModalConfig, NgbModal]
})

export class PsbOffersComponent implements OnInit {
  public topOfferRate = 0;
  public offersHasSolar = false;
  public mostExpensiveOfferRate = 0;

  public surveyEnabled = environment.surveyEnabled;
  private pageLimit = environment.pageLimit;
  public pageLoad = false;
  public offersCount = 0;
  public offersShown = 0;
  public compareIsDisabled = false;
  public originalOffers = { "offersList": [] };
  public reportingSent = false;
  private surveyIsShown = false;
  private yPosition = 0;
  private offersLoaded = false;
  public fileUpload = false;
  public getMyData = false;

  public dualOffers = { "offersList": [] };
  public offers = { "offersList": [] };
  public reportingOffers = { "offersList": [] };
  public electricityOffers = { "offersList": [] };
  public gasOffers = { "offersList": [] };

  public selectedOffers = [];
  public userData;
  public hasConcession;
  public userType;
  public bill;
  public averageDailyConsumption;
  public loopBackAverageDailyConsumption;
  public loopBackIsEmpty = false;
  public loopBackHasOffers = false;
  public electricityGasConnection = 1;
  public loopBackEnergyType = '';
  //filters
  public showRetailersFilter = false;
  public showOfferTypesFilter = false;
  public showResultsFilter = false;
  public showFeaturesFilter = false;
  public showContractsFilter = false;
  public showResourcesFilter = false;
  public selectAllRetailers = true;
  public selectAllContracts = true;
  public sortByConditionalPrice = true;
  public showTopOffer = true;
  public showTouOffer = false;
  public showSingleRate = true;
  public showFlexiblePricing = false;
  public showPayment = true;
  public showIncentive = true;
  public showVDO = false;
  public showCustomEligibility = false;
  public showDemand = false;
  public showInnovative = false;
  public showGreenPower = false;
  public showGoGreenFilter = false;
  public selectedRetailers = [];
  public selectedContracts = [];

  public retailers = [];
  public gasRetailers = [];
  public electricityRetailers = [];

  public features = [];
  public contractLength = [];
  public offerTypes = {};
  public showFilters = true;
  public energyType;
  public energyTypeCount = 1;
  public outOfWindow = false;

  //dual offers variables
  public gasOfferIndex = 0;
  public electricityOffersCount = 0;
  public dualOffersLimit = 0;
  public loopBackDistributors = true;
  public offersSelection = {
    'energyType' : '',
    'showRetailersFilter' : false,
    'showOfferTypesFilter' : false,
    'showResultsFilter' : false,
    'showFeaturesFilter' : false,
    'showContractsFilter' : false,
    'showResourcesFilter' : false,
    'selectAllRetailers' : false,
    'selectAllContracts' : false,
    'showTouOffer' : false,
    'showSingleRate' : false,
    'showFlexiblePricing' : false,
    'showPayment' : false,
    'showIncentive' : false,
    'showVDO' : false,
    'showCustomEligibility' : false,
    'showDemand' : false,
    'showInnovative' : false,
    'showGreenPower' : false,
    'showGoGreenFilter' : false,
    'selectedRetailers' : [],
    'selectedOffers' : [],
    'offersShown' : 0
  };

  constructor(
    public router : Router,
    public config : NgbModalConfig,
    public modalService : NgbModal,
    public utility : Utility,
    public dataService : DataService,
    public spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
 }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.modalService.dismissAll(); //close modal dialog if there are any open
    this.surveyPage();
    this.dataService.changeBillPage('false');
    this.dataService.changeEnergyProfilePage('false');
    this.dataService.changeLoopBack('false');
    document.title = 'Results - Victorian Energy Compare';
    this.initCheck();
  }

  private initCheck() {
    var serverCacheId = 'test';
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.spinner.show();
      this.dataService.getPSBSavedData(serverCacheId).subscribe(
        data => {
          if (data.existing == false) {
            this.utility.sessionRestart();
            this.utility.redirectToHome();
          }
          //get form data from dataService
          this.userData = data.energyConfigData;
          this.loopBackHasOffers = data.loopBackHasOffers;
          this.hasConcession = this.userData.hasConcession;
          this.userType = this.userData.userType;
          this.bill = this.userData.bill;
          this.dataService.setEnergyConfigData(data.energyConfigData);
          this.dataService.setEnergyProfileData(data.energyProfileData);
          this.dataService.setBillDetailsData(data.billDetailsData);
          this.dataService.setDistributor(this.userData.distributor);
          this.averageDailyConsumption = this.userData.averageDailyConsumption * 1;
          this.averageDailyConsumption = (Number.isInteger(this.averageDailyConsumption) == true) ? this.averageDailyConsumption : parseFloat(this.averageDailyConsumption).toFixed(1);
          let measurement = (this.userData.energyType == 'Gas') ? 'MJ' : 'kWh';
          this.averageDailyConsumption += measurement;
          //check if the user has uploaded a consumption file
          if (this.userData.energyType == 'Electricity') {
            if (this.userData.usageProfile == 1) {
              this.getMyData = true;
            }
            if (this.userData.usageProfile == 2) {
              this.fileUpload = true;
            }
          }
          if (data.loopBackData.energyConfigData.hasOwnProperty('energyType') == true && data.loopBackData.energyConfigData.energyType == 'Electricity') {
            if (data.loopBackData.energyConfigData.usageProfile == 1) {
              this.getMyData = true;
            }
            if (data.loopBackData.energyConfigData.usageProfile == 2) {
              this.fileUpload = true;
            }
          }

          let offersURL = this.router.url.split("/");
          if (this.router.url == '/offers/list/dual') {
            this.dataService.changeLoopBack('true');
          }
          if (this.router.url == '/offers' || (offersURL.length == 4 && offersURL[3] == data.energyConfigData.energyType.toLowerCase())) {
            this.energyType = data.energyConfigData.energyType;
            if (data.billDetailsData.electricityBillStartDate != undefined || data.billDetailsData.gasBillStartDate != undefined) {
              this.dataService.changeBillPage('true');
            }
            if (data.energyProfileData.energyType != undefined) {
              this.dataService.changeEnergyProfilePage('true');
            }
          } else {
            if (this.router.url != '/offers/list/dual') {
              this.energyType = data.loopBackData.energyConfigData.energyType;
            }
            this.dataService.changeLoopBack('true');
            if (data.loopBackData.billDetailsData.electricityBillStartDate != undefined || data.loopBackData.billDetailsData.gasBillStartDate != undefined) {
              this.dataService.changeBillPage('true');
            }
            if (data.loopBackData.energyProfileData.energyType != undefined) {
              this.dataService.changeEnergyProfilePage('true');
            }
          }

          if (data.loopBackData.energyConfigData.hasOwnProperty('energyType') == false) {
            this.loopBackIsEmpty = true;
            let loopbackEnergy = (this.userData.energyType == 'Gas') ? 'Electricity' : 'Gas';
            //check if there are distributors for the loopback energy type
            this.dataService.getDistributors(data.energyConfigData.postcode, loopbackEnergy).subscribe(
              results => {
                //if there is some issue in the api
                if (results['data'].length < 1) {
                  this.loopBackDistributors = false;
                }
              }
            );

            if (this.router.url == '/offers/list/dual') {
              this.router.navigate(['/offers']).then(() => {
                //window.location.reload();
              });
            }
          } else { //there is loopback data update the average consumption
            this.loopBackEnergyType = data.loopBackData.energyConfigData.energyType;
            this.loopBackAverageDailyConsumption = data.loopBackData.energyConfigData.averageDailyConsumption * 1;
            this.loopBackAverageDailyConsumption = (Number.isInteger(this.loopBackAverageDailyConsumption) == true) ? this.loopBackAverageDailyConsumption : parseFloat(this.loopBackAverageDailyConsumption).toFixed(1);
            measurement = (this.loopBackEnergyType == 'Gas') ? 'MJ' : 'kWh';
            this.loopBackAverageDailyConsumption += measurement;
          }
          this.applyFiltersSelection();
        },
        error => {
          this.spinner.hide();
        }
      );
      this.setSelectedContracts();
      this.originalOffers = this.dataService.getSavedOffers();
      let loopBack = (this.router.url != '/offers') ? true : false;
      this.energyTypeCount = (this.router.url == '/offers/list/dual') ? 2 : 1;
      if (this.originalOffers.hasOwnProperty('originalOffers') == true) {
        let keys = Object.keys(this.originalOffers);
        this.energyType = keys[0];
        this.dataService.changeEnergyType(this.energyType);
        //process offers, group and sort offers based on energyTypes
        this.processOffers();
      } else {
        this.dataService.getPSBOffers(loopBack, this.router.url).subscribe(
          data => {
            this.offersLoaded = true;
            this.spinner.hide();
            if (data.status != undefined && data.status == 'error') {
              this.pageLoad = true;
            } else if (data.status == 'redirect') {
              this.utility.sessionRestart();
              localStorage.removeItem("serverCacheId");
              location.reload();
              return false;
            } else {
              //log journey progress
              this.utility.logJourneyProgress('results_page');

              this.electricityGasConnection = data.electricityGasConnection;
              this.energyType = data.selectedEnergyType;
              this.dataService.changeEnergyType(this.energyType);
              //keep a copy of the original offers from server
              this.originalOffers = data.offers;
              //process offers, group and sort offers based on energyTypes
              this.processOffers();
              this.sortingModal();
            }
          },
          error => {
            this.offers = { "offersList": [] };
            this.pageLoad = true;
            this.spinner.hide();
          }
        );
      }
    } else {
      this.utility.redirectToHome();
    }
  }

  private surveyPage() {
    var root = this;
    document.onmouseout = function(e) {
      root.yPosition = e.clientY;
      root.outOfWindow = true;
      root.openSurvey();
    }
  }

  public processOffers() {
    if (this.energyType == "dual") {
      this.processDualEnergyOffers();
    } else {
      this.processEnergyOffers(this.energyType);
    }
  }

  public processDualEnergyOffers() {
    //process gas offers
    this.processEnergyOffers('Gas');

    //process electricty offer
    this.processEnergyOffers('Electricity');

  }

  public processEnergyOffers(selectedEnergyType) {
    this.retailers = [];
    for (var offer in this.originalOffers[selectedEnergyType].offersList) {
      var offerDetails = this.originalOffers[selectedEnergyType].offersList[offer].offerDetails[0];
      let retailer = {
          "Id": "",
          "name" : "",
          "selected" : false,
          "retailerImageUrl" : "",
          "retailerUrl" : "",
          "retailerPhone" : "" ,
          "retailerDescription" : "",
          "retailerLicenseName" : "",
          "retailerFueltype" : ""
        };
      retailer.Id = offerDetails.retailerId;
      retailer.name = offerDetails.retailerName;
      retailer.retailerImageUrl = offerDetails.retailerImageUrl;
      retailer.retailerUrl = offerDetails.retailerUrl;
      retailer.retailerPhone = offerDetails.retailerPhone;
      retailer.retailerDescription = offerDetails.retailerDescription;
      retailer.retailerLicenseName = offerDetails.retailerLicenseName;
      retailer.retailerFueltype = offerDetails.retailerFueltype;
      if (retailer.name == 'Sumo') {
        retailer.name = (this.energyType == 'dual') ? 'Sumo ' + selectedEnergyType : retailer.name;
      }
      this.retailers.push(retailer);
    }
    //remove retailers duplicate
    this.retailers = this.retailers.filter((v,i) => this.retailers.findIndex(item => item.Id == v.Id) === i);

    //sort by name
    this.retailers = _.orderBy(this.retailers, [retailer => retailer.name.toLowerCase()], ['asc']);
    this.dataService.setRetailers(this.retailers);
    this.setSelectedRetailers();
    //apply sorting and filters
    if (this.energyType == selectedEnergyType || (this.energyType == 'dual' && selectedEnergyType != 'Gas')) {
      this.applyRetailersSelection(); //load saved selected retailers
    }
    this.applyFilters(selectedEnergyType);
    setTimeout(() => {
      this.applyOffersSelection();
      this.spinner.hide();
    }, 1000);
  }

  public sortingModal() {
    if (localStorage.getItem("sorting-showTopOffer") == null && localStorage.getItem("sorting-sortByConditionalPrice") == null) {
      if (this.dataService.getSortingSaved() == false) {
        let modalRef = this.modalService.open(SortingModalComponent, { size : 'xl', backdrop : 'static', keyboard : false });
        modalRef.result.then((options) => {
          this.sortByConditionalPrice = options.sortByConditionalPrice;
          this.showTopOffer = options.showTopOffer;
          this.dataService.setSortingSaved(true);
          this.dataService.setSorting(this.sortByConditionalPrice);
          this.dataService.setShowTopOffer(this.showTopOffer);
          this.setFilterEnergyType();
        });
      } else {
        this.sortByConditionalPrice = this.dataService.getSorting();
        this.showTopOffer = this.dataService.getShowTopOffer();
        this.setFilterEnergyType();
      }
    } else {
      let showTopOffer = localStorage.getItem("sorting-showTopOffer");
      let sortByConditionalPrice = localStorage.getItem("sorting-sortByConditionalPrice");
      this.sortByConditionalPrice = (sortByConditionalPrice == 'true');
      this.showTopOffer = (showTopOffer == 'true');
      this.dataService.setSortingSaved(true);
      this.dataService.setSorting(this.sortByConditionalPrice);
      this.dataService.setShowTopOffer(this.showTopOffer);
      this.setFilterEnergyType();
    }
  }

  public updateSelectedOffers(selectedOffers) {
    this.selectedOffers = selectedOffers;
    if (selectedOffers.length > 0) {
      this.compareIsDisabled = false;
    } else {
      this.compareIsDisabled = true;
    }
  }

  public setSelectedContracts() {
    this.selectedContracts = [];
    this.selectedContracts["None"] = { "selected" : true };
    this.selectedContracts["1 Year"] = { "selected" : true };
    this.selectedContracts["2 Year"] = { "selected" : true };
    this.selectedContracts["3 Year"] = { "selected" : true };
    this.selectedContracts["4 Year"] = { "selected" : true };
    this.selectedContracts["5 Year"] = { "selected" : true };
    this.selectedContracts["Other"] = { "selected" : true };
  }

  public setSelectedRetailers() {
    for (var retailer in this.retailers) {
      let selectedRetailer = { "selected" : true };
      this.selectedRetailers[this.retailers[retailer].Id] = selectedRetailer;
    }
  }

  public applyFilters(selectedEnergyType = '') {
    selectedEnergyType = (selectedEnergyType == '') ? this.energyType : selectedEnergyType;
    this.selectedOffers = [];
    this.offers = { "offersList": [] };
    for (var offer in this.originalOffers[selectedEnergyType].offersList) {
      let offerDetails = this.originalOffers[selectedEnergyType].offersList[offer].offerDetails[0];
      let retailerId = offerDetails.retailerId;
      let addRecord = true;
      let offerKey = offerDetails.offerKey;
      //retailers filter
      if (this.selectedRetailers[retailerId].selected == false) {
        addRecord = false;
      }

      //remove selected offer if the retailer filter is unchecked
      if (this.selectedOffers.length > 0) {
        for (var selectedOfferIndex in this.selectedOffers) {
          let selectedOffer = this.selectedOffers[selectedOfferIndex];
          if (this.selectedRetailers[retailerId].selected == false && selectedOffer == offerKey) {
            this.selectedOffers.splice(parseInt(selectedOfferIndex), 1);
          }
        }
      }

      //contracts filter
      for (var contractLength in offerDetails.contractLength) {
        if (this.selectedContracts[offerDetails.contractLength].selected == false) {
          addRecord = false;
          break;
        }
      }

      let tariffType = offerDetails.tariffType.toLowerCase();
      //time of use filter
      if (this.showTouOffer == false && this.energyType != 'Gas' &&
        (tariffType == "time of use" || tariffType == "time of use with controlled load")) {
        addRecord = false;
      }

      //single rate filter
      if (this.showSingleRate == false && this.energyType != 'Gas' &&
        (tariffType == "single rate" || tariffType == "two rate: single rate with controlled load")) {
        addRecord = false;
      }

      //flexible rate filter
      if (this.showFlexiblePricing == false && this.energyType != 'Gas' &&
        (tariffType == "flexible pricing" || tariffType == "flexible pricing with controlled load")) {
        addRecord = false;
      }

      //showIncentive filter
      if (this.showIncentive == false && offerDetails.hasIncentive == true) {
        addRecord = false;
      }

      //showPayment filter
      if (this.showPayment == false && offerDetails.isDirectDebitOnly == true) {
        addRecord = false;
      }

      //showGreenPower filter
      if (this.showGreenPower == true) {
        let greenPower = (offerDetails.intrinsicGreenpowerPercentage != 0);
        //greenPower = (offerDetails.intrinsicGreenpowerPercentage != '0.0000');
        greenPower = greenPower || (offerDetails.greenPower != 0);
        if (greenPower != true && selectedEnergyType != 'Gas') {
          addRecord = false;
        }
      }

      //Only show Victorian Default Offers filter
      if (this.showVDO == true && offerDetails.isVictorianDefaultOffer != true && selectedEnergyType != 'Gas') {
        addRecord = false;
      }

      //show offers with demand
      if (this.showDemand == true) {
        if (offerDetails.includesDemandTariff == false && selectedEnergyType != 'Gas') {
          addRecord = false;
        }
      } else {
        if (offerDetails.includesDemandTariff == true && selectedEnergyType != 'Gas') {
          addRecord = false;
        }
      }

      //show offers with custom eligibility
      if (this.showCustomEligibility == false && offerDetails.isCustomEligibilityOffer == true) {
        addRecord = false;
      }

      //Show innovative offers filter
      if (this.showInnovative == true && offerDetails.isInnovativeOffer != true && selectedEnergyType != 'Gas') {
        addRecord = false;
      }

      let greenPowerDisplay = parseInt(offerDetails.greenPower) + parseInt(offerDetails.intrinsicGreenpowerPercentage);
      let intrinsicGreenPowerDisplay = parseInt(offerDetails.intrinsicGreenpowerPercentage);
      offerDetails.greenPowerDisplay = greenPowerDisplay;
      offerDetails.intrinsicGreenPowerDisplay = intrinsicGreenPowerDisplay;

      // Get the price of an offer, either fullDiscountedPrice or conditionalPrice
      //var totalBasePrice = offerDetails.basePrice;
      var totalBasePrice = offerDetails.totalUnconditional;
      offerDetails.totalBasePrice = totalBasePrice;

      // Get the price of an offer, either fullDiscountedPrice or conditionalPrice
      var totalConditionalPrice = offerDetails.totalConditional;
      offerDetails.totalConditionalPrice = totalConditionalPrice;
      offerDetails.gasOfferIndex = 0;

      if (addRecord == true) {
        this.offers.offersList.push(offerDetails);
      }
      this.pageLoad = true;
    }
    //group top offers
    this.groupOffers(selectedEnergyType);

    //apply sort
    let tmpSelectedOffers = this.sortOffers();

    //apply pagination
    if (this.energyType != 'dual') {
      this.offersPagination();
    } else {
      if (selectedEnergyType == 'Electricity') {
        this.electricityOffers = JSON.parse(JSON.stringify(this.offers));
        this.dualOffers = JSON.parse(JSON.stringify(this.offers));
        this.dualOffersPagination();

        //this.offersCount = this.offers.offersList.length * this.gasOffers.offersList.length;
      } else {
        this.gasOffers = this.offers;
      }
    }

    this.setOfferSettings(tmpSelectedOffers);
    this.processReporting();
  }

  private setOfferSettings(tmpSelectedOffers) {
    if (this.selectedOffers.length == 0) {
      this.selectedOffers = tmpSelectedOffers;
      this.dataService.setSelectedOffers(this.selectedOffers);
    }
    this.dataService.setOffers(this.originalOffers);
    this.dataService.setSortedOffers(this.offers);

    if (this.energyType == 'dual') {
      this.dataService.setDualOffers(this.dualOffers);
      this.dataService.setGasOffers(this.gasOffers);
    }
  }

  private processReporting() {
    if (this.energyType != 'dual' && this.reportingSent == false && localStorage.getItem("sorting-sortByConditionalPrice") != null) {
      this.reportingSent = true;
      let reportingTopOffers = { "offersList": [] };
      let reportingExpensiveOffers = { "offersList": [] };
      let sortByConditionalPrice = localStorage.getItem("sorting-sortByConditionalPrice");
      reportingTopOffers.offersList = this.reportingOffers.offersList.slice(0, 10);
      reportingExpensiveOffers.offersList = this.reportingOffers.offersList.slice(Math.max(this.reportingOffers.offersList.length - 10, 0));
      this.reportingOffers = { "offersList" : [] };

      //get reporting offers
      let topOffers = [];
      let ranking = 1;
      //top offers
      for (let offerIndex in reportingTopOffers.offersList) {
        let currentOffer = reportingTopOffers.offersList[offerIndex];
        this.offersHasSolar = (currentOffer.solarCredit > 0) ? true : false;
        let newOffer = {
          "ranking" : ranking,
          "offerRefCode" : currentOffer.offerId,
          "undiscountedAmount" : currentOffer.totalBasePrice,
          "discountedAmount" : currentOffer.totalConditional,
          "sortByConditionalPrice" : sortByConditionalPrice
        };
        topOffers.push(newOffer);
        ranking++;
      }
      //most expensive offers
      let mostExpensiveOffers = [];
      ranking = 10;
      for (let offerIndex in reportingExpensiveOffers.offersList) {
        let currentOffer = reportingExpensiveOffers.offersList[offerIndex];
        let newOffer = {
          "ranking" : ranking,
          "offerRefCode" : currentOffer.offerId,
          "undiscountedAmount" : currentOffer.totalBasePrice,
          "discountedAmount" : currentOffer.totalConditional,
          "sortByConditionalPrice" : sortByConditionalPrice
        };
        mostExpensiveOffers.push(newOffer);
        ranking--;
      }
      this.topOfferRate = topOffers[0].undiscountedAmount;
      this.mostExpensiveOfferRate = mostExpensiveOffers[mostExpensiveOffers.length - 1].undiscountedAmount;

      var serverCacheId = localStorage.getItem("serverCacheId");
      this.spinner.show();
      this.dataService.reporting(this.energyType, this.showTopOffer, serverCacheId, topOffers, mostExpensiveOffers).subscribe(
        data => {
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      );
    }

    if (this.energyType == 'dual') {
      if (this.dualOffers.offersList.length > 0) {
        this.topOfferRate = this.dualOffers.offersList[0].bundleTotalBasePrice;
      }
    }
  }

  private removeOfferSelection(offer) {
    offer = parseInt(offer);
    let index = this.selectedOffers.indexOf(offer);
    if (index != -1) {
      this.selectedOffers.splice(index, 1);
    }
  }

  public showMoreOffers() {
    if (this.pageLimit < this.offersCount) {
      this.pageLimit += environment.pageLimit;
      this.setFilterEnergyType();
    }
  }

  public updateFilters(filter) {
    if (filter.name == 'selectedRetailers') {
      this.selectedRetailers = filter.value;
    } else if (filter.name == 'selectedContracts') {
      this.selectedContracts = filter.value;
    } else if (filter.name == 'showTouOffer') {
      this.showTouOffer = filter.value;
    } else if (filter.name == 'showSingleRate') {
      this.showSingleRate = filter.value;
    } else if (filter.name == 'showFlexiblePricing') {
      this.showFlexiblePricing = filter.value;
    } else if (filter.name == 'showPayment') {
      this.showPayment = filter.value;
    } else if (filter.name == 'showIncentive') {
      this.showIncentive = filter.value;
    } else if (filter.name == 'showGreenPower') {
      this.showGreenPower = filter.value;
    } else if (filter.name == 'showGoGreenFilter') {
      this.showGoGreenFilter = filter.value;
    } else if (filter.name == 'showVDO') {
      this.showVDO = filter.value;
    } else if (filter.name == 'showCustomEligibility') {
      this.showCustomEligibility = filter.value;
    } else if (filter.name == 'showDemand') {
      this.showDemand = filter.value;
    } else if (filter.name == 'showInnovative') {
      this.showInnovative = filter.value;
    } else if (filter.name == 'sortByConditionalPrice') {
      this.sortByConditionalPrice = filter.value;
      this.dataService.setSorting(this.sortByConditionalPrice);
      localStorage.setItem("sorting-sortByConditionalPrice", this.sortByConditionalPrice.toString());
      this.reportingSent = false;
    } else if (filter.name == 'showTopOffer') {
      this.showTopOffer = filter.value;
      this.dataService.setShowTopOffer(this.showTopOffer);
      localStorage.setItem("sorting-showTopOffer", this.showTopOffer.toString());
    } else if (filter.name == 'showRetailersFilter') {
      this.showRetailersFilter = filter.value;
      return false;
    } else if (filter.name == 'showOfferTypesFilter') {
      this.showOfferTypesFilter = filter.value;
      return false;
    } else if (filter.name == 'showResultsFilter') {
      this.showResultsFilter = filter.value;
      return false;
    } else if (filter.name == 'showContractsFilter') {
      this.showContractsFilter = filter.value;
      return false;
    } else if (filter.name == 'showFeaturesFilter') {
      this.showFeaturesFilter = filter.value;
      return false;
    } else if (filter.name == 'showResourcesFilter') {
      this.showResourcesFilter = filter.value;
      return false;
    } else if (filter.name == 'selectAllRetailers') {
      this.selectAllRetailers = filter.value;
    } else if (filter.name == 'selectAllContracts') {
      this.selectAllContracts = filter.value;
    }
    this.setFilterEnergyType();
  }

  public compareSelected() {
    this.saveOffersSelection();
    this.utility.scrollToTop('none');
    this.dataService.setSelectedOffers(this.selectedOffers);
    if (this.selectedOffers.length == 1) {
      let offerKey = this.selectedOffers[0];
      let selectedOffer;
      let modalRef = this.modalService.open(OfferInfoModalComponent, {size : 'xl'});
      for (let offerIndex in this.offers.offersList) {
        if (this.offers.offersList[offerIndex].offerKey == offerKey) {
          selectedOffer = this.offers.offersList[offerIndex];
          break;
        }
      }
      modalRef.componentInstance.offer = selectedOffer;
      modalRef.componentInstance.energyType = this.energyType;
    } else {
      this.spinner.show();
      //remove all offers from the list and just add the selected offers
      let tmpOffersList = (this.energyType == 'dual') ? this.dualOffers.offersList : this.originalOffers.offersList;
      let newOffers = { "offersList" : [] };
      this.dataService.setReturnPage(this.router.url);
      if (this.energyType == 'dual') {
        for (let selectedOffer in this.selectedOffers) {
          let newOffer = tmpOffersList[this.selectedOffers[selectedOffer]];
          if (newOffer != null) {
            newOffers.offersList.push(newOffer);
          }
        }
        this.dataService.setDualOffers(newOffers);
        this.router.navigate(['/loopback/compare']);
      } else {
        for (let selectedOffer in this.selectedOffers) {
          let newOffer = _.find(tmpOffersList, { offerKey : this.selectedOffers[selectedOffer] });
          if (newOffer != null) {
            newOffers.offersList.push(newOffer);
          }
        }
        this.dataService.setOffers(newOffers);
        if (this.energyType == 'Gas') {
          this.router.navigate(['/compare/gas']);
        } else if (this.energyType == 'Electricity') {
          this.router.navigate(['/compare/electricity']);
        } else {
          this.router.navigate(['/compare']);
        }
      }
      setTimeout(()=> {
        this.spinner.hide();
      }, 1000);
    }
  }

  public groupReportingOffers(reportingOffers) {
    let groupedOffers = _.mapValues(_.groupBy(reportingOffers.offersList, 'retailerId'), clist => clist.map(offer => _.omit(offer, 'offer')));
    let newReportingOffers = { "offersList" : [] };
    let sortByConditionalPrice = localStorage.getItem("sorting-sortByConditionalPrice");
    for (let group in groupedOffers) {
      let offerDetails = groupedOffers[group];
      if (offerDetails.length > 1) {
        let retailerOffers;
        if (sortByConditionalPrice == "true") {
          retailerOffers = _.orderBy(offerDetails, [function(o) { return parseFloat(o['totalConditionalPrice']); }], 'asc');
        } else {
          retailerOffers = _.orderBy(offerDetails, [function(o) { return parseFloat(o['totalBasePrice']); }], 'asc');
        }
        newReportingOffers.offersList.push(retailerOffers[0]);
      } else {
        newReportingOffers.offersList.push(offerDetails[0]);
      }
    }
    return newReportingOffers;
  }

  public groupOffers(selectedEnergyType = '') {
    if (this.showTopOffer == true || (this.energyType == 'dual' && selectedEnergyType == 'Gas')) {
      let groupedOffers = _.mapValues(_.groupBy(this.offers.offersList, 'retailerId'), clist => clist.map(offer => _.omit(offer, 'offer')));
      this.offers = {"offersList": []};
      this.reportingOffers = {"offersList": []};
      for (let group in groupedOffers) {
        let offerDetails = groupedOffers[group];
        if (offerDetails.length > 1) {
          let retailerOffers;
          if (this.sortByConditionalPrice == true) {
            retailerOffers = _.orderBy(offerDetails, [function(o) { return parseFloat(o['totalConditionalPrice']); }], 'asc');
          } else {
            retailerOffers = _.orderBy(offerDetails, [function(o) { return parseFloat(o['totalBasePrice']); }], 'asc');
          }
          this.offers.offersList.push(retailerOffers[0]);
          this.reportingOffers.offersList.push(retailerOffers[0]);
        } else {
          this.offers.offersList.push(offerDetails[0]);
          this.reportingOffers.offersList.push(offerDetails[0]);
        }
      }
    }
  }

  public offersPagination() {
    let ctr = 0;
    let tmpOffersList = this.offers.offersList;
    let sortedOffers = [];
    this.offersCount = this.offers.offersList.length;
    this.offers.offersList = [];
    tmpOffersList.length = (tmpOffersList.length < this.pageLimit) ? tmpOffersList.length : this.pageLimit;
    this.offersShown = tmpOffersList.length;
    this.offers.offersList = tmpOffersList;
  }

  public dualOffersPagination() {
    let gasCtr = 0;
    let tmpOffersList = this.dualOffers.offersList;
    let newDualOffers = [];
    while (gasCtr < this.gasOffers.offersList.length) {
      let tmp = 0;
      let gasOffer = this.gasOffers.offersList[gasCtr];
      let tmpOffer : any;
      while (tmp < tmpOffersList.length) {
        tmpOffer = null;
        tmpOffer = tmpOffersList[tmp];
        if ((tmpOffer.isContingentOffer != true && gasOffer.isContingentOffer != true) ||
          (tmpOffer.isContingentOffer == true || gasOffer.isContingentOffer == true) && tmpOffer.retailerId == gasOffer.retailerId) {
          tmpOffer.bundleTotalConditionalPrice = (tmpOffersList[tmp].totalConditionalPrice * 1) + (gasOffer.totalConditionalPrice * 1);
          tmpOffer.bundleTotalBasePrice = (tmpOffersList[tmp].totalBasePrice * 1) + (gasOffer.totalBasePrice * 1);
          let eletricityOffersString = JSON.stringify(tmpOffer);
          eletricityOffersString = eletricityOffersString.split('"gasOfferIndex":0').join('"gasOfferIndex":' + gasCtr);
          tmpOffer = JSON.parse(eletricityOffersString);
          newDualOffers.push(tmpOffer);
        }
        tmp++;
      }
      gasCtr++;
    }
    if (this.sortByConditionalPrice == true) {
      //Use Lodash to sort object by conditionalPrice
      newDualOffers = _.orderBy(newDualOffers, [function(o) { return parseFloat(o['bundleTotalConditionalPrice']); }], 'asc');
    } else {
      newDualOffers = _.orderBy(newDualOffers, [function(o) { return parseFloat(o['bundleTotalBasePrice']); }], 'asc');
    }
    this.offersCount = newDualOffers.length;
    let offersPageCount = Math.floor(this.pageLimit / this.electricityOffers.offersList.length);
    this.dualOffers.offersList = [];
    newDualOffers.length = (newDualOffers.length < this.pageLimit) ? newDualOffers.length : this.pageLimit;
    this.offersShown = newDualOffers.length;
    this.dualOffers.offersList = newDualOffers;
    this.dataService.setDualOffers(this.dualOffers);
  }

  public sortOffers() {
    if (this.sortByConditionalPrice == true) {
      //Use Lodash to sort object by conditionalPrice
      this.offers.offersList = _.orderBy(this.offers.offersList, [function(o) { return parseFloat(o['totalConditionalPrice']); }], 'asc');
      this.reportingOffers.offersList = _.orderBy(this.offers.offersList, [function(o) { return parseFloat(o['totalConditionalPrice']); }], 'asc');
    } else {
      this.offers.offersList = _.orderBy(this.offers.offersList, [function(o) { return parseFloat(o['totalBasePrice']); }], 'asc');
      this.reportingOffers.offersList = _.orderBy(this.offers.offersList, [function(o) { return parseFloat(o['totalBasePrice']); }], 'asc');
    }
    let tmpSelectedOffers = [];
    if (this.offers.offersList.length > 0) {
      if (this.offers.offersList[0] != undefined) {
        if (this.energyType == 'dual') {
          tmpSelectedOffers.push(0);
        } else {
          tmpSelectedOffers.push(this.offers.offersList[0].offerKey);
        }
      }

      if (this.offers.offersList[1] != undefined) {
        if (this.energyType == 'dual') {
          tmpSelectedOffers.push(1);
        } else {
          tmpSelectedOffers.push(this.offers.offersList[1].offerKey);
        }
      }
    }
    return tmpSelectedOffers;
  }

  public toggleFilters(value) {
    this.showFilters = value;
  }

  public resetResult() {
    this.selectedOffers = [];
    this.showFilters = true;
    this.pageLimit = environment.pageLimit;
    this.sortByConditionalPrice = true;
    this.showTopOffer = true;
    this.showTouOffer = false;
    this.showSingleRate = true;
    this.showFlexiblePricing = false;
    this.showPayment = true;
    this.showIncentive = true;
    this.showVDO = false;
    this.showCustomEligibility = false;
    this.showDemand = false;
    this.showInnovative = false;
    this.showGreenPower = false;
    this.showGoGreenFilter = false;

    this.setSelectedRetailers();
    this.setSelectedContracts();
    this.showRetailersFilter = false;
    this.showOfferTypesFilter = false;
    this.showResultsFilter = false;
    this.showFeaturesFilter = false;
    this.showContractsFilter = false;
    this.showResourcesFilter = false;
    this.selectAllRetailers = true;
    this.selectAllContracts = true;
    this.setFilterEnergyType();
  }

  public openSurvey() {
    setTimeout(() => {
      if (this.surveyEnabled == true && this.router.url.indexOf("offers") != -1) {
        let survey = localStorage.getItem("survey");
        if (survey == null && this.yPosition <= 0 && this.surveyIsShown == false && this.offersLoaded == true) {
          this.surveyIsShown = true;
          this.modalService.open(SurveyModalComponent, { size : 'lg' });
        }
      }
    }, 7000);
  }

  private setFilterEnergyType() {
    if (this.energyType == 'dual') {
      this.applyFilters('Electricity');
    } else {
      this.applyFilters();
    }
  }

  public saveOffersSelection() {
    this.offersSelection = {
      energyType : this.energyType,
      showRetailersFilter : this.showRetailersFilter,
      showOfferTypesFilter : this.showOfferTypesFilter,
      showResultsFilter : this.showResultsFilter,
      showFeaturesFilter : this.showFeaturesFilter,
      showContractsFilter : this.showContractsFilter,
      showResourcesFilter : this.showResourcesFilter,
      selectAllRetailers : this.selectAllRetailers,
      selectAllContracts : this.selectAllContracts,
      showTouOffer : this.showTouOffer,
      showSingleRate : this.showSingleRate,
      showFlexiblePricing : this.showFlexiblePricing,
      showPayment : this.showPayment,
      showIncentive : this.showIncentive,
      showVDO : this.showVDO,
      showCustomEligibility : this.showCustomEligibility,
      showDemand : this.showDemand,
      showInnovative : this.showInnovative,
      showGreenPower : this.showGreenPower,
      showGoGreenFilter : this.showGoGreenFilter,
      selectedRetailers : this.selectedRetailers,
      selectedOffers : this.selectedOffers,
      offersShown : this.offersShown
    };
    this.dataService.setOffersSelection(this.offersSelection);
  }

  public applyFiltersSelection() {
    this.offersSelection = this.dataService.getOffersSelection();
    this.energyType = (this.router.url == '/offers/list/dual') ? 'dual' : this.energyType;
    if (this.offersSelection.selectedOffers.length > 0 && this.energyType == this.offersSelection.energyType) {
      this.showRetailersFilter = this.offersSelection.showRetailersFilter;
      this.showOfferTypesFilter = this.offersSelection.showOfferTypesFilter;
      this.showResultsFilter = this.offersSelection.showResultsFilter;
      this.showFeaturesFilter = this.offersSelection.showFeaturesFilter;
      this.selectAllRetailers = this.offersSelection.selectAllRetailers;
      this.showTouOffer = this.offersSelection.showTouOffer;
      this.showSingleRate = this.offersSelection.showSingleRate;
      this.showFlexiblePricing = this.offersSelection.showFlexiblePricing;
      this.showPayment = this.offersSelection.showPayment;
      this.showIncentive = this.offersSelection.showIncentive;
      this.showVDO = this.offersSelection.showVDO;
      this.showCustomEligibility = this.offersSelection.showCustomEligibility;
      this.showDemand = this.offersSelection.showDemand;
      this.showInnovative = this.offersSelection.showInnovative;
      this.showGreenPower = this.offersSelection.showGreenPower;
      this.showGoGreenFilter = this.offersSelection.showGoGreenFilter;
    }
  }

  public applyRetailersSelection() {
    if (this.offersSelection.selectedRetailers.length > 0 && this.energyType == this.offersSelection.energyType) {
      this.selectedRetailers = this.offersSelection.selectedRetailers;
    }
  }

  public applyOffersSelection() {
    if (this.offersSelection.selectedOffers.length > 0 && this.energyType == this.offersSelection.energyType) {
      if (this.offersSelection.offersShown > this.offersShown) {
        let dataCount = Math.round(this.offersSelection.offersShown / environment.pageLimit);
        let remainder = this.offersSelection.offersShown % environment.pageLimit;
        if (remainder > 0 && remainder < 5) {
          dataCount += 1;
        }
        let ctr = Math.round(this.offersShown / environment.pageLimit);
        while (ctr < dataCount) {
          this.showMoreOffers();
          ctr++;
        }
      }
      this.updateSelectedOffers(this.offersSelection.selectedOffers);
    }
  }

}
