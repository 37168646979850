<div id="container">
    <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails">
    </app-banner>
    <br />

    <div>
        <div class="col-md-12 col-xs-12">
            <img class="outreach-image" src="assets/images/{{ secImageURL }}contentpages/outreach_header.jpeg">
            <h2 class="subTitle">Overview</h2>
            <p>
                The Power Saving Bonus was a Victorian Government program that offered eligible Victorian households a
                $250
                payment to help ease cost-of-living pressures and encourage them to compare energy offers and save
                money.
                The fourth round of the program closed for new applications on 31 August 2023.
            </p>
            <br />
            <p>
                To support Victorians to access the $250 Power Saving Bonus the Victorian Government partnered with a
                range of
                organisations who specialise in providing support to the Victorian community.
                The $250 Power Saving Bonus Community Outreach Program delivered state-wide community-based support to
                support vulnerable and hard-to-reach households to become aware of and access the $250 Bonus.
            </p>

            <br />
            <h2 class="subTitle">Participating community organisations</h2>
            <br />
            <p>
                Participating organisations who delivered support through the Community Outreach Program included:
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Anglicare Victoria:</h4>
            <p>
                At Anglicare Victoria, everything they do is to prevent, protect and empower disadvantaged Victorian
                children, young people,
                families and adults. Whether it be a helping hand in a time of crisis or providing longer-term support
                and care. Anglicare
                Victoria partners with local communities, the private and public sectors and their donors to deliver
                services in areas
                including family services, family violence, parenting, financial counselling, emergency relief, out of
                home care, alcohol
                and drug support, youth and other community services.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Bendigo Community Health Services:</h4>
            <p>
                Bendigo Community Health Services is a community-owned, not-for-profit organisation that delivers more
                than 50 programs which
                help people live healthier and more connected lives.
            </p>
            <br />
            <p>
                Bendigo Community Health Services’ programs and services are broad, reflecting the diverse needs of our
                community. They include
                (but are not limited to) access to GPs, along with services covering families and children, mental
                health and counselling, alcohol
                and other drugs, sexual health, men's and women's health clinics, supporting the LGBTIQ community and
                refugee settlement. BCHS
                also helps people manage specific health and wellbeing needs, such as managing their mental health or
                living with chronic
                conditions such as diabetes, respiratory issues or heart disease.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Brotherhood of St Laurence:</h4>
            <p>
                The Brotherhood of St Laurence (BSL) is a social justice organisation working alongside people
                experiencing disadvantage to
                address the fundamental causes of poverty in Australia. BSL provide services in areas including
                disability, aged care,
                employment, as well as conducting research and advocacy.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Consumer Action Law Centre:</h4>
            <p>
                National Debt Helpline – Consumer Action (NDH) is a free telephone financial counselling service
                delivered to people living in
                Victoria by Consumer Action Law Centre. NDH financial counsellors are qualified professionals who
                provide information, advice
                and referral for advocacy for people with debts who are in financial difficulty.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Community Information & Support Victoria:</h4>
            <p>
                Community information and support services are local community agencies that have been supporting
                vulnerable Victorians for over
                50 years. Agencies wrap services around a person in distress, providing information, advocacy,
                referrals, support and material aid,
                like assistance with food, household and medical bills, educational costs and a range of services.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Ethnic Communities' Council Victoria:</h4>
            <p>
                Ethnic Communities' Council Victoria (ECCV) is the member-based peak body in Victoria for migrant and
                refugee communities
                and people from culturally and linguistically diverse backgrounds. ECCV works closely with over 220
                member organisations run
                for and by migrant and refugee communities.
            </p>
            <br />
            <p>
                This includes ethnic associations, multicultural service providers, and eight ethnic communities'
                councils across rural and
                regional Victoria. Since 1974 ECCV has been advocating for human rights, freedom, respect, equality and
                dignity for migrant
                and refugee communities, and for the building of a socially cohesive and inclusive Victorian community.
                ECCV has a strong
                history in advocating for the rights of migrant and refugee communities, informing industry practice and
                influencing Federal,
                State and Local governments on a range of issues including culturally responsive approaches, anti-racism
                action, equitable
                access to services and socially just policy.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Good Shepherd - Neighbourhood Houses Victoria:</h4>
            <p>
                Good Shepherd aspires for all women, girls and families to be safe, strong and connected. Good Shepherd
                are committed to
                tackling the issues of our time which adversely affect them. Good Shepherd are Australia's oldest
                charity working to
                support women and girls experiencing abuse and disadvantage. Good Shepherd provides services and support
                in the areas of
                family and domestic violence, financial insecurity and youth experiencing disadvantage.
            </p>
            <br />
            <p>
                Neighbourhood Houses are local community-based organisations which offer a range of social, educational,
                recreational
                and support activities, for people of all ages, abilities and backgrounds.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">State Trustees:</h4>
            <p>
                State Trustees helps people plan, manage and live on through every stage of life. State Trustees deliver
                public benefit to
                Victorians through vital services and expert support that improves our clients' financial wellbeing and
                peace of mind.
            </p>

            <br />
            <h4 class="psboutreach-header-sub">Cohealth:</h4>
            <p>
                Cohealth is a not-for-profit community health organisation. They provide essential health and support
                services in
                Melbourne's CBD, and northern and western suburbs. By providing community health services, advocating
                for health and social equity, undertaking research, and developing
                health promotion initiatives
            </p>

            <br /><br />
            <h2 class="subTitle">Frequently asked Questions</h2>

            <h4 class="psboutreach-header-sub">When did applications for the $250 Power Saving Bonus close?</h4>
            <p>
                The fourth round of the $250 Power Saving Bonus closed on 31 August 2023. New applications will not be
                accepted after this date.
            </p>
            <br />
            <h4 class="psboutreach-header-sub">Will I still receive my $250 Bonus if my application hasn’t been approved
                and paid by 31 August 2023?</h4>
            <p>
                Yes, if you submitted your application by 11.59pm on 31 August 2023 and you meet the eligibility
                requirements (including having not previously applied) you will still receive your $250 Bonus.
            </p>
            <br />
            <h4 class="psboutreach-header-sub">I applied through an Outreach Partner or the Victorian Energy Compare
                helpline – how do I contact them?</h4>
            <p>
                If you applied with support from a community outreach partner and need to contact them about your
                application, please contact the organisation directly on the numbers listed below or call the Victorian
                Energy Compare helpline on 1800 000 832.
            </p>
            <p>Participating community organisations:</p>
            <ul>
                <li><strong>Anglicare Victoria:</strong> <a href="tel:1800531741">1800 531 741</a></li>
                <li><strong>Bendigo Community Health Services</strong>: <a href="tel:(03)54061200">(03) 5406 1200</a>.
                </li>
                <li><strong>The Brotherhood of St. Laurence (and partners GV Community Energy and Uniting
                        Vic.Tas)</strong>:
                    <a href="tel:(03)99568850">(03) 9956 8850</a> or <a href="tel:1800 830 029">1800 830 029</a>.
                </li>
                <li><strong>Community Information & Support Victoria</strong>: <a href="tel:(03)96722003">(03) 9672
                        2003</a>
                </li>
                <li><strong>Ethnic Communities' Council Victoria</strong>: <a href="tel:0466038261">0466 038 261</a>
                </li>
                <li><strong>Good Shepherd and Neighbourhood House</strong>: <a href="tel:1300026500">1300 026 500</a>
                </li>
                <li><strong>The National Debt Helpline - Consumer Action Law Centre</strong>: <a
                        href="tel:1800149689">1800
                        149 689</a></li>
                <li><strong>State Trustees</strong>: <a href="tel:1300138672">1300 138 672</a></li>
            </ul>

            <br />
            <p><a href="/psb-faq">Further information on the $250 Power Saving Bonus is available here.</a></p>

        </div>
    </div>
</div>
<br /><br />
