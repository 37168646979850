import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-solar-header-panel',
  templateUrl: './solar-header-panel.component.html',
  styleUrls: ['./solar-header-panel.component.css']
})
export class SolarHeaderPanelComponent implements OnInit {
  //get data from main component (results.component)
  @Input() solarData: { systemSize : 0 };
  @Input() SolarEstimatesData: { annualSavedTotal : 0 };
  constructor(private dataService : DataService) { }

  ngAfterViewInit() {
    //GA event for annual solar savings
    GA4LogEvent('/solar-results', 'SOLAR_ANNUAL_SAVINGS', Math.round(this.SolarEstimatesData.annualSavedTotal));
    GA4LogEvent('/solar-results', 'SOLAR_CALCULATOR_RESULTS', "Solar savings calculator - Estimated Potential Savings");
  }

  ngOnInit() {
  }

}
