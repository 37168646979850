import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';
import * as Bowser from "bowser";
import { OffersDescriptionModalComponent } from '../offers-description-modal/offers-description-modal.component';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-offers-left-panel',
  templateUrl: './offers-left-panel.component.html',
  styleUrls: ['./offers-left-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffersLeftPanelComponent implements OnInit {
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  @Input() retailers = [];
  @Input() tariffTypes = [];
  @Input() features = [];
  @Input() contractLength = [];
  @Input() selectedRetailers = [];
  @Input() selectedContracts = [];
  @Input() showIncentive = false;
  @Input() showGreenPower = false;
  @Input() showGoGreenFilter = false;
  @Input() energyType;
  @Input() showVDO = false;
  @Input() showCustomEligibility = false;
  @Input() showSolarFiT = false;
  @Input() showDemand = false;
  @Input() showInnovative = false;
  @Input() showPayment = false;
  @Input() showOfferTypesFilter = false;
  @Input() showTouOffer = false;
  @Input() showSingleRate = true;
  @Input() showFlexiblePricing = false;
  @Input() showResultsFilter = false;
  @Input() showRetailersFilter = false;
  @Input() showFeaturesFilter = false;
  @Input() showContractsFilter = false;
  @Input() showResourcesFilter = false;
  @Input() showGreenFilter = false;
  @Input() sortByConditionalPrice = true;
  @Input() showTopOffer = true;
  @Input() selectAllRetailers = true;
  @Input() selectAllContracts = true;
  @Input() compareIsDisabled = false;
  @Input() selectedOffers = [];
  @Input() offersCount = 0;
  @Input() offersShown = 0;
  @Input() hasSolar;
  @Output() compareSelected = new EventEmitter();
  @Output() updateFilters = new EventEmitter();
  @Output() resetResult = new EventEmitter();
  @Output() showMoreOffers = new EventEmitter();


  public browser;
  public browserName;
  public offersInfo = false;

  constructor(private cd: ChangeDetectorRef,
    public utility : Utility,
    public modalService : NgbModal,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
  }

  //any changes to @input variables
  ngOnChanges(changes: any) {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.browser = Bowser.getParser(window.navigator.userAgent);
    let browserDetails = this.browser.getBrowser();
    this.browserName = browserDetails.name;
  }

  public showComparison() {
    if (this.selectedOffers.length > 0) {
      this.compareSelected.emit();
    }
  }

  public showOffers() {
    if (this.offersInfo == false) {
      this.offersInfo = true;
      let modalRef = this.modalService.open(OffersDescriptionModalComponent, {size : 'lg'});
      modalRef.result.then((options) => {
        setTimeout(() => {
          this.showMoreOffers.emit();
        }, 100);
      });
      modalRef.result.then(() => {}, () => {
        setTimeout(() => {
          this.showMoreOffers.emit();
        }, 100);
      }); //enable buttons on click outside
    } else {
      this.showMoreOffers.emit();
    }
  }

  public reset() {
    this.resetResult.emit();
    this.utility.scrollToTop('top');
  }

  public selectRetailers() {
    if (this.browserName != 'Internet Explorer') {
      this.selectAllRetailers = !this.selectAllRetailers;
    }
    for (var retailer in this.retailers) {
      let selectedRetailer = { "selected" : this.selectAllRetailers };
      this.selectedRetailers[this.retailers[retailer].Id] = selectedRetailer;
    }
    this.updateFilters.emit({ 'name' : 'selectAllRetailers', 'value' : this.selectAllRetailers });
  }

  public selectContracts() {
    if (this.browserName != 'Internet Explorer') {
      this.selectAllContracts = !this.selectAllContracts;
    }
    this.selectedContracts["None"] = { "selected" : this.selectAllContracts };
    this.selectedContracts["1 Year"] = { "selected" : this.selectAllContracts };
    this.selectedContracts["2 Year"] = { "selected" : this.selectAllContracts };
    this.selectedContracts["3 Year"] = { "selected" : this.selectAllContracts };
    this.selectedContracts["4 Year"] = { "selected" : this.selectAllContracts };
    this.selectedContracts["5 Year"] = { "selected" : this.selectAllContracts };
    this.selectedContracts["Other"] = { "selected" : this.selectAllContracts };

    this.updateFilters.emit({ 'name' : 'selectAllContracts', 'value' : this.selectAllContracts });
  }

  public showFilters(filterName) {
    let value = false;
    if (filterName == "showRetailersFilter") {
      this.showRetailersFilter = !this.showRetailersFilter;
      value = this.showRetailersFilter;
    } else if (filterName == "showOfferTypesFilter") {
        this.showOfferTypesFilter = !this.showOfferTypesFilter;
        value = this.showOfferTypesFilter;
    } else if (filterName == "showResultsFilter") {
      this.showResultsFilter = !this.showResultsFilter;
      value = this.showResultsFilter;
    } else if (filterName == "showFeaturesFilter") {
      this.showFeaturesFilter = !this.showFeaturesFilter;
      value = this.showFeaturesFilter;
    } else if (filterName == "showContractsFilter") {
      this.showContractsFilter = !this.showContractsFilter;
      value = this.showContractsFilter;
    } else if (filterName == "showGoGreenFilter") {
      this.showGoGreenFilter = !this.showGoGreenFilter;
      value = this.showGoGreenFilter;
      GA4LogEvent('/offers', 'GO_GREEN');
      //return false;
    } else if (filterName == "showGoGreenFilter2") {
      if (this.showGoGreenFilter == false) {
        this.showGoGreenFilter = true;
        value = this.showGoGreenFilter;
      } else {
        if (this.showGreenPower == false) {
          this.showGoGreenFilter = false;
        }
      }
      GA4LogEvent('/offers', 'GO_GREEN');
    } else {
      this.showResourcesFilter = !this.showResourcesFilter;
      value = this.showResourcesFilter;
    }
    this.updateFilters.emit({ 'name' : filterName, 'value' : value });
  }

  public updateRetailers(retailerId) {
    if (this.browserName != 'Internet Explorer') {
      this.selectedRetailers[retailerId].selected = !this.selectedRetailers[retailerId].selected;
    }
    this.updateFilters.emit({ 'name' : 'selectedRetailers', 'value' : this.selectedRetailers });
  }

  public updateContracts(contract) {
    if (this.browserName != 'Internet Explorer') {
      this.selectedContracts[contract].selected = !this.selectedContracts[contract].selected
    }
    this.updateFilters.emit({ 'name' : 'selectedContracts', 'value' : this.selectedContracts });
  }

  public updateFeatures(filterName) {
    let value = false;
    if (filterName == "showIncentive") {
      if (this.browserName != 'Internet Explorer') {
        this.showIncentive = !this.showIncentive;
      }
      value = this.showIncentive;
    } else if (filterName == "sortByConditionalPrice") {
      if (this.browserName != 'Internet Explorer') {
        this.sortByConditionalPrice = !this.sortByConditionalPrice;
      }
      value = this.sortByConditionalPrice;
    } else if (filterName == "showTopOffer") {
      if (this.browserName != 'Internet Explorer') {
        this.showTopOffer = !this.showTopOffer;
      }
      value = this.showTopOffer;
    } else if (filterName == "showSingleRate") {
      if (this.browserName != 'Internet Explorer') {
        this.showSingleRate = !this.showSingleRate;
      }
      value = this.showSingleRate;
    } else if (filterName == "showFlexiblePricing") {
      if (this.browserName != 'Internet Explorer') {
        this.showFlexiblePricing = !this.showFlexiblePricing;
      }
      value = this.showFlexiblePricing;
    } else if (filterName == "showTouOffer") {
      if (this.browserName != 'Internet Explorer') {
        this.showTouOffer = !this.showTouOffer;
      }
      value = this.showTouOffer;
    } else if (filterName == "showPayment") {
      if (this.browserName != 'Internet Explorer') {
        this.showPayment = !this.showPayment;
      }
      value = this.showPayment;
    } else if (filterName == "showGreenPower") {
      if (this.browserName != 'Internet Explorer') {
        this.showGreenPower = !this.showGreenPower;
      }
      value = this.showGreenPower;
    } else if (filterName == "showVDO") {
      if (this.browserName != 'Internet Explorer') {
        this.showVDO = !this.showVDO;
      }
      value = this.showVDO;
    } else if (filterName == "showCustomEligibility") {
      if (this.browserName != 'Internet Explorer') {
        this.showCustomEligibility = !this.showCustomEligibility;
      }
      value = this.showCustomEligibility;
    } else if (filterName == "showDemand") {
      if (this.browserName != 'Internet Explorer') {
        this.showDemand = !this.showDemand;
      }
      value = this.showDemand;
    } else if (filterName == "showInnovative") {
      if (this.browserName != 'Internet Explorer') {
        this.showInnovative = !this.showInnovative;
      }
      value = this.showInnovative;
    }
    else if (filterName == "showSolarFiT") {
      if (this.browserName != 'Internet Explorer') {
        this.showSolarFiT = !this.showSolarFiT;
      }
      value = this.showSolarFiT;
    }
    this.updateFilters.emit({ 'name' : filterName, 'value' : value });
  }

}
