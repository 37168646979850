import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';

@Component({
  selector: 'app-community-outreach-survey',
  templateUrl: './community-outreach-survey.component.html',
  styleUrls: ['./community-outreach-survey.component.css']
})
export class CommunityOutreachSurveyComponent implements OnInit {
  public bannerTitle = "$250 Power Saving Bonus Community Outreach Applicant Survey";
  public bannerSubTitle = "Fill in our online survey to provide feedback on the Community Outreach program.";
  public bannerDetails = "";

  constructor(public utility : Utility) { }

  ngAfterViewInit() {
    var script = document.createElement('script');
    script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdwgeUYA_2BUgoyEPmZcGjh3xGA7oUVjvVmH0P2qo7Sbmy3.js";
    document.getElementById("smcx-sdk").appendChild(script);
  }

  ngOnInit(): void {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = '$250 Power Saving Bonus Community Outreach Applicant Survey';
  }

}
