<div class="alert-message alert-message-warning">
    <div class="row">
        <div class="col-md-9 header-banner-title">
            <h1 class="headerTitle">
                <span *ngIf="router.url != '/offers/list/dual' && router.url != '/loopback/compare'">
                    {{ energyType == 'Gas' ? 'Gas' : 'Electricity' }} offers in your area
                </span>
                <span *ngIf="router.url == '/offers/list/dual' || router.url == '/loopback/compare'">
                    Electricity and gas offers in your area
                </span>
            </h1>
            <div class="custom-subheader-padding">
                <ul>
                    <li *ngIf="userType != 'Small business'">
                        Energy concessions: <span class="strong">{{ (hasConcession == '1') ? 'Yes' : 'No' }}</span>
                    </li>
                    <li *ngIf="bill != 'no bill'" [ngClass]="{ 'daily-usage': userType != 'Small business' }">
                        Average daily usage:
                        <span *ngIf="router.url != '/offers/list/dual' && averageDailyConsumption != undefined" class="strong">
                            {{ (energyType == loopBackEnergyType) ? loopBackAverageDailyConsumption : averageDailyConsumption }}
                        </span>
                        <span *ngIf="router.url == '/offers/list/dual' && averageDailyConsumption != undefined" class="strong">
                            {{ averageDailyConsumption + "/" + loopBackAverageDailyConsumption }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-3 offer-header-banner">
            <img
                aria-label="Switch to a flexible pricing plan to save on bills"
                alt="Switch to a flexible pricing plan to save on bills"
                src="assets/images/faq2.png"
                (click)="faqModal()"
                id="offer-banner">
            <div class="header-banner-link">
                <a
                    alt="Frequently Asked Questions"
                    title="Frequently Asked Questions"
                    aria-label="Frequently Asked Questions"
                    (click)="faqModal()"
                    class="quiz-link non-focus link">
                    Frequently Asked Questions
                </a>
            </div>
            <div class="in-your-language" *ngIf="router.url == '/offers' || router.url == '/compare/electricity' || router.url == '/compare/electricity' || router.url == '/compare/gas' || router.url == '/offers/list/gas' || router.url == '/offers/list/dual' || router.url == '/offers/list/electricity' || router.url == '/loopback/compare'">
                <a (click)="faqModal()" alt="in your language" target="_blank" class="in-your-language">
                    <img src="/assets/images/button_in_your_language.png" alt="In your in-your-language">
                </a>
            </div>
        </div>
    </div>
</div>
