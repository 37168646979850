import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { environment } from '../../environments/environment'; //config file
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { CollectionStatementModalComponent } from '../collection-statement-modal/collection-statement-modal.component';
import { DistributorInformationModalComponent } from '../distributor-information-modal/distributor-information-modal.component';
import { TermsModalComponent } from '../terms-modal/terms-modal.component';
import { PowercorInfoModalComponent } from '../powercor-info-modal/powercor-info-modal.component';
import { DownloadDataModalComponent } from '../download-data-modal/download-data-modal.component';
import { ConsessionInfoModalComponent } from '../consession-info-modal/consession-info-modal.component';
import { SmartMeterModalComponent } from '../smart-meter-modal/smart-meter-modal.component';
import { TwelveMonthsModalComponent } from '../twelve-months-modal/twelve-months-modal.component';
import { Utility } from '../utility';
import * as _ from 'lodash';
import { ReCaptchaService } from '../reCaptcha.service';

@Component({
  selector: 'app-loopback-start',
  templateUrl: './loopback-start.component.html',
  styleUrls: ['./loopback-start.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class LoopbackStartComponent implements OnInit {
  public secBranding = environment.secBranding;
  public allDataIsSet = false;
  public isIE = false;
  public bannerTitle = "";
  public bannerSubTitle = 'We have already collected information about how you use your energy. We just need a little more information to estimate your gas usage. This will only take a few minutes.';
  public bannerDetails = "";
  public recaptchaEnabled = environment.recaptchaEnabled;
  private reCaptchaToken : any;
  public googleSiteKey = environment.googleSiteKey;
  public recaptchaError;
  private timer;
  public distributorErrorMessage = '';
  public saveFormError = '';
  public distributorError = false;
  public showNMIAnimation = false;
  public NMIStatus = '';
  public NMIErrorMessage = '';
  public NMIIsComplete = false;
  public NMIPercentage = 0;
  public showUploadAnimation = false;
  public fileUploadStatus = '';
  public fileUploadIsComplete = false;
  public fileUploadPercentage = 0;
  public retailersLoading = false;
  public retailers;
  public uploadRetailers;
  private subscription : Subscription;
  private initEnergyType = true;
  private initUserType = true;
  private initBill = true;
  private initTwelveMonths = true;
  private initUsageProfile = true;
  private initSolarFields = true;
  private initSolar = true;
  public NMIValidation;
  public solarCapacityValidation = null;
  public feedInTariffValidation = null;
  public fileProviderValidation = null;
  public compareURL = environment.compareURL;
  public solarURL = environment.solarURL;
  public appURL = environment.appURL;
  public VECForm : FormGroup;
  public submitDisabled = true;
  public distributors = [];
  public distributorsInfo;
  public distributorZoneClash = false;
  public skipNMIData = false;
  public userData = {
    showSolarSelection : true,
    energyType : "",
    userType : "",
    bill : "",
    twelveMonths : "",
    retailer : "",
    retailerName : "",
    postcode : "",
    usageProfile : '0',
    averageDailyConsumption : 0,
    nmi : "",
    skipNMI : false,
    smartMeter : "1",
    fileProvider : "",
    file : "",
    agree : "",
    disclaimer : "",
    hasSolar : "",
    solarCapacity : "",
    hasConcession : "",
    feedInTariff : "",
    serverCacheId : "",
    distributor : { id : "", name : "", distribution_zone_name : "" },
    distributorDerived : 0,
    recaptchaResponse : "",
    fileName : "",
    distributorSubmit : false,
    pageDataType : "energyConfigData",
    loopBack : false
  }

  public loopBackValues = {
    hasConcession : "",
    hasSolar : ""
  }

  public formShowSelection = {
    distributorInfo : false,
    distributors : false,
    userType : false,
    bill : false,
    twelveMonths : false,
    retailer : false,
    postcode : false,
    usageProfile : false,
    solar : false,
    concession : false,
    disclaimer : false,
    uploadData : false
  };

  constructor(
    public formBuilder : FormBuilder,
    private reCaptchaService: ReCaptchaService,
    public router : Router,
    public config : NgbModalConfig,
    public modalService : NgbModal,
    public utility : Utility,
    public spinner : NgxSpinnerService,
    public dataService : DataService,
    private tooltipConfig : NgbTooltipConfig,
    private cdr: ChangeDetectorRef) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
  }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.modalService.dismissAll(); //close modal dialog if there are any open
    document.title = 'Welcome - Victorian Energy Compare';

    //subscribe for reCaptcha token
    this.reCaptchaService.token$.subscribe(token => {
      this.userData.recaptchaResponse = token;
      this.reCaptchaToken = token;
    });

    this.initializeForm();
    let ua = window.navigator.userAgent;
    this.isIE = /MSIE|Trident/.test(ua);
  }

  private getRetailers(hideLoader = true) {
    this.retailersLoading = true;
    this.spinner.show();
    this.retailersLoading = false;
    this.dataService.getAPIRetailers(this.userData.energyType).subscribe(
      retailers => {
        retailers = retailers["data"];
        this.retailers = retailers; //get data object from rest api
        this.uploadRetailers = JSON.parse(JSON.stringify(retailers)); //avoid getting variable by refrerence
        this.dataService.setRetailers(retailers);
        this.dataService.getAPIDistributorsList(this.userData.energyType).subscribe(distributorsData => {
          let distributors = distributorsData["data"];
          for (var distributorIndex in distributors) {
            let newRetailer = {
              id : distributorsData['data'][distributorIndex].id,
              title : distributorsData['data'][distributorIndex].name,
              aemo_id : distributorsData['data'][distributorIndex].id,
            };
            this.uploadRetailers.push(newRetailer);
          }
          this.uploadRetailers = _.orderBy(this.uploadRetailers, [function(o) { return o['title'].toLowerCase(); }], 'asc');
        });
        if (hideLoader == true) {
          this.spinner.hide();
        }
        this.reCaptchaService.initializeRecaptcha();
      },
      error => {
        this.spinner.hide();
        this.reCaptchaService.initializeRecaptcha();
      }
    );
  }

  public resetForm() {
    let loopBack = true;
    this.userData.showSolarSelection = true;
    this.distributorError = false;
    this.VECForm.controls["nmi"].markAsPristine();
    this.VECForm.controls["nmi"].markAsUntouched();
    this.VECForm.controls["retailer"].markAsUntouched();
    this.VECForm.controls["postcode"].markAsUntouched();

    this.distributors = [];
    let serverCacheId = this.userData.serverCacheId;
    this.userData = {
      showSolarSelection : true,
      energyType : "",
      userType : "",
      bill : "",
      twelveMonths : "",
      retailer : "",
      retailerName : "",
      postcode : "",
      usageProfile : '0',
      averageDailyConsumption : 0,
      nmi: "",
      skipNMI : false,
      smartMeter : "1",
      fileProvider : "",
      file : "",
      agree : "",
      disclaimer : "",
      hasSolar : "",
      solarCapacity : '',
      hasConcession : "",
      feedInTariff : "",
      serverCacheId : serverCacheId,
      distributor : { id : "", name : "", distribution_zone_name : "" },
      distributorDerived : 0,
      recaptchaResponse : this.reCaptchaToken,

      fileName : "",
      distributorSubmit : false,
      pageDataType : "energyConfigData",
      loopBack: false
    };

    this.resetFormSelection();
    this.NMIValidation = <FormControl>this.VECForm.get('nmi');
    this.solarCapacityValidation = <FormControl>this.VECForm.get('solarCapacity');
    this.feedInTariffValidation = <FormControl>this.VECForm.get('feedInTariff');
    this.solarCapacityValidation.setValidators(null);
    this.feedInTariffValidation.setValidators(null);
    this.NMIValidation.setValidators(null);
    this.NMIValidation.updateValueAndValidity();
    this.feedInTariffValidation.updateValueAndValidity();
    this.solarCapacityValidation.updateValueAndValidity();
  }

  public resetFormSelection() {
    this.formShowSelection = {
      distributorInfo : false,
      distributors : false,
      userType : false,
      bill : false,
      twelveMonths : false,
      retailer : false,
      postcode : false,
      usageProfile : false,
      concession : false,
      solar : false,
      disclaimer : false,
      uploadData : false
    };
  }

  public enableSubmit() {
    //if (Number(this.userData.postcode) >= 3000 && Number(this.userData.postcode) <= 4000) {
    if (this.userData.postcode.length == 4) {
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
      this.formShowSelection.distributorInfo = false;
      this.formShowSelection.distributors = false;
      this.userData.distributorDerived = 0;
      this.formShowSelection.usageProfile = false;
      this.formShowSelection.concession = false;
      this.formShowSelection.solar = false;
      this.formShowSelection.disclaimer = false;
      this.distributorErrorMessage = '';
      this.distributorError = false;
      this.userData.usageProfile = '0';
      this.userData.nmi = '';
      this.userData.agree = '';
      this.userData.smartMeter = "1";
      this.userData.fileProvider = "";
      this.userData.file = '';
    }
  }

  public resetValdators() {
    if (this.feedInTariffValidation != null) {
      this.feedInTariffValidation.setValidators(null);
      this.feedInTariffValidation.updateValueAndValidity();
    }
    if (this.solarCapacityValidation != null) {
      this.solarCapacityValidation.setValidators(null);
      this.solarCapacityValidation.updateValueAndValidity();
    }
    if (this.fileProviderValidation != null) {
      this.fileProviderValidation.setValidators(null);
      this.fileProviderValidation.updateValueAndValidity();
    }
  }

  public selectButton(event, name) {
    if (event.keyCode == 32 || event.keyCode == 13) { //spacebar & enter
      if (name == "Electricity" || name == "Gas" || name == "Solar") {
        this.userData.energyType = name;
      } else if (name == "Residential" || name == "Small business") {
        this.userData.userType = name;
      } else if (name == "bill" || name == "no bill") {
        this.userData.bill = name;
      } else if (name == "submitPostCode") {
        this.getDistributors();
      } else if (name == "12monthYes") {
        this.userData.twelveMonths = '1';
      } else if (name == "12monthNo") {
        this.userData.twelveMonths = '0';
      } else if (name == "concessionYes") {
        this.userData.hasConcession = '1';
      } else if (name == "concessionNo") {
        this.userData.hasConcession = '0';
      } else if (name == "concessionModal") {
        this.concessionModal();
      } else if (name == "smartMeterYes") {
        this.userData.smartMeter = '1';
      } else if (name == "smartMeterNo") {
        this.userData.smartMeter = '0';
        this.smartMeterGroupFields();
        this.smartMeterInformation();
      } else if (name == "solarYes") {
        this.userData.hasSolar = '1';
      } else if (name == "solarNo") {
        this.userData.hasSolar = '0';
      } else if (name == "terms") {
        this.termsModal();
      } else if (name == "saveData") {
        this.saveData();
      } else if (name == "saveSetData") {
        this.saveSetData();
      } else if (name == "fullStatement") {
        this.collectionStatementModal();
      } else if (name == "downloadData") {
        this.downloadDataModal();
      } else if (name == "getMyData") {
        this.setDefaultUsageProfile();
      } else if (name == "uploadData") {
        this.userData.usageProfile = '2';
      } else if (name == "questionnaire") {
        this.userData.usageProfile = '3';
        this.formShowSelection.concession = true;
        this.initializeLoopBackValues();
      } else if (name == "solarDefault") {
        this.setDefaultSolar();
      } else if (name == "GetNMIdata") {
        this.getConsumption();
      } else if (name == "skipNMI") {
        this.skipNMIProcess();
      }
    }
  }

  public getConsumption() {
    this.NMIValidation = <FormControl>this.VECForm.get('nmi');
    this.NMIValidation.setValidators([
      this.isNMIValid.bind(this) //custom javascript validation function
    ]);
    this.NMIValidation.updateValueAndValidity();
    this.userData.serverCacheId = localStorage.getItem("serverCacheId");
    this.userData.averageDailyConsumption = 0;
    this.utility.setErrorMessage('');
    this.formShowSelection.disclaimer = false;
    this.formShowSelection.solar = false;
    this.formShowSelection.concession = false;
    this.userData.feedInTariff = '';
    this.userData.solarCapacity = '';
    this.userData.hasConcession = '';
    this.userData.hasSolar = '';
    this.NMIIsComplete = false;
    this.NMIStatus = '';
    this.NMIErrorMessage = '';
    if (this.VECForm.status == 'VALID') {
      this.showNMIAnimation = true;
      if (this.NMIPercentage == 0 || this.NMIPercentage == 100) {
        this.utility.scrollToNextForm(180);
        this.showPercentage(0, 50, 125, '');
          this.dataService.getConsumption(this.userData).subscribe(
            data => {
              if (data["status"] == 'success') {
                this.userData.serverCacheId = data["serverCacheId"];
                localStorage.setItem("serverCacheId", data["serverCacheId"]);
                this.userData.hasSolar = (data["hasSolar"] == true) ? '1' : '0';
                if (this.userData.hasSolar == '0') {
                  this.removeSolarValidation();
                }
                this.userData.averageDailyConsumption = data["averageDailyConsumption"];
                this.userData.showSolarSelection = false;
                this.progressLoader('AEMO');
              } else {
                this.NMIErrorMessage = data["message"];
                this.progressLoader('AEMO', 'error');
              }
              this.reCaptchaService.initializeRecaptcha();
            },
            error => {
              this.progressLoader('AEMO', 'error');
              this.reCaptchaService.initializeRecaptcha();
            }
          );
      }
    } else {
      if (this.userData.nmi == '') {
        this.VECForm.get('nmi').markAsTouched();
        this.VECForm.get('nmi').markAsDirty();
      }
      this.utility.scrollToError();
    }
  }

  public getDistributors() {
    this.distributorZoneClash = false;
    this.distributorError = false;
    if (this.userData.postcode.length == 4 && !isNaN(parseFloat(this.userData.postcode))) {
      this.spinner.show();
      if (Number(this.userData.postcode) < 3000  || Number(this.userData.postcode) > 3999) {
        this.formShowSelection.usageProfile = true;
        this.spinner.hide();
      } else {
        this.dataService.getDistributors(this.userData.postcode, this.userData.energyType).subscribe(
          results => {
            //if there is some issue in the api
            if (results['status'] == "error") {
              this.distributorError = true;
              this.distributorErrorMessage = "An error has occurred while trying to submit the postcode, please try again later.";
            } else {
              if (results['data'].length > 1) {
                this.distributors = results['data'];
                this.formShowSelection.postcode = true;
                this.formShowSelection.distributors = true;
                this.userData.distributorDerived = 1;
                //check for zone clash
                let distributorItems = [];
                for (var key in this.distributors) {
                  if (distributorItems.indexOf(this.distributors[key].name) !== -1) {
                    this.distributorZoneClash = true;
                  }
                  distributorItems.push(this.distributors[key].name);
                }
                distributorItems = null; //free memory
                if (this.userData.energyType == 'Gas') {
                  this.userData.distributor = results['data'][0];
                }
              } else {
                if (this.userData.bill == 'no bill') {
                  this.formShowSelection.solar = true;
                }

                if (results['data'].length == 0 && Number(this.userData.postcode) >= 3000 && Number(this.userData.postcode) <= 3999) {
                  this.distributorError = true;
                  this.distributorErrorMessage = "No distributor found for postcode.";
                } else {
                  this.userData.distributor = results['data'][0];
                  this.dataService.setDistributor(this.userData.distributor);
                  if ((this.userData.bill == 'bill' && this.userData.energyType != 'Gas' && this.userData.twelveMonths == '1') ||
                      (this.userData.userType == 'Small business' && this.userData.twelveMonths == '1')) {
                    this.formShowSelection.usageProfile = true;
                    this.setDefaultUsageProfile();
                  } else {
                    if (this.userData.userType == 'Small business') {
                      if (this.userData.energyType == 'Gas') {
                        this.formShowSelection.disclaimer = true;
                      } else {
                        this.userData.usageProfile = '2';
                      }
                    }
                  }
                  this.initializeLoopBackValues();
                }
              }
            }
            this.spinner.hide();
            this.reCaptchaService.initializeRecaptcha();
          },
          error => {
            this.distributorError = true;
            this.spinner.hide();
            this.reCaptchaService.initializeRecaptcha();
          },
        );
      }
    }
  }

  public createForm() {
    this.VECForm = this.formBuilder.group({
      energyType : new FormControl(''),
      userType : new FormControl(''),
      bill : new FormControl(''),
      twelveMonths : new FormControl(''),
      retailer : new FormControl('', [Validators.required]),
      postcode : new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        //Validators.min(3000),
        //Validators.max(4000),
        Validators.pattern('^[0-9]+$') //field type must be integer
      ]),
      usageProfile : new FormControl(''),
      nmi : new FormControl(''),
      smartMeter : new FormControl(''),
      fileProvider : new FormControl(''),
      file : new FormControl(''),
      feedInTariff : new FormControl(''),
      hasSolar : new FormControl(''),
      solarCapacity : new FormControl(''),
      hasConcession : new FormControl(''),
      agree : new FormControl(''),
      disclaimer : new FormControl('')
    });

    /*
    * custom validation for fields
    * validation rules based on other fields
    * if a field gets selected then some other field will have validation
    */
    this.NMIValidation = <FormControl>this.VECForm.get('nmi');
    this.VECForm.controls['usageProfile'].valueChanges.subscribe(value => {
      if (this.skipNMIData == true) {
        this.userData.skipNMI = false;
      }
      this.skipNMIData = false;

      if (value == "1") {
        if (this.userData.nmi == null) {
          this.userData.nmi = '';
        }

        this.NMIValidation.setValidators([
          this.isNMIValid.bind(this) //custom javascript validation function
        ]);
      } else {
        this.NMIValidation.setValidators(null);
      }
      this.NMIValidation.updateValueAndValidity();
    });
  }

  private showPercentage(start, percent, speed, status) {
    if (this.timer > 0) {
      clearInterval(this.timer);
    }
    this.NMIPercentage = start;
    this.timer = setInterval(() => {
      if (this.NMIPercentage == percent) {
        clearInterval(this.timer);
        if (percent == 100) {
          this.NMIIsComplete = true;
          this.NMIStatus = status;
          if (status == 'success') {
            this.formShowSelection.concession = true;
            this.formShowSelection.solar = this.userData.showSolarSelection;
            this.userData.hasConcession = this.loopBackValues.hasConcession;
            if (this.userData.hasSolar == '1') {
              this.formShowSelection.solar = true;
            }
            this.utility.scrollToNextForm();
            if (this.userData.userType == 'Small business') {
              this.utility.scrollToNextForm();
              this.userData.hasConcession = '0';
              if (this.userData.hasSolar != '' && this.userData.hasSolar == '0') {
                this.formShowSelection.disclaimer = true;
              } else {
                this.showSolarFormFields();
              }
            }
            if (this.userData.showSolarSelection == false) {
              this.formShowSelection.disclaimer = true;
            }
          }
        }
      } else {
        this.NMIPercentage++;
      }
    }, speed);
  }

  private showFilePercentage(start, percent, speed, status) {
    if (this.timer > 0) {
      clearInterval(this.timer);
    }
    this.fileUploadPercentage = start;

    this.timer = setInterval(() => {
      if (this.fileUploadPercentage == percent) {
        clearInterval(this.timer);
        if (percent == 100) {
          this.fileUploadIsComplete = true;
          this.fileUploadStatus = status;
          if (status == 'success') {
            this.formShowSelection.concession = true;
            this.userData.hasConcession = this.loopBackValues.hasConcession;
            if (this.userData.hasSolar == '1') {
              this.formShowSelection.solar = true;
            }
            this.utility.scrollToNextForm();
            if (this.userData.userType == 'Small business') {
              this.utility.scrollToNextForm();
              this.userData.hasConcession = '0';
              this.formShowSelection.solar = true;
              if (this.userData.hasSolar == '0') {
                this.formShowSelection.disclaimer = true;
              }
            }
            if (this.userData.showSolarSelection == false) {
              this.formShowSelection.disclaimer = true;
            }
          }
        }
      } else {
        this.fileUploadPercentage++;
      }
    }, speed);
  }


  /*
  * Checksum validation for NMI
  */
  public isNMIValid = (control : FormControl) : ValidationErrors => {
    var nmi = control.value;
    let NMIPrefix;
    let NMIUATPrefix = nmi.substring(0, 5).toUpperCase();
    //check nmi length
    if (nmi.length < 10 || nmi.length > 11) { // 1
      return { 'NMILength' : true } //return error message invalid NMI
    } else if (!/^(XXXXX|TESTV[0-9]{5,6})|([0-9]{10,11})+$/.test(nmi)) { //only allow X & numbers
      return { 'NMIPrefix' : true } //return error message invalid NMI
    } else { //2
      NMIPrefix = nmi.substring(0, 1);
      if (NMIPrefix != 6 && NMIUATPrefix != 'XXXXX' && NMIUATPrefix != 'TESTV') { //AEMO Prefix format
        let distributorName = this.userData.distributor.name;
        if (distributorName == 'AusNet Services (electricity)') {
          return { 'NMIPrefixStartAusnet' : true }
        } else if (distributorName == 'Citipower') {
          return { 'NMIPrefixStartCitipower' : true }
        } else if (distributorName == 'Jemena') {
          return { 'NMIPrefixStartJemena' : true }
        } else if (distributorName == 'Powercor') {
          return { 'NMIPrefixStartPowercor' : true }
        } else {
          return { 'NMIPrefixStartUnited' : true }
        }
      }
    }
    //3
    NMIPrefix = nmi.substring(0, 4);
    if (NMIUATPrefix == 'XXXXX' || NMIUATPrefix == 'TESTV') { //AEMO test NMI's
      return null; //nmi valid
    } else if (this.utility.checkNMIPrefix(this.userData.distributor.name, NMIPrefix) == false) {
      let distributorName = this.userData.distributor.name;
      if (distributorName == 'AusNet Services (electricity)') {
        return { 'NMIPrefixAusnet' : true }
      } else if (distributorName == 'Citipower') {
        return { 'NMIPrefixCitipower' : true }
      } else if (distributorName == 'Jemena') {
        return { 'NMIPrefixJemena' : true }
      } else if (distributorName == 'Powercor') {
        return { 'NMIPrefixPowercor' : true }
      } else {
        return { 'NMIPrefixUnited' : true }
      }
    } else if (nmi.length == 10 && !isNaN(nmi)) {
      return null; //nmi valid
    } else if (nmi.length > 10 && !isNaN(nmi)) {
      var nmiWithoutChecksum = nmi.substr(0, nmi.length-1);
      var checksum = nmi.substr(-1);

      var doubleValue = true;
      var total = 0.0;

      for (var i = nmiWithoutChecksum.length - 1; i >= 0; i--) {
        var ascii = nmiWithoutChecksum.charCodeAt(i);
        //double the ascii value of every second digit
        var doubled = doubleValue ? ascii * 2 : ascii;
        doubled = '' + doubled;

        for (var j = 0; j < doubled.length; j++) {
          total += parseInt(doubled.charAt(j));
        }
        doubleValue = !doubleValue;
      }
      var calculated = (10 - (total % 10)) % 10;
      if (checksum == calculated) {
        return null; //nmi valid
      } else {
        return { 'NMIPrefix' : true } //return error message invalid NMI
      }
    } else {
      return { 'NMIPrefix' : true }
    }
  }

  public resetNMI() {
    this.userData.skipNMI = false;
    this.showNMIAnimation = false;
    this.formShowSelection.concession = false;
    this.formShowSelection.solar = false;
    this.formShowSelection.disclaimer = false;
    this.NMIStatus = '';
  }

  private updateForms() {
    this.VECForm.controls['nmi'].valueChanges.subscribe(value => {
      if (value.length > 9) {
        this.VECForm.controls["nmi"].markAsTouched();
      }
    });

    this.VECForm.controls['energyType'].valueChanges.subscribe(value => {
      if (this.VECForm.value['energyType'] != value) {
        if (value != '') {
          this.dataService.changeEnergyType(value);

          if (this.userData.userType != '') {
            if (this.initEnergyType == false) {
              this.resetForm();
            }
          }
          this.initEnergyType = false;
          this.formShowSelection.userType = true;
        }
      }
    });
    this.VECForm.controls['userType'].valueChanges.subscribe(value => {
      //run onchange if there is a change in value only
      if (this.VECForm.value['userType'] != value) {
        if (this.initUserType == false) {
          this.formShowSelection.postcode = false;
          this.formShowSelection.retailer = false;
          this.formShowSelection.twelveMonths = false;
          this.formShowSelection.bill = false;
          this.formShowSelection.concession = false;
          this.formShowSelection.usageProfile = false;
          this.formShowSelection.solar = false;
          this.formShowSelection.disclaimer = false;
          this.userData.usageProfile = '0';
          this.userData.nmi = '';
          this.userData.hasConcession = '';
          this.userData.twelveMonths = '';
          this.userData.retailer = '';
          this.userData.bill = '';
          this.formShowSelection.distributors = false;
          this.userData.distributorDerived = 0;
        }
        if (value == 'Residential') {
          setTimeout(() => this.formShowSelection.bill = true);
        } else if (value == 'Small business') {
          if (this.userData.energyType == 'Gas') {
            setTimeout(() => this.formShowSelection.retailer = true);
          } else {
            setTimeout(() => this.formShowSelection.twelveMonths = true);
          }
        }
        this.initUserType = false;
      }
    });
    this.VECForm.controls['bill'].valueChanges.subscribe(value => {
      //run onchange if there is a change in value only
      if (this.VECForm.value['bill'] != value) {
        if (this.initBill == false) {
          this.formShowSelection.retailer = false;
          this.formShowSelection.postcode = false;
          this.formShowSelection.concession = false;
          this.formShowSelection.solar = false;
          this.formShowSelection.disclaimer = false;
          this.formShowSelection.distributors = false;
          this.userData.distributorDerived = 0;
          this.formShowSelection.distributorInfo = false;
          this.formShowSelection.usageProfile = false;
        }
        //reset forms
        if (value == 'no bill') {
          this.fileProviderValidation = <FormControl>this.VECForm.get('fileProvider');
          this.fileProviderValidation.setValidators(null);
          this.fileProviderValidation.updateValueAndValidity();

          this.NMIValidation = <FormControl>this.VECForm.get('nmi');
          this.NMIValidation.setValidators(null);
          this.NMIValidation.updateValueAndValidity();

          let retailerValidation = <FormControl>this.VECForm.get('retailer');
          retailerValidation.setValidators(null);
          retailerValidation.updateValueAndValidity();

          this.userData.twelveMonths = '';
          this.formShowSelection.postcode = true;
        } else if (value == 'bill') {
          if (this.userData.energyType == 'Gas') {
            this.formShowSelection.retailer = true;
            this.userData.twelveMonths = '';
          } else {
            setTimeout(()=> {
              this.formShowSelection.twelveMonths = true;
            }, 0);
          }
        }
        this.initBill = false;
      }
    });

    this.VECForm.controls['twelveMonths'].valueChanges.subscribe(value => {
      //run onchange if there is a change in value only
      if (this.VECForm.value['twelveMonths'] != value) {
        if (this.initTwelveMonths == false) {
          this.userData.retailer = '';
          this.formShowSelection.retailer = false;
          this.formShowSelection.postcode = false;
          this.formShowSelection.concession = false;
          this.formShowSelection.solar = false;
          this.formShowSelection.disclaimer = false;
          this.formShowSelection.usageProfile = false;
          this.formShowSelection.distributors = false;
          this.userData.distributorDerived = 0;
          this.userData.usageProfile = '0';
        }

        if (value != '') {
          if (this.userData.userType == 'Small business' && this.userData.energyType != 'Gas' && value == '0') {
            this.modalService.open(TwelveMonthsModalComponent, { size : 'lg' });
          } else {
            this.formShowSelection.retailer = true;
          }
        }
        this.initTwelveMonths = false;
      }
    });

    this.VECForm.controls['retailer'].valueChanges.subscribe(value => {
      this.distributorError = false;
      if (value != '' && value != null) {
        if (this.userData.energyType == 'Electricity') {
          this.formShowSelection.postcode = false;
          //get distributors
          this.formShowSelection.postcode = true;
          this.distributorError = false;
          this.formShowSelection.distributorInfo = false;
          this.formShowSelection.distributors = false;
          this.spinner.show();
          this.dataService.getDistributors(this.userData.postcode, this.userData.energyType).subscribe(
            results => {
              //if there is some issue in the api
              if (results['status'] == "error") {
                this.distributorError = true;
                this.distributorErrorMessage = "An error has occurred while trying to submit the postcode, please try again later.";
              } else {
                if (results['data'].length > 1) {
                  this.distributors = results['data'];
                  this.formShowSelection.distributors = true;
                  this.userData.distributorDerived = 1;
                } else {
                  if (results['data'].length == 0) {
                    this.distributorError = true;
                    this.distributorErrorMessage = "No distributor found for postcode.";
                  } else {
                    this.userData.distributor = results['data'][0];
                    this.dataService.setDistributor(this.userData.distributor);
                    if ((this.userData.bill == 'bill' && this.userData.twelveMonths == '1') || (this.userData.userType == 'Small business' && this.userData.twelveMonths == '1')) {
                      this.formShowSelection.usageProfile = true;
                      this.setDefaultUsageProfile();
                    } else {
                      if (this.userData.userType == 'Small business') {
                        this.userData.usageProfile = '2';
                      } else {
                        this.formShowSelection.concession = true;
                      }
                    }
                  }
                }
              }
              this.spinner.hide();
            },
            error => {
              this.distributorError = true;
              this.spinner.hide();
            },
          );
        } else {
          this.formShowSelection.disclaimer = true;
        }
      }
    });

    this.VECForm.controls['usageProfile'].valueChanges.subscribe(value => {
      this.utility.setErrorMessage('');
      let profileValidation = <FormControl>this.VECForm.get('usageProfile');
      if (this.VECForm.value['usageProfile'] !== value || this.userData.usageProfile != value) {
        this.showNMIAnimation = false;
        this.showUploadAnimation = false;
        this.feedInTariffValidation = <FormControl>this.VECForm.get('feedInTariff');
        this.feedInTariffValidation.setValidators(null);
        this.feedInTariffValidation.updateValueAndValidity();
        if (this.initUsageProfile == false) {
          this.formShowSelection.concession = false;
          this.formShowSelection.solar = false;
          this.formShowSelection.disclaimer = false;
          this.userData.file = '';
          this.userData.fileName = '';
          setTimeout(()=> {
            this.userData.hasSolar = '';
            this.userData.disclaimer = '';
            this.userData.hasConcession = '';
            this.initializeLoopBackValues();
            this.userData.smartMeter = '1';
          }, 0);
        }
        if (value == 3) {
          this.formShowSelection.concession = true;
          this.initializeLoopBackValues();
          this.fileProviderValidation = <FormControl>this.VECForm.get('fileProvider');
          this.fileProviderValidation.setValidators(null);
          this.fileProviderValidation.updateValueAndValidity();
          if (this.userData.hasConcession != '') {
            if (this.userData.energyType == 'Gas') {
              this.formShowSelection.disclaimer = true;
            }
            if (this.userData.hasSolar != '' && this.userData.hasSolar == '0') {
              this.formShowSelection.disclaimer = true;
            } else {
              this.showSolarFormFields();
            }
          }
        }

      }
      this.initUsageProfile = false;
    });

    this.VECForm.controls['hasConcession'].valueChanges.subscribe(value => {
      //run onchange if there is a change in value only
      if (this.VECForm.value['hasConcession'] != value) {
        if (this.initSolarFields == false) {
          this.userData.feedInTariff = "";
          this.userData.solarCapacity = "";
        }

        if (value != '') {
          if (value == '1') {
            setTimeout(()=> {
              document.getElementById("concessionTipButton").click();
            }, 0);
          }

          setTimeout(()=> {
            if (this.userData.energyType == 'Gas') {
              this.formShowSelection.disclaimer = true;
            }
            if (this.userData.hasSolar != '' && this.userData.hasSolar == '0') {
              this.formShowSelection.disclaimer = true;
            } else {
              this.showSolarFormFields();
            }
          }, 0);
        }

      }
      this.initSolarFields = false;
    });

    this.VECForm.controls['hasSolar'].valueChanges.subscribe(value => {
      if (this.VECForm.value['hasSolar'] != value) {
        if (this.initSolar == false) {
          this.formShowSelection.disclaimer = false;
        }

        this.solarCapacityValidation = <FormControl>this.VECForm.get('solarCapacity');
        this.feedInTariffValidation = <FormControl>this.VECForm.get('feedInTariff');
        if (value == '0') {
          this.formShowSelection.disclaimer = true;
          this.feedInTariffValidation.setValidators(null);
          this.solarCapacityValidation.setValidators(null);
        } else if (value == '1') {
          this.feedInTariffValidation.setValidators([Validators.required]);
          if (this.userData.usageProfile == '3' || this.userData.usageProfile == '0') {
            this.solarCapacityValidation.setValidators([Validators.required, Validators.min(1), Validators.max(500)]);
          }
          if (this.userData.feedInTariff != '' && this.userData.feedInTariff != null) {
            this.formShowSelection.disclaimer = true;
          }
        }
        this.feedInTariffValidation.updateValueAndValidity();
        this.solarCapacityValidation.updateValueAndValidity();
        this.initSolar = false;
      }
    });

    this.VECForm.controls['feedInTariff'].valueChanges.subscribe(value => {
      if (this.VECForm.value['feedInTariff'] != value) {
        if (value != '' && value != null) {
          this.formShowSelection.disclaimer = true;
          if (this.userData.usageProfile == '3' || this.userData.usageProfile == '0') {
            window.setTimeout(function() {
              document.getElementById("solarCapacity").focus();
            }, 0);
          }
        }
      }
    });

    this.VECForm.controls['smartMeter'].valueChanges.subscribe(value => {
      if (this.VECForm.value['smartMeter'] != value) {
        this.fileProviderValidation = <FormControl>this.VECForm.get('fileProvider');
        if (value == '1') {
          this.fileProviderValidation.setValidators([Validators.required]);
        } else {
          this.fileProviderValidation.setValidators(null);
          this.showUploadAnimation = false;
          this.userData.showSolarSelection = true;
        }
        this.fileProviderValidation.updateValueAndValidity();
      }
    });
  }

  public setRetailerName() {
    this.userData.retailerName = document.getElementsByClassName("ng-value-label")[0]?.innerHTML;
  }

  public reAssignVariables(data) {
    if (data.energyType != '') {
      this.resetFormSelection();
      this.userData.bill = data.bill;
      this.userData.postcode = data.postcode;
      this.userData.smartMeter = data.smartMeter;
      this.userData.userType = data.userType;
      this.loopBackValues.hasConcession = data.hasConcession;
      this.loopBackValues.hasSolar = data.hasSolar;

      //loopback
      this.userData.loopBack = true;
      if (data.energyType == 'Electricity') {
        this.userData.energyType = 'Gas';
        this.userData.twelveMonths = data.twelveMonths;
        this.userData.usageProfile = '3';
        this.userData.nmi = data.nmi;
        this.userData.fileProvider = data.fileProvider;
        this.userData.file = data.file;
        this.userData.agree = data.agree;
        this.userData.disclaimer = data.disclaimer;
        this.userData.feedInTariff = data.feedInTariff;
        this.userData.distributor = data.distributor;
        this.userData.skipNMI = data.skipNMI;
        this.skipNMIData == data.skipNMI;
        this.formShowSelection.retailer = true;
      } else {
        this.userData.energyType = 'Electricity';
        this.userData.retailer = "";
        this.userData.bill = data.bill;
        this.userData.hasConcession = data.hasConcession;
        if (this.userData.bill == 'no bill') {
          this.getDistributors();

          this.fileProviderValidation = <FormControl>this.VECForm.get('fileProvider');
          this.fileProviderValidation.setValidators(null);
          this.fileProviderValidation.updateValueAndValidity();

          this.NMIValidation = <FormControl>this.VECForm.get('nmi');
          this.NMIValidation.setValidators(null);
          this.NMIValidation.updateValueAndValidity();

          let retailerValidation = <FormControl>this.VECForm.get('retailer');
          retailerValidation.setValidators(null);
          retailerValidation.updateValueAndValidity();

          this.userData.usageProfile = '3';
        }
      }
      this.bannerTitle = 'Add your ' + this.userData.energyType.toLowerCase() + ' details';
      this.userData.showSolarSelection = data.showSolarSelection;
      if (this.userData.usageProfile == '2') {
        this.showUploadAnimation = true;
        this.fileUploadPercentage = 100;
        this.fileUploadStatus = 'success';
        this.userData.fileName = data.fileName;
      }
    } else {
      this.utility.redirectToHome();
    }
    if (this.userData.energyType == 'Gas') {
      this.getDistributors();
    }
  }

  public selectDistributor(id, name, distributionZone) {
    this.formShowSelection.distributorInfo = false;
    let newDistributor = { id : id, name : name, distribution_zone_name : distributionZone };
    this.userData.distributor = newDistributor;
    this.dataService.setDistributor(newDistributor);
    if (this.userData.energyType == 'Electricity') {
      this.formShowSelection.usageProfile = true;
      this.setDefaultUsageProfile();
      if (this.userData.userType == 'Small business') {
        this.formShowSelection.disclaimer = true;
      } else {
        if (this.userData.twelveMonths == '0') {
          this.formShowSelection.concession = true;
          this.initializeLoopBackValues();
        }
      }

      if (this.userData.bill == 'no bill') {
        this.formShowSelection.concession = true;
        this.formShowSelection.solar = true;
      }
    }

  }

  public uploadFileConsumption(files : FileList) {
    this.userData.averageDailyConsumption = 0;
    this.formShowSelection.concession = false;
    this.formShowSelection.solar = false;
    this.formShowSelection.disclaimer = false;
    this.userData.feedInTariff = "";
    this.userData.solarCapacity = "";
    this.userData.hasConcession = "";
    this.userData.disclaimer = "";
    this.fileUploadStatus = "";
    this.fileUploadPercentage = 0;
    this.userData.serverCacheId = localStorage.getItem("serverCacheId");
    this.showUploadAnimation = true;
    this.userData.file = files.item(0).name;
    this.userData.fileName = files.item(0).name;
    this.utility.setErrorMessage('');
    if (this.isIE == true) {
      this.showFilePercentage(0, 50, 100, '');
    } else {
      this.showFilePercentage(0, 50, 25, '');
    }
    this.dataService.uploadFile(files.item(0), this.userData.serverCacheId, this.userData.energyType, this.userData.recaptchaResponse, this.userData.loopBack.toString(), this.userData.fileName).subscribe(
      data => {
        let status = data["status"];
        if (status == 'success') {
          this.userData.serverCacheId = data["serverCacheId"];
          this.userData.hasSolar = (data["hasSolar"] == true) ? '1' : '0';
          if (this.userData.hasSolar == '0') {
            this.removeSolarValidation();
          } else {
            this.requireFeedInTariff();
          }
          this.userData.averageDailyConsumption = data["averageDailyConsumption"];
          this.userData.showSolarSelection = false;
          this.progressLoader('FileConsumption');
          localStorage.setItem("serverCacheId", data["serverCacheId"]);
        } else {
          this.progressLoader('FileConsumption', 'error');
          this.utility.setErrorMessage(data["message"]);
        }
        this.reCaptchaService.initializeRecaptcha();
      },
      error => {
        this.progressLoader('FileConsumption', 'error');
        this.utility.setErrorMessage("An error has occurred while trying to upload the file. Please try again later");
        this.reCaptchaService.initializeRecaptcha();
      }
    );
  }

  private progressLoader(process, status = 'success') {
    if (process == 'AEMO') {
      let loadingSpeed = (this.isIE == true) ? 60 : 25;
      setTimeout(()=> {
        this.showPercentage(51, 100, loadingSpeed, status);
      }, 5000);
    } else {
      if (this.isIE == true) {
        setTimeout(()=> {
          this.showFilePercentage(51, 100, 60, status);
        }, 4000);
      } else {
        this.showFilePercentage(51, 100, 25, status);
      }
    }
  }

  private requireFeedInTariff() {
    this.feedInTariffValidation = <FormControl>this.VECForm.get('feedInTariff');
    this.feedInTariffValidation.setValidators([Validators.required]);
  }

  public setDefaultSolar() {
    this.userData.solarCapacity = '2.9';
  }

  public smartMeterInformation() {
    let modalRef = this.modalService.open(SmartMeterModalComponent, {size : 'md'});
    modalRef.componentInstance.distributor = this.userData.distributor.name;
    modalRef.componentInstance.postcode = this.userData.postcode;
    this.formShowSelection.concession = true;
    this.initializeLoopBackValues();
    this.utility.scrollToNextForm();
    if (this.userData.userType == 'business') {
      this.utility.scrollToNextForm();
      this.userData.hasConcession = '0';
      this.formShowSelection.solar = true;
    }
  }

  public skipNMIProcess() {
    this.userData.skipNMI = true;
    this.NMIStatus = '';
    this.showNMIAnimation = false;
    this.formShowSelection.disclaimer = false;
    this.formShowSelection.solar = false;
    this.userData.hasConcession = '';
    this.userData.hasSolar = '';
    this.NMIValidation = <FormControl>this.VECForm.get('nmi');
    this.NMIValidation.setValidators(null);
    this.NMIValidation.updateValueAndValidity();
    setTimeout(() => {
      document.getElementById("nmi").classList.remove("is-valid");
    }, 0);

    if (this.userData.userType == 'Small business') {
      this.userData.usageProfile = '2';
    } else {
      this.formShowSelection.concession = true;
      this.initializeLoopBackValues();
    }
  }

  public smartMeterGroupFields() {
    this.formShowSelection.concession = false;
    this.formShowSelection.solar = false;
    this.formShowSelection.disclaimer = false;
    this.userData.file = '';
    this.userData.fileName = '';
    this.userData.hasSolar = '';
    this.userData.disclaimer = '';
    this.userData.hasConcession = '';
  }

  public distributorInfoModal() {
    this.spinner.show();
    this.dataService.getDistributorInfo(this.userData.distributor.name).subscribe(
      data => {
        this.spinner.hide();
        let modalRef = this.modalService.open(DistributorInformationModalComponent, {size : 'lg'});
        modalRef.componentInstance.distributorInfo = data["data"];
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public collectionStatementModal() {
    this.modalService.open(CollectionStatementModalComponent, { size : 'lg' });
  }

  public termsModal() {
    this.modalService.open(TermsModalComponent, { size : 'lg' });
  }

  public downloadDataModal() {
    this.spinner.show();
    this.dataService.getDistributorDownloadInfo(this.userData.distributor.name).subscribe(
      data => {
        this.spinner.hide();
        let modalRef = this.modalService.open(DownloadDataModalComponent, {size : 'xl'});
        modalRef.componentInstance.postcode = this.userData.postcode;
        modalRef.componentInstance.distributorInfo = data['content'];
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public concessionModal() {
    this.modalService.open(ConsessionInfoModalComponent, { size : 'lg' });
  }

  public distributorInformationModal() {
    this.modalService.open(PowercorInfoModalComponent, { size : 'lg' });
  }

  private showSolarFormFields() {
    this.formShowSelection.solar = true;
    this.requireFeedInTariff();
    if (this.userData.usageProfile == '3' || this.userData.usageProfile == '0' ||
       (this.userData.usageProfile == '2' && this.userData.smartMeter == '0') ||
       (this.userData.usageProfile == '1' && this.userData.skipNMI == true)) {
      this.solarCapacityValidation = <FormControl>this.VECForm.get('solarCapacity');
      this.solarCapacityValidation.setValidators([Validators.required, Validators.min(1), Validators.max(500)]);
    }
  }

  private removeNMIValidation() {
    this.NMIValidation = <FormControl>this.VECForm.get('nmi');
    this.NMIValidation.setValidators(null);
    this.NMIValidation.updateValueAndValidity();
  }

  public saveSetData() {
    this.spinner.show();
    this.userData['allDataIsSet'] = this.allDataIsSet;
    this.dataService.saveData(this.userData).subscribe(
      data => {
        this.utility.showOffers(this.userData.loopBack, this.userData.energyType);
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public saveData() {
    if (this.userData.skipNMI == true) {
      this.removeNMIValidation();
    }
    this.userData.serverCacheId = localStorage.getItem("serverCacheId");
    if (this.VECForm.status == 'VALID') {
      this.spinner.show();
      this.dataService.setFileIsUploaded(this.userData.fileName != '');

      this.dataService.saveData(this.userData).subscribe(
        data => {
          if (data.status == "success") {
            this.userData.serverCacheId = data["serverCacheId"];
            localStorage.setItem("serverCacheId", data['serverCacheId']);

            //remove offers saved
            let offers = { "offersList" : [] };
            this.dataService.setOffers(offers);

            this.utility.scrollToTop('none');
            if (this.userData.energyType == 'Gas' || this.userData.bill == 'no bill' || this.userData.usageProfile == '3' || this.userData.smartMeter == '0' || this.userData.skipNMI == true) {
              if (this.userData.energyType == 'Gas' && this.userData.userType == 'Small business') {
                this.utility.nextForm('bill-details', this.userData.loopBack);
              } else {
                this.utility.nextForm('energy-questionnaire', this.userData.loopBack);
              }
            } else if (this.userData.twelveMonths == '0') {
              if (this.userData.userType == 'Small business') {
                this.utility.showOffers(this.userData.loopBack, this.userData.energyType);
              } else {
                this.utility.nextForm('energy-questionnaire', this.userData.loopBack);
              }
            } else {
              this.utility.showOffers(this.userData.loopBack, this.userData.energyType);
            }
            this.VECForm = null;
          } else {
            this.spinner.hide();
            this.saveFormError = data.message;
            this.utility.scrollToTop('VECForm');
          }
        },
        error => {
          this.spinner.hide();
          this.saveFormError = 'error';
          this.utility.scrollToTop('VECForm');
          this.reCaptchaService.initializeRecaptcha();
        },
        () => {
          this.reCaptchaService.initializeRecaptcha();
        }
      );
    } else {
      //console.log(this.VECForm); //for debugging
      if (this.userData.solarCapacity == '' && this.userData.hasSolar == '1' && this.userData.usageProfile == '3') {
        this.VECForm.get('solarCapacity').markAsTouched();
        this.VECForm.get('solarCapacity').markAsDirty();
      }

      this.VECForm.markAllAsTouched();
      Object.keys(this.VECForm.controls).forEach(key => {
        this.VECForm.get(key).markAsDirty();
      });
      this.utility.scrollToError();
    }
  }

  public NMIFocus() {
    window.setTimeout(function() {
      document.getElementById("nmi").focus();
    }, 0);
  }

  public startsWithSearchFn(item, term) {
    return term.title.toLowerCase().startsWith(item.toLowerCase());
  }

  public getFormShowSelection(property) {
    if (property == 'concession') {
      return this.formShowSelection.concession;
    }
  }

  private initializeLoopBackValues() {
    for (var property in this.loopBackValues) {
      if (this.loopBackValues[property] != '') {
        this.userData[property] = this.loopBackValues[property];
      }
    }
  }

  public initializeForm() {
    let loopBack = true;
    this.createForm();
    this.resetForm();
    this.updateForms();
    this.modalService.dismissAll(); //close modal dialog if there are any open
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.dataService.resetForms(serverCacheId, loopBack).subscribe(); //reset forms
      this.submitDisabled = false;
      this.userData.serverCacheId = serverCacheId;
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.existing == false) {
            this.utility.sessionRestart();
            this.utility.removeServerCacheId(serverCacheId);
            this.utility.redirectToHome('', true);
          } else {
            let data = results.energyConfigData;
            if (results.energyConfigData.energyType == 'Electricity' && results.energyConfigData.bill == 'no bill') {
              if (results.energyProfileData.hasOwnProperty('hhSize')) {
                this.allDataIsSet = true;
              }
            }
            if (data.hasOwnProperty('energyType') == true) {
              this.reAssignVariables(data);
              this.getRetailers(true);
            } else {
              //localStorage.removeItem("serverCacheId");
              this.utility.redirectToHome();
            }
          }
        },
        error => {
          this.spinner.hide();
        }
      );
    } else {
      this.utility.redirectToHome('', true);
    }
  }

  public clearSolarSelection() {
    this.userData.showSolarSelection = true;
  }

  private removeSolarValidation() {
    this.feedInTariffValidation = <FormControl>this.VECForm.get('feedInTariff');
    this.solarCapacityValidation = <FormControl>this.VECForm.get('solarCapacity');
    this.solarCapacityValidation.setValidators(null);
    this.feedInTariffValidation.setValidators(null);
    this.solarCapacityValidation.updateValueAndValidity();
    this.feedInTariffValidation.updateValueAndValidity();
  }

  public setDefaultUsageProfile() {
    if (this.userData.energyType == 'Gas' || this.userData.twelveMonths == '0' || this.userData.bill == 'no bill') {
      this.userData.usageProfile = '3';
    } else {
      if (this.dataService.liveAEMO == true) {
        this.userData.usageProfile = '1';
        this.NMIFocus();
      }
    }
  }

}
