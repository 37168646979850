<div class="alert-message alert-message-warning">
    <div class="row" *ngIf="utility.getSelectedEnergyType() != 'Solar'">
        <div class="col-lg-9 header-banner-title" [ngClass]="{'col-lg-12': router.url == '/offline' ||  hideTips}">
            <br >
            <h1 class="headerTitle" dir="{{ textDirection }}" [ngClass]="{ 'headerTitleVietnamese' : router.url.indexOf('/languages/vietnamese') != -1, 'text-right' : textDirection == 'rtl' }">
                {{ title }}
            </h1>
            <br />
            <h4 dir="{{ textDirection }}" *ngIf="subTitle != ''" [ngClass]="textDirection == 'rtl' ? 'text-right' : 'text-left'">{{ subTitle }}</h4>
            <p dir="{{ textDirection }}" *ngIf="details != ''" [innerHTML]="details" [ngClass]="textDirection == 'rtl' ? 'text-right' : 'text-left'"></p>
        </div>

        <div class="header-banner" [ngClass]="hideTips ? 'hidden' : 'col-lg-3'" *ngIf="router.url != '/offline' && hideTips != true">
            <br /><br />
            <div class="header-banner-link" *ngIf="router.url != '/energy-quiz' ">
                <a
                    alt="Frequently Asked Questions"
                    title="Frequently Asked Questions"
                    aria-label="Frequently Asked Questions"
                    (click)="faqModal()"
                    class="quiz-link non-focus">
                    Frequently Asked Questions
                </a>
            </div>
            <div class="in-your-language" *ngIf="router.url != '/languages' && router.url != '/'">
                <a (click)="faqModal()" alt="in your language" target="_blank" class="in-your-language">
                    <img src="/assets/images/button_in_your_language.png" alt="In your in-your-language">
                </a>
            </div>

            <div class="in-your-language" *ngIf="router.url == '/'">
                <a href="/languages" alt="in your language" target="_blank" class="in-your-language">
                    <img src="/assets/images/button_in_your_language.png" alt="In your in-your-language">
                </a>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="utility.getSelectedEnergyType() == 'Solar'">
        <div class="col-lg-9 header-banner-title">
            <br >
            <h1 class="headerTitle text-left">
                Solar Savings Calculator
            </h1>
            <br />
            <h4 class="text-left">
                Welcome to the Victorian Energy Compare solar savings calculator.
                Use this calculator to see if you could save by installing a PV Solar System. For more information
                <a
                    href='{{ appURL }}/solar-savings'
                    alt="Solar Journey and Facts"
                    title="Solar Journey and Facts"
                    aria-label="Solar Journey and Facts"
                    target="_blank"
                    class="banner-link non-focus">
                    click here.
                </a>
            </h4>
            <br />
            <p class="text-left">
                <span class='strong'>
                    If you are looking to compare electricity and gas prices in your area, visit
                    <a class='banner-link non-focus' (click)="utility.resetPage()">Victorian Energy Compare</a> today.
                </span>
            </p>
        </div>

        <div class="header-banner col-lg-3">
            <img
                src="assets/images/solar-banner.png"
                id="banner">
            <br>
            <div class="header-banner-link">
                <a
                    href='{{ appURL }}/solar-savings'
                    alt="Solar Journey and Facts"
                    title="Solar Journey and Facts"
                    aria-label="Solar Journey and Facts"
                    class="banner-link non-focus">
                    Solar Journey and Facts
                </a>
            </div>
        </div>
    </div>
</div>