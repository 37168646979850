import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {
  private token = new Subject<string>();
  token$ = this.token.asObservable();

  public recaptchaEnabled = environment.recaptchaEnabled;
  public recaptchaToken : any;
  public googleSiteKey = environment.googleSiteKey;

  constructor(
    private reCaptchaV3Service : ReCaptchaV3Service
    ) {}


  setToken(token: string) {
    this.token.next(token);
  }

  public initializeRecaptcha() : void {
    if (this.recaptchaEnabled == true) {
      this.reCaptchaV3Service.execute(this.googleSiteKey, 'homepage', (token) => {
          this.recaptchaToken = token;
          this.setToken(token);
        },
        {
          useGlobalDomain: false
        }
      );
    }
  }


}
