<div class="container hide-on-ie-print">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="col-lg-10">
        <h2 class="subTitle">Your energy profile</h2>
        <form method="post" id="PSBForm" [formGroup]="PSBForm" onsubmit="return false" (keydown.enter)="$event.preventDefault()">
            <div class="row form-group page-divider-up">
                <br />
                <div class="row col-md-12">
                    <div class="row col-md-5 no-margin no-padding">
                        <label class="h3 small-pad-left">
                            What's your average energy consumption level?
                            <ng-template #usageinfo>
                                <p>Please select the option closest to your actual daily electricity consumption.</p>
                                <br />
                                <p>Your average daily usage is the amount of electricity your household uses on a typical day. </p>
                                <br />
                                <p>This is measured in kilo-watt hours (kWh) and can be found on your electricity bill. Please contact your retailer if you are unsure.</p>
                            </ng-template>
                            <i class="material-icons" data-toggle="tooltip" 
                            [triggers]="utility.toolTipForMobile()" 
                            [ngbTooltip]="usageinfo"
                            aria-label="Please select the option closest to your actual daily electricity consumption. Your average daily usage is the amount of electricity your household uses on a typical day. This is measured in kilo-watt hours (kWh) and can be found on your electricity bill. Please contact your retailer if you are unsure.">info</i>
                        </label>
                    </div>
                    <div class="col-md-7 no-margin no-padding">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="radio-inline">
                                    <input type="radio" id="small" value="low" formControlName="usage" [(ngModel)]="PSBData.usage">
                                    <label for="small" class="custom-btn-large custom-btn-large-text col-md-12" tabindex="1" (keydown)="selectButton($event, 'small')">
                                        Low<br />
                                        <i class="material-icons button-icons" aria-hidden="true">person</i><br />
                                        1 person <br />
                                        ~7.2 kWh/day
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class=" radio-inline">
                                    <input type="radio" id="medium" value="medium" formControlName="usage" [(ngModel)]="PSBData.usage" />
                                    <label for="medium" class="custom-btn-large custom-btn-large-text col-md-12" tabindex="2" (keydown)="selectButton($event, 'medium')">
                                        Medium<br />
                                        <i class="material-icons button-icons medium" aria-hidden="true">group</i><br />
                                        2-3 people<br />
                                        ~11.8 kWh/day
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class=" radio-inline">
                                    <input type="radio" id="large" value="large" formControlName="usage" [(ngModel)]="PSBData.usage" />
                                    <label for="large" tabindex="3" class="custom-btn-large custom-btn-large-text col-md-12" (keydown)="selectButton($event, 'large')">
                                        High<br />
                                        <i class="material-icons button-icons medium" aria-hidden="true">groups</i><br />
                                        4+ people<br />
                                        ~15.5 kWh/day
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>
<br /><br />

