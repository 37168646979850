<div id="container">
  <app-banner
    [title]="bannerTitle"
    [subTitle]="bannerSubTitle"
    [details]="bannerDetails"
  >
  </app-banner>

  <div class="row">
    <div class="col-md-10">
      <div class="col-md-12">
        <p>
          We want to continue to help you save money on your energy bills, so
          we've launched <strong>VEC Energy Updates</strong>.
        </p>
        <br />
        <p>
          Our quarterly newsletter aims to help you navigate the energy market
          by providing regular reminders to compare offers on the Victorian
          Energy Compare website as well as timely information on energy
          affordability issues impacting Victorian households.
        </p>
        <br />
        <p>
          <strong>VEC Energy Updates</strong> will help you save money on your
          energy bills by:
        </p>
        <ul>
          <li>
            Providing advice on how to find the best electricity, gas and solar
            offers for your household
          </li>
          <li>Showing you easy ways to save energy at home</li>
          <li>
            Keeping you up to date on key changes in the energy market, new
            government programs and your consumer rights
          </li>
        </ul>
        <p>
          For further information or to subscribe email
          <a href="mailto:info.vec@deeca.vic.gov.au?subject=VEC Energy Updates"
            >info.vec@deeca.vic.gov.au</a
          >.
        </p>
        <br />
        <p><strong>Statement of terms:</strong></p>
        <br>
        <p>
          <a href="privacy"
            ><strong
              >We will only use your contact details to send you emails, and
              your information will be handled in accordance with the Privacy
              and Data Protection Act 2014 (Vic). Click here for more
              information.</strong
            ></a
          >
        </p>
      </div>
    </div>
  </div>
</div>
