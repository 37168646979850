import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from '../../environments/environment'; //config file

@Component({
  selector: 'app-restart',
  templateUrl: './restart.component.html',
  styleUrls: ['./restart.component.css']
})
export class RestartComponent implements OnInit {
  public appURL = environment.appURL;

  constructor(public router : Router) {
    localStorage.removeItem("serverCacheId");
    window.location.replace(this.appURL);
  }

  ngOnInit() {
  }

}
