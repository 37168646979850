import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';

@Component({
  selector: 'app-sorting-modal',
  templateUrl: './sorting-modal.component.html',
  styleUrls: ['./sorting-modal.component.css']
})
export class SortingModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public dataService : DataService) { }

  ngOnInit(): void {
    localStorage.setItem("sorting-showTopOffer", this.options.showTopOffer.toString());
    localStorage.setItem("sorting-sortByConditionalPrice", this.options.sortByConditionalPrice.toString());
  }

  public options = {
    'showTopOffer' : true,
    'sortByConditionalPrice' : true
  }

  public changeSort(value) {
    this.options.sortByConditionalPrice = value;
    localStorage.setItem("sorting-sortByConditionalPrice", this.options.sortByConditionalPrice.toString());
  }

  public changeOffers(value) {
    this.options.showTopOffer = value;
    localStorage.setItem("sorting-showTopOffer", this.options.showTopOffer.toString());
  }

}
