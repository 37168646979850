import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from '../data.service';
import { environment } from '../../environments/environment'; //config file
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Utility } from '../utility';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dual-rate-details-modal',
  templateUrl: './dual-rate-details-modal.component.html',
  styleUrls: ['./dual-rate-details-modal.component.css']
})
export class DualRateDetailsModalComponent implements OnInit {
  private oneDay = 86400000;
  public appURL = environment.appURL;
  //Peak / flat rate
  public billRateMin = 0;
  public billRateOffPeakMin = 0;
  public billRateMax = 0;
  //Feed in tariff rates // Solar
  public fitRateMin = 0;
  public fitRateMax = 0;
  // Control load charge
  public controllLoadMin = 0;
  public controllLoadMax = 0;
  // supply charge electricity
  public dailyChargeUnitMin = 0;
  public dailyChargeUnitMax = 0;
  // supply charge gas
  public dailyGasChargeUnitMin = 0;
  public dailyGasChargeUnitMax = 0;

  public peakRateMin = 0;
  public peakRateMax = 0;

  public solarFitRateMin = 0;
  public solarFitRateMax = 0;

  public controlledLoadRateMin = 0;
  public controlledLoadRateMax = 0;

  public offPeakRateMin = 0;
  public offPeakRateMax = 0;

  public shoulderRateMin = 0;
  public shoulderRateMax = 0;

  public hoveredDate: NgbDate | null = null;
  public gasHoveredDate : NgbDate | null = null;
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public gasFromDate: NgbDate | null;
  public gasToDate: NgbDate | null;
  public isMobile = false;
  public energyConfigForm = {};
  public supplyDays = 0;
  public gasSupplyDays = 0;

  public PDFUploadError = '';
  public manualEntryError = '';
  public billRates = {
    "offerType" : '',
    "retailerImage" : '',
    "retailerName" : '',
    "gasRetailerImage" : '',
    "gasRetailerName" : '',
    "totalConditional" : 0,
    "totalUnconditional" : 0,
    "totalGasConditional" : 0,
    "totalGasUnconditional" : 0,
    "components" : {
      "solar" : 0,
      "controlledLoad" : 0,
    }
  };

  public retailerDetails : any = {
    "retailerSettings" : {
      'retailer_bill_daily_charge_cost_unit_min_res' : 0,
      'retailer_bill_daily_charge_cost_unit_max_res' : 0,
      'retailer_bill_daily_charge_cost_unit_min_smb' : 0,
      'retailer_bill_daily_charge_cost_unit_max_smb' : 0,
      'retailer_bill_flat_rate_cost_unit' : '',
      'retailer_bill_peak_rate_cost_unit' : '',
      'retailer_bill_offpeak_rate_cost_unit' : '',
      'retailer_bill_shoulder_rate_cost_unit' : '',
      'retailer_bill_controlled_load_rate_cost_unit' : '',

    }
  };

  public gasRetailerDetails : any = {
    "retailerSettings" : {
      'retailer_bill_daily_charge_cost_unit_min_res' : 0,
      'retailer_bill_daily_charge_cost_unit_max_res' : 0,
      'retailer_bill_daily_charge_cost_unit_min_smb' : 0,
      'retailer_bill_daily_charge_cost_unit_max_smb' : 0,
      'retailer_bill_flat_rate_cost_unit' : '',
    }
  };

  public energyType;
  public userType;
  public loopBack = false;
  public controlledLoad = false;

  public electricityBillStartDate = '';
  public electricityBillEndDate = '';
  public gasBillStartDate = '';
  public gasBillEndDate = '';
  public billRatesForm = {
    loopBack : false,
    energyType : "",
    hasSolar : false,
    feedInTariff: "",
    billStartDate: "",
    billEndDate: "",
    retailer: "",
    retailerName: "",
    totalCharge: "",
    offPeak: "",
    offPeakRate: "",
    controlledLoadRate: "",
    peakRate: "",
    solarFitRate: "",
    shoulder: "",
    shoulderRate: "",
    supplyCharge: "",
    discount: "",
    discountAmount: "",
    discountType: "",
    serverCacheId: ""
  };

  public loopBackBillRatesForm = {
    loopBack : true,
    energyType : "",
    hasSolar : false,
    feedInTariff: "",
    billStartDate: "",
    billEndDate: "",
    retailer: "",
    retailerName: "",
    totalCharge: "",
    offPeak: "",
    offPeakRate: "",
    controlledLoadRate: "",
    peakRate: "",
    solarFitRate: "",
    shoulder: "",
    shoulderRate: "",
    supplyCharge: "",
    discount: "",
    discountAmount: "",
    discountType: "",
    serverCacheId: ""
  };

  private subscription : Subscription;
  public userData = {
    loopBack : false,
    energyType : "",
    hasSolar : false,
    feedInTariff: "",
    billStartDate: "",
    billEndDate: "",
    retailer: "",
    retailerName: "",
    offPeak: "",
    offPeakRate: "",
    controlledLoadRate: "",
    peakRate: "",
    solarFitRate: "",
    shoulder: "",
    shoulderRate: "",
    supplyCharge: "",
    discount: "",
    discountAmount: "",
    discountType: "",
    rateUnit : '$',
    supplyRateUnit : '$',
    //gas
    gasBillStartDate: "",
    gasBillEndDate: "",
    gasRetailer: "",
    gasRetailerName: "",
    totalCharge: "",
    gasSupplyCharge: "",
    gasDiscount: "",
    gasDiscountAmount: "",
    gasDiscountType: "",
    gasRateUnit : '$',
    supplyGasRateUnit : '$',
    serverCacheId: ""
  }
  public fileName = '';
  public rateForm : FormGroup;
  public currentPage = 'main';
  public showTermsAndCondition = false;
  public minDate = {};
  public maxDate = {};
  public currentDate;

  constructor(private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    public router : Router,
    private calendar: NgbCalendar,
    public utility : Utility,
    public formatter: NgbDateParserFormatter,
    public dataService : DataService,
    public spinner: NgxSpinnerService,
    public formBuilder : FormBuilder,
    public activeModal: NgbActiveModal,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
    }

  ngOnInit() : void {
    this.currentDate = new Date();
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 2,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };


    this.isMobile = this.deviceService.isMobile();
    var serverCacheId = localStorage.getItem("serverCacheId");
    this.userData.serverCacheId = serverCacheId;
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.spinner.show();
      this.createForm();
      //this.userData.serverCacheId = serverCacheId;
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.energyConfigData.hasOwnProperty('energyType') == false) {
            this.spinner.hide();
            window.location.reload();
          }
          this.controlledLoad = results.hasControlledLoad;
          this.userData.hasSolar = results.hasSolar;
          this.userData.feedInTariff = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.feedInTariff : results.loopBackData.energyConfigData.feedInTariff;
          this.userData.retailer = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.retailer : results.loopBackData.energyConfigData.retailer;
          this.userData.retailerName = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.retailerName : results.loopBackData.energyConfigData.retailerName;
          this.userData.gasRetailer = (results.energyConfigData.energyType == 'Gas') ? results.energyConfigData.retailer : results.loopBackData.energyConfigData.retailer;
          this.userData.gasRetailerName = (results.energyConfigData.energyType == 'Gas') ? results.energyConfigData.retailerName : results.loopBackData.energyConfigData.retailerName;
          this.userType = results.energyConfigData.userType;
          //get rates for electricity
          if (results.hasOwnProperty('billRatesForm') == true && results.loopBackData.hasOwnProperty('billRatesForm') == true) {
            let billRatesData;
            if (results.billRatesForm.energyType == 'Electricity') {
              billRatesData = results.billRatesForm;
            }
            if (results.loopBackData.billRatesForm.energyType == 'Electricity') {
              billRatesData = results.loopBackData.billRatesForm;
            }

            if (billRatesData != undefined) {
              this.userData.hasSolar = billRatesData.hasSolar;
              this.userData.feedInTariff = billRatesData.feedInTariff;
              this.userData.billStartDate = billRatesData.billStartDate;
              this.userData.billEndDate = billRatesData.billEndDate;
              this.userData.offPeak = billRatesData.offPeak;
              this.userData.offPeakRate = billRatesData.offPeakRate;
              this.userData.controlledLoadRate = billRatesData.controlledLoadRate;
              this.userData.peakRate = billRatesData.peakRate;
              this.userData.solarFitRate = billRatesData.solarFitRate;
              this.userData.shoulder = billRatesData.shoulder;
              this.userData.shoulderRate = billRatesData.shoulderRate;
              this.userData.supplyCharge = billRatesData.supplyCharge;
              this.userData.discount = billRatesData.discount;
              this.userData.discountAmount = billRatesData.discountAmount;
              this.userData.discountType = billRatesData.discountType;
              this.getSupplyDays();
            }
          }

          //get rates for gas
          if (results.hasOwnProperty('billRatesForm') == true && results.loopBackData.hasOwnProperty('billRatesForm') == true) {
            let gasBillRatesData;
            if (results.billRatesForm.energyType == 'Gas') {
              gasBillRatesData = results.billRatesForm;
            }
            if (results.loopBackData.billRatesForm.energyType == 'Gas') {
              gasBillRatesData = results.loopBackData.billRatesForm;
            }

            if (gasBillRatesData != undefined) {
              this.userData.gasBillStartDate = gasBillRatesData.billStartDate;
              this.userData.gasBillEndDate = gasBillRatesData.billEndDate;
              this.userData.totalCharge = gasBillRatesData.totalCharge;
              this.userData.gasSupplyCharge = gasBillRatesData.supplyCharge;
              this.userData.gasDiscount = gasBillRatesData.discount;
              this.userData.gasDiscountAmount = gasBillRatesData.discountAmount;
              this.userData.gasDiscountType = gasBillRatesData.discountType;
              this.getGasSupplyDays();
            }
          } else if(results.loopBackData.billDetailsData.billForms.length > 1) {
            let loopBackBillForms = results.loopBackData.billDetailsData.billForms;
              this.userData.gasBillStartDate = (loopBackBillForms[loopBackBillForms.length - 1].gasBillStartDate == undefined) ? '' : loopBackBillForms[loopBackBillForms.length - 1].gasBillStartDate;
              this.userData.gasBillEndDate = (loopBackBillForms[loopBackBillForms.length - 1].gasBillEndDate == undefined) ? '' : loopBackBillForms[loopBackBillForms.length - 1].gasBillEndDate;
            } else {
              this.userData.gasBillStartDate = (results.loopBackData.billDetailsData.gasBillStartDate == undefined) ? '' : results.loopBackData.billDetailsData.gasBillStartDate;
              this.userData.gasBillEndDate = (results.loopBackData.billDetailsData.gasBillEndDate == undefined) ? '' : results.loopBackData.billDetailsData.gasBillEndDate;
            }

          //this.getSupplyDays();
          //get retailer electricity settings
          this.dataService.getRetailerSettings(this.userData.retailer).subscribe(
            data => {
              this.retailerDetails = data;
              if (this.userType == 'Residential') {
                this.dailyChargeUnitMin = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_res : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_res * 100;
                this.dailyChargeUnitMax = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_res : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_res * 100;
              } else {
                this.dailyChargeUnitMin = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_smb : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_min_smb * 100;
                this.dailyChargeUnitMax = (this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_smb : this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit_max_smb * 100;
              }

              this.controllLoadMin = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_min : this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_min * 100;
              this.controllLoadMax = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_max : this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit_max * 100;

              this.billRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_min : this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_min * 100;
              this.billRateOffPeakMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_rate_off_peak_cost_unit_min : this.retailerDetails.retailerSettings.retailer_bill_rate_off_peak_cost_unit_min * 100;
              this.billRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_max : this.retailerDetails.retailerSettings.retailer_bill_rate_cost_unit_max * 100;

              this.fitRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_min : this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_min * 100;
              this.fitRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$') ? this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_max : this.retailerDetails.retailerSettings.retailer_bill_fit_rate_per_unit_max * 100;

              this.peakRateMin = (this.retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit == 'cents') ? 1 : 0.01;
              this.peakRateMax = (this.retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit == 'cents') ? 100 : 1;

              this.solarFitRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 1 : 0.01;
              this.solarFitRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 100 : 1;

              this.controlledLoadRateMin = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == 'cents') ? 1 : 0.01;
              this.controlledLoadRateMax = (this.retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == 'cents') ? 100 : 1;

              this.userData.rateUnit = this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit;
              this.userData.supplyRateUnit = this.retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit;

              var peakRateValidation = <FormControl>this.rateForm.get('peakRate');
              peakRateValidation.setValidators(
                [
                  Validators.required,
                  Validators.min(this.peakRateMin),
                  Validators.max(this.peakRateMax),
                  this.checkIfNumber.bind(this)
                ]
              );
              peakRateValidation.updateValueAndValidity();

            },
            error => {
              this.spinner.hide();
            }
          );

          //get retailer gas settings
          this.dataService.getRetailerSettings(this.userData.gasRetailer).subscribe(
            data => {
              this.gasRetailerDetails = data;
              if (this.userType == 'Residential') {
                this.dailyGasChargeUnitMin = (this.gasRetailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_res : this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_res * 100;
                this.dailyGasChargeUnitMax = (this.gasRetailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_res : this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_res * 100;
              } else {
                this.dailyGasChargeUnitMin = (this.gasRetailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_smb : this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_min_smb * 100;
                this.dailyGasChargeUnitMax = (this.gasRetailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$') ? this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_smb : this.gasRetailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit_max_smb * 100;
              }

              this.userData.gasRateUnit = this.gasRetailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit;
              this.userData.supplyGasRateUnit = this.gasRetailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit;
              setTimeout(() => {
                this.spinner.hide();
              }, 1000);
            },
            error => {
              this.spinner.hide();
            }
          );
        },
        error => {
          this.spinner.hide();
        }
      );

    } else {
      window.location.reload();
    }
  }

  public selectButton(event, name) {
    if (event.keyCode == 32) { //spacebar
      if (name == "offPeakYes") {
        this.userData.offPeak = '1';
      } else if (name == "offPeakNo") {
        this.userData.offPeak = '0';
      } else if (name == "shoulderRateYes") {
        this.userData.shoulder = '1';
      } else if (name == "shoulderRateNo") {
        this.userData.shoulder = '0';
      } else if (name == "elecDiscountYes") {
        this.userData.discount = '1';
      } else if (name == "elecDiscountNo") {
        this.userData.discount = '0';
      } else if (name == "usage") {
        this.userData.discountType = 'usage';
      } else if (name == "bill") {
        this.userData.discountType = 'bill';
      } else if (name == "gasElecDiscountYes") {
        this.userData.gasDiscount = '1';
      } else if (name == "gasElecDiscountNo") {
        this.userData.gasDiscount = '0';
      } else if (name == "gasUsage") {
        this.userData.gasDiscountType = 'usage';
      } else if (name == "gasBill") {
        this.userData.gasDiscountType = 'bill';
      }
    }

    if (event.keyCode == 13) { //enter
      if (name == "textInput") {
        this.submitRates();
      }
    }
  }

  public validateDate(obj, userData) {
    var dateFrom = new Date(userData.billStartDate).getTime() / 1000;
    var dateTo =  new Date(userData.billEndDate).getTime() / 1000;
    if (dateTo <= dateFrom) {
      return { 'pattern' : true };
    } else {
      return null;
    }
  }

  public gasFormat(date): string {
    return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
  }

  public format(date): string {
    return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
  }

  public isDisabled(date: NgbDate) {
    let unixDateValue = new Date(date.year, date.month, date.day).getTime() / 1000;
    let dateStartLimit = new Date(this.currentDate.getFullYear() - 2, this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
    let dateEndLimit = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate()).getTime() / 1000;
    if (unixDateValue > dateEndLimit || unixDateValue < dateStartLimit) {
      return true;
    } else {
      return false;
    }
  }

  public onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.userData.billStartDate = this.format(date);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.userData.billEndDate = this.format(date);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.userData.billStartDate = this.format(date);
      this.userData.billEndDate = '';
    }

    if (this.fromDate != null && this.toDate != null) {
      let date1 : any = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      let date2 : any = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      this.supplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.supplyDays = (this.supplyDays < 0) ? 0 : this.supplyDays + 1;
      datepicker.close();
    }
  }

  public onGasDateSelection(date: NgbDate, datepicker: any) {
    if (!this.gasFromDate && !this.gasToDate) {
      this.gasFromDate = date;
      this.userData.gasBillStartDate = this.format(date);
    } else if (this.gasFromDate && !this.gasToDate && date && date.after(this.gasFromDate)) {
      this.gasToDate = date;
      this.userData.gasBillEndDate = this.format(date);
    } else {
      this.gasToDate = null;
      this.gasFromDate = date;
      this.userData.gasBillStartDate = this.format(date);
      this.userData.gasBillEndDate = '';
    }
    if (this.gasFromDate != null && this.gasToDate != null) {
      let date1 : any = new Date(this.gasFromDate.year, this.gasFromDate.month - 1, this.gasFromDate.day);
      let date2 : any = new Date(this.gasToDate.year, this.gasToDate.month - 1, this.gasToDate.day);
      this.gasSupplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.gasSupplyDays = (this.gasSupplyDays < 0) ? 0 : this.gasSupplyDays + 1;
      datepicker.close();
    }
  }

  public getSupplyDays() {
    if (this.userData.billStartDate != '' && this.userData.billEndDate != '') {
      let billStartDate = [];
      let billEndDate = [];
      billStartDate = this.userData.billStartDate.split("/");
      billEndDate = this.userData.billEndDate.split("/");
      let date1 : any = new Date(billStartDate[2], billStartDate[1] - 1, billStartDate[0]);
      let date2 : any = new Date(billEndDate[2], billEndDate[1] - 1, billEndDate[0]);
      this.supplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.supplyDays = (this.supplyDays < 0) ? 0 : this.supplyDays + 1;
    } else {
      this.supplyDays = 0;
    }
  }

  public getGasSupplyDays() {
    if (this.userData.gasBillStartDate != '' && this.userData.gasBillEndDate != '') {
      let gasBillStartDate = [];
      let gasBillEndDate = [];
      gasBillStartDate = this.userData.gasBillStartDate.split("/");
      gasBillEndDate = this.userData.gasBillEndDate.split("/");
      let date1 : any = new Date(gasBillStartDate[2], gasBillStartDate[1] - 1, gasBillStartDate[0]);
      let date2 : any = new Date(gasBillEndDate[2], gasBillEndDate[1] - 1, gasBillEndDate[0]);
      this.gasSupplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.gasSupplyDays = (this.gasSupplyDays < 0) ? 0 : this.gasSupplyDays + 1;
    } else {
      this.gasSupplyDays = 0;
    }
  }

  public gasIsHovered(date: NgbDate) {
    return this.gasFromDate && !this.gasToDate && this.gasHoveredDate && date.after(this.gasFromDate) && date.before(this.gasHoveredDate);
  }

  public gasIsInside(date: NgbDate) {
    return this.gasToDate && date.after(this.gasFromDate) && date.before(this.gasToDate);
  }

  public gasIsRange(date: NgbDate) {
    return date.equals(this.gasFromDate) || (this.gasToDate && date.equals(this.gasToDate)) || this.gasIsInside(date) || this.gasIsHovered(date);
  }

  public validateGasInput(currentValue, input : string) : NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public validateInput(currentValue : NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public toggleTermsAndCondition() {
    this.showTermsAndCondition = !this.showTermsAndCondition;
  }

  public createForm() {
    this.rateForm = this.formBuilder.group({
      billStartDate: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$'),
      ]),
      billEndDate: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$'),
      ]),
      retailer: new FormControl(''),
      solarFitRate: new FormControl(''),
      offPeak: new FormControl('', [Validators.required, Validators.pattern('^[01]+$')]),
      offPeakRate: new FormControl(''),
      peakRate: new FormControl(''),
      controlledLoadRate: new FormControl(''),
      supplyCharge: new FormControl('', [Validators.required, this.checkIfNumber.bind(this)]),
      shoulder: new FormControl(''),
      shoulderRate: new FormControl(''),
      discount: new FormControl('', [Validators.required, Validators.pattern('^[01]+$')]), //allow 1 or 0 only
      discountAmount: new FormControl(''),
      discountType: new FormControl(''),
      //gasForm
      gasBillStartDate: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$'),
      ]),
      gasBillEndDate: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$'),
      ]),
      gasRetailer: new FormControl(''),
      totalCharge: new FormControl('', [Validators.required, this.checkIfNumber.bind(this)]),
      gasRetailerName: new FormControl(''),
      gasSupplyCharge: new FormControl('', [Validators.required, this.checkIfNumber.bind(this)]),
      gasDiscount: new FormControl('', [Validators.required, Validators.pattern('^[01]+$')]), //allow 1 or 0 only
      gasDiscountAmount: new FormControl(''),
      gasDiscountType: new FormControl(''),
    });

    /*
    * custom validation for fields
    * validation rules based on other fields
    * if a field gets selected then some other field will have validation
    */
    var offPeakValidation = <FormControl>this.rateForm.get('offPeak');
    var offPeakRateValidation = <FormControl>this.rateForm.get('offPeakRate');
    this.subscription = offPeakValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        this.offPeakRateMin = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 1 : 0;
        this.offPeakRateMax = (this.retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents') ? 100 : 1;

        shoulderValidation.setValidators([Validators.required]);
        offPeakRateValidation.setValidators(
          [
            Validators.required,
            Validators.min(this.offPeakRateMin),
            Validators.max(this.offPeakRateMax),
            this.checkIfNumber.bind(this)
          ]
        );
      } else {
        shoulderValidation.setValidators(null);
        offPeakRateValidation.setValidators(null);
        //this.userData.offPeakRate = '';
        offPeakRateValidation.updateValueAndValidity();
        shoulderValidation.updateValueAndValidity();
      }
    });
    var discountValidation = <FormControl>this.rateForm.get('discount');
    var discountAmountValidation = <FormControl>this.rateForm.get('discountAmount');
    var discountTypeValidation = <FormControl>this.rateForm.get('discountType');
    this.subscription = discountValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        discountTypeValidation.setValidators([Validators.required]);
        discountAmountValidation.setValidators(
          [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            this.checkIfNumber.bind(this)
          ]
        );
        discountAmountValidation.updateValueAndValidity();
        discountTypeValidation.updateValueAndValidity();
      } else {
        discountAmountValidation.setValidators(null);
        discountTypeValidation.setValidators(null);
        this.userData.discountAmount = '';
        discountAmountValidation.updateValueAndValidity();
        discountTypeValidation.updateValueAndValidity();
      }
    });
    var shoulderValidation = <FormControl>this.rateForm.get('shoulder');
    var shoulderRateValidation = <FormControl>this.rateForm.get('shoulderRate');
    this.subscription = shoulderValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        this.shoulderRateMin = (this.retailerDetails.retailerSettings.retailer_bill_shoulder_rate_cost_unit == 'cents') ? 1 : 0.01;
        this.shoulderRateMax = (this.retailerDetails.retailerSettings.retailer_bill_shoulder_rate_cost_unit == 'cents') ? 100 : 1;

        shoulderRateValidation.setValidators(
          [
            Validators.required,
            Validators.min(this.shoulderRateMin),
            Validators.max(this.shoulderRateMax),
            this.checkIfNumber.bind(this)
          ]
        );
      } else {
        shoulderRateValidation.setValidators(null);
        //this.userData.shoulderRate = '';
        shoulderRateValidation.updateValueAndValidity();
      }
    });

    if (this.userData.hasSolar == true) {
      var solarFitRateValidation = <FormControl>this.rateForm.get('solarFitRate');
      solarFitRateValidation.setValidators(
        [
          Validators.required,
          Validators.min(this.solarFitRateMin),
          Validators.max(this.solarFitRateMax),
          this.checkIfNumber.bind(this)
        ]
      );
      solarFitRateValidation.updateValueAndValidity();
    }

    if (this.controlledLoad == true) {
      var controlledLoadRateValidation = <FormControl>this.rateForm.get('controlledLoadRate');
      controlledLoadRateValidation.setValidators(
        [
          Validators.required,
          Validators.min(this.controlledLoadRateMin),
          Validators.max(this.controlledLoadRateMax),
          this.checkIfNumber.bind(this)
        ]
      );
      controlledLoadRateValidation.updateValueAndValidity();
    }

    var gasDiscountValidation = <FormControl>this.rateForm.get('gasDiscount');
    var gasDiscountAmountValidation = <FormControl>this.rateForm.get('gasDiscountAmount');
    var gasDiscountTypeValidation = <FormControl>this.rateForm.get('gasDiscountType');
    this.subscription = gasDiscountValidation.valueChanges.subscribe(value => {
      if (value == 1) {
        gasDiscountTypeValidation.setValidators([Validators.required]);
        gasDiscountAmountValidation.setValidators(
          [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            this.checkIfNumber.bind(this)
          ]
        );
        gasDiscountAmountValidation.updateValueAndValidity();
        gasDiscountTypeValidation.updateValueAndValidity();
      } else {
        gasDiscountAmountValidation.setValidators(null);
        gasDiscountTypeValidation.setValidators(null);
        this.userData.gasDiscountAmount = '';
        gasDiscountAmountValidation.updateValueAndValidity();
        gasDiscountTypeValidation.updateValueAndValidity();
      }
    });

  }

  public submitRates() {
    if (this.rateForm.status == 'VALID' && this.totalChargeFinalValidation() == true) {
      this.spinner.show();
      this.dataService.getDualBillRates(this.userData, this.supplyDays, this.gasSupplyDays).subscribe(
        data => {
          let status = data["status"];
          this.spinner.hide();
          if (status == "success") {
            this.billRates = data["results"];
            this.activeModal.close(this.billRates);
          } else {
            this.manualEntryError = data["message"];
            setTimeout(()=> {
              document.getElementById("startDate").focus();
            }, 0);
            if (status == 'redirect') {
              window.location.reload();
            }
          }
        },
        error => {
          this.spinner.hide();
        }
      );
    }
  }

  public clearOffPeakRate() {
    this.userData.offPeakRate = '';
  }

  public clearShoulderRate() {
    this.userData.shoulderRate = '';
  }

  private totalChargeFinalValidation() {
    if (Number(this.userData.totalCharge) <= Number(this.userData.gasSupplyCharge)) {
      return false;
    }
    return true;
  }

  public convertToNumber(data) {
    return Number(data);
  }

  public checkIfNumber(control : FormControl) {
    var numberValue = control.value;
    if (isNaN(numberValue)) {
      return { 'pattern' : true };
    }
    return null; //valid
  }
}
