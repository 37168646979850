import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from '../utility';

@Component({
  selector: 'app-retailers',
  templateUrl: './retailers.component.html',
  styleUrls: ['./retailers.component.css']
})
export class RetailersComponent implements OnInit {
  public retailers;
  public bannerTitle = "Victorian energy retailers";
  public bannerSubTitle = "A list of retailers who have published retailer offers";
  public bannerDetails = "";

  constructor(public dataService : DataService,
    public utility : Utility,
    public spinner : NgxSpinnerService) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.showRetailers();
    document.title = 'Retailers - Victorian Energy Compare';
  }

  private showRetailers() {
    this.spinner.show();
    let retailers;
    this.dataService.showRetailers().subscribe(
      retailers => {
        this.spinner.hide();
        this.retailers = retailers["data"];
      },
      error => {
        this.spinner.hide();
      }
    );
  }

}
