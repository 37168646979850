import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';
import { Router } from "@angular/router";

@Component({
  selector: 'app-hot-topics-moving-out',
  templateUrl: './hot-topics-moving-out.component.html',
  styleUrls: ['./hot-topics-moving-out.component.css']
})
export class HotTopicsMovingOutComponent implements OnInit {
  public appURL = environment.appURL;
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public bannerTitle = "Renting? Six ways to save on energy bills as a renter";
  public bannerSubTitle = "";
  public bannerDetails = "";

  constructor(
    public router : Router,
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Hot Topics - Moving out? Six ways to save on energy bills as a renter - Victorian Energy Compare';
  }

  public mainHotTopics() {
    this.router.navigate(['/hot-topics']);
    this.utility.scrollToTop('none');
  }

}
