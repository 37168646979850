<div class="container hide-on-ie-print">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="col-lg-10" [ngClass]="{ 'solar-background' : (userData.energyType == 'Solar') }">
        <h2 class="subTitle">Get started</h2>
        <div class="row" *ngIf="sessionRestart == true">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        Your session has timed out due to inactivity. Please start again if required.
                    </h4>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="userData.distributorSubmit == true && userData.source != ''">
            <div class="col-lg-12">
                <div class="alert alert-warning distributor-clickthrough-recommendation" role="alert">
                    You have entered the {{ userData.source | titlecase }} portal<br />
                    Please scroll down to the unanswered questions below.
                </div>
            </div>
        </div>
        <div class="row" *ngIf="distributorSubmitError != ''">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        An error has occured while trying to submit the data
                    </h4>
                    <p [innerHTML]="distributorSubmitError" class="distributor-error"></p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="PSBError != ''">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        Invalid PSB details
                    </h4>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="EAPError != ''">
            <div class="col-lg-12">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        Invalid EAP details
                    </h4>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="saveFormError != ''">
            <div class="col-lg-12" id="saveFormError">
                <div class="alert alert-danger text-center" role="alert">
                    <h4>
                        An error has occured while trying to submit the information you have provided. Please try again.
                    </h4>
                </div>
            </div>
        </div>

        <form method="post" id="VECForm" [formGroup]="VECForm" onsubmit="return false" (keydown.enter)="$event.preventDefault()">
            <div class="row form-group page-divider-up" [ngClass]="{ 'd-none' : (utility.getSelectedEnergyType() == 'Solar') }">
                <label class="h3 small-pad-left">
                    What are you looking to compare?
                </label>
                <br />
                <div class="row col-lg-12 no-margin no-padding">
                    <div class="col-sm-4">
                        <div class="radio-inline">
                            <input type="radio" id="electricity" value="Electricity" formControlName="energyType" [(ngModel)]="userData.energyType">
                            <label for="electricity" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="1" (keydown)="selectButton($event, 'Electricity'); getRetailers('Electricity')" (click)="getRetailers('Electricity')">
                                <i class="material-icons button-icons" aria-hidden="true">flash_on</i>
                                Electricity Offers
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class=" radio-inline">
                            <input type="radio" id="gas" value="Gas" formControlName="energyType" [(ngModel)]="userData.energyType" />
                            <label for="gas" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="2" (keydown)="selectButton($event, 'Gas'); getRetailers('Gas')" (click)="getRetailers('Gas')">
                                <i class="material-icons button-icons" aria-hidden="true">whatshot</i>
                                Gas Offers
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class=" radio-inline">
                            <input type="radio" id="solar" value="Solar" formControlName="energyType" [(ngModel)]="userData.energyType">
                            <label for="solar" tabindex="3" class="custom-btn-large custom-btn-large-text col-lg-12" (keydown)="selectButton($event, 'Solar'); getRetailers('Electricity')" (click)="getRetailers('Electricity')">
                                <i class="material-icons button-icons" aria-hidden="true">wb_sunny</i>
                                Solar Savings
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row tooltip-energy-select" [ngClass]="{ 'd-none' : (utility.getSelectedEnergyType() == 'Solar') }">
                <div class="col-md-12">
                    <span class="custom-tooltip info-icon" *ngIf="userData.energyType != 'Solar'">
                        <i
                            class="material-icons non-focus"
                            tabindex="0"
                            data-toggle="tooltip"
                            title=""
                            [triggers]="utility.toolTipForMobile()"
                            ngbTooltip="To compare both electricity and gas select an energy type on this screen and then add the other energy type when you get to the Available Offers screen." data-original-title="To compare both electricity and gas select an energy type on this screen and then add the other energy type when you get to the Available Offers screen."
                            aria-label="To compare both electricity and gas select an energy type on this screen and then add the other energy type when you get to the Available Offers screen." data-original-title="To compare both electricity and gas select an energy type on this screen and then add the other energy type when you get to the Available Offers screen.">
                            info</i>
                    </span>
                    <span *ngIf="userData.energyType != 'Solar'">
                        To compare both electricity and gas select an energy type on this screen and then add the other energy type when you get to the Available Offers screen.
                    </span>

                    <span class="custom-tooltip info-icon" *ngIf="userData.energyType == 'Solar'">
                        <i
                            class="material-icons non-focus"
                            tabindex="0"
                            data-toggle="tooltip"
                            title=""
                            [triggers]="utility.toolTipForMobile()"
                            ngbTooltip="Use this calculator to see if you could save by installing a PV Solar System."
                            aria-label="Use this calculator to see if you could save by installing a PV Solar System.">
                            info</i>
                    </span>
                    <span *ngIf="userData.energyType == 'Solar'">
                        Use this calculator to see if you could save by installing a PV Solar System.
                        For more information
                        <a
                            href='{{ appURL }}/solar-savings'
                            alt="Solar Journey and Facts"
                            title="Solar Journey and Facts"
                            aria-label="Solar Journey and Facts"
                            target="_blank"
                            class="link non-focus">
                            click here.
                        </a>
                    </span>
                </div>
            </div>

            <br />
            <div class="form-group page-divider-up" *ngIf="formShowSelection.userType == true">
                <label class="h3">Where do you need your energy?</label>
                <div class="row">
                    <div class="col-sm-5">
                        <div class="radio-inline">
                            <input type="radio" id="customer_home" value="Residential" formControlName="userType" [(ngModel)]="userData.userType">
                            <label for="customer_home" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="4" (keydown)="selectButton($event, 'Residential')">
                                <i class="material-icons button-icons" aria-hidden="true">home</i>
                                Home
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="radio-inline">
                            <input type="radio" id="customer_business" value="Small business" formControlName="userType" [(ngModel)]="userData.userType">
                            <label for="customer_business" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="5" (keydown)="selectButton($event, 'Small business')">
                                <i class="material-icons button-icons" aria-hidden="true">business</i>
                                Small Business
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-5">
                        <div class="radio-inline" *ngIf="formShowSelection.bill == true">
                            <input formControlName="bill" type="radio" id="bill" value="bill" [(ngModel)]="userData.bill">
                            <label for="bill" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="6" (keydown)="selectButton($event, 'bill')">
                                I have a recent bill
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <span *ngIf="userData.energyType != ''" class="small-business-info">
                            Select small business if you are on a business plan, not a residential plan. This information will be on your bill.
                        </span>
                    </div>
                </div>
                <div class="row" *ngIf="formShowSelection.bill == true">
                    <div class="col-sm-5">
                        <div class="radio-inline">
                            <span>
                                <input formControlName="bill" type="radio" id="moving" value="no bill" [(ngModel)]="userData.bill">
                                <label for="moving" class="custom-btn-large custom-btn-large-text col-lg-12" tabindex="7" (keydown)="selectButton($event, 'moving')">
                                    I'm moving (so I don't have a bill)
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-5">
                    </div>
                </div>
            </div>

            <div class="row form-group page-divider-up" *ngIf="formShowSelection.twelveMonths == true && userData.bill != 'no bill' && userData.energyType != 'Gas'">
                <div class="col-lg-5 vertical-align">
                    <ng-template #tipTwelveMonths>If you have been at your address for more than 12 months, we can retrieve your smart meter data and provide you with more accurate results.<br><br>Please refer to the terms and conditions for further details.</ng-template>
                    <label class="h3">
                        Have you been at your address for the last 12 months?
                        <i class="material-icons" data-toggle="tooltip"
                            [triggers]="utility.toolTipForMobile()"
                            [ngbTooltip]="tipTwelveMonths"
                            aria-label="If you have been at your address for more than 12 months, we can retrieve your smart meter data and provide you with more accurate results. Please refer to the terms and conditions for further details.">info</i>
                    </label>
                </div>
                <div class="radio-inline col-sm-7 vertical-align">
                    <input formControlName="twelveMonths" type="radio" id="12monthYes" value="1" [(ngModel)]="userData.twelveMonths">
                    <label for="12monthYes" class="custom-btn-default custom-btn-text" tabindex="8" (keydown)="selectButton($event, '12monthYes')">Yes</label>
                    <input formControlName="twelveMonths" type="radio" id="12monthNo" value="0" [(ngModel)]="userData.twelveMonths">
                    <label for="12monthNo" class="custom-btn-default custom-btn-text" tabindex="9" (keydown)="selectButton($event, '12monthNo')">No</label>
                </div>
            </div>

            <div class="row page-divider-up" *ngIf="formShowSelection.retailer == true">
                <div class="col-lg-5 vertical-align">
                    <label class="h3">
                        Who is your current retailer?
                        <i class="material-icons" data-toggle="tooltip"
                            [triggers]="utility.toolTipForMobile()"
                            ngbTooltip="Your retailer is the company that charges you for your energy usage. The retailer's name will be at the top of your bill."
                            aria-label="Your retailer is the company that charges you for your energy usage. The retailer's name will be at the top of your bill.">info</i>
                    </label>
                </div>
                <div class="col-sm-7 form-group vertical-align">
                    <ng-select
                        class="form-control"
                        name="retailer"
                        tabindex="10"
                        [searchFn]="startsWithSearchFn"
                        formControlName="retailer"
                        [(ngModel)]="userData.retailer"
                        [items]="retailers"
                        bindLabel="title"
                        bindValue="id"
                        #retailers1
                        (blur)="setRetailerName()"
                        (close)="retailers1.blur(); retailers1.focus()"
                        [loading]="retailersLoading">
                    </ng-select>
                    <bfv-messages></bfv-messages>
                </div>
            </div>

            <div *ngIf="formShowSelection.postcode == true" class="row form-group">
                <div class="col-lg-5 vertical-align">
                    <label class="h3">
                        What is your postcode?
                    </label>
                </div>
                <div class="col-sm-4 vertical-align postcode">
                    <input
                        (keydown.enter)="getDistributors()"
                        type="text"
                        maxlength="4"
                        title="Postcode"
                        aria-label="Postcode"
                        tabindex="12"
                        formControlName="postcode"
                        value=""
                        id="postcode"
                        [ngClass]="{ 'ng-invalid is-invalid' : (distributorError == true) }"
                        [(ngModel)]="userData.postcode"
                        (ngModelChange)="enableSubmit()"
                        class="form-control"/>
                    <bfv-messages></bfv-messages>
                </div>
                <div class="col-sm-3 postcode-div">
                    <input
                        type="button"
                        tabindex="13"
                        [disabled]="submitDisabled"
                        (click)="getDistributors()"
                        class="postcode-button custom-button form-control"
                        value="Submit postcode"
                        (keydown)="selectButton($event, 'submitPostCode')"/>
                </div>
            </div>
            <br />
            <div class="row smart-meter" *ngIf="formShowSelection.distributors == true">
                <div class="col-lg-5">
                </div>
                <div class="col-lg-7">
                    <p *ngIf="distributorZoneClash == false">
                        There are multiple distributors that supply {{ userData.energyType.toLowerCase() }} for the postcode you have provided. Please confirm the correct
                        distributor has been selected. If you have a bill handy, you can do this by matching the telephone number below to the
                        faults and emergencies telephone number on your bill.
                    </p>
                    <p *ngIf="distributorZoneClash == true">
                        There are multiple {{ userData.energyType.toLowerCase() }} distribution zones for the postcode you have provided. You can confirm the correct zone by calling the distributor.
                    </p>

                    <ul class="distributor-list">
                        <li *ngFor="let distributor of distributors">
                            <div class="radio radio-primary">
                                <input
                                    name="distributor"
                                    type="radio"
                                    tabindex="14"
                                    id="radio-{{ distributor.name }}-{{ distributor.distribution_zone_name }}"
                                    value="{{ distributor.name }}"
                                    (click)="selectDistributor(distributor.id, distributor.name, distributor.distribution_zone_name)">
                                <label for="radio-{{ distributor.name }}-{{ distributor.distribution_zone_name }}" *ngIf="distributorZoneClash == false">
                                    {{ distributor.name }} {{ (distributor.phone != '' || distributor.phone == null) ? '(' + distributor.phone + ')' : '' }}
                                </label>
                                <label for="radio-{{ distributor.name }}-{{ distributor.distribution_zone_name }}" *ngIf="distributorZoneClash == true">
                                    {{ distributor.name }} - {{ distributor.distribution_zone_name }}
                                </label>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="alert alert-danger row form-group distributor-error" *ngIf="distributorError == true">
                {{ distributorErrorMessage }}
            </div>

            <div *ngIf="formShowSelection.usageProfile == true && !(userData.postcode >= '3000' && userData.postcode < '4000')" class="row form-group">
                <div class="col-lg-12">
                    <div class="alert alert-warning distributor-clickthrough-recommendation" role="alert">
                        Consumers outside Victoria can compare electricity and gas offers at the
                        <a title="Energy Made Easy" aria-label="Energy Made Easy" href="https://www.energymadeeasy.gov.au/" target="_blank">Energy Made Easy</a> website.
                    </div>
                    <br />
                </div>
            </div>
            <div *ngIf="formShowSelection.usageProfile == true && (userData.postcode >= '3000' && userData.postcode < '4000') && userData.energyType != 'Gas'" class="row form-group">
                <div class="col-lg-12" *ngIf="userData.distributor.name == 'Powercor'">
                    <div class="alert alert-warning distributor-clickthrough-recommendation">
                        Did you know? Your distributor, Powercor, now has a
                        <a href="https://customermeterdata.portal.powercor.com.au/customermeterdata/CADAccountPage" target="_blank">customer web portal</a>
                        which allows you to view your smart meter data and send it directly to Victorian Energy Compare.
                        <span class="distributor-link" (click)="distributorInfoModal()">More information here</span>.
                    </div>
                    <br />
                </div>
                <div class="col-lg-12" *ngIf="userData.distributor.name == 'Citipower'">
                    <div class="alert alert-warning distributor-clickthrough-recommendation">
                        Did you know? Your distributor, CitiPower, now has a
                        <a href="https://customermeterdata.portal.powercor.com.au/customermeterdata/CADAccountPage" target="_blank">customer web portal</a>
                        which allows you to view your smart meter data and send it directly to Victorian Energy Compare.
                        <span class="distributor-link" (click)="distributorInfoModal()">More information here</span>.
                    </div>
                    <br />
                </div>
                <div class="col-lg-12" *ngIf="userData.distributor.name == 'AusNet Services (electricity)'">
                    <div class="alert alert-warning distributor-clickthrough-recommendation">
                        Did you know? Your distributor, Ausnet Services, now has a
                        <a href="https://www.ausnetservices.com.au/myHomeEnergy/Login" target="_blank">customer web portal</a>
                        which allows you to send your smart meter data directly to Victorian Energy Compare, allowing for the most accurate comparisons.
                        <span class="distributor-link" (click)="distributorInfoModal()">More information here</span>
                    </div>
                    <br />
                </div>

                <div class="row form-group page-divider-up usage-profile-selection"
                    *ngIf="userData.bill != 'no bill' && userData.energyType != 'Gas' && userData.twelveMonths == '1'">
                    <label class="h3 col-lg-12 small-pad-left">
                        How would you like to build your energy usage profile?
                    </label>
                    <div class="row col-lg-12">
                        <div [ngClass]="(userData.userType != 'Small business') ? 'col-sm-4' : 'col-sm-6'">
                            <ng-template #tipContent2>Selecting <strong>Get my data for me</strong> will allow us to fetch your smart meter data.</ng-template>
                            <div class="radio-inline">
                                <input
                                    [checked]="(userData.usageProfile == '1')"
                                    type="radio"
                                    id="getdata"
                                    value="1"
                                    (click)="clearSolarSelection()"
                                    formControlName="usageProfile"
                                    [(ngModel)]="userData.usageProfile" />
                                <label *ngIf="dataService.liveAEMO == true" for="getdata" class="get-my-data2 usage-profile ie-padding custom-btn-large custom-btn-large-text col-lg-12" (click)="NMIFocus(); resetValdators();" tabindex="15" (keydown)="selectButton($event, 'getMyData')">
                                    Get my data for me (recommended)
                                    <i
                                        class="material-icons"
                                        [triggers]="utility.toolTipForMobile()"
                                        [ngbTooltip]="tipContent2">info</i>
                                </label>
                                <label *ngIf="dataService.liveAEMO == false" class="get-my-data-disabled usage-profile ie-padding custom-btn-large-text col-lg-12" (click)="NMIFocus(); resetValdators();" tabindex="15" (keydown)="selectButton($event, 'getMyData')">
                                    Get my data for me
                                    <i
                                        class="material-icons"
                                        [triggers]="utility.toolTipForMobile()"
                                        [ngbTooltip]="tipContent2">info</i>
                                </label>
                            </div>
                        </div>
                        <div [ngClass]="(userData.userType != 'Small business') ? 'col-sm-4' : 'col-sm-6'">
                            <div class="radio-inline">
                                <ng-template #tipContent3>Selecting <strong>Upload a data file</strong> will allow you to upload a data file you have obtained from your distributor or retailer.</ng-template>
                                <input
                                    [checked]="(userData.usageProfile == '2')"
                                    type="radio"
                                    id="upload"
                                    value="2"
                                    (click)="clearSolarSelection()"
                                    formControlName="usageProfile"
                                    [(ngModel)]="userData.usageProfile" />
                                <label for="upload" class="usage-profile ie-padding custom-btn-large custom-btn-large-text col-lg-12" tabindex="16" (keydown)="selectButton($event, 'uploadData')">
                                    Upload a data file
                                    <i
                                        class="material-icons"
                                        [triggers]="utility.toolTipForMobile()"
                                        [ngbTooltip]="tipContent3">info</i>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-4" *ngIf="userData.userType != 'Small business'">
                            <div class="radio-inline">
                                <ng-template #tipContent4>
                                   Selecting <strong>Complete an energy questionnaire</strong> will allow you to answer questions
                                   about your household energy usage so we can estimate your electricity consumption.
                                </ng-template>
                                <input
                                    [checked]="(userData.usageProfile == '3')"
                                    type="radio"
                                    id="questionnaire"
                                    value="3"
                                    (click)="clearSolarSelection()"
                                    formControlName="usageProfile"
                                    [(ngModel)]="userData.usageProfile" />
                                <label for="questionnaire" class="usage-profile custom-btn-large custom-btn-large-text col-lg-12" tabindex="16.5" (keydown)="selectButton($event, 'questionnaire')">
                                    Complete an energy questionnaire
                                    <i
                                        class="material-icons"
                                        [triggers]="utility.toolTipForMobile()"
                                        [ngbTooltip]="tipContent4">info</i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row col-lg-12 margin-left pad-left" *ngIf="userData.userType != 'Small business'">
                        <br />
                        <span class="col-lg-12">
                            <br />
                            <span *ngIf="dataService.liveAEMO == false">
                                The "Get my data for me" function is currently unavailable. However, you can also proceed using one of the other options above.
                            </span>
                            <span *ngIf="dataService.liveAEMO == true">
                                Using "Get my data for me" is the quickest and most accurate way to get your electricity comparison.
                                However, you can also proceed using one of the other options above.
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="userData.usageProfile == '1'" class="page-divider-up aemo">
                <div class="row">
                    <div class="col-sm-5">
                        <label class="h3">
                            What is your NMI?
                        </label>
                        <i class="material-icons"
                            [triggers]="utility.toolTipForMobile()"
                            ngbTooltip="The National Metering Identifier (NMI) is unique to your electricity meter. We use this to fetch consumption data for offer comparison. The NMI is a 10 or 11 digit number that can be found in your electricity bill."
                            aria-label="The National Metering Identifier (NMI) is unique to your electricity meter. We use this to fetch consumption data for offer comparison. The NMI is a 10 or 11 digit number that can be found in your electricity bill.">info</i>
                        <br />
                        <span>
                            Your National Metering Identifier (NMI) can be found on your electricity bill.<br /><br />
                        </span>
                    </div>
                    <div class="col-sm-7 form-group">
                        <input
                            type="text"
                            id="nmi"
                            title="NMI"
                            aria-label="NMI"
                            tabindex="17"
                            formControlName="nmi"
                            value=""
                            maxlength="11"
                            (change)="resetNMI()"
                            [(ngModel)]="userData.nmi"
                            class="form-control col-lg-8"/>
                        <bfv-messages></bfv-messages>
                        <br />
                        <span *ngIf="userData.distributor.name == 'AusNet Services (electricity)'">example: 6305000000</span>
                        <span *ngIf="userData.distributor.name == 'Citipower'">example: 6102000000</span>
                        <span *ngIf="userData.distributor.name == 'Jemena'">example: 6001000000</span>
                        <span *ngIf="userData.distributor.name == 'Powercor'">example: 6203000000</span>
                        <span *ngIf="userData.distributor.name == 'United Energy'">example: 6407000000</span>
                    </div>
                </div>
                <br /><br />
                <div class="form-group">
                    <label class="control-label title-black"><h3>Collection Statement</h3></label>
                    <p>
                        <span
                            title="Read the full collection statement"
                            aria-label="Read the full collection statement"
                            (click)="collectionStatementModal()"
                            class="full-statement with-focus"
                            tabindex="18"
                            (keydown)="selectButton($event, 'fullStatement')">
                            Read the full collection statement
                        </span>
                    </p>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 checkbox-home checkbox checkbox-primary">
                            <input type="checkbox" class="form-check-input" id="agree" formControlName="agree" [(ngModel)]="userData.agree" tabindex="19">
                            <label for="agree" class="agree-label">
                                I have read and agree to the collection statement.<br /><br />
                                By agreeing to the collection statement and providing your NMI, we can retrieve your smart meter data and provide you with more accurate results.
                            </label>
                        </div>
                        <div class="col-md-5 col-sm-12 col-xs-12">
                            <div class="nmi-buttons col-md-6 get-data-btn">
                                <input
                                    type="button"
                                    tabindex="19"
                                    [disabled]="!userData.agree ? true : null"
                                    (click)="getConsumptionData()"
                                    (keydown)="selectButton($event, 'GetNMIdata')"
                                    [ngClass]="{
                                        'btn-disabled': !userData.agree,
                                        'custom-button': userData.agree
                                    }"
                                    class="no-padding no-margin form-control"
                                    value="Get my data" />
                            </div>
                            <div id="aemo_logo" class="nmi-buttons col-md-6">
                                <img alt="AEMO" aria-label="AEMO" src='assets/images/powered_by_aemo.png' alt="AEMO" width="130" height="43">
                            </div>
                            <div class="nmi-buttons col-md-6 skip">
                                <span
                                    title="Skip"
                                    aria-label="Skip"
                                    (click)="skipNMIProcess()"
                                    class="skip-nmi"
                                    tabindex="19.1"
                                    (keydown)="selectButton($event, 'skipNMI')">
                                    Skip this step
                                </span>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div id="nmi_upload_status"
                *ngIf="userData.usageProfile == '1' && showNMIAnimation == true">
                <div id="nmi-animation" class="{{ (NMIIsComplete == true && NMIStatus == 'error') ? 'done error' : '' }} {{ (NMIIsComplete == true && NMIStatus == 'success') ? 'done success' : '' }}">
                    <div id="nmi-animation__vec" [ngClass]="secBranding == true ? 'nmi-animation__vec_sec' : ''"></div>
                    <div id="nmi-animation__file"></div>
                    <div id="nmi-animation__meter"></div>
                    <div id="nmi-animation__track"></div>
                </div>
                <div id="nmi-animation-text" class="info-text custom-padding">
                    <p id="nmi-animation-text-1" [ngStyle]="{ 'display': (NMIPercentage < 50) ? 'block' : 'none' }">Information from your smart meter, such as how much energy you use and what time of day, will help create an accurate energy usage profile.</p>
                    <p id="nmi-animation-text-2" [ngStyle]="{ 'display': (NMIPercentage >= 50) ? 'block' : 'none' }">This will be used to accurately estimate what each energy retailer's offer will cost you, based on precisely how much electricity you use day to day.</p>
                </div>
                <div id="nmi-progress" class="progress progress-animation">
                    <div
                        class="align-middle progress-bar-animation {{ (NMIStatus == 'success') ? 'progress-bar-success': '' }} {{ (NMIStatus == 'error') ? 'progress-bar-danger': '' }}"
                        [ngStyle]="{'width': NMIPercentage + '%' }">
                        {{ NMIPercentage }}%
                    </div>
                </div>
                <br />
                <div class="alert alert-danger" *ngIf="NMIStatus == 'error'">
                    <p *ngIf="NMIErrorMessage == ''">
                        <span class="strong">The following issue was found: </span>
                        were unable to retrieve usage data for your NMI
                    </p>
                    <p *ngIf="NMIErrorMessage != ''" [innerHTML]="NMIErrorMessage">
                    </p>
                    <div *ngIf="NMIErrorMessage != 'Google reCAPTCHA is invalid.'">
                        <br />
                        <span class="strong">What can I do?</span>
                        <ul class="profiles">
                            <li>
                                Ensure that your Postcode, Retailer and NMI are correct and click Get my data again, or
                            </li>
                            <li>
                                Build your energy usage profile by uploading a data file or
                            </li>
                            <li>
                                Click
                                <span
                                    title="Skip"
                                    aria-label="Skip"
                                    (click)="skipNMIProcess()"
                                    class="skip-nmi"
                                    (keydown)="selectButton($event, 'skipNMI')">
                                    Skip this step
                                </span>, to continue without your usage data.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="alert alert-success" *ngIf="NMIStatus == 'success'">
                    <p>
                        Usage data was successfully fetched.
                        <span class="strong">Please continue.</span>
                    </p>
                </div>
                <br /><br />
            </div>

            <div *ngIf="userData.usageProfile == '2'" class="form-group">
                <div class="row smart-meter page-divider-up" *ngIf="userData.twelveMonths == '1' && userData.userType != 'Small business'">
                    <div class="col-lg-5 vertical-align">
                        <label class="h3">
                            Do you have a smart meter data file to upload?
                        </label>
                    </div>
                    <div class="col-lg-7 row">
                        <div class="radio-inline col-lg-7 vertical-align">
                            <input formControlName="smartMeter" type="radio" id="smartMeterYes" value="1" [(ngModel)]="userData.smartMeter">
                            <label for="smartMeterYes" class="custom-btn-default custom-btn-text" (click)="smartMeterGroupFields()" tabindex="20" (keydown)="selectButton($event, 'smartMeterYes')">Yes</label>
                            <input formControlName="smartMeter" type="radio" id="smartMeterNo" value="0" [(ngModel)]="userData.smartMeter">
                            <label for="smartMeterNo" class="custom-btn-default custom-btn-text" (click)="smartMeterGroupFields(); smartMeterInformation()" tabindex="21" (keydown)="selectButton($event, 'smartMeterNo')">No</label>
                        </div>
                        <div class="col-lg-5 smart-meter">
                            <span
                                title="Download data"
                                aria-label="Download data"
                                (click)="downloadDataModal()"
                                class="download with-focus middle-align"
                                tabindex="22"
                                (keydown)="selectButton($event, 'downloadData')">
                                Download data
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="userData.smartMeter == '1'" class="page-divider-up">
                    <div class="row">
                        <div class="col-lg-5 vertical-align">
                            <label class="h3" *ngIf="userData.userType == 'Small business'">
                                Upload your smart meter file to proceed:
                            </label>
                            <br />
                            <span
                                title="Show me how to download my data"
                                aria-label="Show me how to download my data"
                                (click)="downloadDataModal()"
                                class="download middle-align"
                                tabindex="23"
                                (keydown)="selectButton($event, 'downloadData')"
                                *ngIf="userData.userType == 'Small business'">
                                Show me how to download my data
                            </span>
                        </div>
                        <div class="col-sm-7 form-group vertical-align">
                            Where did you get the file?
                            <br /><br />
                            <ng-select
                                tabindex="24"
                                class="form-control"
                                formControlName="fileProvider"
                                [searchFn]="startsWithSearchFn"
                                [(ngModel)]="userData.fileProvider"
                                [items]="uploadRetailers"
                                bindLabel="title"
                                bindValue="id"
                                #retailers2
                                (close)="retailers2.blur(); retailers2.focus()"
                                [loading]="retailersLoading">
                            </ng-select>
                            <bfv-messages></bfv-messages>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-5 vertical-align">
                        </div>
                        <div class="col-sm-7 vertical-align file-upload">
                            Upload your file:
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-5 vertical-align">
                        </div>
                        <div class="col-sm-3 vertical-align file-upload">
                            <input
                                type="text"
                                value="{{ userData.fileName }}"
                                disabled="disabled"
                                class="form-control"/>
                        </div>
                        <div
                            class="form-control fileUploadDiv col-sm-3 vertical-align"
                            [ngClass]="{
                                'custom-button-disabled': (userData.fileProvider == '' || userData.fileProvider == null || userData.fileProvider == '0'),
                                'custom-button': (userData.fileProvider > '0')
                            }">

                            <label for="fileupload" class="error hidden-label">Please enter a value with a valid mimetype.</label>
                            <input
                                *ngIf="utility.inApp() == false"
                                [disabled]="(userData.fileProvider == '' || userData.fileProvider == null || userData.fileProvider == '0') ? true : null"
                                id="fileupload"
                                onclick="this.value = null"
                                (change)="uploadFileConsumptionData($event.target.files)"
                                name="fileupload"
                                type="file"
                                class="upload valid"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                            <input
                                *ngIf="utility.inApp() == true"
                                [disabled]="(userData.fileProvider == '' || userData.fileProvider == null || userData.fileProvider == '0') ? true : null"
                                id="fileupload"
                                onclick="this.value = null"
                                (change)="uploadFileConsumptionData($event.target.files)"
                                name="fileupload"
                                type="file"
                                class="upload valid">

                            <label
                                id="browse"
                                for="fileupload">Browse</label>
                        </div>
                    </div>
                </div>
            </div>
            <div id="file_upload_status"
                *ngIf="userData.usageProfile == '2' && showUploadAnimation == true">
                {{ userData.fileName }}
                <br />
                <div id="file-progress" class="progress progress-animation">
                    <div
                        class="align-middle progress-bar-animation {{ (fileUploadStatus == 'success') ? 'progress-bar-success': '' }} {{ (fileUploadStatus == 'error') ? 'progress-bar-danger': '' }}"
                        [ngStyle]="{'width': fileUploadPercentage + '%' }">
                        {{ fileUploadPercentage }}%
                    </div>
                </div>
                <br />
                <div class="alert alert-danger" *ngIf="fileUploadStatus == 'error'">
                    <i class="fa fa-warning"></i>&nbsp;<span [innerHTML]="utility.getErrorMessage()"></span>
                </div>
                <div class="alert alert-success" *ngIf="fileUploadStatus == 'success'">
                    <p>
                        File was successfully uploaded.
                        <span class="strong">Please continue.</span>
                    </p>
                </div>
                <br /><br />
            </div>

            <div *ngIf="formShowSelection.concession == true && userData.userType != 'Small business'" class="page-divider-up form-group">
                <div class="row">
                    <div class="col-lg-5 vertical-align">
                        <label class="h3" id="lbl_step4_header">
                            Do you currently receive an energy concession?
                            <i
                                class="material-icons"
                                [triggers]="utility.toolTipForMobile()"
                                aria-label="Selecting yes means the offers we show you include 'annual electricity concessions' and 'service to property charge concessions' for electricity or for gas 'winter gas concession'."
                                ngbTooltip="Selecting yes means the offers we show you include 'annual electricity concessions' and 'service to property charge concessions' for electricity or for gas 'winter gas concession'.">
                                info
                            </i>
                        </label>
                    </div>
                    <div class="col-lg-7 row">
                        <div class="radio-inline col-lg-7 vertical-align">
                            <input formControlName="hasConcession" type="radio" id="concessionYes" value="1" [(ngModel)]="userData.hasConcession">
                            <label id="concessionTipButton" for="concessionYes" class="custom-btn-default custom-btn-text" (click)="concessionTip.open()" tabindex="25" (keydown)="selectButton($event, 'concessionYes')">Yes</label>
                            <input formControlName="hasConcession" type="radio" id="concessionNo" value="0" [(ngModel)]="userData.hasConcession">
                            <label for="concessionNo" class="custom-btn-default custom-btn-text" tabindex="26" (click)="concessionTip.close()" (keydown)="selectButton($event, 'concessionNo')">No</label>
                        </div>
                        <div class="col-lg-5 smart-meter">
                            <span
                                alt="Am I eligible for cencession?"
                                aria-label="Am I eligible for cencession?"
                                #concessionTip="ngbTooltip"
                                (click)="concessionModal()"
                                class="download with-focus"
                                ngbTooltip="A discount will be included in your offers and values."
                                [disableTooltip]="(userData.hasConcession == '0') ? true : false"
                                [autoClose]="false"
                                tabindex="27"
                                closeDelay="0"
                                (keydown)="selectButton($event, 'concessionModal')">
                                Am I eligible?
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="(formShowSelection.solar == true && userData.energyType != 'Gas' && userData.showSolarSelection == true) || (userData.skipNMI == true && userData.hasConcession != '')" class="form-group page-divider-up">
                <div class="row">
                    <div class="col-lg-5 vertical-align">
                        <label class="h3" id="lbl_step6_header">
                            Do you have solar panels that generate electricity?
                            <i class="material-icons"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="If your household has solar panels which generate electricity then you should answer yes to this question. If solar panels are installed, household electricity costs are likely to decrease during the daylight hours."
                                aria-label="If your household has solar panels which generate electricity then you should answer yes to this question. If solar panels are installed, household electricity costs are likely to decrease during the daylight hours.">info</i>
                        </label>
                    </div>
                    <div class="radio-inline col-md-7 vertical-align">
                        <input formControlName="hasSolar" type="radio" id="solarYes" value="1" [(ngModel)]="userData.hasSolar">
                        <label for="solarYes" class="custom-btn-default custom-btn-text" tabindex="28" (keydown)="selectButton($event, 'solarYes')">Yes</label>
                        <input formControlName="hasSolar" type="radio" id="solarNo" value="0" [(ngModel)]="userData.hasSolar">
                        <label for="solarNo" class="custom-btn-default custom-btn-text" tabindex="29" (keydown)="selectButton($event, 'solarNo')">No</label>
                    </div>
                </div>
            </div>

            <div *ngIf="formShowSelection.solar == true && userData.hasSolar == '1' && userData.hasConcession != ''" class="form-group">
                <br />
                <div class="row">
                    <div class="col-lg-5">
                        <label class="h3" id="lbl_step6_header">
                            What is your solar feed-in tariff type?
                        </label>
                        <br />
                        <span>
                            For the best results, please confirm your feed-in tariff type with your retailer.
                            <br />
                            The rates shown are examples and yours may differ.
                        </span>
                    </div>
                    <div class="col-sm-7 form-group">
                        <div class="row col-md-12">
                            <label class="tariff-type-label">
                                Tariff type:
                            </label>
                        </div>
                        <div class="row select col-md-12">
                            <select tabindex="30" formControlName="feedInTariff" [(ngModel)]="userData.feedInTariff" class="form-control">
                                <option value="NewFIT">Current Feed-in Tariff / I don't know</option>
                                <option value="PFIT" selected="true">Premium Feed-in Tariff (60c/kWh or more)</option>
                            </select>
                        </div>
                        <bfv-messages *ngIf="!VECForm.controls['feedInTariff'].valid"></bfv-messages>
                        <br />
                    </div>
                </div>
                <div class="row" *ngIf="userData.usageProfile == '3' || userData.usageProfile == '0' ||
                                        (userData.usageProfile == '2' && userData.smartMeter == '0') ||
                                        (userData.usageProfile == '1' && userData.skipNMI == true)">
                    <div class="col-lg-5">
                    </div>
                    <div class="col-lg-7">
                        What is the capacity of the solar panels?
                        <br /><br />
                        <div class="row">
                            <div class="col-sm-5 form-group">
                                <label class="hidden-label">Solar panel capacity</label>
                                <input
                                    tabindex="31"
                                    step="0.01"
                                    [type]="'number'"
                                    id="solarCapacity"
                                    formControlName="solarCapacity"
                                    [(ngModel)]="userData.solarCapacity"
                                    [disabled]="userData.feedInTariff == '' ? true : null"
                                    class="form-control"/>
                                <bfv-messages></bfv-messages>
                            </div>
                            <div class="col-sm-2 align-middle solar-label">
                                <span class="strong">kW</span>
                            </div>
                            <div class="col-sm-5 align-middle solar-label">
                                <span
                                    (click)="setDefaultSolar()"
                                    tabindex="32"
                                    (keydown)="selectButton($event, 'solarDefault')"
                                    [triggers]="utility.toolTipForMobile()"
                                    ngbTooltip="The average system capacity in Victoria is 2.9kW"
                                    class="download">I don't know</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="formShowSelection.disclaimer == true" class="form-group page-divider-up upload">
                <h2>Disclaimer Agreement</h2>
                <br />
                <p>
                    <span
                        title="Read the full terms and conditions"
                        aria-label="Read the full terms and conditions"
                        (click)="termsModal()"
                        class="full-statement with-focus google-links"
                        tabindex="33"
                        (keydown)="selectButton($event, 'terms')">
                        Read the full terms and conditions
                    </span>
                </p>
                <div class="form-group">
                    <br />
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="checkbox checkbox-primary">
                                <input type="checkbox" class="form-check-input" id="disclaimer" formControlName="disclaimer" [(ngModel)]="userData.disclaimer" tabindex="34">
                                <label for="disclaimer" class="disclaimer-label">
                                    I have read and agree to the terms and conditions
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <button
                                title="Next button"
                                aria-label="Next button"
                                tabindex="35"
                                name="next"
                                [disabled]="!userData.disclaimer ? true : null"
                                class="custom-btn-default custom-button no-margin no-padding"
                                type="button"
                                value="next"
                                (keydown)="selectButton($event, 'saveFormData')"
                                (click)="saveFormData()">
                                NEXT <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <br />
                    <p>
                        <br />
                        This site is protected by reCAPTCHA and the Google
                        <a
                            title="Privacy Policy"
                            aria-label="Privacy Privacy"
                            class="google-links non-focus"
                            tabindex=-1
                            href="https://policies.google.com/privacy"
                            target="_blank">Privacy Policy</a> and
                        <a
                            title="Terms of Service"
                            aria-label="Terms of Service"
                            tabindex=-1
                            class="google-links non-focus"
                            href="https://policies.google.com/terms"
                            target="_blank">Terms of Service</a> apply.
                    </p>
                </div>
            </div>

        </form>
    </div>
</div>
<br /><br />
