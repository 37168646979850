<div id="container">
  <app-banner
      [title]="bannerTitle"
      [subTitle]="bannerSubTitle"
      [details]="bannerDetails">
  </app-banner>
  <br />

  <div>
      <div class="col-md-12 col-xs-12" [innerHTML]="pageContents">


      </div>
  </div>
</div>
<br /><br />
