import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { environment } from '../../environments/environment'; //config file
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.css']
})
export class ExportModalComponent implements OnInit {
  @ViewChild("htmlForm") htmlForm: ElementRef;
  public offerData;
  public energyType;
  public apiEndpoint = environment.apiEndpoint;
  public exportForm : FormGroup;
  constructor(
    public router : Router,
    public spinner : NgxSpinnerService,
    public formBuilder : FormBuilder,
    private modalService: NgbModal,
    public dataService : DataService,
    public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.exportForm = this.formBuilder.group({
      offerList : new FormControl(''),
    });
  }

  public exportOffers() {
    let offers = this.dataService.getSavedSortedOffers();
    let newOffers = [];
    for (let offer in offers.offersList) {
      let offerDetails = {
        "tariffType" : offers.offersList[offer].tariffType,
        "retailerName" : offers.offersList[offer].retailerName,
        "offerId" : offers.offersList[offer].offerId,
        "offerName" : offers.offersList[offer].offerName,
        "totalConditionalPrice" : offers.offersList[offer].totalConditionalPrice,
        "totalBasePrice" : offers.offersList[offer].totalBasePrice
      }
      newOffers.push(offerDetails);
    }
    this.spinner.show();
    this.offerData = JSON.stringify(newOffers);
    setTimeout(() => {
      this.htmlForm.nativeElement.submit();
      this.modalService.dismissAll();
      this.spinner.hide();
    }, 1000);
  }

  public exportAllOffers() {
    let offers = this.dataService.getSavedOffers();
    let energyTypeData = (this.router.url == '/offers/list/dual') ? 'Electricity' : this.energyType;
    let newOffers = [];
    for (let offer in offers[energyTypeData].offersList) {
      let offerDetails = {
        "tariffType" : offers[energyTypeData].offersList[offer].offerDetails[0].tariffType,
        "retailerName" : offers[energyTypeData].offersList[offer].offerDetails[0].retailerName,
        "offerId" : offers[energyTypeData].offersList[offer].offerDetails[0].offerId,
        "offerName" : offers[energyTypeData].offersList[offer].offerDetails[0].offerName,
        "totalConditionalPrice" : offers[energyTypeData].offersList[offer].offerDetails[0].totalConditionalPrice,
        "totalBasePrice" : offers[energyTypeData].offersList[offer].offerDetails[0].totalBasePrice
      }
      newOffers.push(offerDetails);
    }
    this.spinner.show();
    this.offerData = JSON.stringify(newOffers);
    setTimeout(() => {
      this.htmlForm.nativeElement.submit();
      this.modalService.dismissAll();
      this.spinner.hide();
    }, 1000);
  }

}
