<div class="container" id="print-section">
    <div class="modal-header">
        <div class="modal-icons col-lg-12">
            <button class="hidden-btn"></button>
            <button
                tabindex="3"
                title="Close"
                aria-label="Close"
                type="" class="btn btn-link non-focus" aria-label="Close" (click)="close()">
                <i class="fa fa-times" aria-hidden="true"></i> close
            </button>
            <button
                tabindex="2"
                title="Print retailer offer details"
                aria-label="Print retailer offer details"
                type="button"
                class="print-icon btn btn-link"
                (click)="print()">
                <i class="fa fa-print"></i>
                - print retailer offer details
            </button>
            <button
                tabindex="1"
                title="Email this offer"
                aria-label="Email this offer"
                type="button"
                class="email-icon btn btn-link"
                (click)="email()">
                <i class="fa fa-envelope"></i>
                - email this offer
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-medium-print-header" [ngClass]="secBranding == true ? 'vec-sec' : ''">
            <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png" *ngIf="secBranding == true">
            <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_720_left.png" *ngIf="secBranding == false">
            <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_720_vic_logo.png" *ngIf="secBranding == false">
            <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_600_vec_logo.png" *ngIf="secBranding == false">
        </div>

        <div class="hidden-label">{{ emailSecurityKey }}</div>
        <div class="offer-title">
            <div class="row offer-header">
                <div class="col-lg-8">
                    <h1 class="retailer-name">{{ offer.retailerName }}</h1>
                    <div class="offer-name">
                        {{ offer.offerName }}
                        <span *ngIf="offer.solarType != null && offer.solarType != undefined && offer.solarType != ''">
                            {{ '(' + offer.solarType + ')' | uppercase }}
                        </span>
                    </div>
                    <br /><br />
                </div>

                <div class="col-lg-4">
                    <div class="compare-retailer-logo align-middle-img">
                        <img [attr.alt]="offer.retailerName" [attr.aria-label]="offer.retailerName" src='{{ appURL }}/assets/{{ offer.retailerImageUrl }}' class="align-retailer-logo">
                    </div>
                </div>
            </div>
        </div>
        <div class="row view-offer-section1">
            <div class="col-lg-8">
                <div class="row offer-section">
                    <div class="col-md-6 border-side-right">
                        <span class="sub-header">Features</span>
                        <table class="table offer-feature-table">
                            <tbody>
                            <tr *ngIf="energyType != 'Gas' && offer.isVictorianDefaultOffer != false">
                                <td>
                                    <i
                                        class="material-icons"
                                        [ngClass]="(offer.isVictorianDefaultOffer != false) ? 'victorian-default-offer' : 'icon-disabled'">
                                        &#xe32a;
                                    </i>
                                </td>
                                <td>
                                    Victorian Default Offer
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <i class="material-icons debit-img"
                                    [ngClass]="(offer.isDirectDebitOnly == true) ? 'direct-debit' : 'icon-disabled'">
                                       &#xE870;
                                    </i>
                                </td>
                                <td>
                                    {{ (offer.isDirectDebitOnly == true) ? 'Payment details required (e.g. bank debit / credit card)' : 'Payment details not required' }}
                                </td>
                            </tr>
                            <tr *ngIf="offer.hasPayOnTimeDiscount == true || offer.contractLength != 'None'">
                                <td>
                                    <i class="material-icons"
                                        [ngClass]="(offer.hasPayOnTimeDiscount == true || offer.contractLength != 'None') ? 'payontime' : 'icon-disabled'">
                                       &#xE855;
                                    </i>
                                </td>
                                <td>
                                    <span *ngIf="offer.hasPayOnTimeDiscount == true">Pay on time discount<br></span>
                                    <span *ngIf="offer.contractLength != 'None'">
                                        {{ offer.contractLength }} contract
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <i class="material-icons"
                                        [ngClass]="(offer.hasIncentive == true) ? 'incentive' : 'icon-disabled'">
                                        &#xE8F6;
                                    </i>
                                </td>
                                <td>
                                    {{ (offer.hasIncentive == true) ? 'The offer has incentive(s)' : 'No incentive' }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <i class="material-icons"
                                       [ngClass]="(offer.greenPowerDisplay != 0) ? 'green-power' : 'icon-disabled'">&#xE545;</i>
                                </td>
                                <td *ngIf="offer.greenPowerDisplay != 0">
                                    <span *ngIf="offer.intrinsicGreenPowerDisplay != 0">
                                        {{ offer.greenPowerDisplay }}%
                                        green power
                                    </span>
                                    <span *ngIf="offer.intrinsicGreenPowerDisplay == 0">
                                        {{ offer.greenPowerDisplay }}%
                                        GreenPower
                                    </span>
                                </td>
                                <td *ngIf="offer.greenPowerDisplay == 0">
                                    No Green Power
                                </td>
                            </tr>
                            <tr *ngIf="energyType != 'Gas'">
                                <td>
                                    <i class="material-icons"
                                        [ngClass]="(offer.includesDemandTariff != 0) ? 'includes-demand-tariff' : 'icon-disabled'">
                                        &#xE6E1;
                                    </i>
                                </td>
                                <td *ngIf="offer.includesDemandTariff != 0">
                                    Includes Demand Charge
                                </td>
                                <td *ngIf="offer.includesDemandTariff == 0">
                                    No Demand Charge
                                </td>
                            </tr>
                            <tr *ngIf="offer.isInnovativeOffer != false && energyType != 'Gas'">
                                <td>
                                    <i
                                        class="material-icons" tabindex="0"
                                        [ngClass]="(offer.isInnovativeOffer == true) ? 'contingentgasoffer' : 'icon-disabled'"
                                        [triggers]="utility.toolTipForMobile()"
                                        ngbTooltip="This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information."
                                        aria-label="This offer may incur additional costs that are not included in the estimated annual amount. Please refer to the offer details or contact the retailer for more information.">
                                       &#xE002;
                                    </i>
                                </td>
                                <td>
                                    Includes additional costs
                                </td>
                            </tr>
                            <tr *ngIf="offer.isGasOffer == true && offer.isContingentOffer == true">
                                <td>
                                    <i
                                        class="material-icons contingentgasoffer" tabindex="0"
                                        tooltipClass="contingentgasoffer-tip"
                                        [triggers]="utility.toolTipForMobile()"
                                        ngbTooltip="This offer is contingent upon having an electricity contract with the same retailer"
                                        aria-label="This offer is contingent upon having an electricity contract with the same retailer">
                                       &#xE002;
                                    </i>
                                </td>
                                <td>
                                    Contingent on electricity offer
                                </td>
                            </tr>
                            <tr *ngIf="offer.isGasOffer == false && offer.isContingentOffer == true">
                                <td>
                                    <i
                                        class="material-icons contingentgasoffer" tabindex="0"
                                        tooltipClass="contingentgasoffer-tip"
                                        [triggers]="utility.toolTipForMobile()"
                                        ngbTooltip="This offer is contingent upon having a gas contract with the same retailer"
                                        aria-label="This offer is contingent upon having a gas contract with the same retailer">
                                       &#xE002;
                                    </i>
                                </td>
                                <td>
                                    Contingent on gas offer
                                </td>
                            </tr>
                            <tr *ngIf="offer.annualFeeCount > 0">
                                <td>
                                    <i
                                        class="material-icons annual-fee"
                                        [ngClass]="(offer.annualFeeCount > 1) ? 'annual-fee' : 'icon-disabled'">
                                        &#xe7f7;
                                    </i>
                                </td>
                                <td>
                                    Estimate includes an annual fee
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <span class="sub-header">
                            Offer Details
                        </span>
                        <table class="table offer-detail-table">
                            <tbody>
                            <tr>
                                <td>Offer ID:</td>
                                <td class="strong">
                                    {{ offer.offerId }}
                                </td>
                            </tr>
                            <tr>
                                <td>Customer type:</td>
                                <td class="strong">
                                    {{ (offer.isResidential == true) ? 'Residential' : '' }}
                                    {{ (offer.isSme == true && offer.isResidential == true) ? ', ' : '' }}
                                    {{ (offer.isResidential == false) ? 'Business' : '' }}
                                </td>
                            </tr>
                            <tr>
                                <td>Distributor:</td>
                                <td class="strong">
                                    {{ offer.distributor }}
                                </td>
                            </tr>
                            <tr>
                                <td>Rate type:</td>
                                <td class="strong">
                                    {{ offer.tariffType }}
                                </td>
                            </tr>
                            <tr>
                                <td>Offer type:</td>
                                <td class="strong">
                                    {{ offer.offerType }}
                                </td>
                            </tr>
                            <tr>
                                <td>Release date:</td>
                                <td class="strong">
                                    {{ offer.releaseDate }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offer-section-no-bg">
                <br *ngIf="offer.solarCredit <= 0"/>
                <div class="row solar-credit-display" *ngIf="offer.solarCredit > 0">
                    <p class="col-md-12 no-padding font-weight-bold">
                        Estimate includes solar credit of
                        <span class="strong">${{ offer.solarCredit | number : '1.2-2' }}</span>
                    </p>
                    <br />
                    <br />
                </div>
                <div>
                    <p>

                        <span *ngIf="dataService.getSorting() == true">
                            <span class="currency">
                              <span class="price-sign">$</span>{{ Math.abs(offer.totalConditionalPrice) | number }}
                            </span>
                            <sup *ngIf="offer.totalConditionalPrice < 0" class="strong">
                                CR
                            </sup>
                        </span>
                        <span *ngIf="dataService.getSorting() == false">
                            <span class="currency">
                              <span class="price-sign">$</span>{{ Math.abs(offer.totalBasePrice) | number }}
                            </span>
                            <sup *ngIf="offer.totalBasePrice < 0" class="strong">
                                CR
                            </sup>
                        </span>
                        <sub class="dual-per-year">/ Est. yearly price</sub>
                    </p>
                    <div
                        *ngIf="offer.includesDemandTariff != 0 && fileUpload == false && getMyData == false"
                        class="includes-demand-tariff includes-tariff-notice full-width center-text"
                        tooltipClass="includes-demand-tariff-tip"
                        placement="top"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you."
                        aria-label="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.">
                        <br />
                        <span class="plus">+</span>
                        demand charge
                    </div>
                </div>
                <div class="section-separator-bottom">
                    <p>
                        <br class="spacer"/>
                        <span *ngIf="dataService.getSorting() == true">
                            Without conditional discounts
                        </span>
                        <span *ngIf="dataService.getSorting() == false">
                            With conditional discounts
                        </span>
                        <span class="strong">
                            <span *ngIf="dataService.getSorting() == true">
                                <span>
                                    ${{ Math.abs(offer.totalBasePrice) | number }}
                                </span>
                                <span *ngIf="offer.totalBasePrice < 0">
                                    CR
                                </span>
                            </span>
                            <span *ngIf="dataService.getSorting() == false">
                                <span>
                                    ${{ Math.abs(offer.totalConditionalPrice) | number }}
                                </span>
                                <span *ngIf="offer.totalConditionalPrice < 0">
                                    CR
                                </span>
                            </span>
                        </span>
                        <br />
                        <br />
                    </p>
                </div>
                <div>
                    <span class="what-next-heading">Ready to switch to this offer?</span>
                    <i class="material-icons hidden-label-email"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="Click here to find out the how to contact the retailer and for information on how to switch to this offer."
                        aria-label="Click here to find out the how to contact the retailer and for information on how to switch to this offer.">info</i>
                    <br />
                    <button
                        tabindex="4"
                        class="what-next-button what-next-button-text"
                        (click)="contactRetailer()">
                        Contact {{ offer.retailerName }}
                    </button>

                    <br /><br /><br />
                    <span class="what-next-heading">Do you have any questions?</span>
                    <i class="material-icons hidden-label-email"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="Do you have further questions? Click here to contact the Victorian Energy Compare support team."
                        aria-label="Do you have further questions? Click here to contact the Victorian Energy Compare support team.">info</i>
                    <br />
                    <button
                        tabindex="5"
                        class="what-next-button what-next-button-text"
                        (click)="contactVEC()">
                        Contact Victorian Energy Compare
                    </button>
                </div>
            </div>
        </div>

        <div class="row view-offer-section2">
            <div class="col-lg-8 border-side-right">
                <div class="row contract-header">
                    <span class="sub-title">
                        <br />
                        Contract terms: Notices, availability and incentives
                    </span>
                </div>
                <div class="row">
                    <div class="row table-responsive">
                        <table
                            class="table table-striped contract-table"
                            *ngIf="offer.contractLength.length > 0">
                            <tr>
                                <td width="30%" class="strong">Contract term</td>
                                <td>
                                    {{ (offer.contract.offer_term_of_contract == 'None') ? 'No lock-in contract' : offer.contract.offer_term_of_contract }}
                                </td>
                            </tr>
                            <tr>
                                <td class="strong">Cooling off period</td>
                                <td>
                                    {{ (offer.coolOffPeriod == '') ? "N/A" : offer.coolOffPeriod }}
                                </td>
                            </tr>
                            <tr>
                                <td class="strong">Eligibility criteria</td>
                                <td>
                                    {{ offer.contract.offer_eligibility_clause }}
                                </td>
                            </tr>
                            <tr>
                                <td class="strong">Full terms and conditions</td>
                                <td>
                                    {{ offer.contract.terms_and_conditions }}
                                </td>
                            </tr>
                            <tr>
                                <td class="strong">Price changes</td>
                                <td>
                                    {{ (offer.contract.offer_var_of_prices == null || offer.contract.offer_var_of_prices == '') ? 'N/A' : offer.contract.offer_var_of_prices }}
                                </td>
                            </tr>
                            <tr>
                                <td class="strong">Contract expiry details</td>
                                <td>
                                    {{ offer.contract.offer_arr_on_expiry }}
                                </td>
                            </tr>
                        </table>
                        <table class="table table-striped contract-table">
                            <tr>
                                <td width="30%" class="strong">Incentives</td>
                                <td>
                                    <span *ngIf="offer.hasIncentive == false">
                                        N/A
                                    </span>
                                    <span *ngIf="offer.hasIncentive == true && offer.incentives.length > 0">
                                        <p *ngFor="let incentive of offer.incentives">
                                            <i>{{ incentive.incentive_name }}</i>
                                            {{ (incentive.incentive_conditions != null) ? ' - ' +  incentive.incentive_conditions : '' }}
                                            <br /><br />
                                        </p>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="energyType != 'Gas'">
                                <td class="strong">Avail. to solar customers</td>
                                <td>
                                    {{ (offer.isAvailableForSolar == true) ? 'Yes' : 'No' }}
                                </td>
                            </tr>
                            <tr>
                                <td class="strong">ESC licence information</td>
                                <td>
                                    {{ (offer.retailerFueltype == 'Both') ? 'Electricity and gas' : offer.retailerFueltype }}
                                    {{ (offer.retailerLicenseName == null || offer.retailerLicenseName == '') ? '' : ' retail licence under ' + offer.retailerLicenseName }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="row col-lg-12">
                        <span class="sub-title">
                            Discounts and fees
                            <br />
                        </span>
                    </div>

                    <div class="row col-lg-12 fees-details">
                        <div class="col-md-6">
                            <span class="sub-header">Discounts:</span>
                            <br />
                            <div *ngIf="offer.discounts.length > 0">
                                <div *ngFor="let discount of offer.discounts">
                                    <span class="strong">
                                        {{ discount.discount_type + ' ' }}
                                        <span *ngIf="discount.discount_amount == null || discount.discount_amount == 0">
                                            ({{ discount.discount_percentage }}%):
                                        </span>
                                        <span *ngIf="discount.discount_amount > 0">
                                            (${{ discount.discount_amount | number : '1.2-2' }}):
                                        </span>
                                        {{ discount.discount_application_type }}
                                    </span>
                                    <p>
                                        <br />
                                        {{ discount.discount_conditions }}
                                    </p>
                                    <p *ngIf="(discount.discount_type | lowercase) == 'unconditional'">
                                        <br />
                                        <i>* This guaranteed discount is included in the offer amount displayed.</i>
                                    </p>
                                    <br /><br />
                                </div>
                            </div>
                            <div *ngIf="offer.discounts.length < 1">
                                N/A
                            </div>
                        </div>
                        <div class="col-md-6">
                            <span class="sub-header">Fees</span>
                            <div *ngIf="offer.fees.length < 1">
                                N/A
                            </div>
                            <div *ngIf="offer.fees.length > 0">
                                <div *ngFor="let fee of offer.fees">
                                    <p class="strong">
                                        {{ fee.fee_type }}
                                    </p>
                                    <p *ngIf="fee.fee_description != '' && fee.fee_description != null">
                                        <br />
                                        {{ fee.fee_description }}
                                        <br />
                                    </p>

                                    <p *ngIf="fee.fee_term != ''">
                                        <br />
                                        {{ fee.fee_term }}:

                                        <span *ngIf="fee.fee_percentage <= 0">
                                            {{ fee.fee_amount | currency:'USD':'symbol':'1.2-2' }}
                                        </span>
                                        <span *ngIf="fee.fee_percentage > 0">
                                            {{  fee.fee_percentage + "%" }}
                                        </span>
                                    </p>

                                    <p *ngIf="fee.offer_add_info != '' && fee.offer_add_info != null">
                                        <br />
                                        <strong>Additional Fee Information</strong>
                                        <br /><br />
                                        {{ fee.offer_add_info }}
                                    </p>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="offer-rate-header col-md-12">
                    <br />
                    <span class="sub-title">
                        Offer rate and details
                    </span>
                    <br/>
                    <span *ngIf="energyType != 'Gas'">
                        {{ offer.timeDefinition }}
                    </span>
                    <span *ngIf="energyType == 'Gas'">
                        AEST only
                    </span>
                </div>

                <!-- season start-->
                <div *ngFor="let index=index; let season of offer.tariffDetails">
                    <div class="offer-rate-header">
                        <br />
                        <p>
                            {{ season.season_name }}
                        </p>
                        <p class="read-only" *ngIf="season.tariff_applicable_from != '' && season.tariff_applicable_to != ''">
                            {{ utility.convertDate(season.tariff_applicable_from) + " - " + utility.convertDate(season.tariff_applicable_to) }}
                        </p>
                        <!--
                        <p class="read-only" *ngIf="season.tariff_applicable_from == '' && season.tariff_applicable_to == ''">
                            1 January - 31 December
                        </p>
                        -->
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                            <p class="read-only tariff-heading">
                                {{ (offer.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                            </p>
                        </div>
                    </div>

                    <div class="row supply-charge">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            Supply charges
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 value">
                            <p>
                                <span *ngIf="offer.includeGSTRates == true">
                                    {{ ((season.tariff_daily_charge * 1) + (season.tariff_daily_charge * (offer.GSTPercent / 100))) | number : '1.2-2' }}
                                </span>
                                <span *ngIf="offer.includeGSTRates == false">
                                    {{ season.tariff_daily_charge | number : '1.2-2' }}
                                </span>
                                &cent;/day
                            </p>
                        </div>
                    </div>

                    <div *ngFor="let tariff of season.TARIFFS">
                        <div class="row rate-charge" *ngIf="season.TARIFFS.length == 1">
                            <div class="col-md-12">
                                Flat
                            </div>
                        </div>
                        <div class="row rate-charge" *ngIf="season.TARIFFS.length > 1">
                            <div class="col-md-12">
                                {{ (tariff.tariff_type == '' || tariff.tariff_type == null) ? tariff.tariff_name : tariff.tariff_type }}
                            </div>
                        </div>

                        <!--time bands-->
                        <div class="row rate-separator no-border" *ngIf="season.TARIFFS.length > 1">
                            <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                            </div>
                        </div>

                        <!--rates start-->
                        <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                            <div class="row rate-charge rate-separator">
                                <div class="col-md-6 mid-padding season-rate">
                                    <div *ngIf="tariff.RATES.length > 1">
                                        <span *ngIf="rate.desc != 'Balance'">
                                            {{ rate.desc }}
                                            {{ rate.desc_rate_block_consumption | number : '1.0-2' }}
                                            {{ energyType == 'Gas' ? 'MJ' : 'kWh' }} per {{ season.tariff_cons_prd }}
                                        </span>
                                        <span *ngIf="rate.desc == 'Balance'">
                                            {{ rate.desc }}
                                        </span>
                                    </div>
                                    <div *ngIf="tariff.RATES.length == 1">
                                        All consumption
                                    </div>
                                </div>
                                <div class="col-md-6 mid-padding value strong">
                                    <span>
                                        <span *ngIf="offer.includeGSTRates == true">
                                            {{ ((rate.rate_block * 1) + (rate.rate_block * (offer.GSTPercent / 100))) | number : '1.2-2' }}
                                        </span>
                                        <span *ngIf="offer.includeGSTRates == false">
                                            {{ rate.rate_block | number : '1.2-2' }}
                                        </span>
                                        {{ energyType == 'Gas' ? '¢/MJ' : '¢/kWh' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <!--rates end-->

                        <!--demand start-->
                        <div *ngIf="season.DEMAND !== undefined" class="demand">
                            <br />
                            <div class="row">
                                <div class="col-md-12">
                                    {{ (fileUpload == true || getMyData == true) ? 'Demand Charge' : 'Demand Charge (not included in estimate)'}}
                                </div>
                            </div>
                            <!--demand days-->
                            <div class="row" *ngIf="season.DEMAND.tariff_weekdays == true">
                                <div class="col-md-12 season-rate">
                                    Weekdays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <div class="row" *ngIf="season.DEMAND.tariff_sat == true && season.DEMAND.tariff_sun == true">
                                <div class="col-md-12 season-rate">
                                    Weekends {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <div class="row" *ngIf="season.DEMAND.tariff_sat == true && season.DEMAND.tariff_sun == false">
                                <div class="col-md-12 season-rate">
                                    Saturdays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <div class="row" *ngIf="season.DEMAND.tariff_sat == false && season.DEMAND.tariff_sun == true">
                                <div class="col-md-12 season-rate">
                                    Sundays {{ utility.convertTime(season.DEMAND.time_band_start) }} - {{ utility.convertTime(season.DEMAND.time_band_end) }}
                                </div>
                            </div>
                            <!--demand days-->

                            <div class="row bottom-margin">
                                <div class="col-md-6 season-rate">
                                    Charge
                                </div>
                                <div class="col-md-6 value strong">
                                    {{ season.DEMAND.demand_charge + (season.DEMAND.demand_charge * (offer.GSTPercent / 100)) | number : '1.2-2' }}
                                    {{ energyType == 'Gas' ? '¢/MJ' : '¢/kWh' }} per
                                    {{ season.DEMAND.demand_charge_period | lowercase }}
                                </div>
                            </div>
                            <div class="row rate-line-border rate-line-border-top demand-padding">
                                <div class="col-md-12 no-padding">
                                    {{ (fileUpload == true || getMyData == true) ? 'You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.' : 'This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.' }}
                                </div>
                            </div>
                            <br />
                        </div>
                        <!--demand end-->
                    </div>
                </div>
                <!-- season end -->

                <!--controll load start-->
                <div *ngIf="offer.controlledLoadRate > 0">
                    <div class="row">
                        <div class="col-md-12 offer-rate-header">
                            Controlled Load
                            <br />
                            1 January - 31 December
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                            <p class="read-only tariff-heading">
                                {{ (offer.includeGSTRates == true) ? 'Tariff(inc. GST)' : 'Tariff(excl. GST)' }}
                            </p>
                        </div>
                    </div>
                    <div class="row bottom-margin rate-separator rate-charge">
                        <div class="col-md-6 season-rate no-padding">
                            All consumption
                        </div>
                        <div class="col-md-6 value strong no-padding">
                            <span *ngIf="offer.includeGSTRates == true">
                                {{ ((offer.controlledLoadRate * 1) + (offer.controlledLoadRate * (offer.GSTPercent / 100))) | number : '1.2-2' }}
                            </span>
                            <span *ngIf="offer.includeGSTRates == false">
                                {{ offer.controlledLoadRate | number : '1.2-2' }}
                            </span>
                            ¢/kWh
                        </div>
                    </div>
                    <br /><br />
                </div>
                <!--controll load end-->

                <!-- greenpower start -->
                <div *ngIf="offer.greenPowerDisplay != false">
                    <div class="row col-md-12 offer-rate-header">
                        Green power
                    </div>
                    <div class="read-only no-padding col-md-12" *ngIf="offer.intrinsicGreenPowerDisplay != 0">
                        Annual total includes {{ offer.intrinsicGreenPowerDisplay }}% green power included in the offer rates
                        <br />
                    </div>
                    <div class="read-only no-padding col-md-12" *ngIf="offer.greenPower !== 0">
                        Annual total includes {{ offer.greenPower }}%
                        GreenPower option
                        <br />
                    </div>
                    <div class="row bottom-margin rate-separator rate-charge" *ngIf="offer.greenPower !== 0">
                        <div class="col-md-6 season-rate no-padding">
                            Charge
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 solar-value strong no-padding">
                            {{ offer.greenpowerAmount * 100 | number : '1.2-2' }}
                            <span *ngIf="offer.greenpowerChargeType == 'Amount per unit of consumption'" class="no-padding">
                                &cent;/kWh
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Weekly charge'" class="no-padding">
                                &cent;/Week
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Daily charge'" class="no-padding">
                                &cent;/Day
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Percentage of consumption'" class="no-padding">
                                &cent;/% of your total bill amount
                            </span>
                            <span *ngIf="offer.greenpowerChargeType == 'Percentage of bill amount'" class="no-padding">
                                &cent;/% of your consumption cost amount
                            </span>
                        </div>
                    </div>
                    <br /><br />
                </div>
                <!-- greenpower end -->

                <!-- solar -->
                <div *ngIf="offer.solar.length > 0">
                  <div *ngFor="let solar of offer.solar">

                    <!-- Current and Premium FiT -->
                    <div *ngIf="solar.rate">
                      <div class="col-md-12 offer-rate-header">
                        {{ solar.feed_in_tariff }}
                        <br />
                        1 January - 31 December
                      </div>
                      <div class="row bottom-margin rate-separator rate-charge">
                        <div class="col-md-6 season-rate no-padding">
                          All export
                        </div>
                        <div class="col-md-6 value strong no-padding">
                          {{ solar.rate | number : '1.2-2' }} ¢/kWh
                        </div>
                      </div>
                      <br /><br />
                    </div>

                    <!-- Solar variable FiT: Option 1 & 2 -->
                    <div *ngIf="solar.name === 'Variable FiT - Option 1' || solar.name === 'Variable FiT - Option 2'">
                      <div class="col-md-12 offer-rate-header">
                        {{ solar.name }}
                        <br />
                      </div>
                      <div *ngFor="let tariff of solar.TARIFFS">
                        <br />
                        {{tariff.tariff_type}}

                        <!--time bands-->
                        <div class="row rate-separator no-border" *ngIf="tariff.TIME_BANDS.length >= 1">
                          <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                          </div>
                        </div>
                        <div *ngIf="tariff.RATES.length > 1">
                          <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                            <div class="row rate-charge rate-separator">
                              <div class="col-md-7 mid-padding season-rate">
                                <div *ngIf="tariff.RATES.length > 1">
                                  <span *ngIf="rate.desc != 'Balance'">
                                    {{ rate.desc }} {{ rate.desc_rate_block_export
                                    | number : '1.0-2' }} kWh / {{solar.period}}
                                  </span>
                                  <span *ngIf="rate.desc == 'Balance'">
                                    {{ rate.desc }} / {{solar.period}}
                                  </span>
                                </div>
                                <div *ngIf="tariff.RATES.length == 1">All export</div>
                              </div>
                              <div class="col-md-5 mid-padding value strong">
                                <span>
                                  {{ rate.rate_block | number : '1.2-2' }} ¢/kWh
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="tariff.RATES.length == 1" class="row bottom-margin rate-separator rate-charge">
                          <div class="col-md-8 season-rate no-padding">
                            All export
                          </div>
                          <div class="col-md-4 value strong no-padding">
                            {{ tariff.RATES[0]["rate_block"] | number : '1.2-2' }} ¢/kWh
                          </div>
                        </div>

                      </div>
                      <br /><br />
                    </div>

                    <!-- Solar variable FiT: Custom with tiers -->
                    <div *ngIf="!solar.rate && (solar.feed_in_tariff === 'Current FIT policy' || solar.feed_in_tariff === 'Premium FIT [closed]') ">
                      <div class="col-md-12 offer-rate-header">
                        {{ solar.feed_in_tariff }}
                        <br />
                      </div>
                      <div *ngFor="let tariff of solar.TARIFFS">
                        <br />
                        {{tariff.tariff_type}}

                        <!--time bands-->
                        <div class="row rate-separator no-border" *ngIf="tariff.TIME_BANDS?.length >= 1">
                          <div class="col-md-12 mid-padding" [innerHTML]="utility.formatTimeBand(tariff.TIME_BANDS)">
                          </div>
                        </div>

                        <div class="tariff-separator" *ngFor="let index=index; let rate of tariff.RATES">
                          <div class="row rate-charge rate-separator">
                            <div class="col-md-7 mid-padding season-rate">
                              <div *ngIf="tariff.RATES.length > 1">
                                <span *ngIf="rate.desc != 'Balance'">
                                  {{ rate.desc }} {{ rate.desc_rate_block_export
                                  | number : '1.0-2' }} kWh / {{solar.period}}
                                </span>
                                <span *ngIf="rate.desc == 'Balance'">
                                  {{ rate.desc }} / {{solar.period}}
                                </span>
                              </div>
                              <div *ngIf="tariff.RATES.length == 1">All export</div>
                            </div>
                            <div class="col-md-5 mid-padding value strong">
                              <span>
                                {{ rate.rate_block | number : '1.2-2' }} ¢/kWh
                              </span>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>

                  </div>
                </div>

            </div>
        </div>
    </div>
</div>
