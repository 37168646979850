import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { Utility } from '../utility';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RetailerInfoModalComponent } from '../retailer-info-modal/retailer-info-modal.component';
import { OfferInfoModalComponent } from '../offer-info-modal/offer-info-modal.component';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { environment } from '../../environments/environment'; //config file
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-loopback-compare',
  templateUrl: './loopback-compare.component.html',
  styleUrls: ['./loopback-compare.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class LoopBackCompareComponent implements OnInit {
  public electricityIsVisible = true;
  public Math = Math;
  public secBranding = environment.secBranding;
  public emailSecurityKey = environment.emailSecurityKey;
  public appURL = environment.appURL;
  public selectedOffers = [];
  public dualOffers;
  public gasOffers;
  public gasOffer;
  public gasOfferVisibility = [false, false, false];
  public checkedOffers = [];
  public columnSizes = 0;
  public seasons = [];
  public showRates = true;
  public retailers = [];
  public distributor = '';
  public gst = environment.gst;
  public infoModalShown = false;
  public sortByConditionalPrice = true;
  public fileUpload = false;
  public getMyData = false;

  public hasConcession;
  public bill;
  public averageDailyConsumption;
  public loopBackAverageDailyConsumption;
  public loopBackEnergyType;
  public energyType;
  public loopBackIsEmpty = false;

  constructor(
    public spinner : NgxSpinnerService,
    public utility : Utility,
    public modalService : NgbModal,
    public dataService : DataService,
    public router : Router,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
  }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Results - Victorian Energy Compare';
    this.modalService.dismissAll();
    var serverCacheId = localStorage.getItem("serverCacheId");
    //if serverCacheId is defined get saved data from the server
    if (serverCacheId != null) {
      this.distributor = this.dataService.getDistributor();
      this.gasOffers = this.dataService.getGasOffers();
      this.dualOffers = this.dataService.getDualOffers();
      this.selectedOffers = this.dataService.getSelectedOffers();
      if (this.selectedOffers.length < 1) {
        //redirect to home page
        this.router.navigate(['/offers/list/dual']);
      } else {
        let ctr = 0;
        while (ctr < this.selectedOffers.length) {
          let offer = this.dualOffers.offersList[ctr];
          offer.gasOffer = this.gasOffers.offersList[offer.gasOfferIndex];
          offer.offerIndex = ctr;
          offer.totalConditionalPrice = offer.totalConditionalPrice * 1;
          offer.totalBasePrice = offer.totalBasePrice * 1;
          offer.bundleTotalConditionalPrice = (offer.totalConditionalPrice * 1) + (offer.gasOffer.totalConditionalPrice * 1);
          offer.bundleTotalBasePrice = (offer.totalBasePrice * 1) + (offer.gasOffer.totalBasePrice * 1);
          this.checkedOffers.push(offer);
          ctr++;
        }
        this.dualOffers = null;
        this.retailers = this.dataService.getRetailers();
        this.columnSizes = 12 / this.selectedOffers.length;
        this.sortByConditionalPrice = this.dataService.getSorting();
        if (this.sortByConditionalPrice == true) {
          //Use Lodash to sort object by totalConditionalPrice
          this.checkedOffers = _.orderBy(this.checkedOffers, ['bundleTotalConditionalPrice', 'asc']);
        } else {
          this.checkedOffers = _.orderBy(this.checkedOffers, ['bundleTotalBasePrice', 'asc']);
        }
        this.gasOffers = null; //remove data to free some memory

        this.formatRates(); //format electricity rates
        this.formatRates(true); //format gas rates
        this.spinner.show();
        this.dataService.getSavedData(serverCacheId).subscribe(
          data => {
            if (data.existing == false) {
              this.utility.sessionRestart();
              this.utility.redirectToHome();
              return false;
            }

            //log journey progress
            this.utility.logJourneyProgress('compared_offers');

            //get form data from dataService
            this.hasConcession = data.energyConfigData.hasConcession;
            this.bill = data.energyConfigData.bill;
            this.averageDailyConsumption = data.energyConfigData.averageDailyConsumption;
            let measurement = (data.energyConfigData.energyType == 'Gas') ? 'MJ' : 'kWh';
            this.averageDailyConsumption += measurement;

            if (data.loopBackData.energyConfigData.hasOwnProperty('energyType') == false) {
              this.loopBackIsEmpty = true;
            } else { //there is loopback data update the average consumption
              this.loopBackEnergyType = data.loopBackData.energyConfigData.energyType;
              this.loopBackAverageDailyConsumption = data.loopBackData.energyConfigData.averageDailyConsumption;
              measurement = (this.loopBackEnergyType == 'Gas') ? 'MJ' : 'kWh';
              this.loopBackAverageDailyConsumption += measurement;
            }

            //check if the user has uploaded a consumption file
            if (data.energyConfigData.energyType == 'Electricity') {
              if (data.energyConfigData.usageProfile == 1) {
                this.getMyData = true;
              } else if (data.energyConfigData.usageProfile == 2) {
                this.fileUpload = true;
              }
            }
            if (data.loopBackData.energyConfigData.hasOwnProperty('energyType') == true && data.loopBackData.energyConfigData.energyType == 'Electricity') {
              if (data.loopBackData.energyConfigData.usageProfile == 1) {
                this.getMyData = true;
              } else if (data.loopBackData.energyConfigData.usageProfile == 2) {
                this.fileUpload = true;
              }
            }

            if (this.router.url == '/compare') {
              this.energyType = data.energyConfigData.energyType;
            } else if (this.router.url == '/compare/gas') {
              this.energyType = 'Gas';
            } else if (this.router.url == '/compare/electricity') {
              this.energyType = 'Electricity';
            } else { //dual
              this.energyType = 'dual';
            }
            this.spinner.hide();
          },
          error => {
            this.spinner.hide();
          }
        );
      }
    } else {
      //redirect to home page
      this.utility.redirectToHome();
    }
  }

  private formatRates(isGasOffer = false) {
    let offersCtr = 0;
    for (let offer of this.checkedOffers) {
      if (isGasOffer == true) {
        offer = offer.gasOffer;
      }
      let tariffDetails = this.utility.formatTariffs(offer.tariffDetails);
      offer.tariffDetails = tariffDetails;
      if (isGasOffer == true) {
        this.checkedOffers[offersCtr].gasOffer = offer;
      } else {
        this.checkedOffers[offersCtr] = offer;
      }
      offersCtr++;
    }
  }

  public toggleShowRates() {
    this.showRates = !this.showRates;
  }

  public email() {
    this.showRates = true;
    let modalRef = this.modalService.open(EmailModalComponent, {size : 'xs'});
    modalRef.componentInstance.headerTitle = 'Email offer results';
    GA4LogEvent('Dual Energy', 'EMAIL');
  }

  public print() {
    this.showRates = true;
    setTimeout(()=> {
      window.print();
    }, 500);
    GA4LogEvent('Dual Energy', 'PRINT');
  }

  public back() {
    this.router.navigate(['/offers/list/dual']);
  }

  public viewRetailer(retailerId) {
    this.utility.scrollToTop('none');
    let selectedRetailer = _.find(this.retailers, { Id : retailerId } );
    let modalRef = this.modalService.open(RetailerInfoModalComponent, {size : 'xl'});
    modalRef.componentInstance.retailer = selectedRetailer;
  }

  public viewOffer(offerIndex) {
    this.dataService.setSelectedEnergyType('Electricity');
    this.utility.scrollToTop('none');
    let modalRef = this.modalService.open(OfferInfoModalComponent, { size : 'xl' });
    modalRef.componentInstance.offer = this.checkedOffers[offerIndex];
    modalRef.componentInstance.returnPage = this.router.url;
    modalRef.componentInstance.fileUpload = this.fileUpload;
    modalRef.componentInstance.getMyData = this.getMyData;
    this.infoModalShown = true;
    modalRef.result.then((result) => {
      this.infoModalShown = false;
    }, (reason) => {
    });
  }

  public viewGasOffer(offerIndex) {
    this.dataService.setSelectedEnergyType('Gas');
    this.utility.scrollToTop('none');
    let modalRef = this.modalService.open(OfferInfoModalComponent, { size : 'xl' });
    modalRef.componentInstance.offer = this.checkedOffers[offerIndex].gasOffer;
    modalRef.componentInstance.returnPage = this.router.url;
    modalRef.componentInstance.fileUpload = false;
    modalRef.componentInstance.getMyData = false;
    this.infoModalShown = true;
    modalRef.result.then((result) => {
      this.infoModalShown = false;
    }, (reason) => {
    });
  }

  public contactRetailer(offerIndex) {
    //set return page
    this.dataService.setReturnPage('loopback/compare');
    this.utility.scrollToTop('none');
    let offer = this.checkedOffers[offerIndex];
    this.dataService.setReturnPage(this.router.url);
    this.dataService.setSelectedEnergyType('Electricity');
    this.dataService.setSelectedOffer(offer);
    this.spinner.show();
    this.router.navigate(['/contact']);
    setTimeout(()=> {
      this.spinner.hide();
    }, 1000);
  }

  public contactGasRetailer(offerIndex) {
    //set return page
    this.dataService.setReturnPage('loopback/compare');
    this.utility.scrollToTop('none');
    let offer = this.checkedOffers[offerIndex].gasOffer;
    this.dataService.setSelectedEnergyType('Gas');
    this.dataService.setSelectedOffer(offer);
    this.spinner.show();
    this.router.navigate(['/contact']);
    setTimeout(()=> {
      this.spinner.hide();
    }, 1000);
  }

  public toggleGasOffer(offerIndex, value) {
    this.gasOfferVisibility[offerIndex] = value;
  }

  public toggleElectricityOffer(value) {
    this.electricityIsVisible = value;
  }
}
