import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment'; //config file
import { NgxSpinnerService } from "ngx-spinner";
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { RateDetailsModalComponent } from '../rate-details-modal/rate-details-modal.component';
import { DualRateDetailsModalComponent } from '../dual-rate-details-modal/dual-rate-details-modal.component';
import { Utility } from '../utility';
import { Router } from "@angular/router";
import { DataService } from '../data.service';
import * as Bowser from "bowser";

@Component({
  selector: 'app-offers-header-panel',
  templateUrl: './offers-header-panel.component.html',
  styleUrls: ['./offers-header-panel.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class OffersHeaderPanelComponent implements OnInit {
  public appURL = environment.appURL;
  public dualRatesData = {
    loopBack : false,
    energyType : "",
    hasSolar : false,
    feedInTariff: "",
    billStartDate: "",
    billEndDate: "",
    retailer: "",
    retailerName: "",
    offPeak: "",
    offPeakRate: "",
    controlledLoadRate: "",
    peakRate: "",
    solarFitRate: "",
    shoulder: "",
    shoulderRate: "",
    supplyCharge: "",
    discount: "",
    discountAmount: "",
    discountType: "",
    rateUnit : '$',
    supplyRateUnit : '$',
    //gas
    gasBillStartDate: "",
    gasBillEndDate: "",
    gasRetailer: "",
    gasRetailerName: "",
    totalCharge: "",
    gasSupplyCharge: "",
    gasDiscount: "",
    gasDiscountAmount: "",
    gasDiscountType: "",
    gasRateUnit : '$',
    supplyGasRateUnit : '$',
    serverCacheId: ""
  }
  public gasSupplyDays = 0;

  @Input() eapData = {};
  @Input() eapPage = false;
  @Input() psbPage = false;
  @Input() distributorClickThrough = false;
  @Input() sorting = {};
  @Input() offerTypes = {};
  @Input() showTouOffer = false;
  @Input() loopBackIsEmpty;
  @Input() loopBackHasOffers;
  @Input() showSingleRate = true;
  @Input() showFlexiblePricing = true;
  @Input() showTopOffer = true;
  @Input() showFilters = true;
  @Input() sortByConditionalPrice = true;
  @Input() hasConcession;
  @Input() userType;
  @Input() bill;
  @Input() averageDailyConsumption;
  @Input() loopBackAverageDailyConsumption;
  @Input() loopBackEnergyType;
  @Input() energyType;
  @Output() resetResult = new EventEmitter();
  @Output() groupOffers = new EventEmitter();
  @Output() updateFilters = new EventEmitter();
  @Output() toggleFilters = new EventEmitter();
  @Input() topOfferRate;
  @Input() offersHasSolar = false;
  @Input() mostExpensiveOfferRate;
  @Input() showOfferTypesFilter = false;
  private supplyDays = 0;
  public ratesError = false;
  public lowestRate = 0;
  public highestRate = 0;
  public totalConditional = 0;
  public showRates = false;
  private checkThreshold = true;
  private oneDay = 86400000;
  private submittedRates = false;
  private fetchedWAIP = false;
  private fetchedWAIPGas = false;

  public billRates = {
    "offerType" : '',
    "retailerImageUrl" : '',
    "retailerName" : '',
    "gasRetailerImageUrl" : '',
    "gasRetailerName" : '',
    "totalConditional" : 0,
    "totalUnconditional" : 0,
    "totalGasConditional" : 0,
    "totalGasUnconditional" : 0,
    "components" : {
      "solar" : 0,
      "controlledLoad" : 0,
    }
  };

  public Math = Math;
  public browser;

  constructor(public utility : Utility,
    public router : Router,
    public config : NgbModalConfig,
    public dataService : DataService,
    public spinner : NgxSpinnerService,
    public modalService : NgbModal,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
  }

  ngOnInit() {
    this.browser = Bowser.getParser(window.navigator.userAgent);
  }

  ngOnChanges(changes: any) {
    const isEapPage = this.eapPage === true;
    const hasKogbillResponse = this.eapData["kogbillResponse"] !== null;
    const isGas = this.eapData["energyType"] === 'Gas';
    const isElectricity = this.eapData["energyType"] === 'Electricity';
    const isDualUrl = this.router.url === '/offers/list/dual';

    if (isEapPage) {
      if (hasKogbillResponse) {
        if (!this.fetchedWAIPGas && isGas) {
          this.fetchGasWAIPForKogbill();
          this.fetchedWAIPGas = true;
        } else if (!this.fetchedWAIP && isElectricity) {
          this.fetchWAIPForKogbill();
          this.fetchedWAIP = true;
        }
      } else if (!this.submittedRates) {
        this.submitRates();
      }
    }

    if (this.psbPage && !this.fetchedWAIP) {
      this.fetchWAIPForKogbill();
    }

    if (this.mostExpensiveOfferRate > 0 || isDualUrl) {
      this.getRates();
    }
  }

  public reset() {
    this.resetResult.emit();
    this.utility.scrollToTop('top');
  }

  public updateFilter() {
    this.showFilters = !this.showFilters;
    this.toggleFilters.emit(this.showFilters);
  }

  public renderClass() {
    if (this.billRates.components.solar > 0) {
      return 'full-width';
    }
    if (this.energyType == 'Gas') {
      return 'full-width remove-row-margin';
    }

    if (this.offersHasSolar == true) {
      return 'full-width';
    }
    return '';
  }

  public renderRateClass() {
    let CSSClass = '';
    if (this.billRates.components.solar != 0 || this.energyType == 'Gas' || (this.offersHasSolar == true && this.billRates.components.solar == 0)) {
      CSSClass += ' no-padding';
    }
    if (this.billRates.components.solar == 0 && this.energyType != 'Gas') {
      if (this.offersHasSolar == false) {
        CSSClass += '  margin-left';
      }
    }
    return CSSClass;
  }

  public updateHeaderFilters(filterName) {
    let browserDetails = this.browser.getBrowser();
    let browserName = browserDetails.name;
    let value = false;
    if (filterName == "showTopOffer") {
      if (browserName != 'Internet Explorer') {
        this.showTopOffer = !this.showTopOffer;
      }
      value = this.showTopOffer;
    } else if (filterName == "sortByConditionalPrice") {
      if (browserName != 'Internet Explorer') {
        this.sortByConditionalPrice = !this.sortByConditionalPrice;
      }
      value = this.sortByConditionalPrice;
    } else if (filterName == "showTouOffer") {
      if (browserName != 'Internet Explorer') {
        this.showTouOffer = !this.showTouOffer;
      }
      value = this.showTouOffer;
    } else if (filterName == "showSingleRate") {
      if (browserName != 'Internet Explorer') {
        this.showSingleRate = !this.showSingleRate;
      }
      value = this.showSingleRate;
    } else if (filterName == "showFlexiblePricing") {
      if (browserName != 'Internet Explorer') {
        this.showFlexiblePricing = !this.showFlexiblePricing;
      }
      value = this.showFlexiblePricing;
    }
    this.updateFilters.emit({ 'name' : filterName, 'value' : value });
  }

  public rateDetailsModal() {
    let loopBack = (this.energyType == this.loopBackEnergyType) ? true : false;
    this.disableButtons(true);
    if (this.router.url == '/offers/list/dual') {
      let modalRef = this.modalService.open(DualRateDetailsModalComponent, { keyboard: false, size : 'xl' });
      modalRef.result.then((billRates) => {
        if (Math.abs(billRates.totalConditional) > 0 && Math.abs(billRates.totalUnconditional) > 0) {
          this.billRates = billRates;
          this.showOfferType(this.billRates.offerType);
          this.showRates = true;
        }
        this.disableButtons(false);
      }, (reason) => {
        // on dismiss
      });
      modalRef.result.then(() => {}, () => { this.disableButtons(false); }); //enable buttons on click outside
    } else {
      let modalRef = this.modalService.open(RateDetailsModalComponent, { keyboard: false, size : 'xl' });
      modalRef.componentInstance.energyType = this.energyType;
      modalRef.componentInstance.loopBack = loopBack;
      modalRef.result.then((billRates) => {
        if (Math.abs(billRates.totalConditional) > 0 && Math.abs(billRates.totalUnconditional) > 0) {
          this.billRates = billRates;
          this.showOfferType(this.billRates.offerType);
          if (this.checkThreshold == true && this.eapPage == false && this.psbPage == false && this.distributorClickThrough == false) {
            this.totalConditional = Math.abs(billRates.totalConditional);
            this.ratesError = (this.totalConditional > (parseInt(this.mostExpensiveOfferRate) + 100)) ? true : false;
          }
          this.showRates = true;
        }
        this.disableButtons(false);
      }, (reason) => {
        // on dismiss
      });
      modalRef.result.then(() => {}, () => { this.disableButtons(false); }); //enable buttons on click outside
    }
  }

  public changeEnergy(energyType) {
    this.router.navigate(['/offers/list/' + energyType]);
  }

  private disableButtons(disable) {
    var inputs = document.querySelectorAll('input');
    [].forEach.call(inputs, function(input) {
      input.disabled = disable;
    });

    var links = document.querySelectorAll('button');
    [].forEach.call(links, function(link) {
      link.disabled = disable;
    });
  }

  public showOfferType(offerType) {
    this.showOfferTypeFilter(offerType);
  }

  public showOfferTypeFilter(offerType) {
    this.updateFilters.emit({ 'name' : 'showOfferTypesFilter', 'value' : true });
    if (offerType == 'Flat') {
      this.updateFilters.emit({ 'name' : 'showTouOffer', 'value' : false });
      this.updateFilters.emit({ 'name' : 'showFlexiblePricing', 'value' : false });
      this.updateFilters.emit({ 'name' : 'showSingleRate', 'value' : true });
    } else if (offerType == 'Flexible') {
      this.updateFilters.emit({ 'name' : 'showTouOffer', 'value' : false });
      this.updateFilters.emit({ 'name' : 'showSingleRate', 'value' : false });
      this.updateFilters.emit({ 'name' : 'showFlexiblePricing', 'value' : true });
    } else {
      this.updateFilters.emit({ 'name' : 'showSingleRate', 'value' : false });
      this.updateFilters.emit({ 'name' : 'showFlexiblePricing', 'value' : false });
      this.updateFilters.emit({ 'name' : 'showTouOffer', 'value' : true });
    }
  }

  public ratePlaceholder() {
    let placeHolder = '';
    if (this.topOfferRate != 0) {
      let stringLength = this.Math.trunc(this.topOfferRate).toString().length;
      let ctr = 0;
      while (ctr < stringLength) {
        placeHolder += '_ ';
        ctr++;
      }
    }
    return placeHolder;
  }

  public getSupplyDays() {
    if (this.eapData["billStartDate"] != '' && this.eapData["billEndDate"] != '') {
      let billStartDate = [];
      let billEndDate = [];
      billStartDate = this.eapData["billStartDate"].split("/");
      billEndDate = this.eapData["billEndDate"].split("/");
      let date1 : any = new Date(billStartDate[2], billStartDate[1] - 1, billStartDate[0]);
      let date2 : any = new Date(billEndDate[2], billEndDate[1] - 1, billEndDate[0]);
      this.supplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.supplyDays = (this.supplyDays < 0) ? 0 : this.supplyDays + 1;
    } else {
      this.supplyDays = 0;
    }
  }

  public submitRates() {
    if (this.eapData) {
      if (this.eapData["billStartDate"] != undefined && this.eapData["billEndDate"] != undefined && this.submittedRates == false) {
        this.eapData["serverCacheId"] = localStorage.getItem("serverCacheId");
        this.getSupplyDays();
        this.dataService.getBillRates(this.eapData, this.supplyDays).subscribe(
          data => {
            this.submittedRates = true;
            let status = data["status"];
            if (status == "success") {
              this.showRates = true;
              this.billRates = data["offers"][0]["results"]["result"];
              this.totalConditional = Math.abs(data["offers"][0]["results"]["result"]["totalConditional"]);
              //this.ratesError = (this.totalConditional > (parseInt(this.mostExpensiveOfferRate) + 100)) ? true : false;
              this.ratesError = false;
            } else {
              if (status == 'redirect') {
                window.location.reload();
              }
            }
          },
          error => {
            this.submittedRates = true;
          }
        );
      }
    }
  }

  public fetchWAIPForKogbill() {
    let psbData = {}
    psbData["serverCacheId"] = localStorage.getItem("serverCacheId");
    this.dataService.fetchWAIPForKogbill(psbData, this.supplyDays).subscribe(
      data => {
        this.fetchedWAIP = true;
        let status = data["status"];
        if (status == "success") {
          this.showRates = true;
          this.billRates = data["offers"][0]["results"]["result"];
        } else {
          if (status == 'redirect') {
            window.location.reload();
          }
        }
      },
      error => {
        this.fetchedWAIP = true;
      }
    );
  }

  public fetchGasWAIPForKogbill() {
    let psbData = {}
    psbData["serverCacheId"] = localStorage.getItem("serverCacheId");
    this.dataService.fetchGasWAIPForKogbill(psbData, this.supplyDays).subscribe(
      data => {
        this.fetchedWAIPGas = true;
        let status = data["status"];
        if (status == "success") {
          this.showRates = true;
          this.billRates = data["offers"][0]["results"]["result"];
        } else {
          if (status == 'redirect') {
            window.location.reload();
          }
        }
      },
      error => {
        this.fetchedWAIPGas = true;
      }
    );
  }

  public getRates() {
    if (this.router.url == '/offers/list/electricity' || this.router.url == '/offers/list/gas' || this.router.url == '/offers/list/dual') {
      var serverCacheId = localStorage.getItem("serverCacheId");
      this.dualRatesData.serverCacheId = serverCacheId;
      //if serverCacheId is defined get saved data from the server
      if (serverCacheId != null) {
        this.dataService.getSavedData(serverCacheId).subscribe(
          data => {
            if (data.hasOwnProperty('billRatesForm') == true || data.loopBackData.hasOwnProperty('billRatesForm') == true) {
              if (data.existing == false) {
                this.utility.sessionRestart();
                this.utility.redirectToHome();
              }
              if (this.router.url == '/offers/list/dual') { //get dual rates
                this.processDualRates(data);
                this.submitDualRates();
              } else { //get individual energy rates
                this.eapData = (this.router.url == '/offers/list/electricity') ? data.billRatesForm : data.loopBackData.billRatesForm;
                this.submitRates();
              }
            }
          },
          error => {
            this.spinner.hide();
          }
        );
      } else {
        this.utility.redirectToHome();
      }
    }
  }

  private processDualRates(results) {
    this.dualRatesData.hasSolar = results.hasSolar;
    this.dualRatesData.feedInTariff = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.feedInTariff : results.loopBackData.energyConfigData.feedInTariff;
    this.dualRatesData.retailer = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.retailer : results.loopBackData.energyConfigData.retailer;
    this.dualRatesData.retailerName = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.retailerName : results.loopBackData.energyConfigData.retailerName;
    this.dualRatesData.gasRetailer = (results.energyConfigData.energyType == 'Gas') ? results.energyConfigData.retailer : results.loopBackData.energyConfigData.retailer;
    this.dualRatesData.gasRetailerName = (results.energyConfigData.energyType == 'Gas') ? results.energyConfigData.retailerName : results.loopBackData.energyConfigData.retailerName;
    this.dualRatesData.rateUnit = (results.energyConfigData.energyType == 'Electricity') ? results.energyConfigData.rateUnit : results.loopBackData.energyConfigData.rateUnit;
    this.userType = results.energyConfigData.userType;
    //get rates for electricity
    if (results.hasOwnProperty('billRatesForm') == true && results.loopBackData.hasOwnProperty('billRatesForm') == true) {
      let billRatesData;
      if (results.billRatesForm.energyType == 'Electricity') {
        billRatesData = results.billRatesForm;
      }
      if (results.loopBackData.billRatesForm.energyType == 'Electricity') {
        billRatesData = results.loopBackData.billRatesForm;
      }

      if (billRatesData != undefined) {
        this.dualRatesData.hasSolar = billRatesData.hasSolar;
        this.dualRatesData.feedInTariff = billRatesData.feedInTariff;
        this.dualRatesData.billStartDate = billRatesData.billStartDate;
        this.dualRatesData.billEndDate = billRatesData.billEndDate;
        this.dualRatesData.offPeak = billRatesData.offPeak;
        this.dualRatesData.offPeakRate = billRatesData.offPeakRate;
        this.dualRatesData.controlledLoadRate = billRatesData.controlledLoadRate;
        this.dualRatesData.peakRate = billRatesData.peakRate;
        this.dualRatesData.solarFitRate = billRatesData.solarFitRate;
        this.dualRatesData.shoulder = billRatesData.shoulder;
        this.dualRatesData.shoulderRate = billRatesData.shoulderRate;
        this.dualRatesData.supplyCharge = billRatesData.supplyCharge;
        this.dualRatesData.discount = billRatesData.discount;
        this.dualRatesData.discountAmount = billRatesData.discountAmount;
        this.dualRatesData.discountType = billRatesData.discountType;
        this.getDualSupplyDays();
      }
    }

    //get rates for gas
    if (results.hasOwnProperty('billRatesForm') == true && results.loopBackData.hasOwnProperty('billRatesForm') == true) {
      let gasBillRatesData;
      if (results.billRatesForm.energyType == 'Gas') {
        gasBillRatesData = results.billRatesForm;
      }
      if (results.loopBackData.billRatesForm.energyType == 'Gas') {
        gasBillRatesData = results.loopBackData.billRatesForm;
      }

      if (gasBillRatesData != undefined) {
        this.dualRatesData.gasBillStartDate = gasBillRatesData.billStartDate;
        this.dualRatesData.gasBillEndDate = gasBillRatesData.billEndDate;
        this.dualRatesData.totalCharge = gasBillRatesData.totalCharge;
        this.dualRatesData.gasSupplyCharge = gasBillRatesData.supplyCharge;
        this.dualRatesData.gasDiscount = gasBillRatesData.discount;
        this.dualRatesData.gasDiscountAmount = gasBillRatesData.discountAmount;
        this.dualRatesData.gasDiscountType = gasBillRatesData.discountType;
        this.getGasSupplyDays();
      }
    }
  }

  public getDualSupplyDays() {
    if (this.dualRatesData.billStartDate != '' && this.dualRatesData.billEndDate != '') {
      let billStartDate = [];
      let billEndDate = [];
      billStartDate = this.dualRatesData.billStartDate.split("/");
      billEndDate = this.dualRatesData.billEndDate.split("/");
      let date1 : any = new Date(billStartDate[2], billStartDate[1] - 1, billStartDate[0]);
      let date2 : any = new Date(billEndDate[2], billEndDate[1] - 1, billEndDate[0]);
      this.supplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.supplyDays = (this.supplyDays < 0) ? 0 : this.supplyDays + 1;
    } else {
      this.supplyDays = 0;
    }
  }

  public getGasSupplyDays() {
    if (this.dualRatesData.gasBillStartDate != '' && this.dualRatesData.gasBillEndDate != '') {
      let gasBillStartDate = [];
      let gasBillEndDate = [];
      gasBillStartDate = this.dualRatesData.gasBillStartDate.split("/");
      gasBillEndDate = this.dualRatesData.gasBillEndDate.split("/");
      let date1 : any = new Date(gasBillStartDate[2], gasBillStartDate[1] - 1, gasBillStartDate[0]);
      let date2 : any = new Date(gasBillEndDate[2], gasBillEndDate[1] - 1, gasBillEndDate[0]);
      this.gasSupplyDays = Math.round((date2.getTime() - date1.getTime()) / this.oneDay);
      this.gasSupplyDays = (this.gasSupplyDays < 0) ? 0 : this.gasSupplyDays + 1;
    } else {
      this.gasSupplyDays = 0;
    }
  }

  public submitDualRates() {
    if (this.dualRatesData["billStartDate"] != undefined && this.dualRatesData["billEndDate"] != undefined && this.supplyDays > 0 && this.gasSupplyDays > 0 && this.submittedRates == false) {
      this.dataService.getDualBillRates(this.dualRatesData, this.supplyDays, this.gasSupplyDays).subscribe(
        data => {
          this.submittedRates = true;
          let status = data["status"];
          if (status == "success") {
            this.showRates = true;
            this.billRates = data["results"];
          } else {
            if (status == 'redirect') {
              window.location.reload();
            }
          }
        },
        error => {
          this.submittedRates = true;
        }
      );
    }
  }
}
