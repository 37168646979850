<div class="container" id="print-section">
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-medium-print-header" [ngClass]="secBranding == true ? 'vec-sec' : ''">
        <img alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png" *ngIf="secBranding == true">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_720_left.png" *ngIf="secBranding == false">
        <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_720_vic_logo.png" *ngIf="secBranding == false">
        <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_600_vec_logo.png" *ngIf="secBranding == false">

    </div>

    <div class="distributor-modal" id="distributorBrochure">
        <div class="row steps-header">
            <div class="col-md-6">
                <h3>Download my data...</h3>
                <h4>{{ postcode }} ({{ distributor.name }})</h4>
            </div>
            <div class="col-md-6">
                <button class="hidden-btn"></button>
                <button type="button" class="close non-focus" (click)="activeModal.close('Close click')">close ×</button>
            </div>
        </div>
        <div class="hidden-label">{{ emailSecurityKey }}</div>
        <div class="row steps-header">
            <div class="col-md-12">
                <p>If you have been at your address for 12 months or more, you can download your data by following these steps.</p>
            </div>
        </div>
        <div class="row">
            <div class="print-icons col-md-12">
                <button type="button" class="btn btn-link link distributor-link" (click)="emailDistributorBrochure()">
                    <i class="material-icons button-icons distributor-icons" aria-hidden="true">email</i>
                    - email this brochure
                </button>
                <button type="button" class="btn btn-link link distributor-link" (click)="print()">
                    <i class="material-icons button-icons distributor-icons" aria-hidden="true">print</i>
                    - print this brochure
                </button>
            </div>
        </div>

        <div class="col-md-11 container">
            <div class="row">
                <div class="col-md-12">
                    <!-- Step 1 -->
                    <div class="row step">
                        <div class="col-md-5 col-left step-left">
                            <h4>Step One</h4>
                            <p>Grab your current electricity bill</p>
                        </div>
                        <div class="col-md-1 border-vertical step-middle">&nbsp;</div>
                        <div class="col-md-6 col-right step-right">
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-1.png" class="img-fluid."/>
                        </div>
                    </div>

                    <div class="row border-horizontal-container">
                        <div class="col-md-9 border-horizontal container">&nbsp;</div>
                    </div>

                    <!-- Step 2 -->
                    <div class="row step">
                        <div class="col-md-5 col-left step-left">
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-2.png" class="img-fluid." />
                        </div>
                        <div class="col-md-1 border-vertical step-middle">&nbsp;</div>
                        <div class="col-md-6 col-right step-right">
                            <h4>Step Two</h4>
                            <p [innerHTML]="distributorInfo.step2">
                            </p>
                        </div>
                    </div>

                    <div class="row border-horizontal-container">
                        <div class="col-md-9 border-horizontal container">&nbsp;</div>
                    </div>

                    <!-- Step 3 -->
                    <div class="row step">
                        <div class="col-md-5 col-left step-left">
                            <h4>Step Three</h4>
                            <p [innerHTML]="distributorInfo.step3">
                            </p>
                        </div>
                        <div class="col-md-1 border-vertical step-middle">&nbsp;</div>
                        <div class="col-md-6 col-right  step-right">
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-4.png" *ngIf="distributorInfo.step5 == ''"/>
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-3.png" *ngIf="distributorInfo.step5 != ''"/>
                        </div>
                    </div>

                    <div class="row border-horizontal-container">
                        <div class="col-md-9 border-horizontal container">&nbsp;</div>
                    </div>

                    <!-- Step 4 -->
                    <div class="row step">
                        <div class="col-md-5 col-left step-left">
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-5.png" *ngIf="distributorInfo.step5 == ''"/>
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-4.png" *ngIf="distributorInfo.step5 != ''"/>
                        </div>
                        <div class="col-md-1 border-vertical step-middle">&nbsp;</div>
                        <div class="col-md-6 col-right step-right">
                            <h4>Step Four</h4>
                            <p [innerHTML]="distributorInfo.step4">
                            </p>
                        </div>
                    </div>

                    <div class="row border-horizontal-container" *ngIf="distributorInfo.step5 != ''">
                        <div class="col-md-9 border-horizontal container">&nbsp;</div>
                    </div>

                    <!-- Step 5 -->
                    <div class="row step" *ngIf="distributorInfo.step5 != ''">
                        <div class="col-md-5 col-left step-left">
                            <h4>Step Five</h4>
                            <p [innerHTML]="distributorInfo.step5">
                            </p>
                        </div>
                        <div class="col-md-1 border-vertical step-middle">&nbsp;</div>
                        <div class="col-md-6 col-right step-right">
                            <img alt="Download my data" aria-label="Download my data" src="{{ appURL }}/assets/images/distributor_modal/step-5.png" />
                        </div>
                    </div>

                    <div class="row border-horizontal-container no-print">
                        <div class="col-md-9 border-horizontal container">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br /><br />
</div>
