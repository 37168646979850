<app-offer-banner-panel
    [hasConcession]="hasConcession"
    [userType]="userType"
    [averageDailyConsumption]="averageDailyConsumption"
    [loopBackAverageDailyConsumption]="loopBackAverageDailyConsumption"
    [energyType]="energyType"
    [loopBackEnergyType]="loopBackEnergyType"
    [loopBackIsEmpty]="loopBackIsEmpty"
    [bill]="bill">
</app-offer-banner-panel>

<div class="row dual-energy-buttons" role="group" aria-labelledby="lbl-dual-energy-button" *ngIf="loopBackHasOffers == true">
    <div class="col-md-12 text-center HelveticaNeueLTStd-Lt-Offer">
        Select the energy type to view the offers
    </div>
    <br /><br /><br />
    <div class="col-md-12 text-center loop-back-buttons">
        <a class="btn energy-button btn-default" [ngClass]="(router.url == '/offers/list/electricity' || energyType == 'Electricity') ? 'energy-button-selected' : ''" (click)="changeEnergy('electricity')">ELECTRICITY</a>
        <a class="btn energy-button btn-default" [ngClass]="(router.url == '/offers/list/gas' || energyType == 'Gas') ? 'energy-button-selected' : ''" (click)="changeEnergy('gas')">GAS</a>
        <a class="btn energy-button btn-default" [ngClass]="(router.url == '/offers/list/dual') ? 'energy-button-selected' : ''" (click)="changeEnergy('dual')">BOTH </a>
    </div>
</div>

<br />
<div class="row">
    <div class="col-lg-2 left-filters no-padding-right">
        <div class="psb-reference" *ngIf="dataService.PSBReference != ''">
            <span class="strong">Your PSB application is complete</span>. Your reference number is
            <span class="strong">{{ dataService.PSBReference }}</span>
        </div>
        <div class="eap-reference" *ngIf="dataService.EAPReference != ''">
            <span class="strong">Your EAP reference number is {{ dataService.EAPReference }}</span>
        </div>
    </div>
    <div class="col-lg-10">
        <ul id="offers" *ngIf="showRates == false">
            <li class="row offer-row" [ngClass]="(offersHasSolar == true) ? 'offer-row-header' : ''">
                <div class="row col-md-12 offer-div">

                    <div class="col-md-3 col-xs-12 col-sm-12 no-padding align-middle">
                      <div class="row col-md-12 no-padding">
                          <div class="align-middle actual-price no-padding" [ngClass]="(offersHasSolar == true) ? 'col-md-3' : 'col-md-4'">
                              &nbsp;
                          </div>
                          <div class="col-md-8 mobile-padding align-middle-y no-padding rate-placehoder ie-padding">
                              <span class="price-sign">$</span> {{ ratePlaceholder() }} <span class="small">/year</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-6 col-sm-6 align-middle">
                        Add your current rates to
                        <br />
                        help you compare offers.
                    </div>
                    <div class="col-md-5 col-xs-6 col-sm-6 no-padding align-middle">
                            <button type="button" class="offers-button offers-button-txt" (click)="rateDetailsModal()">
                                What am I currently paying?
                            </button>
                        </div>
                    </div>
            </li>
        </ul>

        <div class="strong currently-paying" *ngIf="Math.abs(billRates.totalConditional) > 0">
            <span class="btn">What you are currently paying</span>
            <i
                class="material-icons small-tooltip non-focus"
                placement="top"
                ngbTooltip="This estimate is based on your current rates and is created with the same profile used to generate the other offer estimates. You can use this estimate to see how much you might save if you decide to switch to another offer."
                tabindex="0">
                info
            </i>
            &nbsp;
            <a (click)="rateDetailsModal()" class="link">edit</a>
        </div>
        <div class="row rate-details-section col-md-12 low-rate-notice" *ngIf="ratesError == true && showRates == true">
            <p class="col-md-12 alert alert-warning">
                We are unable to calculate your current offer. Please check your rates and try again.
            </p>
            <br />
        </div>

        <!--single energy-->
        <ul id="offers" *ngIf="ratesError == false && showRates == true && energyType != 'dual'">
            <li class="row offer-row">
                <div class="row col-md-12 offer-div">

                    <div class="col-12 col-lg-5 no-padding prices offer-price">
                      <div class="col-1 col-lg-1 single-checkbox">
                      </div>
                    <div class="row ie-full-width" [ngClass]="renderClass()">
                        <div class="row col-md-12 first-solar-credit-display" *ngIf="billRates.components.solar > 0" [ngClass]="billRates.components.solar > 0 ? 'no-padding' : ''">
                            <div class="col-md-12 solar-credit-display no-padding">
                                Estimate includes solar credit of {{ billRates.components.solar | currency : 'USD' : 'symbol' : '1.0-0' }}
                            </div>
                        </div>
                        <div class="row ie-full-width" [ngClass]="renderRateClass()">
                          <div class="col-md-6 mobile-padding align-middle-y no-padding conditional-price price-format">
                            <div class="full-width">
                                <span class="price-sign">$</span>
                                <span *ngIf="dataService.getSorting() == true">
                                    <span class="currency">{{ Math.abs(billRates.totalConditional) | number }}
                                        <span *ngIf="billRates.totalConditional < 0" class="strong credits">
                                            CR
                                        </span>
                                    </span>
                                </span>
                                <span *ngIf="dataService.getSorting() == false">
                                    <span class="currency">{{ Math.abs(billRates.totalUnconditional) | number }}
                                        <span *ngIf="billRates.totalUnconditional < 0" class="strong credits">
                                            CR
                                        </span>
                                    </span>
                                </span>
                                <p class="estimated-price">
                                    Est. yearly price
                                </p>
                            </div>
                        </div>

                            <div class="col-md-6 align-middle actual-price">
                                <p *ngIf="dataService.getSorting() == true">
                                    <span class="price-alignment">
                                        Without conditional discounts {{ Math.abs(billRates.totalUnconditional) | currency:'USD':'symbol':'1.0-0' }}
                                    </span>
                                    Without discounts
                                    <span class="strong total-base-price">{{ Math.abs(billRates.totalUnconditional) | currency:'USD':'symbol':'1.0-0' }}
                                    </span>
                                    <span *ngIf="billRates.totalUnconditional < 0" class="strong">
                                        CR
                                    </span>
                                </p>
                                <p *ngIf="dataService.getSorting() == false">
                                    <span class="price-alignment">
                                        With conditional discounts {{ Math.abs(billRates.totalConditional) | currency:'USD':'symbol':'1.0-0' }}
                                    </span>
                                    With discounts
                                    <span class="total-base-price strong">
                                        {{ Math.abs(billRates.totalConditional) | currency:'USD':'symbol':'1.0-0' }}
                                    </span>
                                    <span *ngIf="billRates.totalConditional < 0" class="strong">
                                        CR
                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>
                    </div>

                    <div class="col-6 col-lg-3 no-padding align-middle offer-types">
                      <span class="strong current-offer" *ngIf="energyType != 'Gas'">Current offer type:<br>{{ billRates.offerType }} {{ (billRates.components.controlledLoad > 0) ? ' + CL' : '' }}</span>
                      <br />
                      <span *ngIf="energyType != 'Gas' && billRates.offerType == 'Flat' && (showTouOffer == true || showFlexiblePricing == true)">
                          <a class="offer-types-link" (click)="showOfferType('Flat')">Only show Flat offers </a>
                          <i
                              class="material-icons small-tooltip non-focus"
                              placement="top"
                              ngbTooltip="It is often simpler to choose an offer that matches your current offer type. If you wish to change your tariff type you will need to contact the retailer to discuss if it is possible."
                              tabindex="0">
                              info
                          </i>
                      </span>
                      <span *ngIf="energyType != 'Gas' && billRates.offerType == 'Flexible' && (showSingleRate == true || showTouOffer == true)">
                          <a class="offer-types-link" (click)="showOfferType('Flexible')">Only show Flexible offers </a>
                          <i
                              class="material-icons small-tooltip non-focus"
                              placement="top"
                              ngbTooltip="It is often simpler to choose an offer that matches your current offer type. If you wish to change your tariff type you will need to contact the retailer to discuss if it is possible."
                              tabindex="0">
                              info
                          </i>
                      </span>
                      <span *ngIf="energyType != 'Gas' && billRates.offerType == 'Time of Use' && (showSingleRate == true || showFlexiblePricing == true)">
                          <a class="offer-types-link" (click)="showOfferType('Time of Use')">Only show Time of Use offers </a>
                          <i
                              class="material-icons small-tooltip non-focus"
                              placement="top"
                              ngbTooltip="It is often simpler to choose an offer that matches your current offer type. If you wish to change your tariff type you will need to contact the retailer to discuss if it is possible."
                              tabindex="0">
                              info
                          </i>
                      </span>
                  </div>

                    <div class="col-md-2 col-xs-6 col-sm-6 offer-details align-middle offer-name">
                        <div class="row offer-info">
                            <span class="retailer-name full-width align-middle-y">
                                {{ billRates.retailerName }}
                                <br />
                            </span>
                        </div>
                    </div>

                    <div class="col-md-2 col-xs-6 col-sm-6 no-padding logo">
                        <div class="row y-align-middle">

                            <div class="col-md-8 align-middle single-retailer-logo">
                                <div class="retailer-logo offer-center-align offer-left-align align-middle-img">
                                    <img
                                        class="retailer-logo"
                                        [attr.alt]="billRates.retailerName"
                                        [attr.aria-label]="billRates.retailerName"
                                        src='{{ appURL }}/assets/{{ billRates.retailerImageUrl }}'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <!--dual/loopback-->
        <ul *ngIf="Math.abs(billRates.totalConditional) > 0 && energyType == 'dual'" id="offers">
            <li class="row offer-row">
                <div class="row col-md-9 offer-div">
                    <div class="col-md-1 dual-energy-display">
                        <br />
                        <div class="row vertical-text">
                            <p>ELEC</p>
                        </div>

                        <div class="row dual-energy-checkbox">
                            <div class="checkbox checkbox-warning offer-left-align align-middle-checkbox dual-checkbox">
                            </div>
                        </div>
                        <br />
                        <div class="row vertical-text">
                            <p>GAS</p>
                        </div>
                    </div>
                    <div class="col-md-11 dual-energy-details">
                        <!--electricity-->
                        <div class="row page-divider-bottom">
                            <div class="col-md-3 col-xs-6 col-sm-6 retailer-image dual-energy-column align-middle">
                                <div class="retailer-logo offer-center-align">
                                    <img
                                        class="retailer-logo"
                                        [attr.alt]="billRates.retailerName"
                                        [attr.aria-label]="billRates.retailerName"
                                        src='{{ appURL }}/assets/{{ billRates.retailerImageUrl }}'>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-6 col-sm-6 offer-details align-middle offer-name dual-energy-column">
                                <div class="row offer-info">
                                    <span class="retailer-name full-width align-middle-y">
                                        {{ billRates.retailerName }}
                                        <br />
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-5 col-xs-6 col-sm-6 align-middle offer-types dual-energy-column">
                                <span class="strong current-offer" *ngIf="energyType != 'Gas'">Current offer type: {{ billRates.offerType }} {{ (billRates.components.controlledLoad > 0) ? ' + CL' : '' }}</span>
                                <br />
                                <span *ngIf="energyType != 'Gas' && billRates.offerType == 'Flat' && (showTouOffer == true || showFlexiblePricing == true)">
                                    <a class="offer-types-link" (click)="showOfferType('Flat')">Only show Flat offers </a>
                                    <i
                                        class="material-icons small-tooltip non-focus"
                                        placement="top"
                                        ngbTooltip="It is often simpler to choose an offer that matches your current offer type. If you wish to change your tariff type you will need to contact the retailer to discuss if it is possible."
                                        tabindex="0">
                                        info
                                    </i>
                                </span>
                                <span *ngIf="energyType != 'Gas' && billRates.offerType == 'Flexible' && (showSingleRate == true || showTouOffer == true)">
                                    <a class="offer-types-link" (click)="showOfferType('Flexible')">Only show Flexible offers </a>
                                    <i
                                        class="material-icons small-tooltip non-focus"
                                        placement="top"
                                        ngbTooltip="It is often simpler to choose an offer that matches your current offer type. If you wish to change your tariff type you will need to contact the retailer to discuss if it is possible."
                                        tabindex="0">
                                        info
                                    </i>
                                </span>
                                <span *ngIf="energyType != 'Gas' && billRates.offerType == 'Time of Use' && (showSingleRate == true || showFlexiblePricing == true)">
                                    <a class="offer-types-link" (click)="showOfferType('Time of Use')">Only show Time of Use offers </a>
                                    <i
                                        class="material-icons small-tooltip non-focus"
                                        placement="top"
                                        ngbTooltip="It is often simpler to choose an offer that matches your current offer type. If you wish to change your tariff type you will need to contact the retailer to discuss if it is possible."
                                        tabindex="0">
                                        info
                                    </i>
                                </span>
                            </div>
                        </div>
                        <!--gas-->
                        <div class="row">
                            <div class="col-md-3 col-xs-6 col-sm-6 retailer-image dual-energy-column align-middle">
                                <div class="retailer-logo offer-center-align">
                                    <img
                                        class="retailer-logo"
                                        [attr.alt]="billRates.gasRetailerName"
                                        [attr.aria-label]="billRates.gasRetailerName"
                                        src='{{ appURL }}/assets/{{ billRates.gasRetailerImageUrl }}'>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-6 col-sm-6 offer-details align-middle offer-name dual-energy-column">
                                <div class="row offer-info">
                                    <span class="retailer-name full-width align-middle-y">
                                        {{ billRates.gasRetailerName }}
                                        <br />
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-5 col-xs-6 col-sm-6 align-middle offer-types dual-energy-column">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 dual-price-bundle">
                    <div class="col-md-12 offer-bundle-price text-center">
                        <p>
                            <span class="HelveticaNeueLTStd-Regular">Bundle total price</span>
                        </p>
                        <br />
                        <p *ngIf="billRates.components.solar > 0" class="bundle-solar">
                            Solar generation of <span class="strong">{{ billRates.components.solar | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                            deducted from est. value
                        </p>
                        <br />
                        <p>
                            <span class="price-sign">$</span>
                            <span *ngIf="dataService.getSorting() == true">
                                <span class="currency">
                                    {{ Math.abs((billRates.totalConditional * 1) + (billRates.totalGasConditional * 1)) | number }}
                                </span>
                                <span *ngIf="((billRates.totalConditional * 1) + (billRates.totalGasConditional * 1)) < 0" class="strong credits">
                                    CR
                                </span>
                            </span>
                            <span *ngIf="dataService.getSorting() == false">
                                <span class="currency">
                                    {{ Math.abs((billRates.totalUnconditional * 1) + (billRates.totalGasUnconditional * 1)) | number }}
                                </span>
                                <span *ngIf="((billRates.totalUnconditional * 1) + (billRates.totalGasUnconditional * 1)) < 0" class="strong credits">
                                    CR
                                </span>
                            </span>
                            &nbsp;
                            <sub>
                                / year
                            </sub>
                        </p>

                        <br />
                        <p *ngIf="dataService.getSorting() == true">
                            Without discounts
                            <span class="strong total-base-price">
                                {{ Math.abs((billRates.totalUnconditional * 1) + (billRates.totalGasUnconditional * 1)) | currency:'USD':'symbol':'1.0-0' }}
                            </span>
                            <span *ngIf="((billRates.totalUnconditional * 1) + (billRates.totalGasUnconditional * 1)) < 0" class="strong">
                                CR
                            </span>
                        </p>
                        <p *ngIf="dataService.getSorting() == false">
                            With discounts
                            <span class="total-base-price strong">
                                {{ Math.abs((billRates.totalConditional * 1) + (billRates.totalGasConditional * 1)) | currency:'USD':'symbol':'1.0-0' }}
                            </span>
                            <span *ngIf="((billRates.totalConditional * 1) + (billRates.totalGasConditional * 1)) < 0" class="strong">
                                CR
                            </span>
                        </p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<span id="top"></span>

