<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <img
                class="hottopic-image"
                src="assets/images/{{ secImageURL }}contentpages/hottopics_header_2.jpg"
                alt="Moving house? Move to cheaper energy bills">
            <p>
                Whether you're renting or buying, if you’re moving to a new house, you'll need to connect your power. There are a few easy
                steps you can take to make the move just a little bit easier - so your energy is connected that first day you move in, and
                your first bill in the new place won't be a shock.
            </p>
            <ol>
                <li>
                    <span class="strong">
                        Compare electricity and gas providers to find the best offer for your new home.
                    </span>
                    <p>
                        Moving to a new house can be stressful, but knowing you're on the right energy offer can help take some of the pressure
                        off. <a class="hot-topics" href="{{ appURL }}" target="_blank">Victorian Energy Compare</a> lets you compare all 25
                        energy companies in the state to find the cheapest provider for you. It's worth checking you're getting the best deal
                        for your new house before you move in.
                    </p>
                </li>
                <li>
                    <span class="strong">
                        Avoid energy bill double-ups.
                    </span>
                    <p>
                        There's nothing worse than getting two energy bills at once. When you're moving, make sure you cancel or transfer your
                        energy plan while you're setting up the energy plan for your new place to avoid bill double-up. Check your energy
                        provider's website to see when you need to apply for cancellation or transfer and when you can expect your last bill.
                    </p>
                </li>
                <li>
                    <span class="strong">
                        Line up the dates.
                    </span>
                    <p>
                        Once you've selected the best offer for your new home, make sure you're ready to flick on the lights when you arrive.
                        Let your energy provider know of your arrival date so that your electricity is on in time.
                    </p>
                </li>
                <li>
                    <span class="strong">
                        Stay switched on to your power bills.
                    </span>
                    <p>
                        Energy prices change over time, so even if you're confident you've got the best deal for your new home, don't forget to
                        check back with Victorian Energy Compare regularly. It's constantly updated with the latest offers from energy retailers
                        so you can make sure you're getting the best deal all year round.
                    </p>
                </li>
            </ol>
            <p>
                For more tips, head to the
                <a class="hot-topics" href="https://www.ewov.com.au/common-complaints/connecting-and-transferring" target="_blank">Energy and Water Ombudsman Victoria website</a>,
                and to compare electricity and gas providers for your new home, head to our independent
                <a class="hot-topics" href="{{ appURL }}" target="_blank">comparison website</a>.
            </p>
            <br />
            <p>
                <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
            </p>
        </div>
    </div>
</div>
<br /><br />
