<div class="modal-header" class="button">
    <div class="row col-md-12">
        <div class="col-md-12 modal-icons">
            <button class="hidden-btn"></button>
            <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
                <i class="fa fa-times" aria-hidden="true"></i> close
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="row form-group" [innerHTML]="distributorInfo">
    </div>
</div>
