<div id="results-header">
    <div class="card">
        <div class="card-header bg-success text-white">
            <div class="card-title">Solar <b>Savings</b></div>
        </div>
        <div class="card-body">
            <h1 id="saving-text">{{ SolarEstimatesData.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}<sup>*</sup></h1>
            <br><div class='potential-savings'>Estimated Potential Savings</div>
            <p id="grid-saving-text">
                Based on your electricity usage and location you could save
                {{ SolarEstimatesData.annualSavedTotal | currency:'USD':'symbol':'1.0-0' }}<sup>*</sup>
                annually by installing a {{ solarData.systemSize }}kW solar system.
            </p>
        </div>
    </div>
</div>