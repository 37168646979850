<div class="modal-header" class="button">
  <div class="row col-md-12 no-gutters">
    <div class="col-md-12 modal-icons">
        <button class="hidden-btn"></button>
        <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
            <i class="fa fa-times" aria-hidden="true"></i> close
        </button>
    </div>
</div>
</div>

<div class="modal-content">
    <div class="modal-body" id="modal-body">
      <div [innerHTML]="pageContents">

      </div>
    </div>
    <div class="close-btn">
        <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">Close</button>
    </div>
</div>
