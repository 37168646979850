<div class="modal-header" class="button">
    <div class="row col-md-12">
        <div class="col-md-12 modal-icons">
            <button class="hidden-btn"></button>
            <button type="" class="btn btn-link non-focus" aria-label="Close" (click)="activeModal.close(billRates)">
                <i class="fa fa-times" aria-hidden="true"></i> close
            </button>
        </div>
    </div>
</div>
<div class="modal-header">
    <div class="row col-md-12">
        <div class="col-md-12">
            <h3>
                <span *ngIf="currentPage == 'main'">
                    What am I currently paying?
                </span>
                <span *ngIf="currentPage == 'upload'">
                    Upload a PDF Bill
                </span>
                <span *ngIf="currentPage == 'manual'">
                    Enter your rate details
                </span>
            </h3>
            <a
                title="Upload a file"
                aria-label="Upload a file"
                class="hot-topics"
                *ngIf="currentPage == 'manual'"
                (click)="changePage('upload')">upload file</a>
            <a
                title="Manual rate entry"
                aria-label="Manual rate entry"
                class="hot-topics"
                *ngIf="currentPage == 'upload'"
                (click)="changePage('manual')">manual entry</a>
            <br clear="all" />
            <h5 *ngIf="currentPage == 'manual'" class="modal-subheader">
                <br />
                Your {{ energyType.toLowerCase() }} bill details:
                <br clear="all" />
            </h5>
            <span *ngIf="currentPage == 'main'">
                Provide your rates to compare your rates with other offers
            </span>
            <span *ngIf="currentPage == 'manual'">
                <br />
                Enter all prices INCLUDING GST
            </span>
            <span *ngIf="currentPage == 'upload'">
                <br /><br />
                Upload a PDF bill to compare your rates
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="container col-md-12">
        <div class="row zero-height" role="group">
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <div class="input-group">
                        <input name="datepicker"
                                class="form-control"
                                ngbDatepicker
                                #datepicker="ngbDatepicker"
                                [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event, datepicker)"
                                [displayMonths]="(isMobile == true) ? 1 : 2"
                                [maxDate]="maxDate"
                                [minDate]="minDate"
                                [dayTemplate]="t"
                                outsideDays="hidden"
                                [startDate]="fromDate!"
                                style="opacity: 0; ">
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [ngClass]="{'custom-day-disabled' : isDisabled(date)}"
                                [class.focused]="focused"
                                [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <br />
        <div class="row" *ngIf="currentPage == 'main'">
            <div class="col-md-1">
            </div>
            <div class="col-md-5">
                <label class="export-csv-button export-csv-button-txt btn-block x-custom-btn-large-text" (click)="changePage('upload')">
                    Upload a PDF Bill
                </label>
            </div>
            <div class="col-md-5">
                <label class="export-csv-button export-csv-button-txt btn-block x-custom-btn-large-text" (click)="changePage('manual')">
                    Enter your Rates Manually
                </label>
            </div>
        </div>
        <div *ngIf="currentPage == 'upload'">
            <div class="alert alert-danger" *ngIf="PDFUploadError != ''">
                <span class="strong">The following issue was found:</span> We are unable to process your bill.<br />
                Please provide another PDF bill, or
                <a
                title="Manual rate entry"
                aria-label="Manual rate entry"
                class="link"
                (click)="changePage('manual')">enter rates manually</a>.
            </div>

            <div class="row">
                <div class="col-lg-5 vertical-align">
                    <label class="h3">
                        PDF File
                    </label>
                </div>
                <div class="col-lg-3 vertical-align">
                    <input
                    type="text"
                    value="{{ fileName }}"
                    disabled="disabled"
                    class="form-control"/>
                </div>
                <div class="col-lg-4 vertical-align">
                    <div class="form-control fileUploadDiv custom-button no-margin">
                        <label for="fileupload" class="error" style="display: none;">Please enter a value with a valid mimetype.</label>
                        <input id="fileupload" name="fileupload" onclick="this.value = null" (change)="uploadFileConsumption($event.target.files)" type="file" class="upload valid" accept=".pdf, application/pdf">
                        <label id="browse" for="fileupload" style="padding-top: .15em;">Browse</label>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div id="pdfTerms">
                    <p>
                        <br />
                        <span class="strong">Disclaimer Agreement</span>
                        <br />
                        Your use of Victorian Energy Compare is governed by the privacy statement for this
                        website, which is located at <a class="hot-topics" title="Victoria's Privacy policy" aria-label="Victoria's Privacy policy" href="https://www.deeca.vic.gov.au/privacy" target="_blank">https://www.deeca.vic.gov.au/privacy</a>.
                        In addition to the collection of anonymous information through web servers set out in that privacy statement, if you choose to
                        upload a PDF bill to the Victorian Energy Compare website, the information you provide will be retained by the Department of Energy, Environment and Climate Action (DEECA) for the purpose of forming aggregated data and to help inform matters relating to
                        energy affordability. Any such information that is personal information will be held and used in accordance with our privacy
                        policy. You may request access to any personal information that DEECA may have collected about you. If you do not want your
                        PDF bill information to be held and used in accordance with this disclaimer agreement, you can enter your billing information
                        manually <a class="hot-topics" (click)="changePage('manual')">here</a>.
                    </p>
                    <br />
                    <p>
                        <span class="strong">Third party data</span>
                        <br />
                        If you choose to upload a PDF bill to the Victorian Energy Compare website, you are acknowledging that the
                        information you upload will be sent to a third party provider, Accurassi.  This privacy notice for
                        Accurassi, and further information about the treatment of your information, can be found at
                        <a class="hot-topics" title="Terms & Conditions" aria-label="Terms & Conditions" href="http://www.accurassi.com/terms" target="_blank">www.accurassi.com/terms</a>.
                    </p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="currentPage == 'manual'">
            <form method="post" id="rateForm" [formGroup]="rateForm" class="col-md-12" onsubmit="return false" (keydown.enter)="$event.preventDefault()">
                <div class="alert alert-danger" *ngIf="manualEntryError != ''">
                    We are unable to calculate your current offer. Please check your rates and try again.
                    <br />
                </div>

                <div class="row">
                    <div class="col-md-6 form-padding">
                        <label class="h3" for="retailer">Who is your current {{ energyType.toLowerCase() }} retailer?</label>
                    </div>
                    <div class="col-md-6 vertical-align">
                        <div *ngIf="retailersSelection == false">
                            <span class="disabled-text custom-input-text input-retailer-disp">
                                {{ energyConfigForm['retailerName'] }}
                            </span>
                            <input type="hidden" formControlName="retailer" [(ngModel)]="userData.retailer">
                        </div>
                        <div *ngIf="retailersSelection == true" class="input-group form-group">
                            <label class="hidden-label">Retailer</label>
                            <ng-select
                                class="form-control col-md-12 retailer-selection"
                                name="retailer"
                                tabindex="1"
                                [searchFn]="startsWithSearchFn"
                                formControlName="retailer"
                                [(ngModel)]="userData.retailer"
                                [items]="retailers"
                                bindLabel="title"
                                bindValue="id"
                                #retailers1
                                (blur)="setRetailerName()"
                                (close)="retailers1.blur(); retailers1.focus()"
                                [loading]="retailersLoading">
                            </ng-select>
                            <bfv-messages class="retailer-selection"></bfv-messages>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding" role="group">
                    <div class="col-md-6 custom-margin">
                        <label id="lbl_elec_step1_header" class="h3 bill-date-group control-label">
                            Your {{ energyType.toLowerCase() }} bill period:
                            <i
                                *ngIf="energyType == 'Electricity'"
                                attr.aria-label="'The bill period is the time from one bill to the next bill. You pay for the electricity you used during the bill period. Your bill might say something like '28 Jul 2015 to 21 Sept 2015 (56 days)'. The bill period is not related to the invoice date or processing data shown on the bill.'"
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="'The bill period is the time from one bill to the next bill. You pay for the electricity you used during the bill period. Your bill might say something like '28 Jul 2015 to 21 Sept 2015 (56 days)'. The bill period is not related to the invoice date or processing data shown on the bill.'"></i>
                            <i
                                *ngIf="energyType == 'Gas'"
                                attr.aria-label="'The bill period is the time from one bill to the next bill. You pay for the gas you used during the bill period. Your bill might say something like '28 Jul 2015 to 21 Sept 2015 (56 days)'. The bill period is not related to the invoice date or processing data shown on the bill.'"
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="'The bill period is the time from one bill to the next bill. You pay for the gas you used during the bill period. Your bill might say something like '28 Jul 2015 to 21 Sept 2015 (56 days)'. The bill period is not related to the invoice date or processing data shown on the bill.'"></i>
                        </label>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <label class="control-label" for="elec-start-date">START DATE:</label>
                            <div class="input-group form-group">
                                <label class="hidden-label">Start date</label>
                                <input #dpFromDate
                                    id="startDate"
                                    tabindex="1"
                                    class="form-control"
                                    placeholder="dd//mm/yyyy"
                                    formControlName="billStartDate"
                                    [(ngModel)]="userData.billStartDate"
                                    [value]="userData.billStartDate"
                                    (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                    (focusout)="getSupplyDays()">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button" tabindex="2">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <bfv-messages class="date-error"></bfv-messages>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <label class="control-label" for="elec-end-date">END DATE:</label>
                            <div class="input-group form-group">
                                <label class="hidden-label">End date</label>
                                <input #dpToDate
                                    tabindex="3"
                                    class="form-control"
                                    placeholder="dd//mm/yyyy"
                                    formControlName="billEndDate"
                                    [(ngModel)]="userData.billEndDate"
                                    [value]="userData.billEndDate"
                                    (input)="toDate = validateInput(toDate, dpToDate.value)"
                                    (focusout)="getSupplyDays()">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button" tabindex="4">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                                <bfv-messages class="date-error"></bfv-messages>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row page-divider-up no-right-gutter" *ngIf="energyConfigForm['energyType'] == 'Gas'">
                    <div class="col-md-6 col-xs-12 form-padding">
                        <div class="row no-gutters">
                            <div class="col-md-9 col-xs-12">
                                <label class="h3" for="elec-supply-charge">
                                    {{ retailerDetails.retailerSettings.lbl_dialog_gas_total_charge }}
                                    <i
                                        class="material-icons non-focus"
                                        tabindex="-1"
                                        [triggers]="utility.toolTipForMobile()"
                                        ngbTooltip="The total gas charges are the new charges for this billing period. This does not include any unpaid or amounts carried forward from previous bills or any concessions applied to the bill."
                                        aria-label="The total gas charges are the new charges for this billing period. This does not include any unpaid or amounts carried forward from previous bills or any concessions applied to the bill."></i>
                                </label>
                                <br />
                                <small>
                                    {{ retailerDetails.retailerSettings.lbl_dialog_gas_total_charge_line2 }}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 form-group form-padding supply-charge">
                                <label class="hidden-label">{{ retailerDetails.retailerSettings.lbl_dialog_gas_total_charge }}</label>
                                <input
                                    tabindex="5"
                                    type="text"
                                    id="totalCharge"
                                    formControlName="totalCharge"
                                    class="form-control"
                                    step=".1"
                                    [readonly]="supplyDays == 0"
                                    [type]="'number'"
                                    (keydown)="selectButton($event, 'textInput')"
                                    (change)="getSupplyDays()"
                                    [ngClass]="{ 'ng-invalid is-invalid' : (convertToNumber(userData.totalCharge) <= convertToNumber(userData.supplyCharge)) }"
                                    [(ngModel)]="userData.totalCharge">
                                <bfv-messages></bfv-messages>
                                <p class="total hidden-xs hidden-sm">Total in {{ retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit }}</p>
                                <p id="lbl_dialog_exm2_elec"></p>
                            </div>
                            <div class="col-md-6 form-padding">
                                <p class="total-suffix">
                                    for <span class="strong">{{ supplyDays }}</span> day(s) of {{ energyType.toLowerCase() }} supply
                                    (including {{ retailerDetails.retailerSettings.retailer_bill_daily_charge_name }} total and discounts)
                                </p>
                            </div>
                        </div>

                        <div class="row" *ngIf="userData.totalCharge != '' && userData.supplyCharge != '' && convertToNumber(userData.totalCharge) <= convertToNumber(userData.supplyCharge)">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    The amount you have entered is not valid. {{ utility.titleCase(retailerDetails.retailerSettings.lbl_dialog_gas_total_charge) }}
                                    include both your usage and {{ retailerDetails.retailerSettings.retailer_bill_daily_charge_name }},
                                    so it cannot be less than your {{ retailerDetails.retailerSettings.retailer_bill_daily_charge_name }} total.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row page-divider-up" role="radiogroup" *ngIf="energyConfigForm['energyType'] == 'Electricity'">
                    <div class="col-md-6 form-padding">
                        <label class="h3 control-label">Do you have {{ retailerDetails.retailerSettings.retailer_bill_offpeak_rate_name }}?
                            <i
                                *ngIf="energyType == 'Electricity'"
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="You will only have an off-peak rate if you are on a flexible pricing plan. This means your electricity costs different prices at different times of the day. Off-peak is when electricity is cheapest. This might be during the night time."
                                aria-label="You will only have an off-peak rate if you are on a flexible pricing plan. This means your electricity costs different prices at different times of the day. Off-peak is when electricity is cheapest. This might be during the night time."></i>
                        </label>
                    </div>
                    <div class="col-md-6 vertical-align">
                        <div class="row custom-padding radio-inline form-group">
                            <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_offpeak_rate_name) }}</label>
                            <input type="radio" id="offPeakYes" value="1" formControlName="offPeak" [(ngModel)]="userData.offPeak">
                            <label for="offPeakYes" class="custom-btn-default custom-btn-text" tabindex="6" (keydown)="selectButton($event, 'offPeakYes'); clearOffPeakRate();" (click)="clearOffPeakRate()">Yes</label>
                            <input type="radio" id="offPeakNo" value="0" formControlName="offPeak" [(ngModel)]="userData.offPeak">
                            <label for="offPeakNo" class="custom-btn-default custom-btn-text" tabindex="7" (keydown)="selectButton($event, 'offPeakNo'); clearOffPeakRate();" (click)="clearOffPeakRate()">No</label>
                            <bfv-messages class="col-md-6"></bfv-messages>
                        </div>
                    </div>
                </div>

                <!-- ngIf: 1 == offpeak_disp -->
                <div class="row offpeak-rate-disp" *ngIf="userData.offPeak == '1'">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <div class="row no-left-gutter">
                            <label for="offpeak-rate">
                                {{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_offpeak_rate_name) }} rate for electricity use:
                            </label>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-xs-12 no-left-gutter form-group">
                                <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_offpeak_rate_name) }} rate</label>
                                <input
                                    tabindex="8"
                                    type="text"
                                    formControlName="offPeakRate"
                                    id="offPeakRate"
                                    step="0.01"
                                    [type]="'number'"
                                    [(ngModel)]="userData.offPeakRate"
                                    max="1"
                                    (keydown)="selectButton($event, 'textInput')"
                                    class="form-control">
                                <bfv-messages></bfv-messages>
                                <p class="example hidden-xs hidden-sm">{{ retailerDetails.retailerSettings.lbl_dialog_exmp1 }}</p>
                            </div>
                            <div class="col-md-6">
                                <p class="total-suffix">Rate in <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit }}</span> per <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_offpeak_rate_per_unit }}</span></p>
                            </div>
                        </div>
                        <div class="row" *ngIf="userData.offPeakRate != '' && convertToNumber(userData.offPeakRate) < billRateOffPeakMin || convertToNumber(userData.offPeakRate) > billRateMax">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    <span *ngIf="convertToNumber(userData.offPeakRate) < billRateOffPeakMin">
                                        The amount you have entered is quite low.
                                    </span>
                                    <span *ngIf="convertToNumber(userData.offPeakRate) > billRateMax">
                                        The amount you have entered is quite high.
                                    </span>
                                    A typical amount would be between
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit == '$'">
                                        ${{ billRateOffPeakMin }} and ${{ billRateMax }}.
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_offpeak_rate_cost_unit == 'cents'">
                                        {{ billRateOffPeakMin }} and {{ billRateMax }} cents.
                                    </span>
                                    Please check that you have entered in the correct amount before continuing.
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- end ngIf: 1 == offpeak_disp -->
                <div class="row custom-padding" *ngIf="energyConfigForm['energyType'] == 'Electricity'">
                    <div class="col-md-6 form-padding">
                        <label class="h3" for="peak-rate">
                            {{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_flat_rate_name) }} rate for electricity usage:
                            <i
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="If the current plan only has one tariff, this will probably be called 'peak'. You might have peak and off-peak rates on your bill if you are on a flexible pricing plan. This means your electricity costs different prices at different times of the day. Peak is when electricity costs the most money. This might be during the evening."
                                aria-label="If the current plan only has one tariff, this will probably be called 'peak'. You might have peak and off-peak rates on your bill if you are on a flexible pricing plan. This means your electricity costs different prices at different times of the day. Peak is when electricity costs the most money. This might be during the evening."></i>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-xs-12 no-left-gutter form-group vertical-align">
                                <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_flat_rate_name) }} rate</label>
                                <input
                                    tabindex="9"
                                    type="text"
                                    formControlName="peakRate"
                                    id="peakRate"
                                    max="1"
                                    [(ngModel)]="userData.peakRate"
                                    step="0.01"
                                    [type]="'number'"
                                    (keydown)="selectButton($event, 'textInput')"
                                    class="form-control custom-input-text">
                                <bfv-messages></bfv-messages>
                                <p class="example hidden-xs hidden-sm">{{ retailerDetails.retailerSettings.lbl_dialog_exmp1 }}</p>
                            </div>
                            <div class="col-md-6 col-xs-12 form-padding">
                                <p class="total-suffix">Rate in <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_peak_rate_cost_unit }}</span> per <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_peak_rate_per_unit }}</span></p>
                            </div>
                        </div>
                        <div class="row" *ngIf="userData.peakRate != '' && convertToNumber(userData.peakRate) < billRateMin || convertToNumber(userData.peakRate) > billRateMax">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    <span *ngIf="convertToNumber(userData.peakRate) < billRateMin">
                                        The amount you have entered is quite low.
                                    </span>
                                    <span *ngIf="convertToNumber(userData.peakRate) > billRateMax">
                                        The amount you have entered is quite high.
                                    </span>
                                    A typical amount would be between
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_peak_rate_cost_unit == '$'">
                                        ${{ billRateMin }} and ${{ billRateMax }}.
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_peak_rate_cost_unit == 'cents'">
                                        {{ billRateMin }} and {{ billRateMax }}.
                                    </span>
                                    Please check that you have entered in the correct amount before continuing.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ngIf: 1 == offpeak_disp -->
                <div *ngIf="userData.offPeak == '1'">
                    <div class="row page-divider-up" role="radiogroup">
                        <div class="col-md-6 form-padding">
                            <label class="h3">Do you have {{ retailerDetails.retailerSettings.retailer_bill_shoulder_rate_name }} rates?
                            <i
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="You might have a shoulder rate if you are on a flexible pricing plan. The shoulder rate is for electricity used between peak and off-peak times."
                                aria-label="You might have a shoulder rate if you are on a flexible pricing plan. The shoulder rate is for electricity used between peak and off-peak times.">
                                </i>
                            </label>
                        </div>
                        <div class="col-md-6 form-padding">
                            <div class="row custom-padding radio-inline form-group">
                                <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_shoulder_rate_name) }}</label>
                                <input type="radio" id="shoulderRateYes" value="1" [(ngModel)]="userData.shoulder" formControlName="shoulder">
                                <label for="shoulderRateYes" class="custom-btn-default custom-btn-text" tabindex="10" (keydown)="selectButton($event, 'shoulderRateYes'); clearShoulderRate();" (click)="clearShoulderRate()">Yes</label>
                                <input type="radio" id="shoulderRateNo" value="0" [(ngModel)]="userData.shoulder" formControlName="shoulder">
                                <label for="shoulderRateNo" class="custom-btn-default custom-btn-text" tabindex="11" (keydown)="selectButton($event, 'shoulderRateNo'); clearShoulderRate();" (click)="clearShoulderRate()">No</label>
                                <bfv-messages class="col-md-6"></bfv-messages>
                            </div>
                        </div>
                    </div>
                    <!-- ngIf: 1 == shoulder_disp -->
                    <div class="row shoulder-rate-disp custom-padding" *ngIf="userData.shoulder == '1'">
                        <div class="col-md-6"></div>
                        <div class="col-md-6 form-padding">
                            <div class="row no-left-gutter">
                                <label for="shoulder-rate">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_shoulder_rate_name) }} rate for electricity use:</label>
                            </div>
                            <div class="row form-group">
                                <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_shoulder_rate_name) }} rate</label>
                                <div class="col-md-6 col-xs-12 no-left-gutter">
                                    <input
                                        tabindex="12"
                                        type="text"
                                        id="shoulderRate"
                                        formControlName="shoulderRate"
                                        step="0.01"
                                        [type]="'number'"
                                        class="form-control custom-input-text"
                                        (keydown)="selectButton($event, 'textInput')"
                                        [(ngModel)]="userData.shoulderRate">
                                    <bfv-messages></bfv-messages>
                                    <p class="example hidden-xs hidden-sm">{{ retailerDetails.retailerSettings.lbl_dialog_exmp1 }}</p>
                                </div>
                                <div class="col-md-6 form-padding">
                                    <p class="total-suffix">
                                        Rate in <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_shoulder_rate_cost_unit }}</span> per
                                        <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_shoulder_rate_per_unit }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="userData.shoulderRate != '' && convertToNumber(userData.shoulderRate) < billRateMin || convertToNumber(userData.shoulderRate) > billRateMax">
                                <div class="col-md-12">
                                    <div class="alert alert-warning">
                                        <span *ngIf="convertToNumber(userData.shoulderRate) < billRateMin">
                                            The amount you have entered is quite low.
                                        </span>
                                        <span *ngIf="convertToNumber(userData.shoulderRate) > billRateMax">
                                            The amount you have entered is quite high.
                                        </span>
                                        A typical amount would be between
                                        <span *ngIf="retailerDetails.retailerSettings.retailer_bill_peak_rate_cost_unit == '$'">
                                            ${{ billRateMin }} and ${{ billRateMax }}.
                                        </span>
                                        <span *ngIf="retailerDetails.retailerSettings.retailer_bill_peak_rate_cost_unit == 'cents'">
                                            {{ billRateMin }} and {{ billRateMax }}.
                                        </span>
                                        Please check that you have entered in the correct amount before continuing.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- end ngIf: 1 == shoulder_disp -->
                </div><!-- end ngIf: 1 == offpeak_disp -->
                <!-- //shoulder rate -->

                <div class="row custom-padding" *ngIf="energyConfigForm['energyType'] == 'Electricity' && controlledLoad == true">
                    <div class="col-md-6 form-padding">
                        <label class="h3" for="contoll-load-rate">
                            {{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_name) }}
                             rate for electricity use
                             {{ retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_name_suffix }}
                            <i
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="Controlled load is the price for electricity used on a separate circuit. Examples are underfloor slab heating or an electric hot water system. You might see this as a separate item on your bill."
                                aria-label="Controlled load is the price for electricity used on a separate circuit. Examples are underfloor slab heating or an electric hot water system. You might see this as a separate item on your bill."></i>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-xs-12 no-left-gutter form-group vertical-align">
                                <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_name) }} rate</label>
                                <input
                                    tabindex="13"
                                    type="text"
                                    formControlName="controlledLoadRate"
                                    id="controlledLoadRate"
                                    max="1"
                                    [(ngModel)]="userData.controlledLoadRate"
                                    step="0.01"
                                    [type]="'number'"
                                    (keydown)="selectButton($event, 'textInput')"
                                    class="form-control custom-input-text">
                                <bfv-messages></bfv-messages>
                                <p class="example hidden-xs hidden-sm">{{ retailerDetails.retailerSettings.lbl_dialog_exmp1 }}</p>
                            </div>
                            <div class="col-md-6 col-xs-12 form-padding">
                                <p class="total-suffix">
                                    Rate in <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit }}</span> per
                                    <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_per_unit }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="userData.controlledLoadRate != '' && convertToNumber(userData.controlledLoadRate) < controllLoadMin || convertToNumber(userData.controlledLoadRate) > controllLoadMax">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    <span *ngIf="convertToNumber(userData.controlledLoadRate) < controllLoadMin">
                                        The amount you have entered is quite low.
                                    </span>
                                    <span *ngIf="convertToNumber(userData.controlledLoadRate) > controllLoadMax">
                                        The amount you have entered is quite high.
                                    </span>
                                    A typical amount would be between
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == '$'">
                                        ${{ controllLoadMin }} and ${{ controllLoadMax }}.
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_controlled_load_rate_cost_unit == 'cents'">
                                        {{ controllLoadMin }} and {{ controllLoadMax }} cents.
                                    </span>
                                    Please check that you have entered in the correct amount before continuing.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row custom-padding" *ngIf="energyConfigForm['energyType'] == 'Electricity' && userData.hasSolar == true">
                    <div class="col-md-6 form-padding">
                        <label class="h3" for="solar-rate">
                            <!--Solar Feed in Tariff ({{ userData.feedInTariff }}) rate:-->
                            <!--make it same copy as the live version-->
                            Solar Feed in Tariff (FIT) rate:
                            <i
                                class="material-icons non-focus"
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="The rate agreed to with your retailer for electricity, generated through solar panels, exported into the electricity grid."
                                aria-label="The rate agreed to with your retailer for electricity, generated through solar panels, exported into the electricity grid."></i>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 col-xs-12 no-left-gutter form-group vertical-align">
                                <label class="hidden-label">Solar Feed in Tariff</label>
                                <input
                                    tabindex="14"
                                    type="text"
                                    formControlName="solarFitRate"
                                    id="solarFitRate"
                                    [(ngModel)]="userData.solarFitRate"
                                    step="0.01"
                                    [type]="'number'"
                                    (keydown)="selectButton($event, 'textInput')"
                                    class="form-control custom-input-text">
                                <bfv-messages></bfv-messages>
                                <p class="example hidden-xs hidden-sm">
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents'">
                                        example: 5.2
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$'">
                                        example: 0.052
                                    </span>
                                </p>
                            </div>
                            <div class="col-md-6 col-xs-12 form-padding">
                                <p class="total-suffix">
                                    Rate in <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit }}</span> per
                                    <span class="strong">{{ retailerDetails.retailerSettings.retailer_bill_flat_rate_per_unit }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="userData.solarFitRate != '' && convertToNumber(userData.solarFitRate) < fitRateMin || convertToNumber(userData.solarFitRate) > fitRateMax">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    The amount you have entered is quite
                                    <span *ngIf="convertToNumber(userData.solarFitRate) < fitRateMin">
                                        low.
                                    </span>
                                    <span *ngIf="convertToNumber(userData.solarFitRate) > fitRateMax">
                                        high.
                                    </span>
                                    A typical amount would be between
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == '$'">
                                        ${{ fitRateMin }} and ${{ fitRateMax }}.
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_flat_rate_cost_unit == 'cents'">
                                        {{ fitRateMin }} and {{ fitRateMax }} cents.
                                    </span>
                                    Please check that you have entered in the correct amount before continuing.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row page-divider-up no-right-gutter">
                    <div class="col-md-6 form-padding">
                        <div class="row no-gutters">
                            <div class="col-md-9 col-xs-12">
                                <label class="h3" for="elec-supply-charge">
                                    {{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_daily_charge_name) }} total:
                                    <i
                                        *ngIf="energyType == 'Electricity'"
                                        class="material-icons non-focus"
                                        tabindex="-1"
                                        [triggers]="utility.toolTipForMobile()"
                                        ngbTooltip="All electricity bills have a have a daily supply charge. The daily supply charge pays for the network that brings electricity to your home. The network includes things like poles and wires."
                                        aria-label="All electricity bills have a daily supply charge. The daily supply charge pays for the network that brings electricity to your home. The network includes things like poles and wires."></i>
                                    <i
                                        *ngIf="energyType == 'Gas'"
                                        class="material-icons non-focus"
                                        tabindex="-1"
                                        [triggers]="utility.toolTipForMobile()"
                                        ngbTooltip="All gas bills have a daily supply charge. The daily supply charge pays for the network that brings gas to your home. The network includes things like poles and wires."
                                        aria-label="All gas bills have a daily supply charge. The daily supply charge pays for the network that brings gas to your home. The network includes things like poles and wires."></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6 form-group form-padding supply-charge">
                                <label class="hidden-label">{{ utility.titleCase(retailerDetails.retailerSettings.retailer_bill_daily_charge_name) }}</label>
                                <input
                                    tabindex="15"
                                    type="text"
                                    id="supplyCharge"
                                    [readonly]="supplyDays == 0"
                                    formControlName="supplyCharge"
                                    class="form-control"
                                    step=".1"
                                    [type]="'number'"
                                    (change)="getSupplyDays()"
                                    (keydown)="selectButton($event, 'textInput')"
                                    [(ngModel)]="userData.supplyCharge">
                                <bfv-messages></bfv-messages>
                                <p *ngIf="energyType != 'Gas'" class="total hidden-xs hidden-sm">Total in {{ retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit }}</p>
                                <p *ngIf="energyType == 'Gas'" class="total hidden-xs hidden-sm">Total in {{ retailerDetails.retailerSettings.retailer_bill_gas_daily_charge_cost_unit }}</p>
                                <p></p>
                            </div>
                            <div class="col-md-6 form-padding">
                                <p class="total-suffix">for <span class="strong">{{ supplyDays }}</span> days of {{ energyType.toLowerCase() }} supply</p>
                            </div>
                        </div>
                        <!--electricity-->
                        <div class="row" *ngIf="energyType != 'Gas' && userData.supplyCharge != ''
                            && (convertToNumber(userData.supplyCharge) < (supplyDays * dailyChargeUnitMin) || convertToNumber(userData.supplyCharge) > (supplyDays * dailyChargeUnitMax))">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    The amount you have entered is quite
                                    <span *ngIf="convertToNumber(userData.supplyCharge) < (supplyDays * dailyChargeUnitMin)">
                                        low.
                                    </span>
                                    <span *ngIf="convertToNumber(userData.supplyCharge) > (supplyDays * dailyChargeUnitMax)">
                                        high.
                                    </span>
                                    A typical amount would be between
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$'">
                                        ${{ supplyDays * dailyChargeUnitMin | number : '1.2-2' }} and
                                        ${{ supplyDays * dailyChargeUnitMax | number : '1.2-2' }}.
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == 'cents'">
                                        {{ supplyDays * dailyChargeUnitMin  }} and
                                        {{ supplyDays * dailyChargeUnitMax }}.
                                    </span>
                                    for your billing period. Please check that you have entered in the correct amount before continuing.
                                </div>
                            </div>
                        </div>
                        <!--gas-->
                        <div class="row" *ngIf="energyType == 'Gas' && userData.supplyCharge != ''
                            && (convertToNumber(userData.supplyCharge) < (supplyDays * dailyGasChargeUnitMin) || convertToNumber(userData.supplyCharge) > (supplyDays * dailyGasChargeUnitMax))">
                            <div class="col-md-12">
                                <div class="alert alert-warning">
                                    The amount you have entered is quite
                                    <span *ngIf="convertToNumber(userData.supplyCharge) < (supplyDays * dailyGasChargeUnitMin)">
                                        low.
                                    </span>
                                    <span *ngIf="convertToNumber(userData.supplyCharge) > (supplyDays * dailyGasChargeUnitMax)">
                                        high.
                                    </span>
                                    A typical amount would be between
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == '$'">
                                        ${{ supplyDays * dailyGasChargeUnitMin | number : '1.2-2' }} and
                                        ${{ supplyDays * dailyGasChargeUnitMax | number : '1.2-2' }}.
                                    </span>
                                    <span *ngIf="retailerDetails.retailerSettings.retailer_bill_daily_charge_cost_unit == 'cents'">
                                        {{ supplyDays * dailyGasChargeUnitMin  }} and
                                        {{ supplyDays * dailyGasChargeUnitMax }}.
                                    </span>
                                    for your billing period. Please check that you have entered in the correct amount before continuing.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding" role="radiogroup">
                    <div class="col-md-6 form-padding">
                        <label class="h3">
                            Do you have any discounts?
                            <i
                                class="material-icons non-focus"
                                aria-label="Some retailers offer a cheaper rate if you do certain things. For example, if you pay by direct debit, some retailers will reduce your bill. Other retailers might offer a discount if you pay on time. If your retailer offers a discount on your bill, add it here."
                                tabindex="-1"
                                [triggers]="utility.toolTipForMobile()"
                                ngbTooltip="Some retailers offer a cheaper rate if you do certain things. For example, if you pay by direct debit, some retailers will reduce your bill. Other retailers might offer a discount if you pay on time. If your retailer offers a discount on your bill, add it here."></i>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <div class="row custom-padding radio-inline form-group vertical-align">
                            <label class="hidden-label">Discount</label>
                            <input type="radio" id="elecDiscountYes" value="1" [(ngModel)]="userData.discount" formControlName="discount">
                            <label for="elecDiscountYes" class="custom-btn-default custom-btn-text" tabindex="16" (keydown)="selectButton($event, 'elecDiscountYes')">Yes</label>
                            <input type="radio" id="elecDiscountNo" value="0" [(ngModel)]="userData.discount" formControlName="discount">
                            <label for="elecDiscountNo" class="custom-btn-default custom-btn-text" tabindex="17" (keydown)="selectButton($event, 'elecDiscountNo')">No</label>
                            <bfv-messages class="col-md-6"></bfv-messages>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding" *ngIf="userData.discount == '1'">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <div class="row no-left-gutter">
                            <label for="elec-discount">Discounts:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-xs-12 no-left-gutter form-group">
                                <label class="hidden-label">Discount amount</label>
                                <input
                                    tabindex="18"
                                    type="text"
                                    id="discountAmount"
                                    formControlName="discountAmount"
                                    max="100"
                                    step="1"
                                    [type]="'number'"
                                    class="form-control custom-input-text"
                                    (keydown)="selectButton($event, 'textInput')"
                                    [(ngModel)]="userData.discountAmount">
                                <bfv-messages></bfv-messages>
                                <p class="example">example: 20</p>
                            </div>
                            <div class="col-md-6 col-xs-4">
                                <p class="total-suffix">%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="radiogroup" class="row custom-padding" *ngIf="userData.discount == '1'">
                    <div class="col-md-6 form-padding">
                        <label class="h3">
                            Discount applied to:
                            <i
                                class="material-icons non-focus"
                                tabindex="-1"
                                tooltip-placement="auto"
                                [triggers]="utility.toolTipForMobile()"
                                aria-label="Select whether the discount applies to the usage or total bill."
                                ngbTooltip="Select whether the discount applies to the usage or total bill."></i>
                        </label>
                    </div>
                    <div class="col-md-6 vertical-align">
                        <div class="row custom-padding radio-inline form-group">
                            <label class="hidden-label">Discount applied to</label>
                            <input type="radio" id="discountUsage" value="usage" formControlName="discountType" [(ngModel)]="userData.discountType">
                            <label for="discountUsage" class="custom-btn-default custom-btn-text" tabindex="19" (keydown)="selectButton($event, 'usage')">
                                Usage
                            </label>
                            <input type="radio" id="discountBill" value="bill" formControlName="discountType" [(ngModel)]="userData.discountType">
                            <label for="discountBill" class="custom-btn-default custom-btn-text" tabindex="20" (keydown)="selectButton($event, 'bill')">
                                Total bill
                            </label>
                            <bfv-messages class="col-md-6"></bfv-messages>
                        </div>
                    </div>
                </div>
                <div class="row custom-padding">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            (click)="submitRates()"
                            type="submit"
                            class="custom-button rate-details-btn"
                            tabindex="21">
                            Submit these rate details
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
