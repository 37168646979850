<div id="container">
  <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails">
  </app-banner>
  <br />

  <div>
    <div class="col-md-12 col-xs-12">
      <img class="en-image" src="assets/images/{{ secImageURL }}contentpages/outreach_header.jpeg">
      <h2 class="subTitle">Overview</h2>
      <p>
        Electricity embedded networks are privately owned and managed electricity networks that supply electricity to
        multiple customers within a specific area or building. Embedded networks generally buy electricity in bulk and
        then on-sell it to customers inside their network. The electricity is supplied to these properties by a
        privately owned and managed supplier.
      </p>
      <br />
      <p>
        The amount you can be charged for electricity is capped for all households and most businesses in embedded
        networks. The maximum price for embedded networks is the Victorian Default Offer which is set annually by the
        Essential Services Commission. You can find out more about the <a
          href="https://www.esc.vic.gov.au/electricity-and-gas/prices-tariffs-and-benchmarks/victorian-default-offer"
          target="_blank">Victorian Default Offer</a> and how much you can be charged on the Embedded Network tariff
        page on the <a
          href="https://www.esc.vic.gov.au/electricity-and-gas/tariffs-and-benchmarks/embedded-network-tariffs-including-caravan-parks"
          target="_blank">Essential Services Commission</a> website.
      </p>
      <br />
      <h2 class="subTitle">2024-25 Energy Bill Relief Program for Embedded Network Customers</h2>
      <p>
        Households in embedded networks such as a caravan park, residential community, retirement village or apartment
        building will have to apply for the payment via an application on this website from early October 2024. Embedded
        network households will receive a one-off payment of $300.
      </p>
      <br>
      <p>
        Small businesses in embedded networks, such as shopping centres and apartment buildings will also need to apply
        for the payment via an application on this website from early October 2024. Eligible small businesses will
        receive a one-off payment of $325.
      </p>
      <br />
      <p>
        Embedded network customers of Origin, WinConnect and Momentum will have their bill relief automatically applied
        via their retailer.
      </p>
      <br />
      <br />
      <h2 class="subTitle">Frequently Asked Questions</h2>
      <br />
      <h4 class="en-header-sub">Are embedded network customers eligible for the 2024-25 Energy Bill Relief Fund?</h4>
      <p>
        Yes. Households in embedded networks (such as where you receive electricity from your strata or landlord in a
        caravan park, apartment building or retirement home or village) are eligible for the bill relief payment.
      </p>
      <br />
      <p>
        Small businesses in embedded networks that have a separately metered business tariff and an annual electricity
        consumption below 40 MWh are also eligible for the payment.
      </p>
      <br>
      <h4 class="en-header-sub">How do embedded network customers apply for the Energy Bill Relief? </h4>
      <p>
        Embedded network customers will need to apply for the bill relief via an application on this website in early
        October 2024.
      </p>
      <br>
      <h4 class="en-header-sub">How will payments be made to embedded network households?  </h4>
      <p>
        The Energy Bill Relief payment for embedded network households will be provided as a one-off payment of $300 and
        will be paid via bank cheque or Electronic Funds Transfer (EFT). In certain circumstances, it may be necessary
        for your payment to be made via bank cheque. 
      </p>
      <br>
      <h4 class="en-header-sub">How will payments be made to eligible small businesses in embedded networks?  </h4>
      <p>
        The Energy Bill Relief payment for eligible small businesses in embedded networks will be provided as a one-off
        payment of $325 and will be paid via bank cheque or Electronic Funds Transfer (EFT). In certain circumstances,
        it may be necessary for your payment to be made via bank cheque. 
      </p>
      <br>
      <h4 class="en-header-sub">Why are embedded network customers receiving one-off payments, rather than quarterly?
      </h4>
      <p>
        To reduce administrative burden and due to the complexity of embedded networks and system limitations,
        households in embedded networks will receive one payment of $300 and small businesses one payment of $325.
      </p>
      <br>
      <h4 class="en-header-sub">How do I receive the Energy Bill Relief payment if I am an embedded network customer of
        Origin, WinConnect or Momentum?</h4>
      <p>
        If you are an embedded network customer of Origin, WinConnect or Momentum, you will automatically receive your
        Energy Bill Relief payment through your retailer. Contact your retailer for further details.
      </p>
      <br>

      <h4 class="en-header-sub">What support is available to help me submit my application? </h4>
      <p>
        If you need help to apply, the following support options may be able to help.
      </p>
      <ul>
        <li><strong>Friends and family members:</strong> If someone you know has a computer with the internet, ask if
          you can visit them and use it to access <a href="/embedded-networks">Victorian Energy Compare</a>. </li>
        <li><strong>Community based support:</strong> Your local member’s electorate office may be able to help you
          submit an application. Support may also be available through libraries, council support services or local
          community groups. </li>
        <li><strong>Phone support:</strong> You can call 1800 000 VEC (832) to receive advice on how to apply for the
          Energy Bill Relief for embedded network customers. </li>
        <li><strong>Email:</strong> You can email our support team and they can help you navigate the online application
          process. They can be contacted at info.vec@deeca.vic.gov.au. </li>
      </ul>
      <br>

      <h4 class="en-header-sub">What was the 2023-24 Energy Bill Relief Program for eligible Embedded Network
        Households? </h4>
      <p>
        The Energy Bill Relief Program for Embedded Network Households was a $250 payment for eligible recipients of
        government concessions. The program closed for new applications on 30 June 2024. Please contact <a
          href="mailto:info.vec@deeca.vic.gov">info.vec@deeca.vic.gov</a>.au for enquiries relating to existing
        applications.
      </p>
      <br>

      <h4 class="en-header-sub">What is an embedded network?</h4>
      <p>
        An embedded network is a private electricity network that is operated by a single entity, such as a property
        owner or an external network manager. The party is known as an embedded network operator.
      </p>
      <br />
      <p>The network is used to supply energy to multiple customers within a specific location or development, such as
        an apartment building, caravan park or retirement village.</p>
      <br />
      <p>To supply the energy, the owner or operator of the network purchases electricity from a licensed energy
        retailer or wholesaler. They then on-sell that electricity to residents of individual properties within the
        network, e.g. an apartment complex.</p>
      <br />
      <h4 class="en-header-sub">How can I find out if I live in an embedded network?</h4>
      <p>
        If you live in a retirement village, caravan park or some apartment buildings, it is likely that you receive
        your electricity through an embedded network. Use the following steps to determine whether your property is in
        an embedded network:
      </p>
      <ul>
        <li>Check your electricity bill or invoice. If you can see a National Metering Identifier (NMI) on your bill,
          you are unlikely to live in an embedded network. </li>
        <li>Speak to the body corporation or property manager and ask if your property is part of an embedded network
          for your electricity supply.</li>
        <li>You can visit the <a href="https://escvic.microsoftcrmportals.com/rex-home/" target="_blank">Essential
            Services Commission public register</a> to check whether you are in an embedded network. Please note that
          some embedded networks are not included in the list.</li>
      </ul>
      <h4 class="en-header-sub">Can I switch electricity retailer if I live in an embedded network?</h4>
      <p>
        If you live in an embedded network and want to switch to a new supplier, you will need to contact the property
        owner or manager, such as the body corporation, to discuss options for switching to a new embedded network
        manager.
      </p>
      <br />
      <p>For more information about embedded networks, you can visit the <a
          href="https://www.esc.vic.gov.au/electricity-and-gas/information-consumers/information-embedded-electricity-network-customers"
          target="_blank">Essential Services Commission</a> and <a
          href="https://www.ewov.com.au/fact-sheets/embedded-network-basics" target="_blank">Energy and Water Ombudsman
          websites</a>.
      </p>
    </div>
  </div>
</div>
<br /><br />
