<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <img
                class="hottopic-image"
                src="assets/images/{{ secImageURL }}contentpages/hottopics_header_4.jpg"
                alt="Renting? Six ways to save on energy bills as a renter">
            <p>
                It's no secret that renting in a share house can be expensive. When you consider that multiple people are cooking dinner
                separately, doing their washing in single loads and heating and cooling all the bedrooms, the energy bills will really
                start to stack up.
            </p>
            <p>
                For renters, it can sometimes feel like there's not much you can do about your house’s energy use - you don't have much
                control over things insulation, lighting, heating and cooling systems or fixed appliances. But there's still a lot you
                can do to take charge of your energy bills. Here are six tips on how to save energy and money as a renter.
            </p>
            <ol>
                <li>
                    <span class="strong">
                        Compare electricity and gas providers to find the cheapest offer for you.
                    </span>
                    <p>
                        7 out of 10 Victorian Households are paying too much, and you should find out if your house is one of them. All
                        you need to do is grab your latest energy bill and head to the independent energy compare website,
                        <a class="hot-topics" href="{{ appURL }}" target="_blank">Victorian Energy Compare</a>. Most households save
                        an average of $330 in the first year alone.
                    </p>
                </li>
                <li>
                    <span class="strong">Get energy savvy around the house.</span>
                    <p>
                        Change the light bulbs on any lamps you own to LED bulbs (which use 75% less energy!), wrap your hot water pipes
                        with insulator to keep them from losing heat, make sure your windows and doors are draught sealed by buying a
                        sealant yourself and rug up in winter to save on heating!
                    </p>
                </li>
                <li>
                    <span class="strong">Save energy while you sleep.</span>
                    <p>
                        Finished with the TV or the microwave? Up to 10% of your electricity usage comes from appliances on standby.
                        Switch your appliances off at the wall when you're not using them, and when you go to sleep, to save.
                    </p>
                </li>
                <li>
                    <span class="strong">Be energy efficient with your heating and cooling.</span>
                    <p>
                        Every degree you set the heating to, above 20˚, can add 10% to your heating bill in winter. The same goes for
                        setting your thermostat below 24˚ in summer.
                    </p>
                </li>
                <li>
                    <span class="strong">Wash efficiently.</span>
                    <p>
                        Washing your clothes in cold water, selecting the shortest cycle on your machine and washing only full loads
                        can save you around $115 per year.
                    </p>
                </li>
                <li>
                    <span class="strong">Check if you're eligible for a discount!</span>
                    <p>
                        If someone in your house is a Health Care Card holder, you're eligible for 17.5% off your annual bills. There
                        are other concessions that you might be eligible for too, you can
                        <a class="hot-topics" href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills" target="_blank">check them out here</a>.
                    </p>
                </li>
            </ol>
            <p>
                It's easy to take control of your energy bills if you know where to look. Head to
                <a class="hot-topics" href="https://www.victorianenergysaver.vic.gov.au/" target="_blank">Victorian Energy Saver</a> for energy saving tips
                and check out our <a class="hot-topics" href="{{ appURL }}" target="_blank">independent comparison tool</a> to compare
                electricity and gas providers and make sure your house is on the cheapest offer.
            </p>
            <br />
            <p>
                <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
            </p>
        </div>
    </div>
</div>
<br /><br />
