import { Component, OnInit, Input } from '@angular/core';
import { FaqOffersModalComponent } from '../faq-offers-modal/faq-offers-modal.component';
import { FaqCompareModalComponent } from '../faq-compare-modal/faq-compare-modal.component';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment'; //config file

@Component({
  selector: 'app-offer-banner-panel',
  templateUrl: './offer-banner-panel.component.html',
  styleUrls: ['./offer-banner-panel.component.css']
})
export class OfferBannerPanelComponent implements OnInit {
  @Input() hasConcession;
  @Input() userType;
  @Input() bill;
  @Input() averageDailyConsumption;
  @Input() loopBackAverageDailyConsumption;
  @Input() loopBackEnergyType;
  @Input() energyType;
  @Input() loopBackIsEmpty;
  public appURL = environment.appURL;
  constructor(public config : NgbModalConfig,
    public modalService : NgbModal,
    public router : Router) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
  }


  public faqModal() {
    if (this.router.url == '/offers') {
      this.modalService.open(FaqOffersModalComponent, { size : 'xl' });
    } else {
      this.modalService.open(FaqCompareModalComponent, { size : 'xl' });
    }
  }

}
