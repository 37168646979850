<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row" *ngIf="messageIsSent == false">
        <div class="col-lg-7 col-md-7 col-xs-12">
            <h3>Send us your feedback</h3>
            <br>
            <div class="alert alert-danger" *ngIf="errorMessage != ''">
                {{ errorMessage }}
            </div>

            <form [formGroup]="FeedbackForm" onsubmit="return false" (keydown.enter)="$event.preventDefault()">
                <input type="hidden" formControlName="recaptchaResponse" [(ngModel)]="recaptchaResponse">
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="firstName" class="strong">First Name</label> *
                                <input type="text" aria-label="First Name" class="form-control" formControlName="firstName" [(ngModel)]="contactData.firstName" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.firstName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="lastName" class="strong">Last Name</label> *
                                <input type="text" aria-label="Last Name" class="form-control" formControlName="lastName" [(ngModel)]="contactData.lastName" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.lastName}}</p>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="email" class="strong">Email Address</label> *
                                <input id="email" type="text" class="form-control" formControlName="email" [(ngModel)]="contactData.email" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.email}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="emailConfirm" class="strong">Confirm Email Address</label> *
                                <input id="emailConfirm" type="text" class="form-control" appConfirmEqualValidator="email" formControlName="emailConfirm" [(ngModel)]="contactData.emailConfirm" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.emailConfirm}}</p>

                            </div>
                            <div class="alert alert-danger" *ngIf="fbForm.emailConfirm.touched && fbForm.emailConfirm.errors?.notEqual && !fbForm.emailConfirm.errors?.required">
                                    Email Address and Confirm Email Address does not match
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="phoneNumber" class="strong">Phone Number</label>
                                <input type="text" id="phoneNumber" aria-label="Phone Number" class="form-control" formControlName="phoneNumber" [(ngModel)]="contactData.phoneNumber" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.phoneNumber}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="postcode" class="strong">Postcode</label> *
                                <input type="text" aria-label="Postcode" class="form-control" formControlName="postcode" [(ngModel)]="contactData.postcode" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.postcode}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="natureOfEnquiry" class="strong">Nature of Enquiry</label> *
                                <select class="custom-select" formControlName="natureOfEnquiry" [(ngModel)]="contactData.natureOfEnquiry" *ngIf="reviewForm == false">
                                    <option value="" disabled>Choose one</option>
                                    <option *ngFor="let enquiry of natureOfEnquiry" [ngValue]="enquiry">{{enquiry}}</option>
                                </select>
                                <p *ngIf="reviewForm == true">{{contactData.natureOfEnquiry}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="enquiryRelatingTo" class="strong">Enquiry Relating To</label> *
                                <select class="custom-select" formControlName="enquiryRelatingTo" [(ngModel)]="contactData.enquiryRelatingTo" *ngIf="reviewForm == false">
                                    <option value="" disabled>Choose one</option>
                                    <option *ngFor="let enquiry of enquiryRelatingTo" [ngValue]="enquiry">{{enquiry}}</option>
                                </select>
                                <p *ngIf="reviewForm == true">{{contactData.enquiryRelatingTo}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="contactData.enquiryRelatingTo == 'Power Saving Bonus'">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="name" class="strong">PSB application number (if known)</label>
                                <input type="text" aria-label="PSB application number" placeholder="Optional" class="form-control" formControlName="refNumber" [(ngModel)]="contactData.refNumber" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.refNumber}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="contactData.enquiryRelatingTo == 'Energy Bill Relief'">
                      <div class="col-lg-11 col-md-11 col-xs-12">
                          <div class="form-group">
                              <label for="name" class="strong">EBR application number (if known)</label>
                              <input type="text" aria-label="EBR application number" placeholder="Optional" class="form-control" formControlName="refNumber" [(ngModel)]="contactData.refNumber" *ngIf="reviewForm == false">
                              <p *ngIf="reviewForm == true">{{contactData.refNumber}}</p>
                          </div>
                      </div>
                  </div>
                    <div class="row" *ngIf="contactData.enquiryRelatingTo == 'Energy Offer Comparison'">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="name" class="strong">Offer ID (if known)</label>
                                <input type="text" aria-label="Offer ID" class="form-control" formControlName="offerIdNumber" [(ngModel)]="contactData.offerIdNumber" *ngIf="reviewForm == false">
                                <p *ngIf="reviewForm == true">{{contactData.offerIdNumber}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="contactData.natureOfEnquiry == 'Complaint'">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="enquiryRelatingTo" class="strong">Have you contacted us previously?</label> *
                                <div class="row radio-inline" *ngIf="reviewForm == false">
                                    <input type="radio" id="contactedBeforeYes" value="1" formControlName="contactedBefore" [(ngModel)]="contactData.contactedBefore">
                                    <label for="contactedBeforeYes" class="custom-btn-sm custom-btn-text-sm">Yes</label>
                                    <input type="radio" id="contactedBeforeNo" value="0" formControlName="contactedBefore" [(ngModel)]="contactData.contactedBefore">
                                    <label for="contactedBeforeNo" class="custom-btn-sm custom-btn-text-sm">No</label>
                                </div>
                                <p *ngIf="reviewForm == true">{{contactData.contactedBefore == '1'?'Yes':'No'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-xs-12">
                            <div class="form-group">
                                <label for="message" class="strong">Message</label> *
                                <br />
                                <textarea name="message" rows="5" class="form-control"
                                formControlName="message" [(ngModel)]="contactData.message" *ngIf="reviewForm == false"></textarea>
                                <p *ngIf="reviewForm == true">{{contactData.message}}</p>
                            </div>
                        </div>
                    </div>
                    <span class="msg-error error"></span>
                <p>&nbsp;</p>

                <button type="button" name="reset" class="btn btn-danger" (click)="resetForm()" *ngIf="reviewForm == false">
                    <i class="fa fa-undo"></i> Reset</button>
                <button type="submit" name="review" id="review" class="btn btn-primary" (click)="review()" *ngIf="reviewForm == false">
                    <i class="fa fa-paper-plane"></i> Next</button>
                <button type="button" name="back" id="back" class="btn btn-secondary" (click)="back()" *ngIf="reviewForm == true">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i> Back</button>
                <button type="submit" name="submit" id="submit" class="btn btn-primary" (click)="sendMessage()" *ngIf="reviewForm == true">
                    <i class="fa fa-paper-plane"></i> Submit</button>
            </form>
        </div>
        <div class="col-lg-5 col-md-5 col-xs-12">
        </div>
    </div>

    <div class="row" *ngIf="messageIsSent == true">
        <div class="col-lg-12 col-md-10 col-xs-12">
            <h3>Thank you</h3>
            <br>
            <div class="alert alert-danger" *ngIf="emailStatus == 'error'">
                An error has occured while trying to send the email, please try again later.
            </div>
        </div>
    </div>

    <div class="row" *ngIf=" emailStatus == 'success'">
        <div class="col-lg-12 col-md-8 col-xs-12" *ngIf="contactData.natureOfEnquiry == 'Feedback'">
            <p>Thank you for providing your feedback.<br><br>
                You will receive an email confirming receipt of your feedback shortly.</p>
        </div>
        <div class="col-lg-12 col-md-8 col-xs-12" *ngIf="contactData.natureOfEnquiry == 'Complaint'">
            <p>Thank you for providing your feedback.<br><br>
                The reference number for your complaint is #{{feedbackId}}.<br><br>
                You will also receive an email confirming this reference number shortly. Please include this reference number in all correspondence about this matter.<br><br>
                A member of the team will be in contact within 10 business days.</p>
        </div>
        <div class="col-lg-12 col-md-8 col-xs-12" *ngIf="contactData.natureOfEnquiry == 'Compliment'">
            <p>Thank you for providing your feedback.<br><br>
                You will receive an acknowledgment email from the team shortly.</p>
        </div>
    </div>

    <br /><br />
    <div class="row">
        <div class="col-lg-10 col-md-10 col-xs-10">
            <h3>Call us</h3>
            <p>
                Call <span class="strong">1800 000 VEC (832)</span> from anywhere in Australia, Monday to Friday, 9am to 5pm.
                Costs may vary - mobiles and  public telephones may incur additional costs.
            </p>
            <p>
                If you are deaf, or have a hearing impairment or speech impairment, contact us throught the National
                Relay Service on 133 677 or visit
                <a href="http://www.relayservice.gov.au" target="_blank">www.relayservice.gov.au</a>
            </p>
            <br>
            <h3>Contact us by post</h3>
            <p>
                <span class="strong">Victorian Energy Compare team</span><br>
                <span class="strong">PO Box 500</span><br>
                <span class="strong">East Melbourne VIC 8002</span>
            </p>
        </div>
    </div>

</div>
<br /><br />
