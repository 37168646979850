<div id="container">
    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row hottopic-content">
        <div class="col-md-12 col-xs-12">
            <div>
                <img class="hottopic-image" src="assets/images/contentpages/hottopics_header_1.jpg" alt="Why is my electricity bill so high?">
                <p>There's nothing worse than opening your energy bill and finding it's higher than expected. Take charge, and lower your electricity bills by considering the following options.</p>
                <ol>
                    <li>
                        <span class="strong">
                            Make sure you're paying the lowest electricity prices.
                        </span>
                        <p>
                            No one wants to pay too much for their power, but 7 out of 10 Victorians are. To make sure you're
                            not paying too much - <a class="hot-topics" href="{{ appURL }}">visit Victorian Energy Compare</a>,
                            it's an independent website that compares all the energy retailers' prices. By using the site, you can
                            shop around, and make sure you've got the best energy offer available. Most people save an average of
                            $330 in the first year alone.
                        </p>
                    </li>
                    <li>
                        <span class="strong">
                            Heat and cool your space efficiently.
                        </span>
                        <p>
                            No matter what time of year it is - it's important to check your house is properly sealed and insulated.
                            If your walls and windows aren't air-tight, you might notice that your winter heating and your summer
                            air-conditioning aren't working as well as they should, and are costing you more than expected. Having
                            a well-insulated home can save you up to 45% on your energy usage per year. The right insulation makes
                            your house like an esky in summer and like a thermos in winter, leading to less reliance on heating and
                            cooling systems.
                        </p>
                    </li>
                    <li>
                        <span class="strong">
                            Get savvy around the house.
                        </span>
                        <p>
                            There are lots of ways to save power and money at home. Did you know that standby power appliances like TVs
                            and computers are sucking up electricity even when you turn them off? Flick off those appliances at the
                            power point when you go to bed, and you'll save on bills while you sleep. You can also save money by cutting
                            down your hot water use - try washing your clothes in cold water and shortening time in the shower. And
                            consider switching to LED bulbs for your home lighting, which use up to 75% less energy.
                        </p>
                    </li>
                    <li>
                        <span class="strong">
                            Stay switched on to your power bills.
                        </span>
                        <p>
                            Energy prices change over time, so even if you're confident you've got the best deal right now, don't forget to
                            check back with Victorian Energy Compare regularly. It's constantly updated with the latest offers from energy
                            retailers so you can make sure you're getting the best deal all year round.
                        </p>
                    </li>
                </ol>
                <p>
                    It's easy to take charge of your energy use if you know what to do! Head to our independent
                    <a class="hot-topics" href="{{ appURL }}" target="_blank">comparison website</a>
                    today and make sure you're on the cheapest offer for your house.
                </p>
                <p>
                    Need help with your energy bills? The
                    <a class="hot-topics" href="https://www.energy.vic.gov.au/for-households/help-paying-your-bills" target="_blank">Energy</a>
                    website has information about the support available.
                </p>
                <br>
                <p>
                    <a (click)="mainHotTopics()" class="hot-topics">See more hot topics</a>
                </p>
            </div>
        </div>
    </div>
</div>
<br /><br />
