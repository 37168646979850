import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from '../utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hot-topics-best-offer',
  templateUrl: './hot-topics-best-offer.component.html',
  styleUrls: ['./hot-topics-best-offer.component.css']
})
export class HotTopicsBestOfferComponent implements OnInit {

  public appURL = environment.appURL;
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand/' : '';
  public bannerTitle = "Unlock energy bill savings with your retailer’s best offer";
  public bannerSubTitle = "";
  public bannerDetails = "";

  constructor(
    public router : Router,
    public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Hot Topics - Unlock energy bill savings with your retailer’s best offer  - Victorian Energy Compare';
  }

  public mainHotTopics() {
    this.router.navigate(['/hot-topics']);
    this.utility.scrollToTop('none');
  }
}
