import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from './data.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { Utility } from './utility';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { requireCheckboxesToBeCheckedValidator } from './shared/directives/require-checkboxes-to-be-checked.validator';


@Injectable({
  providedIn: 'root'
})

export class EnergyQuestionnaireForm {
  public saveFormError = '';
  public questionnaireForm: FormGroup;
  public bannerTitle = "Energy questionnaire";
  public bannerSubTitle = "";
  public bannerDetails = "The questionnaire will take around 5 minutes to complete.";
  public bill;
  public progress = 0;
  public solarCapacityDontKnow = false;

  public businessHoursValidation = '';
  public businessDaysValidation = '';
  public appliancesValidation = '';
  public solarCapacityValidation = null;

  public industries: any;
  public premisesTypes: any;

  public days = [
    { name: 'Monday', value: 'days1' },
    { name: 'Tuesday', value: 'days2' },
    { name: 'Wednesday', value: 'days3' },
    { name: 'Thursday', value: 'days4' },
    { name: 'Friday', value: 'days5' },
    { name: 'Saturday', value: 'days6' },
    { name: 'Sunday', value: 'days7' }
  ];

  public businessHours = [
    { name: 'Mornings ', value: 'hoursMornings' },
    { name: 'Afternoons ', value: 'hoursAfternoons' },
    { name: 'Evenings ', value: 'hoursEvenings' },
    { name: 'Overnight', value: 'hoursOvernight' },
  ];

  public employeesOnSite = [
    { name: '1-4', value: '1-4' },
    { name: '5-20', value: '5-20' },
    { name: '21-50', value: '21-50' },
    { name: '51-100', value: '51-100' },
    { name: '101-200', value: '101-200' },
    { name: 'Other', value: 'other' },
  ];

  public haveGas = [
    { name: 'Yes, my business has mains connected gas', value: 'Mains' },
    { name: 'Yes, my business has bulk bottled gas', value: 'Bottled' },
    { name: 'No', value: 'None' },
  ];

  public electricitySupply = ['Single phase', 'Three phase', "I don\'t know"];


  public energyQuestionnaire = {
    serverCacheId: "",
    // userType : "",
    smbPeakOffpeakConsumptionSummaryId: "",
    smbWeekdayWeekendConsumptionSummaryId: "",
    // energyType : "",
    distributor: "",
    postcode: "",
    averageDailyConsumption: "",
    industryId: '',
    industryOther: "",
    hoursMornings: false,
    hoursAfternoons: false,
    hoursEvenings: false,
    hoursOvernight: false,
    days1: false,
    days2: false,
    days3: false,
    days4: false,
    days5: false,
    days6: false,
    days7: false,
    premisesTypeId: '',
    premisesTypeOther: "",
    employeesOnSite: "",
    employeesOnSiteOther: "",
    gasSupply: "",
    appliance1: "",
    appliance2: "",
    appliance3: "",
    electricitySupply: "",
    hasSolar: "",
    solarCapacity: "",
    created: "",

    pageDataType: "energyQuestionnaireData",
    loopBack: false
  }

  public loopBackValues = {
    hasConcession: "",
    postcode: "",
    hasSolar: ""
  }

  constructor(
    public dataService: DataService,
    public router: Router,
    public utility: Utility,
    public formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    public config: NgbModalConfig,
    public modalService: NgbModal,
    private tooltipConfig: NgbTooltipConfig) {
  }


  public resetForm() {
    this.questionnaireForm.reset();
    this.energyQuestionnaire = {
      serverCacheId: "",
      smbPeakOffpeakConsumptionSummaryId: "",
      smbWeekdayWeekendConsumptionSummaryId: "",
      distributor: "",
      postcode: "",
      averageDailyConsumption: "",
      industryId: '',
      industryOther: "",
      hoursMornings: false,
      hoursAfternoons: false,
      hoursEvenings: false,
      hoursOvernight: false,
      days1: false,
      days2: false,
      days3: false,
      days4: false,
      days5: false,
      days6: false,
      days7: false,
      premisesTypeId: '',
      premisesTypeOther: "",
      employeesOnSite: "",
      employeesOnSiteOther: "",
      gasSupply: "",
      appliance1: "",
      appliance2: "",
      appliance3: "",
      electricitySupply: "",
      hasSolar: "",
      solarCapacity: "",
      created: "",

      pageDataType: "energyQuestionnaireData",
      loopBack: false
    }
  }

  public createForm() {
    this.questionnaireForm = this.formBuilder.group({
      industryId: new FormControl('', [Validators.required]),
      industryOther: new FormControl('', [Validators.required]),

      businessHoursCheckboxGroup: new FormGroup({
        hoursMornings: new FormControl(''),
        hoursAfternoons: new FormControl(''),
        hoursEvenings: new FormControl(''),
        hoursOvernight: new FormControl(''),
      }, requireCheckboxesToBeCheckedValidator()),

      businessDaysCheckboxGroup: new FormGroup({
        days1: new FormControl(''),
        days2: new FormControl(''),
        days3: new FormControl(''),
        days4: new FormControl(''),
        days5: new FormControl(''),
        days6: new FormControl(''),
        days7: new FormControl(''),
      }, requireCheckboxesToBeCheckedValidator()),

      premisesTypeId: new FormControl('', [Validators.required]),
      premisesTypeOther: new FormControl(''),
      employeesOnSite: new FormControl('', [Validators.required]),
      gasSupply: new FormControl('', [Validators.required]),
      employeesOnSiteOther: new FormControl('', [Validators.pattern("^[0-9]*$")]),
      appliance1: new FormControl('', [this.ValidatAppliances]),
      appliance2: new FormControl(''),
      appliance3: new FormControl(''),
      electricitySupply: new FormControl('', [Validators.required]),
      hasSolar: new FormControl('', [Validators.required]),
      solarCapacity: new FormControl(''),

    });

    this.questionnaireForm.controls['industryId'].valueChanges.subscribe(value => {
      if (value == 'other') {
        this.questionnaireForm.controls['industryOther'].setValidators([Validators.required]);
      } else {
        this.questionnaireForm.controls['industryOther'].clearValidators();
      }
      this.questionnaireForm.controls['industryOther'].updateValueAndValidity();
    });

    this.questionnaireForm.controls['hasSolar'].valueChanges.subscribe(value => {
      if (value) {
        this.solarCapacityValidation = <FormControl>this.questionnaireForm.get('solarCapacity');
        if (value == '0') {
          this.solarCapacityValidation.setValue('');
          this.solarCapacityValidation.setValidators(null);
        } else if (value == '1' && this.solarCapacityDontKnow == false) {
          this.solarCapacityValidation.setValidators([Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]);
          setTimeout(() => {
            document.getElementById("solarCapacity").focus();
          }, 10);
        }

        this.solarCapacityValidation.updateValueAndValidity();
      }
      this.setProgress();
    });

    this.questionnaireForm.controls['solarCapacity'].valueChanges.subscribe(value => {

      if (this.questionnaireForm.value['hasSolar'] != value) {
        this.setProgress();
      }
    });

    this.questionnaireForm.controls['appliance1'].valueChanges.subscribe(value => {
      this.setProgress();
    });

  }

  public setProgress() {
    let progress = 0;

    if (this.questionnaireForm.get('industryId').value != '' && this.questionnaireForm.get('industryId').value != 'other') {
      progress += 10;
    }

    if (this.questionnaireForm.get('industryId').value == 'other' && this.questionnaireForm.get('industryOther').value != '') {
      progress += 10;
    }

    if (this.questionnaireForm.get('businessHoursCheckboxGroup').valid) {
      progress += 10;
    }

    if (this.questionnaireForm.get('businessDaysCheckboxGroup').valid) {
      progress += 10;
    }

    if (this.questionnaireForm.get('premisesTypeId').value != '' && this.questionnaireForm.get('premisesTypeId').value != 'other') {
      progress += 10;
    }

    if (this.questionnaireForm.get('premisesTypeId').value == 'other' && this.questionnaireForm.get('premisesTypeOther').value != '') {
      progress += 10;
    }

    if (this.questionnaireForm.get('employeesOnSite').value != '' && this.questionnaireForm.get('employeesOnSite').value != 'other') {
      progress += 10;
    }

    if (this.questionnaireForm.get('employeesOnSite').value == 'other' && this.questionnaireForm.get('employeesOnSiteOther').value != '') {
      progress += 10;
    }

    if (this.questionnaireForm.get('gasSupply').value != '') {
      progress += 10;
    }

    if (this.questionnaireForm.get('appliance1').value != '' && this.questionnaireForm.get('appliance1').valid) {
      progress += 20;
    }

    if (this.questionnaireForm.get('electricitySupply').value != '') {
      progress += 10;
    }

    if (this.questionnaireForm.get('hasSolar').value == '0') {
      this.solarCapacityDontKnow = false;
      progress += 10;
    } else {
      if ((this.questionnaireForm.get('hasSolar').value == '1' && this.questionnaireForm.get('solarCapacity').valid) || this.solarCapacityDontKnow == true) {
        progress += 10;
      }
    }

    this.progress = progress;

  }

  public selectButton(event, name) {

    if (name == "SolarNo") {
      setTimeout(() => {
        document.getElementById("submit").focus();
      }, 10);
    }

    if (event.keyCode == 32 || event.keyCode == 13) { //spacebar & enter
      if (name == "saveData") {
        this.saveData();
      } else if (name == "industryContinue") {
        this.validateIndustry();
      } else if (name == "businessHoursContinue" || name == "hoursMornings" || name == "hoursAfternoons" || name == "hoursEvenings" || name == "hoursOvernight" || name == "days1" || name == "days2" || name == "days3" || name == "days4" || name == "days5" || name == "days6" || name == "days7") {
        this.validateBusinessHours();
      } else if (name == "days1" || name == "days2" || name == "days3" || name == "days4" || name == "days5" || name == "days6" || name == "days7") {
        this.validateBusinessDays();
      } else if (name == "workPremisesContinue") {
        this.validateWorkPremises();
      } else if (name == "premisesTypeOther") {
        this.validateWorkPremisesOther();
      } else if (name == "employeesOnSite") {
        this.validateEmployeesOnSite();
      } else if (name == "employeesOnSiteOther") {
        this.validateEmployeesOnSiteOther();
      } else if (name == "appliances") {
        this.validatAppliances();
      } else if (name == "solarCapacity") {
        this.saveData();
      }

    }
    this.setProgress();
  }

  showButtonContinueIndustry: boolean = false;
  showBusinessHours: boolean = false;
  showQuestionEmployees: boolean = false;
  showQuestionGas: boolean = false;
  showQuestionAppliances: boolean = false;
  showQuestionElectricityType: boolean = false;
  showQuestionSolar: boolean = false;
  showQuestionWorkPremises: boolean = false;
  showButtonContinue: boolean = false;

  public showBusinessHour() {
    if (this.questionnaireForm.get('industryId').value != '' && this.questionnaireForm.get('industryId').value != 'other') {
      this.showQuestionWorkPremises = true;
    }
    else if (this.questionnaireForm.get('industryId').value == 'other') {
      setTimeout(() => {
        document.getElementById("industryOther").focus();
      }, 0);
      this.showButtonContinueIndustry = true;
    }
    this.setProgress();
  }

  public validateIndustry() {
    this.showButtonContinueIndustry = false;
    if (this.questionnaireForm.get('industryId').value == '') {
    } else if (this.questionnaireForm.get('industryId').value == 'other') {
      this.showQuestionWorkPremises = true;
      this.showButtonContinueIndustry = false;
    }
    else {
      this.questionnaireForm.get('industryOther').markAsTouched();
      this.questionnaireForm.get('industryOther').markAsDirty();
    }
    this.setProgress();
  }

  public validateIndustryOther() {
    if (this.questionnaireForm.get('industryOther').value == '') {
      this.questionnaireForm.get('industryOther').markAsTouched();
      this.questionnaireForm.get('industryOther').markAsDirty();
    } else {
      this.showQuestionWorkPremises = true;
    }
    this.setProgress();
  }

  public validateBusinessHoursDays() {
    if (this.validateBusinessDays() && this.validateBusinessHours()) {
      this.showQuestionGas = true;
    }
    this.setProgress();
  }

  public validateBusinessHours() {
    this.questionnaireForm.get('businessHoursCheckboxGroup').markAsTouched();
    this.setProgress();
    return this.questionnaireForm.get('businessHoursCheckboxGroup').valid;
  }

  public validateBusinessDays() {
    this.questionnaireForm.get('businessDaysCheckboxGroup').markAsTouched();
    this.setProgress();
    return this.questionnaireForm.get('businessDaysCheckboxGroup').valid;
  }

  public validateWorkPremises() {
    if (this.questionnaireForm.get('premisesTypeId').value == '') {
      this.questionnaireForm.get('premisesTypeId').setValue('');
      this.questionnaireForm.get('premisesTypeId').markAsTouched();
      this.questionnaireForm.get('premisesTypeId').markAsDirty();

    } else if (this.questionnaireForm.get('premisesTypeId').value != 'other') {
      this.showQuestionEmployees = true;
    } else if (this.questionnaireForm.get('premisesTypeId').value == 'other') {
      setTimeout(() => {
        document.getElementById("premisesTypeOther").focus();
      }, 10);
    }
    this.setProgress();
  }

  public validateWorkPremisesOther() {
    if (this.energyQuestionnaire.premisesTypeOther == '') {
      this.questionnaireForm.get('premisesTypeOther').markAsTouched();
      this.questionnaireForm.get('premisesTypeOther').markAsDirty();
    } else {
      this.showQuestionEmployees = true;
    }
    this.setProgress();
  }

  public validateEmployeesOnSite() {

    if (this.questionnaireForm.get('employeesOnSite').value == '' || this.questionnaireForm.get('employeesOnSite').value == '0') {
      this.questionnaireForm.get('employeesOnSite').markAsTouched();
      this.questionnaireForm.get('employeesOnSite').markAsDirty();
    }
    else if (this.questionnaireForm.get('employeesOnSite').value == 'other') {
      setTimeout(() => {
        document.getElementById("employeesOnSiteOther").focus();
      }, 10);
    }
    else {
      this.showBusinessHours = true;
      setTimeout(() => {
        document.getElementById("hoursMornings").focus();
      }, 10);

    }
    this.setProgress();
  }

  public validateEmployeesOnSiteOther() {

    if (this.questionnaireForm.get('employeesOnSiteOther').value == '') {
      this.questionnaireForm.get('employeesOnSiteOther').markAsTouched();
      this.questionnaireForm.get('employeesOnSiteOther').markAsDirty();
    } else {
      this.showBusinessHours = true;
      setTimeout(() => {
        document.getElementById("hoursMornings").focus();
      }, 10);
    }
    this.setProgress();
  }

  public validateGasSupply() {
    if (this.questionnaireForm.get('gasSupply').value == '') {
      this.questionnaireForm.get('gasSupply').markAsTouched();
      this.questionnaireForm.get('gasSupply').markAsDirty();
    } else {
      this.showQuestionElectricityType = true;
    }
    this.setProgress();
  }

  public ValidatAppliances(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == '') {
      return { 'applianceInvalid': true };
    }
    return null;
  }

  public validatAppliances() {

    if (this.energyQuestionnaire.appliance1 == '') {
      this.questionnaireForm.get('appliance1').markAsTouched();
      this.questionnaireForm.get('appliance1').markAsDirty();

    } else {
      this.showQuestionSolar = true;
    }
    this.setProgress();

  }

  public validateElectricitytype() {
    if (this.energyQuestionnaire.electricitySupply == '') {
      this.questionnaireForm.get('electricitySupply').markAsTouched();
      this.questionnaireForm.get('electricitySupply').markAsDirty();
    } else {
      this.showQuestionAppliances = true;
    }
    this.setProgress();
  }

  public skipSolarCapacity() {
    this.solarCapacityDontKnow = true;

    this.solarCapacityValidation = <FormControl>this.questionnaireForm.get('solarCapacity');
    this.solarCapacityValidation.clearValidators();
    this.solarCapacityValidation.setValue('');
    this.solarCapacityValidation.updateValueAndValidity();

    this.setProgress();

    setTimeout(() => {
      document.getElementById("submit").focus();
    }, 10);
  }

  public saveData() {
    //remove offers saved
    let offers = { "offersList": [] };
    this.dataService.setOffers(offers);
    this.energyQuestionnaire.serverCacheId = localStorage.getItem("serverCacheId");
    if (this.questionnaireForm.valid) {
      this.spinner.show();
      this.dataService.saveData(this.energyQuestionnaire).subscribe(
        data => {
          if (data.status == "success") {
            this.energyQuestionnaire.serverCacheId = data["serverCacheId"];
            localStorage.setItem("serverCacheId", data['serverCacheId']);
            this.utility.scrollToTop('none');

            if (this.bill == null) {
              this.router.navigate(['/offers']);
            } else {
              this.utility.nextForm('bill-details', this.energyQuestionnaire.loopBack);
            }
          } else {
            this.spinner.hide();
            this.saveFormError = data.message;
            this.utility.scrollToTop('none');
          }
        },
        error => {
          this.spinner.hide();
          this.saveFormError = 'error';
          this.utility.scrollToTop('none');
        }
      );
    } else {
      this.questionnaireForm.markAllAsTouched();
      this.utility.scrollToError();
    }
  }

  private initializeLoopBackValues() {
    if (this.router.url != '/') {
      for (var property in this.loopBackValues) {
        if (this.loopBackValues[property] != '') {
          this.energyQuestionnaire[property] = this.loopBackValues[property];
        }
      }
    }
  }

  public reAssignVariables(results) {
    let data;
    if (this.router.url == '/small-business-energy-questionnaire') {
      this.energyQuestionnaire.loopBack = false;
      data = results.energyQuestionnaireData;
      this.bill = results.energyConfigData.bill;
      this.energyQuestionnaire.postcode = results.energyConfigData.postcode;
      this.energyQuestionnaire.distributor = results.energyConfigData.distributor.id;
      this.dataService.changeEnergyType(results.energyConfigData.energyType);
    } else {

      if (results.loopBackData.energyConfigData == undefined) {
        this.utility.redirectToHome('', true);
      }
      data = (results.loopBackData.energyQuestionnaireData.hasOwnProperty('industryId') == '') ? results.energyProfileData : results.loopBackData.energyProfileData;
      this.energyQuestionnaire.loopBack = true;
      this.bill = results.loopBackData.energyConfigData.bill;
      this.dataService.changeEnergyType(results.loopBackData.energyConfigData.energyType);
    }

  }

  public initializeForm() {
    this.createForm();

    this.modalService.dismissAll(); //close modal dialog if there are any open
    var serverCacheId = localStorage.getItem("serverCacheId");

    this.dataService.getSmbIndustries().subscribe(
      response => {
        this.industries = response['data'];
      });

    this.dataService.getSmbPremisesTypes().subscribe(
      response => {
        this.premisesTypes = response['data'];
      });



    //if serverCacheID is defined get saved data from the server
    if (serverCacheId != null) {
      this.energyQuestionnaire.serverCacheId = serverCacheId;
      this.spinner.show();
      this.dataService.getSavedData(serverCacheId).subscribe(
        results => {
          if (results.existing == false) {
            this.utility.sessionRestart();
            this.utility.removeServerCacheId(serverCacheId);
            this.utility.redirectToHome('', true);
            return false;
          } else {
            if (results.energyConfigData.energyType == '' || results.energyConfigData.energyType == undefined) {
              //redirect to home page
              this.utility.redirectToHome('', true);
              return false;
            }
            this.reAssignVariables(results);
          }
          this.spinner.hide();
          this.setProgress();
        },
        error => {
          //get form data from dataservice
          this.spinner.hide();
          this.utility.redirectToHome();
        }
      );
    } else {
      this.utility.redirectToHome();
    }
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
}

}
