import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { Utility } from '../utility';

@Component({
  selector: 'app-hot-topics',
  templateUrl: './hot-topics.component.html',
  styleUrls: ['./hot-topics.component.css']
})
export class HotTopicsComponent implements OnInit {
  public appURL = environment.appURL;
  public secImageURL = (environment.secBranding == true) ? 'sec_rebrand//' : '';
  public secCss = (environment.secBranding == true) ? 'hottopic-item-title-sec' : '';
  public bannerTitle = "Hot topics";
  public bannerSubTitle = "Learn more about Victorian energy consumers like you.";
  public bannerDetails = "";

  constructor(public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Hot Topics - Victorian Energy Compare';
  }

  getImageUrl(image) {
    return "url('assets/images/" + this.secImageURL + "contentpages/" + image + "')";
  }

}
