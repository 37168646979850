<div id="container">
    <app-banner [title]="bannerTitle" [subTitle]="bannerSubTitle" [details]="bannerDetails">
    </app-banner>
    <br />

    <div class="row">
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/why-is-my-electricity-bill-so-high" class="hottopic-item"
                title="Why is my electricity bill so high?" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image"
                    [ngStyle]="{'background-image': getImageUrl('hottopics_header_1_1.jpg')}"
                    title="Why is my electricity bill so high?">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_1_1.jpg"
                        alt="Why is my electricity bill so high?">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    Why is my electricity bill so high?
                </div>
            </a>
        </div>
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/how-to-switch-and-get-the-best-offer-for-you " class="hottopic-item"
                title="How to switch and get the best offer for you" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_8.jpg')}"
                    title="How to switch and get the best offer for you">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_8.jpg"
                        alt="How to switch and get the best offer for you">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    How to switch and get the best offer for you
                </div>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/unlock-energy-bill-savings-with-your-retailer’s-best-offer" class="hottopic-item"
                title="Unlock energy bill savings with your retailer’s best offer"
                (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_9.jpg')}"
                    title="Unlock energy bill savings with your retailer’s best offer">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_9.jpg"
                        alt="Unlock energy bill savings with your retailer’s best offer">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    Unlock energy bill savings with your retailer’s best offer
                </div>
            </a>
        </div>
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/the-victorian-default-offer-makes-electricity-bills-fair" class="hottopic-item"
                title="The Victorian Default Offer makes electricity bills fair" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_6.jpg')}"
                    title="The Victorian Default Offer makes electricity bills fair">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_6.jpg"
                        alt="The Victorian Default Offer makes electricity bills fair">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    The Victorian Default Offer makes electricity bills fair
                </div>
            </a>
        </div>

    </div>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/how-to-reduce-my-energy-bill" class="hottopic-item"
                title="Saving as a senior: how to reduce my energy bill?" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_5.jpg')}"
                    title="Saving as a senior: how to reduce my energy bill?">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_5.jpg"
                        alt="Saving as a senior: how to reduce my energy bill?">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    Saving as a senior: how to reduce my energy bill?
                </div>
            </a>
        </div>
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/moving-or-renovating-its-time-to-start-saving" class="hottopic-item"
                title="Building or renovating? It's time to start saving" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_3.jpg')}"
                    title="Building or renovating? It's time to start saving">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_3.jpg"
                        alt="Building or renovating? It's time to start saving">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    Building or renovating? It's time to start saving
                </div>
            </a>
        </div>

    </div>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/moving-out-six-ways-to-save-on-energy-bills-as-a-renter" class="hottopic-item"
                title="Renting? Six ways to save on energy bills as a renter" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_4.jpg')}"
                    title="Renting? Six ways to save on energy bills as a renter">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_4.jpg"
                        alt="Renting? Six ways to save on energy bills as a renter">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    Renting? Six ways to save on energy bills as a renter
                </div>
            </a>
        </div>
        <div class="col-md-6 col-sm-12">
            <a href="hot-topics/moving-house-move-to-cheaper-energy-bills" class="hottopic-item"
                title="Moving house? Move to cheaper energy bills" (click)="utility.scrollToTop('none')">
                <div class="hottopic-item-image" [ngStyle]="{'background-image': getImageUrl('hottopics_header_2.jpg')}"
                    title="Moving house? Move to cheaper energy bills">
                    <img src="assets/images/{{ secImageURL }}contentpages/hottopics_header_2.jpg"
                        alt="Moving house? Move to cheaper energy bills">
                </div>
                <div class="hottopic-item-title {{ secCss }}">
                    Moving house? Move to cheaper energy bills
                </div>
            </a>
        </div>
    </div>
</div>
<br /><br />
