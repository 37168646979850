<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">
        Did you know?
    </h2>

    <button class="hidden-btn"></button>
    <button type="button" class="close non-focus" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="font-weight-bold">Our offers</p>
    <br />
    <p>
        Victorian Energy Compare is updated daily to reflect offers in the market - Energy retailers are required to provide up-to-date information to the website.
    </p>
    <br />
    <p class="font-weight-bold">Offers change</p>
    <br />
    <p>
        Energy offers change frequently with old offers becoming unavailable to new customers. If your existing offer is more than a year old, it is worthwhile using Victorian Energy Compare to see whether a better offer is available.
    </p>
    <br />
    <p class="font-weight-bold">Finding the best from lots of offers</p>
    <br />
    <p>
        Retailers offer multiple energy offers not just one or two. Their offers reflect different offer types, different locations and different costs of energy at different times. Victorian Energy Compare helps you compare all the offers for which you are eligible, and allows you to select offers that suit you best.
    </p>
    <br />
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>