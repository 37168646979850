<div id="filters">
    <img src="assets/images/{{ secImageURL }}vic_energy_compare_logo.png" id="filters_vec_logo">
    <br class="filters_vec_logo_spacer"/>

    <a class="closebtn" (click)="utility.closeFilters()">&times;</a>
    <div class="column-headers">
        <span class="strong">Filters </span>
        <i
            class="material-icons medium-tooltip non-focus"
            [triggers]="utility.toolTipForMobile()"
            ngbTooltip="The filters below can be used to sort and display the offer list in different ways. You can change which offers are displayed in the list by changing options related to tariff type, specific retailers, green power, discounted price or some of the other categories listed below."
            aria-label="The filters below can be used to sort and display the offer list in different ways. You can change which offers are displayed in the list by changing options related to tariff type, specific retailers, green power, discounted price or some of the other categories listed below."
            tabindex="0">info</i>
    </div>
    <div class="filter-title" (click)="showFilters('showResultsFilter')">
        Results
        <span class="pull-right">
            <i class="fa fa-{{ showResultsFilter == true ? 'minus' : 'plus' }}" aria-hidden="true"></i>
        </span>
    </div>
    <div class="row" *ngIf="showResultsFilter == true">
        <div class="checkbox checkbox-primary bottom-pad">
            <br />
            <input id="sorting" class="form-check-input" type="checkbox" [(ngModel)]="sortByConditionalPrice" (click)="updateFeatures('sortByConditionalPrice')" />
            <label class="price-sort-toggle" for="sorting">
                Offers sorted by discounted price
                <i
                    onclick="return false"
                    style="margin-left: 5px"
                    class="material-icons medium-tooltip non-focus"
                    placement=left
                    [triggers]="utility.toolTipForMobile()"
                    tabindex="0"
                    ngbTooltip="The discounted price includes all your discounts, including any that you may receive by meeting certain conditions (such as pay-on-time or e-billing). Any applicable concessions have been applied to both prices."
                    aria-label="The discounted price includes all your discounts, including any that you may receive by meeting certain conditions (such as pay-on-time or e-billing). Any applicable concessions have been applied to both prices." tooltip-trigger="click focus mouseenter">
                    info
                </i>
            </label>
        </div>
        <div class="checkbox checkbox-primary">
            <input id="grouping" type="checkbox" class="form-check-input" [(ngModel)]="showTopOffer"  (click)="updateFeatures('showTopOffer')"/>
            <label class="price-sort-toggle" for="grouping">
                Only show the top offer for each retailer
            </label>
        </div>
    </div>
    <br />

    <div class="filter-title" (click)="showFilters('showOfferTypesFilter')" *ngIf="energyType != 'Gas'">
        Rate type
        <span class="pull-right">
            <i class="fa fa-{{ showOfferTypesFilter == true ? 'minus' : 'plus' }}" aria-hidden="true"></i>
        </span>
    </div>
    <div class="row" *ngIf="showOfferTypesFilter == true && energyType != 'Gas'">
        <div class="checkbox checkbox-primary content-separator filter-block full-width">
            <br />
            <input type="checkbox" class="form-check-input" id="single_rate" [(ngModel)]="showSingleRate" (click)="updateFeatures('showSingleRate')"/>
            <label for="single_rate" class="offers-list-filter">
                Single rate / flat
            </label>
        </div>
        <div class="checkbox checkbox-primary filter-block full-width">
            <input type="checkbox" id="tou" class="form-check-input" [(ngModel)]="showTouOffer" (click)="updateFeatures('showTouOffer')"/>
            <label for="tou" class="offers-list-filter">
                Time of use
                <i
                    onclick="return false"
                    class="material-icons medium-tooltip non-focus"
                    tabindex="0"
                    [triggers]="utility.toolTipForMobile()"
                    ngbTooltip="Only customers who are already on a TOU plan can switch to other TOU Offers. With a time of use plan, you will be charged different prices for usage at different time of the day. Typically, the prices will be categorised as peak and off-peak."
                    data-toggle="tooltip">
                    info
                </i>
            </label>
        </div>
      <div class="checkbox checkbox-primary filter-block full-width" *ngIf="tariffTypes.includes('Flexible pricing')">
        <input type="checkbox" id="flexible_pricing" class="form-check-input" [(ngModel)]="showFlexiblePricing" (click)="updateFeatures('showFlexiblePricing')"/>
        <label for="flexible_pricing" class="offers-list-filter">
          Flexible pricing
          <i
            onclick="return false"
            class="material-icons medium-tooltip non-focus"
            tabindex="0"
            [triggers]="utility.toolTipForMobile()"
            ngbTooltip="If you are not already on a flexible pricing plan, you may need to talk to your current retailer or any new retailers you are interested in about how you can access flexible pricing offer types. With a flexible pricing offer, you will be charged different prices for usage at different times of the day."
            data-toggle="tooltip">
            info
          </i>
        </label>
      </div>
        <br />
    </div>
    <br *ngIf="energyType != 'Gas'" />

    <div class="filter-title" (click)="showFilters('showFeaturesFilter')">
        Features
        <span class="pull-right">
            <i class="fa fa-{{ showFeaturesFilter == true ? 'minus' : 'plus' }}" aria-hidden="true"></i>
        </span>
    </div>

    <div class="row" *ngIf="showFeaturesFilter == true">
        <div class="row col-lg-12">
            <br />
            <div class="checkbox checkbox-primary">
                <input id="payment_details" type="checkbox" class="form-check-input" [(ngModel)]="showPayment" (click)="updateFeatures('showPayment')"/>
                <label for="payment_details">Show offers that require payment (e.g. bank debit / credit card)</label>
            </div>
        </div>
        <div class="row col-lg-12">
            <div class="checkbox checkbox-primary">
                <input id="has_incentive" type="checkbox" class="form-check-input" [(ngModel)]="showIncentive" (click)="updateFeatures('showIncentive')"/>
                <label for="has_incentive">
                    Only show offers with incentives
                    <i
                        onclick="return false"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="An incentive may be a welcoming credit, gift, or some other reward. In some offers, incentives may need to be refunded if you terminate the contract early. Please check the offer carefully."
                        aria-label="An incentive may be a welcoming credit, gift, or some other reward. In some offers, incentives may need to be refunded if you terminate the contract early. Please check the offer carefully."
                        tabindex="0" class="material-icons medium-tooltip non-focus">info
                    </i>
                </label>
            </div>
        </div>
        <div class="row col-lg-12" *ngIf="energyType != 'Gas'">
            <div class="checkbox checkbox-primary" (click)="showFilters('showGoGreenFilter2')">
                <input id="green_offers_1" type="checkbox" [(ngModel)]="showGreenPower" class="form-check-input" (click)="updateFeatures('showGreenPower');"/>
                <label for="green_offers_1" (click)="showFilters('showGoGreenFilter2')">Only show green offers</label>
            </div>
        </div>
        <div class="row col-lg-12" *ngIf="energyType != 'Gas'">
            <div class="checkbox checkbox-primary">
                <input id="show_demand" type="checkbox" class="form-check-input" [(ngModel)]="showDemand" (click)="updateFeatures('showDemand')"/>
                <label for="show_demand">
                    Only show offers with Demand
                    <i
                        onclick="return false"
                        placement="left"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="Demand offers include an additional charge based on your highest consumption within a defined period (E.g. a month)."
                        aria-label="Demand offers include an additional charge based on your highest consumption within a defined period (E.g. a month)."
                        tabindex="0" class="material-icons medium-tooltip non-focus">info</i>
                </label>
            </div>
        </div>
        <div class="row col-lg-12" *ngIf="energyType != 'Gas'">
            <div class="checkbox checkbox-primary">
                <input id="show_innovative" type="checkbox" class="form-check-input" [(ngModel)]="showInnovative" (click)="updateFeatures('showInnovative')"/>
                <label for="show_innovative">
                    Only show innovative offers
                    <i
                        onclick="return false"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="Innovative offers have pricing structures that are different to most other offers on Victoria Energy Compare. They may incur additional costs that are not included in the estimated annual amount."
                        aria-label="Innovative offers have pricing structures that are different to most other offers on Victoria Energy Compare. They may incur additional costs that are not included in the estimated annual amount."
                        tabindex="0" class="material-icons medium-tooltip non-focus">info</i>
                </label>
            </div>
        </div>
        <div class="row col-lg-12">
            <div class="checkbox checkbox-primary">
                <input id="show_custom_eligibility" type="checkbox" class="form-check-input" [(ngModel)]="showCustomEligibility" (click)="updateFeatures('showCustomEligibility')"/>
                <label for="show_custom_eligibility">
                    Show offers with custom eligibility
                    <i
                        onclick="return false"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="Custom eligibility offers contain characteristics which may limit the ability to sign-up for the offer without taking additional action."
                        aria-label="Custom eligibility offers contain characteristics which may limit the ability to sign-up for the offer without taking additional action."
                        tabindex="0" class="material-icons medium-tooltip non-focus">info</i>
                </label>
            </div>
        </div>
        <div class="row col-lg-12" *ngIf="energyType != 'Gas'">
            <div class="checkbox checkbox-primary">
                <input id="show_vdo" type="checkbox" class="form-check-input" [(ngModel)]="showVDO" (click)="updateFeatures('showVDO')"/>
                <label for="show_vdo">
                    Only show Victorian Default Offers
                    <i
                        onclick="return false"
                        [triggers]="utility.toolTipForMobile()"
                        ngbTooltip="The Victorian Default Offer is a simple, trusted and fair‑price electricity option for Victorian households and small businesses."
                        aria-label="The Victorian Default Offer is a simple, trusted and fair‑price electricity option for Victorian households and small businesses."
                        tabindex="0" class="material-icons medium-tooltip non-focus">info</i>
                </label>
            </div>
        </div>
        <div class="row col-lg-12" *ngIf="energyType != 'Gas' && hasSolar == true">
          <div class="checkbox checkbox-primary">
              <input id="show_solar_fit" type="checkbox" class="form-check-input" [(ngModel)]="showSolarFiT" (click)="updateFeatures('showSolarFiT')"/>
              <label for="show_solar_fit">
                  Only show offers with variable FiT
              </label>
          </div>
      </div>
    </div>
    <br />

    <div class="filter-title" (click)="showFilters('showRetailersFilter')">
        Energy retailers
        <span class="pull-right">
            <i class="fa fa-{{ showRetailersFilter == true ? 'minus' : 'plus' }}" aria-hidden="true"></i>
        </span>
    </div>
    <div class="row" *ngIf="showRetailersFilter == true">
        <div class="checkbox retailers-checkbox">
            <br />
            <input
                id="all_retailers"
                type="checkbox"
                [(ngModel)]="selectAllRetailers"
                class="form-check-input"
                (click)="selectRetailers()"/>
            <label for="all_retailers">Consider all retailers</label>
        </div>
        <div *ngFor="let retailer of retailers" class="row col-lg-12">
            <div class="checkbox checkbox-primary retailers-checkbox">
                <input
                    id="{{ 'retailer' + retailer.Id }}"
                    type="checkbox"
                    [(ngModel)]="selectedRetailers[retailer.Id].selected"
                    class="form-check-input"
                    (click)="updateRetailers(retailer.Id)"/>
                <label for="{{ 'retailer' + retailer.Id }}">{{ retailer.name }}</label>
            </div>
        </div>
        <br />
    </div>
    <br />

    <div class="filter-title go-green" (click)="showFilters('showGoGreenFilter')" *ngIf="energyType != 'Gas'">
        Go Green
        <span class="pull-right">
            <i class="fa fa-{{ showGoGreenFilter == true ? 'minus' : 'plus' }}" aria-hidden="true"></i>
        </span>
    </div>
    <br />
    <div class="row" *ngIf="showGoGreenFilter == true && energyType != 'Gas'">
        <div class="row col-lg-12" *ngIf="energyType != 'Gas'">
            <div class="checkbox checkbox-primary">
                <input id="green_offers_2" type="checkbox" [(ngModel)]="showGreenPower" class="form-check-input" (click)="updateFeatures('showGreenPower')"/>
                <label for="green_offers_2">Only show green offers</label>
            </div>
        </div>
        <br />
    </div>

    <div>
        <a
            title="Choice"
            aria-label="Choice"
            (click)="reset()"
            class="reset-filters"
            target="_blank">
            Reset filters <i class="fa fa-sliders"></i>
        </a>
        <br />

    </div>
    <br />
    <button
        title="Compare selected"
        aria-label="Compare selected"
        class="btn compare-offer-btn compare-offer-btn-txt"
        [disabled]="(compareIsDisabled == true)"
        (click)="showComparison()">
        Compare <br />Selected
    </button>
    <br /><br />
    <br />
    <button
        title="View more offers"
        aria-label="View more offers"
        class="more-offer-btn more-offer-btn-txt"
        [disabled]="!((offersCount - offersShown) > 0)"
        (click)="showOffers()">
        View more <br />offers
    </button>
    <br /><br />
</div>
