import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';

@Component({
  selector: 'app-psb-survey4',
  templateUrl: './psb-survey4.component.html',
  styleUrls: ['./psb-survey4.component.css']
})
export class PsbSurvey4Component implements OnInit {
  public bannerTitle = "$250 Power Saving Bonus Applicant Survey";
  public bannerSubTitle = "Fill in our online survey to provide feedback on the $250 Power Saving Bonus.";
  public bannerDetails = "";

  constructor(public utility : Utility) { }

  ngAfterViewInit() {
    var script = document.createElement('script');
    script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd_2BwFbuVJEqIaifpsro0cm4jla_2FPI2scdL2OIaxFL6oJg.js";
    document.getElementById("smcx-sdk").appendChild(script);
  }

  ngOnInit(): void {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = '$250 Power Saving Bonus Survey - Victorian Energy Compare';
  }


}
