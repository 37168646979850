
<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Victorian Government Solar Panel Rebate</h2>
    <button class="hidden-btn"></button>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="para-solar-rebates">
        Did you know the Victorian Government is providing rebates to eligible households on the cost 
        of a solar panel (PV) system. For more information, and to find out if you are eligible, 
        please visit <a href="http://www.solar.vic.gov.au" target="_blank">www.solar.vic.gov.au</a>.
    </p>
</div>
