<div id="container">

    <app-banner
        [title]="bannerTitle"
        [subTitle]="bannerSubTitle"
        [details]="bannerDetails">
    </app-banner>

    <div class="row">
        <div class="col-md-6">
            <video class="usage_video" controls
                poster="assets/images/videos/posters/02HOWTOREAD_640.png"
                width="100%">
                <source src="{{ compareURL }}/videos/02HOWTOREAD_640.mp4" type="video/mp4">
                <div class="not-supported">
                    Sorry, your browser does not support the &lt;video&gt;
                </div>
            </video>
        </div>
        <div class="col-md-6">
            <video class="usage_video" controls
                poster="assets/images/videos/posters/03HowToUse_640.png"
                width="100%">
                <source src="{{ compareURL }}/videos/03HowToUse_640.mp4" type="video/mp4">
                <div class="not-supported">
                    Sorry, your browser does not support the &lt;video&gt;
                </div>
            </video>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <video class="usage_video" controls
                poster="assets/images/videos/posters/04OTHERWAYSTOSAVE_640.png"
                width="100%">
                <source src="{{ compareURL }}/videos/04OTHERWAYSTOSAVE_640.mp4" type="video/mp4">
                <div class="not-supported">
                    Sorry, your browser does not support the &lt;video&gt;
                </div>
            </video>
        </div>
        <div class="col-md-6">
        </div>
    </div>
</div>
<br /><br />


