import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';

@Component({
  selector: 'app-top-tips',
  templateUrl: './top-tips.component.html',
  styleUrls: ['./top-tips.component.css']
})
export class TopTipsComponent implements OnInit {
  public bannerTitle = "Top tips for saving money on energy bills";
  public bannerSubTitle = "Think you're paying too much on energy bills? There's a good chance you are.";
  public bannerDetails = "";

  constructor(public utility : Utility) { }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Top Tips for saving money on energy bills - Victorian Energy Compare';
  }

}
