import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { Utility } from '../utility';
import { environment } from '../../environments/environment'; //config file
import * as _ from 'lodash';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-offer-info',
  templateUrl: './offer-info-modal.component.html',
  styleUrls: ['./offer-info-modal.component.css'],
  providers: [NgbModal]
})
export class OfferInfoModalComponent implements OnInit {
  public Math = Math;
  public energyType;
  public offer;
  public GST = true;
  public distributor;
  public returnPage;
  public fileUpload;
  public getMyData;
  public secBranding = environment.secBranding;
  public emailSecurityKey = environment.emailSecurityKey;
  public appURL = environment.appURL;

  constructor(
    private modalService: NgbModal,
    public utility : Utility,
    public router : Router,
    public activeModal: NgbActiveModal,
    public dataService : DataService) { }

  ngOnInit() {
    this.energyType = this.dataService.getSelectedEnergyType();
    this.formatRates();
    //log journey progress
    this.utility.logJourneyProgress('viewed_offer', this.offer.offerId);
  }

  private formatRates() {
    this.offer.tariffDetails = this.utility.formatTariffs(this.offer.tariffDetails);
    this.offer.solar = this.utility.formatTariffs(this.offer.solar);
  }

  public close() {
    this.dataService.setPrint(false);
    this.activeModal.close();
  }

  public print() {
    this.dataService.setPrint(true);
    window.print();
    GA4LogEvent('View Offer - ' + this.energyType, 'PRINT');
  }

  public email() {
    let modalRef = this.modalService.open(EmailModalComponent, { size : 'xs' });
    modalRef.componentInstance.headerTitle = 'Email single offer (' + this.offer.offerId + ')';
    modalRef.componentInstance.modal = true;
    GA4LogEvent('View Offer - ' + this.energyType, 'EMAIL');
  }

  public contactRetailer() {
    //set return page
    this.dataService.setReturnPage(this.returnPage);
    this.dataService.setSelectedOffer(this.offer);
    this.modalService.dismissAll();
    this.router.navigate(['/contact']).then(() => {
      this.utility.scrollToTop('none');
    });
  }

  public contactVEC() {
    sessionStorage.setItem("offerId", this.offer.offerId);
    this.modalService.dismissAll();
    this.router.navigate(['/contact-us']).then(() => {
      this.utility.scrollToTop('none');
    });
  }
}
