import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { DataService } from '../data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Utility } from '../utility';
import { ReCaptchaService } from '../reCaptcha.service';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public offerId = '';
  public recaptchaEnabled = environment.recaptchaEnabled;
  public contactForm : FormGroup;
  public emailStatus;
  public errorMessage = '';
  public recaptchaResponse;
  public recaptchaValidation = false;
  public messageIsSent = false;
  public googleSiteKey = environment.googleSiteKey;
  public bannerTitle = "Contact us";
  public bannerSubTitle = "For further information or support you can contact the Victorian Energy Compare support team.";
  public bannerDetails = "";
  private recaptchaError;
  public contactData = {
    name : "",
    email : "",
    subject : "",
    refNumber: "",
    comment : "",
    recaptchaResponse : ""
  }
  enquiryRelatingTo : any = ['General', 'Technical issue', 'Energy offer comparison', 'Power Saving Bonus', 'Energy Bill Relief'];

  constructor(
    private reCaptchaService: ReCaptchaService,
    public formBuilder : FormBuilder,
    public spinner : NgxSpinnerService,
    public reCaptchaV3Service : ReCaptchaV3Service,
    public utility : Utility,
    public dataService : DataService) { }

  ngOnInit() {
    if (sessionStorage.getItem("offerId") != null) {
      this.offerId = sessionStorage.getItem("offerId");
      sessionStorage.removeItem("offerId");
    }
    this.utility.maintenanceMode(); //check if its in maintenance mode

    //subscribe for reCaptcha token
    this.reCaptchaService.token$.subscribe(token => {
      this.contactData.recaptchaResponse = token;
    });

    document.title = 'Contact us - Victorian Energy Compare';
    this.createForm();
  }

  public createForm() {
    this.contactForm = this.formBuilder.group({
      name : new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
      subject : new FormControl('', [
        Validators.required
      ]),
      refNumber: new FormControl(''),
      comment : new FormControl('', [
        Validators.required
      ]),
      recaptchaResponse : new FormControl('')
    });

    if (this.offerId != '') {
      let subjectValidation = <FormControl>this.contactForm.get('subject');
      subjectValidation.setValidators(null);
      subjectValidation.updateValueAndValidity();
      this.contactData.subject = 'Offer Enquiry - Contact Us form submission';
    }
  }

  public resetForm() {
    this.contactData = {
      name : "",
      email : "",
      subject : "",
      refNumber: "",
      comment : "",
      recaptchaResponse : ""
    };
  }

  public resolved(captchaResponse: string): void {
    this.recaptchaValidation = true;
    this.recaptchaResponse = captchaResponse;
  }

  public onError(): void {
    this.recaptchaValidation = false;
    this.recaptchaResponse = '';
  }


  public sendMessage() {
    if (this.recaptchaEnabled == true) {
      this.reCaptchaV3Service.execute(this.googleSiteKey, 'contact', (token) => {
          this.contactData.recaptchaResponse = token;
          this.sendEmailMessage();
        },
        {
          useGlobalDomain: false
        }
      );
    } else {
      this.sendEmailMessage();
    }
  }

  public sendEmailMessage() {
    if (this.contactForm.status == 'VALID') {
      let comment = this.contactData.comment.replace(/<[^>]*>/g, '\n'); //remove html tags
      //send message to backend
      this.spinner.show();
      this.dataService.sendContactMessage(this.contactData.name, this.contactData.email, this.contactData.subject, this.contactData.refNumber, comment, this.offerId, this.contactData.recaptchaResponse).subscribe(
        result => {
          this.emailStatus = result["status"];
          if (this.emailStatus == "error") {
            this.errorMessage = result["message"];
            setTimeout(() => {
              let error = document.querySelector('.alert-danger');
              error.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
          } else {
            this.messageIsSent = true;
          }
          this.spinner.hide();
        },
        error => {
          this.errorMessage = 'An error has occurred while trying to send the email, please try again later';
          this.spinner.hide();
        },
        () => {
          this.reCaptchaService.initializeRecaptcha();
        }
      );
    } else {
      this.utility.scrollToError();
    }
  }

}
