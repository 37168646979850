import { Component, OnInit } from '@angular/core';
import { Utility } from '../utility';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {
  public bannerTitle = "Sign up to Victorian Energy Compare Energy Updates";
  public bannerSubTitle = "Stay ahead of energy prices";
  public bannerDetails = "";

  constructor(public utility : Utility) { }

  ngOnInit(): void {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    document.title = 'Subscribe - Victorian Energy Compare';
  }

}
