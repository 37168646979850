<div id="results-left">
    <ul class="nav nav-tabs">
        <li class="option1" [ngClass]="{ 'active' : (selectedOption == 1) }"><a data-toggle="tab" href="#" (click)="updateSelectedOption(1)">Option 1</a></li>
        <li class="option2" [ngClass]="{ 'active' : (selectedOption == 2) }"><a data-toggle="tab" href="#" (click)="updateSelectedOption(2)">Option 2</a></li>
        <li class="option3" [ngClass]="{ 'active' : (selectedOption == 3) }"><a data-toggle="tab" href="#" (click)="updateSelectedOption(3)">Option 3</a></li>
    </ul>
    <form method="post" id="solarForm" [formGroup]="solarForm" (validSubmit)="submitSolarForm()">
        <div class="card result-left">
            <div class="left-inner-block" id="adjustable-first">
                <p class="result-left-title">
                    Adjustable Information
                </p>
                <p class="result-left-lable">
                    Adjust these parameters to match the desired solar system and electricity rates.
                </p>
                <hr class="result-left-line1">
            </div>

            <div class="left-inner-block">
                <span class="result-left-sub-title">Solar System Details</span>
                <p></p>
                <div class="form-label title-black">
                    <label class="control-label"><h3 class="form_label">Size of Solar System</h3></label>
                    <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="The solar system size is the total output of the solar system. This is measured in kilowatts." *ngIf="download == false"></span>
                </div>
                <div class="form-group">
                    <span class="bound_label float-left" *ngIf="download == false">1.5 kW</span>
                    <span class="bound_label float-right" *ngIf="download == false">20 kW</span>
                    <input class="form-control-range" step="0.1" type="range" id="systemSize" formControlName="systemSize" [(ngModel)]="solarData.systemSize" min="1.5" max="20" (change)="updateSizePosition(); updateSolarEstimates('size');" data-html2canvas-ignore="true" *ngIf="download == false">
                    <span *ngIf="download == false" [style.left]="getSizePosition()" class="slider-value slider-size"> {{ solarData.systemSize }} kW</span>
                    <span *ngIf="download == true" style="float: left; margin-left: 10em;" class="slider-value slider-size">{{ solarData.systemSize }} kW</span>
                </div>
                <p>&nbsp;</p>
            </div>
            <div class="left-inner-block">
                <br *ngIf="download == false" />
                <div class="form-label title-black">
                    <label class="control-label"><h3 class="form_label">Cost of Solar System</h3></label>
                    <span class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="The cost of the system is after any rebates and should include GST" *ngIf="download == false"></span>
                </div>
                <div class="form-group">
                    <span class="bound_label float-left" *ngIf="download == false">$0</span>
                    <span class="bound_label float-right" *ngIf="download == false">$20,000</span>
                    <input class="form-control-range" step="100" type="range" id="systemCost" formControlName="systemCost" [(ngModel)]="solarData.systemCost" min="0" max="20000" (change)="updateCostPosition(); updateSolarEstimates('cost');" data-html2canvas-ignore="true" *ngIf="download == false">
                    <span *ngIf="download == false" [style.left]="getCostPosition()" class="slider-value slider-cost"> {{ solarData.systemCost | currency:'USD':'symbol':'1.0-0' }}</span>
                    <span *ngIf="download == true" style="float: left; margin-left: 10em" class="slider-value slider-cost">{{ solarData.systemCost | currency:'USD':'symbol':'1.0-0' }}</span>
                    <br *ngIf="download == true" />
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <hr class="result-left-line1">

                <div id="adjustable-second">
                    <span class="result-left-sub-title">Electricity Rates</span>
                </div>
                <div class="row aersection">
                    <div class="col-lg-5 nopadding-right nopadding-left verticalLine" id="form-details1">
                        <label class="control-label"><h3 class="form_label">Electricity Usage</h3></label>
                        <p></p>

                        <div class="form-group">
                            <label class="control-label">Peak</label>
                            <div class="input-group-prepend">
                                <input type="number" class="form-control result-left-input" placeholder="Cents per kWh" [(ngModel)]="solarData.peakRateValue" id="peakRateValue" formControlName="peakRateValue" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.peakRateValue }}</span>&nbsp;
                            <span class="small-label">cents per kWh</span>
                            <bfv-messages></bfv-messages>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>

                        <div class="form-group" *ngIf="solarData.shoulderRate > 0">
                            <label class="control-label">Shoulder</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.01" min="0" max="25" class="form-control result-left-input" placeholder="Cents per kWh" [(ngModel)]="solarData.shoulderRateValue" id="shoulderRateValue" formControlName="shoulderRateValue" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.shoulderRateValue }}</span>&nbsp;
                            <span class="small-label">cents per kWh</span>
                            <bfv-messages></bfv-messages>
                            <div class="col-sm-12">&nbsp;</div>
                        </div>


                        <div class="form-group" *ngIf="solarData.offPeakRate > 0">
                            <label class="control-label">Off-Peak</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.01" min="0" max="25" class="form-control result-left-input" placeholder="Cents per kWh" [(ngModel)]="solarData.offPeakRateValue" id="offPeakRateValue" formControlName="offPeakRateValue" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.offPeakRateValue }}</span>&nbsp;
                            <span class="small-label">cents per kWh</span>
                            <bfv-messages></bfv-messages>
                            <div class="col-sm-12">&nbsp;</div>
                        </div>

                        <div class="form-group">
                            <label class="control-label">Daily Supply Charge</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.1" min="0" max="10000" class="form-control result-left-input DSC" placeholder="Cents per day" [(ngModel)]="solarData.dailySupplyCharge" id="dailySupplyCharge" formControlName="dailySupplyCharge" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.dailySupplyCharge }}</span>&nbsp;
                            <span class="small-label">cents per day</span>
                            <bfv-messages></bfv-messages>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>

                        <div class="form-group" *ngIf="solarData.discount > 0">
                            <label class="control-label">Discount rate</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.1" min="0" max="100" class="form-control result-left-input" placeholder="Rate in %" [(ngModel)]="solarData.discountRateValue" id="discountRateValue" formControlName="discountRateValue" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.discountRateValue }}</span>&nbsp;
                            <span class="small-label">Rate in %</span>
                            <bfv-messages></bfv-messages>
                            <div class="col-sm-12">&nbsp;</div>
                        </div>

                    </div>
                    <div class="col-lg-5 nopadding-right" id="form-details2">
                        <label class="control-label">
                            <h3 class="form_label">
                            Solar Feed-in Tariff
                            <span style="font-size: 15px;" class="fa fa-info-circle" [triggers]="utility.toolTipForMobile()" ngbTooltip="A feed-in tariff (FiT) is a rate paid for electricity fed back into the electricity grid from a designated renewable electricity generation source. Please speak to both your solar installer and electricity distributor about your properties ability to export electricity to the grid before installation." *ngIf="download == false"></span></h3>
                        </label>

                        <p class="col-xs-12 col-sm-12 nopadding"></p>
                        <div class="form-group">
                            <label class="control-label">Peak / Single Rate</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.1" min="0" class="form-control result-fit-input" placeholder="Cents per kWh" formControlName="fitPeak" id="fitPeak" [(ngModel)]="solarData.fitPeak" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.fitPeak }}</span>&nbsp;
                            <span class="small-label">cents per kWh</span>
                            <bfv-messages></bfv-messages>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>

                        <div class="form-group">
                            <label class="control-label">Shoulder</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.01" min="0" max="25" class="form-control result-fit-input" formControlName="fitShoulder" id="fitShoulder" placeholder="e.g. 7" [(ngModel)]="solarData.fitShoulder" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.fitShoulder }}</span>&nbsp;
                            <span class="small-label">cents per kWh</span>
                            <bfv-messages></bfv-messages>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="form-group">
                            <label class="control-label">Off-Peak</label>
                            <div class="input-group-prepend">
                                <input type="number" step="0.01" min="0" max="25" class="form-control result-fit-input" formControlName="fitOffPeak" id="fitOffPeak" placeholder="e.g. 5" [(ngModel)]="solarData.fitOffPeak" (blur)="updateSolarEstimates()" *ngIf="download == false">
                            </div>
                            <span *ngIf="download == true">{{ solarData.fitOffPeak }}&nbsp;</span>
                            <span class="small-label">cents per kWh</span>
                            <bfv-messages></bfv-messages>
                        </div>
                    </div>
                </div>
                <div id="compare_btn">
                    <hr class="result-left-line1">
                    <br />
                    <input type="button" value="Compare All Options" class="compare-btn col-lg-12" (click)="compareSolar()">
                    <br /><br />
                    <em>
                        Press this button to compare all of your Solar Savings options
                    </em>
                </div>

                <p>&nbsp;</p>
                <div data-html2canvas-ignore="true" *ngIf="download == false">
                    <hr class="result-left-line1">
                    <p class="result-left-sub-title ">External Resources</p>
                    <div class="external-resources"><a class="anchor-style non-focus" href="https://www.solar.vic.gov.au/" target="_blank" tabindex="-1">Solar Victoria</a></div>
                    <div class="external-resources"><a class="anchor-style non-focus" href="https://www.cleanenergycouncil.org.au/" target="_blank" tabindex="-1">Clean Energy Council</a></div>
                    <div class="external-resources"><a class="anchor-style non-focus" href="https://www.ewov.com.au/" target="_blank" tabindex="-1">Energy and Water Ombudsman of Victoria (EWOV)</a></div>
                    <div class="external-resources"><a class="anchor-style non-focus" href="http://www.esc.vic.gov.au/" target="_blank" tabindex="-1">Essential Services Commission (ESC)</a></div>
                    <div class="external-resources"><a class="anchor-style non-focus" href="https://www.choice.com.au/home-improvement/energy-saving" target="_blank" tabindex="-1">CHOICE</a></div>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>

            </div>
        </div>
    </form>
    <div class="card result-left" *ngIf="download == true" style="margin-top: 1em;">
        <div style="padding:1em">
            <span>*</span> Information provided by The Solar Calculator is general in nature and does not take into
            consideration your personal financial situation, or property-specific factors that may impact the
            effectiveness of PV solar panels for your property such as shading, roof area, roof pitch, and aspect.
            Calculation of solar benefit estimates are based on user-supplied post code and energy costs, historical
            consumption, and a blend of typical roof pitches and aspects.
            <br><br>

            Solar benefit estimates do not constitute formal financial advice or a formal assessment of the suitability
            of PV solar panels for your property. Before acting, you should consider seeking independent personal
            financial advice that is tailored to your needs, as well as a quote from a qualified solar installer.
            <br><br>

            Please speak to your solar installer and your electricity distributor to confirm whether you are
            able to export electricity to the grid before you decide to install solar panels. Some properties
            are unable to export electricity, or have limits on how much electricity can be exported. These
            limits and restrictions (known as 'export limiting') are based on the electricity infrastructure
            in your area and the rules can vary across different distribution zones in Victoria.
            <br />
        </div>
    </div>
</div>
