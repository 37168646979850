import { Component, OnInit } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnergyProfileForm } from '../energyProfileForm';
import { DataService } from '../data.service';
import { Utility } from '../utility';

@Component({
  selector: 'app-energy-profile',
  templateUrl: './energy-profile.component.html',
  styleUrls: ['./energy-profile.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class EnergyProfileComponent implements OnInit {
  public bannerTitle = "Energy questionnaire";
  public bannerSubTitle = "";
  public bannerDetails = "A set of questions designed to discover personalised energy usage.";
  
  constructor(
    public energyProfileForm : EnergyProfileForm,
    public config : NgbModalConfig,
    public modalService : NgbModal,
    public dataService : DataService,
    public utility : Utility,
    private tooltipConfig : NgbTooltipConfig) {
      this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
    }

  ngOnInit() {
    this.utility.maintenanceMode(); //check if its in maintenance mode
    this.modalService.dismissAll(); //close modal dialog if there are any open
    document.title = 'Your Energy Profile - Victorian Energy Compare';
    this.energyProfileForm.initializeForm();
    this.dataService.changeEnergyProfilePage('true');
  }

}
