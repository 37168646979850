<div class="row  col-lg-12" [ngClass]="(infoModalShown == true) ? 'offers-hidden' : ''">
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-print-header" *ngIf="secBranding == false">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_940_left.png">
        <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_1140_vec_logo.png">
        <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_1140_vic_logo.png">
    </div>
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-print-header" *ngIf="secBranding == true">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png">
    </div>
    <div class="large col-sm-12 col-xs-12 hidden-sm hidden-xs" id="vec-medium-print-header">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/sec_rebrand/offers_header.png" *ngIf="secBranding == true">
        <img class="take_charge_logo" alt="Take Charge. Save on Bills." src="{{ appURL }}/assets/images/header_720_left.png" *ngIf="secBranding == false">
        <img class="vec_logo" alt="Victorian Energy Compare" src="{{ appURL }}/assets/images/header_720_vic_logo.png" *ngIf="secBranding == false">
        <img class="vic_logo" alt="State Government of Victoria, Australia – link to Government home" src="{{ appURL }}/assets/images/header_600_vec_logo.png" *ngIf="secBranding == false">
    </div>
</div>
<div class="hidden-label">{{ emailSecurityKey }}</div>
<div *ngIf="((energyType != 'dual' && offers.offersList.length < 1) || (dualOffers.offersList.length < 1 && energyType == 'dual')) && pageLoad == true" class="text-center h4" [ngClass]="(infoModalShown == true) ? 'offers-hidden' : ''">
    Your filtered selection has returned no results
    <br /><br />
</div>

<div class="row how-is-this-calculated no-margin" *ngIf="showCalculation == true">
    <div class="row row-close">
        <div class="col-lg-12 text-center">
            <button type="button" class="close non-focus" (click)="hideCalculation()">
                close ×
            </button>
        </div>
    </div>
    <div class="col-lg-12">
        <ul class="list-inline row">
            <li class="col-md-4">
                <div class="row">
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/profile.png" class="img" *ngIf="energyType != 'Gas'">
                        <img src="{{ appURL }}/assets/images/how_calculated/gas.png" class="img" *ngIf="energyType == 'Gas'">
                        <br />
                        Your energy profile
                    </div>
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/plus.png" class="plus">
                    </div>
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/power.png" class="img">
                        <br />
                        Usage charge
                    </div>
                </div>
            </li>
            <li class="col-md-4">
                <div class="row">
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/plus.png" class="plus">
                    </div>
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/home.png" class="img">
                        <br />
                        Supply charge
                    </div>
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/plus.png" class="plus">
                    </div>
                </div>
            </li>
            <li class="col-md-4">
                <div class="row">
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/discount.png" class="img">
                        <br />
                        Discounts
                    </div>
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/equal.png" class="plus">
                    </div>
                    <div class="col-md-4 text-center no-padding">
                        <img src="{{ appURL }}/assets/images/how_calculated/money.png" class="img">
                        <br />
                        Annual Estimate
                    </div>
                </div>
            </li>
        </ul>
        <p class="calculation-desc" *ngIf="energyType != 'Gas'">
            Victorian Energy Compare selects a set of offers based on your location, and whether you have controlled load or solar. The estimated annual costs are calculated by combining your annual energy consumption with the usage and daily charges of each offer and applying any eligible discounts.
        </p>
        <p class="calculation-desc" *ngIf="energyType == 'Gas'">
            Victorian Energy Compare selects a set of offers based on your location. The estimated annual costs are calculated by combining your annual energy consumption with the usage and daily charges of each offer and applying any eligible discounts.
        </p>
    </div>
</div>
<div *ngIf="(energyType != 'dual' && offers.offersList.length > 0) || (dualOffers.offersList.length > 0 && energyType == 'dual')" [ngClass]="(infoModalShown == true) ? 'offers-hidden' : ''">
    <div class="row best-offer-tab">
        <span class="btn">Top Offer</span>
            <div class="offer-buttons">
                <span
                    title="Based on your filtering choice and the information you have provided, this is your top offer"
                    class="anchor-style"
                    [triggers]="utility.toolTipForMobile()"
                    ngbTooltip="Based on your filtering choice and the information you have provided, this is your top offer."
                    aria-label="Based on your filtering choice and the information you have provided, this is your top offer.">why this offer?</span>
                <span class="separator">|</span>
                <span
                    title="how is this calculated?"
                    class="anchor-style calculation-btn"
                    aria-label="how is this calculated?"
                    (click)="toggleCalculation()">how is this calculated?</span>
        </div>
    </div>

    <ul *ngIf="energyType != 'dual'" id="offers" [ngClass]="dataService.getPrint() == true ? 'print-offers' : ''">
        <li
            *ngFor="let offer of offers.offersList; first as isFirst, index as offerIndex;"
            [ngClass]="showFilters == false ? 'extra-margin-bottom' : ''"
            class="row offer-row">
            <div class="row col-md-12 offer-div">
              <!-- offer-price -->
              <div class="col-12 col-lg-5 no-padding">
                <div class="row y-align-middle">
                    <div class="col-1 col-lg-1 single-checkbox" >
                        <div class="checkbox checkbox-warning check-left-align align-middle-checkbox">
                            <input
                                type="checkbox"
                                [checked]="(selectedOffers.includes(offer.offerKey))"
                                [disabled]="checkboxIsDisabled(offer.offerKey)"
                                class="form-check-input"
                                (click)="updateOfferSelection(offer.offerKey)">
                            <label>
                            </label>
                        </div>
                    </div>
                    <div class="col-11 col-lg-11 align-middle">
                      <div class="col-12 col-lg-12 no-padding prices offer-price" [ngClass]="offer.solarCredit > 0 ? '' : 'align-middle'" >
                        <div class="first-solar-credit-display" *ngIf="offer.solarCredit > 0">
                            <div class="col-lg-12 solar-credit-display no-padding">
                                Estimate includes solar credit of {{ offer.solarCredit | currency : 'USD' : 'symbol' : '1.0-0' }}
                            </div>
                        </div>
                        <div class="row" [ngClass]="offer.solarCredit > 0 ? 'price' : 'no-padding'">
                          <div class="col-lg-6 col-xs-6 price-format" >
                            <div class="row">
                                <span class="price-sign">$</span>
                                <span *ngIf="dataService.getSorting() == true">
                                    <span class="currency">{{ Math.abs(offer.totalConditionalPrice) | number }}
                                        <span *ngIf="offer.totalConditionalPrice < 0" class="strong credits">
                                            CR&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span *ngIf="dataService.getSorting() == false">
                                    <span class="currency">
                                        {{ Math.abs(offer.totalBasePrice) | number }}
                                        <span *ngIf="offer.totalBasePrice < 0" class="strong credits">
                                            CR
                                        </span>
                                    </span>
                                </span>
                                <div
                                    *ngIf="offer.includesDemandTariff != 0 && fileUpload == false && getMyData == false"
                                    class="includes-demand-tariff includes-tariff-notice full-width center-text"
                                    tooltipClass="includes-demand-tariff-tip"
                                    placement="top"
                                    [triggers]="utility.toolTipForMobile()"
                                    ngbTooltip="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you."
                                    aria-label="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.">
                                    <br />
                                    <span class="plus">+</span>
                                    demand charge
                                </div>
                            </div>
                            <div class="row">
                              <p class="estimated-price">
                                Est. yearly price
                            </p>
                            </div>
                        </div>
                        <div class="col-6 col-lg-6 align-middle actual-price no-padding">
                            <p *ngIf="dataService.getSorting() == true">
                                Without conditional discounts
                                <span class="strong total-base-price">
                                    {{ Math.abs(offer.totalBasePrice) | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                                <span *ngIf="offer.totalBasePrice < 0" class="strong">
                                    CR
                                </span><br>
                                <span class="full-width view-offer">
                                  <span
                                      [attr.title]="offer.offerName"
                                      [attr.aria-label]="offer.offerName"
                                      class="anchor-style full-width"
                                      (click)="viewOffer(offerIndex)">
                                      view offer
                                  </span>
                              </span>
                            </p>
                            <p *ngIf="dataService.getSorting() == false">
                                With conditional discounts
                                <span class="total-base-price strong">
                                    {{ Math.abs(offer.totalConditionalPrice) | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                                <span *ngIf="offer.totalConditionalPrice < 0" class="strong">
                                    CR
                                </span><br>
                                <span class="full-width view-offer">
                                  <span
                                      [attr.title]="offer.offerName"
                                      [attr.aria-label]="offer.offerName"
                                      class="anchor-style full-width"
                                      (click)="viewOffer(offerIndex)">
                                      view offer
                                  </span>
                              </span>
                            </p>
                        </div>

                        </div>
                    </div>
                    </div>
                </div>
              </div>

              <!-- offer-features -->
              <div class="col-6 col-lg-2 align-middle no-padding">
                  <div class="offer-icons">
                      <i
                          *ngIf="offer.isVictorianDefaultOffer != false"
                          class="material-icons victorian-default-offer non-focus"
                          tooltipClass="victorian-default-offer-tip"
                          placement="top"
                          tabindex="0"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="Victorian Default Offer"
                          aria-label="Victorian Default Offer">
                          &#xe32a;
                      </i>
                      <i
                          *ngIf="offer.isDirectDebitOnly == true"
                          class="material-icons direct-debit non-focus"
                          tooltipClass="direct-debit-tip"
                          placement="top"
                          tabindex="0"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="Payment details required (e.g. bank debit / credit card)"
                          aria-label="Payment details required (e.g. bank debit / credit card)">
                          &#xE870;
                      </i>
                      <i
                          *ngIf="offer.hasPayOnTimeDiscount == true || offer.contractLength != 'None'"
                          class="material-icons payontime non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="payontime-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="{{ (offer.hasPayOnTimeDiscount == true) ? 'Offer has a pay on time discount' : ''}} {{ (offer.contractLength != 'None') ? offer.contractLength + ' contract' : '' }} "
                          aria-label="Offer has a pay on time discount">
                          &#xE855;
                      </i>
                      <i
                          *ngIf="offer.hasIncentive == true"
                          class="material-icons incentive non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="incentive-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="Offer has an incentive"
                          aria-label="Offer has an incentive">
                          &#xE8F6;
                      </i>
                      <i
                          *ngIf="offer.greenPowerDisplay != 0"
                          class="material-icons green-power non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="green-power-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="{{ (offer.intrinsicGreenPowerDisplay == 0) ? offer.greenPowerDisplay + '% GreenPower' :  offer.greenPowerDisplay + '% green power' }} ">
                          &#xE545;
                      </i>
                      <i
                          *ngIf="offer.includesDemandTariff != 0"
                          class="material-icons includes-demand-tariff non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="includes-demand-tariff-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="Offer includes demand charge"
                          aria-label="Offer includes demand charge">
                          &#xE6E1;
                      </i>
                      <i
                          *ngIf="offer.annualFeeCount > 0"
                          class="material-icons annual-fee non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="annual-fee-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="The annual fee has been taken into account within the estimate for this offer."
                          aria-label="The annual fee has been taken into account within the estimate for this offer.">
                          notification_important
                      </i>
                      <i
                          *ngIf="offer.isContingentOffer == true"
                          class="material-icons contingentgasoffer non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="contingentgasoffer-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="{{ (energyType == 'Gas') ? 'This offer is contingent upon having an electricity contract with the same retailer' : 'This offer is contingent upon having a gas contract with the same retailer' }}">
                          &#xE002;
                      </i>
                      <i
                          *ngIf="offerHasVariableFit(offer.solar) >= 0 && hasSolar == true"
                          class="material-icons solar non-focus"
                          placement="top"
                          tabindex="0"
                          tooltipClass="solar-tip"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="{{ (energyType != 'Gas') ? variableFitTariffName(offer.solar) : '' }}">
                          grid_on
                      </i>
                      &nbsp;
                  </div>
              </div>

              <!-- eligibility -->
              <div class="col-6 col-lg-1 align-middle no-padding">
                <a
                    title="Eligibility criteria"
                    aria-label="Eligibility criteria"
                    class="eligibility-criteria"
                    tooltipClass="eligibility-criteria"
                    [triggers]="utility.toolTipForMobile()"
                    ngbTooltip="{{ offer.eligibilityCriteria }}">Eligibility criteria</a>
              </div>

              <!-- offer name -->
              <div class="col-6 col-lg-2 offer-details align-middle offer-name">
                <div class="row offer-info">
                    <span class="retailer-name full-width" [ngClass]="{ 'full-retailer-name' : (showFilters == false) }">
                        <a
                          [attr.title]="offer.retailerName"
                          [attr.aria-label]="offer.retailerName"
                          (click)="viewOffer(offerIndex)">
                          {{ offer.retailerName }}
                        </a>
                        <br />
                    </span>
                    <span *ngIf="showFilters == false" class="offer-name full-width">
                        <a
                            [attr.title]="offer.offerName"
                            [attr.aria-label]="offer.offerName"
                            (click)="viewOffer(offerIndex)">
                            {{ offer.offerName }}
                        </a>
                        <br />
                    </span>
                    <span class="full-width offer-id">
                        <a
                            [attr.title]="offer.offerName"
                            [attr.aria-label]="offer.offerName"
                            (click)="viewOffer(offerIndex)">
                            ID: {{ offer.offerId }}
                        </a>
                        <br/>
                    </span>

                </div>
              </div>

              <!-- offer logo -->
              <div class="col-6 col-lg-2 no-padding logo">
                <div class="row y-align-middle">
                    <div class="col-12 col-lg-12 align-middle single-retailer-logo">
                        <div class="retailer-logo offer-center-align offer-left-align align-middle-img">
                            <a
                                [attr.title]="offer.retailerName"
                                [attr.aria-label]="offer.retailerName"
                                (click)="viewRetailer(offer.retailerId)">
                                <img
                                    [attr.alt]="offer.retailerName"
                                    [attr.aria-label]="offer.retailerName"
                                    src='{{ appURL }}/assets/{{ offer.retailerImageUrl }}'>
                            </a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </li>
    </ul>

    <ul *ngIf="energyType == 'dual'" id="offers" [ngClass]="dataService.getPrint() == true ? 'print-offers' : ''">
        <li
            *ngFor="let offer of dualOffers.offersList; first as isFirst, index as offerIndex;"
            [ngClass]="showFilters == false ? 'extra-margin-bottom' : ''"
            class="row offer-row">
            <div class="row col-md-12 offer-div">
              <div class="col-2 col-lg-1 dual-energy-display">
                    <br *ngIf="showFilters == false" />
                    <br *ngIf="showFilters == false" />
                    <div class="row vertical-text">
                        <p>ELEC</p>
                    </div>

                    <div class="row dual-energy-checkbox">
                        <div class="checkbox checkbox-warning offer-left-align align-middle-checkbox dual-checkbox">
                            <input
                                type="checkbox"
                                [checked]="(selectedOffers.includes(offerIndex))"
                                [disabled]="checkboxIsDisabled(offerIndex)"
                                class="form-check-input"
                                (click)="updateOfferSelection(offerIndex)">
                            <label>
                            </label>
                        </div>
                    </div>

                    <div class="row vertical-text">
                        <p>GAS</p>
                    </div>
              </div>
              <div class="col-10 col-lg-4 dual-price-bundle offer-div">
                <div class="col-md-12 offer-bundle-price text-center">
                      <p>
                          <span class="HelveticaNeueLTStd-Regular">Bundle total price</span>
                      </p>
                      <br />
                      <p *ngIf="offer.solarCredit > 0" class="bundle-solar">
                          Solar generation of <span class="strong">{{ offer.solarCredit | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                          deducted from est. value
                      </p>
                      <br />
                      <p>
                          <span class="price-sign">$</span>
                          <span *ngIf="dataService.getSorting() == true">
                              <span class="currency">
                                  {{ Math.abs((offer.totalConditionalPrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalConditionalPrice * 1)) | number }}
                                  <span *ngIf="((offer.totalConditionalPrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalConditionalPrice * 1)) < 0" class="strong credits">
                                      CR
                                  </span>
                              </span>
                          </span>
                          <span *ngIf="dataService.getSorting() == false">
                              <span class="currency">
                                  {{ Math.abs((offer.totalBasePrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalBasePrice * 1)) | number }}
                              </span>
                              <span *ngIf="((offer.totalBasePrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalBasePrice * 1)) < 0" class="strong credits">
                                  CR
                              </span>
                          </span>
                          &nbsp;
                          <sub>
                              / Est. yearly price
                          </sub>
                      </p>
                      <div
                          *ngIf="offer.includesDemandTariff != 0 && fileUpload == false && getMyData == false"
                          class="includes-demand-tariff includes-tariff-notice full-width center-text"
                          tooltipClass="includes-demand-tariff-tip"
                          placement="top"
                          [triggers]="utility.toolTipForMobile()"
                          ngbTooltip="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you."
                          aria-label="This demand charge does not form part of the annual estimate above. You should discuss switching to a demand tariff with the retailer, to find out if it is the best offer for you.">
                          <span class="plus">+</span>
                          demand charge
                      </div>

                      <br />
                      <p *ngIf="dataService.getSorting() == true">
                          Without conditional discounts
                          <span class="strong total-base-price">
                              {{ Math.abs((offer.totalBasePrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalBasePrice * 1)) | currency:'USD':'symbol':'1.0-0' }}
                          </span>
                          <span *ngIf="((offer.totalBasePrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalBasePrice * 1)) < 0" class="strong">
                              CR
                          </span>
                      </p>
                      <p *ngIf="dataService.getSorting() == false">
                          With conditional discounts
                          <span class="total-base-price strong">
                              {{ Math.abs((offer.totalConditionalPrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalConditionalPrice * 1)) | currency:'USD':'symbol':'1.0-0' }}
                          </span>
                          <span *ngIf="((offer.totalConditionalPrice * 1) + (gasOffers.offersList[offer.gasOfferIndex].totalConditionalPrice * 1)) < 0" class="strong">
                              CR
                          </span>
                      </p>
                </div>
              </div>
              <div class="col-lg-7 dual-energy-details offer-div">
                  <!--electricity-->
                  <div class="row page-divider-bottom">

                      <div class="col-md-3 col-xs-6 col-sm-6 align-middle offer-features dual-energy-column">
                          <div class="offer-icons">
                              <i
                                  *ngIf="offer.isVictorianDefaultOffer != false"
                                  class="material-icons victorian-default-offer non-focus"
                                  tooltipClass="victorian-default-offer-tip"
                                  placement="top"
                                  tabindex="0"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="Victorian Default Offer"
                                  aria-label="Victorian Default Offer">
                                  &#xe32a;
                              </i>
                              <i
                                  *ngIf="offer.isDirectDebitOnly == true"
                                  class="material-icons direct-debit non-focus"
                                  tooltipClass="direct-debit-tip"
                                  placement="top"
                                  tabindex="0"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="Payment details required (e.g. bank debit / credit card)"
                                  aria-label="Payment details required (e.g. bank debit / credit card)">
                                  &#xE870;
                              </i>
                              <i
                                  *ngIf="offer.hasPayOnTimeDiscount == true || offer.contractLength != 'None'"
                                  class="material-icons payontime non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="payontime-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ (offer.hasPayOnTimeDiscount == true) ? 'Offer has a pay on time discount' : ''}} {{ (offer.contractLength != 'None') ? offer.contractLength + ' contract' : '' }} "
                                  aria-label="Offer has a pay on time discount">
                                  &#xE855;
                              </i>
                              <i
                                  *ngIf="offer.hasIncentive == true"
                                  class="material-icons incentive non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="incentive-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="Offer has an incentive"
                                  aria-label="Offer has an incentive">
                                  &#xE8F6;
                              </i>
                              <i
                                  *ngIf="offer.greenPowerDisplay != 0"
                                  class="material-icons green-power non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="green-power-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ (offer.intrinsicGreenPowerDisplay == 0) ? offer.greenPowerDisplay + '% GreenPower' :  offer.greenPowerDisplay + '% green power' }} ">
                                  &#xE545;
                              </i>
                              <i
                                  *ngIf="offer.includesDemandTariff != 0"
                                  class="material-icons includes-demand-tariff non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="includes-demand-tariff-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="Offer includes demand charge"
                                  aria-label="Offer includes demand charge">
                                  &#xE6E1;
                              </i>
                              <i
                                  *ngIf="offer.annualFeeCount > 0"
                                  class="material-icons annual-fee non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="annual-fee-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="The annual fee has been taken into account within the estimate for this offer."
                                  aria-label="The annual fee has been taken into account within the estimate for this offer.">
                                  &#xe7f7;
                              </i>
                              <i
                                  *ngIf="offer.isContingentOffer == true"
                                  class="material-icons contingentgasoffer non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="contingentgasoffer-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ (energyType == 'Gas') ? 'This offer is contingent upon having an electricity contract with the same retailer' : 'This offer is contingent upon having a gas contract with the same retailer' }}">
                                  &#xE002;
                              </i>
                              <i
                                  *ngIf="offerHasVariableFit(offer.solar) >= 0 && hasSolar == true"
                                  class="material-icons solar non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="solar-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ (energyType != 'Gas') ? variableFitTariffName(offer.solar) : '' }}">
                                  grid_on
                              </i>
                              &nbsp;
                          </div>
                      </div>
                      <div class="col-md-2 col-xs-6 col-sm-6 align-middle criteria dual-energy-column">
                          <a
                              title="Eligibility criteria"
                              aria-label="Eligibility criteria"
                              class="eligibility-criteria"
                              tooltipClass="eligibility-criteria"
                              [triggers]="utility.toolTipForMobile()"
                              ngbTooltip="{{ offer.eligibilityCriteria }}">Eligibility criteria</a>
                      </div>

                      <div class="col-md-4 col-xs-6 col-sm-6 offer-details align-middle offer-name dual-energy-column">
                        <div class="row offer-info">
                            <span class="retailer-name full-width" [ngClass]="{ 'full-retailer-name' : (showFilters == false) }">
                                <a
                                    [attr.title]="offer.retailerName"
                                    [attr.aria-label]="offer.retailerName"
                                    (click)="viewOffer(offerIndex, 'electricity')">
                                    {{ offer.retailerName }}
                                </a>
                                <br />
                            </span>
                            <span *ngIf="showFilters == false" class="offer-name full-width">
                                <a
                                    [attr.title]="offer.offerName"
                                    [attr.aria-label]="offer.offerName"
                                    (click)="viewOffer(offerIndex, 'electricity')">
                                    {{ offer.offerName }}
                                </a>
                                <br />
                            </span>
                            <span class="full-width offer-id">
                                <a
                                    [attr.title]="offer.offerName"
                                    [attr.aria-label]="offer.offerName"
                                    (click)="viewOffer(offerIndex, 'electricity')">
                                    ID: {{ offer.offerId }}
                                </a>
                                <br/>
                            </span>
                            <span class="full-width view-offer">
                                <a
                                    [attr.title]="offer.offerName"
                                    [attr.aria-label]="offer.offerName"
                                    class="anchor-style full-width"
                                    (click)="viewOffer(offerIndex, 'electricity')">
                                    view offer
                                </a>
                            </span>
                        </div>
                    </div>

                      <div class="col-md-3 col-xs-6 col-sm-6 retailer-image dual-energy-column">
                        <div class="retailer-logo offer-center-align align-middle-img align-middle-img-dual">
                            <a
                                [attr.title]="offer.retailerName"
                                [attr.aria-label]="offer.retailerName"
                                (click)="viewRetailer(offer.retailerId)">
                                <img
                                    [attr.alt]="offer.retailerName"
                                    [attr.aria-label]="offer.retailerName"
                                    src='{{ appURL }}/assets/{{ offer.retailerImageUrl }}'>
                            </a>
                        </div>
                    </div>
                  </div>
                  <!--gas-->
                  <div class="row">
                      <div class="col-md-3 col-xs-6 col-sm-6 align-middle offer-features dual-energy-column">
                          <div class="offer-icons">
                              <i
                                  *ngIf="gasOffers.offersList[offer.gasOfferIndex].isDirectDebitOnly == true"
                                  class="material-icons direct-debit non-focus"
                                  tooltipClass="direct-debit-tip"
                                  placement="top"
                                  tabindex="0"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="Payment details required (e.g. bank debit / credit card)"
                                  aria-label="Payment details required (e.g. bank debit / credit card)">
                                  &#xE870;
                              </i>
                              <i
                                  *ngIf="gasOffers.offersList[offer.gasOfferIndex].hasPayOnTimeDiscount == true || gasOffers.offersList[offer.gasOfferIndex].contractLength != 'None'"
                                  class="material-icons payontime non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="payontime-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ (gasOffers.offersList[offer.gasOfferIndex].hasPayOnTimeDiscount == true) ? 'Offer has a pay on time discount' : ''}} {{ (gasOffers.offersList[offer.gasOfferIndex].contractLength != 'None') ? gasOffers.offersList[offer.gasOfferIndex].contractLength + ' contract' : '' }} "
                                  aria-label="Offer has a pay on time discount">
                                  &#xE855;
                              </i>
                              <i
                                  *ngIf="gasOffers.offersList[offer.gasOfferIndex].hasIncentive == true"
                                  class="material-icons incentive non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="incentive-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="Offer has an incentive"
                                  aria-label="Offer has an incentive">
                                  &#xE8F6;
                              </i>
                              <i
                                  *ngIf="gasOffers.offersList[offer.gasOfferIndex].greenPowerDisplay != 0"
                                  class="material-icons green-power non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="green-power-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ (gasOffers.offersList[offer.gasOfferIndex].intrinsicGreenPowerDisplay == 0) ? gasOffers.offersList[offer.gasOfferIndex].greenPower + '% GreenPower' :  gasOffers.offersList[offer.gasOfferIndex].greenPower + '% green power' }} ">
                                  &#xE545;
                              </i>
                              <i
                                  *ngIf="gasOffers.offersList[offer.gasOfferIndex].annualFeeCount > 0"
                                  class="material-icons annual-fee non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="annual-fee-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="The annual fee has been taken into account within the estimate for this offer."
                                  aria-label="The annual fee has been taken into account within the estimate for this offer.">
                                  &#xe7f7;
                              </i>
                              <i
                                  *ngIf="gasOffers.offersList[offer.gasOfferIndex].isContingentOffer == true"
                                  class="material-icons contingentgasoffer non-focus"
                                  placement="top"
                                  tabindex="0"
                                  tooltipClass="contingentgasoffer-tip"
                                  [triggers]="utility.toolTipForMobile()"
                                  ngbTooltip="{{ 'This offer is contingent upon having an electricity contract with the same retailer' }}">
                                  &#xE002;
                              </i>
                              &nbsp;
                          </div>
                      </div>
                      <div class="col-md-2 col-xs-6 col-sm-6 align-middle dual-energy-column criteria">
                          <a
                              title="Eligibility criteria"
                              aria-label="Eligibility criteria"
                              class="eligibility-criteria"
                              tooltipClass="eligibility-criteria"
                              [triggers]="utility.toolTipForMobile()"
                              ngbTooltip="{{ gasOffers.offersList[offer.gasOfferIndex].eligibilityCriteria }}">Eligibility criteria</a>
                      </div>
                      <div class="col-md-4 col-xs-6 col-sm-6 offer-details align-middle offer-name dual-energy-column">
                        <div class="row offer-info">
                            <span class="retailer-name full-width" [ngClass]="{ 'full-retailer-name' : (showFilters == false) }">
                                <a
                                    [attr.title]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                    [attr.aria-label]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                    (click)="viewOffer(offer.gasOfferIndex, 'Gas')">
                                    {{ gasOffers.offersList[offer.gasOfferIndex].retailerName }}
                                </a>
                                <br />
                            </span>
                            <span *ngIf="showFilters == false" class="offer-name full-width">
                                <a
                                    [attr.title]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                    [attr.aria-label]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                    (click)="viewOffer(offer.gasOfferIndex, 'Gas')">
                                    {{ gasOffers.offersList[offer.gasOfferIndex].retailerName }}
                                </a>
                                <br />
                            </span>
                            <span class="full-width offer-id">
                                <a
                                    [attr.title]="gasOffers.offersList[offer.gasOfferIndex].offerName"
                                    [attr.aria-label]="gasOffers.offersList[offer.gasOfferIndex].offerName"
                                    (click)="viewOffer(offer.gasOfferIndex, 'Gas')">
                                    ID: {{ gasOffers.offersList[offer.gasOfferIndex].offerId }}
                                </a>
                                <br/>
                            </span>
                            <span class="full-width view-offer">
                                <a
                                    [attr.title]="gasOffers.offersList[offer.gasOfferIndex].offerName"
                                    [attr.aria-label]="gasOffers.offersList[offer.gasOfferIndex].offerName"
                                    class="anchor-style full-width"
                                    (click)="viewOffer(offer.gasOfferIndex, 'Gas')">
                                    view offer
                                </a>
                            </span>
                        </div>
                    </div>
                      <div class="col-md-3 col-xs-6 col-sm-6 retailer-image dual-energy-column">
                        <div class="retailer-logo offer-center-align align-middle-img align-middle-img-dual">
                            <a
                                [attr.title]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                [attr.aria-label]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                (click)="viewRetailer(gasOffers.offersList[offer.gasOfferIndex].retailerId)">
                                <img
                                    [attr.alt]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                    [attr.aria-label]="gasOffers.offersList[offer.gasOfferIndex].retailerName"
                                    src='{{ appURL }}/assets/{{ gasOffers.offersList[offer.gasOfferIndex].retailerImageUrl }}'>
                            </a>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </li>
    </ul>
</div>
<div class="row" style="display: none;">
    <div class="col-md-7 hidden-sm hidden-xs mobile-padding">
        <button
            tabindex="10"
            title="Compare selected offers"
            aria-label="Compare selected offers"
            class="btn compare-offer-btn compare-offer-btn-txt"
            [disabled]="compareIsDisabled()"
            (click)="showComparison()">
            Compare selected offers</button>
    </div>
    <div class="col-md-5 mobile-padding">
        <button
            tabindex="11"
            title="View more offers"
            aria-label="View more offers"
            [disabled]="!((offersCount - offersShown) > 0)"
            class="more-offer-btn more-offer-btn-txt non-focus"
            [ngClass]="(offersCount - offersShown) > 0 ? 'btn-success' : ''"
            id="modalMoreOffers"
            (click)="incrementPageCount()">
            View more offers
        </button>
    </div>
</div>

<div class="col-12 row-more-offers text-center" *ngIf="offersCount" [ngClass]="(offersCount - offersShown) > 0 ? '' : 'more-offers-disabled'" (click)="incrementPageCount()">
  <button mat-icon-button [disabled]="!((offersCount - offersShown) > 0)" [ngClass]="(offersCount - offersShown) > 0 ? '' : 'more-offers-disabled'" class="btn-more-offers" >
    <i aria-hidden="true" class="material-icons mat-icon-show-more" [ngClass]="(offersCount - offersShown) > 0 ? 'mat-icon-show-more-color' : 'more-offers-disabled'">expand_more</i>
  </button>
</div>

<div class="row loopback-buttons">
    <div class="col-md-2" [ngClass]="{'col-md-7': loopBackHasOffers == true || energyType == 'Gas' || (energyType == 'Electricity' && electricityGasConnection != 1) }">
        &nbsp;
    </div>
    <div class="text-right col-md-5 mobile-padding"
        *ngIf="loopBackHasOffers == false && energyType != undefined"
        [hidden]="(energyType == 'Electricity' && electricityGasConnection != 1)"
        (click)="utility.loopBack()">
        <button type="button" class="offers-button offers-button-txt col-md-12">
            {{ (energyType != 'Gas') ? 'Compare gas offers as well' : 'Compare electricity offers as well' }}
        </button>
    </div>
    <div class="text-right col-md-5 mobile-padding" *ngIf="energyType != 'Gas'">
        <a href="{{ appURL }}/solar-details" target="_blank">
            <button type="button" class="offers-button offers-button-txt col-md-12">
                Calculate your solar savings
            </button>
        </a>
    </div>
</div>
<br />
<div class="row">
  <div class="print-icons col-lg-12">
      <button
          tabindex="14"
          title="Email offer results"
          aria-label="Email offer results"
          type="button"
          class="btn btn-link"
          id="email-results"
          (click)="email()">
          <i class="fa fa-envelope"></i>
          - email these results
      </button>
      <button
          tabindex="13"
          title="Print these results"
          aria-label="Print these results"
          type="button"
          class="btn btn-link"
          id="print-results"
          (click)="print()">
          <i class="fa fa-print"></i>
          - print these results
      </button>
      <button
          type="button"
          class="btn btn-link"
          id="export-results"
          (click)="export()"
          tabindex="12">
          <i class="fa fa-save"></i>
          - export these results
      </button>
  </div>
</div>
