import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment'; //config file
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OffersDescriptionModalComponent } from '../offers-description-modal/offers-description-modal.component';
import { RetailerInfoModalComponent } from '../retailer-info-modal/retailer-info-modal.component';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { OfferInfoModalComponent } from '../offer-info-modal/offer-info-modal.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { DataService } from '../data.service';
import { Utility } from '../utility';
import * as _ from 'lodash';
declare const GA4LogEvent : any;

@Component({
  selector: 'app-offers-right-panel',
  templateUrl: './offers-right-panel.component.html',
  styleUrls: ['./offers-right-panel.component.css'],
  providers : [NgbModalConfig, NgbModal]
})
export class OffersRightPanelComponent implements OnInit {
  @Input() pageLoad = false;
  @Input() retailers = [];
  @Input() offers;
  @Input() electricityOffers;
  @Input() dualOffers;
  @Input() gasOffers;
  @Input() energyType;
  @Input() electricityGasConnection;
  @Input() loopBackIsEmpty;
  @Input() loopBackHasOffers;
  @Input() loopBackDistributors;
  @Input() gasOfferIndex;
  @Input() offersCount = 0;
  @Input() offersShown = 0;
  @Input() showFilters = true;
  @Input() fileUpload;
  @Input() getMyData;
  @Input() hasSolar;
  @Input() selectedOffers = [];
  @Output() compareSelected = new EventEmitter();
  @Output() updateSelectedOffers = new EventEmitter();
  @Output() showMoreOffers = new EventEmitter();
  public showSolarButton = false;
  public showLoopBackButton = false;
  public Math = Math;
  public secBranding = environment.secBranding;
  public emailSecurityKey = environment.emailSecurityKey;
  public pageLimit = environment.pageLimit;
  public appURL = environment.appURL;
  public offersInfo = false;
  public infoModalShown = false;
  public showCalculation = false

  constructor(private cd: ChangeDetectorRef,
              public router : Router,
              public utility : Utility,
              public modalService : NgbModal,
              public dataService : DataService,
              private tooltipConfig : NgbTooltipConfig) {
                this.tooltipConfig.triggers = 'mouseenter click:mouseleave';
              }

  //any changes to @input variables
  ngOnChanges(changes: any) {
    //this.cd.detectChanges();

    if (this.loopBackIsEmpty == true) {
      this.showLoopBackButton = true;
    }

    if (this.loopBackDistributors == false) {
      this.showLoopBackButton = false;
    }
  }

  ngOnInit() {
  }

  public toggleCalculation() {
    this.showCalculation = !this.showCalculation;
  }

  public hideCalculation() {
    this.showCalculation = false;
  }

  public offersInfoModal() {
    this.modalService.open(OffersDescriptionModalComponent, {size : 'lg'});
  }

  public showComparison() {
    if (this.selectedOffers.length > 0) {
      this.compareSelected.emit();
    }
  }

  public checkboxIsDisabled(offerKey) {
    if (this.selectedOffers.length >= 3) {
      let index = this.selectedOffers.indexOf(offerKey);
      if (index > -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public compareIsDisabled() {
    if (this.selectedOffers.length < 1) {
      return true;
    } else {
      return false;
    }
  }

  public updateOfferSelection(offerIndex) {
    let index = this.selectedOffers.indexOf(offerIndex);
    if (index > -1) {
      this.selectedOffers.splice(index, 1);
    } else {
      if (this.selectedOffers.length < 3) {
        this.selectedOffers.push(offerIndex);
      } else {
        return false;
      }
    }
    this.updateSelectedOffers.emit(this.selectedOffers);
  }

  public incrementPageCount() {
    if (this.offersInfo == false) {
      this.offersInfo = true;
      let modalRef = this.modalService.open(OffersDescriptionModalComponent, {size : 'lg'});
      modalRef.result.then((options) => {
        setTimeout(() => {
          this.showMoreOffers.emit();
        }, 100);
      });
      modalRef.result.then(() => {}, () => {
        setTimeout(() => {
          this.showMoreOffers.emit();
        }, 100);
      }); //enable buttons on click outside
    } else {
      this.showMoreOffers.emit();
    }
  }

  public formatDate(day, month) {
    var objDate = new Date();
    objDate.setMonth(parseInt(month));
    objDate.setDate(parseInt(day));
  }

  public viewRetailer(retailerId) {
    let selectedRetailer = _.find(this.retailers, { Id : retailerId } );
    let modalRef = this.modalService.open(RetailerInfoModalComponent, {size : 'xl'});
    modalRef.componentInstance.retailer = selectedRetailer;
  }

  public viewOffer(offerIndex, energyType = '') {
    this.dataService.setPrint(true);
    //this.utility.scrollToTop('none');
    let modalRef = this.modalService.open(OfferInfoModalComponent, {size : 'xl'});
    if (energyType != '') {
      modalRef.componentInstance.offer = (energyType == 'Gas') ? this.gasOffers.offersList[offerIndex] : this.dualOffers.offersList[offerIndex];
    } else {
      modalRef.componentInstance.offer = this.offers.offersList[offerIndex];
    }
    let seletedEnergyType = (energyType != '') ? energyType : this.energyType;
    this.dataService.setSelectedEnergyType(seletedEnergyType);
    modalRef.componentInstance.energyType = seletedEnergyType;
    modalRef.componentInstance.returnPage = this.router.url;
    modalRef.componentInstance.fileUpload = this.fileUpload;
    modalRef.componentInstance.getMyData = this.getMyData;
    this.infoModalShown = true;
    modalRef.result.then((result) => {
      this.infoModalShown = false;
    }, (reason) => {
    });
  }

  public contactTheRetailer(offerInfo) {
    var retailerName = encodeURI(offerInfo.retailerName.replace(/ /g,'-').toLowerCase());
    var offerId = encodeURI(offerInfo.offerId.toLowerCase());
    var url = '/contact/' + retailerName + '/' + offerId;
  }

  public export() {
    let modalRef = this.modalService.open(ExportModalComponent, {size : 'lg'});
    modalRef.componentInstance.energyType = this.energyType;
    GA4LogEvent('/offers', 'EXPORT');
  }

  public email() {
    let modalRef = this.modalService.open(EmailModalComponent, {size : 'xs'});
    modalRef.componentInstance.headerTitle = 'Email offer results';
    GA4LogEvent('/offers', 'EMAIL');
  }

  public print() {
    window.print();
    GA4LogEvent('/offers', 'PRINT');
  }

  public showSolarDetails() {
    this.router.navigate(['/solar-details']).then(() => {
      this.utility.scrollToTop("none");
    });
  }

  offerHasVariableFit(solarTariff: any) {
    return solarTariff.findIndex(tariff => tariff.feed_in_tariff == "Variable FIT policy");
  }

  variableFitTariffName(solarTariff: any) {

    let solarTariffName = [];
    solarTariff.forEach(tariff => {
      if (tariff.name != '') {
        solarTariffName.push(tariff.name.trim());
      }
    });

    return solarTariffName.toString().replace(',',' ');

  }

}
