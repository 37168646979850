import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PowercorInfoModalComponent } from './powercor-info-modal/powercor-info-modal.component';
import { DownloadDataModalComponent } from './download-data-modal/download-data-modal.component';
import { ConsessionInfoModalComponent } from './consession-info-modal/consession-info-modal.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgBootstrapFormValidationModule, CUSTOM_ERROR_MESSAGES } from 'ng-bootstrap-form-validation';
import { CollectionStatementModalComponent } from './collection-statement-modal/collection-statement-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveChatWidgetModule } from '@livechat/widget-angular'
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { CUSTOM_ERRORS } from "./custom-errors";
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { OffersLeftPanelComponent } from './offers-left-panel/offers-left-panel.component';
import { OffersRightPanelComponent } from './offers-right-panel/offers-right-panel.component';
import { OffersHeaderPanelComponent } from './offers-header-panel/offers-header-panel.component';
import { OffersComponent } from './offers/offers.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OfferInfoModalComponent } from './offer-info-modal/offer-info-modal.component';
import { CompareComponent } from './compare/compare.component';
import { ContactComponent } from './contact/contact.component';
import { OffersDescriptionModalComponent } from './offers-description-modal/offers-description-modal.component';
import { RetailerInfoModalComponent } from './retailer-info-modal/retailer-info-modal.component';
import { OfferBannerPanelComponent } from './offer-banner-panel/offer-banner-panel.component';
import { SmartMeterModalComponent } from './smart-meter-modal/smart-meter-modal.component';
import { SortingModalComponent } from './sorting-modal/sorting-modal.component';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { ExportModalComponent } from './export-modal/export-modal.component';
import { RateDetailsModalComponent } from './rate-details-modal/rate-details-modal.component';
import { FaqModalComponent } from './faq-modal/faq-modal.component';
import { QuizComponent } from './quiz/quiz.component';
import { FaqOffersModalComponent } from './faq-offers-modal/faq-offers-modal.component';
import { FaqCompareModalComponent } from './faq-compare-modal/faq-compare-modal.component';
import { DistributorInformationModalComponent } from './distributor-information-modal/distributor-information-modal.component';
import { RetailersComponent } from './retailers/retailers.component';
import { InstructionalVideosComponent } from './instructional-videos/instructional-videos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { BannerComponent } from './banner/banner.component';
import { TopTipsComponent } from './top-tips/top-tips.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { HotTopicsComponent } from './hot-topics/hot-topics.component';
import { EnergyProfileComponent } from './energy-profile/energy-profile.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { PsbModalComponent } from './psb-modal/psb-modal.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SolarDetailsComponent } from './solar-details/solar-details.component';
import { SolarTermsModalComponent } from './solar-terms-modal/solar-terms-modal.component';
import { SolarResultsComponent } from './solar-results/solar-results.component';
import { SolarRightPanelComponent } from './solar-right-panel/solar-right-panel.component';
import { SolarLeftPanelComponent } from './solar-left-panel/solar-left-panel.component';
import { SolarHeaderPanelComponent } from './solar-header-panel/solar-header-panel.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { HasSolarModalComponent } from './has-solar-modal/has-solar-modal.component';
import { HotTopicsElectricityBillComponent } from './hot-topics-electricity-bill/hot-topics-electricity-bill.component';
import { HotTopicsSwitchComponent } from './hot-topics-switch/hot-topics-switch.component';
import { HotTopicsEnergySimplifiedComponent } from './hot-topics-energy-simplified/hot-topics-energy-simplified.component';
import { HotTopicsVDOComponent } from './hot-topics-vdo/hot-topics-vdo.component';
import { HotTopicsReduceComponent } from './hot-topics-reduce/hot-topics-reduce.component';
import { HotTopicsRenovatingComponent } from './hot-topics-renovating/hot-topics-renovating.component';
import { HotTopicsMovingOutComponent } from './hot-topics-moving-out/hot-topics-moving-out.component';
import { HotTopicsMovingHouseComponent } from './hot-topics-moving-house/hot-topics-moving-house.component';
import { SolarSavingsComponent } from './solar-savings/solar-savings.component';
import { SurveyModalComponent } from './survey-modal/survey-modal.component';
import { environment } from '../environments/environment';
import { LoopbackStartComponent } from './loopback-start/loopback-start.component';
import { LoopbackEnergyProfileComponent } from './loopback-energy-profile/loopback-energy-profile.component';
import { LoopBackBillDetailsComponent } from './loopback-bill-details/loopback-bill-details.component';
import { LoopBackCompareComponent } from './loopback-compare/loopback-compare.component';
import { PsbFaqComponent } from './psb-faq/psb-faq.component';
import { TwelveMonthsModalComponent } from './twelve-months-modal/twelve-months-modal.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { EmailBrochureModalComponent } from './email-brochure-modal/email-brochure-modal.component';
import { PsbOutreachComponent } from './psb-outreach/psb-outreach.component';
import { DualRateDetailsModalComponent } from './dual-rate-details-modal/dual-rate-details-modal.component';
import { OfflineComponent } from './offline/offline.component';
import { SolarCalculatorComponent } from './solar-calculator/solar-calculator.component';
import { YearRequirementModalComponent } from './year-requirement-modal/year-requirement-modal.component';
import { LanguagesComponent } from './languages/languages.component';
import { LanguageComponent } from './languages/language/language.component';
import { LanguagePageComponent } from './languages/language-page/language-page.component';
import { RestartComponent } from './restart/restart.component';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { FaqConsumptionModalComponent } from './faq-consumption-modal/faq-consumption-modal.component';
import { FaqContactModalComponent } from './faq-contact-modal/faq-contact-modal.component';
import { FaqQuestionnaireModalComponent } from './faq-questionnaire-modal/faq-questionnaire-modal.component';
import { PsbSurveyComponent } from './psb-survey/psb-survey.component';
import { CommunityOutreachSurveyComponent } from './community-outreach-survey/community-outreach-survey.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { ConfirmEqualValidatorDirective } from './shared/directives/confirm-equal-validator.directive';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { PsbRegistrationModalComponent } from './psb-registration-modal/psb-registration-modal.component';
import { PSBResultsComponent } from './psbresults/psbresults.component';
import { PsbOffersComponent } from './psb-offers/psb-offers.component';
import { CompareSolarModalComponent } from './compare-solar-modal/compare-solar-modal.component';
import { StandaloneComponent } from './standalone/standalone.component';
import { SolarOptionsModalComponent } from './solar-options-modal/solar-options-modal.component';
import { PSB3JumpPointComponent } from './psb3-jump-point/psb3-jump-point.component';
import { PsbSurvey3Component } from './psb-survey3/psb-survey3.component';
import { PsbSurvey4Component } from './psb-survey4/psb-survey4.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EmbeddedNetworksComponent } from './embedded-networks/embedded-networks.component';
import { EbrFaqsComponent } from './ebr-faqs/ebr-faqs.component';
import { SmallBusinessEnergyQuestionnaireComponent } from './small-business-energy-questionnaire/small-business-energy-questionnaire.component';
import { HotTopicsBestOfferComponent } from './hot-topics-best-offer/hot-topics-best-offer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CollectionStatementModalComponent,
    PowercorInfoModalComponent,
    DownloadDataModalComponent,
    ConsessionInfoModalComponent,
    TermsModalComponent,
    OffersLeftPanelComponent,
    OffersRightPanelComponent,
    OffersHeaderPanelComponent,
    OffersComponent,
    OfferInfoModalComponent,
    CompareComponent,
    ContactComponent,
    OffersDescriptionModalComponent,
    RetailerInfoModalComponent,
    OfferBannerPanelComponent,
    SmartMeterModalComponent,
    SortingModalComponent,
    EmailModalComponent,
    ExportModalComponent,
    RateDetailsModalComponent,
    FaqModalComponent,
    QuizComponent,
    FaqOffersModalComponent,
    FaqCompareModalComponent,
    FaqQuestionnaireModalComponent,
    FaqConsumptionModalComponent,
    FaqContactModalComponent,
    DistributorInformationModalComponent,
    RetailersComponent,
    InstructionalVideosComponent,
    PrivacyComponent,
    BannerComponent,
    TopTipsComponent,
    HotTopicsComponent,
    EnergyProfileComponent,
    BillDetailsComponent,
    PsbModalComponent,
    ContactUsComponent,
    FeedbackFormComponent,
    SolarDetailsComponent,
    SolarTermsModalComponent,
    SolarResultsComponent,
    SolarRightPanelComponent,
    SolarLeftPanelComponent,
    SolarHeaderPanelComponent,
    HasSolarModalComponent,
    HotTopicsElectricityBillComponent,
    HotTopicsSwitchComponent,
    HotTopicsEnergySimplifiedComponent,
    HotTopicsVDOComponent,
    HotTopicsReduceComponent,
    HotTopicsRenovatingComponent,
    HotTopicsMovingOutComponent,
    HotTopicsMovingHouseComponent,
    SolarSavingsComponent,
    SurveyModalComponent,
    LoopbackStartComponent,
    LoopbackEnergyProfileComponent,
    LoopBackBillDetailsComponent,
    LoopBackCompareComponent,
    PsbFaqComponent,
    TwelveMonthsModalComponent,
    EmailBrochureModalComponent,
    PsbOutreachComponent,
    DualRateDetailsModalComponent,
    OfflineComponent,
    SolarCalculatorComponent,
    YearRequirementModalComponent,
    LanguagesComponent,
    LanguageComponent,
    LanguagePageComponent,
    RestartComponent,
    InformationModalComponent,
    PsbSurveyComponent,
    CommunityOutreachSurveyComponent,
    ConfirmEqualValidatorDirective,
    SubscribeComponent,
    PsbRegistrationModalComponent,
    PSBResultsComponent,
    PsbOffersComponent,
    CompareSolarModalComponent,
    PsbRegistrationModalComponent,
    StandaloneComponent,
    SolarOptionsModalComponent,
    PSB3JumpPointComponent,
    PsbSurvey3Component,
    PsbSurvey4Component,
    EmbeddedNetworksComponent,
    EbrFaqsComponent,
    SmallBusinessEnergyQuestionnaireComponent,
    HotTopicsBestOfferComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    NgbModule,
    LiveChatWidgetModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgSelectModule,
    MatExpansionModule,
    MatInputModule,
    NgxCaptchaModule,
    Ng2GoogleChartsModule,
    BsDropdownModule.forRoot()
  ],
  providers: [
    {
      provide: CUSTOM_ERROR_MESSAGES,
      useValue: CUSTOM_ERRORS,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
